import { Box, Button, Typography } from '@mui/material'
import icon1 from '../../../assets/images/howitworks/icon-1.svg'
import icon2 from '../../../assets/images/howitworks/icon-2.svg'
import icon3 from '../../../assets/images/howitworks/icon-3.svg'
import jcbIcon from '../../../assets/images/howitworks/jcbIcon.png'
import './styles.scss'

const FindEqpSaleTab = () => {
    return (
        <Box>
            <Box>
                <Box>
                    <p className='tab-heading'>
                        Buying Is As Easy As…
                    </p>
                    <Box>
                        <Box className='tablike-content'>
                            <Box className='tab-grid'>
                                <Box>
                                    <Box className='tab-grid-heading'>
                                        <Box>
                                            <img src={icon1} alt="" className='tabicon' />
                                        </Box>
                                        <Typography>Find</Typography>
                                    </Box>
                                    <Typography className='tab-grid-text'>
                                        Search our database
                                        of privately owned
                                        equipment nationwide.
                                        Or filter for equipment
                                        that might be just
                                        around the corner.
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box className='tab-grid-heading'>
                                        <Box>
                                            <img src={icon2} alt="" className='tabicon' />
                                        </Box>
                                        <Typography>Select</Typography>
                                    </Box>
                                    <Typography className='tab-grid-text'>
                                        Find your desired
                                        item. Test drive
                                        before you buy
                                        with an RPO.
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box className='tab-grid-heading'>
                                        <Box>
                                            <img src={icon3} alt="" className='tabicon' />
                                        </Box>
                                        <Typography>Buy</Typography>
                                    </Box>
                                    <Typography className='tab-grid-text'>
                                        Confidently secure
                                        your purchase with
                                        our experts assisting
                                        you every step of the
                                        way. Need financing?
                                        We can help.
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box className='tab-grid-heading'>
                                        <Box>
                                            <img src={jcbIcon} alt="" className='jcbicon' />
                                        </Box>
                                        <Typography>Get to Work</Typography>
                                    </Box>
                                    <Typography className='tab-grid-text'>
                                        Build your project. Build
                                        your own rental fleet. Or
                                        just enjoy your savings
                                        and knowing you are
                                        part of a community
                                        first model.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default FindEqpSaleTab
