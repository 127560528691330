import React from 'react';
import { Box, Typography , Link} from '@mui/material';
import ServiceItem from './ServiceItem';
import iconLending from 'assets/images/icons_lending.png'
import iconService from 'assets/images/icons_servies.png'
import iconDirt from 'assets/images/icons_dirtradio.png'
import iconLocation from 'assets/images/icons_equipmentlocation.png'
import testimonial1 from 'assets/images/testimonial_01.png'
import './styles.scss'

const testimonialDescription1 = `"As we get more equipment, or at any time that we need equipment, it seems like we can just find it on the app. It's been really beneficial because a lot of the main rental agencies are just booked out for months."

- Casey D., St. George, UT`
const ServiceSection = () => {
  return (
    <>
    </>
    // <Box className='service-section' >
    //   <Box className='service-section__row' >
    //   <Link
    //         href="/equipment-leanding"
    //         sx={{ textDecoration: 'none' }}
    //       >
    //     <ServiceItem
    //       title='Equipment Financing'
    //       description='Our Loan Specialists will help YOU get equipment financing for: Purchases, Refinances, Cash-outs.'
    //       icon={iconLending}
    //     />
    //     </Link>
    //     <ServiceItem 
    //       title='Services Directory'
    //       description="List your company's services for FREE on the app and reach a wider audience in your area"
    //       icon={iconService}
    //     />
    //   </Box>
      
    //   <Box className='service-section__row' >
    //   <Link
    //         href="https://dirtradio.com"
    //         sx={{ textDecoration: 'none' }}
    //         target="_blank"
    //       >
    //         <ServiceItem
    //       title='Rock Your Day'
    //       description='We made radio station just for you! Ad-free classic rock to pump-up your day!'
    //       icon={iconDirt}
    //     />
    //      </Link>
    //     <ServiceItem
    //       title='Location Services'
    //       description='COMING SOON! Know where your equipment and high-value assets are at all times'
    //       icon={iconLocation}
    //     />
    //   </Box>
    //   <Box className='testimonial'>
    //     <img
    //       src={testimonial1}
    //       alt='Testimonial'
    //       className='testimonial-img'
    //     />
    //     <Box className='testimonial-content'>
    //       <Typography variant='h4' className='testimonial-title'>
    //         What real people are saying
    //       </Typography>
    //       <Typography className='testimonial-description' >
    //         {testimonialDescription1}
    //       </Typography>
    //     </Box>
    //   </Box>
    // </Box>
  );
};

export default ServiceSection;