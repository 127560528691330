import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import { FaPlus, FaMinus } from "react-icons/fa";

import './styles.scss'

const CustomExpandIcon = () => {
    return (
        <Box
            sx={{
                ".Mui-expanded & > .collapsIconWrapper": {
                    display: "none"
                },
                ".expandIconWrapper": {
                    display: "none"
                },
                ".Mui-expanded & > .expandIconWrapper": {
                    display: "block"
                }
            }}
        >


            <div className="expandIconWrapper"><FaMinus /></div>
            <div className="collapsIconWrapper"><FaPlus /></div>
        </Box>
    );
};

const ListEqpQuesSale = () => {
  return (
      <Box className='gq'>
          <Box className='gq-in'>
              <Box>
                  <p className='gq-heading'>
                      GOT QUESTIONS?
                  </p>
                  <p className='gq-subheading'>
                      Here Are a Few That Are Frequently Asked...
                  </p>
              </Box>
              <Box className='gq-content'>
                 
                  <Box>
                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                          >
                              <Typography>How much does it cost to list?</Typography>
                          </AccordionSummary>
                          <AccordionDetails >
                              <Typography>
                                  It is FREE to list.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel2-content"
                              id="panel2-header"
                          >
                              <Typography>How old do you have to be to list an item?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  You must be at least 18 years old.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel3-content"
                              id="panel3-header"
                          >
                              <Typography>Do I pay any seller fees?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Yes, a 5% seller’s fee is withheld from the purchase price.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel4-content"
                              id="panel4-header"
                          >
                              <Typography>Who sets the sales prices?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  The equipment owner sets the sales price. Sales price can be adjusted by the owner at
                                  any time.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel5-content"
                              id="panel5-header"
                          >
                              <Typography>Can GoEquipMe help determine the value of my equipment?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Yes. With an experienced team and all the right resources we can help to determine the
                                  appropriate value of your equipment.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel6-content"
                              id="panel6-header"
                          >
                              <Typography>Can I communicate with the buyer?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  A GoEquipMe representative will communicate with potential buyers on your behalf to
                                  ensure a no hassle experience while getting the best possible price.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel7-content"
                              id="panel7-header"
                          >
                              <Typography>Can I rent my equipment on GoEquipMe while waiting for it to sell?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Yes. In fact we recommend keeping your equipment making money for you while
                                  finding a suitable buyer.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel8-content"
                              id="panel8-header"
                          >
                              <Typography>What other seller services does GoEquipMe provide?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Just about everything! From due diligence items, written contracts, inspections,
                                  transport or title, just ask us and we will help.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel9-content"
                              id="panel9-header"
                          >
                              <Typography>What is an RPO?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  RPO stands for Rental Purchase Option. Sellers may elect to offer potential buyers an
                                  opportunity to ‘test drive’ listed equipment through a rental with purchase option. Should
                                  the potential buyer choose to exercise the purchase option a predetermined percentage
                                  of the rental payment is applied to the purchase price.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel10-content"
                              id="panel10-header"
                          >
                              <Typography>Who structures the RPO?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  GoEquipMe will assist in structuring RPO terms that work for both buyer and seller.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel11-content"
                              id="panel11-header"
                          >
                              <Typography>How much do I earn with an RPO?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  There are two possible outcomes of an RPO that provide additional income to the seller.
                              </Typography>
                              <ol>
                                <li>
                                      Sales price +additional rental income: If the RPO is converted to a purchase the
                                      seller will receive the agreed upon sales price plus a percentage of the rental
                                      income.
                                </li>
                                <li>
                                      Rental income: Should the RPO not be exercised the seller keeps the rental
                                      income and a new buyer is sought.
                                </li>
                              </ol>
                              <Typography>
                                  Electing to offer an RPO attracts the largest pool of buyers as it instills confidence in
                                  your listed equipment.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>


                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel12-content"
                              id="panel12-header"
                          >
                              <Typography>Can I offer owner financing?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Yes. GoEquipMe has all the resources to help to structure a financed sale that works for
                                  you.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel13-content"
                              id="panel13-header"
                          >
                              <Typography>Can GoEquipMe help to service an owner financed loan?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  As a full service lender GoEquipMe can help with contracting, loan servicing,
                                  collections, etc.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel14-content"
                              id="panel14-header"
                          >
                              <Typography>Can I use my sales proceeds to become a GoEquipMe lender?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Yes. Invest in what you know. Become a qualified lender and earn attractive income with
                                  equipment as security collateral. <a>Inquire here</a>.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                  </Box>
              </Box>
          </Box>
      </Box>
  )
}

export default ListEqpQuesSale
