import React from 'react'
import { useState } from 'react';
import MainLayout from '../../layouts/MainLayout'
import { Box, Grid, Typography } from '@mui/material';
import { EquipmentAPIs } from '../../service/api-service';
import { httpClient } from "../../service/http-client";
import './styles.scss'
import { IApiResponseRawData, IEquipment } from 'utils/types';


import { useDispatch, useSelector } from 'react-redux';
import { setalertMessagetype, setalertMessage} from 'redux/redux-slice';

/**
 * Main About page.
 */
const Contact: React.FC = () => {
    const dispatch = useDispatch()
    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleName = (event) => {
        setName(event.target.value);
    }

    const handleEmail = (event) => {
        setEmail(event.target.value);
    }

    const handleAddress = (event) => {
        setMessage(event.target.value);
    }



    const handleSubmit = async (event) => {

        try {
            
            event.preventDefault();
            let _name = name;
            let _email = email;
            let _message = message;

            if(_name == "" || _name == null) {
                dispatch(setalertMessage("Please fill name first."));
                dispatch(setalertMessagetype("error"));
                return false;
            }
            
            var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (_email == "") {
                dispatch(setalertMessage("Please fill email address!"));
                dispatch(setalertMessagetype("error"));              
                return false;
            }
            if (!_email.match(mailformat)) {
                dispatch(setalertMessage("Invalid email address!"));
                dispatch(setalertMessagetype("error"));
                return false;
            }


            if(_name == "" || _message == null) {
                dispatch(setalertMessage("Please fill message first."));
                dispatch(setalertMessagetype("error"));
                return false;
            }
            
            
            const data = {
                "name": _name,
                "email": _email,
                "message": _message,
            }
            const contactmail: IApiResponseRawData = await EquipmentAPIs.sendContactMail(data)           
            console.log("contactmail api: " , contactmail);
            
            if(contactmail.result == 'success') {
                setEmail('');
                setName('');
                setMessage('')
                 alert('Message sent. Thank you!')
            }
           
           
      
          } catch (err) {
           // console.log(err)
          }

        

    }

    return (
        <MainLayout insideMainContainer={false}>            
            <div className="main-contaner">
                <Box className='service-section__row' >
                    <div className="row main">
                        <div className="col-md-12">
                            <div className="contact__description main-header">
                                <p className="text-center">Questions or feedback?</p>
                                <p className="text-center">
                                    We are here to help and look forward to hearing from you!
                                </p>

                            </div>
                        </div>
                    </div>

                </Box>
                <Box className='service-section__row' >
                    <Box className="row contact-form" sx={{ width: '100%', }}>
                        <Grid container spacing={2} sx={{ width: '100%', marginLeft: '0 !important' }}>
                            <Grid item xs={12} md={6} lg={6} className="contact-form-div" sx={{ width: '100%', padding: '20px !important' }}>
                                <form onSubmit={e => { handleSubmit(e) }} className="main-form">
                                    <div className="col-md-4 col-12 p-3 main">
                                        <div className="contact__form">
                                            <div className="form-group contact-input-grp">
                                                <input
                                                    type="text"
                                                    className="form-control contact-inputs"
                                                    id="name"
                                                    value={name}
                                                    placeholder="Your Name"
                                                    onChange={e => handleName(e)}
                                                    
                                                />
                                                <span id="chkname"></span>
                                            </div>

                                            <div className="form-group contact-input-grp">
                                                <input
                                                    type="email"
                                                    className="form-control contact-inputs"
                                                    id="email"
                                                    placeholder="Your Email"
                                                    value={email}
                                                    onChange={e => handleEmail(e)}
                                                />
                                                <span id="chkemail"></span>
                                            </div>
                                            <div className="form-group contact-input-grp">
                                                <textarea
                                                    className="form-control contact-inputs"
                                                    placeholder="Message"
                                                    id="message"
                                                    value={message}
                                                    onChange={e => handleAddress(e)}
                                                    rows={5}
                                                ></textarea>
                                                <span id="chkmsg"></span>
                                            </div>
                                        </div>
                                        <div className="conatctform__buttton text-center">

                                            <button type="submit" className="submit-btn" 
                                            style={{cursor:"pointer"}}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </Grid>
                            {/* <Grid item xs={12} md={6} lg={6} sx={{ width: '100%', padding: '20px !important', marginLeft: '0 !important' }}>
                                <div className="contact__info mt-4">
                                <div className="contact-detail-container contact-info">
                                    <div className="contact__info mt-4">
                                        <div className="contactinfo__detail d-flex">
                                            <div className="location__icon">

                                                <a>Email:</a>
                                            </div>
                                            <div className="location__address" >
                                                <a href="mailto:admin@goequipme.com" className="mail-link"> admin@goequipme.com</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="contact__info mt-4">
                                        <div className="contactinfo__detail d-flex">
                                            <div className="location__icon">
                                                <a>Address:</a>
                                            </div>
                                            <div className="location__address" >
                                                <a>782 S. River Road #121, Saint George UT 84790</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="contact__info mt-4">
                                        <div className="contactinfo__detail d-flex">
                                            <div className="location__icon">
                                                <a>Mobile:-</a>
                                            </div>
                                            <div className="location__address" >
                                                <a>435.565.1357</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </Grid> */}
                        </Grid>
                    </Box>
                

                </Box>
            </div>
        </MainLayout>
    )
}

export default Contact