import React, { useEffect, useState, useRef, useCallback } from "react";
import { Box, Button, Checkbox, Container, FormControlLabel, TextField, Typography, styled, Backdrop, CircularProgress, Modal, IconButton, useMediaQuery, FormControl, Link, DialogContent, DialogContentText, DialogActions, Dialog, CheckboxProps, InputAdornment } from '@mui/material'
import pencilIcon from "assets/images/pencil.png";
import locationIcon from "assets/images/icons_equipmentlocation.png";
import HelpLogo from "assets/images/help-icon.png";
import cross from "assets/images/Social-Icon/cross-delete.png";
import MainLayout from "layouts/MainLayout";
import './styles.scss'
import { useDispatch, useSelector } from "react-redux";
import {
  setLoginStatus,
  setLoginModalOpen,
  setLogInLoading,
  setalertMessagetype,
  setalertMessage,
  setaccessToken,
  SetRedirectPage,
  setAuthuser,
  setSessionExpire,
  setEmailVerify,
  setAccountCompleted
} from "redux/redux-slice";
import { EquipmentAPIs } from "../../service/api-service";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import GoogleautoComplete from '../equipment-list/GoogleautoComplete'
import CloseIcon from '@mui/icons-material/Close'
import Geocode from "react-geocode";
import TxtNotification from "../common/TxtNotificationPopup/TextNotificationPopup";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const CssTextField = styled(TextField)(({ theme }) => ({

  '& .MuiInputBase-input': {
    borderRadius: ' 8px !important',
    padding: '10px',
    [theme.breakpoints.up('xs')]: {
      padding: '10px',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '12px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '14px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '15px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '16px',
    },
    fontSize: { xs: '12px !important', sm: '12px !important', md: '13px !important', lg: '14px !important', xl: '14px !important' },
  },
  "& .MuiInputBase-root": {
    color: "#2e2e2e",
    background: "#fff",
    borderRadius: ' 8px !important',
    fontFamily: "Montserrat !important",
    fontWeight: '600',
    fontSize: "clamp(14px, 3vw, 16px) !important",
    // border: "2px solid #faa61a",
  },
  "& label.Mui-focused": {
    color: "#faa61a",
    fontWeight: "bold",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#2e2e2e",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
      fontSize: { xs: '12px !important', sm: '12px !important', md: '13px !important', lg: '14px !important', xl: '14px !important' },
    },
    "&:hover fieldset": {
      borderColor: "#2e2e2e",
      border: "2px solid #faa61a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
}));

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  border: "2px solid #faa61a",
  backgroundColor: "#fff",
  ".Mui-focusVisible &": {
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#fff",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd'  clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%232d2d2d'/%3E%3C/svg%3E\")",
    content: '""',
  },
});

const loginstyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  //width: 800,
  //bgcolor: 'rgb(46 46 46)', 
  boxShadow: 24,
  p: 4,
}


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const SignUp = () => {
  const dispatch = useDispatch();
  const APIKEY = process.env.REACT_APP_GEOCODDER_KEY
  Geocode.setApiKey(APIKEY);
  const inRef = useRef(null)
  const searchRef = useRef();

  const AuthToken = useSelector((state: IStoreValue) => state.equipmentReducer.accessToken)
  const UserId = useSelector((state: IStoreValue) => state.equipmentReducer.Authuser)
  const OnelinkQaCode = useSelector((state: IStoreValue) => state.equipmentReducer.qrCodeValue);
  const AuthProfile = useSelector((state: IStoreValue) => state.equipmentReducer.AuthProfileData);

  const matchesMobileX = useMediaQuery('(max-width:425px)')
  // 1st Step
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [referral, setReferral] = useState("");
  const [loading, setloading] = useState<boolean>(false);
  const isLoggedin = useSelector((state: IStoreValue) => state.equipmentReducer.isLogin)
  const ProfileStatus = useSelector((state: IStoreValue) => state.equipmentReducer.ProfileStatus)
  const EmailVerify = useSelector((state: IStoreValue) => state.equipmentReducer.EmailVerify)

  // const [FirsttimePopup, setFirsttimePopup] = useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showCnfrmPassword, setShowCnfrmPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowCnfrmPassword = () => setShowCnfrmPassword((show) => !show);

  const HandleSignup = async (e) => {
    e.preventDefault();
    try {
      setloading(true);
      var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (email == "") {
        dispatch(setalertMessage("Please fill email address!"));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        dispatch(setLoginStatus(false));
        return false;
      }
      if (!email.match(mailformat)) {
        dispatch(setalertMessage("Invalid email address!"));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        dispatch(setLoginStatus(false));
        return false;
      }
      if (password == "") {
        dispatch(setalertMessage("Please fill password!"));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        dispatch(setLoginStatus(false));
        return false;
      }
      if (cpassword == "") {
        dispatch(setalertMessage("Please fill confirm password!"));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        dispatch(setLoginStatus(false));
        return false;
      }
      if (password != cpassword) {
        dispatch(setalertMessage("Confirm password did not match!"));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        dispatch(setLoginStatus(false));
        return false;
      } else {
        const Signup_status: IApiResponseRawData = await EquipmentAPIs.SignUp({
          email: email,
          password: password,
          // by GD for include referral code in sign up
          referred_by: referral
        });

        if (Signup_status["status"] == 200) {

          if (Signup_status["type"] == "RXERROR") {
            dispatch(setalertMessage(Signup_status["message"]));
            dispatch(setalertMessagetype("error"));
            setloading(false);
            dispatch(setLoginStatus(false));
            return false;
          } else {
            setloading(false);
            let auth_user = Signup_status["data"];
            localStorage.setItem("token-info", JSON.stringify(auth_user));
            dispatch(setaccessToken(Signup_status["data"]["auth_token"]));
            dispatch(setAuthuser(Signup_status["data"]["user_id"]));
            dispatch(setEmailVerify(Signup_status["data"]["is_user_verify"]));
            dispatch(setAccountCompleted(1));
            setUserMail(email)
            setEmail("");
            setPassword("");
            setCPassword("");
            // dispatch(setLoginStatus(true));
            dispatch(setLoginModalOpen(false));

            // dispatch(setalertMessage(Signup_status["message"]));
            // dispatch(setalertMessagetype("success"));

          }

          // window.location.href = "/signup";
        } else {
          setloading(false);
          dispatch(setLoginStatus(false));
          dispatch(setalertMessage(Signup_status["message"]));
          dispatch(setalertMessagetype("error"));
        }
      }
    } catch (err) {
      console.log(err);
      setloading(false);
      setEmail("");
      setPassword("");
      dispatch(setLoginStatus(false));
    }
  };

  // 2nd step
  const RedirectPage = useSelector((state: IStoreValue) => state.equipmentReducer.RedirectPage)
  const [FirstName, setFirstName] = React.useState('');
  const [MiddleName, setMiddleName] = React.useState('');
  const [LastName, setLastName] = React.useState('');
  const [Company, setCompany] = React.useState('');
  const [Address, setAddress] = React.useState('');
  const [Aaddress, setAaddress] = React.useState('');
  const [Phone, setPhone] = React.useState('');
  const [Terms, setTerms] = React.useState('no');
  const [MapPopup, setMapPopup] = useState<boolean>(false);

  const [suggesaddress, setsuggesaddress] = useState('');
  const defaultLocationcods = { lat: 39.321980897034734, lng: -111.10149320091872 };
  const [defaultLocation, setdefaultLocation] = useState(null);
  const [LocationAddress, setLocationAddress] = useState(null);
  const [defaultNewLocation, setdefaultNewLocation] = useState(defaultLocationcods);

  /*
   * Last step 
   * OPen text notification poup
   */
  const [NotificationSettingPopup, setNotificationSettingPopup] = React.useState(false);


  //user Verification email 
  const [UserMail, setUserMail] = useState('');

  const HandleFName = (event) => {
    let fname = event.target.value
    var letters = fname.replace(/[^A-Za-z ]+/g, '');
    setFirstName(letters)
  }

  const HandleMiddle = (event) => {
    let m_name = event.target.value
    var letters = m_name.replace(/[^A-Za-z ]+/g, '');
    setMiddleName(letters)
  }

  const HandleLName = (event) => {
    let l_name = event.target.value
    var letters = l_name.replace(/[^A-Za-z ]+/g, '');
    setLastName(letters)
  }

  const HandleCompany = (event) => {
    let company_name = event.target.value
    setCompany(company_name.trim())
  }

  const HandleAaddress = (event) => {
    let additional_address = event.target.value
    setAaddress(additional_address.trim())
  }

  const phoneFormat = (phNumber) => {
    var match = phNumber.match(/(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var number = [match[1], "-", match[2], "-", match[3]].join("");
      setPhone(number);
      return;
    }
    setPhone(phNumber);
  };

  const HandlePhone = (event) => {
    let val = event.target.value;
    val = val.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
    if (val.length == 0) {
      setPhone(val);
    } else {
      phoneFormat(val)
    }
  }

  const HandleTerms = (event) => {
    let val = event.target.checked
    if (val) {
      setTerms('yes')
    } else {
      setTerms('no')
    }
  }

  const [FullAddress, setFullAddress] = useState({
    "lat": "",
    "lng": "",
    "city": "",
    "state": "",
    "country": "",
    "postal_code": "",
    "address": "",
  });

  // Update profile
  const ValidateProfile = async (e) => {
    e.preventDefault();
    try {
      var new_FirstName = FirstName;
      if (new_FirstName != null) {
        if (new_FirstName.length > 0) {
          new_FirstName = new_FirstName.trimStart();
        }
      }

      if (new_FirstName == '' || new_FirstName == null) {
        dispatch(setalertMessage('Please fill first name to continue!'));
        dispatch(setalertMessagetype('error'));
        return false;
      }

      if (Address == '') {
        dispatch(setalertMessage('Please fill valid street address to continue!'));
        dispatch(setalertMessagetype('error'));
        return false;
      }

      if (FullAddress.lat == '' || FullAddress.lat == null || FullAddress.lng == '' || FullAddress.lng == null) {
        dispatch(setalertMessage('Please fill valid street address to continue!'));
        dispatch(setalertMessagetype('error'));
        return false;
      }

      if (Phone == '') {
        dispatch(setalertMessage('Please enter your phone number to continue!'));
        dispatch(setalertMessagetype('error'));
        return false;
      }

      if (Phone.length != 12) {
        dispatch(setalertMessage('Please enter valid phone number to continue!'));
        dispatch(setalertMessagetype('error'));
        return false;
      }

      if (Terms == 'no') {
        dispatch(setalertMessage('Please check Terms of Service and Privacy Policy to continue!'));
        dispatch(setalertMessagetype('error'));
        return false;
      }

      setNotificationSettingPopup(true)
      // var new_Phone = Phone.replace(/[^0-9]/g, "");

      // let body = {
      //   first_name: new_FirstName,
      //   middle_name: MiddleName ? MiddleName.trim() : '',
      //   last_name: LastName ? LastName.trim() : '',
      //   company_name: Company ? Company.trim() : '',
      //   additional_address: Aaddress ? Aaddress.trim() : '',
      //   user_id: UserId.toString(),
      //   address: FullAddress.address,
      //   lat: FullAddress.lat,
      //   lng: FullAddress.lng,
      //   city: FullAddress.city,
      //   state: FullAddress.state,
      //   country: FullAddress.country,
      //   postal_code: FullAddress.postal_code,
      //   phone_no: new_Phone.toString(),
      //   // email_pref: this.state.emailCheck == true ? "1" : "0",
      //   // phone_pref: this.state.phoneCheck == true ? "1" : "0",
      //   // discount_pref: this.state.discountCheck == true ? "1" : "0",
      //   status: "1",
      //   device_type: "web",
      //   term: Terms,
      //   text_notification: smsnotification ? "1" : "0",
      // }

      // const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      // const UserStatus: IApiResponseRawData = await EquipmentAPIs.UpdateProfile(
      //   body, headers
      // );

      // if (UserStatus['status'] == 200) {
      //   resetForm();
      //   if (UserStatus["type"] == "RXERROR") {
      //     dispatch(setalertMessage(UserStatus['message']));
      //     dispatch(setalertMessagetype('error'));
      //     return false;
      //   } else {
      //     dispatch(setalertMessage(UserStatus['message']));
      //     dispatch(setalertMessagetype('success'));
      //     setFirsttimePopup(true)
      //     dispatch(setAccountCompleted(3));
      //   }
      // }
      // else {
      //   setloading(false)
      //   dispatch(setalertMessage(UserStatus['message']));
      //   dispatch(setalertMessagetype('error'));
      //   dispatch(setSessionExpire(true));
      // }

    } catch (err) {
      setloading(false)
      console.log(err);
    }
  };

  const UpdateProfile = async (smsnotification = false) => {
    //e.preventDefault();
    try {
      var new_FirstName = FirstName;
      if (new_FirstName != null) {
        if (new_FirstName.length > 0) {
          new_FirstName = new_FirstName.trimStart();
        }
      }

      if (new_FirstName == '' || new_FirstName == null) {
        dispatch(setalertMessage('Please fill first name to continue!'));
        dispatch(setalertMessagetype('error'));
        return false;
      }

      if (Address == '') {
        dispatch(setalertMessage('Please fill valid street address to continue!'));
        dispatch(setalertMessagetype('error'));
        return false;
      }

      if (FullAddress.lat == '' || FullAddress.lat == null || FullAddress.lng == '' || FullAddress.lng == null) {
        dispatch(setalertMessage('Please fill valid street address to continue!'));
        dispatch(setalertMessagetype('error'));
        return false;
      }

      if (Phone == '') {
        dispatch(setalertMessage('Please enter your phone number to continue!'));
        dispatch(setalertMessagetype('error'));
        return false;
      }

      if (Phone.length != 12) {
        dispatch(setalertMessage('Please enter valid phone number to continue!'));
        dispatch(setalertMessagetype('error'));
        return false;
      }

      if (Terms == 'no') {
        dispatch(setalertMessage('Please check Terms of Service and Privacy Policy to continue!'));
        dispatch(setalertMessagetype('error'));
        return false;
      }

      var new_Phone = Phone.replace(/[^0-9]/g, "");

      let body = {
        first_name: new_FirstName,
        middle_name: MiddleName ? MiddleName.trim() : '',
        last_name: LastName ? LastName.trim() : '',
        company_name: Company ? Company.trim() : '',
        additional_address: Aaddress ? Aaddress.trim() : '',
        user_id: UserId.toString(),
        address: FullAddress.address,
        lat: FullAddress.lat,
        lng: FullAddress.lng,
        city: FullAddress.city,
        state: FullAddress.state,
        country: FullAddress.country,
        postal_code: FullAddress.postal_code,
        phone_no: new_Phone.toString(),
        // email_pref: this.state.emailCheck == true ? "1" : "0",
        // phone_pref: this.state.phoneCheck == true ? "1" : "0",
        // discount_pref: this.state.discountCheck == true ? "1" : "0",
        status: "1",
        device_type: "web",
        term: Terms,
        text_notification: smsnotification ? "1" : "0",
      }

      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const UserStatus: IApiResponseRawData = await EquipmentAPIs.UpdateProfile(
        body, headers
      );

      if (UserStatus['status'] == 200) {
        resetForm();
        if (UserStatus["type"] == "RXERROR") {
          dispatch(setalertMessage(UserStatus['message']));
          dispatch(setalertMessagetype('error'));
          return false;
        } else {
          dispatch(setalertMessage(UserStatus['message']));
          dispatch(setalertMessagetype('success'));
          // setFirsttimePopup(true)
          localStorage.setItem("profileCompleted", "true");
          dispatch(setAccountCompleted(3));
          window.location.href = "/";
        }
      }
      else {
        setloading(false)
        dispatch(setalertMessage(UserStatus['message']));
        dispatch(setalertMessagetype('error'));
        dispatch(setSessionExpire(true));
      }

    } catch (err) {
      setloading(false)
      console.log(err);
    }
  };

  const UpdateAddress = () => {
    let location = LocationAddress;
    setFullAddress(location)
    setLocationAddress(null)

    setdefaultNewLocation({ "lat": location.lat, "lng": location.lng })
    setAddress(suggesaddress)
    setsuggesaddress('')
  }

  const HandelAddress = (e) => {

    setFullAddress({
      "lat": "",
      "lng": "",
      "city": "",
      "state": "",
      "country": "",
      "postal_code": "",
      "address": ""
    })
    setsuggesaddress('')

    let name = e.target.value;
    setAddress(name)
    if (name != '' && name.length > 0) {
      setsuggesaddress('')
      const xhr = new XMLHttpRequest();
      xhr.open('GET', 'https://maps.googleapis.com/maps/api/geocode/json?address=' + name + '&key=' + APIKEY);
      xhr.onload = function () {
        if (xhr.status === 200) {
          let result = JSON.parse(xhr.response);
          if (result['results'].length > 0) {
            let address = result['results'][0]['formatted_address'];
            let lett = result['results'][0].geometry['location'].lat;
            let lngg = result['results'][0].geometry['location'].lng;

            let city, state, country, short_state, postal_code;
            for (let i = 0; i < result['results'][0].address_components.length; i++) {
              for (let j = 0; j < result['results'][0].address_components[i].types.length; j++) {
                switch (result['results'][0].address_components[i].types[j]) {
                  case "locality":
                    city = result['results'][0].address_components[i].long_name;
                    break;
                  case "administrative_area_level_1":
                    state = result['results'][0].address_components[i].long_name;
                    break;
                  case "country":
                    country = result['results'][0].address_components[i].long_name;
                    break;
                  case "postal_code":
                    postal_code = result['results'][0].address_components[i].short_name;
                    break;
                }
              }
            }

            if (!postal_code) {
              const addressData = result.results.find(address => address.types.includes("postal_code"));
              const postalCodeArr = addressData?.address_components?.find(address => !isNaN(+address.short_name));
              postal_code = postalCodeArr?.short_name || postalCodeArr?.long_name || "";
            }

            let location_address = {
              "lat": lett,
              "lng": lngg,
              "city": city,
              "state": state,
              "country": country,
              "postal_code": postal_code,
              "address": address
            };

            // setFullAddress(location_address)
            setLocationAddress(location_address)
            setsuggesaddress(address)
            //setdefaultNewLocation({ "lat": lett, "lng": lngg })
          } else {
            setFullAddress({
              "lat": "",
              "lng": "",
              "city": "",
              "state": "",
              "country": "",
              "postal_code": "",
              "address": ""
            })
            setsuggesaddress('')
          }
        } else {
          setFullAddress({
            "lat": "",
            "lng": "",
            "city": "",
            "state": "",
            "country": "",
            "postal_code": "",
            "address": ""
          })
          setsuggesaddress('')
        }
      };
      xhr.send();
    }
  }

  //  Get city state country address etc from geocode
  const getFullAddress = async () => {
    let center = defaultLocation;

    if (center.lat == null && center.lng == null) {
      setFullAddress({
        "lat": "",
        "lng": "",
        "city": "",
        "state": "",
        "country": "",
        "postal_code": "",
        "address": ""
      })
      setsuggesaddress('')

      dispatch(setalertMessage('Please fill valid street address to continue!'));
      dispatch(setalertMessagetype('error'));
      return false
    } else {
      Geocode.fromLatLng(center.lat, center.lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          let city, state, country, short_state, postal_code;
          for (let i = 0; i < response.results[0].address_components.length; i++) {
            for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
              switch (response.results[0].address_components[i].types[j]) {
                case "locality":
                  city = response.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].long_name;
                  break;
                case "country":
                  country = response.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  short_state = response.results[0].address_components[i].short_name;
                  break;
                case "postal_code":
                  postal_code = response.results[0].address_components[i].short_name;
                  break;
              }
            }
          }
          let location_address = {
            "lat": center.lat.toString(),
            "lng": center.lng.toString(),
            "city": city,
            "state": state,
            "country": country,
            "postal_code": postal_code,
            "address": address
          };
          setFullAddress(location_address)
          setMapPopup(false)
          setAddress(address)

          setdefaultNewLocation(
            { lat: center.lat, lng: center.lng }
          )
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  const GetProfile = async (user_id, token) => {
    try {
      setloading(true)
      const headers = { Authorization: `${token}`, request_from: "Web" };
      const Result = await EquipmentAPIs.getProfile(
        {
          user_id: user_id.toString(),
        },
        headers
      );

      if (Result["type"] == "RXSUCCESS") {
        // if (
        //   (Result["data"]["is_profile_completed"] == "1" || Result["data"]["is_profile_completed"] == 1)) {
        //   window.location.href = "/";
        //   return false;
        // } 
        if (Result["data"]["is_user_verify"] == 0) {
          if (Result["data"]["is_profile_completed"] == "1" || Result["data"]["is_profile_completed"] == 1) {
            window.location.href = "/";
            return false;
          }
        }

        // if(redirect == 'signup' 
        // && !window.location.href.includes("signup")
        //   && !window.location.href.includes("terms_and_conditions")
        //   && !window.location.href.includes("privacy_policy") 
        // )
        //   {
        //     window.location.href = "/signup";

        //   return false;
        //   }


        else {
          dispatch(setAccountCompleted(1));
          setFirstName(Result["data"]['first_name'])
          setLastName(Result["data"]['last_name'])
          setUserMail(Result["data"]['email'])
        }
        setloading(false)
      } else {
        setloading(false)
      }
    } catch (err) {
      setloading(false)
    }
  };

  // below getlocation function is commented by pp on 19 apr 2024 due no longer need to user asks location 
  // const _getlocation = useCallback(async () => {
  //   try {
  //     navigator.geolocation.getCurrentPosition(
  //       function (position) {
  //         setdefaultNewLocation({
  //           lat: position.coords.latitude, lng: position.coords.longitude
  //         })

  //       },
  //       function (error) { //not access permission
  //         //alert('location');
  //         // console.error("Error Code = " + error.code + " - " + error.message);
  //         //dispatch(setEquipments([] as Array<IEquipment>))
  //         // searchEquipments(pagelimit)
  //       }
  //     );

  //   } catch (err) {
  //     // console.error("catch Error Code = " + err);    
  //   }
  // }, [])

  const resetForm = async () => {
    //setFirstName('');
    setMiddleName('');
    setLastName('');
    setCompany('');
    setAddress('');
    setAaddress('');
    setPhone('');
    setTerms('no')
    setdefaultNewLocation(defaultLocationcods);
  }

  const VerificationEmailResendHandle = async () => {
    try {
      let body = {
        email: UserMail,
      }
      setloading(true)
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const MailStatus: IApiResponseRawData = await EquipmentAPIs.ResendMail(
        body, headers
      );

      if (MailStatus["type"] == "RXERROR") {
        setloading(false)
        dispatch(setalertMessage(MailStatus['message']));
        dispatch(setalertMessagetype('error'));
        return false;
      } else {
        setloading(false)
        dispatch(setalertMessage(MailStatus['message']));
        dispatch(setalertMessagetype('success'));
        return false;
      }


    } catch (err) {
      setloading(false)
      console.log(err);
    }
  };


  // After email verify from web browser user auto login
  const VerifyUserLogin = async (email = null) => {
    try {
      setloading(true)
      const Result = await EquipmentAPIs.webverifyLogin(
        {
          email: email.toString(),
        }
      );
      console.log('Result API Result', Result);
      if (Result["type"] == "RXSUCCESS") {
        setloading(false);
        let auth_user = Result["data"];
        console.log("Auth data in signup header: ", auth_user);

        if (Result["data"]["is_user_verify"] == 0 && Result["data"]["is_profile_completed"] == 1) {
          localStorage.setItem("login-popup", "open");
          window.location.href = "/"
          return;
        }
        localStorage.setItem("token-info", JSON.stringify(auth_user));
        dispatch(setaccessToken(Result["data"]["auth_token"]));
        dispatch(setAuthuser(Result["data"]["user_id"]));
        dispatch(setEmailVerify(Result["data"]["is_user_verify"]));
        setUserMail(email)
        dispatch(setLoginStatus(true));
        dispatch(setLoginModalOpen(false));
        dispatch(setAccountCompleted(1));
      } else {
        setloading(false)
      }
    } catch (err) {
      setloading(false)
    }
  }

  useEffect(() => {

    /**
     * Check email verification status verify or not
     * when user click on verify button from email
     * email open in web browser
     * check user email verify then user redirect to profile complete section
     */
    console.log("AAuthProfileData in sign up section: ", AuthProfile);

    let user_email = window.location.href.split('/').at(-1);
    let user_status = window.location.href.split('/').at(-2);
    let auth_user = localStorage.getItem("token-info");

    if (!auth_user && user_status == 'verify') {

      if (user_email != "") {
        console.log('user_email', user_email);

        // get user info and redirect to profile page
        VerifyUserLogin(user_email)

      }


    }
    // End 

    // _getlocation()
    if (auth_user) {
      auth_user = JSON.parse(auth_user);
      if (auth_user != null && auth_user) {
        GetProfile(auth_user["user_id"], auth_user["auth_token"]);

        dispatch(setEmailVerify(auth_user["is_user_verify"]));

        let AccessLocation = localStorage.getItem('accesslocation');
        if (AccessLocation != 'undefined') {
          AccessLocation = JSON.parse(AccessLocation);
          if (AccessLocation != null && AccessLocation) {
            setdefaultLocation(
              { lat: AccessLocation['lat'], lng: AccessLocation['lng'] }
            )
          }
        }
      }
    }
  }, []);

  return (
    <MainLayout>
      {loading && (
        <Box>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}


      {/* Email Verification section 21 sep 2023 */}
      {EmailVerify == 1 ? (
        // {isLoggedin && EmailVerify == 1 && (
        <>
          <Box className="signup-1">
            <Container>
              <Box 
              // className="signup-inner-1"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  gap: '2rem',
                  flexDirection: 'column',
                  padding: '1.5rem',
                  border: '4px solid #faa61a',
                  borderRadius: '15px',
                  background: '#111111',
                  width: '80%',
                  margin: 'auto'
                }}
              >
                <Box className="signup-inner-content-1">
                  <Box className="signup-heading-1">
                    <Typography sx={{ color: "#fff !important" }} >GoEquipMe needs to verify your email. We've sent a verification link to <span style={{ color: "#faa61a" }} > {UserMail}</span>.</Typography>
                  </Box>
                  <Box className="signup-heading-1">
                    <Typography sx={{ color: "#fff !important" }} >To finish creating your account, click the big green<span style={{ color: "#faa61a" }} >"Verify my email"</span> button found in our email, or paste the verification link into your browser.</Typography>
                  </Box>
                  <Box>
                    <Typography sx={{ color: "#fff !important" }} >Be sure to <span style={{ color: "#faa61a", fontFamily: 'GothamBold !important' }}>check your spam folder</span> if you cannot see the email.</Typography>
                  </Box>
                </Box>
                <Box className="resend-div">
                  <Button 
                    sx={{ background: '#017933', padding: { xs: '0.5rem 1rem', sm: '0.5rem 1rem', md: '1rem 2rem', lg: '1rem 2.5rem', xl: '1rem 3rem' }, fontSize: { xs: '14px', sm: '16px', md: '20px', lg: '24px', xl: '26px' }, textTransform: 'uppercase', color: '#fff', borderRadius: '7px', fontFamily: 'Montserrat !important', fontWeight: '600' }}
                  // className="resend-email"
                    onClickCapture={VerificationEmailResendHandle}
                  >RESEND EMAIL</Button>
                </Box>
              </Box>
            </Container>
          </Box>
        </>
      ) : (
        <>

          <Box className="signup">
            <Container>
              <Box className="signup-inner">
                {!isLoggedin && EmailVerify == 0 && (
                  // {!isLoggedin && (
                  <>
                    <Box 
                    // className="signup-heading"
                        sx={{ margin: '1rem 0', textAlign: 'center' }}>
                        <Typography sx={{ color: '#fff', fontFamily: 'GothamBold !important', fontSize: { xs: '25px', sm: '30px', md: '45px', lg: '60px', xl: '68px' } }}>Join the GoEquipMe Community</Typography>
                    </Box>
                  </>

                )}
                {isLoggedin && EmailVerify == 0 && !ProfileStatus &&
                  // {isLoggedin && !ProfileStatus &&
                  <>
                    <Box
                      // className="signup-heading"
                      sx={{ margin: '1rem 0', textAlign: 'center' }}>
                      <Typography sx={{ color: '#fff', fontFamily: 'GothamBold !important', fontSize: { xs: '25px', sm: '30px', md: '45px', lg: '60px', xl: '68px' } }}>Join the GoEquipMe Community</Typography>
                    </Box>
                  </>
                }
                  <Box
                    // className="signup-grid"
                    sx={{ mt: 6, display: 'grid', gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr' }, gap: { xs: '1rem', sm: '1rem', md: '1rem', lg: '2rem', xl: '2rem' } }}
                  >
                  {!isLoggedin && (
                    <>
                        <Box
                          // className="signup-grid-item"
                          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
                        >
                          <Typography sx={{ color: '#faa61a', fontFamily: 'GothamBold !important', fontSize: { xs: '18px', sm: '20px', md: '24px', lg: '26px', xl: '30px' } }}>Lets' Get Started</Typography>
                          <Typography sx={{ color: '#fff', fontSize: { xs: '15px', sm: '16px', md: '18px', lg: '20px', xl: '22px' } }}>
                          Find equipment when and where you need it in easy to access
                          categories.
                        </Typography>
                          <Typography sx={{ color: '#faa61a', fontFamily: 'GothamBold !important', fontSize: { xs: '18px', sm: '20px', md: '24px', lg: '26px', xl: '30px' } }}>Rent & Save... List & Earn</Typography>
                      </Box>
                    </>
                  )}

                  {isLoggedin && EmailVerify == 0 && !ProfileStatus && (
                    //  {isLoggedin && !ProfileStatus && (
                    <>
                        <Box
                          // className="signup-grid-item"
                          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
                        >
                          <Typography sx={{ color: '#faa61a', fontFamily: 'GothamBold !important', fontSize: { xs: '18px', sm: '20px', md: '24px', lg: '26px', xl: '30px' } }}>Lets' Get Started</Typography>
                          <Typography sx={{ color: '#fff', fontSize: { xs: '15px', sm: '16px', md: '18px', lg: '20px', xl: '22px' } }}>
                          Find equipment when and where you need it in easy to access
                          categories.
                        </Typography>
                          <Typography sx={{ color: '#faa61a', fontFamily: 'GothamBold !important', fontSize: { xs: '18px', sm: '20px', md: '24px', lg: '26px', xl: '30px' } }}>Rent & Save... List & Earn</Typography>
                      </Box>
                    </>
                  )}
                  <Box className="signup-grid-item">
                    {/* 1st Step */}
                    {!isLoggedin && (
                      <>
                        <Box className="signup-steps-sec">
                            <Box
                              // className="signup-steps-heading"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem',
                                marginBottom: '1rem'
                              }}
                            >
                              <Box component={'img'} src={pencilIcon} alt="" sx={{ width: '35px', height: '35px' }} />
                              <Typography sx={{ color: '#fff', fontSize: { xs: '25px', sm: '30px', md: '35px', lg: '40px', xl: '45px' }, fontFamily: 'GothamBold !important' }}>Sign Up</Typography>
                          </Box>
                            <Box
                              // className="steps-bar"
                              sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(2, 1fr)',
                                gridGap: '1rem',
                                margin: '0.5rem 0',
                                width: '40%',
                              }}
                            >
                              <Box
                                // className="steps-bar"
                                sx={{
                                  width: '100',
                                  height: '4px',
                                  backgroundColor: '#faa61a'
                                }}
                              ></Box>
                              <Box
                                sx={{
                                  width: '100',
                                  height: '4px',
                                  backgroundColor: '#fff'
                                }}
                              // className="steps-bar white-bar"
                              ></Box>
                          </Box>
                        </Box>
                          <Box
                          // className="signup-fields"
                          >
                            <Box
                              // className="input-div"
                              sx={{ margin: '1.2rem 0' }}
                            >
                            <CssTextField
                              id="login-email"
                              variant="outlined"
                              placeholder="Email Address"
                              type="email"
                              sx={{ width: "100%" }}
                              onChange={(e) => setEmail(e.target.value.trim())}
                              value={email}
                            />
                          </Box>
                            <Box
                              // className="input-div"
                              sx={{ margin: '1.2rem 0' }}
                            >
                            <CssTextField
                              id="login-password"
                              variant="outlined"
                              placeholder="Password"
                              type={showPassword ? 'text' : 'password'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      edge="end"
                                      className="eye-icon"
                                    >
                                      {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              sx={{ width: "100%" }}
                              onChange={(e) => setPassword(e.target.value.trim())}
                              value={password}
                            />
                          </Box>
                            <Box
                              // className="input-div"
                              sx={{ margin: '1.2rem 0' }}
                            >
                            <CssTextField
                              id="login-c-password"
                              variant="outlined"
                              placeholder="Confirm Password"
                              type={showCnfrmPassword ? 'text' : 'password'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowCnfrmPassword}
                                      edge="end"
                                      className="eye-icon"
                                    >
                                      {showCnfrmPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              sx={{ width: "100%" }}
                              onChange={(e) => setCPassword(e.target.value.trim())}
                              value={cpassword}
                            />
                          </Box>

                          {/* by GD 26 Feb 2024 for build a crew referral field */}
                            <Box
                              // className="input-div"
                              sx={{ margin: '1.2rem 0' }}
                            >
                            <CssTextField
                              id="login-c-password"
                              variant="outlined"
                              placeholder="Referral Code"
                              type="text"
                              sx={{ width: "100%" }}
                              onChange={(e) => setReferral(e.target.value.replace(/[^0-9]/g, ''))}
                              value={referral}
                            />
                          </Box>
                          {/* GD end */}

                        </Box>
                        <Box className="next-btn-div">
                          <Button 
                              // className="next-btn"
                              sx={{
                                fontSize: {
                                  xs: '20px',
                                  sm: '22px',
                                  md: '24px',
                                  lg: '26px',
                                  xl: '28px'
                                },
                                backgroundColor: '#149248',
                                fontFamily: 'Montserrat',
                                fontWeight: '700',
                                borderRadius: '10px',
                                color: '#fff',
                                boxShadow: 'none',
                                padding: '0.5rem 3rem',
                                "&:hover": {
                                  background: '#149248'
                                }
                              }}
                            onClickCapture={HandleSignup}
                          >Next</Button>
                        </Box>
                      </>
                    )}
                    {isLoggedin && EmailVerify == 0 && !ProfileStatus && (
                      // {isLoggedin && !ProfileStatus &&  (
                      <>
                        {/* --------------- */}
                        {/* 2nd Step */}
                        <Box className="signup-steps-sec">
                            <Box
                              // className="signup-steps-heading"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem',
                                marginBottom: '1rem'
                              }}
                            >
                            {/* <img src={pencilIcon} alt="" /> */}
                              <Box component={'img'} src={pencilIcon} alt="" sx={{ width: '35px', height: '35px' }} />
                              <Typography sx={{ color: '#fff', fontSize: { xs: '25px', sm: '30px', md: '35px', lg: '40px', xl: '45px' }, fontFamily: 'GothamBold !important' }}>Create Profile</Typography>
                          </Box>
                          <Box 
                              // className="steps-bar"
                              sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(2, 1fr)',
                                gridGap: '1rem',
                                margin: '0.5rem 0',
                                width: '40%',
                              }}
                          >
                              <Box

                                sx={{
                                  width: '100',
                                  height: '4px',
                                  backgroundColor: '#faa61a'
                                }}
                              ></Box>
                              <Box

                                sx={{
                                  width: '100',
                                  height: '4px',
                                  backgroundColor: '#faa61a'
                                }}
                              ></Box>
                          </Box>
                          <Box className="tellus-text">
                              <Typography sx={{ color: '#faa61a !important', fontSize: { xs: '14px', sm: '16px', md: '18px', lg: '22px', xl: '26px' }, fontFamily: 'GothamBold !important' }}>Tell Us More About You</Typography>
                          </Box>
                          <Box 
                          // className="signup-fields"
                          >
                            <Box 
                                // className="input-div"
                                sx={{ margin: '1.2rem 0' }}
                            >
                                <Typography
                                  // className="labels"
                                  sx={{
                                    color: '#fff',
                                    marginBottom: '0.3rem'
                                  }}
                                >Name</Typography>
                              <Box 
                                  // className="input-grid"
                                  sx={{ display: 'grid', gridTemplateColumns: '1fr 0.5fr', gap: '1rem', width: '100%', alignItems: 'center', boxSizing: 'border-box' }}
                              >
                                <CssTextField
                                  id="login-fname"
                                  variant="outlined"
                                  placeholder="First Name"
                                  type="text"
                                  sx={{ width: "100%" }}
                                  onChange={HandleFName}
                                  value={FirstName}
                                />
                                <CssTextField
                                  id="login-mname"
                                  variant="outlined"
                                  placeholder="Middle Initial"
                                  type="text"
                                  sx={{ width: "100%" }}
                                  onChange={HandleMiddle} value={MiddleName}
                                />
                              </Box>
                                <Box
                                  // className="input-div"
                                  sx={{ width: '100%', margin: '1.2rem 0' }}
                                >
                                <CssTextField
                                  id="login-lname"
                                  variant="outlined"
                                  placeholder="Last Name"
                                  type="text"
                                  sx={{ width: "100%" }}
                                  onChange={HandleLName}
                                  value={LastName}
                                />
                              </Box>
                            </Box>

                              <Box
                                // className="input-div"
                                sx={{ width: '100%', margin: '1.2rem 0' }}
                              >
                                <Typography
                                  // className="labels"
                                  sx={{
                                    color: '#fff',
                                    marginBottom: '0.3rem'
                                  }}
                                >Company Name</Typography>
                                <Box sx={{ width: '100%' }}>
                                <CssTextField
                                  id="login-company"
                                  variant="outlined"
                                  placeholder="If Applicable"
                                  type="text"
                                  sx={{ width: "100%" }}
                                  onChange={HandleCompany}
                                  value={Company}
                                />
                              </Box>
                            </Box>

                              <Box
                                // className="input-div"
                                sx={{ margin: '1.2rem 0' }}
                              >
                                <Typography
                                  // className="labels"
                                  sx={{
                                    color: '#fff',
                                    marginBottom: '0.3rem'
                                  }}
                                >Address</Typography>
                                <Box
                                  // className="input-grid search-address-field"
                                  sx={{ width: '100%', display: 'flex', gap: '1rem' }}
                                >
                                <CssTextField
                                  id="login-address"
                                  variant="outlined"
                                  placeholder="Street, City, State, Zip"
                                  type="text"
                                  sx={{ width: "88%" }}
                                  // sx={{ width: "100%" }}
                                  onChange={(event) => {
                                    HandelAddress(event);
                                  }}
                                  value={Address}
                                />

                                <Box 
                                    // className="input-loc-icon" 
                                    sx={{
                                      backgroundColor: '#fff',
                                      height: 'auto',
                                      border: '2px solid #faa61a',
                                      borderRadius: '10px',
                                      display: 'flex',
                                      alignItems: 'stretch',
                                      justifyContent: 'center',
                                      width: "12%",
                                      cursor: "pointer"
                                    }}
                                  onClick={() => {
                                    setMapPopup(true);
                                    setsuggesaddress('')
                                  }} >
                                    <Box component={'img'} src={locationIcon} alt=""
                                      sx={{
                                        width: '25px',
                                        height: 'auto',
                                        objectFit: 'contain'
                                      }} />
                                </Box>
                              </Box>

                              {suggesaddress && (
                                  <Box
                                    // className="input-div"
                                    sx={{ margin: '1.2rem 0' }}
                                  >
                                  <span
                                    onClick={() => {
                                      UpdateAddress();
                                    }}
                                    className="address-suggesion"
                                  >
                                    {suggesaddress}
                                  </span>
                                </Box>
                              )}

                                <Box
                                  // className="input-div"
                                  sx={{ margin: '1.2rem 0' }}
                                >
                                <CssTextField
                                  id="login-additional-address"
                                  variant="outlined"
                                  placeholder="Additional address info (Optional)"
                                  type="text"
                                  sx={{ width: "100%" }}
                                  onChange={HandleAaddress}
                                  value={Aaddress}
                                />
                              </Box>
                            </Box>

                              <Box
                                // className="input-div"
                                sx={{ margin: '1.2rem 0' }}
                              >
                                <Typography
                                  // className="labels"
                                  sx={{
                                    color: '#fff',
                                    marginBottom: '0.3rem'
                                  }}
                                >Phone Number</Typography>
                              <Box>
                                <CssTextField
                                  id="login-phone"
                                  variant="outlined"
                                  placeholder="XXX-XXX-XXXX"
                                  type="tel"
                                  sx={{ width: "100%" }}
                                  onChange={HandlePhone}
                                  value={Phone}
                                // inputProps={{
                                //   maxLength: 12,
                                // }}
                                />
                              </Box>
                            </Box>

                              <Box
                                // className="input-div" 
                                sx={{ margin: '1.2rem 0', display: 'flex', alignItems: 'center' }}>
                              <Checkbox
                                sx={{
                                  "&:hover": { bgcolor: "transparent" },
                                }}
                                defaultChecked={Terms == 'yes' ? true : false}
                                onChange={HandleTerms}
                                disableRipple
                                color="default"
                                checkedIcon={<BpCheckedIcon />}
                                icon={<BpIcon />}
                                inputProps={{ "aria-label": "Checkbox demo" }}
                              />

                              {/* <label className="agree-label" style={{ display: 'flex', alignItems: 'center', gap: '4px', width: 'max-content' }}>I agree to the
                                <Link href="/terms_and_conditions" target="_blank" className='' sx={{ display: 'flex', color: '#faa61a !important', textDecorationColor: '#faa61a !important' }}>
                                  <span>
                                    Terms of Service and Privacy Policy.
                                  </span>
                                </Link>
                              </label> */}

                                <Typography sx={{ color: '#fff', display: 'flex', alignItems: 'baseline', flexWrap: 'wrap' }}>I agree to the <Link href="/terms_and_conditions" target="_blank" className='' sx={{ display: 'flex', color: '#faa61a !important', flexWrap: 'wrap', textDecorationColor: '#faa61a !important', }}>
                                  <span style={{ marginLeft: '4px', wordBreak:'break-word' }}>
                                    Terms of Service and Privacy Policy.
                                  </span>
                                </Link></Typography>

                            </Box>

                              <Box
                                // className="next-btn-div"
                                sx={{ mb: 1.5, mt: 1.5 }}
                              >
                              <Button 
                                  sx={{
                                    fontSize: {
                                      xs: '20px',
                                      sm: '22px',
                                      md: '24px',
                                      lg: '26px',
                                      xl: '28px'
                                    },
                                    backgroundColor: '#149248',
                                    fontFamily: 'Montserrat',
                                    fontWeight: '700',
                                    borderRadius: '10px',
                                    color: '#fff',
                                    boxShadow: 'none',
                                    padding: '0.5rem 3rem',
                                    "&:hover": {
                                      background: '#149248'
                                    }
                                  }}
                              // className="next-btn"
                                //onClickCapture={UpdateProfile}
                                onClickCapture={ValidateProfile}
                              >Finish!</Button>
                            </Box>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Container>
          </Box>

        </>
      )}


      {/* Google map Popup */}
      <Modal
        keepMounted
        open={MapPopup}
        onClose={() => dispatch(setLoginModalOpen(false))}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            width: matchesMobileX ? 'calc(100vw - 100px)' : 800,
            padding: "0px",
          }}>

          <IconButton onClick={() => setMapPopup(false)} 
          // sx={{ position: 'absolute', right: 0, top: 0, color: '#faa61a' }}
            sx={{
              position: "absolute",
              top: "-20px",
              right: "-20px",
              bgcolor: "#faa61a",
              color: "#000",
              "&:hover": {
                bgcolor: "#faa61a",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <>
            <Box className="login__main">
              <Box className="login-main-div location-modal">
                <Box className="login-main-inner">
                  <Box className="login-fields-div ">
                    {MapPopup && (
                      <GoogleautoComplete
                        onPress={(center, address) => {
                          // console.log('center',center);                            
                          setdefaultLocation(center);
                        }}
                        inputRefs={inRef}
                        searchRef={searchRef}
                        equipLocation={defaultNewLocation}
                      />
                    )}
                    <Box
                      // className="next-btn-div location-btn-grp"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                        justifyContent: 'flex-end',
                        mt: 2,
                        mb: 1
                      }}
                    >
                      <Button
                        // className="next-btn cancel-btn-2"
                        // className="next-btn cancel-btn-2"
                        sx={{
                          fontSize: {
                            xs: '14px',
                            sm: '16px',
                            md: '18px',
                            lg: '18px',
                            xl: '20px'
                          },
                          backgroundColor: '#faa61a',
                          fontFamily: 'Montserrat',
                          fontWeight: '700',
                          borderRadius: '10px',
                          color: '#fff',
                          boxShadow: 'none',
                          padding: '0.2rem 1rem',
                          "&:hover": {
                            background: '#faa61a'
                          }
                        }}
                        onClickCapture={() => {
                          setMapPopup(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        // className="next-btn ok-btn"
                        sx={{
                          fontSize: {
                            xs: '14px',
                            sm: '16px',
                            md: '18px',
                            lg: '18px',
                            xl: '20px'
                          },
                          backgroundColor: '#faa61a',
                          fontFamily: 'Montserrat',
                          fontWeight: '700',
                          borderRadius: '10px',
                          color: '#fff',
                          boxShadow: 'none',
                          padding: '0.2rem 1rem',
                          "&:hover": {
                            background: '#faa61a'
                          }
                        }}
                        onClickCapture={() => {
                          getFullAddress();
                        }}
                      >
                        Ok
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        </Box>
      </Modal>

      {/* after completion */}
      {/* <Modal
        keepMounted
        open={FirsttimePopup}
        onClose={() => setFirsttimePopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            width: matchesMobileX ? 'calc(100vw - 100px)' : 800,
            padding: "0px",
          }}>

          <>
            <Box className="login__main">
              <Box className="login-main-inner">
                <Box className="login-fields-div">
                  <Box className="after-msg">
                    <Box className="after-msg-inner">
                      <Typography
                        variant="h4"
                        className="welcome-heading"
                        sx={{ textAlign: "center", color: "#fff",
                          fontSize: 'clamp(24px, 3vw, 32px) !important',
                      }}
                      >
                        Welcome, {FirstName}!
                      </Typography>

                      <Typography className="first-para">
                        Thank you for being part of the Evolution of Equipment Rentals.
                      </Typography>

                      <Typography className="second-para">
                        Use the Owners to manage your rentals and listings.
                        Remember that GoEquipMe is available as an app for your Android or
                        iPhone. <Link className="clickhere-link" target="_blank"
                          href={OnelinkQaCode}>Click here</Link>.
                      </Typography>

                      <Box className="live-help-sec">
                        <img src={HelpLogo} alt="" />
                        <Typography>
                          If you have questions or need help, just click on the LIVE HELP
                          button!
                        </Typography>
                      </Box>

                      <Box
                        className="close"
                      // onClick={() => (window.location.href = "/")}
                      >
                        <Box className="close-content" onClick={() => (window.location.href = "/")}>
                          <img src={cross} alt="" />
                          <Typography>Close</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                </Box>
              </Box>
            </Box>
          </>
        </Box>
      </Modal> */}


      {/* Text notification popup */}
      <Box className='text-notification'>
        <BootstrapDialog
          //onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={NotificationSettingPopup}
          className='tn-popup'
        >
          <TxtNotification onClose={(result) => {
            if (result == "Accept") {
              UpdateProfile(true)
            } else {
              UpdateProfile(false)
            }
            setNotificationSettingPopup(false)
          }} />
        </BootstrapDialog>
      </Box>

    </MainLayout>
  );
}

export default SignUp