import React, { useRef, useState } from "react";
import { GoogleMap, LoadScript, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import { TextField, FormControl, Box } from '@mui/material';
import Geocode from "react-geocode";
import { useEffect } from "react";
import styled from "@emotion/styled";
const containerStyle = {
  width: '100%',
  height: '450px'
};

// by GD to resolve error of loadScript
type GoogleMapsLibrary = "places" | "drawing" | "geometry" | "localContext" | "visualization";
const GOOGLE_MAPS_LIBRARIES: GoogleMapsLibrary[] = ['places'];
// end by GD
const GoogleautoComplete = ({ onPress, inputRefs, searchRef, equipLocation = null, equipAddress = null }) => {
  const APIKEY = process.env.REACT_APP_GEOCODDER_KEY
  const GEOAPIKEY = process.env.REACT_APP_GEOCODDER_KEY
  Geocode.setApiKey(GEOAPIKEY);
  const markerRef = useRef();
  const defaultLocation = { lat: 39.321980897034734, lng: -111.10149320091872 };
  const [center, setCenter] = useState(defaultLocation);
  const [Markercenter, setMarkercenter] = useState(defaultLocation);
  const [addressfield, setaddressfield] = useState('');
  const [suggesaddress, setsuggesaddress] = useState('');
  const [addresslocation, setaddresslocation] = useState(null);
  const [LocationAddress, setLocationAddress] = useState(null);
  const [FullAddress, setFullAddress] = useState({
    "city": "",
    "state": "",
    "country": "",
    "postal_code": "",
    "address": "",
  });

  useEffect(() => {
    if (equipLocation && equipLocation.lat && equipLocation.lng) {
      setsuggesaddress('')
      setMarkercenter({ lat: equipLocation.lat, lng: equipLocation.lng })
      setCenter({ lat: equipLocation.lat, lng: equipLocation.lng })
      getFullAddress({ lat: equipLocation.lat, lng: equipLocation.lng })
    }

    // console.log("equipAddress: " , equipAddress);

    if (equipAddress || equipAddress == "") {
      setsuggesaddress('')
      setaddressfield(equipAddress);
    }
  }, [equipLocation, equipAddress])

  const HandelAddress = (e) => {
    setFullAddress({
      "city": "",
      "state": "",
      "country": "",
      "postal_code": "",
      "address": "",
    })
    setaddressfield('')

    let name = e.target.value;
    setaddressfield(name)
    if (name || name.length > 0) {
      setsuggesaddress('')
      const xhr = new XMLHttpRequest();
      xhr.open('GET', 'https://maps.googleapis.com/maps/api/geocode/json?address=' + name + '&key=' + GEOAPIKEY);
      xhr.onload = function () {

        if (xhr.status === 200) {
          onPress({ lat: null, lng: null }, '')
          //setsuggesaddress('')
          let result = JSON.parse(xhr.response);

          if (result['results'].length > 0) {
            let address = result['results'][0]['formatted_address'];

            let city, state, country, short_state, postal_code;
            for (let i = 0; i < result['results'][0].address_components.length; i++) {
              for (let j = 0; j < result['results'][0].address_components[i].types.length; j++) {
                switch (result['results'][0].address_components[i].types[j]) {
                  case "locality":
                    city = result['results'][0].address_components[i].long_name;
                    break;
                  case "administrative_area_level_1":
                    state = result['results'][0].address_components[i].long_name;
                    break;
                  case "country":
                    country = result['results'][0].address_components[i].long_name;
                    break;
                  case "postal_code":
                    postal_code = result['results'][0].address_components[i].short_name;
                    break;
                }
              }
            }
            let full_address = {
              "city": city,
              "state": state,
              "country": country,
              "postal_code": postal_code,
              "address": address
            };

            // console.log("full_address: " , full_address);
            setFullAddress(full_address)
            setLocationAddress(full_address)

            let location = result['results'][0]['geometry']['location'];
            setsuggesaddress(address)
            setaddresslocation(location)
          }
          else {
            onPress({ lat: null, lng: null }, '')
            setsuggesaddress('')
          }
        } else {
          onPress({ lat: null, lng: null }, '')
          setsuggesaddress('')
        }
      };
      xhr.send();
    }
    else {
      //   setaddresslocation(null);
      //   console.log('addresslocation',addresslocation);
      onPress({ lat: null, lng: null }, '')
      setsuggesaddress('')
    }
  }

  const UpdateAddress = () => {
    let new_address = LocationAddress
    setFullAddress(new_address)
    setLocationAddress(null)

    setaddressfield(suggesaddress)
    setCenter(addresslocation)
    setMarkercenter(addresslocation)
    onPress(addresslocation, FullAddress)
    setsuggesaddress('')
    setaddresslocation({})
  }

  const [mapref, setMapRef] = React.useState(null);
  const handleOnLoad = map => {
    setMapRef(map);
  };

  const handleCenterChanged = () => {
    if (mapref) {
      const newCenter = mapref.getCenter();
      setMarkercenter({
        lat: newCenter.lat(),
        lng: newCenter.lng()
      });
      onPress(Markercenter, FullAddress)
      getFullAddress(Markercenter)
    }
  };

  const getFullAddress = async (center) => {
    Geocode.fromLatLng(center.lat, center.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setaddressfield(address);

        let city, state, country, short_state, postal_code;
        for (let i = 0; i < response.results[0].address_components.length; i++) {
          for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
              case "postal_code":
                postal_code = response.results[0].address_components[i].short_name;
                break;
            }
          }
        }
        let full_address = {
          "city": city,
          "state": state,
          "country": country,
          "postal_code": postal_code,
          "address": address
        };
        setFullAddress(full_address)
        onPress(center, address)
      },
      (error) => {
        console.error(error);
        let full_address = {
          "city": '',
          "state": '',
          "country": '',
          "postal_code": '',
          "address": ''
        };
        setFullAddress(full_address)
        setaddressfield('');
      }
    );
  }

  const CssTextField = styled(TextField)({
    "& .MuiInputBase-root": {
      color: "#2e2e2e",
      background: "#fff",
      fontFamily: "GothamBold !important",
      fontSize: "clamp(12px, 3vw, 15px) !important",
      borderRadius: "10px !important",
      // border: "2px solid #faa61a",
    },
    "& .MuiInputBase-input": {
      // height: '28px'
    },
    "& label.Mui-focused": {
      color: "#faa61a",
      fontWeight: "bold",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#2e2e2e",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "2px solid #faa61a",
      },
      "&:hover fieldset": {
        borderColor: "#2e2e2e",
        border: "2px solid #faa61a",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#faa61a",
      },
    },
  });

  return (
    <>
      <FormControl fullWidth className="field-padding">
        <TextField
          id="outlined-basic"
          // label="Street Address, City, State, ZIP"
          variant="outlined"
          onChange={(event) => {
            HandelAddress(event);
          }}
          className="google-address-field"
          placeholder={"Street Address, City, State, ZIP"}
          value={addressfield}
          sx={{
            "& .MuiInputBase-root": {
              color: "#000",
              background: "#fff",
              // height: "3rem !important",
              fontFamily: "GothamBold !important",
              fontSize: "clamp(10px, 3vw, 16px) !important",
            },
            color: "#adadad",
            "& .MuiFormLabel-root": {
              color: "#adadad",
            },
            "& label.Mui-focused": {
              color: "#000",
              fontWeight: "bold",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "#2e2e2e",
            },

            "& .MuiOutlinedInput-root": {
              "&.MuiFormControl-root-MuiTextField-root .MuiFormLabel-root": {
                color: "#000 !important",
              },
              "& fieldset": {
                borderColor: "#faa61a !important",
                // backgroundColor: "#fff",
                color: "#faa61a !important",
              },
              "&:hover fieldset": {
                borderColor: "#2e2e2e",
                border: "2px solid #faa61a",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#faa61a",
                backgroundColor: "transparent",
                color: "#faa61a !important",
              },
            },
          }}
        />
        {suggesaddress && (
          <span
            onClick={() => {
              UpdateAddress();
            }}
            className="address-suggesion"
          >
            {suggesaddress}
          </span>
        )}
      </FormControl>

      {/* by GD added libraries={GOOGLE_MAPS_LIBRARIES} to resolve error of load script */}
      <LoadScript googleMapsApiKey={APIKEY} libraries={GOOGLE_MAPS_LIBRARIES}>
        {/* <LoadScript googleMapsApiKey={APIKEY} libraries={['places']}>        */}
        <Box className="google-map-section" style={{ marginTop: "20px" }}>
          <GoogleMap
            center={center}
            zoom={12}
            onLoad={handleOnLoad}
            onDragEnd={handleCenterChanged}
            ref={inputRefs}
            mapContainerStyle={containerStyle}
          >
            <Marker
              ref={markerRef}
              position={Markercenter}
              draggable={false}
              onDragEnd={(coord) => {
                const { latLng } = coord;
                const lat = latLng.lat();
                const lng = latLng.lng();
                setCenter({
                  lat: lat,
                  lng: lng,
                });
                onPress(center, FullAddress);
                setsuggesaddress('')
              }}
            />
          </GoogleMap>
        </Box>
      </LoadScript>
    </>
  );
};
export default GoogleautoComplete;