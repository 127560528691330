import { Box, Divider } from '@mui/material'
import React from 'react'
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const TextNotificationPopup = ({onClose}) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = (type=null) => {
        onClose(type);
    };


  return (
      <>
    {/* //   <Box className='text-notification'>
    //       <Button variant="contained" onClick={handleClickOpen} className='text-notification-btn'>
    //           Open dialog
    //       </Button>
    //       <BootstrapDialog
    //           onClose={handleClose}
    //           aria-labelledby="customized-dialog-title"
    //           open={open}
    //           className='tn-popup'
              
    //       > */}
              <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center', color: "#faa61a !important", fontFamily:'GothamBold !important', fontSize: "30px !important" }} id="customized-dialog-title">
                  Text Notifications
              </DialogTitle>
              <Divider sx={{bgcolor: "#fff", height: "2px", width: "50%", margin: 'auto'}}/>
              <DialogContent>
                  <Typography gutterBottom className='text-notification-p'>
                      <span style={{ color: "#faa61a" }}>GoEquipMe</span> will send <span style={{ color: "#faa61a" }}>text notifications</span> to apprise users of key rental information, such as but not limited to rental requests, rental confirmation, order summaries and more. By submitting this form and signing up for texts, you consent to receive notification messages from <span style={{ color: "#faa61a" }}>GoEquipMe Inc</span> at the number provided. Msg & data rates may apply. Unsubscribe at any time by replying <span style={{ color: "#faa61a" }}>STOP</span> or clicking the unsubscribe link (where available).
                  </Typography>
                  
              </DialogContent>
              <DialogActions>
                <Box className="tn-btngrp">
                    <Button className="decline-btn"
                    onClick={()=>{
                        handleClick('Decline')
                    }}>
                        Decline
                    </Button>
                    <Button className='accept-btn'
                    onClick={()=>{
                        handleClick('Accept')
                    }}>
                        Accept
                    </Button>
                </Box>                  
              </DialogActions>
    {/* //       </BootstrapDialog>
    //   </Box> */}
    </>
  )
}

export default TextNotificationPopup
