import { Backdrop, Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, IconButton, MenuItem, Modal, Select, TextField, Typography, styled, useMediaQuery } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close'
import { useEffect, useState } from "react";
import { newPhoneFormat } from "utils/commonFxns"

const loginstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 4,
};

const CssTextField = styled(TextField)({
    "& .MuiInputBase-root": {
        color: "#2e2e2e",
        background: "#fff",
        fontFamily: "GothamBold !important",
        fontSize: "clamp(12px, 3vw, 15px) !important"
        // border: "2px solid #faa61a",
    },
    "& label.Mui-focused": {
        color: "#faa61a",
        fontWeight: "bold"
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#2e2e2e"
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "2px solid #faa61a"
        },
        "&:hover fieldset": {
            borderColor: "#2e2e2e",
            border: "2px solid #faa61a"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#faa61a"
        }
    }
});


const EditServiceDescriptionModal = ({ loading, serviceDescModalOpen, setServiceDescModalOpen, serviceCategories, serviceData, setServiceData, updateService }) => {
    const matchesMobileX = useMediaQuery('(max-width:425px)');
    // console.log("service data in edit api: ", serviceData);
    const [serviceDescriptionWords, setServiceDescriptionWords] = useState(1000 - (serviceData.service_description ? serviceData.service_description.length : 0))

    useEffect(() => {
        // console.log("edit Component Modal rendered");
    }, []);

    return (
        <Modal
            keepMounted
            open={serviceDescModalOpen}
            onClose={() => setServiceDescModalOpen(false)}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box
                className="download-popups"
                sx={{
                    ...loginstyle,
                    width: matchesMobileX ? 'calc(100vw - 100px)' : 800,
                    padding: "0px",
                }}>

                <IconButton
                    className="cross-btn"
                    onClick={() => setServiceDescModalOpen(false)}
                >
                    <CloseIcon />
                </IconButton>
                <Box className="login__main">
                    <Box className="login-main-inner">
                        <Box className="login__main-div__form popup-cmmn popup-white edit-eqp-inner">
                            {loading && (
                                <Box>
                                    <Backdrop
                                        sx={{
                                            color: "#000",
                                            zIndex: (theme) => theme.zIndex.drawer - 1,
                                        }}
                                        open={loading}
                                    >
                                        <CircularProgress color="inherit" />
                                    </Backdrop>
                                </Box>
                            )}
                            <Box>
                                {/* comapany Name set */}
                                <FormControl
                                    fullWidth
                                    className="form-fields list-eqp-form-fields"
                                >
                                    <label className="list-eqp-input-label">
                                        Company Name
                                    </label>
                                    <CssTextField
                                        onChange={(e) => setServiceData({ ...serviceData, company_name: e.target.value })}
                                        id="outlined-basicd"
                                        // label="Listing Title"
                                        variant="outlined"
                                        value={serviceData.company_name}
                                        placeholder="your company name (required)"
                                    />
                                </FormControl>

                                {/* for service category select */}
                                <FormControl
                                    sx={{ width: "100%" }}
                                    className="form-fields list-eqp-form-fields"
                                >
                                    <label className="list-eqp-input-label">Category</label>
                                    <Select
                                        value={serviceData.service_category}
                                        onChange={(e) => setServiceData({ ...serviceData, service_category: e.target.value })}
                                        displayEmpty
                                        inputProps={{ "aria-label": "Without label" }}
                                        sx={{
                                            "& .MuiSelect-select": {
                                                color: "#2D2D2D !important",
                                                fontFamily: "GothamBold !important",
                                            },
                                        }}
                                        style={{
                                            backgroundColor: "#fff",
                                            border: "2px solid #FAA61A",
                                            borderRadius: "10px",
                                        }}
                                        className="select-dropdwn"
                                    >
                                        <MenuItem value="" className="category-dropdown">
                                            Select Here
                                        </MenuItem>
                                        {serviceCategories.map((item, index) => (
                                            <MenuItem
                                                key={item.id} value={item.title}
                                                className="list-eqp-select-options"
                                            >
                                                {item.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {/* key search terms */}
                                <FormControl
                                    fullWidth
                                    className="form-fields list-eqp-form-fields"
                                >
                                    <label className="list-eqp-input-label">
                                        Search Terms
                                    </label>
                                    <CssTextField
                                        onChange={(e) => setServiceData({ ...serviceData, key_description_search: e.target.value })}
                                        value={serviceData.key_description_search ? serviceData.key_description_search : ''}
                                        id="outlined-basic"
                                        // label="Make & Model"
                                        variant="outlined"
                                        placeholder="Key Search Terms"
                                    />
                                </FormControl>

                                {/* hide my cation checkBox */}
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                    <FormControlLabel
                                        className='hide-checkbox'
                                        control={<Checkbox
                                            sx={{
                                                "&:hover": { bgcolor: "transparent", color: '#fff !important', fontFamily: 'GothamBold !important' },
                                            }}
                                            checked={serviceData.location_status == 1 ? true : false}
                                            onChange={(e) => setServiceData({ ...serviceData, location_status: e.target.checked ? 1 : 0 })}
                                            disableRipple
                                            color="default"
                                            // checkedIcon={<BpCheckedIcon />}
                                            // icon={<BpIcon />}
                                            inputProps={{ "aria-label": "Checkbox demo" }}
                                        />} label="Hide my Location"
                                    />
                                </Box>

                                <Typography className="list-eqp-white-p" sx={{ marginBottom: '1rem', color: '#fff !important', fontSize: 'clamp(16px, 3vw, 20px) !important' }}>
                                    Optional info to publish
                                </Typography>

                                {/* phoneVoice field */}
                                <FormControl
                                    fullWidth
                                    className="form-fields list-eqp-form-fields"
                                >
                                    <label className="list-eqp-input-label">
                                        Phone/Voice
                                    </label>
                                    <CssTextField
                                        onChange={(e) =>
                                            setServiceData({ ...serviceData, phone_voice: e.target.value.replace(/[^0-9]/g, '') })
                                        }
                                        // value={serviceData.phone_voice}
                                        value={serviceData.phone_voice ? newPhoneFormat(serviceData.phone_voice) : ''}
                                        id="outlined-basic"
                                        // label="Make & Model"
                                        variant="outlined"
                                        placeholder="Phone/Voice"
                                        inputProps={{
                                            maxLength: 12,
                                        }}
                                    />
                                </FormControl>

                                {/* phoneText field */}
                                <FormControl
                                    fullWidth
                                    className="form-fields list-eqp-form-fields"
                                >
                                    <label className="list-eqp-input-label">
                                        Phone/Text
                                    </label>
                                    <CssTextField
                                        onChange={(e) => setServiceData({ ...serviceData, phone_text: e.target.value.replace(/[^0-9]/g, '') })}
                                        // value={serviceData.phone_text}
                                        value={serviceData.phone_voice ? newPhoneFormat(serviceData.phone_text) : ''}
                                        id="outlined-basic"
                                        // label="Make & Model"
                                        variant="outlined"
                                        placeholder="Phone/Text"
                                        inputProps={{
                                            maxLength: 12,
                                        }}
                                    />
                                </FormControl>

                                {/* Email field*/}
                                <FormControl
                                    fullWidth
                                    className="form-fields list-eqp-form-fields"
                                >
                                    <label className="list-eqp-input-label">
                                        Email
                                    </label>
                                    <CssTextField
                                        type='email'
                                        onChange={(e) => setServiceData({ ...serviceData, email: e.target.value })}
                                        value={serviceData.email ? serviceData.email : ''}
                                        id="outlined-basic"
                                        // label="Make & Model"
                                        variant="outlined"
                                        placeholder="Email"
                                    />
                                </FormControl>

                                {/* website field */}
                                <FormControl
                                    fullWidth
                                    className="form-fields list-eqp-form-fields"
                                >
                                    <label className="list-eqp-input-label">
                                        Website
                                    </label>
                                    <CssTextField
                                        onChange={(e) => setServiceData({ ...serviceData, website: e.target.value })}
                                        value={serviceData.website ? serviceData.website : ''}
                                        id="outlined-basic"
                                        // label="Make & Model"
                                        variant="outlined"
                                        placeholder="website"
                                    />
                                </FormControl>

                                {/* serviceDescription */}
                                <FormControl
                                    fullWidth
                                    className="form-fields list-eqp-form-fields new-textarea"
                                >
                                    <label className="list-eqp-input-label">
                                        Description
                                    </label>
                                    <CssTextField
                                        onChange={(e) => {
                                            setServiceData({ ...serviceData, service_description: e.target.value })
                                            setServiceDescriptionWords(1000 - (e.target.value.length));
                                        }}
                                        value={serviceData.service_description ? serviceData.service_description : ''}
                                        id="outlined-basic"
                                        // label="Make & Model"
                                        variant="outlined"
                                        placeholder="Service Description "
                                        multiline
                                        rows={4}
                                        sx={{ borderRadius: '10px !important' }}
                                        inputProps={{
                                            maxLength: 1000,
                                        }}
                                    />
                                </FormControl>
                                <Typography sx={{ display: 'flex', alignItems: 'baseline', color: '#fff', fontFamily: 'GothamBold !important' }}>
                                    <span style={{ color: '#fff !important', fontFamily: 'GothamBold !important' }}>
                                        Characters Left :
                                    </span>
                                    <span style={{ color: '#faa61a !important', fontFamily: 'GothamBold !important' }}>
                                        {serviceDescriptionWords}
                                    </span>

                                </Typography>

                            </Box>
                            <Box className="">

                                <Box className="">
                                    <Typography
                                        variant="h4"
                                        className="welcome-heading"
                                        sx={{ textAlign: "center", color: "#fff" }}
                                    >
                                    </Typography>

                                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', justifyContent: 'center', alignItems: 'center', gap: '2rem', marginTop: '1.5rem', marginBottom: '0.5rem' }}>
                                        <Button onClick={() => setServiceDescModalOpen(false)} sx={{ borderRadius: '7px', width: '100%', padding: '0.5rem 1rem', background: '#faa61a !important', fontSize: '18px !important', color: '#fff', textTransform: 'capitalize', fontFamily: 'GothamBold !important' }}>Cancel</Button>
                                        <Button sx={{ borderRadius: '7px', fontSize: '18px !important', padding: '0.5rem 1rem', width: '100%', background: '#149247 !important', color: '#fff', textTransform: 'capitalize', fontFamily: 'GothamBold !important' }}
                                            onClick={() => {
                                                console.log("update Working");
                                                updateService();
                                            }}
                                        >
                                            Update
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
};

export default EditServiceDescriptionModal;