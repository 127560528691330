import { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OrderDetailComponent from "components/common/OrderDetailsComponent";
import VieworderDetail from "components/OrderDetail/VieworderDetail";
import { EquipmentAPIs } from "../../../service/api-service";
import { IStoreValue } from "utils/types";
import { useSelector, useDispatch } from "react-redux";
import { setalertMessage, setalertMessagetype } from "redux/redux-slice";
import { convertTimeZoneShortName } from "utils/commonFxns";
import momentTimeZone from "moment-timezone";
import Img from "./../../../assets/images/dummy-profile-pic.jpeg";
import Orderdetailimg from "assets/images/details-page/booking2.png";
const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4,
};
const OrderDetailPopup = ({
  orderDetailPopup,
  handleOrderDetailPopupClose,
  detailModalData,
  onCancelConfirmation,
}) => {
  let timeZone: string = "UTC";
  const dispatch = useDispatch();
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const [loading, setLoading] = useState(false);
  const [orderDetailPopupData, setOrderDetailPopData] = useState([]);
  const [orderDetailPopupDataRenter, setOrderDetailPopupDataRenter] = useState({
    thumbnail: "",
    id: "",
    make: "",
    model: "",
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    profile_pic: "",
    first_name: "",
    order_status: "",
    order_from: "",
    rental_price: 0,
    minimum_delivery_fees: 0,
    security_deposit_refund_txt: "",
    security_deposit: 0,
    grand_total_txt: "",
    grand_total: 0,
    protection_fee: 0,
    transport_fee: 0,
    admin_fee: 0,
    side: "renter",
    timeZone: "UTC",
    owner_name: "",
    renter_name: "",
    owner_profile_pic: "",
    refunded_protection_fee: 0,
  });
  const [extendedOrder, setExtendOrder] = useState([]);

  const HandleOrderDetail = async (orderObj: any) => {
    setLoading(true);
    let fname = orderObj["first_name"] ? orderObj["first_name"] : "NA";
    let lname = orderObj["last_name"] ? orderObj["last_name"] : "NA";

    let devilry = orderObj["delivery_charges"] * 2;
    let minimun = orderObj["minimum_delivery_fees"];
    let totalMi = devilry + minimun;
    let _changeInTotal = (totalMi / 100) * 10;

    let returnDepo =
      orderObj["returnd_deposit"] != undefined ||
      orderObj["returnd_deposit"] != null
        ? orderObj["returnd_deposit"]
        : null;

    //let rent_price = orderObj['price'];
    let rent_price =
      orderObj["price"] != undefined || orderObj["price"] != null
        ? orderObj["price"]
        : 0;

    let security_depo =
      returnDepo == orderObj["security_deposit"]
        ? 0
        : returnDepo == null
        ? orderObj["security_deposit"]
        : orderObj["security_deposit"] - returnDepo;

    let transport_fees =
      orderObj["delivery_charges"] * 2 + orderObj["minimum_delivery_fees"];

    let refunded_protection =
      orderObj["refunded_protection"] != undefined ||
      orderObj["refunded_protection"] != ""
        ? orderObj["refunded_protection"]
        : "";

    let protection_fees =
      orderObj["protection_fees_refunded"] == "1"
        ? orderObj["equipment_protection_fees"] -
          (refunded_protection == ""
            ? orderObj["equipment_protection_fees"]
            : refunded_protection)
        : orderObj["equipment_protection_fees"];

    let grand_total =
      orderObj["protection_fees_refunded"] != "1"
        ? orderObj["admin_commission"] != null
          ? orderObj["total_price"] +
            orderObj["equipment_protection_fees"] +
            _changeInTotal +
            orderObj["admin_commission"]
          : // -
            // (orderObj['order_status'] == "completed"
            //   ? orderObj['security_deposit']
            //   : 0)
            orderObj["total_price"] + orderObj["equipment_protection_fees"]
        : orderObj["admin_commission"] != null
        ? orderObj["total_price"] -
          (orderObj["order_status"] == "order_canceled"
            ? orderObj["security_deposit"]
            : 0) +
          _changeInTotal +
          orderObj["admin_commission"] +
          (orderObj["protection_fees_refunded"] == "1"
            ? orderObj["equipment_protection_fees"] -
              (refunded_protection == ""
                ? orderObj["equipment_protection_fees"]
                : refunded_protection)
            : 0)
        : orderObj["total_price"];

    /*****************/
    //added by pp 0n 25 apr 2024 due to timezone changes
    // if (!timeZone) {
    //   const headers = {
    //     Authorization: `${AuthToken}`,
    //     request_from: "Web",
    //   };
    //   const timeZoneData = await EquipmentAPIs.getEquipTimeZone(
    //     { equip_id: orderObj["id"] },
    //     headers
    //   );
    //   if (timeZoneData.type === "RXSUCCESS") {
    //     timeZone = timeZoneData.data;
    //   }
    // }
    // end by pp
    console.log("protection_fee", protection_fees);
    setOrderDetailPopupDataRenter({
      thumbnail: orderObj["thumbnail"],
      id: "Order ID #" + orderObj["order_id"],
      make: orderObj["make"] ? orderObj["make"] : "NA",
      model: orderObj["model"] ? orderObj["model"] : "",
      start_date: orderObj["start_date"],
      start_time: orderObj["start_time"],
      end_date: orderObj["end_date"],
      end_time: orderObj["end_time"],
      profile_pic: orderObj["profile_pic"] ? orderObj["profile_pic"] : Img,
      first_name: fname + " " + lname,
      order_status: orderObj["order_status"],
      order_from: orderObj["delivery_address"] == "" ? "Renter" : "Owner",
      rental_price: rent_price,
      minimum_delivery_fees: minimun,
      security_deposit_refund_txt:
        orderObj["returnd_deposit"] != undefined ||
        orderObj["returnd_deposit"] != null
          ? "(Refunded" +
            "-$" +
            new Intl.NumberFormat("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(orderObj["returnd_deposit"]) +
            ")"
          : "",
      security_deposit: security_depo,
      grand_total_txt:
        orderObj["order_status"] == "order_canceled" ? "(Refunded)" : "",
      grand_total: grand_total,
      protection_fee: orderObj["equipment_protection_fees"], //orderObj['equipment_protection_fees'],
      transport_fee: transport_fees,
      admin_fee: 0,
      side: "renter",
      owner_name: orderObj["owner_name"],
      renter_name: orderObj["renter_name"],
      owner_profile_pic: orderObj["owner_profile_pic"],
      timeZone: timeZone,
      refunded_protection_fee: orderObj["refunded_protection"],
    });

    // Check extended orders available or not
    // check if key exists
    const hasKey = "order_extend" in orderObj;
    if (hasKey) {
      const modifiendExtendedOrder = orderObj["order_extend"]?.map(
        (order: any) => {
          order.timeZone = timeZone;
          // order.order_status = orderObj["order_status"];
          return order;
        }
      );
      setExtendOrder(modifiendExtendedOrder);
    }
    // Extend order section end

    setLoading(false);
  };
  const getOrderDetails = async () => {
    try {
      setLoading(true);
      const { order_id, side, owner, renter, timezone } = detailModalData[0];
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const response = await EquipmentAPIs.getOrderDetails(
        {
          order_id: order_id?.toString(),
          type: side === "owner" ? "" : "owner",
          non_requests: "non_requests",
        },
        headers
      );
      if (response["type"] === "RXSUCCESS") {
        if (timezone) {
          timeZone = convertTimeZoneShortName(
            momentTimeZone().tz(timezone).zoneAbbr()
          );
        }
        if (side === "owner") {
          // console.log("response.data[0]",response.data[0]);
          setOrderDetailPopData([
            { ...response.data[0], first_name: renter, timeZone },
          ]);
        } else if (side === "renter") {
          HandleOrderDetail({ ...response.data[0], first_name: owner });
        }
      } else {
        throw new Error(response["message"]);
      }
    } catch (error) {
      dispatch(setalertMessage(error["message"]));
      dispatch(setalertMessagetype("error"));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (orderDetailPopup) {
      getOrderDetails();
    }
  }, [orderDetailPopup]);
  let modalType = "";
  if (detailModalData.length) {
    const { side } = detailModalData[0];
    modalType = side;
  }
  if (modalType === "owner") {
    return (
      <Modal
        keepMounted
        open={orderDetailPopup}
        onClose={handleOrderDetailPopupClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          // className="download-popups"
          // sx={{
          //   ...loginstyle,
          //   // width: matchesMobileX ? "calc(100vw - 100px)" : 800,
          //   padding: "0px",
          // }}

          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            //  overflowX:'hidden',
            bgcolor: "#2e2e2e",
            border: "5px solid #faa61a",
            borderRadius: "10px",
            outline: "none !important",
            // p: 1,
            width: {
              xs: "90%",
              sm: "400px",
              md: "500px",
              lg: "500px",
              xl: "500px",
            },
          }}
        >
          <IconButton
            onClick={handleOrderDetailPopupClose}
            sx={{
              position: "absolute",
              top: "-20px",
              right: "-20px",
              bgcolor: "#faa61a",
              color: "#000",
              "&:hover": {
                bgcolor: "#faa61a",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
          // className="login__main"
          >
            {/* <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            > */}
            <Box
              sx={{
                // width: {
                //   xs: "80%",
                //   sm: "400px",
                //   md: "500px",
                //   lg: "500px",
                //   xl: "500px",
                // },
                width: "100%",
                maxHeight: "600px",
                overflowY: "auto",
                p: 3,
                boxSizing: "border-box",
              }}
            >
              {/* <span onClick={handleOrderDetailPopupClose}>
                  <CloseIcon className="modal-popup" />
                </span> */}
              <Typography
                variant="h5"
                sx={{
                  color: "#fff",
                  // fontSize: {
                  //   xs: "18px",
                  //   sm: "24px",
                  //   md: "26px",
                  //   lg: "28px",
                  //   xl: "32px",
                  // },
                  fontWeight: "700",
                  fontFamily: "Montserrat !important",
                  mb: 2,
                }}
              >
                <Typography
                  variant="h5"
                  component={"span"}
                  sx={{
                    color: "#faa61a",
                    fontWeight: "700",
                    fontFamily: "Montserrat !important",
                  }}
                >
                  Order
                </Typography>
                Detail
              </Typography>

              <Box className="extend-rental-od">
                {loading && (
                  <Box>
                    <Backdrop
                      sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer - 1,
                      }}
                      open={loading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </Box>
                )}
                {orderDetailPopupData.length > 0 && (
                  <OrderDetailComponent
                    orderObject={orderDetailPopupData}
                    onSuccess={(data) => {
                      if (data && typeof data === "object") {
                        onCancelConfirmation(data);
                      }
                    }}
                  />
                )}
              </Box>
            </Box>
            {/* </Box> */}
          </Box>
        </Box>
      </Modal>
    );
  } else if (modalType === "renter") {
    return (
      <Modal
        keepMounted
        open={orderDetailPopup}
        onClose={handleOrderDetailPopupClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            //  overflowX:'hidden',
            bgcolor: "#2e2e2e",
            border: "5px solid #faa61a",
            borderRadius: "10px",
            outline: "none !important",
            // p: 1,
            width: {
              xs: "90%",
              sm: "400px",
              md: "500px",
              lg: "500px",
              xl: "500px",
            },
          }}
        >
          <IconButton
            onClick={handleOrderDetailPopupClose}
            sx={{
              position: "absolute",
              top: "-20px",
              right: "-20px",
              bgcolor: "#faa61a",
              color: "#000",
              "&:hover": {
                bgcolor: "#faa61a",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
          // className="login__main"
          >
            <Box
              sx={{
                // width: {
                //   xs: "80%",
                //   sm: "400px",
                //   md: "500px",
                //   lg: "500px",
                //   xl: "500px",
                // },
                width: "100%",
                maxHeight: "600px",
                overflowY: "auto",
                p: 3,
                boxSizing: "border-box",
              }}
              // className="login__main-div__form popup-cmmn popup-cmmn-modal pupup-scroll edit-eqp-inner"
            >
              <Box
                // className="new-modal-heading"
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  gap: "0.5rem",
                }}
              >
                <Box
                  component={"img"}
                  src={Orderdetailimg}
                  style={{ width: "22px", height: "auto" }}
                />

                <Typography
                  variant="h5"
                  sx={{
                    color: "#fff",
                    // fontSize: {
                    //   xs: "18px",
                    //   sm: "24px",
                    //   md: "26px",
                    //   lg: "28px",
                    //   xl: "32px",
                    // },
                    fontWeight: "700",
                    fontFamily: "Montserrat !important",
                    mb: 2,
                  }}
                >
                  <Typography
                    variant="h5"
                    component={"span"}
                    sx={{
                      color: "#faa61a",
                      fontWeight: "700",
                      fontFamily: "Montserrat !important",
                    }}
                  >
                    Order
                  </Typography>
                  Detail
                </Typography>
              </Box>

              <Box className="extend-rental-od">
                {loading && (
                  <Box>
                    <Backdrop
                      sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                      }}
                      open={loading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </Box>
                )}
                {orderDetailPopupDataRenter && (
                  <Box className="">
                    <VieworderDetail
                      OrderObj={orderDetailPopupDataRenter}
                      title={"no"}
                      ExtendedOrders={extendedOrder}
                      Success={(data) => {
                        if (data && typeof data === "object") {
                          if (
                            orderDetailPopupDataRenter?.id?.includes(
                              data?.orderId
                            )
                          ) {
                            const modifiedOrders = extendedOrder.map(
                              (order) => ({
                                ...order,
                                order_status: "order_canceled",
                              })
                            );
                            if (
                              JSON.stringify(modifiedOrders) !==
                              JSON.stringify(extendedOrder)
                            ) {
                              setExtendOrder(modifiedOrders);
                            }
                          } else {
                            const modifiedOrders = extendedOrder.map(
                              (order) => {
                                if (
                                  parseInt(data?.orderId) ===
                                  parseInt(order?.id)
                                ) {
                                  return {
                                    ...order,
                                    order_status: "order_canceled",
                                  };
                                }
                                return order;
                              }
                            );
                            if (
                              JSON.stringify(modifiedOrders) !==
                              JSON.stringify(extendedOrder)
                            ) {
                              setExtendOrder(modifiedOrders);
                            }
                          }
                          onCancelConfirmation(data);
                        }
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  }
};

export default OrderDetailPopup;
