import React from 'react';
import { Box } from '@mui/material';
import sectionBk from 'assets/images/hand_holding_cellphone.png'
import Typography from '@mui/material/Typography';
import SectionItem from './SectionItem';
import './styles.css'

const leftSectionItems = [
  'Create Your Own Rental Yard',
  'Owner Management Tools',
  'Equipment Damage Protection',
  'Liability Protection'
]

const rightSectionItems = [
  'Easy Scheduling',
  'List for Free',
  'Sharing Features',
  'Direct Messaging'
]

const DescriptionSection = () => {
  return (
    <Box className='section-container'>
      <Box className='section-top-border'/>
      <Box className='section-content-wrapper'>
        <img
          src={sectionBk}
          alt='Section Hand'
          className='img-hand-cellphone'
        />
        <Box className='section-content'>
          <Typography variant="h4" className='section-title'>A Rental Yard that brings equipment owners and  renters together… all in your back pocket.</Typography>
          <Box className='section-lists'>
            <Box className='list-left'>
              {leftSectionItems.map(item => (
                <SectionItem title={item} key={item} />
              ))}
            </Box>
            <Box className='list-right'>
              {rightSectionItems.map(item => (
                <SectionItem title={item} key={item} />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DescriptionSection;