import { Backdrop, Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, IconButton, MenuItem, Modal, Select, TextField, Typography, useMediaQuery } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close'
import { useEffect, useRef, useState } from "react";
import GoogleautoComplete from "../equipment-list/GoogleautoComplete";
import Geocode from "react-geocode";

const loginstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 4,
};
const defaultLocation = { lat: 39.321980897034734, lng: -111.10149320091872 };


const EditServiceLocationModal = ({ loading, serviceLocationModalOpen, setServiceLocationModalOpen, serviceData, setServiceData, updateService }) => {
    const inRef = useRef(null)
    const searchRef = useRef();
    const matchesMobileX = useMediaQuery('(max-width:425px)');
    console.log("service data in edit api: ", serviceData);
    const [FullAddress, setFullAddress] = useState({
        "address": "",
        "city": "",
        "state": "",
        "short_state": "",
        "postal_code": "",
        "country": "",
        "lat": "",
        "lng": "",
    });

    let defLat = parseFloat(serviceData.lat) || defaultLocation.lat;
    let defLng = parseFloat(serviceData.lng) || defaultLocation.lng;

    const [mapLocation, setMapLocation] = useState({
        lat: parseFloat(serviceData.latitude) || defLat,
        lng: parseFloat(serviceData.longitude) || defLng
    });

    // Get city state country address etc from geocode
    const getFullAddress = async (center) => {
        if (center.lat != null && center.lng != null) {
            // Use the Geocode API to fetch address details based on latitude and longitude.
            Geocode.fromLatLng(center.lat, center.lng).then(
                (response) => {
                    const address = response.results[0].formatted_address;
                    let city, state, country, short_state, postal_code;
                    // Extract address components from the Geocode API response.
                    for (let i = 0; i < response.results[0].address_components.length; i++) {
                        for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {

                            switch (response.results[0].address_components[i].types[j]) {
                                case "locality":
                                    city = response.results[0].address_components[i].long_name;
                                    break;
                                case "administrative_area_level_1":
                                    state = response.results[0].address_components[i].long_name;
                                    short_state = response.results[0].address_components[i].short_name;
                                    break;
                                case "country":
                                    country = response.results[0].address_components[i].long_name;
                                    break;
                                // case "administrative_area_level_1":
                                //   short_state = response.results[0].address_components[i].short_name;                
                                //   break;
                                case "postal_code":
                                    postal_code = response.results[0].address_components[i].short_name;
                                    break;
                            }
                        }
                    }
                    // Construct a full address object.
                    let full_address = {
                        "address": address,
                        "city": city,
                        "state": state,
                        "short_state": short_state,
                        "postal_code": postal_code,
                        "country": country,
                        "lat": center.lat,
                        "lng": center.lng,
                    };
                    // Update state variable with the full address.
                    setFullAddress(full_address)
                    console.log("full address: ", full_address);
                    setServiceData({
                        ...serviceData,
                        address: full_address.address,
                        city: full_address.city,
                        state: full_address.state,
                        short_state_name: full_address.short_state,
                        country: full_address.country,
                        latitude: full_address.lat,
                        longitude: full_address.lng
                    })
                },
                (error) => {
                    console.error(error);
                }
            );
        } else {
            // Handle the scenario when latitude or longitude is not provided.
            setFullAddress({
                "address": "",
                "city": "",
                "state": "",
                "short_state": "",
                "postal_code": "",
                "country": "",
                "lat": "",
                "lng": "",
            });
        }
    };

    useEffect(() => {
        console.log("edit Component location Modal rendered");
    }, []);

    return (
        <Modal
            keepMounted
            open={serviceLocationModalOpen}
            onClose={() => setServiceLocationModalOpen(false)}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box
                className="download-popups"
                sx={{
                    ...loginstyle,
                    width: matchesMobileX ? 'calc(100vw - 100px)' : 800,
                    padding: "0px",
                }}>

                <IconButton
                    className="cross-btn"
                    onClick={() => setServiceLocationModalOpen(false)}
                >
                    <CloseIcon />
                </IconButton>
                <Box className="login__main">
                    <Box className="login-main-inner">
                        <Box className="login__main-div__form popup-cmmn popup-white edit-eqp-inner">
                            {loading && (
                                <Box>
                                    <Backdrop
                                        sx={{
                                            color: "#000",
                                            zIndex: (theme) => theme.zIndex.drawer - 1,
                                        }}
                                        open={loading}
                                    >
                                        <CircularProgress color="inherit" />
                                    </Backdrop>
                                </Box>
                            )}

                            <Box className="">
                                <GoogleautoComplete
                                    onPress={(center) => {
                                        getFullAddress(center);
                                    }}
                                    inputRefs={inRef}
                                    searchRef={searchRef}
                                    equipLocation={mapLocation}
                                // equipAddress={FullAddressMap}
                                />
                                <Box className="">
                                    <Typography
                                        variant="h4"
                                        className="welcome-heading"
                                        sx={{ textAlign: "center", color: "#fff" }}
                                    >
                                    </Typography>

                                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', justifyContent: 'center', alignItems: 'center', gap: '2rem', marginTop: '1.5rem', marginBottom: '0.5rem' }}>
                                        <Button onClick={() => setServiceLocationModalOpen(false)} sx={{ borderRadius: '7px', width: '100%', padding: '0.5rem 1rem', background: '#faa61a !important', fontSize: '18px !important', color: '#fff', textTransform: 'capitalize', fontFamily: 'GothamBold !important' }}>Cancel</Button>
                                        <Button sx={{ borderRadius: '7px', fontSize: '18px !important', padding: '0.5rem 1rem', width: '100%', background: '#149247 !important', color: '#fff', textTransform: 'capitalize', fontFamily: 'GothamBold !important' }}
                                            onClick={() => {
                                                console.log("update Location Working");
                                                updateService();
                                            }}
                                        >
                                            Update
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
};

export default EditServiceLocationModal;