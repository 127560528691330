import { Box, Button, Typography } from '@mui/material'
import icon1 from '../../../assets/images/howitworks/icon-1.svg'
import icon2 from '../../../assets/images/howitworks/icon-2.svg'
import icon3 from '../../../assets/images/howitworks/icon-3.svg'
import dollarIcon from '../../../assets/images/go-dollar.png'
import './styles.scss'

const ListForSaleTab = () => {
    return (
        <Box>
            <Box>
                <Box sx={{ marginBottom: '2rem' }}>
                    <p className='tab-heading-2'>
                        Selling Equipment Is As Easy As...
                    </p>
                    <Box>
                        <Box className='tablike-content'>
                            <Box className='tab-grid'>
                                <Box>
                                    <Box className='tab-grid-heading'>
                                        <Box>
                                            <img src={icon1} alt="" className='tabicon' />
                                        </Box>
                                        <Typography>List</Typography>
                                    </Box>
                                    <Typography className='tab-grid-text'>
                                        List your equipment
                                        for free in as little as
                                        5 minutes.
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box className='tab-grid-heading'>
                                        <Box>
                                            <img src={icon2} alt="" className='tabicon' />
                                        </Box>
                                        <Typography>Approve</Typography>
                                    </Box>
                                    <Typography className='tab-grid-text'>
                                        Sit back and entertain
                                        offers. Close the deal
                                        with in-house
                                        financing or an RPO
                                        to earn even more.
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box className='tab-grid-heading'>
                                        <Box>
                                            <img src={icon3} alt="" className='tabicon' />
                                        </Box>
                                        <Typography>Sell</Typography>
                                    </Box>
                                    <Typography className='tab-grid-text'>
                                        GoEquipMe can help
                                        as much, or as little,
                                        as you would like to
                                        ensure your transac-
                                        tion goes smoothly.
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box className='tab-grid-heading'>
                                        <Box>
                                            <img src={dollarIcon} alt="" className='tabicon' />
                                        </Box>
                                        <Typography>Get Paid</Typography>
                                    </Box>
                                    <Typography className='tab-grid-text'>
                                        Sales proceeds
                                        deposited to your
                                        Wallet to withdraw at
                                        your convenience.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ListForSaleTab
