import { httpClient } from "./http-client";

let type = process.env.REACT_APP_PLATFORM;

// Staging
// let url = 'https://www.goequipmestaging.com:5000/'; //Staging
// let backend_url = 'https://www.goequipmeapis.com:8080/api/v1/';  // Staging

// if(type == "Live") {
//   // Live
//   url = 'https://goequipme.com:3000/'; // Live
//   backend_url = 'https://goequipmebackendlive.com:8080/api/v1/';   // Live
// }

// if(type == "PreLive") {
//   // Live
//   url = 'https://goequipme-prelive.com:8080/api/v1/'; // Live
//   backend_url = 'https://goequipme-prelive.com:8080/api/v1/';   // Live
// }

let url = process.env.REACT_APP_STAGING_URL;; //Staging
let backend_url = process.env.REACT_APP_STAGING_BACKEND_URL;;  // Staging

if (type == "PreLive") {
  // PreLive
  url = process.env.REACT_APP_PRELIVE_BACKEND_URL;; // PreLive
  backend_url = process.env.REACT_APP_PRELIVE_BACKEND_URL;;   // PreLive
}

if (type == "Live") {
  // Live
  url = process.env.REACT_APP_LIVE_URL; // Live
  backend_url = process.env.REACT_APP_LIVE_BACKEND_URL;;   // Live
}



export const EquipmentAPIs = {
  

  getAllCategories: async () => await httpClient.get(url+'categories'),
  getLocation: async (params: object) => await httpClient.post(url+'locations', params),

  postLoanEquipments: async (params: object) => await httpClient.post(url+'loan-equipments', params),
  // Get all Near equipments location based
  getNearEquipments: async (params: object) => await httpClient.post(url+'near-equipments', params),
  
  viewEquipment: async (params: object) => await httpClient.post(url+'view-equipment', params),

  // Send contact us mail
  sendContactMail: async (params: object) => await httpClient.post(url+'contact-email', params),
  // Get lat lng using zip code
  getlaglngZip: async (params: object) => await httpClient.post(url+'get-lat-lng', params),


  /*
   * New Version API
   */

  // Signip with email and password
  SignUp: async (params: object) => await httpClient.post(backend_url+'web-signup', params),
  // SignUp: async (params: object) => await httpClient.post(backend_url+'signup', params),  

  // Login with email and password
  userLogin: async (params: object) => await httpClient.post(backend_url+'web-login', params),
  // userLogin: async (params: object) => await httpClient.post(backend_url+'login', params),  

  // Resend email for mail verification
  ResendMail: async (params: object, header: object) => await httpClient.post(backend_url+'resend-user-verifications', params,{},header),

  // After email verify from web browser user auto login
  webverifyLogin: async (params: object) => await httpClient.post(backend_url+'web-verify-login', params),
  
  // Remove Device Token or logout
  SaveDevice: async (params: object,header: object) => await httpClient.post(backend_url+'save-web-device', params,{},header),
  // SaveDevice: async (params: object,header: object) => await httpClient.post(backend_url+'save-device', params,{},header),
  
  // Update Profile
  UpdateProfile: async (params: object,header: object) => await httpClient.post(backend_url+'updateProfile', params,{},header),
  
  // Login with social platform
  socialLogin: async (params: object) => await httpClient.post(backend_url+'web-social-login', params),
  socialLoginV2: async (params: object) => await httpClient.post(backend_url+'web-social-login-v2', params),
  socialLoginVerification: async (params: object) => await httpClient.post(backend_url+'social-login-verification', params),
  // socialLogin: async (params: object) => await httpClient.post(backend_url+'social-login', params),
    
  // Get all equipment list of a user
  selfEquip: async (params: object,header: object) => await httpClient.post(backend_url+'get-user-equip', params,{},header),

  // Upload Equipme images
  uploadEquipImage: async (params: object,header: object) => await httpClient.post(backend_url+'upload-equip-image', params,{},header),
  // Upload Equipme images
  createEquip: async (params: object,header: object) => await httpClient.post(backend_url+'create-equip', params,{},header),
  // Get Distance using lat lng
  getDistence: async (params: object,header: object) => await httpClient.post(backend_url+'get-distence', params,{},header),
  //Get User Profile Data
  getProfile: async (params: object,header: object) => await httpClient.post(backend_url+'fetchUserProfile', params,{},header),
  //Add Order
  // addOrder: async (params: object,header: object) => await httpClient.post(backend_url+'add-order', params,{},header),
  //Add Order request added by GD 2 jan 2024
  addOrder: async (params: object,header: object) => await httpClient.post(backend_url+'add-order-request', params,{},header),

  // Add wallet Balance For Live
  // addWalletBalance: async (params: object,header: object) => await httpClient.post(backend_url+'add-balance', params,{},header),
  // Add wallet Balance For Staging
  addWalletBalance: async (params: object,header: object) => await httpClient.post(backend_url+'add-balance-with-card-save', params,{},header),
  
  // Get Saved Cards listing
  SavedCardList: async (params: object,header: object) => await httpClient.post(backend_url+'stripe-payment-methods-list', params,{},header),

  // Get My listed equip
  // commented by GD on 3 jan 2024 this api works with staging until code of staging and prelive is merged
  // MyListedEquip: async (params: object,header: object) => await httpClient.post(backend_url+'get-all-equip-v-two', params,{},header),
  MyListedEquip: async (params: object,header: object) => await httpClient.post(backend_url+'get-all-equip-v3', params,{},header),
  
  // Get My Order
  MyOrder: async (params: object,header: object) => await httpClient.post(backend_url+'get-my-orders', params,{},header),
  // Update Equip Block dates
  UpdateBlockDates: async (params: object,header: object) => await httpClient.post(backend_url+'update-equip', params,{},header),
  // Update Order Status
  UpdateOrderStatus: async (params: object,header: object) => await httpClient.post(backend_url+'change-order-status', params,{},header),
  // Refund order security amount
  RefundSecurity: async (params: object,header: object) => await httpClient.post(backend_url+'refund-secorty', params,{},header),
  // Update account notification setting
  PushNotify: async (params: object,header: object) => await httpClient.post(backend_url+'push-notify', params,{},header),
  // Update Profile image
  UpdateProfileimage: async (params: object,header: object) => await httpClient.post(backend_url+'upload-profile-image', params,{},header),
  // Update Licence image
  UpdateLicenceimage: async (params: object,header: object) => await httpClient.post(backend_url+'upload-licence-image', params,{},header),
  // Update Phone Number
  UpdatePhone: async (params: object,header: object) => await httpClient.post(backend_url+'change-phone', params,{},header),
  // Update Email Address
  UpdateEmail: async (params: object,header: object) => await httpClient.post(backend_url+'updateemail', params,{},header),
  // Change Password
  ChangePassword: async (params: object,header: object) => await httpClient.post(backend_url+'change-password', params,{},header),
  // My Wallet
  MyWallet: async (params: object,header: object) => await httpClient.post(backend_url+'transection-history', params,{},header),
  // Get Equip Detail
  GetMyEquipDetail: async (params: object,header: object) => await httpClient.post(backend_url+'get-my-equip-detail', params,{},header),
  // Make Withdrawal Request
  WithDrawalRequest: async (params: object,header: object) => await httpClient.post(backend_url+'payment-resquest', params,{},header),
  // Get Chat
  GetChat: async (params: object,header: object) => await httpClient.post(backend_url+'get-chat-message', params,{},header),
  // Add Chat send chat message
  SendChatMsg: async (params: object,header: object) => await httpClient.post(backend_url+'add-chat', params,{},header),
  // Get Rented Equips  NewAPI
  GetRentedEquip: async (params: object,header: object) => await httpClient.post(backend_url+'get-rented-equip-web', params,{},header),
  //GetRentedEquip: async (params: object,header: object) => await httpClient.post(backend_url+'get-rented-equip', params,{},header),
  // Upload Image
  UploadImage: async (params: object,header: object) => await httpClient.post(backend_url+'upload-image', params,{},header),
  // Equipment Pickup Images and data upload
  PickUp: async (params: object,header: object) => await httpClient.post(backend_url+'add-pickup-image-new', params,{},header),
  // Equipment Dropoff Images and data upload
  DropOff: async (params: object,header: object) => await httpClient.post(backend_url+'return-equip-new', params,{},header),
  // Get all Notification
  GetNotificationsCount: async (params: object,header: object) => await httpClient.post(backend_url+'getNotificationCount', params,{},header),
  
  GetNotifications: async (params: object,header: object) => await httpClient.post(backend_url+'get-notifications', params,{},header),
  // Update Notification on chat
  UpdateNotification: async (params: object,header: object) => await httpClient.post(backend_url+'updateNotification', params,{},header),
  // Update Notification on click selected notification  NewAPI
  UpdateUserNotification: async (params: object,header: object) => await httpClient.post(backend_url+'updateUserNotification', params,{},header),
  // Get all Liked Equipe
  // commented by GD on 3 jan 2024 this api works with staging until code of staging and prelive is merged
  // GetLikedEquip: async (params: object,header: object) => await httpClient.post(backend_url+'get-all-liked-equip', params,{},header),
  GetLikedEquip: async (params: object,header: object) => await httpClient.post(backend_url+'get-all-liked-equip-v2', params,{},header),
  // Get all Liked Equipe
  AddToLikeDislike: async (params: object,header: object) => await httpClient.post(backend_url+'add-to-liked', params,{},header),
  // Check equipe like or not user based NewAPI
  EquipStatus: async (params: object,header: object) => await httpClient.post(backend_url+'equip-status-user', params,{},header),
  
  // Gps Section Start
  // Create Request for New GPS Device
  GpsRequest: async (params: object,header: object) => await httpClient.post(backend_url+'post-request-gps', params,{},header),
  // Get Assigned GPS Devices
  GetGpsDevice: async (params: object,header: object) => await httpClient.post(backend_url+'get-devices', params,{},header),
  // Get Assigned GPS Devices
  EquipConnectGpsDevice: async (params: object,header: object) => await httpClient.post(backend_url+'connect-device', params,{},header),
  // Get Unassigned GPS Devices
  EquipDisconnectGpsDevice: async (params: object,header: object) => await httpClient.post(backend_url+'disconnect-device', params,{},header),
  // Get All Connected GPS Device Location
  GetSingleConnectedGpsDevice: async (params: object,header: object) => await httpClient.post(backend_url+'get-lat-long', params,{},header),
  // Get All Connected GPS Device Location
  GetMultipleConnectedGpsDevice: async (params: object,header: object) => await httpClient.post(backend_url+'get-lat-long-by-userid', params,{},header),
  // Create Gps Device tracking Request
  GpsDeviceRequestCreate: async (params: object) => await httpClient.post(backend_url+'post-gps-web-request', params,{}),

  // Extend Order Section Start
  GetOrderedDates: async (params: object ,header: object) => await httpClient.post(backend_url+'get-ordered-dates', params,{},header),
  // Palce Extended Order
  ExtendedOrderPlace: async (params: object ,header: object) => await httpClient.post(backend_url+'extend-order', params,{},header),
  // Cancel Order by renter
  CancelOrder: async (params: object ,header: object) => await httpClient.post(backend_url+'cancel-order', params,{},header),

  // Cancel Order by owner
  CancelOrderByOwner: async (params: object ,header: object) => await httpClient.post(backend_url+'cancel-order-by-owner', params,{},header),
  // Extend Order Section End

  // My Rental Yard
  //MyRentalyard: async (params: object) => await httpClient.post(backend_url+'list-equip', params,{}),
  // commented by GD on 3 jan 2024 this api works with staging until code of staging and prelive is merged
  MyRentalyard: async (params: object) => await httpClient.post(backend_url+'list-equip-v2', params,{}),
  // MyRentalyard: async (params: object) => await httpClient.post(backend_url+'list-equip-v3', params,{}),
  
  // Extend Rental section start
  // order request summary
  RequestSummary: async (params: object, header: object) => await httpClient.post(backend_url+'order-request-summary', params,{}, header),
  //Update order request
  UpdateRequest: async (params: object, header: object) => await httpClient.post(backend_url+'update-order-request', params,{}, header),
  

  // Extend Rental section end

  //Get nearby equips
  GetNearEquips: async (params: object) => await httpClient.post(backend_url+'get-all-near-equip', params),
  //Simple Search API
  ListEquip: async (params: object) => await httpClient.post(backend_url+'list-equip', params),
  // filter equip
  // commented by GD on 3 jan 2024 this api works with staging until code of staging and prelive is merged
  // api not in use anymore
  GetFilterEquip: async (params: object) => await httpClient.post(backend_url+'equip-filter', params),
  // GetFilterEquip: async (params: object) => await httpClient.post(backend_url+'equip-filter-v2', params),
  
  // Search filter equip
  // searchFilter: async (params: object) => await httpClient.post(backend_url+'search-filter-new', params),
  searchFilter: async (params: object) => await httpClient.post(backend_url+'keyword-search-filter', params),
  
  // Sale Equip
  GetSaleEquips: async (params: object,header: object) => await httpClient.post(backend_url+'sales-equip-list', params,{},header),  
  // Sale Equip Interest email
  SalesEquipInterest: async (params: object,header: object) => await httpClient.post(backend_url+'sales-equip-interest-mail', params,{},header),
  // Delete Equip
  DeleteEquip: async (params: object,header: object) => await httpClient.post(backend_url+'delete-equip', params,{},header),

  // fetch-service
  fetchService: async (params: object,header: object) => await httpClient.post(backend_url+'fetch-service', params,{},header),

  // fetch-service-list
  fetchServiceCategories: async () => await httpClient.get(backend_url+'list-categories'),

  // search-service
  searchService: async (params: object,header: object) => await httpClient.post(backend_url+'search-service', params,{},header),
  // send text message on mobile
  sendTextMsg: async (params: object,header: object) => await httpClient.post(backend_url+'send-text-notification', params,{},header),
  // add-service
  addService: async (params: object,header: object) => await httpClient.post(backend_url+'add-service', params,{},header),
  // share to 3 emails
  shareWithEmails: async (params: object,header: object) => await httpClient.post(backend_url+'share-contacts-email', params,{},header),
  // my-service
  myServices: async (params: object,header: object) => await httpClient.post(backend_url+'my-service', params,{},header),
  // delete-service
  deleteService: async (params: object,header: object) => await httpClient.post(backend_url+'delete-service', params,{},header),
  // update-service-detail
  updateServiceDetails: async (params: object,header: object) => await httpClient.post(backend_url+'update-service-detail', params,{},header),
  // update-service-photo
  updateServicePhotos: async (params: object,header: object) => await httpClient.post(backend_url+'update-service-photo', params,{},header),
  updateServiceLiveStatus: async (params: object,header: object) => await httpClient.post(backend_url+'update-service', params,{},header), 
  
  
  // build a crew Api's
  crewListing: async (params: object,header: object) => await httpClient.post(backend_url+'crew-listing', params,{},header), 
  addReferralCode: async (params: object,header: object) => await httpClient.post(backend_url+'crew-add-refferal', params,{},header), 
  crewPayment: async (params: object,header: object) => await httpClient.post(backend_url+'crew-payment', params,{},header),
  crewTransactions: async (params: object,header: object) => await httpClient.post(backend_url+'crew-transactions', params,{},header),
  crewReferralValidate: async (params: object,header: object) => await httpClient.post(backend_url+'crew-validate-referral', params,{},header),
  crewCardPayment: async (params: object,header: object) => await httpClient.post(backend_url+'crew-card-payment', params,{},header),
  crewTransactionsDetails: async (params: object,header: object) => await httpClient.post(backend_url+'crew-transactions-detail', params,{},header),
  crewPaymentDetails: async (params: object,header: object) => await httpClient.post(backend_url+'crew-earning', params,{},header),
  
  //created by pp on 3 Apr 2024 due to chat thread changes
  updateThread: (params: object) => {
    const blob = new Blob([JSON.stringify(params)],{
      type: 'application/json',
    });
    return navigator.sendBeacon(backend_url+'update-thread', blob);
  },
  //created by pp on 24 apr 2024 due to timezone changes
  getEquipTimeZone: async (params: object,header: object) => await httpClient.post(backend_url+'get-equip-timezone', params,{},header),
  getGpsDeviceFilterLocation: async (params: object,header: object) => await httpClient.post(backend_url+'gps-device-filter', params,{},header),
  getOrderDetails: async (params: object,header: object) => await httpClient.post(backend_url+'get-order-detail', params,{},header),
  getDashboardDetails: async (params: object,header: object) => await httpClient.post(backend_url+'dashboard', params,{},header),

  getOrdersData: async (params: object,header: object) => await httpClient.post(backend_url+'fetch-orders', params,{},header),
  fetchOrderDetails: async (params: object,header: object) => await httpClient.post(backend_url+'fetch-orders-details', params,{},header),
  getUnreadnNotifications: async (params: object,header: object) => await httpClient.post(backend_url+'get-unread-notifications', params,{},header),
}