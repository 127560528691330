import { useEffect } from 'react';
import { Box, Button, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MainLayout from 'layouts/MainLayout';

// redux
import { IStoreValue } from 'utils/types';
import { useDispatch, useSelector } from "react-redux";
import { setLogInLoading, setLoginModalOpen, SetRedirectPage, setEquipAddType } from 'redux/redux-slice';

// componenets
import ListEqpQuesSale from 'components/howItWorks/GotQuestions/ListEqpQuesSale';
import ListForSaleTab from 'components/howItWorks/TabLikeSec/ListForSaleTab';
import Testimonials from 'components/howItWorks/Testimonials/Testimonials';

// images
import listForFreeImg from '../../../assets/images/howitworks/list_for_free.png';
import gemLending from '../../../assets/images/howitworks/goequipmelending-logo.png';
import thousandsUser from '../../../assets/images/howitworks/1000s_of_buyers.png';
import handsKey from '../../../assets/images/howitworks/keys.png';
import Jcb from '../../../assets/images/howitworks/tractor.png';
import livehelpImg from '../../../assets/images/gem-livehelp.png';

// styles
import './styles.scss';

const ListForSale = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const UserID = useSelector((state: IStoreValue) => state.equipmentReducer.Authuser);

    const LoginPopup = () => {
        dispatch(setLoginModalOpen(true));
        dispatch(setLogInLoading(false));
    };

    useEffect(() => { window.scroll(0, 0) }, []);

    return (
        <MainLayout>
            <>
                <Box className='listeqp-bg-img'>
                    <img src={Jcb} alt="" />
                </Box>
                <Box className='hw'>
                    <Box>
                        <Box className='bac-top-main'>
                            <Box className='bac-top'>
                                <Box className='bac-L'>
                                    <h1 className='bac-heading'>
                                        LIST <span>equipment</span>
                                    </h1>

                                </Box>
                                <Box className='bac-R'>
                                    <Button className='bac-btn'
                                        onClick={() => {
                                            if (UserID) {
                                                dispatch(setEquipAddType("sale"));
                                                navigate("/listequipment")
                                            } else {
                                                dispatch(setEquipAddType("sale"));
                                                dispatch(SetRedirectPage("listequipment"));
                                                LoginPopup();
                                            }
                                        }}
                                    >
                                        List for Sale
                                    </Button>
                                </Box>
                            </Box>

                            <p className='bac-subheading'>
                                Stress-free selling with thousands of buyers,
                                in-house financing, and RPO options.
                            </p>
                        </Box>

                        <ListForSaleTab />

                        <Box className='lgt-2'>
                            <Box className='lgt-img'>
                                <img src={listForFreeImg} alt="" />
                            </Box>
                            <Box className='lgt-content-2'>
                                <p className='lgt-heading'>
                                    List for Free! Get it Sold!
                                </p>
                                <p className='lgt-text'>
                                    It’s simple. Snap a couple photos, put in a
                                    description of your equipment and relax as
                                    GoEquipMe brings you offers.
                                </p>
                                <p className='lgt-text'>
                                    It’s a hassle free, no obligation experience with
                                    nothing but upside.
                                </p>
                            </Box>
                        </Box>


                        {/* Hands Key */}
                        <Box className='hk'>
                            <Box className='hk-img'>
                                <img src={handsKey} alt="" />
                            </Box>
                            <Box className='hk-content'>
                                <p className='hk-heading'>
                                    EARN MORE AND PUT BUYERS’
                                    MINDS AT EASE WITH AN RPO.
                                </p>
                                <p className='hk-subheading'>
                                    Give your buyers the confidence they need by offering a
                                    Rental Purchase Option (RPO). How does it work?
                                </p>
                                <ul className='hk-list'>
                                    <li>
                                        Your buyer rents equipment through GoEquipMe’s platform.
                                    </li>
                                    <li>
                                        A portion of rental proceeds are applied towards the
                                        purchase price.
                                    </li>
                                    <li>
                                        You earn additional rental premium on top of the sale price.
                                    </li>
                                </ul>
                            </Box>
                        </Box>

                        {/*  */}
                        <Box className='mipt'>
                            <Box className='mipt-item'>
                                <Box className='mipt-item-img-2'>
                                    <img src={thousandsUser} alt="" />
                                </Box>
                                <p className='mipt-item-heading-2'>
                                    THOUSANDS OF USERS
                                    WAITING TO BUY
                                </p>
                                <p className='mipt-item-text-2'>
                                    To sell equipment it needs to be seen!
                                    Of-course there are lots of sales sites,
                                    but to unlock the thousands of users on
                                    GoEquipMe, you’ll want to list it here too.
                                </p>

                            </Box>

                            <Box className='mipt-item'>
                                <Box className='mipt-item-img-2'>
                                    <img src={gemLending} alt="" />
                                </Box>
                                <p className='mipt-item-heading-2'>
                                    MORE BUYERS WITH
                                    IN-HOUSE FINANCING
                                </p>
                                <p className='mipt-item-text-2'>
                                    GoEquipMe is a <span style={{ color: '#faa61a !important' }}>direct lender</span>. That means
                                    we do loans, in-house, putting more
                                    buyers within reach of your equipment to
                                    get the sale done.
                                </p>

                            </Box>
                        </Box>

                        <Testimonials />

                        {/* 24/7 */}
                        <Box className='help-2'>
                            <Box className='help-img'>
                                <img src={livehelpImg} alt="" />
                            </Box>
                            <Box className='help-content'>
                                <p className='help-heading'>
                                    24/7 LIVE OWNER SUPPORT CHAT
                                </p>
                                <p className='help-text'>
                                    Have questions? Need Help? Simply click on &nbsp;
                                    <Link
                                        onClick={() => {
                                            console.log("intercom working");
                                            window['Intercom']?.('show');
                                        }}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        GoEquipMe’s 24/7 Live Chat
                                    </Link>
                                    &nbsp; for anything you might need.
                                </p>
                            </Box>
                        </Box>

                        {/* Got questions */}
                        <ListEqpQuesSale />
                    </Box>
                </Box>
            </>
        </MainLayout>
    )
}

export default ListForSale;
