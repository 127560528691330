import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import { FaPlus, FaMinus } from "react-icons/fa";

import './styles.scss'

const CustomExpandIcon = () => {
    return (
        <Box
            sx={{
                ".Mui-expanded & > .collapsIconWrapper": {
                    display: "none"
                },
                ".expandIconWrapper": {
                    display: "none"
                },
                ".Mui-expanded & > .expandIconWrapper": {
                    display: "block"
                }
            }}
        >


            <div className="expandIconWrapper"><FaMinus /></div>
            <div className="collapsIconWrapper"><FaPlus /></div>
        </Box>
    );
};

const FindEqpQuesRent = () => {
  return (
      <Box className='gq'>
          <Box className='gq-in'>
              <Box>
                  <p className='gq-heading'>
                      GOT QUESTIONS?
                  </p>
                  <p className='gq-subheading'>
                      Here Are a Few That Are Frequently Asked...
                  </p>
              </Box>
              <Box className='gq-content'>
                 
                  <Box>
                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                          >
                              <Typography>What is required to rent on GoEquipMe?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Create a free user account, complete your profile, and start renting.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel2-content"
                              id="panel2-header"
                          >
                              <Typography>How old do you have to be to rent on GoEquipMe?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  21
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel3-content"
                              id="panel3-header"
                          >
                              <Typography>Can I provide my own transport?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Yes, as long as the equipment owner allows for renter transport.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel4-content"
                              id="panel4-header"
                          >
                              <Typography>Can I communicate with the equipment owner directly?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Absolutely, in fact we encourage you to. Good communication with the equipment
                                  owner will help to ensure a good rental experience.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel5-content"
                              id="panel5-header"
                          >
                              <Typography>Can I extend my rental?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Yes, as long as the owner has the equipment available.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel6-content"
                              id="panel6-header"
                          >
                              <Typography>Are equipment packages offered?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Yes, simply click on the ‘Live Help’ icon and GoEquipMe will personally help line up the
                                  equipment you need no matter how large the equipment package.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel7-content"
                              id="panel7-header"
                          >
                              <Typography>What happens if I damage the equipment?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  We get it, things happen. That’s why every rental comes with equipment damage
                                  protection coverage. See the user agreement for details.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel8-content"
                              id="panel8-header"
                          >
                              <Typography>Do I need my own insurance?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Most individual homeowner policies and contractor’s standard insurance policies
                                  provide adequate coverage. However, just to be sure, every rental is additionally
                                  covered with GoEquipMe’s Equipment Damage Protection coverage up to
                                  $50,000.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel9-content"
                              id="panel9-header"
                          >
                              <Typography>What if I have my own insurance?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  With evidence of rental insurance coverage some owners may agree to waive
                                  the equipment damage protection offered through GoEquipMe. It is up to the
                                  equipment owner to accept or deny this as adequate coverage.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel10-content"
                              id="panel10-header"
                          >
                              <Typography>What if I can’t find what I need?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  We can still help. Simply click on the Live Help and we will get to work finding the
                                  equipment you are looking for. We are growing fast so keep checking back for new
                                  listings.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel11-content"
                              id="panel11-header"
                          >
                              <Typography>Am I responsible for maintenance?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Yes, it is the responsibility of each renter to maintain the equipment in good working
                                  order. GoEquipMe and the equipment owner are always there to help determine what is
                                  required.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel12-content"
                              id="panel12-header"
                          >
                              <Typography>What if I need to cancel?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Yes, you can cancel at any time. As a courtesy, and to maintain good renter reviews,
                                  just let the equipment owner know as soon as possible.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel13-content"
                              id="panel13-header"
                          >
                              <Typography>Do I need an operator’s license?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  This depends. Some items are registered as over-the-road vehicles and do require a
                                  valid operator's license. Other items require a proficient operator’s skill set. While other
                                  items require little to no proficiency. If you are uncertain, be sure to speak with the
                                  equipment owner to ensure it is a good fit for your abilities.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel14-content"
                              id="panel14-header"
                          >
                              <Typography>Can I get discounts for longer rentals?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Yes, most owner’s publish discounted rental rates for longer rentals.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                  </Box>
              </Box>
          </Box>
      </Box>
  )
}

export default FindEqpQuesRent
