import { useState } from "react";
import { Box, Button, IconButton, Modal, TextField, Typography, styled, useMediaQuery } from "@mui/material"
import { useDispatch, useSelector } from 'react-redux';
import { EquipmentAPIs } from "../../service/api-service";
import { IApiResponseRawData, IStoreValue } from 'utils/types';
import { setModalOpen, setLoginStatus, setaccessToken, setSessionExpire, setalertMessagetype, setalertMessage } from 'redux/redux-slice';
import CloseIcon from '@mui/icons-material/Close'

const CssTextField = styled(TextField)({
    "& .MuiInputBase-root": {
        color: "#2e2e2e",
        background: "#fff",
        fontFamily: "GothamBold !important",
        fontSize: "clamp(12px, 3vw, 15px) !important"
        // border: "2px solid #faa61a",
    },
    "& label.Mui-focused": {
        color: "#faa61a",
        fontWeight: "bold"
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#2e2e2e"
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "2px solid #faa61a"
        },
        "&:hover fieldset": {
            borderColor: "#2e2e2e",
            border: "2px solid #faa61a"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#faa61a"
        }
    }
});

const loginstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 4,
}

const DeleteServiceModal = ({ deleteModelOpen, setDeleteModelOpen, deleteServiceId, setLoading, onDeleteSuccess }) => {
    const dispatch = useDispatch();
    const matchesMobileX = useMediaQuery('(max-width:425px)');
    const AuthToken = useSelector((state: IStoreValue) => state.equipmentReducer.accessToken);
    // const [loading, setLoading] = useState(false);
    console.log("deleteServiceId", deleteServiceId);

    const deleteService = async () => {
        try {
            setLoading(true);
            const data = {
                service_id: deleteServiceId
            };
            console.log("data: ", data);
            const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
            const deleteService = await EquipmentAPIs.deleteService(data, headers);
            console.log("deleteService: ", deleteService);
            if (deleteService["type"] == "RXSUCCESS") {
                console.log("deleteService success : ", deleteService);
                // setLoading(false);
                setDeleteModelOpen(false);
                dispatch(setalertMessagetype("success"));
                dispatch(setalertMessage("Service deleted successfully."));
                onDeleteSuccess(deleteServiceId);
            } else {
                // Display an error message if deletion fails
                dispatch(setalertMessagetype("error"));
                dispatch(setalertMessage("error in delete service ."));
                setLoading(false);
                setDeleteModelOpen(false);
            }
        } catch (err) {
            // Handle errors that occur during the deletion process
            console.log(err);
            setLoading(false);
            setDeleteModelOpen(false);
        }
    };

    return (
        <Modal
            keepMounted
            open={deleteModelOpen}
            onClose={() => setDeleteModelOpen(false)}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box
                className="download-popups"
                sx={{
                    ...loginstyle,
                    width: matchesMobileX ? 'calc(100vw - 100px)' : 800,
                    padding: "0px",
                }}>

                <IconButton
                    className="cross-btn"
                    onClick={() => setDeleteModelOpen(false)}
                >
                    <CloseIcon />
                </IconButton>
                <Box className="login__main">
                    <Box className="login-main-inner">
                        <Box className="login__main-div__form popup-cmmn popup-white edit-eqp-inner">
                            <Box className="">
                                <Box className="">
                                    <Typography
                                        variant="h4"
                                        className="welcome-heading"
                                        sx={{ textAlign: "center", color: "#fff" }}
                                    >
                                    </Typography>

                                    <Typography className="second-para" sx={{ fontSize: '20px !important', textAlign: 'center', color: '#fff !important', fontFamily: 'GothamBold !important' }}>
                                        Are you sure you want to delete this service ?
                                    </Typography>

                                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', justifyContent: 'center', alignItems: 'center', gap: '2rem', marginTop: '1.5rem', marginBottom: '0.5rem' }}>
                                        <Button onClick={() => setDeleteModelOpen(false)} sx={{ borderRadius: '7px',width: '100%',padding: '0.5rem 1rem', background: '#faa61a !important', fontSize: '18px !important', color: '#fff', textTransform: 'capitalize', fontFamily: 'GothamBold !important' }}>Cancel</Button>
                                        <Button sx={{ borderRadius: '7px', fontSize: '18px !important', padding: '0.5rem 1rem', width: '100%', background: '#ff0000 !important', color: '#fff', textTransform: 'capitalize', fontFamily: 'GothamBold !important' }}
                                            onClick={() => {
                                                console.log("send text working");
                                                deleteService();
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
};


export default DeleteServiceModal;