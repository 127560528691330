import { Box, Button, Typography } from '@mui/material'
import icon1 from '../../../assets/images/howitworks/icon-1.svg'
import icon2 from '../../../assets/images/howitworks/icon-2.svg'
import icon3 from '../../../assets/images/howitworks/icon-3.svg'
import jcbIcon from '../../../assets/images/howitworks/jcbIcon.png'
import './styles.scss'

const FindEqpTab = () => {
    return (
        <Box>
            <Box>
                <Box>
                    <p className='tab-heading'>
                        Renting Is As Easy As…
                    </p>
                    <Box>
                        <Box className='tablike-content'>
                            <Box className='tab-grid'>
                                <Box>
                                    <Box className='tab-grid-heading'>
                                        <Box>

                                            <img src={icon1} alt="" className='tabicon' />
                                        </Box>
                                        <Typography>Find Equipment</Typography>
                                    </Box>
                                    <Typography className='tab-grid-text'>
                                        Search our broad
                                        inventory of privately
                                        owned equipment
                                        that might be just
                                        around the corner.
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box className='tab-grid-heading'>
                                        <Box>
                                            <img src={icon2} alt="" className='tabicon' />
                                        </Box>
                                        <Typography>Select Transport</Typography>
                                    </Box>
                                    <Typography className='tab-grid-text'>
                                        Choose what works best
                                        for you, owner delivery or
                                        self pickup.
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box className='tab-grid-heading'>
                                        <Box>
                                            <img src={icon3} alt="" className='tabicon' />
                                        </Box>
                                        <Typography>Secure Rental</Typography>
                                    </Box>
                                    <Typography className='tab-grid-text'>
                                        Send your request to
                                        the owner for rental
                                        confirmation.
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box className='tab-grid-heading'>
                                        <Box>
                                            <img className='jcbicon' src={jcbIcon} alt=""  />
                                        </Box>
                                        <Typography>Get to Work</Typography>
                                    </Box>
                                    <Typography className='tab-grid-text'>
                                        Build your project
                                        while enjoying savings
                                        and providing
                                        community support.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default FindEqpTab
