import { Backdrop, Box, Button, CircularProgress, IconButton, Modal, TextField, Typography, useMediaQuery } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { EquipmentAPIs } from "../../service/api-service";
import { IStoreValue } from 'utils/types';
import { setalertMessagetype, setalertMessage } from 'redux/redux-slice';

const CrewPayoutModal = ({ crewPaymentOpen, setCrewPaymentOpen }) => {

    const dispatch = useDispatch();
    const matchesMobileX = useMediaQuery('(max-width:425px)');
    const AuthToken = useSelector((state: IStoreValue) => state.equipmentReducer.accessToken);
    const UserID = useSelector((state: IStoreValue) => state.equipmentReducer.Authuser);

    const [loading, setLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [transactionsData, setTransactionsData] = useState([]);
    const [nextPayout, setNextPayout] = useState("0");

    const crewTransactionsApi = async () => {
        try {

            setLoading(true);
            // Call the API to get the service categories
            const data = {
                user_id: UserID.toString(),
                // user_id: "70",
                // page: page,
                // limit: limit,
            };

            console.log("data in get crews Trans api: ", data);
            // return;
            const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
            const getTrans = await EquipmentAPIs.crewTransactions(data, headers);
            console.log("getTrans api: ", getTrans);
            if (getTrans["type"] == "RXSUCCESS") {
                // console.log("getTrans : ", getTrans);

                if (getTrans.next_payout) setNextPayout(getTrans.next_payout);
                if (getTrans.data) setTransactionsData(getTrans.data);

                // dispatch(setalertMessagetype("success"));
                // dispatch(setalertMessage("Transactions fetch successfully."));
                setLoading(false);
            } else {
                // Display an error message if deletion fails
                dispatch(setalertMessagetype("error"));
                dispatch(setalertMessage("error while fetching transactions."));
                setLoading(false);
                // setDeletePopup(false)
            }
        } catch (err) {
            // Handle errors that occur during the deletion process
            console.log(err);
            setLoading(false);
            setCrewPaymentOpen(false);
        }
    };

    useEffect(() => {
        if (UserID) {
            crewTransactionsApi();
        }
    }, [UserID]);

    return (
        <Modal
            open={crewPaymentOpen}
            onClose={() => setCrewPaymentOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                '& .MuiPaper-root': {
                    outline: 'none !important',
                    border: 'none !important',
                    '& .focus': {
                        outline: 'none !important',
                        border: 'none !important',
                    }
                },
            }}
        >
            <Box sx={{
                outline: 'none',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: '#2d2d2d',
                borderRadius: '10px',
                border: '5px solid #faa61a',
                boxShadow: 24,
                p: {
                    xs: '1rem',
                    sm: '1rem',
                    md: '1.5rem',
                    lg: '2rem',
                    xl: '2rem',
                },
                width: {
                    xs: '80vw',
                    sm: '80vw',
                    md: '500px',
                    lg: '500px',
                    xl: '500px',
                },
                maxWidth: '90vw',
            }}>
                <IconButton className="cross-btn" onClick={() => setCrewPaymentOpen(false)}>
                    <CloseIcon />
                </IconButton>
                {loading && (
                    <Box>
                        <Backdrop
                            sx={{
                                color: "#000",
                                zIndex: (theme) => theme.zIndex.drawer - 1,
                            }}
                            open={loading}
                        >
                            <CircularProgress color="warning" />
                        </Backdrop>
                    </Box>
                )}

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography id="modal-modal-title" sx={{ color: '#fff', fontSize:'clamp(24px, 3vw ,30px) !important' }} variant='h4'>My<span style={{ color: '#faa61a' }}>Crew</span>Transaction's</Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                    <Typography variant='h6' id="modal-modal-description" sx={{ color: '#fff', fontFamily: 'Gotham !important' }}>Pending Payout: ${parseFloat(nextPayout).toFixed(2)}</Typography>
                </Box>

                <Box sx={{ margin: '1rem 0', maxHeight: '400px', overflowY: 'auto' }}>
                    {transactionsData.length === 0 &&
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography sx={{ color: '#fff' }}>
                                No Transactions Found!
                            </Typography>
                        </Box>
                    }

                    {transactionsData.map((item, index) => (
                        <Box key={index} sx={{ display: 'flex', flexDirection: 'column', gap: '5px', borderBottom: '1.5px solid gray', mt: 2, mb: 1, }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '1rem' }}>
                                <Typography sx={{ color: '#fff' }}>+${item.amount.toFixed(2)}</Typography>
                                <Typography sx={{
                                    padding: '3px 10px',
                                    fontSize: '12px',
                                    width: 'fit-content',
                                    borderRadius: '5px',
                                    color: '#fff',
                                    textTransform: 'uppercase',
                                    background: `${item.payout_status == "0" ? '#faa61a' : 'green'}`,
                                    border: `2px solid ${item.payout_status == "0" ? '#fff' : '#faa61a'}`,
                                }}>
                                    {item.payout_status == "0" ? "Pending" : "completed"}
                                </Typography>
                            </Box>
                            <Typography sx={{ color: '#fff' }}> Trans. Id: {item.id}, {new Date(item.createdAt).toString().substring(0, 21)}</Typography>
                            <Typography sx={{ color: '#fff', mb: 2 }}> {item.description}</Typography>

                        </Box>
                    ))}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem', }}>
                </Box>
                <Button onClick={() => setCrewPaymentOpen(false)} sx={{ fontFamily: 'Montserrat !important', color: '#fff', fontSize: '14px', position: 'absolute', top: '-15px', right: '-15px', width: '30px !important', height: '30px !important', minWidth: '30px !important', borderRadius: ' 50%', border: '2px solid #faa61a', background: '#2d2d2d !important', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>X</Button>
            </Box>
        </Modal>
    );
};

export default CrewPayoutModal;
