import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/home/Home';
import About from '../pages/about/About';
import Contact from '../pages/contact/Contact';
import Welcome from '../pages/welcome/Welcome';
import EquipmentDetail from '../pages/equipment-detail/EquipmentDetail';
// import ExtendRental from '../pages/equipment-detail/ExtendRental';
import TermsAndConditions from '../pages/terms-and-conditions/TermsAndConditions';
import DownloadPopup from '../pages/download-popup/DownloadPopup';
import DownloadPage from '../pages/download-popup/DownloadPage';
import UserLogin from '../pages/user-login/UserLogin';
import EquipmentList from '../pages/equipment-list/EquipmentList';
import MyOfficeTrailer from '../pages/myoffice-trailer/MyOfficeTrailer';
import AccountSetting from '../components/account/AccountSetting';
import ProfileInfo from '../components/account/Profileinfo';
import MyWallet from '../components/account/MyWallet';
import Notification from '../components/Notifications/Notification';
import EquipmentTracking from '../pages/equipment-tracking/Tracking';
// import MyRentalYard from '../components/MyRentalYard/MyRentalYard';
import ShareMyRentalYard from '../components/MyRentalYard/ShareRentalYard';
import OrderRequestSummary from '../components/OrderDetail/OrderRequestSummary';

import Signup from '../components/user-signup/SignUp';

import ServiceDirectory from '../components/serviceDirectory/ServiceDirectory';
import AddService from '../components/serviceDirectory/AddService';
// import MyServiceDirectory from 'components/serviceDirectory/MyServiceDirectory';

import EquipmentOrders from 'components/MyofficeTrailer/EquipmentOrders/EquipmentOrders';

import HowItWorks from 'pages/howItWorks/HowItWorks';
import FindEquipRent from 'pages/howItWorks/FindEquip/FindEquipRent';
import FindEquipSale from 'pages/howItWorks/FindEquip/FindEquipSale';
import ListForSale from 'pages/howItWorks/ListEquip/ListForSale';
import ListForRent from 'pages/howItWorks/ListEquip/ListForRent';
import BuildCrew from 'pages/howItWorks/BuildCrew/BuildCrew';
import FindEquip from 'pages/howItWorks/find/FindEquip';
import ThumbsUp from 'components/MyofficeTrailer/Thumbs-up/ThumbsUp';
/**
 * Main Route for the application
 */
const MainRoute: React.FC = () => {
  return (
    <Routes>
      <Route path={'/'} element={<Home />} />
      {/* Use for redirect to notification chat window from email link */}
      <Route path={'/:Type/:EquipId/:ToId/:FromId/:EquipName/:ToName'} element={<Home />} />
      <Route path={'/:live-help'} element={<Home />} /> {/* Route for live-help */}
      <Route path={'/equipmentlending'} element={<Welcome />} />
      <Route path="/detail/:make/:city/:state/:postalcode/:ownerid/:id/" element={<EquipmentDetail />} />
      <Route path="/detail/:make/:city/:state/:postalcode/:ownerid/:id/:list_type" element={<EquipmentDetail />} />
      <Route path="/detail/:ownerid/:id/:list_type?" element={<EquipmentDetail />} />
      {/* <Route path="/extendrental/:orderid/:id" element={<ExtendRental />} /> */}
      <Route path={'/learnmore'} element={<About />} />
      <Route path={'/contactus'} element={<Contact />} />
      <Route path={'/terms_and_conditions'} element={<TermsAndConditions />} />
      <Route path={'/privacy_policy'} element={<TermsAndConditions />} />
      <Route path={'/download-popup'} element={<DownloadPopup />} />
      <Route path={'/appdownload'} element={<DownloadPage />} />
      <Route path={'/login'} element={<UserLogin />} />
      <Route path={'/signup'} element={<Signup />} />
      <Route path={'/signup/verify/:email'} element={<Signup />} /> {/* After email verify from web browser auto login */}
      <Route path={'/listequipment'} element={<EquipmentList />} />
      <Route path={'/myofficetrailer'} element={<MyOfficeTrailer />} />
      {/* <Route path="/accountsettings" element={<AccountSetting />} /> Route for My account setting */}
      <Route path="/profileinfo" element={<ProfileInfo />} /> {/* Route for Profile info */}
      <Route path="/wallet" element={<MyWallet />} /> {/* Route for My WalletPage */}
      {/* <Route path="/notifications/:senderID?/:reciverID?/:equipId?/:reciverName?/:EquipMake?" element={<MyOfficeTrailer />} /> Route for Notification */}
      <Route path="/notifications/:senderID?/:reciverID?/:equipId?/:reciverName?/:EquipMake?" element={<Notification />} /> {/* Route for Notification */}
      <Route path="/equipment-tracking" element={<EquipmentTracking />} /> {/* Route for Tracking Equipment */}
      {/* <Route path="/my-rental-yard/:id" element={<MyRentalYard />} /> */}
      <Route path="/share-rental-yard/:type/:userId/:equipUser?" element={<ShareMyRentalYard />} />
      <Route path="/order-request/rental-summary/:id/:user_id" element={<OrderRequestSummary />} />
      {/* Route for My Rental Yard */}

      {/* service directory related links */}
      <Route path="/service-directory" element={<ServiceDirectory />} />
      <Route path="/add-service" element={<AddService />} />
      {/* <Route path="/myServiceDirectory" element={<MyServiceDirectory />} /> */}

      {/* How it works pages */}
      <Route path="/howItWorks" element={<HowItWorks />} />
      <Route path="/howItWorksFind" element={<FindEquip />} />
      {/* <Route path="/howItWorksFindRent" element={<FindEquipRent />} />
      <Route path="/howItWorksFindSale" element={<FindEquipSale />} /> */}
      <Route path="/howItWorksListRent" element={<ListForRent />} />
      <Route path="/howItWorksListSale" element={<ListForSale />} />
      <Route path="/howItWorksBuildCrew" element={<BuildCrew />} />
      <Route path="/thumbsUp" element={<ThumbsUp />} />
      {/* build a crew */}
      <Route path="/buildACrew/:userId?" element={<MyOfficeTrailer />} /> {/* Route for build a crew */}
      {/* my office trailer routes */}
      <Route path="/myRentalYard/:uId?/:equipmentId?" element={<MyOfficeTrailer />} /> {/* Route for rental yard */}
      <Route path="/mySalesYard" element={<MyOfficeTrailer />} /> {/* Route for sales yard */}
      <Route path="/equipmentRented/:uId?/:orderId?" element={<MyOfficeTrailer />} /> {/* Route for equipment i rented */}
      {/* <Route path="/thumbsUp" element={<MyOfficeTrailer />} /> Route for equipments i liked */}
      <Route path="/myGps" element={<MyOfficeTrailer />} /> {/* Route for GPS */}
      <Route path="/dashboard" element={<MyOfficeTrailer />} /> {/* Route for GPS */}
      <Route path="/myServiceDirectory" element={<MyOfficeTrailer />} /> {/* Route for myServiceDirectory */}
      <Route path="/equipmentOrders/:id?/:filterType?" element={<EquipmentOrders />} /> {/* Route for orders tab */}
    </Routes>
  );
};

export default MainRoute;