import { Box, Button, IconButton, Modal, Typography, useMediaQuery } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close'

const loginstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 4,
};

const PhotosModal = ({ PhotosModelOpen, setPhotosModelOpen, photosArray }) => {
    // const [PhotosModelOpen , setPhotosModelOpen] = useState(false);
    const matchesMobileX = useMediaQuery('(max-width:425px)');

    return (
        <Modal
            keepMounted
            open={PhotosModelOpen}
            onClose={() => setPhotosModelOpen(false)}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box
                className="download-popups"
                sx={{
                    ...loginstyle,
                    width: matchesMobileX ? 'calc(100vw - 100px)' : 800,
                    padding: "0px",
                }}>
                <IconButton
                    className="cross-btn"
                    onClick={() => setPhotosModelOpen(false)}
                >
                    <CloseIcon />
                </IconButton>
                <Box className="login__main">
                    <Box className="login-main-inner">
                        <Box className="login__main-div__form popup-cmmn popup-white edit-eqp-inner">
                            <Box className="">
                                <Box className="">
                                    <Typography
                                        variant="h4"
                                        className="welcome-heading"
                                        sx={{ textAlign: "center", color: "#fff" }}
                                    >
                                    </Typography>

                                    <Typography className="" sx={{ color: '#fff !important', fontSize: '20px !important', fontFamily: 'GothamBold !important', textTransform: 'capitalize' }}>
                                        Photos
                                    </Typography>
                                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '1rem', marginTop: '1rem' }}>
                                        {photosArray.map(photo => (
                                            <Box key={photo}>
                                                <img key={photo} src={photo} style={{ width: "100%", height: "200px", maxHeight: "200px", objectFit: 'cover', display: 'block' }} />
                                            </Box>
                                        ))}
                                    </Box>
                                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', alignItems: 'center', gap: '1rem', }}>
                                        {/* <Button onClick={() => setPhotosModel(false)} sx={{ width: '100%', border: '2px solid #faa61a', color: '#fff', textTransform: 'capitalize', fontFamily: 'GothamBold !important' }}>Cancel</Button> */}
                                        {/* <Button onClick={() => deleteEquipment()} sx={{ width: '100%', border:'2px solid #faa61a', color: '#fff',textTransform: 'capitalize', fontFamily: 'GothamBold !important' }}>Ok</Button> */}
                                    </Box>
                                    {/* <Box
                                            className="close"
                                            onClick={() => deleteEquipment()}
                                        >
                                            <Box className="close-content">
                                            <Typography sx={{ color: '#faa61a !important', fontFamily: 'GothamBold !important', fontSize: '18px !important' }}>Ok</Typography>
                                            </Box>
                                        </Box>

                                        <Box
                                            className="close"
                                            onClick={() => setDeletePopup(false)}
                                        >
                                            <Box className="close-content">
                                            <Typography sx={{ color: '#faa61a !important', fontFamily: 'GothamBold !important', fontSize: '18px !important' }}>Cancel</Typography>
                                            </Box>
                                        </Box> */}
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
};


export default PhotosModal;