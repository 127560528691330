import { Box, Button, Typography } from '@mui/material'
import icon1 from '../../../assets/images/howitworks/icon-1.svg'
import icon2 from '../../../assets/images/howitworks/icon-2.svg'
import icon3 from '../../../assets/images/howitworks/icon-3.svg'
import dollarIcon from '../../../assets/images/go-dollar.png'

import './styles.scss'

const ListForRentTab = () => {
    return (
        <Box>
            <Box>
                <Box>
                    <p className='tab-heading-2'>
                        Renting Equipment Is As Easy As...
                    </p>
                    <Box>
                        <Box className='tablike-content'>
                            <Box className='tab-grid'>
                                <Box>
                                    <Box className='tab-grid-heading'>
                                        <Box>
                                            <img src={icon1} alt="" className='tabicon' />
                                        </Box>

                                        <Typography>List</Typography>
                                    </Box>
                                    <Typography className='tab-grid-text'>
                                        List your equipment
                                        for free in as little as
                                        5 minutes.
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box className='tab-grid-heading'>
                                        <Box>
                                            <img src={icon2} alt="" className='tabicon' />
                                        </Box>
                                        <Typography>Approve</Typography>
                                    </Box>
                                    <Typography className='tab-grid-text'>
                                        Review rental
                                        requests to accept
                                        or deny. Message
                                        renters directly.
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box className='tab-grid-heading'>
                                        <Box>
                                            <img src={icon3} alt="" className='tabicon' />
                                        </Box>
                                        <Typography>Manage</Typography>
                                    </Box>
                                    <Typography className='tab-grid-text'>
                                        Easy-to-use owner
                                        tools in your very own
                                        Owners.
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box className='tab-grid-heading'>
                                        <Box>
                                            <img src={dollarIcon} alt="" className='tabicon' />
                                        </Box>
                                        <Typography>Get Paid</Typography>
                                    </Box>
                                    <Typography className='tab-grid-text'>
                                        Rental revenue is
                                        deposited to your
                                        Wallet with each
                                        rental.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ListForRentTab
