import React, {useEffect, useState } from 'react'
import { Calendar as NewCalendar } from "react-multi-date-picker";
import moment from "moment";
// import "../../pages/equipment-detail/calendar.scss"
import "../../pages/equipment-detail/styles.scss"


// const EquipDetail = ({ monthview, EquipObj, BlockDatesArr, handleStartEndTime, timeObj, MyPlugin , OnSuccess }) => {
  const EquipDetail = ({ monthview, EquipObj, BlockDatesArr, MyPlugin , OnSuccess, calendarStartDate,updateEquipmentTimezone }) => {
  const [Months, setMonths] = React.useState(1);
  const [EquipData, setEquipData] = React.useState(null);
  const [ChaeckDatesBlockArr, setChaeckDatesBlockArr] = React.useState([]);
  const [values, setValues] = useState(ChaeckDatesBlockArr);
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  // const [rentStartDate, setrentStartDate] = useState("");
  // const [rentEndDate, setrentEndDate] = useState("");
  // Check Date is Reserved or not
  const isReserved = (strDate) => {

    if (EquipData["weekends"] == "0") {
      if (moment(strDate).format("ddd") == "Sat") {
        return true;
      }
    }
    if (EquipData["sunday"] == "0") {
      if (moment(strDate).format("ddd") == "Sun") {
        return true;
      }
    }

    if (ChaeckDatesBlockArr.includes(strDate)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setMonths(monthview);
    setEquipData(EquipObj)
    setChaeckDatesBlockArr(BlockDatesArr)
  }, [monthview, EquipObj, BlockDatesArr]);

  // const handleDeselect = () => {
  //   setValues([]); // Clear the selected range by setting it to an empty array
  // };
  return (
    <>
      {/* <Box className="twin-cal"> */}
        {/* <Box className="twin-cal-item"> */}
          <NewCalendar
            className=""
            numberOfMonths={Months}
            disableMonthPicker
            disableYearPicker
            value={values}
            onChange={(rang) => {

              let start = rang[0]?.format?.();
              //update equipement timezone by pp 29 mar 2014
              console.log("rent now calender")
              updateEquipmentTimezone(start)
              // end by pp
              let end = rang[1]?.format?.();
              if (start || end) {
                let flag = "false";
                ChaeckDatesBlockArr.map((itm, ind) => {
                  let item_date = itm.split("-");
                  var startDay =
                    item_date[2] > 9
                      ? item_date[2]
                      : "0" + item_date[2];
                  var startMonth =
                    item_date[1] > 9
                      ? item_date[1]
                      : "0" + item_date[1];
                  var startYear = item_date[0];
                  var blk_date =
                    startMonth + "/" + startDay + "/" + startYear;
                  var b_date = moment(blk_date).format("YYYY/MM/DD");
                  if (b_date == start || b_date == end) {
                    flag = "true";
                    return false;
                  }
                  let foundDateInRange = moment(b_date).isBetween(
                    start,
                    end
                  );
                  if (foundDateInRange) {
                    flag = "true";
                    return false;
                  }
                });

                var endd = moment(end);
                var starttt = moment(start);
                var diff = endd.diff(starttt, 'days')
                if (diff > 0) {
                  for (let index = 0; index <= diff; index++) {
                    let new_date = new Date(start);
                    new_date.setDate(new_date.getDate() + index);
                    let dayname = moment(new_date).format("ddd");

                    if (EquipData["weekends"] == "0" && dayname == 'Sat') {
                      let a = [end];
                      setValues(a);
                      return false;
                    }
                    if (EquipData["sunday"] == "0" && dayname == 'Sun') {
                      let a = [end];
                      setValues(a);
                      return false;
                    }
                  }
                }
                if (flag !== "true") {
                  let a = [start, end];
                  setValues(a);

                  OnSuccess(start, end)

                  // if (start && end) {
                  //   OnSuccess(start, end)
                  // }
                } else {
                  let a = [end];
                  setValues(a);
                  return false;
                }
              }
              return;
            }}
            mapDays={({ date }) => {
              let className = "";
              const strDate = moment(date.format()).format(
                "YYYY-M-D"
              );

              if (isReserved(strDate)) className = "reserved";
              if (className) return { className };
            }}
            // minDate={new Date()}
            // next line added by pp on 29 march 2024 due to timezone update
            minDate={calendarStartDate}
            range
          />
        {/* </Box> */}
        <MyPlugin/>
        {/* <button onClick={handleDeselect}>Deselect</button> */}

        {/* </Box> */}
      
    </>
      );
};
      export default EquipDetail;