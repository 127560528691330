import { Box } from '@mui/material';
import React from 'react';

interface UsCurrencyFormatProps {
  amount: number;
}

const UsCurrencyFormat: React.FC<UsCurrencyFormatProps> = ({ amount }): JSX.Element => {
  amount = typeof amount === "string"?parseFloat(amount):amount;
  const convertedAmount = !isNaN(amount)
    ? amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : '$0.00';

  return <Box component={"span"}>{convertedAmount}</Box>;
};

export default UsCurrencyFormat;
