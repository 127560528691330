import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Console } from "console";
import moment from "moment";
import momentTimezone from "moment-timezone";
const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4,
};
const PickupDropoffImagesView = ({
  pickupDropoffReturnPopup,
  handleRenterPickupImagesClose,
  activeEquipment,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [returnImageTitle, setReturnImageTitle] = useState<string>("pickup");
  const [pickupImage, setPickupImages] = useState<any>([]);
  const [pickupImageTimestamp, setPickupImagesTimestamp] = useState<any>([]);
  const [dropoffImage, setDropoffImage] = useState<any>([]);
  const [dropoffImageTimestamp, setDropoffImageTimestamp] = useState<any>([]);

  useEffect(() => {
    if (pickupDropoffReturnPopup) {
      console.log("activeEquipment", activeEquipment);

      if (activeEquipment["renter_images"]) {
        const images = activeEquipment["renter_images"]?.split(",");
        if (images && images?.length) {
          setPickupImages(images);
        }
        const timestamp =
          activeEquipment["renter_pickup_timestamp"]?.split(",");
        if (timestamp && timestamp?.length) {
          setPickupImagesTimestamp(timestamp);
        }
      }
      if (activeEquipment["return_renter_images"]) {
        const images = activeEquipment["return_renter_images"]?.split(",");
        if (images && images.length) {
          setDropoffImage(images);
        }
        const timestamp =
          activeEquipment["renter_return_timestamp"]?.split(",");
        if (timestamp && timestamp?.length) {
          setDropoffImageTimestamp(timestamp);
        }
      }
    } else {
      setPickupImages([]);
      setDropoffImage([]);
      setPickupImagesTimestamp([]);
      setDropoffImageTimestamp([]);
    }
  }, [activeEquipment, pickupDropoffReturnPopup]);

  return (
    <>
      <Modal
        keepMounted
        open={pickupDropoffReturnPopup}
        onClose={handleRenterPickupImagesClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: "80%",
              sm: "400px",
              md: "500px",
              lg: "500px",
              xl: "500px",
            },
            bgcolor: "#2e2e2e",
            border: "5px solid #faa61a",
            borderRadius: "10px",
            outline: "none !important",
            p: 2,
          }}
        >
          <IconButton
            onClick={handleRenterPickupImagesClose}
            sx={{
              position: "absolute",
              top: "-20px",
              right: "-20px",
              bgcolor: "#faa61a",
              color: "#000",
              "&:hover": {
                bgcolor: "#faa61a",
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  background: "#484840",
                  borderBottom: 1,
                  borderColor: "divider",
                  p: 1,
                  borderRadius: "7px",
                }}
              >
                <Button
                  sx={{
                    fontSize: {
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "16px",
                      xl: "18px",
                    },
                    textTransform: "capitalize",
                    width: "100%",
                    background:
                      returnImageTitle === "pickup" ? "#faa61a" : "transparent",
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontWeight: "800",
                    "&:hover": {
                      background:
                        returnImageTitle === "pickup"
                          ? "#faa61a"
                          : "transparent",
                      outline: "1.5px solid #faa61a",
                    },
                  }}
                  onClick={() => setReturnImageTitle("pickup")}
                >
                  Pickup Photos
                </Button>
                <Button
                  sx={{
                    fontSize: {
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "16px",
                      xl: "18px",
                    },
                    textTransform: "capitalize",
                    width: "100%",
                    background:
                      returnImageTitle === "dropoff"
                        ? "#faa61a"
                        : "transparent",
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontWeight: "800",
                    "&:hover": {
                      background:
                        returnImageTitle === "dropoff"
                          ? "#faa61a"
                          : "transparent",
                      outline: "1.5px solid #faa61a",
                    },
                  }}
                  onClick={() => setReturnImageTitle("dropoff")}
                >
                  Dropoff Photos
                </Button>
              </Box>

              <Box sx={{ padding: 1}}>
                {loading && (
                  <Box>
                    <Backdrop
                      sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                      }}
                      open={loading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </Box>
                )}

                <Box>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: {
                        xs: "0.5rem",
                        sm: "0.5rem",
                        md: "1rem",
                        lg: "1rem",
                        xl: "1rem",
                      },
                      maxHeight: "500px",
                      overflowY: "auto",
                      overflowX: "none",
                    }}
                  >
                    {returnImageTitle === "pickup" && pickupImage.length > 0 && (
                      pickupImage.map((item, index) => {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "0.5rem",
                              border: "1px solid gray",
                              borderRadius: "5px",
                            }}
                          >
                            <Box
                              component={"img"}
                              src={item}
                              alt=""
                              sx={{
                                width: {
                                  xs: "100%",
                                  sm: "187px",
                                  md: "230px",
                                  lg: "230px",
                                  xl: "230px",
                                },
                                height: {
                                  xs: "100px",
                                  sm: "110px",
                                  md: "150px",
                                  lg: "150px",
                                  xl: "150px",
                                },
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px",
                                mb: 1,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontFamily: "Montserrat !important",
                                  color: "#fff",
                                  fontWeight: "500",
                                  textAlign: "center",
                                }}
                              >
                                {pickupImageTimestamp[index]
                                  ? moment(pickupImageTimestamp[index]).format(
                                      "ddd MMM DD YYYY"
                                    )
                                  : "N/A"}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontFamily: "Montserrat !important",
                                  color: "#fff",
                                  fontWeight: "500",
                                  textAlign: "center",
                                }}
                              >
                                {pickupImageTimestamp[index]
                                  ? moment(pickupImageTimestamp[index]).format(
                                      "HH:mm:ss"
                                    )
                                  : ""}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })
                    )}
                    {returnImageTitle === "dropoff" &&
                    dropoffImage.length > 0 && (
                      dropoffImage.map((item, index) => {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "0.5rem",
                              border: "1px solid gray",
                              borderRadius: "5px",
                            }}
                          >
                            <Box
                              component={"img"}
                              src={item}
                              alt=""
                              sx={{
                                width: {
                                  xs: "100%",
                                  sm: "187px",
                                  md: "230px",
                                  lg: "230px",
                                  xl: "230px",
                                },
                                height: {
                                  xs: "100px",
                                  sm: "110px",
                                  md: "150px",
                                  lg: "150px",
                                  xl: "150px",
                                },
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px",
                                mb: 1,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontFamily: "Montserrat !important",
                                  color: "#fff",
                                  fontWeight: "500",
                                  textAlign: "center",
                                }}
                              >
                                {dropoffImageTimestamp[index]
                                  ? moment(dropoffImageTimestamp[index]).format(
                                      "ddd MMM DD YYYY"
                                    )
                                  : "N/A"}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontFamily: "Montserrat !important",
                                  color: "#fff",
                                  fontWeight: "500",
                                  textAlign: "center",
                                }}
                              >
                                {dropoffImageTimestamp[index]
                                  ? moment(dropoffImageTimestamp[index]).format(
                                      "HH:mm:ss"
                                    )
                                  : ""}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })
                    )}
                  </Box>
                  {returnImageTitle === "pickup" && pickupImage?.length === 0 ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "4rem 0",
                          color: "#fff",
                          fontFamily: "Montserrat !important",
                          width:'100%',
                          textAlign:'center',
                          fontWeight:'600'
                        }}
                      >
                        "Picked up photos not available."
                      </Box>
                    ) : (
                      <></>
                    )}
                     {returnImageTitle === "dropoff" && dropoffImage?.length === 0 ? (
                      <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "4rem 0",
                        color: "#fff",
                        fontFamily: "Montserrat !important",
                        width:'100%',
                        textAlign:'center',
                        fontWeight:'600'
                      }}
                      >
                        "Dropoff photos not available."
                      </Box>
                    ) : (
                      <></>
                    )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default PickupDropoffImagesView;
