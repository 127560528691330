import {
  Box,
  IconButton,
  Link,
  Modal,
  Typography,
  useMediaQuery,
  Button,
} from "@mui/material";
import React, { PropsWithChildren, useState } from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import googlePlay from "assets/images/google-play-badge.png";
import iosBtn from "assets/images/ios_btn.png";
import { useDispatch, useSelector } from "react-redux";
import { setModalOpen, setLoginModalOpen } from "redux/redux-slice";
import { IStoreValue } from "utils/types";
import CloseIcon from "@mui/icons-material/Close";
import "./styles.scss";
import siteLogo from "assets/images/logo.png";
import downloadLogo from "assets/images/Download-logo.png";
import gemArrow from "assets/images/gem-arrow.png";
//import QrCode from 'assets/images/app_stpre_code.png'
import QRCode from "react-qr-code";
//Login popup
import LoginPopup from "../components/user-login/UserLogin";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "rgb(46 46 46)", //background.paper
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //width: 800,
  //bgcolor: 'rgb(46 46 46)',
  boxShadow: 24,
  p: 4,
};
/**
 * Main layout.
 */
const MainLayout: React.FC<
  PropsWithChildren<{ insideMainContainer?: boolean }>
> = ({ children, insideMainContainer = true }) => {
  const dispatch = useDispatch();
  const [disableScroll, setDisableScroll] = useState<Boolean>(false);
  const open = useSelector(
    (state: IStoreValue) => state.equipmentReducer.isModalOpen
  );
  const matchesMobileX = useMediaQuery("(max-width:425px)");
  const qrCodeValue = useSelector(
    (state: IStoreValue) => state.equipmentReducer.qrCodeValue
  );
  const Loginopen = useSelector(
    (state: IStoreValue) => state.equipmentReducer.isLoginModalOpen
  );

  let access_page = "";
  if (window.location.href.includes("terms_and_conditions")) {
    access_page = "terms_and_conditions";
  }
  if (window.location.href.includes("privacy_policy")) {
    access_page = "privacy_policy";
  }

  return (
    <>
      <Box
        component={"div"}
        className={
          disableScroll ? "scroll-disable layout-container" : "layout-container"
        }
      >
        <Header setDisableScroll={setDisableScroll} />
        {/* <Box className={insideMainContainer ? 'main-container' : ''}>
          {children}
        </Box>        
        <Footer /> */}

        {access_page == "" ? (
          <>
            <Box className={insideMainContainer ? "main-container" : ""}>
              {children}
            </Box>
            <Footer />
          </>
        ) : (
          <>
            <Box>{children}</Box>
          </>
        )}

        <Modal
          keepMounted
          open={open}
          onClose={() => dispatch(setModalOpen(false))}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            className="download-popup"
            sx={{
              ...style,
              width: matchesMobileX ? "calc(100vw - 100px)" : 400,
              padding: matchesMobileX ? "20px" : 4,
            }}
          >
            <IconButton
              onClick={() => dispatch(setModalOpen(false))}
              sx={{ position: "absolute", right: 0, top: 0, color: "#fff" }}
            >
              <CloseIcon />
            </IconButton>

            <div className="popup-headr-image">
              <img
                src={siteLogo}
                alt="GoEquipMe"
                className="download-popup-logo"
              />
            </div>
            <Typography
              className="download-text"
              id="keep-mounted-modal-title"
              component="p"
              sx={{ textAlign: "center" }}
            >
              Find this listing on the GoEquipMe app. Get Renting... Get Listing
              today!
            </Typography>

            {/* <div className="download-logo-parent">
            <img
              src={downloadLogo}
              alt='GoEquipMe'
              className='download-logo'
            />
          </div> */}
            {/* <div className="gem-arrow-parent">
              <img
                src={gemArrow}
                alt='GoEquipMe'
                className='gem-arrow'
              />
            </div> */}
            <div className="download-parent">
              <Link
                href="https://onelink.to/a7ptek" //https://onelink.to/ch9mhw
                target="_blank"
                sx={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  className="download-app"
                  sx={{ textTransform: "capitalize" }}
                >
                  Download App
                </Button>
              </Link>
              <Typography className="download-yellow-text" variant="h4">
                Scan the QR Code
              </Typography>
            </div>
            <div className="app_store_code">
              {/* <img
                src={QrCode}
                alt='GoEquipMe'
                className='app_store_qr'
              /> */}
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100px" }}
                value={qrCodeValue}
                viewBox={`0 0 256 256`}
              />
              <Typography className="download-text">
                Or search "GoEquipMe" in ...
              </Typography>
            </div>

            {/* <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Download Mobile Application
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            You can download GoEquipMe mobile application from Google Play store or App Store.
          </Typography> */}
            <Box
              className="download-buttons"
              sx={{
                display: "flex",
                justifyContent: "space-around",
                mt: 2,
                flexDirection: matchesMobileX ? "column" : "unset",
                alignItems: matchesMobileX ? "center" : "unset",
              }}
            >
              <Link
                className="download-store"
                href="https://apps.apple.com/us/app/goequipme-peer-to-peer/id1502954874"
                sx={{ paddingRight: 2 }}
                target="_blank"
              >
                <img
                  src={iosBtn}
                  alt="Google Play Store"
                  className="header-mobile-icon"
                />
              </Link>
              <Link
                className="download-store"
                href="https://play.google.com/store/apps/details?id=com.goequipeme"
                target="_blank"
                sx={{ paddingRight: 2 }}
              >
                <img
                  src={googlePlay}
                  alt="iOS Store"
                  className="header-mobile-icon"
                />
              </Link>
            </Box>
          </Box>
        </Modal>

        {/* Login Popup */}
        <Modal
          keepMounted
          open={Loginopen}
          onClose={() => dispatch(setLoginModalOpen(false))}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            className="download-popups"
            sx={{
              ...loginstyle,
              width: matchesMobileX ? "calc(100vw - 100px)" : 800,
              padding: "0px",
            }}
          >
            <IconButton
              onClick={() => dispatch(setLoginModalOpen(false))}
              sx={{ position: "absolute", right: 0, top: 0, color: "#faa61a" }}
            >
              <CloseIcon />
            </IconButton>
            {Loginopen && <LoginPopup />}
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default MainLayout;
