import React, { useCallback, useEffect, useState, useRef } from "react";
import { EquipmentAPIs } from "../../service/api-service";
import {
  Box,
  CircularProgress,
  Backdrop,
  Link,
  Typography,
  Button,
  TextField,
  Modal,
  useMediaQuery,
  IconButton,
  TextareaAutosize,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { useDispatch, useSelector } from "react-redux";
import {
  setalertMessagetype,
  setalertMessage,
  setSessionExpire,
  setNotificationCount,
  setNotificationList,
  setNotificationListCount,
} from "redux/redux-slice";

import { IApiResponseRawData, IStoreValue } from "utils/types";
import moment from "moment";
import "./newstyles.scss";
import "./styles.scss";

import momentTimeZone from "moment-timezone";

import { convertTimeZoneShortName } from "utils/commonFxns";

// import OrderDetailComponent from 'components/common/OrderDetailsComponent';
import VieworderDetail from "components/OrderDetail/VieworderDetail";

import CountdownTimer from "components/common/CountDownTimer";
import Img from "../../assets/images/dummy-profile-image.png";
import Orderdetailimg from "assets/images/details-page/booking2.png";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#b8b9bbfc", //background.paper
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4,
};

const { scrollX, scrollY } = window;

const ChatWindow = ({
  ChatData = null,
  NewMsg = null,
  MessageAdd = null,
  pageNo,
}) => {
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);
  const chatsRef = useRef<HTMLDivElement | null>(null);
  const lastScrollTopRef = useRef<number>(0);
  const loadingRef = useRef<boolean>(false);
  const chatPaginationData = useRef<any>(ChatData);
  const matchesMobileX = useMediaQuery("(max-width:425px)");

  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const UserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const Noticount = useSelector(
    (state: IStoreValue) => state.equipmentReducer.NotificationCount
  );
  // const AuthProfileData = useSelector((state: IStoreValue) => state.equipmentReducer.AuthProfileData)
  const [loading, setloading] = React.useState(false);
  const [Chatloading, setChatloading] = React.useState(false);
  const [chatResult, setChatResult] = React.useState([]);
  // const [TotalMsg, setTotalMsg] = React.useState(0);
  const [ChatMessage, setChatMessage] = React.useState("");
  const [OrderRequestPopup, setOrderRequestPopup] = React.useState(false);
  const [cancelOrderRequestPopup, setCancelOrderRequestPopup] = useState(false);

  const [OrderRequestClick, setOrderRequestClick] = React.useState(null);

  const [orderDetailPopup, setOrderDetailPopup] = useState(false);

  const [orderDetailPopupData, setOrderDetailPopupData] = useState<any>({
    thumbnail: "",
    id: "",
    make: "",
    model: "",
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    profile_pic: "",
    first_name: "",
    order_status: "",
    order_from: "",
    rental_price: 0,
    minimum_delivery_fees: 0,
    security_deposit_refund_txt: "",
    security_deposit: 0,
    grand_total_txt: "",
    grand_total: 0,
    protection_fee: 0,
    transport_fee: 0,
    admin_fee: 0,
    side: "renter",
    timeZone: "UTC",
    owner_name: "",
    renter_name: "",
    owner_profile_pic: "",
    refunded_protection_fee: 0,
  });
  const [extendedOrder, setExtendOrder] = useState([]);
  const [paymentExpiredPopup, setPaymentExpiredPopup] = useState(false);
  const [requestExpireMessage, setRequestExpireMessage] = useState("");

  const [orderRequestId, setOrderRequestId] = useState("");
  const [loadMoreMessage,setLoadMoreMessage] = useState<boolean>(false);

  const totalMessageCount = useRef<number>(0);

  const textAreaFocusRef = useRef<HTMLTextAreaElement>(null);

  const HandleOrderDetail = async (orderObj: any) => {
    let fname = orderObj["first_name"] ? orderObj["first_name"] : "NA";
    let lname = orderObj["last_name"] ? orderObj["last_name"] : "NA";

    let devilry = orderObj["delivery_charges"] * 2;
    let minimun = orderObj["minimum_delivery_fees"];
    let totalMi = devilry + minimun;
    let _changeInTotal = (totalMi / 100) * 10;

    let returnDepo =
      orderObj["returnd_deposit"] != undefined ||
      orderObj["returnd_deposit"] != null
        ? orderObj["returnd_deposit"]
        : null;

    //let rent_price = orderObj['price'];
    let rent_price =
      orderObj["price"] != undefined || orderObj["price"] != null
        ? orderObj["price"]
        : 0;

    let security_depo =
      returnDepo == orderObj["security_deposit"]
        ? 0
        : returnDepo == null
        ? orderObj["security_deposit"]
        : orderObj["security_deposit"] - returnDepo;

    let transport_fees =
      orderObj["delivery_charges"] * 2 + orderObj["minimum_delivery_fees"];

    let refunded_protection =
      orderObj["refunded_protection"] != undefined ||
      orderObj["refunded_protection"] != ""
        ? orderObj["refunded_protection"]
        : "";

    let protection_fees =
      orderObj["protection_fees_refunded"] == "1"
        ? orderObj["equipment_protection_fees"] -
          (refunded_protection == ""
            ? orderObj["equipment_protection_fees"]
            : refunded_protection)
        : orderObj["equipment_protection_fees"];

    let grand_total =
      orderObj["protection_fees_refunded"] != "1"
        ? orderObj["admin_commission"] != null
          ? orderObj["total_price"] +
            orderObj["equipment_protection_fees"] +
            _changeInTotal +
            orderObj["admin_commission"]
          : // -
            // (orderObj['order_status'] == "completed"
            //   ? orderObj['security_deposit']
            //   : 0)
            orderObj["total_price"] + orderObj["equipment_protection_fees"]
        : orderObj["admin_commission"] != null
        ? orderObj["total_price"] -
          (orderObj["order_status"] == "order_canceled"
            ? orderObj["security_deposit"]
            : 0) +
          _changeInTotal +
          orderObj["admin_commission"] +
          (orderObj["protection_fees_refunded"] == "1"
            ? orderObj["equipment_protection_fees"] -
              (refunded_protection == ""
                ? orderObj["equipment_protection_fees"]
                : refunded_protection)
            : 0)
        : orderObj["total_price"];

    /*****************/
    //added by pp 0n 25 apr 2024 due to timezone changes
    let timeZone = orderObj["timezone"];
    if (!timeZone) {
      const headers = {
        Authorization: `${AuthToken}`,
        request_from: "Web",
      };
      const timeZoneData = await EquipmentAPIs.getEquipTimeZone(
        { equip_id: orderObj["id"] },
        headers
      );
      if (timeZoneData.type === "RXSUCCESS") {
        timeZone = timeZoneData.data;
      }
    }
    // end by pp
    setOrderDetailPopupData({
      thumbnail: orderObj["thumbnail"],
      id: "Order ID #" + orderObj["order_id"],
      make: orderObj["make"] ? orderObj["make"] : "NA",
      model: orderObj["model"] ? orderObj["model"] : "",
      start_date: orderObj["start_date"],
      start_time: orderObj["start_time"],
      end_date: orderObj["end_date"],
      end_time: orderObj["end_time"],
      profile_pic: orderObj["profile_pic"] ? orderObj["profile_pic"] : Img,
      first_name: fname + " " + lname,
      order_status: orderObj["order_status"],
      order_from: orderObj["delivery_address"] == "" ? "Renter" : "Owner",
      rental_price: rent_price,
      minimum_delivery_fees: minimun,
      security_deposit_refund_txt:
        orderObj["returnd_deposit"] != undefined ||
        orderObj["returnd_deposit"] != null
          ? "(Refunded" +
            "-$" +
            new Intl.NumberFormat("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(orderObj["returnd_deposit"]) +
            ")"
          : "",
      security_deposit: security_depo,
      grand_total_txt:
        orderObj["order_status"] == "order_canceled" ? "(Refunded)" : "",
      grand_total: grand_total,
      protection_fee: protection_fees, //orderObj['equipment_protection_fees'],
      transport_fee: transport_fees,
      admin_fee: 0,
      side: "renter",
      timeZone: timeZone
        ? convertTimeZoneShortName(momentTimeZone().tz(timeZone).zoneAbbr())
        : "UTC",
      owner_name: orderObj["owner_name"],
      renter_name: orderObj["renter_name"],
      owner_profile_pic: orderObj["owner_profile_pic"],
      refunded_protection_fee: orderObj["refunded_protection"],
    });

    // Check extended orders available or not
    // check if key exists
    const hasKey = "order_extend" in orderObj;
    if (hasKey) {
      let extendedOrder = orderObj["order_extend"].length;
      if (extendedOrder > 0) {
        setExtendOrder(orderObj["order_extend"]);
      }
    }
    setOrderDetailPopup(true);
    // Extend order section end
  };

  // Get All data My Office Trailer
  const scrollToBottom = () => {
    var element = document.querySelector(".chats");
    element.scroll({ top: element.scrollHeight, behavior: "smooth" });
  };

  const LoadChat = async (ChatData, pageNumber = 1) => {
    try {
      if(pageNumber>1){
        setLoadMoreMessage(true);
      }
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const ChatResult = await EquipmentAPIs.GetChat(
        {
          user_id: ChatData["senderID"].toString(),
          equip_id: ChatData["equipId"].toString(),
          equipment_user: ChatData["reciverID"].toString(),
          page: pageNumber,
        },
        headers
      );

      if (ChatResult["type"] == "RXSUCCESS") {
        let chat = ChatResult["data"];
        chat = chat.reverse();

        chat = chat.map((item) => {
          // console.log("created at==",item.created_at)
          if (
            item.recever_id != UserId &&
            item.message.includes("would like to") &&
            item.message_type == "request"
          ) {
            item.message = `Your${
              item.message.includes("extend") ? " extend a" : ""
            } rental request has been submitted for approval.`;
          }
          return item;
        });

        chat = chat.filter(
          (item) =>
            !(
              item.recever_id != UserId &&
              item.message.includes("Your request") &&
              item.message_type == "request"
            )
        );
        if (pageNumber > 1) {
          setChatResult((prev) => [...chat, ...prev]);
        } else {
          setChatResult(chat);
        }
        setTimeout(() => {
          pageNo.current = pageNumber;
          loadingRef.current = false;
        }, 1000);
        if (pageNumber === 1) {
          setTimeout(() => {
            scrollToBottom();
          }, 0);
        } else {
          const chatWindow = chatsRef.current;
          const scrollPositionBefore = chatWindow.scrollHeight;
          setTimeout(() => {
            chatWindow.scrollTop =
              chatWindow.scrollHeight - scrollPositionBefore;
          }, 0);
        }

        totalMessageCount.current = ChatResult["total"];
      } else if (
        ChatResult["status"] == false &&
        ChatResult["slug"] == "Session Expired"
      ) {
        dispatch(setSessionExpire(true));
        return false;
      } else {
        dispatch(setalertMessage(ChatResult["message"]));
        dispatch(setalertMessagetype("error"));
        return false;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadMoreMessage(false);
    }
  };

  // Add new message chat
  const AddChat = async () => {
    try {
      let Message = ChatMessage.trim();

      setChatloading(true);
      if (Message == "" || Message.length == 0) {
        setChatloading(false);
        dispatch(setalertMessage("Enter message."));
        dispatch(setalertMessagetype("error"));
        return false;
      }
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const ChatResult = await EquipmentAPIs.SendChatMsg(
        {
          sender_id: UserId.toString(),
          sender_type: "equipment",
          recever_id: ChatData["reciverID"].toString(),
          message: Message,
          equip_id: ChatData["equipId"].toString(),
        },
        headers
      );

      if (ChatResult["type"] == "RXSUCCESS") {
        //LoadChat(ChatData)
        setChatloading(false);
        setChatMessage("");
        MessageAdd(true);
      } else if (
        ChatResult["status"] == false &&
        ChatResult["slug"] == "Session Expired"
      ) {
        setChatloading(false);
        dispatch(setSessionExpire(true));
        return false;
      } else {
        setChatloading(false);
        dispatch(setalertMessage(ChatResult["message"]));
        dispatch(setalertMessagetype("error"));
        return false;
      }
    } catch (err) {
      console.log(err);
      setChatloading(false);
    }
  };

  const NotificationsUpdate = async () => {
    try {
      //setChatloading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const Result = await EquipmentAPIs.UpdateNotification(
        {
          user_id: UserId.toString(),
        },
        headers
      );
      if (Result["type"] == "RXSUCCESS") {
        //setChatloading(false);
        let old_notification = Noticount;
        if (old_notification > 0) {
          dispatch(setNotificationCount(old_notification - 1));
        }
      } else {
        dispatch(setalertMessage(Result["message"]));
        dispatch(setalertMessagetype("error"));
        //setChatloading(false);
        return false;
      }
    } catch (err) {
      console.log(err);
      //setChatloading(false);
    }
  };

  // Update order request
  const UpdateOrderRequest = async (request_id, status) => {
    try {
      setloading(true);
      setOrderRequestPopup(false);

      // setRequestId(request_id)
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const Result = await EquipmentAPIs.UpdateRequest(
        {
          order_request_id: request_id,
          status: status.toString(),
        },
        headers
      );

      if (Result["type"] == "RXSUCCESS") {
        // setRequestStatus(status.toString())
        MessageAdd(true);
        setloading(false);
      }
      // else if (Result["status"] == false && Result["slug"] == "Session Expired") {
      //   setChatloading(false)
      //   dispatch(setSessionExpire(true));
      //   return false
      // }
      else {
        // setRequestStatus('')
        setloading(false);
        if (Result["statusCode"]) {
          setPaymentExpiredPopup(true);
        } else {
          setPaymentExpiredPopup(true);
          setRequestExpireMessage(Result["message"]);
        }
        MessageAdd(true);
        // setChatloading(false)
        // dispatch(setalertMessage(Result["message"]));
        // dispatch(setalertMessagetype("error"));
        // return false;
      }
    } catch (err) {
      // setRequestStatus('')
      setloading(false);
      console.log(err);
      //setChatloading(false);
    }
  };

  const HandleRequest = async (requestId, status, type) => {
    setOrderRequestClick({
      request_id: requestId,
      request_status: status,
      request_type: type,
    });
    setOrderRequestPopup(true);
  };

  const UpdateRequest = async () => {
    let req_Obj = OrderRequestClick;
    // setloading(true)
    UpdateOrderRequest(req_Obj.request_id, req_Obj.request_status);
  };

  const getOrderDetails = async (
    orderId: number,
    type: string,
    non: string
  ) => {
    if (type === "request_id" || type === "owner") {
      if (orderId > 0) {
        window.open(`/equipmentOrders/${orderId}/advance`, "_blank");
      }
      return;
    }
    try {
      const params = {
        order_id: orderId + "",
        type: "owner",
        non_requests: "non_requests",
      };
      setloading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const response = await EquipmentAPIs.getOrderDetails(params, headers);
      if (response.type == "RXSUCCESS") {
        await HandleOrderDetail(response.data[0]);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(setalertMessage(error["message"]));
      dispatch(setalertMessagetype("error"));
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    textAreaFocusRef.current?.focus();
    if (ChatData && ChatData != null && ChatData["senderID"] != null) {
      chatPaginationData.current = ChatData;
      LoadChat(ChatData);
      // commented by GD due to scrolling on top error every time user hit a chat notification
      // window.scrollTo(0, 100);
      if (NewMsg && NewMsg != null) {
        if (
          ChatData["equipId"] == NewMsg["equipId"] &&
          ChatData["senderID"] == NewMsg["reciverID"] &&
          ChatData["reciverID"] == NewMsg["senderID"]
        ) {
          let old_count = Noticount;
          if (old_count > 0) {
            NotificationsUpdate();
            dispatch(setNotificationCount(old_count - 1));
          }
        }
      }
    }
  }, [ChatData, NewMsg]);

  const cancelOrderByRenter = async () => {
    try {
      setCancelOrderRequestPopup(false);
      setloading(true);
      const data = {
        order_id: orderRequestId?.toString(),
        user_id: UserId.toString(),
        reason: "Cancelled by renter",
      };
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const Result = await EquipmentAPIs.CancelOrder(data, headers);
      if (Result.type === "RXSUCCESS") {
        dispatch(setalertMessage("Order cancelled successfully."));
        dispatch(setalertMessagetype("success"));
        MessageAdd(true);
      } else {
        setRequestExpireMessage(Result?.message);
        setPaymentExpiredPopup(true);
      }
    } catch (error) {
      dispatch(setalertMessage(error["message"]));
      dispatch(setalertMessagetype("error"));
    } finally {
      setloading(false);
    }
  };

  const RenterTimerComp = ({ msgobj, setOrderRequestId }) => {
    const shouldShow =
      msgobj["expire_at"] && msgobj["order_request_status"] === "0";
    return (
      <div>
        {shouldShow && (
          <>
            <Box
              className="chat-btn-grp"
              sx={{
                marginTop: "20px !important",
              }}
            >
              <Button
                sx={{
                  width: "60%",
                  fontWeight: 700,
                  padding: "0px 0px !important",
                  backgroundColor: "rgb(161, 1, 1) !important",
                  borderRadius: "5px",
                }}
                type="button"
                variant="contained"
                onClick={() => {
                  setOrderRequestId(msgobj["renter_order_id"]);
                  setCancelOrderRequestPopup(true);
                }}
              >
                Cancel Request
              </Button>
            </Box>

            <div
              className="notification-timer-div"
              style={{ lineHeight: "15px" }}
            >
              <span>Note: </span>
              <span>
                {msgobj["rfn"].charAt(0).toUpperCase() + msgobj["rfn"].slice(1)}{" "}
                has
              </span>
              <CountdownTimer
                expirationDateTime={msgobj["expire_at"]}
                color="#fff"
              />
              <span>
                &nbsp;to accept this offer before it is withdrawn automatically
              </span>
            </div>
          </>
        )}
      </div>
    );
  };

  const handleScroll = async () => {
    const chatsElement = chatsRef.current;
    if (chatsElement) {
      const currentScrollTop = chatsElement.scrollTop;

      // Detect scrolling direction without using state
      if (
        currentScrollTop < lastScrollTopRef.current &&
        totalMessageCount.current > 20 &&
        chatResult.length < totalMessageCount.current
      ) {
        const chatItems = chatsElement.querySelectorAll(".chat-item");
        const maxPage = Math.ceil(totalMessageCount.current / 20);
        if (chatItems.length >= 3) {
          // Define an array for the first three items
          const items = [
            chatItems[0] as HTMLElement,
            chatItems[1] as HTMLElement,
            chatItems[2] as HTMLElement,
          ];

          let shouldLoadChat = false;

          // Loop through the first three items
          for (const item of items) {
            // Get the position of each item relative to the chat container
            const itemPosition = item.offsetTop;

            // Check if the item is within the visible scroll area
            const visibleAreaStart = chatsElement.scrollTop;
            const visibleAreaEnd = visibleAreaStart + chatsElement.clientHeight;

            if (
              itemPosition >= visibleAreaStart &&
              itemPosition <= visibleAreaEnd
            ) {
              shouldLoadChat = true;
              break; // Stop checking once we find one item in the visible area
            }
          }
          // console.log(shouldLoadChat,loadingRef.current,pageNo.current,maxPage)
          // If any of the first three items are visible and not already loading
          if (shouldLoadChat && !loadingRef.current) {
            loadingRef.current = true;
            pageNo.current = pageNo.current + 1;
            if (pageNo.current <= maxPage) {
              await LoadChat(chatPaginationData.current, pageNo.current);
            }
          }
        }
      }
      // Update the last scroll position
      lastScrollTopRef.current = currentScrollTop;
    }
  };

  useEffect(() => {
    // Make sure the 'chats' element exists before adding the listener
    const chatsElement = chatsRef.current;

    if (chatsElement) {
      // Add the scroll event listener to the 'chats' element
      chatsElement.addEventListener("scroll", handleScroll);
    }

    // Cleanup the event listener on unmount
    return () => {
      if (chatsElement) {
        chatsElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <>
      <Box className="rentyrd-grid-item rentyrd-right">
        <Box className="rentyrd-left-float">
          <Box className="equipment-details-heading">
            <Typography
              sx={{
                color: "#fff",
                textAlign: "center",
                fontSize: "clamp(16px, 3vw , 25px)",
                fontFamily: "GothamBold !important",
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
              }}
            >
              {ChatData?.EquipMake}
            </Typography>
          </Box>
          <Box className="chat-name">
            <Typography>Message from {ChatData?.reciverName}</Typography>
          </Box>
          <Box className="chat-box">
            {/* {Chatloading && (              
              <div>
                <Backdrop
                  sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={Chatloading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </div>             
            )} */}

            <Box>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </Box>

            <Box className="chats" ref={chatsRef}>
              <Box className="chats-inner">
              {loadMoreMessage && <Box sx={{ display: "flex",alignItems:"center",justifyContent:"center" }}>
                  <CircularProgress />
                </Box>}
                {totalMessageCount.current > 0 ? (
                  <>
                    {chatResult.map((msgobj, indx) => (
                      <>
                        <span ref={messagesEndRef} className="chat-item">
                          {msgobj["sender_id"] == ChatData["senderID"] ? (
                            <>
                              <Box className="chat-me">
                                <Typography>
                                  {/* Added on 18 Jan 2024 MK */}
                                  {/* {(msgobj["message"].includes("would like to")) ? ( */}
                                  {/* condition changed by GD 18 march 2024 and cond also applied on load chat api */}
                                  {msgobj["message_type"] == "request" &&
                                  msgobj["message"].includes(
                                    "would like to"
                                  ) ? (
                                    <>
                                      <Box>
                                        Your{" "}
                                        {msgobj["message"].includes("extend")
                                          ? " extend a"
                                          : ""}{" "}
                                        rental request has been submitted for
                                        approval
                                      </Box>
                                      <RenterTimerComp
                                        msgobj={msgobj}
                                        setOrderRequestId={setOrderRequestId}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <Box>{msgobj["message"]}</Box>
                                      <RenterTimerComp
                                        msgobj={msgobj}
                                        setOrderRequestId={setOrderRequestId}
                                      />
                                    </>
                                  )}

                                  {msgobj["order_id"] != null && (
                                    <Box
                                      display={"flex"}
                                      justifyContent={"flex-end"}
                                      onClick={() =>
                                        getOrderDetails(
                                          msgobj["order_id"],
                                          "owner",
                                          "non_request"
                                        )
                                      }
                                    >
                                      <Link
                                        sx={{
                                          fontSize: "clamp(10px, 2vw, 18px)",
                                          color: "black",
                                          textDecorationColor: "black",
                                          padding: "0.5rem 0",
                                          textAlign: "right",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Click here to advance this order.
                                      </Link>
                                    </Box>
                                  )}

                                  {/* Added on 18 Jan 2024 MK */}
                                  {/* {msgobj["message"]} */}
                                  <span>
                                    {/* {moment(msgobj["created_at"]).format("MM-DD-YYYY") ==
                                      moment(new Date()).format("MM-DD-YYYY")
                                      ? moment(msgobj["created_at"]).format("hh:mm a")
                                      : moment(msgobj["created_at"]).format("hh:mm a MM-DD-YYYY")} */}
                                    {/* above line is commented and next line added by pp on 25 Apr 2024 due to timezone changes*/}
                                    {msgobj["sender_timezone"]
                                      ? convertTimeZoneShortName(
                                          momentTimeZone
                                            .tz(
                                              msgobj["created_at"],
                                              msgobj["sender_timezone"]
                                            )
                                            .format("hh:mm A (z) MM/DD/YY")
                                        )
                                      : moment(msgobj["created_at"]).format(
                                          "hh:mm A (UTC) MM/DD/YY"
                                        )}
                                  </span>
                                </Typography>
                              </Box>
                            </>
                          ) : (
                            <Box className="chat-not-me">
                              <Typography>
                                {msgobj["message"]}
                                <span>
                                  {msgobj["order_id"] != null && (
                                    <Box
                                      display={"flex"}
                                      justifyContent={"flex-end"}
                                      onClick={() =>
                                        getOrderDetails(
                                          msgobj["order_id"],
                                          "",
                                          ""
                                        )
                                      }
                                    >
                                      <Link
                                        sx={{
                                          fontSize: "clamp(10px, 2vw, 18px)",
                                          color: "blue",
                                          textDecorationColor: "blue",
                                          padding: "0.5rem 0",
                                          textAlign: "right",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Click Here for Order Details
                                      </Link>
                                    </Box>
                                  )}

                                  {msgobj["order_request_id"] != undefined &&
                                    msgobj["order_request_id"] != null && (
                                      <>
                                        <Box
                                          display={"flex"}
                                          justifyContent={"flex-end"}
                                        >
                                          <Link
                                            href={
                                              "/order-request/rental-summary/" +
                                              msgobj["order_request_id"] +
                                              "/" +
                                              UserId
                                            }
                                            target="_blank"
                                            sx={{
                                              fontSize:
                                                "clamp(10px, 2vw, 18px)",
                                              color: "blue",
                                              textDecorationColor: "blue",
                                              padding: "0.5rem 0",
                                              textAlign: "right",
                                            }}
                                          >
                                            Click Here for Details
                                          </Link>
                                        </Box>

                                        {msgobj["order_request_status"] !=
                                          null &&
                                          msgobj["order_request_status"] ==
                                            "1" && (
                                            <Box
                                              display={"flex"}
                                              justifyContent={"flex-end"}
                                              onClick={() =>
                                                getOrderDetails(
                                                  msgobj["parent_order_id"],
                                                  "owner",
                                                  ""
                                                )
                                              }
                                            >
                                              <Link
                                                sx={{
                                                  fontSize:
                                                    "clamp(10px, 2vw, 18px)",
                                                  color: "blue",
                                                  textDecorationColor: "blue",
                                                  padding: "0.5rem 0",
                                                  textAlign: "right",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                Click here to advance this
                                                order.
                                              </Link>
                                            </Box>
                                          )}

                                        {msgobj["order_request_status"] ==
                                        "0" ? (
                                          <>
                                            <Box
                                              className="chat-btn-grp"
                                              sx={{
                                                marginTop: "20px !important",
                                              }}
                                            >
                                              <Button
                                                className="reject-btn"
                                                type="button"
                                                variant="contained"
                                                onClick={(e) => {
                                                  HandleRequest(
                                                    msgobj["order_request_id"],
                                                    "2",
                                                    "Reject"
                                                  );
                                                  //UpdateOrderRequest(msgobj['order_request_id'], "2")
                                                }}
                                              >
                                                Reject
                                              </Button>
                                              <Button
                                                className="accept-btn"
                                                type="button"
                                                variant="contained"
                                                onClick={(e) => {
                                                  HandleRequest(
                                                    msgobj["order_request_id"],
                                                    "1",
                                                    "Accept"
                                                  );
                                                  //UpdateOrderRequest(msgobj['order_request_id'], "1")
                                                }}
                                              >
                                                Accept
                                              </Button>
                                            </Box>
                                            {msgobj["expire_at"] && (
                                              <div className="notification-timer-div">
                                                <span>Note: </span>
                                                <span>
                                                  The rental request will
                                                  automatically cancel if not
                                                  accepted in next
                                                </span>
                                                <CountdownTimer
                                                  expirationDateTime={
                                                    msgobj["expire_at"]
                                                  }
                                                />
                                                {/* <span>
                                                &nbsp;
                                                {msgobj["sender_timezone"]
                                                  ? momentTimeZone
                                                      .tz(
                                                        msgobj["expire_at"],
                                                        msgobj[
                                                          "sender_timezone"
                                                        ]
                                                      )
                                                      .format("MM/DD/YY")
                                                  : moment(
                                                      msgobj["expire_at"]
                                                    ).format("MM/DD/YY")}
                                              </span>
                                              <span> at </span>
                                              <span>
                                                {msgobj["sender_timezone"]
                                                  ? momentTimeZone
                                                      .tz(
                                                        msgobj["expire_at"],
                                                        msgobj[
                                                          "sender_timezone"
                                                        ]
                                                      )
                                                      .format("hh:mm A")
                                                  : moment(
                                                      msgobj["expire_at"]
                                                    ).format("hh:mm A")}
                                              </span> */}
                                              </div>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <Box className="chat-btn-grp">
                                              {msgobj["order_request_status"] ==
                                              "1" ? (
                                                <Button
                                                  className="accepted-btn"
                                                  type="button"
                                                  variant="contained"
                                                  sx={{
                                                    width: "65%",
                                                    backgroundColor:
                                                      "#faa61a !important",
                                                  }}
                                                >
                                                  Rental Accepted
                                                </Button>
                                              ) : msgobj[
                                                  "order_request_status"
                                                ] == "2" ? (
                                                <Button
                                                  className="rejected-btn"
                                                  type="button"
                                                  variant="contained"
                                                  sx={{
                                                    width: "65%",
                                                    backgroundColor:
                                                      "#faa61a !important",
                                                  }}
                                                >
                                                  Rental Rejected
                                                </Button>
                                              ) : msgobj[
                                                  "order_request_status"
                                                ] == "4" ? (
                                                <Button
                                                  className="rejected-btn"
                                                  type="button"
                                                  variant="contained"
                                                  sx={{ width: "100" }}
                                                >
                                                  This rental request has
                                                  expired
                                                </Button>
                                              ) : (
                                                <Button
                                                  className="rejected-btn"
                                                  type="button"
                                                  variant="contained"
                                                  sx={{ width: "80%" }}
                                                >
                                                  Cancelled by Renter
                                                </Button>
                                              )}
                                            </Box>
                                          </>
                                        )}
                                        <Box
                                          sx={{
                                            fontSize: "clamp(9px, 2vw, 15px)",
                                            color: "#111111",
                                            display: "flex",
                                            flexDirection: "column",
                                            flexWrap: "wrap",
                                            marginBottom: "10px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Message renter directly within this
                                          chat.
                                        </Box>
                                      </>
                                    )}
                                  {/* 
                                  // moment(msgobj["created_at"]).format("MM-DD-YYYY") ==
                                  //   moment(new Date()).format("MM-DD-YYYY")
                                  //   ? moment(msgobj["created_at"]).format("hh:mm a")
                                    // : moment(msgobj["created_at"]).format("hh:mm a MM-DD-YYYY")
                                   */}
                                  {/* next line added by pp on 25 apr 2024 due to timezone change and upper lines commented */}
                                  {msgobj["sender_timezone"]
                                    ? convertTimeZoneShortName(
                                        momentTimeZone
                                          .tz(
                                            msgobj["created_at"],
                                            msgobj["sender_timezone"]
                                          )
                                          .format("hh:mm A (z) MM/DD/YY")
                                      )
                                    : moment(msgobj["created_at"]).format(
                                        "hh:mm A (UTC) MM/DD/YY"
                                      )}
                                </span>
                              </Typography>
                            </Box>
                          )}
                        </span>
                      </>
                    ))}
                  </>
                ) : (
                  <Typography
                    sx={{
                      color: "#fff",
                      textAlign: "center",
                      fontSize: "clamp(16px, 3vw , 25px)",
                      fontFamily: "GothamBold !important",
                    }}
                  >
                    No message
                  </Typography>
                )}
              </Box>
            </Box>

            <Box
              className="chat-msg-box"
              sx={{ position: "relative !important", mb: "1rem" }}
            >
              {/* <TextField
                className="type-msg"
                placeholder="Message here"
                value={ChatMessage}
                //onChange={SubmitChatMessage}
                onChange={(e) => {
                  setChatMessage(e.target.value)
                }}
                autoFocus
                onKeyPress={event => {
                  if(Chatloading) return;
                  if (event.key === 'Enter') {
                    AddChat()
                  }
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    color: "#2e2e2e",
                    background: "#B8B9BB",
                    fontFamily: "GothamBold !important",
                    fontSize: "clamp(14px, 3vw, 18px) !important",
                  },
                  "& label.Mui-focused": {
                    color: "#2e2e2e",
                    fontWeight: "bold",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none !important",
                    },
                    "&:hover fieldset": {
                      borderColor: "#2e2e2e",
                      border: "none !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "none !important",
                    },
                  },
                }}
              /> */}
              <TextareaAutosize
                value={ChatMessage}
                onChange={(e) => setChatMessage(e.target.value)}
                ref={textAreaFocusRef}
                onKeyDown={(event) => {
                  if (Chatloading) return;
                  if (event.key === "Enter" && !event.shiftKey) {
                    event.preventDefault();
                    AddChat();
                  }
                }}
                aria-label="empty textarea"
                placeholder="Message here"
                minRows={2}
                disabled={Chatloading}
                style={{
                  width: "97%",
                  resize: "none",
                  color: "#2e2e2e",
                  background: "#B8B9BB",
                  fontFamily: "GothamBold !important",
                  fontWeight: "bold",
                  borderRadius: "4px",
                  fontSize: "18px",
                  padding: "18px 0px 0px 16px",
                  maxHeight: "42px",
                }}
              />
              {Chatloading ? (
                <CircularProgress />
              ) : (
                <Button className="send-btn" onClick={AddChat}>
                  Send
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Modal
        keepMounted
        open={OrderRequestPopup}
        onClose={() => setOrderRequestPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popup"
          sx={{
            ...style,
            width: matchesMobileX ? "calc(100vw - 100px)" : 400,
            padding: matchesMobileX ? "20px" : 4,
            bgcolor: "#2d2d2d",
            borderRadius: "10px",
          }}
        >
          <IconButton
            onClick={() => setOrderRequestPopup(false)}
            sx={{ position: "absolute", right: 0, top: 0, color: "#fff" }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            className="download-text"
            id="keep-mounted-modal-title"
            component="p"
            sx={{
              textAlign: "center",
              color: "#fff !important",
              fontSize: "20px !important",
              fontFamily: "GothamBold !important",
            }}
          >
            {OrderRequestPopup && OrderRequestClick.request_type == "Reject" ? (
              <>Are you sure you want to REJECT?</>
            ) : (
              <>Are you sure you want to ACCEPT?</>
            )}
          </Typography>

          <Box className="chat-not-me">
            <Typography>
              <span>
                <Box className="chat-btn-grp">
                  <Button
                    className="reject-btn"
                    type="button"
                    variant="contained"
                    onClick={() => {
                      setOrderRequestPopup(false);
                    }}
                    sx={{
                      fontSize: "16px !important",
                      fontFamily: "GothamBold !important",
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="accept-btn"
                    type="button"
                    variant="contained"
                    onClick={UpdateRequest}
                    sx={{
                      fontSize: "16px !important",
                      fontFamily: "GothamBold !important",
                    }}
                  >
                    YES
                  </Button>
                </Box>
              </span>
            </Typography>
          </Box>
        </Box>
      </Modal>

      {/* cancel order confirmation */}
      <Modal
        keepMounted
        open={cancelOrderRequestPopup}
        onClose={() => setCancelOrderRequestPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popup"
          sx={{
            ...style,
            width: matchesMobileX ? "calc(100vw - 100px)" : 400,
            padding: matchesMobileX ? "20px" : 4,
            bgcolor: "#2d2d2d",
            borderRadius: "10px",
          }}
        >
          <IconButton
            onClick={() => setCancelOrderRequestPopup(false)}
            sx={{ position: "absolute", right: 0, top: 0, color: "#fff" }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            className="download-text"
            id="keep-mounted-modal-title"
            component="p"
            sx={{
              textAlign: "center",
              color: "#fff !important",
              fontSize: "20px !important",
              fontFamily: "GothamBold !important",
            }}
          >
            Are you sure you want to CANCEL?
          </Typography>

          <Box className="chat-not-me">
            <Typography>
              <span>
                <Box className="chat-btn-grp">
                  <Button
                    className="reject-btn"
                    type="button"
                    variant="contained"
                    onClick={() => {
                      setCancelOrderRequestPopup(false);
                    }}
                    sx={{
                      fontSize: "16px !important",
                      fontFamily: "GothamBold !important",
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="accept-btn"
                    type="button"
                    variant="contained"
                    onClick={cancelOrderByRenter}
                    sx={{
                      fontSize: "16px !important",
                      fontFamily: "GothamBold !important",
                    }}
                  >
                    YES
                  </Button>
                </Box>
              </span>
            </Typography>
          </Box>
        </Box>
      </Modal>

      {/* Order Detail Popup Section */}
      <Modal
        keepMounted
        open={orderDetailPopup}
        onClose={() => setOrderDetailPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            //  overflowX:'hidden',
            bgcolor: "#2e2e2e",
            border: "5px solid #faa61a",
            borderRadius: "10px",
            outline: "none !important",
            // p: 1,
            width: {
              xs: "90%",
              sm: "400px",
              md: "500px",
              lg: "500px",
              xl: "500px",
            },
          }}
        >
          <IconButton
            onClick={() => setOrderDetailPopup(false)}
            sx={{
              position: "absolute",
              top: "-20px",
              right: "-20px",
              bgcolor: "#faa61a",
              color: "#000",
              "&:hover": {
                bgcolor: "#faa61a",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
          // className="login__main"
          >
            {/* <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            > */}
            <Box
              sx={{
                // width: {
                //   xs: "80%",
                //   sm: "400px",
                //   md: "500px",
                //   lg: "500px",
                //   xl: "500px",
                // },
                width: "100%",
                maxHeight: "600px",
                overflowY: "auto",
                p: 3,
                boxSizing: "border-box",
              }}
              // className="login__main-div__form popup-cmmn popup-cmmn-modal pupup-scroll edit-eqp-inner"
            >
              {/* <span onClick={() => setOrderDetailPopup(false)}>
                  <CloseIcon className="modal-popup" />
                </span> */}

              <Box
                // className="new-modal-heading"
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  gap: "0.5rem",
                }}
              >
                <Box
                  component={"img"}
                  src={Orderdetailimg}
                  style={{ width: "22px", height: "auto" }}
                />
                {/* <img src={Orderdetailimg} style={{ width: "5%" }} /> */}
                {/* <Typography variant="h5">
                    <span style={{ color: "#faa61a" }}>Order</span>Detail
                  </Typography> */}

                <Typography
                  variant="h5"
                  sx={{
                    color: "#fff",
                    // fontSize: {
                    //   xs: "18px",
                    //   sm: "24px",
                    //   md: "26px",
                    //   lg: "28px",
                    //   xl: "32px",
                    // },
                    fontWeight: "700",
                    fontFamily: "Montserrat !important",
                    mb: 2,
                  }}
                >
                  <Typography
                    variant="h5"
                    component={"span"}
                    sx={{
                      color: "#faa61a",
                      fontWeight: "700",
                      fontFamily: "Montserrat !important",
                    }}
                  >
                    Order
                  </Typography>
                  Detail
                </Typography>
              </Box>

              <Box className="extend-rental-od">
                {loading && (
                  <Box>
                    <Backdrop
                      sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                      }}
                      open={loading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </Box>
                )}
                {orderDetailPopupData && (
                  <Box className="">
                    <VieworderDetail
                      OrderObj={orderDetailPopupData}
                      title={"no"}
                      ExtendedOrders={extendedOrder}
                      Success={(data) => {
                        if (data && typeof data === "object") {
                          if (
                            orderDetailPopupData?.id?.includes(data?.orderId)
                          ) {
                            const modifiedOrders = extendedOrder.map(
                              (order) => ({
                                ...order,
                                order_status: "order_canceled",
                              })
                            );
                            if (
                              JSON.stringify(modifiedOrders) !==
                              JSON.stringify(extendedOrder)
                            ) {
                              setExtendOrder(modifiedOrders);
                            }
                          } else {
                            const modifiedOrders = extendedOrder.map(
                              (order) => {
                                if (
                                  parseInt(data?.orderId) ===
                                  parseInt(order?.id)
                                ) {
                                  return {
                                    ...order,
                                    order_status: "order_canceled",
                                  };
                                }
                                return order;
                              }
                            );
                            if (
                              JSON.stringify(modifiedOrders) !==
                              JSON.stringify(extendedOrder)
                            ) {
                              setExtendOrder(modifiedOrders);
                            }
                          }
                        }
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            {/* </Box> */}
          </Box>
        </Box>
      </Modal>

      <Box>
        <Dialog
          open={paymentExpiredPopup}
          // open={true}
          aria-labelledby="draggable-dialog-title"
        >
          <Box className="session-expire">
            <DialogContent>
              <DialogTitle
                style={{
                  color: "#fff",
                  fontSize: "24px",
                  textAlign: "center",
                  marginTop: "-32px",
                }}
              >
                Error
              </DialogTitle>
              <DialogContentText style={{ color: "#fff", fontSize: "18px" }}>
                {requestExpireMessage
                  ? requestExpireMessage
                  : "This Rental request is no longer valid."}
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                className=""
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "#de8d14 !important",
                  color: "#fff !important",
                  padding: "6px 50px 6px 50px",
                  borderRadius: "7px",
                  fontFamily: "GothamBold !important",
                  fontSize: "20px !important",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // UpdateOrderRequest(OrderRequestClick?.request_id, "2");
                  MessageAdd(true);
                  setPaymentExpiredPopup(false);
                  console.log("order request expired");
                }}
              >
                OKAY
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Box>
    </>
  );
};

export default ChatWindow;
