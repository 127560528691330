import React, { useEffect, useState, useCallback } from "react";
import './styles.scss'
import { Box, Button, Container, Typography, Backdrop, CircularProgress, useMediaQuery, Modal, IconButton } from '@mui/material'
import MainLayout from '../../layouts/MainLayout'
import TrackDevice from '../../assets/images/equip-tracking/tracking-mobile.png'
import Sale from '../../assets/images/equip-tracking/sale.png'
import CheckIcon from '../../assets/images/icons_checkmark.png'
import wiredDevice from '../../assets/images/equip-tracking/wired-device.png'
import wirelessDevice from '../../assets/images/equip-tracking/wireless-device.png'
import LowestTag from '../../assets/images/equip-tracking/lowest-price-tag.png'
import QuoteIcon1 from '../../assets/images/equip-tracking/quote-1.png'
import QuoteIcon2 from '../../assets/images/equip-tracking/quote-2.png'
import GPSHomeImg from '../../assets/images/equip-tracking/tracking_user.jpg'
import JcbGrp from '../../assets/images/equip-tracking/jcb-grp-img.png'
import SquareIcon from '../../assets/images/equip-tracking/SquareIcon.png'

import { useDispatch, useSelector } from "react-redux";
import { setalertMessagetype, setalertMessage } from "redux/redux-slice";
import { EquipmentAPIs } from "../../service/api-service";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import CloseIcon from '@mui/icons-material/Close'
import fireAnalyticsEvent from "service/firebase";

const loginstyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  //width: 800,
  //bgcolor: 'rgb(46 46 46)', 
  boxShadow: 24,
  p: 4,
}
const Tracking = () => {

  const dispatch = useDispatch();
  
  const [loading, setLoading] = useState(false);

  const [Name, setName] = React.useState(null);
  const [Email, setEmail] = React.useState(null);
  const [Phone, setPhone] = React.useState('');
  const [Device, setDevice] = React.useState(null);
  const [AlreadyList, setAlreadyList] = React.useState(false);
  const [SuccessPopup, setSuccessPopup] = React.useState(false);

  const matchesMobileX = useMediaQuery('(max-width:425px)')

  // by GD 29 Jan 2024 for firebase/google analylics
  useEffect(() => {
    window.scroll(0, 0)
    // fireAnalyticsEvent("Toolbox_Equipment_Tracking");
    // fireAnalyticsEvent("GPS_My_Office_trailer_Equipment_Tracking");
    fireAnalyticsEvent("GPS_Toolbox_to_Equipment_Tracking");
  }, []);
  // Handle name request
  const HandleName = (event) => {
    let name = event.target.value;
    var letters = name.replace(/[^A-Za-z ]+/g, '');
    // let isChar = name.split(' ').join().length > 0;
    // console.log('object', name.length);
    
    if(letters != null) {
      if(letters.length > 0 ) {
        letters = letters.trimStart(); 
      }
    }

    setName(letters);
  }

  // Handle email request
  const HandleEmail = (event) => {
    let email = event.target.value;
    setEmail(email);
  }

  // Define phone number format
  const phoneFormat = (phNumber) => {
    var match = phNumber.match(/(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var number = [match[1], "-", match[2], "-", match[3]].join("");
      setPhone(number);
      return;
    }
    setPhone(phNumber);
  };

  // Handle phone number request
  const HandlePhone = (event) => {
    let val = event.target.value;
    val = val.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
    if (val.length == 0) {
      setPhone(val);
    } else {
      phoneFormat(val)
    }
  }

  // Handle device request
  const HandleDevice = (event) => {
    let numbers = event.target.value;
   
    // if (numbers == 0) {
    //   setDevice(1);
    // } else {
    //   setDevice(numbers);
    // }
    setDevice(numbers);
  }

  // Create GPS Request
  const GpsRequest = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      var name = Name; 
      if(name != null) {
        if(name.length > 0 ) {
          name = name.trimStart(); 
        }
      }

      if (name == '' || name == null) {
        setName("");
        dispatch(setalertMessage('Please fill your name to continue!'));
        dispatch(setalertMessagetype('error'));
        setLoading(false);
        return false;
      }

      var email = Email;
      var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (email == "" || email == null) {
        dispatch(setalertMessage("Please fill email address to continue!!"));
        dispatch(setalertMessagetype("error"));
        setLoading(false);
        return false;
      }
      if (!email.match(mailformat)) {
        dispatch(setalertMessage("Invalid email address!"));
        dispatch(setalertMessagetype("error"));
        setLoading(false);
        return false;
      }

      var phone = Phone;
      if (phone == '') {
        dispatch(setalertMessage('Please fill your phone number to continue!'));
        dispatch(setalertMessagetype('error'));
        setLoading(false);
        return false;
      }

      if (phone.length != 12) {
        dispatch(setalertMessage('Please fill valid phone number to continue!'));
        dispatch(setalertMessagetype('error'));
        setLoading(false);
        return false;
      }

      var device = Device;      
      if (device == null) {
        dispatch(setalertMessage('Please fill how many devices do you want?'));
        dispatch(setalertMessagetype('error'));
        setLoading(false);
        return false;
      }
      if (device == 0 ) {
        dispatch(setalertMessage('Please fill valid input in device field!'));
        dispatch(setalertMessagetype('error'));
        setLoading(false);
        return false;
      }

      var listdevice = AlreadyList ? "yes" : "no";
      let body = {
        name: name,
        email: email,
        phone: phone,
        how_many_device_need: device,
        listed_device: listdevice,
        request_from: 'web',
      }

     // const headers = { Authorization: `${AuthToken}` ,request_from : "Web" };
      const RequestData = await EquipmentAPIs.GpsDeviceRequestCreate(
        body
       // headers
      );
      if (RequestData['type'] == "RXSUCCESS") {       
        setSuccessPopup(true);
        // dispatch(setalertMessage(RequestData['message']));
        // dispatch(setalertMessagetype('success'));
        setLoading(false);

        // Reset values
        setName('');
        setEmail('');
        setPhone('');
        setDevice('');
        setAlreadyList(false);

        return false;

      } else {
        dispatch(setalertMessage(RequestData['message']));
        dispatch(setalertMessagetype('error'));        
        setLoading(false);
        return false;
      }

    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const [activeTab, setActiveTab] = useState(1);

  return (
    <MainLayout insideMainContainer={false}>

    {loading  && (
          <Box>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        )}

      <Box className='gps-home'>  
        <Container>
          <Box className='gps-home-main'>
            <Box className='gps-home-grid'>
              <Box className='gps-home-grid-left'>
                <Box className='gps-home-grid-left-inner'>
                  <Box className="gps-home-grid-left-head">
                    <Typography className='gps-home-grid-left-heading' sx={{ fontFamily:'Montserrat !important' }}>
                      Track Your Equipment With GoEquipMe
                    </Typography>
                    <Box className="gps-home-grid-left-head-img">
                      <img src={TrackDevice} alt="" className="track-device"/>
                    </Box>
                  </Box>                 


                  <Box className="gps-home-grid-2">
                    <Box className="gps-home-grid-2-left">
                      <img src={LowestTag} alt="" className="track-sale" />
                    </Box>
                    <Box className="gps-home-grid-2-right">
                      <ul className="grid-home-grid-2-right-ul">
                        <li>
                          <img src={CheckIcon} alt="" />
                          Protect Your Investment
                        </li>
                        <li>
                          <img src={CheckIcon} alt="" />
                          Low-Cost Asset Tracking
                        </li>
                        <li>
                          <img src={CheckIcon} alt="" />
                          Simple Installation
                        </li>
                        <li>
                          <img src={CheckIcon} alt="" />
                          No Setup Fees or Contracts
                        </li>
                      </ul>
                    </Box>
                  </Box>

                  <Box className='tracking-tab'>
                    <Box className='tab-btn-grp'>
                      <Button onClick={() => { setActiveTab(1);  }}
                        className={`tab-button-1 ${activeTab === 1 && "active"}`}>
                        <span>Wireless</span>
                      </Button>
                      <Button onClick={() => { setActiveTab(2);  }}
                        className={`tab-button-1 ${activeTab === 2 && "active"}`}>
                        <span>Wired</span>
                      </Button>
                     
                    </Box>
                    <Box className='tracking-tab-content'>
                      {activeTab === 1 && <Box className='tab-cmn tab-1'>
                        <Box>
                          <Box className='tab-grid-1'>
                            <Box className='tab-grid-1-in'>
                              <Box className="tab-grid-1-img">
                                <img src={wirelessDevice} alt="" />
                              </Box>
                              <Box>
                                <Typography variant="h3" sx={{ color: '#fff', fontFamily: 'GothamBold !important' }}>
                                  JUST $249*
                                </Typography>
                                <ul className="grid-home-grid-2-right-ul">
                                  <li>
                                    <div className="yellow-sqr"></div>
                                    No Contract, You Own It
                                  </li>
                                  <li>
                                    <div className="yellow-sqr"></div>
                                    3-Year Service & Warranty
                                  </li>
                                  <li>
                                    <div className="yellow-sqr"></div>
                                    Small & Easy to Hide
                                  </li>
                                  <li>
                                    <div className="yellow-sqr"></div>
                                    Up to 7-Year Battery Life
                                  </li>
                                  <li>
                                    <div className="yellow-sqr"></div>
                                    Stay-Put Magnetic Base
                                  </li>
                                  <li>
                                    <div className="yellow-sqr"></div>
                                    Waterproof (IP67)
                                  </li>
                                </ul>
                              </Box>
                            </Box>
                           <Box sx={{ display:'flex', justifyContent:'center', alignItems: 'center', padding:'0', width: '100%', boxSizing:'border-box' }}>
                              <Typography className="" sx={{ color: '#faa61a !important', fontFamily: 'Montserrat !important', fontWeight: '400', fontSize: '14px !important' }}>
                                * That averages out to only $6.92 per month over 3 years!
                              </Typography>
                           </Box>
                          </Box>
                          
                        </Box>
                      </Box>}

                      {activeTab === 2 && <Box className='tab-cmn tab-2'>
                        <Box>
                          <Box className='tab-grid-1'>
                            <Box className='tab-grid-1-in'>
                              <Box className="tab-grid-1-img">
                                <img src={wiredDevice} alt="" />
                              </Box>
                              <Box>
                                <Typography variant="h3" sx={{ color: '#fff', fontFamily: 'GothamBold !important' }}>
                                  JUST $299*
                                </Typography>
                                <ul className="grid-home-grid-2-right-ul">
                                  <li>
                                    <div className="yellow-sqr"></div>
                                    No Contract, You Own It
                                  </li>
                                  <li>
                                    <div className="yellow-sqr"></div>
                                    3-Year Service & Warranty
                                  </li>
                                  <li>
                                    <div className="yellow-sqr"></div>
                                    Real time tracking
                                  </li>
                                  <li>
                                    <div className="yellow-sqr"></div>
                                    Small & Easy to Install
                                  </li>
                                  <li>
                                    <div className="yellow-sqr"></div>
                                    Waterproof (IP67)
                                  </li>
                                  
                                </ul>
                              </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0', width: '100%', boxSizing: 'border-box' }}>
                              <Typography className="" sx={{ color: '#faa61a !important', fontFamily: 'Montserrat !important', fontWeight: '400', fontSize: '14px !important' }}>
                              * That averages out to only $8.31 per month over 3 years!
                            </Typography>
                            </Box>
                          </Box>

                        </Box>
                      </Box>}
                    </Box>
                  </Box>

                  <Box sx={{ mt:'1rem' }}>
                    <Typography className='gps-home-cmmn-pb'>
                      Want to track a diferent piece of equipment?
                      Simply reassign the GPS with the touch of a
                      button in Your Rental Yard.
                    </Typography>
                  </Box>

                  <Box className='gps-home-img-div'>
                    <img src={JcbGrp} alt="" />
                  </Box>

                  <Box className='gps-home-quote'>
                    <Box className="gps-home-quote-inner">
                      <img className='gps-quote-icon-1' src={QuoteIcon1} alt="" />
                      <Typography className='gps-home-quote-p'> 
                        Using GoEquipMe GPS is a game
                        changer for my rentals. Not only can I keep
                        track of where my equipment is, but I can
                        switch out GPS units easily when I need.
                        And it’s cheap, too!

                      </Typography>
                      <Typography className='gps-home-quote-ps'>
                        – Pete Williams, Washington, UT
                      </Typography>
                      <img className='gps-quote-icon-2' src={QuoteIcon2} alt="" />
                    </Box>
                  </Box>

                  <Box className='gps-home-gen-spec'>
                    <Typography className="gps-home-cmmn-heading">
                      General Specifications
                    </Typography>
                    <Box className='gps-home-gen-spec-item'>
                      <Typography className='gps-home-gen-spec-item-h'>
                        Dimensions
                      </Typography>
                      <Typography className='gps-home-gen-spec-item-p'>
                        Wireless: 3.5 in × 2.0 in × 1.25 in
                      </Typography>
                      <Typography className='gps-home-gen-spec-item-p'>
                        Wired: 5.30 in × 2.66 in × 0.58 in
                      </Typography>
                    </Box>

                    <Box className='gps-home-gen-spec-item'>
                      <Typography className='gps-home-gen-spec-item-h'>
                        Weight
                      </Typography>
                      <Typography className='gps-home-gen-spec-item-p'>
                        Wireless: 5.75 oz (w/ magnetic case)
                      </Typography>
                      <Typography className='gps-home-gen-spec-item-p'>
                        Wired: 4.6 oz
                      </Typography>
                    </Box>

                    <Box className='gps-home-gen-spec-item'>
                      <Typography className='gps-home-gen-spec-item-h'>
                        Backup Battery
                      </Typography>
                      <Typography className='gps-home-gen-spec-item-p'>
                        Wireless: CR123A lithium batteries – 1400 mAh
                      </Typography>
                      <Typography className='gps-home-gen-spec-item-p'>
                        Wired: High temperature NiMH, 200 mAh
                      </Typography>
                    </Box>

                    <Box className='gps-home-gen-spec-item'>
                      <Typography className='gps-home-gen-spec-item-h'>
                        Standby Time (Wireless)*
                      </Typography>
                      <Typography className='gps-home-gen-spec-item-p'>
                        One report daily: 5-7 years
                      </Typography>                      
                      <Typography className="gps-home-spec-item-ps">
                        * Standby times are estimated under normal conditions.
                      </Typography>
                    </Box>

                    <Box className='gps-home-gen-spec-item'>
                      <Typography className='gps-home-gen-spec-item-h'>
                        Waterproof (IP67)
                      </Typography>
                      <Typography className='gps-home-gen-spec-item-p'>
                        Wireless: Yes
                      </Typography>
                      <Typography className='gps-home-gen-spec-item-p'>
                        Wired: Yes
                      </Typography>
                    </Box>

                    <Box className='gps-home-gen-spec-item'>
                      <Typography className='gps-home-gen-spec-item-h'>
                        Operating Temperature
                      </Typography>
                      <Typography className='gps-home-gen-spec-item-p'>
                        Wireless: -4°F ~ +140°F
                      </Typography>
                      <Typography className='gps-home-gen-spec-item-p'>
                        Wired: -22°F ~ +158°F
                      </Typography>
                    </Box>

                    <Box className='gps-home-gen-spec-item'>
                      <Typography className='gps-home-gen-spec-item-h'>
                        Internal Temperature Sensor
                      </Typography>
                      <Typography className='gps-home-gen-spec-item-p'>
                        For temperature monitoring.
                      </Typography>
                      <Typography className='gps-home-gen-spec-item-p'>
                        Accuracy: ±1°C
                      </Typography>
                    </Box>

                    <Box className='gps-home-gen-spec-item'>
                      <Typography className='gps-home-gen-spec-item-h'>
                        Internal Light Sensor
                      </Typography>
                      <Typography className='gps-home-gen-spec-item-p'>
                        For tamper detection and ambient light monitoring.
                      </Typography>
                    </Box>

                    <Box className='gps-home-gen-spec-item'>
                      <Typography className='gps-home-gen-spec-item-h'>
                        Internal Motor
                      </Typography>
                      <Typography className='gps-home-gen-spec-item-p'>
                        For vibration feedback after successful operation of
                        function button.
                      </Typography>
                    </Box>

                    <Box className='gps-home-gen-spec-item'>
                      <Typography className='gps-home-gen-spec-item-h'>
                        Magnetic Mounting Case (Optional)
                      </Typography>
                      <Typography className='gps-home-gen-spec-item-p'>
                        Available on wireless device for easy installation on
                        metal surface.
                      </Typography>
                    </Box>

                  </Box>

                </Box>
              </Box>
              <Box className='gps-home-grid-right'>

                <Box className='gps-home-grid-right-form'>
                  <Typography className='gps-home-grid-right-heading'>
                    Let’s Get Started
                  </Typography>
                  <Box className='gps-home-input'>
                    <label htmlFor="">
                      Name<sup className="mandatory-star">*</sup>
                    </label>
                    <input className='gps-home-input-field form-control contact-inputs' type="text" 
                    placeholder='John Dutchmaster'
                      onChange={HandleName}
                      value={Name}                      
                    />
                  </Box>
                  <Box className='gps-home-input'>
                    <label htmlFor="">
                      Email<sup className="mandatory-star">*</sup>
                    </label>
                    <input className='gps-home-input-field form-control contact-inputs' type="email"
                     placeholder='JRocket77@gmail.com'
                      onChange={HandleEmail}
                      value={Email}
                    />
                  </Box>
                  <Box className='gps-home-input'>
                    <label htmlFor="">
                      Phone<sup className="mandatory-star">*</sup>
                    </label>
                    <input className='gps-home-input-field form-control contact-inputs' type="tel"
                     placeholder='555-121-1234'
                      onChange={HandlePhone}
                      value={Phone}
                    />
                  </Box>
                  <Box className='gps-home-input-2 devices-input-grp' >
                    <label htmlFor="">
                      How Many Devices?
                    </label>
                    <input  className='gps-home-input-field form-control contact-inputs' type="number" placeholder=''
                      dir="rtl"
                      min={1}
                      onChange={HandleDevice}
                      value={Device}
                    />
                  </Box>
                  <Box className='gps-home-input-3'>
                    <label htmlFor="">
                      Do you already have equipment
                      listed on GoEquipMe?
                    </label>
                    <Box className='gps-home-input-3-radio'>
                      <input className='gps-home-input-field form-control contact-inputs' type="radio" placeholder=''
                        value={"checked"}
                        checked={AlreadyList}
                        onClick={(e) => {
                          setAlreadyList(AlreadyList === true ? false : true);
                        }
                        }
                      />
                      <label htmlFor="">Yes</label>
                    </Box>

                  </Box>
                  <Typography className='gps-home-grid-right-btm-p'>
                    You’re on your way to getting the
                    most inexpensive solution to reliably
                    tell you where your stuff is!
                  </Typography>
                  <Box className='get-gps-btn-div'>
                    <Button className='get-gps-btn'
                      onClickCapture={GpsRequest}
                    >GET GPS!</Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

{/* Submit request success popup */}
<Modal
        keepMounted
        open={SuccessPopup}
        onClose={() => setSuccessPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setSuccessPopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >

              
              <Box className="login__main-div__form edit-eqp-inner popup-cmmn popup-white">
                <Typography variant="h6" sx={{ color: "#faa61a", fontWeight: "100" }}>
                Thank you, a representative from GoEquipMe will get in touch with you about our GPS options.
                </Typography>            
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>


      
    </MainLayout>
  )
}

export default Tracking
