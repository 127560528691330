import React from 'react';
import { Box, Typography } from '@mui/material';
import checkMark from 'assets/images/icons_checkmark.png'
import './styles.css'

const SectionItem = ({title}) => {
  return (
    <Box className='section-item'>
      <img
        src={checkMark}
        alt='Check Mark'
        className='img-check-mark'
      />
      <Typography variant='h5' className='section-item-text'>{title}</Typography>
    </Box>
  );
};

export default SectionItem;