import React, { useEffect, useState, useRef } from "react";
import { Box, Container, Backdrop, CircularProgress, Switch, Typography, Modal, IconButton, Dialog, styled } from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";
import MainLayout from "layouts/MainLayout";
import './styles.scss'
import { useDispatch, useSelector } from "react-redux";
import {
  setLoginStatus,
  setalertMessagetype,
  setalertMessage,
  setaccessToken,
  setAuthuser,
  setSessionExpire
} from "redux/redux-slice";
import { EquipmentAPIs } from "../../service/api-service";
import { IApiResponseRawData, IStoreValue } from "utils/types";

import TxtNotification from "../common/TxtNotificationPopup/TextNotificationPopup";

const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


const AccountSetting = () => {
  const dispatch = useDispatch();
  const AuthToken = useSelector((state: IStoreValue) => state.equipmentReducer.accessToken)
  const UserId = useSelector((state: IStoreValue) => state.equipmentReducer.Authuser)
  const [loading, setloading] = useState<boolean>(false);

  const [pushNoti, setPushNoti] = useState(true);
  const [emailNoti, setEmailNoti] = useState(true);
  const [smsNoti, setSmsNoti] = useState(true);
  const [Notificationpopup, setNotificationpopup] = useState(false);

  const [NotificationSettingPopup, setNotificationSettingPopup] = React.useState(false);

  const getuserprofile = async (user_id, token) => {
    try {
      const headers = { Authorization: `${token}` ,request_from : "Web" };
      const AccountData = await EquipmentAPIs.getProfile(
        {
          user_id: user_id.toString(),
        },
        headers
      );
      if (AccountData["type"] == "RXSUCCESS") {
        let profile_data = AccountData["data"];

        let pushnotification = profile_data.push_notification == "1" ? true : false
        let emailnotification = profile_data.email_notification == "1" ? true : false
        let txtnotification = profile_data.text_notification == "1" ? true : false
        setPushNoti(pushnotification)
        setEmailNoti(emailnotification)
        setSmsNoti(txtnotification)

        dispatch(setSessionExpire(false));
        dispatch(setLoginStatus(true));
      } 
      else if (AccountData["status"] == false && AccountData["slug"] == "Session Expired") {
        dispatch(setSessionExpire(true));
        return false
      }
       else {
        dispatch(setalertMessage(AccountData['message']));
        dispatch(setalertMessagetype('error'));
        return false;
      }
    } catch (err) { }
  };

  useEffect(() => {
    let auth_user = localStorage.getItem("token-info");
    if (auth_user != "undefined") {
      auth_user = JSON.parse(auth_user);
      if (auth_user != null && auth_user) {
        dispatch(setaccessToken(auth_user["auth_token"]));
        getuserprofile(auth_user["user_id"], auth_user["auth_token"]);
        dispatch(setLoginStatus(true));
        dispatch(setAuthuser(auth_user["user_id"]));
      }
    }
  }, []);


  const HandleNotifictionsAlert = (event) => {
    setloading(true)
    let val = event.target.checked
    setPushNoti(val)
    UpdateProfile(val, emailNoti, smsNoti)
  }

  const HandleEmailNotifictionsAlert = (event) => {
    setloading(true)
    let val = event.target.checked

    if(!smsNoti && !val) {
      dispatch(setalertMessage('One alert must be enable from email or SMS.'));
      dispatch(setalertMessagetype('error'));
      setloading(false)
      return false;
    }

    setEmailNoti(val)
    UpdateProfile(pushNoti, val, smsNoti)
  }

  const HandleTxtNotifictionsAlert = (event) => {
    setloading(false)
    let val = event.target.checked;

    if(!emailNoti && !val) {
      dispatch(setalertMessage('One alert must be enable from email or SMS.'));
      dispatch(setalertMessagetype('error'));
      setloading(false)
      return false;
    }
    
    if(val) {
      setNotificationSettingPopup(true)
    } else {
      setSmsNoti(val)
      UpdateProfile(pushNoti, emailNoti, val)
    }

    //setSmsNoti(val)
    //UpdateProfile(pushNoti, emailNoti, val)
  }

  // Update Account setting Notifications
  const UpdateProfile = async (push, email, sms) => {
    //console.log('==>',push,email,sms);
    try {

      let body = {
        user_id: UserId.toString(),
        push_notification: push ? "1" : "0",
        email_notification: email ? "1" : "0",
        text_notification: sms ? "1" : "0",
      }

      //console.log('===>',body);
      const headers = { Authorization: `${AuthToken}` ,request_from : "Web"};
      const UpdateSetting = await EquipmentAPIs.PushNotify(
        body, headers
      );
      //console.log('UpdateSetting', UpdateSetting);
      if (UpdateSetting['status'] == 200) {
        setloading(false)
        if (UpdateSetting["type"] == "RXERROR") {
          dispatch(setalertMessage(UpdateSetting['message']));
          dispatch(setalertMessagetype('error'));
          return false;
        } else {
          dispatch(setalertMessage('Account setting updated successfully.'));
          dispatch(setalertMessagetype('success'));
          return false
        }

      } else {
        setloading(false)
        dispatch(setalertMessage(UpdateSetting['message']));
        dispatch(setalertMessagetype('error'));
      }

    } catch (err) {
      setloading(false)
      console.log(err);
    }
  };

  return (
    // <MainLayout>
      // {loading && (
      //   <Box>
      //     <Backdrop
      //       sx={{
      //         color: "#fff",
      //         zIndex: (theme) => theme.zIndex.drawer + 1,
      //       }}
      //       open={loading}
      //     >
      //       <CircularProgress color="inherit" />
      //     </Backdrop>
      //   </Box>
      // )}
      <>

      <Box className="account-setting">
        {/* <Box className="profile-heading">
          <Typography>Account Settings</Typography>
        </Box> */}
        <Box className="setting-div">
          <Typography className="labels">Push Notifiction Alerts</Typography>
          <Box className="switchbox">
            {/* {!pushNoti && (
              <Typography className="switch-off-color">OFF</Typography>
            )} */}
            <Switch
              value={pushNoti}
              checked={pushNoti}
              color="warning"
              onChange={HandleNotifictionsAlert}
            />
            {pushNoti && (
              <Typography className="switch-on-color">ON</Typography>
            )}
            {!pushNoti && (
              <Typography className="switch-off-color">OFF</Typography>
            )}
          </Box>
        </Box>
        <Box className="setting-div">
          <Typography className="labels">Email Alerts</Typography>
          <Box className="switchbox">
            {/* {!emailNoti && (
              <Typography className="switch-off-color">OFF</Typography>
            )} */}
            <Switch
              value={emailNoti}
              checked={emailNoti}
              color="warning"
              onChange={HandleEmailNotifictionsAlert}
            />
            {emailNoti && (
              <Typography className="switch-on-color">ON</Typography>
            )}
            {!emailNoti && (
              <Typography className="switch-off-color">OFF</Typography>
            )}
          </Box>
        </Box>
        <Box className="setting-div">
          <Typography className="labels">SMS/Texting Alerts</Typography>
          <Box className="switchbox">
            {/* {!smsNoti && (
              <Typography className="switch-off-color">OFF</Typography>
            )} */}
            <Switch
              value={smsNoti}
              checked={smsNoti}
              color="warning"
              onChange={HandleTxtNotifictionsAlert}
            />
            {smsNoti && (
              <Typography className="switch-on-color">ON</Typography>
            )}
            {!smsNoti && (
              <Typography className="switch-off-color">OFF</Typography>
            )}
          </Box>
        </Box>
      </Box>

      {/* Popup Section start  */}
      <Modal
          keepMounted
          open={Notificationpopup}
          onClose={() => setNotificationpopup(false)}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            className="download-popups"
            sx={{
              ...loginstyle,
              padding: "0px"
            }}
          >
            <IconButton
              className="cross-btn"
              onClick={() => setNotificationpopup(false)}
            >
              <CloseIcon />
            </IconButton>

            <Box className="login__main">
              <Box
                className="login__main-div edit-eqp"
                sx={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Box className="login__main-div__form popup-cmmn popup-white edit-eqp-inner">
                  <Typography variant="h5" style={{ color: "#faa61a" }}>
                    Popup
                  </Typography>
                  
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>

      {/* Popup Section end  */}
      
      {/* Text notification popup */}
      <Box className='text-notification'>
        <BootstrapDialog
          //onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={NotificationSettingPopup}
          className='tn-popup'
        >
          <TxtNotification onClose={(result) => {
            if(result == "Accept") {
              setSmsNoti(true)
              UpdateProfile(pushNoti, emailNoti, true)
            } 
            setNotificationSettingPopup(false)
          }} />
        </BootstrapDialog>
      </Box>
    </>
    // </MainLayout>
  );
}

export default AccountSetting