import React from 'react'
import MainLayout from '../../layouts/MainLayout'
import EquipmentAdd from 'components/equipment-list/AddEquipment'

/**
 * User Equipment list
 */
const EquipmentList: React.FC = () => {
  
  return (
    <MainLayout insideMainContainer={false}>
      <EquipmentAdd />
    </MainLayout>
  )
}

export default EquipmentList