import React, { useCallback, useEffect, useState, useRef } from "react";
import { EquipmentAPIs } from "../../service/api-service";
import {
  Box,
  CircularProgress,
  TextField,
  Button,
  Link,
  Typography,
  styled,
  Grid,
  Backdrop,
  InputLabel,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  Container,
  IconButton,
  FormGroup,
  Checkbox,
} from "@mui/material";

import Select, { SelectChangeEvent } from "@mui/material/Select";

import { useDispatch, useSelector } from "react-redux";
import {
  setLoginStatus,
  setLoginModalOpen,
  setalertMessagetype,
  setalertMessage,
  setaccessToken,
  setLogInLoading,
  setSessionExpire,
  setEquipAddType,
} from "redux/redux-slice";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import NumericInput from "material-ui-numeric-input";
import "./styles.scss";
import CloseIcon from "@mui/icons-material/Close";

import Scamera from "assets/images/Scamera.png";
import Pencil from "assets/images/pencil.png";
import iconLocation from "assets/images/icons_equipmentlocation-footer.png";
import iconDoller from "assets/images/icons_lending-footer.png";
import iconArrow from "assets/images/details-page/left-arrow.png";

//photo tips
import Suggest1 from "assets/images/suggest1.png";
import Suggest2 from "assets/images/suggest2.png";
import Suggest3 from "assets/images/suggest3.png";
import Suggest4 from "assets/images/suggest4.png";

import Check from "assets/images/check.png";
import Cross from "assets/images/cross.png";

//import ImagePicker from 'react-image-picker'
import "react-image-picker/dist/index.css";

import GoogleautoComplete from "./GoogleautoComplete";
import Geocode from "react-geocode";

const CssTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    color: "#2e2e2e",
    background: "#fff",
    fontFamily: "GothamBold !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "& label.Mui-focused": {
    color: "#faa61a",
    fontWeight: "bold",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#2e2e2e",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
    },
    "&:hover fieldset": {
      borderColor: "#2e2e2e",
      border: "2px solid #faa61a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
});

const CssNumericInput = styled(NumericInput)({
  "& .MuiInputBase-root": {
    color: "#2e2e2e",
    background: "#fff",
    fontFamily: "GothamBold !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "& label.Mui-focused": {
    color: "#faa61a",
    fontWeight: "bold",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#2e2e2e",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
    },
    "&:hover fieldset": {
      borderColor: "#2e2e2e",
      border: "2px solid #faa61a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
});

const AddEquipment = () => {
  const APIKEY = process.env.REACT_APP_GEOCODDER_KEY;
  Geocode.setApiKey(APIKEY);
  const inRef = useRef(null);
  const searchRef = useRef();
  const dispatch = useDispatch();
  const isLoggedin = useSelector(
    (state: IStoreValue) => state.equipmentReducer.isLogin
  );
  // by GD 23 Jan 2024 added Default for location issue when user does not have any lat lng
  const defaultLocation = { lat: 39.321980897034734, lng: -111.10149320091872 };
  
  const [AllCategories, setAllCategories] = useState([]);
  const [loading, setloading] = React.useState(false);
  const [step, setstep] = React.useState(1);
  const [Activestep, setActivestep] = React.useState([1]);

  const [INActivestep, setINActivestep] = React.useState([1, 2, 3, 4]);
  const [MapLocation, setMapLocation] = React.useState(null);

  const [ProfilePicStatus, setProfilePicStatus] = React.useState(null);
  const [LicenceStatus, setLicenceStatus] = React.useState(null);

  //step 1
  const [category, setcategory] = React.useState("");
  const [handlelistingTitle, sethandlelistingTitle] = React.useState("");
  const [handlemakemodel, sethandlemakemodel] = React.useState("");
  // const [EquipType, setEquipType] = React.useState('rent');
  // const [EquipType, setEquipType] = React.useState("rent");
  
  // by GD 14 Feb 2024 for commented upper state and used redux redirect from hiw page auto select rent or sale.
  const EquipType = useSelector((state: IStoreValue) => state.equipmentReducer.equipAddType);

  useEffect(() => {
    let arr = [];
    if (EquipType == "sale") {
      arr = [1, 2, 3];
    } else {
      arr = [1, 2, 3, 4];
    }
    setINActivestep(arr);
  }, [EquipType]);

  // for sale
  const date = new Date();
  let year = date.getFullYear();

  let yearArr = [];
  for (let index = Number(year); index >= 1900; index--) {
    yearArr.push(index);
  }
  const [Year, setYear] = React.useState('');
  const [Feature, setFeature] = React.useState("");
  const [Mileage, setMileage] = React.useState("");
  const [Hours, setHours] = React.useState("");
  const [Description, setDescription] = React.useState("");
  const [pendingDiscriptionChar, setpendingDiscriptionChar] =
    React.useState(1000);

  // step 3
  const [SalePrice, setSalePrice] = React.useState("");

  const [priceperhour, setpriceperhour] = React.useState("");
  const [priceperday, setpriceperday] = React.useState("");
  const [priceperweek, setpriceperweek] = React.useState("");
  const [pricepermonth, setpricepermonth] = React.useState("");
  const [hourinclude, sethourinclude] = React.useState("24");
  const [Obo, setObo] = React.useState("off");
  const [Rpo, setRpo] = React.useState("off");

  // step 4
  const [rentpickupreturn, setrentpickupreturn] = React.useState("yes");
  const [ownerdeliveryreturn, setownerdeliveryreturn] = React.useState("yes");
  const [equpheight, setequpheight] = React.useState("");
  const [equpHeightRadio, setequpHeightRadio] = React.useState("ft");
  const [equpwidth, setequpwidth] = React.useState("");
  const [equpWidthRadio, setequpWidthRadio] = React.useState("ft");
  const [equplength, setequplength] = React.useState("");
  const [equpLengthRadio, setequpLengthRadio] = React.useState("ft");
  const [equpweight, setequpweight] = React.useState("");
  const [mintransportchrg, setmintransportchrg] = React.useState("");
  const [permiledeliverchrg, setpermiledeliverchrg] = React.useState("");
  const [permile, setpermile] = React.useState("");
  const [maximumtransportrange, setmaximumtransportrange] = React.useState("");

  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const UserID = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const AuthProfileData = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );

  const [Thumbimage, setThumbimage] = useState(0);
  const [imagearr, setimagearr] = useState([]);
  const [Uploadedpath, setUploadedpath] = useState([]);
  const [file, setFile] = useState([]);

  const [LocationMarker, setLocationMarker] = useState({});
  const [FullAddress, setFullAddress] = useState({
    address: "",
    city: "",
    state: "",
    short_state: "",
    postal_code: "",
    country: "",
  });

  const [FullAddressMap, setFullAddressMap] = useState(null);

  const [LocationAddr, setLocationAddr] = useState(null);

  // Get All categories
  const LoadCategories = async () => {
    try {
      setloading(true);
      const categoriesRes: IApiResponseRawData =
        await EquipmentAPIs.getAllCategories();
      if (categoriesRes.result == "success") {
        setloading(false);
        setAllCategories(categoriesRes.data.rows);
        // console.log("categoriesRes.data.rows: " , categoriesRes.data.rows);
        
      } else {
        setloading(false);
      }
    } catch (err) {
      setloading(false);
      // console.log(err);
    }
  };

  const nextStep = () => {
    let profile_status = ProfilePicStatus;
    let licence_status = LicenceStatus;

    if (
      profile_status == null ||
      profile_status == "null" ||
      licence_status == null ||
      licence_status == "null"
    ) {
      dispatch(setalertMessage("Please Complete your Profile First."));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    // else if(profile_status == null || profile_status == "null") {
    //     dispatch(setalertMessage('Please update your profile pic first to continue.'));
    //     dispatch(setalertMessagetype('error'));
    //     return false
    // }
    // else if(licence_status == null || licence_status == "null") {
    //     dispatch(setalertMessage('Please update your licence pic first to continue.'));
    //     dispatch(setalertMessagetype('error'));
    //     return false
    // }

    let currentStep = step;
    var activeStep = currentStep;
    //validations
    if (currentStep == 1) {
      var _category = category.trim();
      if (_category == "" || _category == null) {
        dispatch(setalertMessage("Please select Category to continue."));
        dispatch(setalertMessagetype("error"));
        return false;
      }

      var _handlelistingTitle = handlelistingTitle.trim();
      if (_handlelistingTitle == "" || _handlelistingTitle == null) {
        dispatch(setalertMessage("Please add listing title."));
        dispatch(setalertMessagetype("error"));
        return false;
      }

      // Sales section start
      if (EquipType == "sale") {
        let sale_year = Year;
        let sale_feature = Feature;
        let sale_mileage = Mileage;
        let sale_hours = Hours;
        let sale_description = Description;

        // if (sale_year == "") {
        //   dispatch(setalertMessage("Please add year."));
        //   dispatch(setalertMessagetype("error"));
        //   return false;
        // }
        // by GD commented sale_feature
        // if (sale_feature == '') {
        //     dispatch(setalertMessage('Please add equipment feature.'));
        //     dispatch(setalertMessagetype('error'));
        //     return false
        // }

        // if (sale_mileage == '') {
        //     dispatch(setalertMessage('Please add mileage.'));
        //     dispatch(setalertMessagetype('error'));
        //     return false
        // }

        // if (sale_hours == '') {
        //     dispatch(setalertMessage('Please add hours.'));
        //     dispatch(setalertMessagetype('error'));
        //     return false
        // }
        // by GD commented sale_description
        // if (sale_description == '') {
        //     dispatch(setalertMessage('Please add equipment description.'));
        //     dispatch(setalertMessagetype('error'));
        //     return false
        // }
      }

      activeStep = currentStep + 1;
      // by GD commented 3 jan 2024
      // setFile([]);
    }
    if (currentStep == 2) {
      if (imagearr.length > 0) {
        // upload files
        uploadfiles();
        // console.log("in currentStep == 2: " , Uploadedpath);
        
        //activeStep = currentStep + 1;
        // activeStep = currentStep;
      } else if (Uploadedpath && Uploadedpath.length > 0) {
        activeStep = currentStep + 1;
      } else {
        dispatch(setalertMessage("Please add images to continue."));
        dispatch(setalertMessagetype("error"));
        return false;
      }
    }

    if (currentStep == 3) {
      if (EquipType == "sale") {
        var _priceperhour = SalePrice;
        if (_priceperhour == "" || _priceperhour == null) {
          dispatch(setalertMessage("Please enter asking price."));
          dispatch(setalertMessagetype("error"));
          return false;
        }
        // if (Obo == "off" && Rpo == "off") {
        //   dispatch(setalertMessage("Please select one of obo or rpo."));
        //   dispatch(setalertMessagetype("error"));
        //   return false;
        // }
      } else {
        var _priceperhour = priceperhour;
        if(_priceperhour && parseFloat(_priceperhour) === 0) {
          dispatch(setalertMessage("Price per hour can't be zero."));
          dispatch(setalertMessagetype("error"));
          return false;
        }
        if (_priceperhour == "" || _priceperhour == null ) {
          dispatch(setalertMessage("Please enter price per hour."));
          dispatch(setalertMessagetype("error"));
          return false;
        }
        var _priceperday = priceperday;
        if (_priceperday == "" || _priceperday == null) {
          dispatch(setalertMessage("Please enter price per day."));
          dispatch(setalertMessagetype("error"));
          return false;
        }

        if (parseFloat(_priceperhour) >= parseFloat(_priceperday)) {
          console.log("===", _priceperhour > _priceperday);
          dispatch(
            setalertMessage("Day price must be greater than hour price.")
          );
          dispatch(setalertMessagetype("error"));
          return false;
        }

        var _priceperweek = priceperweek;
        if (_priceperweek == "" || _priceperweek == null) {
          dispatch(setalertMessage("Please enter price per week."));
          dispatch(setalertMessagetype("error"));
          return false;
        }

        if (parseFloat(_priceperday) >= parseFloat(_priceperweek)) {
          dispatch(
            setalertMessage("Week price must be greater than day price.")
          );
          dispatch(setalertMessagetype("error"));
          return false;
        }

        var _pricepermonth = pricepermonth;
        if (_pricepermonth == "" || _pricepermonth == null) {
          dispatch(setalertMessage("Please enter price per month."));
          dispatch(setalertMessagetype("error"));
          return false;
        }

        if (parseFloat(_priceperweek) >= parseFloat(_pricepermonth)) {
          dispatch(
            setalertMessage("Month price must be greater than week price.")
          );
          dispatch(setalertMessagetype("error"));
          return false;
        }

        var _hourinclude = hourinclude;
        if (_hourinclude == "" || _hourinclude == null) {
          dispatch(setalertMessage("Please enter per day included hours."));
          dispatch(setalertMessagetype("error"));
          return false;
        }
        if (Number(_hourinclude) > 24) {
          dispatch(
            setalertMessage("Please enter valid per day included hours.")
          );
          dispatch(setalertMessagetype("error"));
          return false;
        }
      }
      activeStep = currentStep + 1;
    }

    if (EquipType != "sale") {
      if (currentStep == 4) {
        // by GD 3 jan 2024
        // console.log("uploaded path in step 4" , Uploadedpath);
        
        // by GD this rentpickupreturn is added to only require this fields if rentpickupreturn is selected
        if (rentpickupreturn == "yes") {
          var _height = equpheight;
          if (_height == "" || _height == null) {
            dispatch(setalertMessage("Please add height."));
            dispatch(setalertMessagetype("error"));
            return false;
          }
          var _width = equpwidth;
          if (_width == "" || _width == null) {
            dispatch(setalertMessage("Please add width."));
            dispatch(setalertMessagetype("error"));
            return false;
          }
          var _equplength = equplength;
          if (_equplength == "" || _equplength == null) {
            dispatch(setalertMessage("Please add length."));
            dispatch(setalertMessagetype("error"));
            return false;
          }
          var _equpweight = equpweight;
          if (_equpweight == "" || _equpweight == null) {
            dispatch(setalertMessage("Please add weight."));
            dispatch(setalertMessagetype("error"));
            return false;
          }
        }

        if (ownerdeliveryreturn == "yes") {
          var _mintransportchrg = mintransportchrg;
          if (_mintransportchrg == "" || _mintransportchrg == null) {
            dispatch(
              setalertMessage(
                "Please enter minimum transport charges to continue."
              )
            );
            dispatch(setalertMessagetype("error"));
            return false;
          }
          var _permiledeliverchrg = permiledeliverchrg;
          if (_permiledeliverchrg == "" || _permiledeliverchrg == null) {
            dispatch(
              setalertMessage(
                "Please enter per-mile delivery charge begin after."
              )
            );
            dispatch(setalertMessagetype("error"));
            return false;
          }
          var _permile = permile;
          if (_permile == "" || _permile == null) {
            dispatch(setalertMessage("Please enter charge for per mile."));
            dispatch(setalertMessagetype("error"));
            return false;
          }
          var _maximumtransportrange = maximumtransportrange;
          if (_maximumtransportrange == "" || _maximumtransportrange == null) {
            dispatch(setalertMessage("Please enter maximum transport range."));
            dispatch(setalertMessagetype("error"));
            return false;
          }
        }
        activeStep = currentStep + 1;
      } else {
        // activeStep = currentStep + 1;
      }
    }

    setstep(activeStep);

    //Active tabs
    var _active = Activestep;
    _active.push(activeStep);
    setActivestep(_active);

    //Inactive tabs
    var _inactive = INActivestep;
    _inactive.pop();
    setINActivestep(_inactive);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const backStep = () => {
    let currentStep = step;
    let activeStep = currentStep - 1;
    setstep(activeStep);

    //Active tabs
    var _active = Activestep;
    _active.pop();
    setActivestep(_active);

    //Inactive tabs
    var _inactive = INActivestep;
    _inactive.push(activeStep);
    setINActivestep(_inactive);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // step 1
  const changeCategory = (event) => {
    setcategory(event.target.value);
  };
  const HandlelistingTitle = (event) => {
    sethandlelistingTitle(event.target.value?.replace(/\//g, ''));
  };
  const Handlemakemodel = (event) => {
    sethandlemakemodel(event.target.value?.replace(/\//g, ''));
  };

  // step 3
  const PriceperHour = (event) => {
    let val = event.target.value;
    if (val === "" || /^\d+(\.\d{0,2})?$/.test(val)) {
      setpriceperhour(val);
    }
  };
  const PriceperDay = (event) => {
    let val = event.target.value;
    if (val === "" || /^\d+(\.\d{0,2})?$/.test(val)) {
      setpriceperday(event.target.value);
    }
    //setpriceperday(event.target.value)
  };
  const PriceperWeek = (event) => {
    let val = event.target.value;
    if (val === "" || /^\d+(\.\d{0,2})?$/.test(val)) {
      setpriceperweek(event.target.value);
    }
    //setpriceperweek(event.target.value)
  };
  const PriceperMonth = (event) => {
    let val = event.target.value;
    if (val === "" || /^\d+(\.\d{0,2})?$/.test(val)) {
      setpricepermonth(event.target.value);
    }
    //setpricepermonth(event.target.value)
  };
  const HourInclude = (event) => {
    if (event.target.value > 0 && event.target.value <= 24) {
      sethourinclude(event.target.value);
    } else {
      if (event.target.value == "") {
        sethourinclude(event.target.value);
      } else {
        dispatch(setalertMessage("Invalid selected hours."));
        dispatch(setalertMessagetype("error"));
        return false;
      }
    }
    // sethourinclude(event.target.value)
  };

  // step 4
  const Rentpickupreturn = (event) => {
    // let val = event.target.value;
    // if (val == "no" && ownerdeliveryreturn == "no") {
    //     setrentpickupreturn('yes')
    // } else {
    //     setrentpickupreturn(val)
    // }
    if (event.target.value == "no" && ownerdeliveryreturn == "no") {
      dispatch(setalertMessage("One Transport option must be yes"));
      dispatch(setalertMessagetype("error"));
      return false;
      //setownerdeliveryreturn('yes')
    } else {
      setrentpickupreturn(event.target.value);
    }
  };
  const OwnerDeliveryReturn = (event) => {
    // let val = event.target.value;
    // if (val == "no" && rentpickupreturn == "no") {
    //     setownerdeliveryreturn('yes')
    // } else {
    //     setownerdeliveryreturn(val)
    // }
    if (event.target.value == "no" && rentpickupreturn == "no") {
      dispatch(setalertMessage("One Transport option must be yes"));
      dispatch(setalertMessagetype("error"));
      return false;
      //setrentpickupreturn('yes')
    } else {
      setownerdeliveryreturn(event.target.value);
    }
  };
  const EqupHeight = (event) => {
    let val = event.target.value;
    //val = val.replace(/\D/g, "");
    setequpheight(val);
  };
  const EqupHeightRadio = (event) => {
    setequpHeightRadio(event.target.value);
  };
  const EqupWidth = (event) => {
    setequpwidth(event.target.value);
  };
  const EqupWidthRadio = (event) => {
    setequpWidthRadio(event.target.value);
  };
  const EqupLength = (event) => {
    setequplength(event.target.value);
  };
  const EqupLengthRadio = (event) => {
    setequpLengthRadio(event.target.value);
  };
  const EqupWeight = (event) => {
    setequpweight(event.target.value);
  };
  const MinTransportChrg = (event) => {
    let val = event.target.value;
    if (val === "" || /^\d+(\.\d{0,2})?$/.test(val)) {
      setmintransportchrg(event.target.value);
    }
    //setmintransportchrg(event.target.value)
  };
  const PerMileDeliverChrg = (event) => {
    setpermiledeliverchrg(event.target.value);
  };
  const PerMile = (event) => {
    let val = event.target.value;
    if (val === "" || /^\d+(\.\d{0,2})?$/.test(val)) {
      setpermile(event.target.value);
    }
    //setpermile(event.target.value)
  };
  const MaximumTransportRange = (event) => {
    let val = event.target.value;
    if (val === "" || /^\d+(\.\d{0,2})?$/.test(val)) {
      setmaximumtransportrange(event.target.value);
    }
    //setmaximumtransportrange(event.target.value)
  };

  // Equipment Images section start

  // on click select thumbnail image
  const pickHandle = (image) => {
    setThumbimage(image.value);
    //this.setState({image})
  };

  // Select multiple images
  const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;
    if (fileList.length > 0) {
      setloading(true);
      var result = Object.entries(fileList);

      if (result.length > 20 || file.length + result.length > 20) {
        setloading(false);
        dispatch(setalertMessage("you can upload only 20 images."));
        dispatch(setalertMessagetype("error"));
        return false;
      } else {
        if (imagearr.length > 0) {
          var arr = [...imagearr, ...result];
          setimagearr(arr);
        } else {
          setimagearr(result);
        }

        let upload_files = [...file];
        for (let index = 0; index < result.length; index++) {
          if (
            e.target.files[index].type == "image/jpg" ||
            e.target.files[index].type == "image/jpeg" ||
            e.target.files[index].type == "image/png"
          ) {
            var fileLoaded = URL.createObjectURL(e.target.files[index]);
            upload_files.push(fileLoaded);
          }
        }
        setFile(upload_files);
        setloading(false);
      }
    }
  };

  // Upload Equipment images
  // const uploadfiles = async () => {
  //   // by GD for images upload 3 jan 2024
  //   console.log("imagearr in upload: " , imagearr);
    
  //   if (imagearr.length > 0) {
  //     setloading(true);
  //     let upload_files_path = [];

  //     file.map((filename, i) => {
  //       if (filename.indexOf("blob:http") > -1) {
  //       } else {
  //         upload_files_path.push(filename);
  //       }
  //     });

  //     let promises = imagearr.map(async (imgObg, index) => {
  //       let file_obj = imgObg[1];

  //       if (file_obj) {
  //         try {
  //           const formData = new FormData();
  //           formData.append("image", file_obj);
  //           formData.append("name", file_obj.name);
  //           formData.append("from", "Web");

  //           if (Thumbimage == index) {
  //             formData.append("thumbnail", file_obj);
  //           }

  //           let access_token = AuthToken;
  //           const headers = {
  //             Authorization: `${access_token}`,
  //             "Content-Type": "multipart/form-data",
  //             Accept: "application/json",
  //             request_from: "Web",
  //           };
  //           const upload_Equip_file: IApiResponseRawData =
  //             await EquipmentAPIs.uploadEquipImage(formData, headers);

  //           if (upload_Equip_file["status"] == 200) {
  //             var uploaded_path = upload_Equip_file["data"]["image"];
  //             upload_files_path.push(uploaded_path);

  //             return;
  //           } else if (upload_Equip_file["status"] == false) {
  //             dispatch(setalertMessage(upload_Equip_file["slug"]));
  //             dispatch(setalertMessagetype("error"));
  //             setloading(false);
  //             return false;
  //           } else {
  //             dispatch(setalertMessage(upload_Equip_file["message"]));
  //             dispatch(setalertMessagetype("error"));
  //             return false;
  //           }
  //         } catch (err) {
  //           dispatch(setalertMessage(err));
  //           dispatch(setalertMessagetype("error"));
  //           setloading(false);
  //           return false;
  //         }
  //       }
  //     });
  //     Promise.all(promises).then(() => {
  //       if (
  //         upload_files_path.length > 0 &&
  //         imagearr.length == upload_files_path.length
  //       ) {
  //         let activeStep = step + 1;
  //         // setUploadedpath(upload_files_path);
  //         // by GD 3 jan 2024
  //         setUploadedpath([...Uploadedpath , ...upload_files_path]);
  //         setstep(activeStep);
  //         setimagearr([]);
  //       }
  //       setloading(false);
  //       // formSubmit(upload_files_path)
  //     });
  //   }
  // };

  // new uploadFiles by GD 9 Feb 2024 due to error in thumbnail image
  const uploadfiles = async () => {
    // by GD for images upload 3 jan 2024
    console.log("imagearr in upload: " , imagearr);
    
    if (imagearr.length > 0) {
      setloading(true);
      let upload_files_path = [];

      file.map((filename, i) => {
        if (filename.indexOf("blob:http") > -1) {
        } else {
          upload_files_path.push(filename);
        }
      });

      let index = 0;
      console.log("loop Started");
      
      for await (const element of imagearr) {
        // code to be executed for each element, with access to both element and index
        console.log(`Index: ${index}, Element: ${element}`);
        // let file_obj = imgObg[1];
        let file_obj = element[1];

        if (file_obj) {
          try {
            console.log("in file_obj if");
            
            const formData = new FormData();
            formData.append("image", file_obj);
            formData.append("name", file_obj.name);
            formData.append("from", "Web");

            if (Thumbimage == index) {
              formData.append("thumbnail", file_obj);
            }

            let access_token = AuthToken;
            const headers = {
              Authorization: `${access_token}`,
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              request_from: "Web",
            };
            const upload_Equip_file: IApiResponseRawData =
              await EquipmentAPIs.uploadEquipImage(formData, headers);

              console.log("upload_Equip_file: " , upload_Equip_file);
              
            if (upload_Equip_file["status"] == 200) {
              var uploaded_path = upload_Equip_file["data"]["image"];
              upload_files_path.push(uploaded_path);
              console.log("in api scs if");
              
              index++;
              // return;
            } else if (upload_Equip_file["status"] == false) {
              dispatch(setalertMessage(upload_Equip_file["slug"]));
              dispatch(setalertMessagetype("error"));
              setloading(false);
              return false;
            } else {
              dispatch(setalertMessage(upload_Equip_file["message"]));
              dispatch(setalertMessagetype("error"));
              return false;
            }
          } catch (err) {
            dispatch(setalertMessage(err));
            dispatch(setalertMessagetype("error"));
            setloading(false);
            return false;
          }
        }

        // console.log("first loop ended");
        
      }

      index = 0;
      console.log("loop ended");
      
      if (
        upload_files_path.length > 0 &&
        imagearr.length == upload_files_path.length
      ) {
        console.log("in if of photos");
        
        let activeStep = step + 1;
        // setUploadedpath(upload_files_path);
        // by GD 3 jan 2024
        setUploadedpath([...Uploadedpath , ...upload_files_path]);
        setstep(activeStep);
        setimagearr([]);
      }
      setloading(false);

    }
  };
  // end code by GD for upload images
  
  // Submit form
  const formSubmit = async () => {
    // e.preventDefault();
    setloading(true);
    try {
      if (Object.keys(LocationMarker).length == 0) {
        dispatch(setalertMessage("Please choose location to submit."));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }
      if (LocationMarker == null) {
        dispatch(setalertMessage("Please choose location to submit"));
        dispatch(setalertMessagetype("error"));
        return false;
      }

      var primaryImage = Uploadedpath[Thumbimage];
      Uploadedpath.splice(Thumbimage, 1);
      //console.log('====',primaryImage,'=====',Uploadedpath);
      let auth_user = localStorage.getItem("token-info");
      auth_user = JSON.parse(auth_user);
      let equipImages = [primaryImage, ...Uploadedpath];

      //return false;
      let body = {
        user_id: auth_user["user_id"].toString(),
        vin_no: "",
        equip_type: handlelistingTitle,
        year: Year, // this field is used sale and rent both
        make: handlelistingTitle,
        model: handlemakemodel,
        feature: Feature, // this field is used sale and rent both
        description: Description, // this field is used sale and rent both
        category: category,
        images: equipImages.toString(),
        thumbnail: primaryImage.toString(),
        price_per_hour: priceperhour.toString(),
        price_day: priceperday.toString(),
        price_per_week: priceperweek.toString(),
        price_month: pricepermonth.toString(),
        included_hrs_day: hourinclude.toString(),
        renter_pickup_return: rentpickupreturn == "yes" ? "1" : "0",
        owner_pickup_return: ownerdeliveryreturn == "yes" ? "1" : "0",
        third_party_pickup_return: "1",
        max_miles:
          ownerdeliveryreturn == "yes" ? maximumtransportrange.toString() : "0",
        height_unit_type: equpHeightRadio,
        width_unit_type: equpWidthRadio,
        length_unit_type: equpLengthRadio,
        additional_miles_fee:
          ownerdeliveryreturn == "yes" ? permiledeliverchrg.toString() : "0",
        free_delivery_miles:
          ownerdeliveryreturn == "yes" ? permile.toString() : "0",
        minimum_delivery_charges:
          ownerdeliveryreturn == "yes" ? mintransportchrg.toString() : "0",
        operator: "0",
        weekends: "1",
        sunday: "1",
        height: equpheight.toString(),
        width: equpwidth.toString(),
        length: equplength.toString(),
        weight: equpweight.toString(),
        start_time: "11:00",
        end_time: "11:00",
        block_dates: "0",
        lat: LocationMarker["lat"],
        lng: LocationMarker["lng"],
        city: FullAddress.city,
        state: FullAddress.state,
        country: FullAddress.country,
        address: FullAddress.address,
        postal_code: FullAddress.postal_code,
        short_state_name: FullAddress.short_state,
        equip_from: "web",
        // sales fields
        equip_list_type: EquipType,
        mileage: Mileage ? Mileage : null,
        hours: Hours ? Hours : null,
        equip_sale_price: SalePrice,
        obo: Obo == "on" ? 1 : 0,
        rpo_available: Rpo == "on" ? 1 : 0,
      };

      // console.log("submit time data: " , body);

      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const EquipRes: IApiResponseRawData = await EquipmentAPIs.createEquip(
        body,
        headers
      );

      if (EquipRes["type"] == "RXSUCCESS") {
        ResetAllValues();
        dispatch(setalertMessage(EquipRes["message"]));
        dispatch(setalertMessagetype("success"));

        // setstep(1);
        // setActivestep([1]);
        // setINActivestep([1, 2, 3, 4]);
        let EquipID = EquipRes["data"]["Equipment_id"];
        localStorage.setItem("EquipID", EquipID);
        localStorage.setItem("EquipType", EquipType);
        if(EquipType=='sale'){
          window.location.href = "/mySalesYard";
          return;
        }
        window.location.href = "/myofficetrailer";
      } else {
        setloading(false);
        dispatch(setalertMessage(EquipRes["message"]));
        dispatch(setalertMessagetype("error"));
      }
    } catch (err) {
      setloading(false);
      console.log(err);
    }
  };

  // Get city state country address etc from geocode
  const getFullAddress = async (center) => {
    if (center.lat != null && center.lng != null) {
      setLocationMarker(center);
      Geocode.fromLatLng(center.lat, center.lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          let city, state, country, short_state, postal_code;
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "locality":
                  city = response.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].long_name;
                  break;
                case "country":
                  country = response.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  short_state =
                    response.results[0].address_components[i].short_name;
                  break;
                case "postal_code":
                  postal_code =
                    response.results[0].address_components[i].short_name;
                  break;
              }
            }
          }
          if(!postal_code) {
            const addressData = response.results.find(address => address.types.includes("postal_code"));
            const postalCodeArr = addressData?.address_components?.find(address => !isNaN(+address.short_name));
            postal_code = postalCodeArr?.short_name || postalCodeArr?.long_name || "";
          }
          let full_address = {
            address: address,
            city: city,
            state: state,
            short_state: short_state,
            postal_code: postal_code,
            country: country,
          };
          setFullAddress(full_address);
          //setLocationAddr(full_address)
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      setFullAddress({
        address: null,
        city: null,
        state: null,
        short_state: null,
        postal_code: null,
        country: null,
      });
      setLocationMarker({});
    }
  };

  // Remove Images
  const removeImage = (fileName, i) => {
    var myArray = [...file];
    var _myarray = [...imagearr];
    const index = myArray.indexOf(fileName);
    if (index > -1) {
      setThumbimage(i == Thumbimage ? 0 : Thumbimage > i ? 0 : 0);
      myArray.splice(index, 1);
      _myarray.splice(index, 1);
    }
    setFile(myArray);
    setimagearr(_myarray);

    if (_myarray.length == 0 && Uploadedpath.length > 0) {
      var uploadedPathFile = [...Uploadedpath];
      uploadedPathFile.splice(i, 1);
      // added by GD 0n 4 jan 2024 due to Uploadedpath undefined issue
      setUploadedpath(uploadedPathFile);
    }
    // commented by GD 0n 4 jan 2024 due to Uploadedpath undefined issue
    // setUploadedpath(uploadedPathFile);

    // setimagearr(myArray)
  };

  const ResetAllValues = () => {
    // step 1
    setcategory("");
    sethandlelistingTitle("");
    sethandlemakemodel("");

    //reset sales fields
    // setEquipType("rent");   // by GD commented due to show of transport
    setYear("");
    setFeature("");
    setMileage("");
    setHours("");
    setpendingDiscriptionChar(1000);
    setDescription("");

    // step 2
    setThumbimage(0);
    setimagearr([]);
    setUploadedpath([]);
    setFile([]);
    // step 3
    setpriceperhour("");
    setpriceperday("");
    setpriceperweek("");
    setpricepermonth("");

    // reset sale fields
    setSalePrice("");
    setObo("off");
    setRpo("off");

    // step 4
    setrentpickupreturn("yes");
    setownerdeliveryreturn("yes");
    setequpheight("");
    setequpHeightRadio("ft");
    setequpwidth("");
    setequpWidthRadio("ft");
    setequplength("");
    setequpLengthRadio("ft");
    setequpweight("");
    setmintransportchrg("");
    setpermiledeliverchrg("");
    setpermile("");
    setmaximumtransportrange("");
    // step 5
    setLocationMarker({});
    setFullAddress({
      address: "",
      city: "",
      state: "",
      short_state: "",
      postal_code: "",
      country: "",
    });
    //setstep(1)
    setloading(false);
  };

  // useEffect(() => {
  //     checkLoginorNot()
  // }, []);

  // by gd for images debug on 3 jan 2024
  // useEffect(() => {
  //   console.log("UploadedPath in effect: " , Uploadedpath);
  // }, Uploadedpath);

  const getuserprofile = async (user_id, Token) => {
    try {
      const headers = { Authorization: `${Token}`, request_from: "Web" };
      const profileData: IApiResponseRawData = await EquipmentAPIs.getProfile(
        {
          user_id: user_id.toString(),
        },
        headers
      );
      if (profileData["type"] == "RXSUCCESS") {
        let profile_status = profileData["data"]["profile_image"];
        let licence_status = profileData["data"]["licence_pic"];
        setProfilePicStatus(profile_status);
        setLicenceStatus(licence_status);

        //console.log('object',  profileData['data']['address']);

        // commented by GD 23 Jan 2024 due to location error when user does not have a location
        // setFullAddressMap(profileData["data"]["address"]);

        // setMapLocation({
        //   lat: Number(profileData["data"]["lat"]),
        //   lng: Number(profileData["data"]["lng"]),
        // });
        // end comment by GD

        // by GD 23 Jan 2024
        if(profileData['data']['address']){
          setFullAddressMap(profileData["data"]["address"])
        }

        // console.log('profileData',  profileData['data']);

        setMapLocation({
          lat: parseFloat(profileData["data"]["lat"]) || defaultLocation.lat,
          lng: parseFloat(profileData["data"]["lng"]) || defaultLocation.lng,
        });

        // end by GD
        LoadCategories();
      }
      // else if (profileData["status"] == false && profileData["slug"] == "Session Expired") {
      //     dispatch(setSessionExpire(true));
      //     return false
      // }
      else {
        dispatch(setalertMessage(profileData["message"]));
        dispatch(setalertMessagetype("error"));
        return false;
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    let auth_user = localStorage.getItem("token-info");
    if (auth_user != "undefined") {
      auth_user = JSON.parse(auth_user);
      if (auth_user != null && auth_user) {
        dispatch(setLoginStatus(true));
        dispatch(setaccessToken(auth_user["auth_token"]));
        getuserprofile(auth_user["user_id"], auth_user["auth_token"]);
      }
    }

    if (AuthProfileData && AuthProfileData != null) {

      // commented by GD 24 Jan 2024
      // setMapLocation({
      //   lat: Number(AuthProfileData["lat"]),
      //   lng: Number(AuthProfileData["lng"]),
      // });

      // setLocationMarker({
      //   lat: Number(AuthProfileData["lat"]),
      //   lng: Number(AuthProfileData["lng"]),
      // });
      // end comment by GD

      // by GD 24 jan 2024
      setMapLocation({
        lat: parseFloat(AuthProfileData["lat"]) || defaultLocation.lat,
        lng: parseFloat(AuthProfileData["lng"]) || defaultLocation.lng,
      });

      setLocationMarker({
        lat: parseFloat(AuthProfileData["lat"]) || defaultLocation.lat,
        lng: parseFloat(AuthProfileData["lng"]) || defaultLocation.lng,
      });

      // end by GD
      let full_address = {
        address: AuthProfileData["address"],
        city: AuthProfileData["city"],
        state: AuthProfileData["state"],
        short_state: AuthProfileData["state"],
        postal_code: AuthProfileData["postal_code"],
        country: AuthProfileData["country"],
      };
      setFullAddress(full_address);
    }
  }, []);

  // For sale equipment section step 1
  const HandleEquipeType = (event) => {
    if (event.target.value == "rent") {
      // setEquipType("rent");
      dispatch(setEquipAddType("rent"));
    } else {
      // setEquipType("sale");
      dispatch(setEquipAddType("sale"));
    }
  };

  // by gd for setEquipType CheckBox step 1
  // const HandleEquipeTypeRent = (event) => {
  //   let val = event.target.checked;
  //   if (val) {
  //     setEquipType("rent");
  //   } else {
  //     setEquipType("sale");
  //   }
  // }

  // const HandleEquipeTypeSale = (event) => {
  //   let val = event.target.checked;
  //   if (val) {
  //     setEquipType("sale");
  //   } else {
  //     setEquipType("rent");
  //   }
  // }
  // end by GD
  const HandleYear = (event) => {
    setYear(event.target.value);
  };

  const HandleFeature = (event) => {
    setFeature(event.target.value);
  };

  const HandleMileage = (event) => {
    var val = event.target.value;
    val = val.replace(/\D/g, "");

    //if (val === '' || /^\d+(\.\d{0,2})?$/.test(val)) {
    setMileage(val);
    //}
  };

  const HandleHours = (event) => {
    var val = event.target.value;
    val = val.replace(/\D/g, "");
    setHours(val);
  };

  const HandleDescription = (event) => {
    let val = event.target.value;
    let len = val.length;

    if (len > 1000) {
      let remove_char = len - 1000;
      val = val.substring(0, val.length - remove_char);
      setpendingDiscriptionChar(0);
      setDescription(val);
    } else {
      setpendingDiscriptionChar(1000 - len);
      setDescription(val);
    }
  };

  // For sale equipment section step 3
  const HandleSalePrice = (event) => {
    let val = event.target.value;
    if (val === "" || /^\d+(\.\d{0,10})?$/.test(val)) {
      setSalePrice(val);
    }
  };

  const HandleOBO = (event) => {
    // let chk_status = event.target.value;
    // if (chk_status == 'off') {
    //     setObo('on')
    // } else {
    //     setObo('off')
    // }
    // by gd for checkBox work
    let val = event.target.checked;
    if (val) {
      setObo("on");
    } else {
      setObo("off");
    }
  };

  const HandleRPO = (event) => {
    // let chk_status = event.target.value;
    // if (chk_status == 'off') {
    //     setRpo('on')
    // } else {
    //     setRpo('off')
    // }

    // by gd for checkBox work
    let val = event.target.checked;
    if (val) {
      setRpo("on");
    } else {
      setRpo("off");
    }
  };

  return (
    <>
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      <Box className="list-eqp-sec list-eqp-sec-page">
        <Container>
          <Box className="list-eqp-inner">
            <Box className="list-eqp-heading">
              <Typography>Get Listing... Get Earning!</Typography>
            </Box>
            <Box className="list-eqp-grid">
              <Box className="list-eqp-grid-item">
                <Box className="list-eqp-grid-item-inner">
                  <Typography
                    className="list-eqp-yellow-p"
                    sx={{ color: "#faa61a" }}
                  >
                    LET’S GET STARTED
                  </Typography>
                  <Typography className="list-eqp-white-p">
                    You didn’t buy all that equipment just to sit in your yard!
                    Create your own rental yard with GoEquipMe’s easy to use
                    platform and earn cash!
                  </Typography>
                  <ul className="list-eqp-left-list">
                    <li className="list-eqp-left-list-item">
                      &nbsp;You keep 90% of rental fees.
                    </li>
                    <li className="list-eqp-left-list-item">
                      &nbsp;Equipment protection coverage with every rental.
                    </li>
                    <li className="list-eqp-left-list-item">
                      &nbsp;Liability protection.
                    </li>
                    <li className="list-eqp-left-list-item">
                      &nbsp;Secure merchant processing.
                    </li>
                    <li className="list-eqp-left-list-item">
                      &nbsp;Security deposit management.
                    </li>
                    <li className="list-eqp-left-list-item">
                      &nbsp;Equipment calendar tools.
                    </li>
                    <li className="list-eqp-left-list-item">
                      &nbsp;Communicate directly with your renter.
                    </li>
                    <li className="list-eqp-left-list-item">
                      &nbsp;Select delivery or pickup parameters.
                    </li>
                    <li className="list-eqp-left-list-item">
                      &nbsp;Transport pricing and options.
                    </li>
                    <li className="list-eqp-left-list-item">
                      &nbsp;Set your prices.
                    </li>
                    <li className="list-eqp-left-list-item">
                      &nbsp;List for free!
                    </li>
                  </ul>
                  <Typography
                    className="list-eqp-yellow-p"
                    sx={{ color: "#faa61a" }}
                  >
                    Put your equipment to work!
                  </Typography>
                </Box>
              </Box>
              <Box className="list-eqp-grid-item">
                <Box className="login__main-div__form">
                  {/* <Typography className="white-color">STEP {step}</Typography> */}
                  {step == 1 && (
                    <Box className="list-eqp-steps-sec">
                      <Box className="list-eqp-steps-heading">
                        <img src={Pencil} className="step-icon" alt="" />
                        <Typography>Step {step}</Typography>
                      </Box>
                      <Box className="list-eqp-steps-subheading">
                        <Typography>EQUIPMENT DESCRIPTION</Typography>
                      </Box>
                    </Box>
                  )}
                  {step == 2 && (
                    <Box className="list-eqp-steps-sec">
                      <Box className="list-eqp-steps-heading">
                        <img src={Scamera} className="step-icon" alt="" />
                        <Typography>Step {step}</Typography>
                      </Box>
                      <Box className="list-eqp-steps-subheading">
                        <Typography>EQUIPMENT PHOTOS</Typography>
                      </Box>
                    </Box>
                  )}
                  {step == 3 && (
                    <Box className="list-eqp-steps-sec">
                      <Box className="list-eqp-steps-heading">
                        <img src={iconDoller} className="step-icon" alt="" />
                        <Typography>Step {step}</Typography>
                      </Box>
                      <Box className="list-eqp-steps-subheading">
                        <Typography>EQUIPMENT PRICING</Typography>
                      </Box>
                    </Box>
                  )}

                  {EquipType == "sale" ? (
                    <>
                      {step == 4 && (
                        <Box className="list-eqp-steps-sec">
                          <Box className="list-eqp-steps-heading">
                            <img
                              src={iconLocation}
                              className="step-icon"
                              alt=""
                            />
                            <Typography>Step {step}</Typography>
                          </Box>
                          <Box className="list-eqp-steps-subheading">
                            <Typography>EQUIPMENT LOCATION</Typography>
                          </Box>
                        </Box>
                      )}
                    </>
                  ) : (
                    <>
                      {step == 4 && EquipType != "sale" && (
                        <Box className="list-eqp-steps-sec">
                          <Box className="list-eqp-steps-heading">
                            <img src={iconArrow} className="step-icon" alt="" />
                            <Typography>Step {step}</Typography>
                          </Box>
                          <Box className="list-eqp-steps-subheading">
                            <Typography> TRANSPORTATION</Typography>
                          </Box>
                        </Box>
                      )}
                      {step == 5 && (
                        <Box className="list-eqp-steps-sec">
                          <Box className="list-eqp-steps-heading">
                            <img
                              src={iconLocation}
                              className="step-icon"
                              alt=""
                            />
                            <Typography>Step {step}</Typography>
                          </Box>
                          <Box className="list-eqp-steps-subheading">
                            <Typography>EQUIPMENT LOCATION</Typography>
                          </Box>
                        </Box>
                      )}
                    </>
                  )}

                  <div className="step-bar-container MuiBox-root css-0">
                    {Activestep.map((active_index) => (
                      <span className="equipment-step-bar active" key={active_index}></span>
                    ))}

                    {INActivestep.map((active_index) => (
                      <span className="equipment-step-bar" key={active_index}></span>
                    ))}
                  </div>

                  {step == 1 && (
                    <>
                      <Typography className="list-eqp-white-p">
                        Tell us about your equipment
                      </Typography>

                      <FormControl
                        sx={{ width: "50%" }}
                        className="form-fields list-eqp-form-fields"
                      >
                        <label className="list-eqp-input-label">Category</label>
                        <Select
                          value={category}
                          onChange={changeCategory}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          sx={{
                            "& .MuiSelect-select": {
                              color: "#2D2D2D !important",
                              fontFamily: "GothamBold !important",
                            },
                          }}
                          style={{
                            backgroundColor: "#fff",
                            border: "2px solid #FAA61A",
                            borderRadius: "10px",
                          }}
                          className="select-dropdwn"
                        >
                          <MenuItem value="" className="category-dropdown">
                            Select Here
                          </MenuItem>
                          {AllCategories.map((item, index) => (
                            <MenuItem
                              className="list-eqp-select-options"
                              value={item}
                              key={index}
                            >
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl
                        fullWidth
                        className="form-fields list-eqp-form-fields"
                      >
                        <label className="list-eqp-input-label">
                          Listing Title
                        </label>
                        <CssTextField
                          onChange={HandlelistingTitle}
                          id="outlined-basicd"
                          // label="Listing Title"
                          variant="outlined"
                          value={handlelistingTitle}
                          placeholder="Example: Track Skid Steer"
                          inputProps={{
                            maxLength: 150,
                          }}
                        />
                      </FormControl>

                      <FormControl
                        fullWidth
                        className="form-fields list-eqp-form-fields"
                      >
                        <label className="list-eqp-input-label">
                          Make & Model
                        </label>
                        <CssTextField
                          onChange={Handlemakemodel}
                          value={handlemakemodel}
                          id="outlined-basic"
                          // label="Make & Model"
                          variant="outlined"
                          placeholder="Example: CAT 299D"
                          inputProps={{
                            maxLength: 150,
                          }}
                        />
                      </FormControl>



                      <FormControl
                        className="form-fields form-fields-radio"
                        sx={{ paddingLeft: "1rem" }}
                      >
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                          onChange={HandleEquipeType}
                          className="radio-grp list-eqp-page new-radio-grp"
                        >
                          <FormControlLabel
                            checked={EquipType == "rent"}
                            value="rent"
                            control={<Radio />}
                            label="For Rent"
                            className="form-ctrl-label"
                            sx={{ fontFamily: "GothamBold !important" }}
                          />
                          <FormControlLabel
                            checked={EquipType == "sale"}
                            value="sale"
                            control={<Radio />}
                            label="For Sale"
                            className="form-ctrl-label"
                            sx={{ fontFamily: "GothamBold !important" }}
                          />
                        </RadioGroup>

                      </FormControl>
                      {/* by GD for checkBox of rent and sales */}
                      {/* <FormGroup sx={{ display: 'flex', alignItems: 'center',gap: '1rem', flexDirection: 'row' ,color: '#fff !important', margin:'1rem 0' }}>
                        
                        <FormControlLabel
                             control={<Checkbox
                                sx={{
                                  "&:hover": { bgcolor: "transparent" },
                                }}
                                defaultChecked={EquipType == "rent" ? true : false}
                                checked = {EquipType == "rent" ? true : false}
                                onChange={HandleEquipeTypeRent}
                                disableRipple
                                color="default"
                                // checkedIcon={<BpCheckedIcon />}
                                // icon={<BpIcon />}
                                inputProps={{ "aria-label": "Checkbox demo" }}
                              />} label="For Rent" />
                        <FormControlLabel control={<Checkbox
                                sx={{
                                  "&:hover": { bgcolor: "transparent" },
                                }}
                                // defaultChecked={EquipType == "sale" ? true : false}
                                checked = {EquipType == "sale" ? true : false}
                                onChange={HandleEquipeTypeSale}
                                disableRipple
                                color="default"
                                // checkedIcon={<BpCheckedIcon />}
                                // icon={<BpIcon />}
                                inputProps={{ "aria-label": "Checkbox demo" }}
                              />} label="For Sale" />
                      </FormGroup> */}
                      {/* end by GD */}
                      {/* by GD for terms and conditions link */}
                      {EquipType !== "sale" && (
                        <FormControl
                          fullWidth
                          className=""
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            flexDirection: "row !important",
                            gap: "8px",
                          }}
                        >
                          <FormLabel
                            className=""
                            sx={{
                              color: "#fff !important",
                              fontFamily: "Gotham !important",
                            }}
                          >
                            Review the
                          </FormLabel>
                          <Link
                            href="/terms_and_conditions"
                            target="_blank"
                            className=""
                            sx={{
                              color: "#faa61a !important",
                              textDecorationColor: "#faa61a !important",
                            }}
                          >
                            <Typography>Terms and Conditions</Typography>
                          </Link>
                        </FormControl>
                      )}
                      {/* end by GD */}
                      {EquipType == "sale" && (
                        <>
                          <FormControl
                            sx={{ width: "45%" }}
                            className="form-fields list-eqp-form-fields"
                          >
                            <label className="list-eqp-input-label">Year</label>
                            <Select
                              className="select-dropdwn"
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select"
                              value={Year}
                              label="Year"
                              onChange={HandleYear}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              sx={{
                                "& .MuiSelect-select": {
                                  color: "#2D2D2D !important",
                                  fontFamily: "GothamBold !important",
                                },
                              }}
                              style={{
                                backgroundColor: "#fff",
                                border: "2px solid #FAA61A",
                                borderRadius: "10px",
                              }}
                            >
                              <MenuItem value="">Select Year</MenuItem>
                              {yearArr.map((yearitem, index) => (
                                <MenuItem value={yearitem} key={index}>{yearitem}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          {/* <FormControl
                            fullWidth
                            className="form-fields list-eqp-form-fields"
                          >
                            <label className="list-eqp-input-label">
                              Equipment Feature
                            </label>

                            <CssTextField
                              onChange={HandleFeature}
                              value={Feature}
                              id="outlined-basic"
                              variant="outlined"
                              placeholder="Equipment Feature"
                              inputProps={{
                                maxLength: 300,
                              }}
                            />
                          </FormControl> */}

                          <FormGroup
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "repeat(2, 1fr)",
                              gap: "2rem",
                            }}
                          >
                            <FormControl
                              fullWidth
                              className="form-fields list-eqp-form-fields"
                            >
                              <label className="list-eqp-input-label">
                                Mileage
                              </label>
                              <CssTextField
                                id="outlined-disabled"
                                // className="popup-input"
                                //label="Mileage"
                                onChange={HandleMileage}
                                value={Mileage ? Number(Mileage).toLocaleString() : Mileage}
                                variant="outlined"
                                placeholder="xx mileage"
                                inputProps={{
                                  maxLength: 12,
                                }}
                              />
                            </FormControl>

                            <FormControl
                              fullWidth
                              className="form-fields list-eqp-form-fields"
                            >
                              <label className="list-eqp-input-label">
                                Hours
                              </label>

                              <CssTextField
                                id="max-transaport-range"
                                //label="Hours"
                                placeholder={"xx hours"}
                                variant="outlined"
                                onChange={HandleHours}
                                value={Hours ? Number(Hours).toLocaleString() : Hours}
                                // className="popup-input"
                                // InputProps={{ disableUnderline: true }}
                                // InputLabelProps={{ shrink: false }}
                                inputProps={{
                                  maxLength: 12,
                                }}
                              />
                            </FormControl>
                          </FormGroup>

                          <FormControl
                            fullWidth
                            className="form-fields list-eqp-form-fields"
                          >
                            <label className="list-eqp-input-label">
                              Equipment Description
                            </label>

                            <CssTextField
                              id="outlined-multiline-static"
                              //label="Equipment Description"
                              multiline
                              rows={4}
                              fullWidth
                              placeholder="Max: 1000 Words"
                              // variant="filled"
                              onChange={HandleDescription}
                              value={Description}
                            // className="popup-input"
                            // InputProps={{ disableUnderline: true }}
                            // InputLabelProps={{ shrink: false }}
                            />
                            <Box
                              className="characters-p"
                              sx={{
                                marginTop: "5px",
                                fontFamily: "Gotham !important",
                                fontWeight: "300",
                                fontSize: "12px",
                                display: "flex",
                                alignItems: "flex-end",
                                justifyContent: "flex-end",
                              }}
                            >
                              Characters Left :&nbsp;
                              <span style={{ color: "#fff" }}>
                                {pendingDiscriptionChar}
                              </span>
                            </Box>
                          </FormControl>

                          <FormControl
                            fullWidth
                            className=""
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              flexDirection: "row !important",
                              gap: "8px",
                            }}
                          >
                            <FormLabel
                              className=""
                              sx={{
                                color: "#fff !important",
                                fontFamily: "Gotham !important",
                              }}
                            >
                              Review the
                            </FormLabel>
                            <Link
                              href="/terms_and_conditions"
                              target="_blank"
                              className=""
                              sx={{
                                color: "#faa61a !important",
                                textDecorationColor: "#faa61a !important",
                              }}
                            >
                              <Typography>Terms and Conditions</Typography>
                            </Link>
                          </FormControl>
                        </>
                      )}

                      <Box
                        className=""
                        sx={{
                          mt: "3rem",
                          mb: "2rem",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          className="signin-btn cmmn-green-btn"
                          type="button"
                          variant="contained"
                          // sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                          onClickCapture={nextStep}
                          sx={{
                            border: "3px solid #faa61a",
                            borderRadius: "10px !important",
                          }}
                        >
                          Next
                        </Button>
                      </Box>
                    </>
                  )}
                  {step == 2 && (
                    <>
                      <Typography className="list-eqp-white-p">
                        Let's show what you've got
                      </Typography>

                      <Box className="img-box list-eqp-img-box">
                        <Box className="img-box-grid list-eqp-img-box-grid">
                          {file.map((image, i) => (
                            <Box
                              className="img-box-item list-eqp-img-box-item"
                              onClickCapture={() => {
                                setThumbimage(i);
                              }}
                              key={i}
                            >
                              <img
                                className="main-img-box-item list-eqp
                                          "
                                src={image}
                                alt=""
                              />
                              <IconButton
                              sx={{ zIndex: '1099' }}
                                className="cross-btn list-eqp-cross"
                                onClickCapture={() => {
                                  // setThumbimage(Thumbimage);
                                  removeImage(image, i);
                                }}
                              >
                                <CloseIcon />
                              </IconButton>

                              {Thumbimage == i && (
                                <img className="img-check" src={Check} alt="" />
                              )}
                            </Box>
                          ))}
                        </Box>
                      </Box>

                      <FormControl fullWidth className="form-fields">
                        <Button
                          variant="contained"
                          component="label"
                          className="upload-btn"
                          sx={{
                            fontSize: "25px",
                            fontFamily: "GothamBold !important",
                          }}
                        >
                          Select Photos
                          <input
                            id="photo"
                            name="photo"
                            type="file"
                            multiple={true}
                            onChange={handleImageChange}
                            hidden
                            accept="image/jpg, image/jpeg, image/png"
                          />
                        </Button>
                      </FormControl>

                      <Typography
                        className="service-item__content-para list-eqp-white-p"
                        sx={{
                          fontFamily: "GothamBold !important",
                          fontWeight: "900",
                        }}
                      >
                        PHOTO TIPS
                      </Typography>
                      <hr className="yellow-line" />
                      <Box className="photo-tips-main list-eqp-photo-tips">
                        <Box className="photo-tips-main-item">
                          <Typography className="list-eqp-suggestion-p">
                            Horizontal Orientation
                          </Typography>
                          <img src={Suggest1} className="photo-tips" alt="" />
                        </Box>
                        <Box className="photo-tips-main-item">
                          <Typography className="list-eqp-suggestion-p">
                            Well Lit
                          </Typography>
                          <img src={Suggest2} className="photo-tips" alt="" />
                        </Box>
                        <Box className="photo-tips-main-item">
                          <Typography className="list-eqp-suggestion-p">
                            No Graphics/Text
                          </Typography>
                          <img src={Suggest3} className="photo-tips" alt="" />
                        </Box>
                        <Box className="photo-tips-main-item">
                          <Typography className="list-eqp-suggestion-p">
                            Composition/Angle
                          </Typography>
                          <img src={Suggest4} className="photo-tips" alt="" />
                        </Box>
                      </Box>

                      <Box className="btns-grp list-eqp-btn-grp">
                        <Button
                          className="signin-btn cmmn-green-btn"
                          type="button"
                          variant="contained"
                          // sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                          onClickCapture={backStep}
                        >
                          Back
                        </Button>
                        <Button
                          className="signin-btn cmmn-green-btn"
                          type="button"
                          variant="contained"
                          // sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                          onClickCapture={nextStep}
                        >
                          Next
                        </Button>
                      </Box>
                    </>
                  )}
                  {step == 3 && (
                    <>
                      {EquipType == "sale" ? (
                        <>
                          <Typography className="list-eqp-white-p">
                            How much do you want for this?
                          </Typography>
                          <FormControl
                            fullWidth
                            className="form-fields list-eqp-form-fields"
                          >
                            <label className="list-eqp-input-label">
                              Asking price
                            </label>
                            <CssTextField
                              id="outlined-disabled"
                              // className="popup-input"
                              //label="Asking price ($)"
                              onChange={HandleSalePrice}
                              value={SalePrice}
                              // variant="filled"
                              placeholder="$"
                              inputProps={{
                                maxLength: 20,
                              }}
                            />
                          </FormControl>
                          {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" /> */}
                          <FormControl className="">
                            {/* <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue="female"
                                  name="radio-buttons-group"
                                  onClick={HandleOBO}
                                  className="radio-grp list-eqp-page"
                              > */}
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              {/* <FormControlLabel
                                  checked={Obo == 'on'}
                                  value={Obo}
                                  control={<Radio />}
                                  label={
                                      <Box>
                                          <Typography sx={{ gap: '8px' }}>
                                              <span className='radio-span-yellow' style={{ fontFamily: '', color: '#faa61a !important' }}>
                                                  OBO:
                                              </span>
                                              <span className='radio-span-white' style={{ fontFamily: '', color: '#fff !important' }}>
                                                  Or Best Offers.
                                              </span>
                                          </Typography>
                                      </Box>
                                  }
                                  className="radio-grp"
                                /> */}
                              {/* by GD for checkbox on obo and Rpo */}
                              <Checkbox
                                sx={{
                                  "&:hover": { bgcolor: "transparent" },
                                }}
                                // defaultChecked={Obo == "on" ? true : false}
                                value={Obo == "on" ? true : false}
                                onChange={HandleOBO}
                                disableRipple
                                color="default"
                                // checkedIcon={<BpCheckedIcon />}
                                // icon={<BpIcon />}
                                inputProps={{ "aria-label": "Checkbox demo" }}
                              />
                              <Box>
                                <Typography sx={{ gap: "8px" }}>
                                  <span
                                    className="radio-span-yellow"
                                    style={{
                                      fontFamily: "",
                                      color: "#faa61a !important",
                                    }}
                                  >
                                    OBO:
                                  </span>
                                  <span
                                    className="radio-span-white"
                                    style={{
                                      fontFamily: "",
                                      color: "#fff !important",
                                    }}
                                  >
                                    Or Best Offers.
                                  </span>
                                </Typography>
                              </Box>

                              {/* end by GD */}
                            </Box>

                            {/* </RadioGroup> */}
                          </FormControl>

                          <FormControl className="">
                            {/* <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                onClick={HandleRPO}
                                className="radio-grp list-eqp-page"
                            > */}
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              {/* <FormControlLabel
                                      checked={Rpo == 'on'}
                                      value={Rpo}
                                      control={<Radio />}
                                      label={
                                          <Box sx={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap' }}>
                                              <Typography sx={{ gap: '8px' }}>
                                                  <span className='radio-span-yellow' style={{ fontFamily: '', color: '#faa61a !important' }}>
                                                      RPO Available:
                                                  </span>
                                                  <span className='radio-span-white' style={{ fontFamily: '', color: '#fff !important' }}>
                                                      The owner may offer this as a rental with purchase option.
                                                  </span>
                                              </Typography>
                                          </Box>
                                      }
                                      className="radio-grp"

                                  /> */}
                              <Checkbox
                                sx={{
                                  "&:hover": { bgcolor: "transparent" },
                                }}
                                // defaultChecked={Rpo == "on" ? true : false}
                                value={Rpo == "on" ? true : false}
                                onChange={HandleRPO}
                                disableRipple
                                color="default"
                                // checkedIcon={<BpCheckedIcon />}
                                // icon={<BpIcon />}
                                inputProps={{ "aria-label": "Checkbox demo" }}
                              />

                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  flexWrap: "wrap",
                                }}
                              >
                                <Typography sx={{ gap: "8px" }}>
                                  <span
                                    className="radio-span-yellow"
                                    style={{
                                      fontFamily: "",
                                      color: "#faa61a !important",
                                    }}
                                  >
                                    RPO Available:
                                  </span>
                                  <span
                                    className="radio-span-white"
                                    style={{
                                      fontFamily: "",
                                      color: "#fff !important",
                                    }}
                                  >
                                    The owner may offer this as a rental with
                                    purchase option.
                                  </span>
                                </Typography>
                              </Box>
                            </Box>
                            {/* </RadioGroup> */}
                          </FormControl>
                        </>
                      ) : (
                        <>
                          <Typography className="set-rental-price-p">
                            Set your rental prices
                          </Typography>
                          <FormControl
                            fullWidth
                            className="form-fields list-eqp-form-fields"
                          >
                            <TextField
                              id="outlined-disabled"
                              className="popup-input"
                              label="Price per hour ($)"
                              onChange={PriceperHour}
                              value={priceperhour}
                              variant="filled"
                              placeholder="Price per hour"
                            />
                          </FormControl>
                          <FormControl
                            fullWidth
                            className="form-fields list-eqp-form-fields"
                          >
                            <TextField
                              id="outlined-disabled"
                              className="popup-input"
                              label="Price per day ($)"
                              onChange={PriceperDay}
                              value={priceperday}
                              variant="filled"
                              placeholder="Price per day"
                            />
                          </FormControl>
                          <FormControl
                            fullWidth
                            className="form-fields  list-eqp-form-fields"
                          >
                            <TextField
                              id="outlined-disabled"
                              className="popup-input"
                              label="Price per week ($)"
                              placeholder="Price per week"
                              onChange={PriceperWeek}
                              value={priceperweek}
                              variant="filled"
                            />
                          </FormControl>
                          <FormControl
                            fullWidth
                            className="form-fields  list-eqp-form-fields"
                          >
                            <TextField
                              id="outlined-disabled"
                              className="popup-input"
                              label="Price per month ($)"
                              placeholder="Price per month"
                              onChange={PriceperMonth}
                              value={pricepermonth}
                              variant="filled"
                            />
                          </FormControl>
                          <FormControl
                            fullWidth
                            className="form-fields  list-eqp-form-fields"
                          >
                            <label className="list-eqp-input-label">
                              Hours included per day
                            </label>
                            <CssTextField
                              //disabled
                              id="outlined-disabled"
                              // label="Hours included per day"
                              placeholder="Hours included per day"
                              defaultValue="24"
                              onChange={HourInclude}
                              value={hourinclude}
                              inputProps={{
                                maxLength: 2,
                              }}
                            />
                          </FormControl>
                        </>
                      )}

                      <Box className="btns-grp list-eqp-btn-grp">
                        <Button
                          className="signin-btn cmmn-green-btn"
                          type="button"
                          variant="contained"
                          // sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                          onClickCapture={backStep}
                        >
                          Back
                        </Button>
                        <Button
                          className="signin-btn cmmn-green-btn"
                          type="button"
                          variant="contained"
                          // sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                          onClickCapture={nextStep}
                        >
                          Next
                        </Button>
                      </Box>
                    </>
                  )}

                  {EquipType == "sale" ? (
                    <>
                      {step == 4 && (
                        <>
                          <Typography className="list-eqp-white-p">
                            Tell us where your equipment is located.
                          </Typography>
                          <FormControl fullWidth className="form-fields">
                            <GoogleautoComplete
                              onPress={(center) => {
                                getFullAddress(center);
                              }}
                              inputRefs={inRef}
                              searchRef={searchRef}
                              equipLocation={MapLocation}
                              equipAddress={FullAddressMap}
                            />
                          </FormControl>
                          <Box className="btns-grp list-eqp-btn-grp">
                            <Button
                              className="signin-btn cmmn-green-btn"
                              type="button"
                              variant="contained"
                              // sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                              onClickCapture={backStep}
                            >
                              Back
                            </Button>
                            <Button
                              className="signin-btn cmmn-green-btn"
                              type="button"
                              variant="contained"
                              //onClickCapture={uploadfiles}
                              onClickCapture={formSubmit}
                            >
                              Submit
                            </Button>
                          </Box>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {(step == 4 && EquipType != "sale") && (
                        <>
                          <Typography className="list-eqp-white-p">
                            SELECT TRANSPORT OPTIONS
                          </Typography>
                          <FormControl className="form-fields form-fields-radio">
                            <FormLabel
                              id="demo-radio-buttons-group-label"
                              className="radio-label list-eqp-steps-label"
                            >
                              Renter pick up and return?
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue="female"
                              name="radio-buttons-group"
                              onChange={Rentpickupreturn}
                              className="radio-grp list-eqp-page"
                            >
                              <FormControlLabel
                                checked={rentpickupreturn == "yes"}
                                value="yes"
                                control={<Radio />}
                                label="Yes"
                                className="form-ctrl-label"
                              />
                              <FormControlLabel
                                checked={rentpickupreturn == "no"}
                                value="no"
                                control={<Radio />}
                                label="No"
                                className="form-ctrl-label"
                              />
                            </RadioGroup>
                          </FormControl>
                          <FormControl className="form-fields form-fields-radio">
                            <FormLabel
                              id="demo-radio-buttons-group-label"
                              className="radio-label list-eqp-steps-label"
                            >
                              Owner delivery and return?
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue="female"
                              name="radio-buttons-group"
                              onChange={OwnerDeliveryReturn}
                              className="radio-grp list-eqp-page"
                            >
                              <FormControlLabel
                                checked={ownerdeliveryreturn == "yes"}
                                value="yes"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                checked={ownerdeliveryreturn == "no"}
                                value="no"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                          {/* by GD for show hide rent Pickup fields acc to Renter pick up option selected  */}
                          {rentpickupreturn == "yes" && (
                            <>
                              <Typography className="list-eqp-white-p">
                                EQUIPMENT TRANSPORT SPECIFICATION
                              </Typography>
                              <FormControl
                                fullWidth
                                className="form-fields form-fields-radio"
                              >
                                <CssNumericInput
                                  name="example"
                                  precision={0}
                                  decimalChar="."
                                  thousandChar=","
                                  placeholder="Height"
                                  onChange={EqupHeight}
                                  value={equpheight}
                                  variant="outlined"
                                  inputProps={{
                                    maxLength: 7,
                                  }}
                                />

                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue="ft"
                                  name="radio-buttons-group"
                                  onChange={EqupHeightRadio}
                                  className="radio-grp list-eqp-page"
                                >
                                  <FormControlLabel
                                    checked={equpHeightRadio == "ft"}
                                    value="ft"
                                    control={<Radio />}
                                    label="feet"
                                  />
                                  <FormControlLabel
                                    checked={equpHeightRadio == "in"}
                                    value="in"
                                    control={<Radio />}
                                    label="Inches"
                                  />
                                </RadioGroup>
                              </FormControl>
                              <FormControl
                                fullWidth
                                className="form-fields form-fields-radio"
                              >
                                <CssNumericInput
                                  name="example"
                                  precision={0}
                                  decimalChar="."
                                  thousandChar=","
                                  placeholder="Width"
                                  onChange={EqupWidth}
                                  value={equpwidth}
                                  variant="outlined"
                                  inputProps={{
                                    maxLength: 7,
                                  }}
                                />
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue="ft"
                                  name="radio-buttons-group"
                                  className="radio-grp list-eqp-page"
                                  onChange={EqupWidthRadio}
                                >
                                  <FormControlLabel
                                    checked={equpWidthRadio == "ft"}
                                    value="ft"
                                    control={<Radio />}
                                    label="feet"
                                  />
                                  <FormControlLabel
                                    checked={equpWidthRadio == "in"}
                                    value="in"
                                    control={<Radio />}
                                    label="Inches"
                                  />
                                </RadioGroup>
                              </FormControl>
                              <FormControl
                                fullWidth
                                className="form-fields form-fields-radio"
                              >
                                <CssNumericInput
                                  name="example"
                                  precision={0}
                                  decimalChar="."
                                  thousandChar=","
                                  placeholder="Length"
                                  onChange={EqupLength}
                                  value={equplength}
                                  variant="outlined"
                                  inputProps={{
                                    maxLength: 7,
                                  }}
                                />
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue="ft"
                                  name="radio-buttons-group"
                                  className="radio-grp list-eqp-page"
                                  onChange={EqupLengthRadio}
                                >
                                  <FormControlLabel
                                    checked={equpLengthRadio == "ft"}
                                    value="ft"
                                    control={<Radio />}
                                    label="feet"
                                  />
                                  <FormControlLabel
                                    checked={equpLengthRadio == "in"}
                                    value="in"
                                    control={<Radio />}
                                    label="Inches"
                                  />
                                </RadioGroup>
                              </FormControl>
                              <FormControl
                                fullWidth
                                className="form-fields form-fields-radio"
                              >
                                <CssNumericInput
                                  name="example"
                                  precision={0}
                                  decimalChar="."
                                  thousandChar=","
                                  placeholder="Weight"
                                  onChange={EqupWeight}
                                  value={equpweight}
                                  variant="outlined"
                                  inputProps={{
                                    maxLength: 7,
                                  }}
                                />
                                <FormLabel
                                  id="demo-radio-buttons-group-label"
                                  className="radio-label list-eqp-steps-label"
                                >
                                  lbs.
                                </FormLabel>
                              </FormControl>
                            </>
                          )}

                          {/* end by GD */}
                          {ownerdeliveryreturn == "yes" && (
                            <>
                              <Typography className="list-eqp-white-p">
                                TRANSPORTATION RATES
                              </Typography>
                              <FormControl fullWidth className="form-fields">
                                <TextField
                                  id="outlined-disabled"
                                  className="popup-input"
                                  //label="Min. transport charges (for round trip) ($)"
                                  placeholder="Min. transport charges (for round trip) ($)"
                                  onChange={MinTransportChrg}
                                  value={mintransportchrg}
                                  variant="outlined"
                                />
                                {/* <CssNumericInput
                                                            prefix={'$'}
                                                            name="example"
                                                            precision={0}
                                                            decimalChar="."
                                                            thousandChar=","
                                                            placeholder="Min. transport charges (for round trip)"
                                                            onChange={MinTransportChrg}
                                                            value={mintransportchrg}
                                                            variant="outlined"
                                                            inputProps={{
                                                                maxLength: 7,
                                                            }}
                                                        /> */}
                              </FormControl>
                              <FormControl fullWidth className="form-fields">
                                <CssNumericInput
                                  // prefix={'$'}
                                  name="example"
                                  precision={0}
                                  decimalChar="."
                                  thousandChar=","
                                  placeholder="Per-mile charge begins after"
                                  onChange={PerMileDeliverChrg}
                                  value={permiledeliverchrg}
                                  variant="outlined"
                                  inputProps={{
                                    maxLength: 7,
                                  }}
                                />
                              </FormControl>
                              <FormControl fullWidth className="form-fields">
                                <TextField
                                  id="outlined-disabled"
                                  className="popup-input"
                                  //label="Per mile rate ($)"
                                  placeholder="Per mile rate ($)"
                                  onChange={PerMile}
                                  value={permile}
                                  variant="outlined"
                                />
                                {/* <CssNumericInput
                                                            prefix={'$'}
                                                            name="example"
                                                            precision={0}
                                                            decimalChar="."
                                                            thousandChar=","
                                                            placeholder="Per mile rate"
                                                            onChange={PerMile}
                                                            value={permile}
                                                            variant="outlined"
                                                            inputProps={{
                                                                maxLength: 7,
                                                            }}
                                                        /> */}
                              </FormControl>
                              <FormControl fullWidth className="form-fields">
                                <TextField
                                  id="outlined-disabled"
                                  className="popup-input"
                                  //label="Maximum transport range ($)"
                                  placeholder="Maximum transport range ($)"
                                  onChange={MaximumTransportRange}
                                  value={maximumtransportrange}
                                  variant="outlined"
                                />
                                {/* <CssNumericInput
                                                            name="example"
                                                            precision={0}
                                                            decimalChar="."
                                                            thousandChar=","
                                                            placeholder="Maximum transport range"
                                                            onChange={MaximumTransportRange}
                                                            value={maximumtransportrange}
                                                            variant="outlined"
                                                            inputProps={{
                                                                maxLength: 7,
                                                            }}
                                                        /> */}
                              </FormControl>
                            </>
                          )}
                          <Box className="btns-grp list-eqp-btn-grp">
                            <Button
                              className="signin-btn cmmn-green-btn"
                              type="button"
                              variant="contained"
                              // sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                              onClickCapture={backStep}
                            >
                              Back
                            </Button>
                            <Button
                              className="signin-btn cmmn-green-btn"
                              type="button"
                              variant="contained"
                              // sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                              onClickCapture={nextStep}
                            >
                              Next
                            </Button>
                          </Box>
                        </>
                      )}
                      {(step == 5 && EquipType != "sale") && (
                        <>
                          <Typography className="list-eqp-white-p">
                            Tell us where your equipment is located.
                          </Typography>
                          <FormControl fullWidth className="form-fields">
                            <GoogleautoComplete
                              onPress={(center) => {
                                getFullAddress(center);
                              }}
                              inputRefs={inRef}
                              searchRef={searchRef}
                              equipLocation={MapLocation}
                              equipAddress={FullAddressMap}
                            />
                          </FormControl>

                          <Box className="btns-grp list-eqp-btn-grp">
                            <Button
                              className="signin-btn cmmn-green-btn"
                              type="button"
                              variant="contained"
                              // sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                              onClickCapture={backStep}
                            >
                              Back
                            </Button>
                            <Button
                              className="signin-btn cmmn-green-btn"
                              type="button"
                              variant="contained"
                              //onClickCapture={uploadfiles}
                              onClickCapture={formSubmit}
                            >
                              Submit
                            </Button>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default AddEquipment;
