import DescriptionSection from 'components/description-section/DescriptionSection'
// import EquipmentsView from 'components/equipments-view/EquipmentsView'
import SearchSection from 'components/search-section/SearchSection'
import ServiceSection from 'components/service-section/ServiceSection'
import React from 'react'
import MainLayout from '../../layouts/MainLayout'

/**
 * Main Home page.
 */
const Home: React.FC = () => {
  
  return (
    <MainLayout insideMainContainer={false}>
      <SearchSection />
      {/* <EquipmentsView /> */}
      <DescriptionSection />
      <ServiceSection />
     
    </MainLayout>
  )
}

export default Home