import React, { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import _Array from "components/ImageArray/Images";
import { Pagination, Navigation, Autoplay } from "swiper";
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import "./swipercarousel.css";


const SwiperCarousel = () => {
  return (
    <>
      <Swiper
        spaceBetween={5}
        slidesPerView={4}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
        navigation={true}
        rewind={true}
        slidesOffsetAfter={0}
        slidesOffsetBefore={0}
        loop={true}
        // centeredSlides={true}
        // centeredSlidesBounds= {true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          640: {
            slidesPerView: 0,
            spaceBetween: 5,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          1400: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          1800: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          2000: {
            slidesPerView: "auto",
            spaceBetween: 5,
          },
          2200: {
            slidesPerView: "auto",
            spaceBetween: 5,
          },
          2600: {
            slidesPerView: "auto",
            spaceBetween: 5,
          },
          3000: {
            slidesPerView: "auto",
            spaceBetween: 5,
          },
          3200: {
            slidesPerView: "auto",
            spaceBetween: 5,
          },
          4200: {
            slidesPerView: "auto",
            spaceBetween: 5,
          },
        }}
        lazyPreloaderClass='swiper-lazy-preloader'
      >
        {_Array.map((img, i) => {
          return (
            <SwiperSlide key={i}>
              <img src={img} alt="" className="img-slider" />

            </SwiperSlide>
          );
        })}

      </Swiper>
    </>
  );

};

export default SwiperCarousel;

