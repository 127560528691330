import React, { useCallback, useEffect, useState, useRef } from "react";
import { EquipmentAPIs } from "../../../service/api-service";
import {
  Box,
  CircularProgress,
  Typography,
  Backdrop,
  Container,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  setalertMessagetype,
  setalertMessage,
  setSessionExpire,
} from "redux/redux-slice";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import gemArrow from "assets/images/gem-arrow.png";
import ForSale from "assets/images/for-sale.png";
import "./../newstyles.scss";
import ThumbsImg from "../../../assets/images/likeMe.png";
// import ThumbsImg from 'assets/images/favorite-filled.png'
import CurrentFormat from "../../common/CurrenyFormat";
import MainLayout from "layouts/MainLayout";
const ThumbsUp = () => {
  const dispatch = useDispatch();
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const AuthUserID = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const AuthProfileData = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );
  const NotificationsList = useSelector(
    (state: IStoreValue) => state.equipmentReducer.NotificationList
  );

  const [loading, setloading] = React.useState(false);
  const [EquipResult, setEquipResult] = React.useState([]);
  const [TotalResult, setTotalResult] = React.useState(0);
  const [Page, setPage] = React.useState(0);
  const [CurrentActiveEquip, setCurrentActiveEquip] = React.useState(null);

  // Get All Liked Equip
  const LikeEquip = async (user_id, new_page) => {
    try {
      setloading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const EquipData = await EquipmentAPIs.GetLikedEquip(
        {
          user_id: user_id.toString(),
          page: new_page,
        },
        headers
      );
      console.log("likedEquips: ", EquipData);

      if (EquipData["type"] == "RXSUCCESS") {
        setPage(new_page);
        setTotalResult(EquipData["total"]);
        let result = EquipData["data"];
        // console.log('result',result);
        if (new_page == 0) {
          setEquipResult(result);
          setCurrentActiveEquip(result[0]["id"]);
        } else {
          let old_EquipData = EquipResult;
          let new_EquipData = old_EquipData.concat(result);
          setEquipResult(new_EquipData);
        }
        setloading(false);
      } else if (
        EquipData["status"] == false &&
        EquipData["slug"] == "Session Expired"
      ) {
        //dispatch(setSessionExpire(true));
        setloading(false);
        return false;
      } else {
        dispatch(setalertMessage(EquipData["message"]));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }
    } catch (err) {
      console.log(err);
      setloading(false);
    }
  };

  const LikeDislikeEquip = async (equip_id, index, type) => {
    try {
      setloading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const EquipData = await EquipmentAPIs.AddToLikeDislike(
        {
          user_id: AuthUserID.toString(),
          equip_id: equip_id.toString(),
          status: "0",
          liked_equip_list_type: type,
        },
        headers
      );

      if (EquipData["type"] == "RXSUCCESS") {
        dispatch(setalertMessage("Action completed."));
        dispatch(setalertMessagetype("success"));
        var Old_Results = [...EquipResult];
        Old_Results.splice(index, 1);
        setTotalResult(Old_Results.length);
        setEquipResult(Old_Results);
        setloading(false);
      } else if (
        EquipData["status"] == false &&
        EquipData["slug"] == "Session Expired"
      ) {
        // dispatch(setSessionExpire(true));
        setloading(false);
        return false;
      } else {
        dispatch(setalertMessage(EquipData["message"]));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }
    } catch (err) {
      console.log(err);
      setloading(false);
    }
  };

  // Handle pagination click
  const HandlePagination = () => {
    let new_page = Number(Page) + 1;
    LikeEquip(AuthUserID, new_page);
  };

  // Handle Equipe detail
  const HandleEquipDetail = (Equip) => {
    var make = Equip["make"] ? Equip["make"] : "NA";
    make = make.replace("/", "'");
    make = make.replace(/ /g, "-");

    var city = Equip["city"] ? Equip["city"] : "NA";
    city = city.replace("/", "'");
    city = city.replace(/ /g, "-");

    var state = Equip["state"] ? Equip["state"] : "NA";
    state = state.replace("/", "'");
    state = state.replace(/ /g, "-");

    var owner_id = Equip["user_id"];
    var postal_code =
      Equip["postal_code"] != "undefined" ? Equip["postal_code"] : 0;
    postal_code = postal_code != "" ? postal_code : 0;

    let link =
      "detail/" +
      make +
      "/" +
      city +
      "/" +
      state +
      "/" +
      postal_code +
      "/" +
      owner_id +
      "/" +
      Equip["id"];
    link = link.replace("#", "");

    let final_link = link + "/" + Equip["equip_list_type"];

    window.location.href = final_link;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (AuthProfileData && AuthProfileData != null) {
      LikeEquip(AuthProfileData["user_id"], Page);
    }
  }, [AuthProfileData]);

  return (
    <>
      <MainLayout>
        {loading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}

        <Container>
          <Box>
            <Typography
              sx={{
                fontSize: {
                  xs: "20px",
                  sm: "22px",
                  md: "28px",
                  lg: "34px",
                  xl: "40px",
                },
                color: "#fff !important",
                mb: 2,
                fontWeight: "800",
                fontFamily: "Montserrat !important",
              }}
            >
              My Saved Equipment
            </Typography>
          </Box>

          {/* <Box className="thumbs-up-grid"> */}
          <Box className="thumbs-up-grid">
            {TotalResult > 0 ? (
              <>
                {EquipResult.map((equipment, indx) => (
                  <Box className="thumbs-up-grid-item">
                    <Box
                      className={
                        CurrentActiveEquip == equipment["id"]
                          ? "rentyrd-card rental-box-active"
                          : "rentyrd-card"
                      }
                      onClick={() => {
                        setCurrentActiveEquip(equipment["id"]);
                      }}
                    >
                      <Box
                        // className="rentyrd-card-grid"
                        sx={{
                          display: "flex",
                          flexDirection: {
                            xs: "column",
                            sm: "row",
                            md: "row",
                            lg: "row",
                            xl: "row",
                          },
                          alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        <Box
                          // className="rentyrd-card-item-left"
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "auto",
                              md: "auto",
                              lg: "auto",
                              xl: "auto",
                            },
                          }}
                        >
                          <Box
                            // className="rentyrd-card-img"
                            sx={{ position: "relative", width: "100%" }}
                          >
                            <Box
                              component={"img"}
                              className="eqppmnt-img"
                              src={equipment["thumbnail"]}
                              alt=""
                              sx={{
                                width: {
                                  xs: "100%",
                                  sm: "200px",
                                  md: "250px",
                                  lg: "180px",
                                  xl: "180px",
                                },
                                height: {
                                  xs: "195px",
                                  sm: "150px",
                                  md: "187px",
                                  lg: "135px",
                                  xl: "135px",
                                },
                                objectFit: "cover",
                              }}
                            />
                            {equipment["equip_list_type"] == "sale" && (
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: "0px",
                                  left: "-8px",
                                  background: "transparent",
                                  transform: "rotate(15deg)",
                                  zIndex: "10",
                                  padding: "5px 15px",
                                  color: "#fff",
                                  fontFamily: "Gotham !important",
                                }}
                              >
                                <Box  component={'img'}
                                  src={ForSale}
                                  alt="For Sale"
                                  sx={{
                                    width: "50px",
                                    height: "auto",
                                    display: "block",
                                  }}
                                />
                              </Box>
                            )}

                            <Box
                              className=""
                              sx={{
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                                width: "30px",
                                height: "auto",
                                display: "block",
                                objectFit: "contain",
                                padding: "5px",
                              }}
                              onClick={() => {
                                LikeDislikeEquip(
                                  equipment["id"],
                                  indx,
                                  equipment["equip_list_type"]
                                );
                              }}
                            >
                              <Box component={'img'}
                                className=""
                                src={ThumbsImg}
                                alt=""
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                  display: "block",
                                  objectFit: "contain",
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          // className="rentyrd-card-item-right"
                          sx={{
                            width:'100%',
                            display: "flex",
                            flexDirection: {
                              xs: "column",
                              sm: "row",
                              md: "row",
                              lg: "row",
                              xl: "row",
                            },
                            alignItems: "center",
                            gap: "1rem",
                            
                          }}
                          onClick={() => {
                            HandleEquipDetail(equipment);
                          }}
                        >
                          <Box 
                            // className="rentyrd-right"
                            sx={{ 
                              width:'100%'
                            }}
                            >
                            <Typography
                              // className="vehicle-model"
                              title={equipment.make}
                              sx={{
                                display: "-webkit-box",
                                overflow: "hidden",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 1,
                                color: "#fff",
                                fontSize: {
                                  xs: "18px",
                                  sm: "19.5px",
                                  md: "21px",
                                  lg: "23px",
                                  xl: "25px",
                                },
                                fontFamily: "GothamBold !important",
                              }}
                            >
                              {equipment.make ? equipment.make : "NA"}
                              {/* {equipment.make ? equipment.make.length > 20
                            ? equipment.make.substring(0, 20) + "..."
                            : equipment.make : "NA"} */}
                            </Typography>
                            <Typography
                              // className="vehicle-type"
                              title={equipment.model}
                              sx={{
                                display: "-webkit-box",
                                overflow: "hidden",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 1,
                                color: "#faa61a",
                                fontSize: {
                                  xs: "14px",
                                  sm: "15px",
                                  md: "17px",
                                  lg: "18.5px",
                                  xl: "20px",
                                },
                              }}
                            >
                              {equipment.model ? equipment.model : "NA"}
                              {/* {equipment.model ? equipment.model.length > 22
                            ? equipment.model.substring(0, 22) + "..."
                            : equipment.model : "NA"} */}
                            </Typography>
                            <Box 
                              // className="rentyrd-address"
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "0.5rem",
                                padding: "0.5rem 0",
                                borderBottom: "2px solid gray",
                              }}
                              >
                              <Typography
                                title={
                                  equipment.city
                                    ? equipment.city + ", " + equipment.state
                                    : equipment.state
                                }
                                
                                sx={{
                                  color: "#fff",
                                  fontSize: {
                                    xs: "12px",
                                    sm: "13px",
                                    md: "13px",
                                    lg: "14px",
                                    xl: "14px",
                                  },

                                  // display: "-webkit-box",
                                  // overflow: "hidden",
                                  // WebkitBoxOrient: "vertical",
                                  // WebkitLineClamp: 2,
                                  // maxWidth: "200px !important",
                                }}
                              >
                                {/* {equipment.city ? equipment.city : "NA"}
                            {equipment.state ? equipment.state : ""} */}
                                {equipment.city
                                  ? equipment.city.length > 20
                                    ? equipment.city.substring(0, 20) + "... "
                                    : equipment.city
                                  : "NA"}

                                {equipment.state
                                  ? equipment.state.length > 20
                                    ? ", " +
                                    equipment.state.substring(0, 20) +
                                    "..."
                                    : ", " + equipment.state
                                  : ""}
                              </Typography>
                              {equipment.owner_pickup_return == "1" ? (
                                <Typography
                                  sx={{
                                    color: "#fff",
                                    fontSize: {
                                      xs: "12px",
                                      sm: "13px",
                                      md: "13px",
                                      lg: "14px",
                                      xl: "14px",
                                    },
                                  }}
                                >Delivery Available</Typography>
                              ) : (
                                <Typography
                                    sx={{
                                      color: "#fff",
                                      fontSize: {
                                        xs: "12px",
                                        sm: "13px",
                                        md: "13px",
                                        lg: "14px",
                                        xl: "14px",
                                      },
                                    }}
                                ></Typography>
                              )}
                            </Box>
                            <Box 
                              // className="rentyrd-rates"
                              // sx={{
                              //   display: "grid",
                              //   gridTemplateColumns: "repeat(3, 1fr)",
                              //   padding: "0.5rem 0",
                              //   gap: "0",
                              // }}
                              >
                              {equipment["equip_list_type"] == "sale" ? (
                                <>
                                  <Box
                                    className=""
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      padding: "0.5rem 0",
                                    }}
                                  >
                                    <Box className="">
                                      <Typography>
                                        <span
                                          style={{
                                            fontSize: "clamp(14px, 3vw, 22px)",
                                            color: "#fff",
                                            fontFamily: "GothamBold !important",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          $
                                          <CurrentFormat
                                            Currency={
                                              equipment.equip_sale_price
                                            }
                                          />
                                        </span>
                                        &nbsp;
                                        <span
                                          className="rentyrd-rates-span"
                                          style={{
                                            fontSize: "clamp(9px, 3vw, 14px)",
                                            color: "#faa61a",
                                          }}
                                        >
                                          {equipment.obo == 1 && <>obo</>}
                                        </span>
                                      </Typography>
                                    </Box>
                                  </Box>
                                </>
                              ) : (
                                <Box 
                                    sx={{
                                      display: "grid",
                                      gridTemplateColumns: "repeat(3, 1fr)",
                                      padding: "0.5rem 0",
                                      gap: "0",
                                    }}
                                >
                                    <Box
                                      // className="rentyrd-rates-item"
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography sx={{
                                        color: "#fff",
                                        fontSize: {
                                          xs: "14px",
                                          sm: "16px",
                                          md: "18px",
                                          lg: "20px",
                                          xl: "22px",
                                        },
                                        fontFamily: "GothamBold !important",
                                      }}>
                                      $
                                      <CurrentFormat
                                        Currency={equipment.price_month}
                                      />
                                        <Typography
                                          component={"span"}
                                          // className="rentyrd-rates-span"
                                          sx={{
                                            color: "#faa61a",
                                            fontFamily: "Gotham !important",
                                            fontSize: {
                                              xs: "10px",
                                              sm: "11px",
                                              md: "12px",
                                              lg: "13px",
                                              xl: "14px",
                                            },
                                          }}
                                        >
                                        /m
                                        </Typography>
                                    </Typography>
                                  </Box>
                                    <Box
                                      // className="rentyrd-rates-item"
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        borderLeft: "2px solid gray",
                                        borderRight: "2px solid gray",
                                      }}
                                    >
                                      <Typography sx={{
                                        color: "#fff",
                                        fontSize: {
                                          xs: "14px",
                                          sm: "16px",
                                          md: "18px",
                                          lg: "20px",
                                          xl: "22px",
                                        },
                                        fontFamily: "GothamBold !important",
                                      }}>
                                      $
                                      <CurrentFormat
                                        Currency={equipment.price_per_week}
                                      />
                                        <Typography
                                          component={"span"}
                                          // className="rentyrd-rates-span"
                                          sx={{
                                            color: "#faa61a",
                                            fontFamily: "Gotham !important",
                                            fontSize: {
                                              xs: "10px",
                                              sm: "11px",
                                              md: "12px",
                                              lg: "13px",
                                              xl: "14px",
                                            },
                                          }}
                                        >
                                        /w
                                        </Typography>
                                    </Typography>
                                  </Box>
                                    <Box
                                      // className="rentyrd-rates-item"
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                    <Typography 
                                        sx={{
                                          color: "#fff",
                                          fontSize: {
                                            xs: "14px",
                                            sm: "16px",
                                            md: "18px",
                                            lg: "20px",
                                            xl: "22px",
                                          },
                                          fontFamily: "GothamBold !important",
                                        }}
                                    >
                                      $
                                      <CurrentFormat
                                        Currency={equipment.price_day}
                                      />
                                        <Typography
                                          component={"span"}
                                          // className="rentyrd-rates-span"
                                          sx={{
                                            color: "#faa61a",
                                            fontFamily: "Gotham !important",
                                            fontSize: {
                                              xs: "10px",
                                              sm: "11px",
                                              md: "12px",
                                              lg: "13px",
                                              xl: "14px",
                                            },
                                          }}
                                        >
                                        /d
                                        </Typography>
                                    </Typography>
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Typography
                        className="card-uid"
                        onClick={() => {
                          HandleEquipDetail(equipment);
                        }}
                      >
                        EID{equipment.id}
                      </Typography>
                    </Box>

                    {/* {EquipResult.length != indx + 1 &&  */}
                    <hr className="gray-line" />
                    {/* } */}
                  </Box>
                ))}
              </>
            ) : (
              <>
                <Typography className="empty-rent">
                  <span>You do not have any saved equipment.</span>
                </Typography>
              </>
            )}
          </Box>

          {TotalResult > 10 && (Page + 1) * 10 < TotalResult && (
            <Box className="pagination-section">
              <Box
                className="btn-load-more"
                sx={{ color: "#faa61a !important" }}
                onClick={HandlePagination}
              >
                {"See More"}
                <img
                  src={gemArrow}
                  alt="GoEquipMe"
                  className="gem-arrow arrow-load-more"
                />
              </Box>
            </Box>
          )}
        </Container>
      </MainLayout>
      {/* </Box> */}
    </>
  );
};
export default ThumbsUp;
