import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import calendarYellowIcon from "../../../assets/images/calendar-icon-yellow.png";
import FilterCalender from "./FilterCalender";
import { setalertMessage, setalertMessagetype } from "redux/redux-slice";
import { useDispatch } from "react-redux";

const formControllabelstyle = {
  ".MuiFormControlLabel-label": {
    color: "#fff",
    fontWeight: "500",
    fontSize: { xs: "13px", sm: "13px", md: "13px", lg: "14px", xl: "14px" },
    fontFamily: "Montserrat !important",
  },
};

const checkboxstyle = {
  "&.Mui-checked": {
    color: "green",
  },
  "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
    color: "#fff !important",
  },
  padding: "5px !important",
};

const EquipmentFilterPopup = ({
  filterModalIsOpen,
  handleFilterModalClose,
  handleFilterSearch,
  requestChecked,
  setRequestChecked,
  activeChecked,
  setActiveChecked,
  pastChecked,
  setPastChecked,
  expiredChecked,
  setExpiredCheck,
  dateRange,
  setDateRange,
}) => {
  const dispatch = useDispatch();
  const [calendarPopup, setCalendarPopup] = useState<boolean>(false);
  const setCalendarPopupClose = () => setCalendarPopup(false);
  const setCalendarPopupOpen = () => setCalendarPopup(true);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    let count = 0;
    if (requestChecked) {
      count++;
    }
    if (activeChecked) {
      count++;
    }
    if (pastChecked) {
      count++;
    }
    if(expiredChecked) {
      count++;
    }
    if (count <= 1 && !event.target.checked) {
      dispatch(setalertMessage("Atleast one Type should be selected!"));
      dispatch(setalertMessagetype("error"));
      return;
    }
    if (type === "request") {
      setRequestChecked(event.target.checked);
    } else if (type === "active") {
      setActiveChecked(event.target.checked);
    } else if (type === "past") {
      setPastChecked(event.target.checked);
    } else if( type === "expire") {
      setExpiredCheck(event.target.checked)
    }
  };

  const handleClearFilter = () => {
    setDateRange([]);
    setRequestChecked(true);
    setActiveChecked(true);
    setPastChecked(true);
    setExpiredCheck(false);
  };

  const handleFilterApply = () => {
    handleFilterModalClose()
    const filterParams = {};
    const rentalType = [];
    requestChecked && rentalType.push("RENTAL REQUESTS");
    activeChecked && rentalType.push("ACTIVE RENTALS");
    pastChecked && rentalType.push("PAST RENTALS");
    expiredChecked && rentalType.push("EXPIRED RENTAL");
    if (dateRange[0]) filterParams["startDate"] = dateRange[0];
    if (dateRange[1]) filterParams["endDate"] = dateRange[1];
    filterParams["rentalType"] = rentalType.join(",");
    handleFilterSearch(filterParams);
  };

  return (
    <Modal
      open={filterModalIsOpen}
      onClose={handleFilterModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: {
            xs: "80%",
            sm: "400px",
            md: "500px",
            lg: "500px",
            xl: "500px",
          },
          bgcolor: "#2e2e2e",
          border: "5px solid #faa61a",
          borderRadius: "10px",
          outline: "none !important",
          p: 3,
        }}
      >
        <IconButton
          onClick={handleFilterModalClose}
          sx={{
            position: "absolute",
            top: "-20px",
            right: "-20px",
            bgcolor: "#faa61a",
            color: "#000",
            "&:hover": {
              bgcolor: "#faa61a",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          sx={{
            color: "#fff",
            fontSize: {
              xs: "18px",
              sm: "24px",
              md: "26px",
              lg: "28px",
              xl: "32px",
            },
            fontWeight: "700",
            fontFamily: "Montserrat !important",
          }}
        >
          Filter By:
        </Typography>

        {/* Order Status */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
          <Typography
            sx={{
              mt: 2,
              color: "#faa61a",
              fontWeight: "600",
              fontFamily: "Montserrat !important",
            }}
          >
            Order Status
          </Typography>
          <FormGroup sx={{ padding: "5px" }}>
            <FormControlLabel
              sx={formControllabelstyle}
              control={
                <Checkbox
                  checked={requestChecked}
                  onChange={(event) => handleChange(event, "request")}
                  sx={checkboxstyle}
                />
              }
              label="Rental Requests"
            />
            <FormControlLabel
              sx={formControllabelstyle}
              control={
                <Checkbox
                  checked={activeChecked}
                  onChange={(event) => handleChange(event, "active")}
                  sx={checkboxstyle}
                />
              }
              label="Active Rentals"
            />
            <FormControlLabel
              sx={formControllabelstyle}
              control={
                <Checkbox
                  checked={pastChecked}
                  onChange={(event) => handleChange(event, "past")}
                  sx={checkboxstyle}
                />
              }
              label="Past Rentals"
            />
            <FormControlLabel
              sx={formControllabelstyle}
              control={
                <Checkbox
                  checked={expiredChecked}
                  onChange={(event) => handleChange(event, "expire")}
                  sx={checkboxstyle}
                />
              }
              label="Expired Rentals"
            />
          </FormGroup>
        </Box>

        {/* Order Date */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
          <Typography
            sx={{
              mt: 2,
              color: "#faa61a",
              fontWeight: "600",
              fontFamily: "Montserrat !important",
            }}
          >
            Order Date
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: "5px",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Montserrat !important",
                fontWeight: "600",
                color: "#fff",
                fontSize: {
                  xs: "13px",
                  sm: "13px",
                  md: "14px",
                  lg: "15px",
                  xl: "16px",
                },
              }}
            >
              Date Range
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                width: "100%",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                  xl: "row",
                },
                mb: 2,
              }}
            >
              <Box
                sx={{
                  border: "1px solid #faa61a",
                  background: "#fff",
                  borderRadius: "7px",
                  padding: "5px",
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "45%",
                    lg: "45%",
                    xl: "45%",
                  },
                  boxSizing: "border-box",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  paddingLeft: "0.5rem",
                  paddingRight: "0.5rem",
                  cursor: "pointer",
                }}
                onClick={setCalendarPopupOpen}
              >
                <Box
                  component={"img"}
                  src={calendarYellowIcon}
                  alt=""
                  sx={{ width: "20px", marginTop: "-3px" }}
                />
                <Typography
                  sx={{
                    fontFamily: "Montserrat !important",
                    fontWeight: "500",
                    fontSize: {
                      xs: "13pxs",
                      sm: "13px",
                      md: "14px",
                      lg: "15px",
                      xl: "16px",
                    },
                  }}
                >
                  {dateRange.length > 0 ? dateRange[0] : "Select Date"}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "10%",
                    lg: "10%",
                    xl: "10%",
                  },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: {
                    xs: "flex-start",
                    sm: "flex-start",
                    md: "center",
                    lg: "center",
                    xl: "center",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat !important",
                    fontWeight: "600",
                    color: "#fff",
                    fontSize: {
                      xs: "13px",
                      sm: "13px",
                      md: "14px",
                      lg: "15px",
                      xl: "16px",
                    },
                  }}
                >
                  to
                </Typography>
              </Box>
              <Box
                sx={{
                  cursor: "pointer",
                  border: "1px solid #faa61a",
                  background: "#fff",
                  borderRadius: "7px",
                  padding: "5px",
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "45%",
                    lg: "45%",
                    xl: "45%",
                  },
                  boxSizing: "border-box",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  paddingLeft: "0.5rem",
                  paddingRight: "0.5rem",
                }}
                onClick={setCalendarPopupOpen}
              >
                <Box
                  component={"img"}
                  src={calendarYellowIcon}
                  alt=""
                  sx={{
                    width: "20px",
                    marginTop: "-3px",
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: "Montserrat !important",
                    fontWeight: "500",
                    fontSize: {
                      xs: "13pxs",
                      sm: "13px",
                      md: "14px",
                      lg: "15px",
                      xl: "16px",
                    },
                  }}
                >
                  {dateRange.length > 1 ? dateRange[1] : "Select Date"}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Button
              variant="contained"
              sx={{
                background: "#008001",
                borderRadius: "8px",
                textTransform: "capitalize",
                fontFamily: "Montserrat !important",
                fontWeight: "600",
                padding: "0px 15px",
                fontSize: {
                  xs: "14px",
                  sm: "16px",
                  md: "18px",
                  lg: "20px",
                  xl: "20px",
                },
                "&:hover": {
                  background: "#006401",
                },
              }}
              onClick={handleFilterApply}
            >
              Search
            </Button>

            <Typography
              sx={{
                color: "#faa61a",
                textDecorationColor: "#faa61a",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={handleClearFilter}
            >
              Reset
            </Typography>
          </Box>
        </Box>

        <FilterCalender
          setCalendarPopupClose={setCalendarPopupClose}
          calendarPopup={calendarPopup}
          setDateRange={setDateRange}
          defaultDateRange={dateRange}
        />
      </Box>
    </Modal>
  );
};

export default EquipmentFilterPopup;
