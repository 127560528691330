import { Backdrop, Box, Button, CircularProgress, IconButton, Link, Modal, TextField, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { EquipmentAPIs } from "../../../service/api-service";
import { IApiResponseRawData, IStoreValue } from 'utils/types';
import { setModalOpen, setLoginStatus, setaccessToken, setSessionExpire, setalertMessagetype, setalertMessage } from 'redux/redux-slice';
// import debounce from 'lodash.debounce';
import { debounce } from 'lodash';

import CloseIcon from '@mui/icons-material/Close'

import tickIcon from '../../../assets/images/check.png'

const AddReferralModal = ({ open, handleClose, fetchCrewListing }) => {

    const dispatch = useDispatch();
    const matchesMobileX = useMediaQuery('(max-width:425px)');
    const AuthToken = useSelector((state: IStoreValue) => state.equipmentReducer.accessToken);
    const UserID = useSelector((state: IStoreValue) => state.equipmentReducer.Authuser);
    const [loading, setLoading] = useState(false);
    const [referralCode, setReferralCode] = useState('');
    const [referrer, setReferrer] = useState('');
    const [isReferralValid, setIsReferralValid] = useState(false);


    const addReferralApi = async () => {
        try {
            // if (referralCode.trim() == '' || (!isReferralValid)) {
            if (referralCode.trim() == '') {
                dispatch(setalertMessagetype("error"));
                dispatch(setalertMessage("Please type a valid referral code."));
                return;
            };
            // return;
            setLoading(true);
            // Call the API to get the service categories
            const data = {
                user_id: UserID.toString(),
                ref_code: referralCode
            };

            // console.log("data in add referral api: ", data);
            // return;
            const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
            const addRef = await EquipmentAPIs.addReferralCode(data, headers);
            console.log("addRef api: ", addRef);
            if (addRef["type"] == "RXSUCCESS") {
                // console.log("addRef : ", addRef);
                dispatch(setalertMessagetype("success"));
                dispatch(setalertMessage("Referral added successfully."));
                setLoading(false);
                setReferralCode('');
                handleClose();
                fetchCrewListing();
            } else {
                // Display an error message if deletion fails
                dispatch(setalertMessagetype("error"));
                // dispatch(setalertMessage("error while adding referral."));
                dispatch(setalertMessage(addRef["message"]));
                setLoading(false);
            }
        } catch (err) {
            // Handle errors that occur during the deletion process
            console.log(err);
            setLoading(false);
            handleClose();
        }
    };

    const referralValidateApi = async (refCode = referralCode) => {
        try {
            console.log("ref code in valid api: ", refCode);

            if (refCode.trim() == '') {
                // dispatch(setalertMessagetype("error"));
                // dispatch(setalertMessage("Please type a valid referral code."));
                setIsReferralValid(false);
                setReferrer('');
                return;
            };

            setLoading(true);
            // Call the API to get the service categories
            const data = {
                user_id: UserID.toString(),
                ref_code: refCode,
                // ref_code: referralCode
            };

            console.log("data in validate referral api: ", data);
            // return;
            const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
            const validateRef = await EquipmentAPIs.crewReferralValidate(data, headers);
            console.log("validateRef api: ", validateRef);
            if (validateRef["type"] == "RXSUCCESS") {
                // console.log("validateRef : ", validateRef);
                // dispatch(setalertMessagetype("success"));
                // dispatch(setalertMessage("Referral validate successfully."));
                setLoading(false);
                setIsReferralValid(true);
                if (validateRef['data']) setReferrer(validateRef['data']);
                // setReferralCode('');
                // handleClose();
                // fetchCrewListing();
            } else {
                // Display an error message if deletion fails
                // dispatch(setalertMessagetype("error"));
                // dispatch(setalertMessage("error while validating referral."));
                setLoading(false);
                setIsReferralValid(false);
                setReferrer('');
            }
        } catch (err) {
            // Handle errors that occur during the deletion process
            console.log(err);
            setLoading(false);
            handleClose();
        }
    };

    const debouncedSearch = debounce(referralValidateApi, 800);

    const handleSearch = debounce((searchString: string) => {
        setReferralCode(searchString);
    }, 800);

    useEffect(() => {
        // if (referralCode) {
        // debouncedSearch(referralCode);
        referralValidateApi(referralCode);
        // }
    }, [referralCode]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                outline: 'none',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: '#2d2d2d',
                borderRadius: '10px',
                border: '5px solid #faa61a',
                boxShadow: 24,
                p: {
                    xs: '1rem',
                    sm: '1rem',
                    md: '1.5rem',
                    lg: '2rem',
                    xl: '2rem',
                },
                width: {
                    xs: '80vw',
                    sm: '80vw',
                    md: '350px',
                    lg: '350px',
                    xl: '350px',
                },
                maxWidth: '90vw',
            }}>
                <IconButton className="cross-btn" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                {loading && (
                    <Box>
                        <Backdrop
                            sx={{
                                color: "#000",
                                zIndex: (theme) => theme.zIndex.drawer - 1,
                            }}
                            open={loading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </Box>
                )}
                <Typography id="modal-modal-title" variant="h4" sx={{ fontSize: 'clamp(24px, 3vw, 32px) !important', textAlign: 'center', color: '#fff !important', mb: 1 }}>Add Referral Code</Typography>

                <Box sx={{ margin: '1rem 0 1rem 0', }}>
                    <TextField id="refereeal-code"
                        fullWidth

                        label=""
                        variant="outlined"
                        placeholder='Enter here'
                        InputLabelProps={{
                            shrink: false,
                        }}
                        inputProps={{
                            maxLength: 12,
                        }}
                        // value={referralCode}
                        onChange={(e) => {
                            // setReferralCode(e.target.value.replace(/[^0-9]/g, ''));
                            handleSearch(e.target.value.replace(/[^0-9]/g, ''));
                            // referralValidateApi(e.target.value.replace(/[^0-9]/g, ''));
                            // debouncedSearch(e.target.value.replace(/[^0-9]/g, ''));

                        }}
                        sx={{

                            '& .MuiInputBase-input .MuiOutlinedInput-input': {
                                textAlign: 'center',
                            },
                            '& .MuiInputBase-formControl': {
                                textAlign: 'center',
                                border: '2px solid #faa61a',
                                backgroundColor: '#fff',
                                color: '#000',
                                fontFamily: 'GothamBold !important',
                                '&:focus': {
                                    border: 'none',
                                    outline: 'none',
                                }
                            },
                            '& .MuiInputBase-formControl.Mui-focused': {
                                outline: 'none',
                            },
                            '& MuiInputBase-input': {
                                border: '2px solid #faa61a',
                                fontFamily: 'GothamBold !important',
                                textAlign: 'center',
                            }
                        }}
                    />
                </Box>
                <Box>
                </Box>

                <Box>
                    {referrer &&
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', marginBottom: ' 1rem' }}>
                            <img src={tickIcon} alt="" style={{ width: '20px', height: 'auto', display: 'block', }} />
                            <Typography sx={{ fontSize: { xs: '14px', sm: '14px', md: '16px', lg: '20px', }, color: '#faa61a !important' }}>Referrer:</Typography>
                            <Typography sx={{ fontSize: { xs: '14px', sm: '14px', md: '16px', lg: '20px', }, color: '#fff !important', textTransform: 'capitalize' }}>{referrer}</Typography>
                        </Box>
                    }
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem', width: { xs: '100%', sm: '100%', md: '70%', lg: '70%', xl: '70%', margin: 'auto' } }}>
                    <Link sx={{ cursor: 'pointer', fontFamily: 'Gotham !important', fontSize: '20px !important', textDecorationLine: 'underline !important', textDecorationColor: '#faa61a !important', color: '#faa61a !important', textTransform: 'capitalize' }}
                        onClick={handleClose}
                    >Cancel</Link>
                    <Button sx={{ fontSize: '20px !important', height: '40px', padding: '0.5rem 1rem', width: 'fit-content', background: '#149247 !important', color: '#fff', textTransform: 'capitalize', fontFamily: 'GothamBold !important' }}
                        onClick={() => {
                            console.log("Add Referral working");
                            addReferralApi();
                        }}
                    >
                        Submit
                    </Button>
                </Box>
                <Button onClick={handleClose} sx={{ fontFamily: 'Montserrat !important', color: '#fff', fontSize: '14px', position: 'absolute', top: '-15px', right: '-15px', width: '30px !important', height: '30px !important', minWidth: '30px !important', borderRadius: ' 50%', border: '2px solid #faa61a', background: '#2d2d2d !important', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>X</Button>
            </Box>
        </Modal>
    );
};

export default AddReferralModal;
