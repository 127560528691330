import React from 'react'
import MainLayout from '../../layouts/MainLayout'
import { Box, Grid, Typography } from '@mui/material';
import LearnMore_01 from '../../assets/images/LearnMore_01.png';
import LearnMore_02 from '../../assets/images/LearnMore_02.png';
import LearnMore_03 from '../../assets/images/LearnMore_03.png';
import LearnMore_04 from '../../assets/images/LearnMore_04.png';
import greenshield from '../../assets/images/learnmore_greenshield.png';
import './styles.scss'

import ServiceItem from '../../components/service-section/ServiceItem';
/**
 * Main About page.
 */
const About: React.FC = () => {

  return (
    <MainLayout insideMainContainer={false}>

      <Box className='service-sections' >
        <Box className='service-section__row main-header'>
          <h2>
            A Rental Yard that brings equipment owners and renters together... all in your back pocket.</h2>
        </Box>

        <Box className='service-section__row renter_img' >
          <img src={LearnMore_01} className="img-fluid" alt="Responsive image" style={{ width:"100%" }}/>
        </Box>
        <Box className='service-section__row renter_img' >
          <div className="row" >
            <div className="col-12 owners">
              <img src={LearnMore_02} className="img-fluid" alt="Responsive image" style={{ width:"100%" }} />
              <h3>You didn’t buy all that equipment just to sit in your yard! Create your own rental yard with GoEquipMe’s easy to use platform and earn cash.</h3>
              <ul>
                <li>Make equipment available when you don’t need it.</li>
                <li>Communicate directly with your renter.</li>
                <li>Select delivery or pickup parameters.</li>
                <li>Set your rental price.</li>
                <li>List for free!</li>
              </ul>
              <h3>Put your equipment to work!</h3>
            </div>
          </div>

        </Box>
        <Box className='service-section__row renter_img'>
          <div className="row" >
            <div className="col-12 owners" >
              <img src={LearnMore_03} className="img-fluid" alt="Responsive image" style={{ width:"100%" }}/>
              <h3>As a renter, find and secure equipment when and where you need it.</h3>
              <ul>
                <li>Simple interface and booking process.</li>
                <li>Benefit from true market prices set by equipment owners.</li>
                <li>Select equipment transport options.</li>
                <li>Communicate directly with the equipment owner.</li>
                <li>Cancel for free up to 24 hours.</li>
              </ul>
              <h3>Save time and money!</h3>
            </div>
          </div>

        </Box>
        <Box className='service-section__row renter_img' >
          <div className="row" >
            <div className="col-12" >
              <img src={LearnMore_04} className="img-fluid" alt="Responsive image" style={{ width:"100%" }}/>
            </div>
          </div>

        </Box>
        <Box className='service-section__row renter_img' >
          <div className="row">
            <div className="col-12 find" >
              <h2>Find equipment when and where you need it in easy to access categories:</h2>
            </div>
          </div>
        </Box>
        <Box className='service-section__row renter_img' >
          <div className="row find-section" >
            <div className="col-md-4 col-sm-3 ">
              <ul>
                <li>Aerial, Lifts & Ladders</li>
                <li>Aggregate Equipment</li>
                <li>Backhoes</li>
                <li>Compactors</li>
                <li>Concrete Equipment</li>
                <li>Dozers</li>
              </ul>
            </div>
            <div className="col-md-4 col-sm-3">
              <ul>
                <li>Excavators</li>
                <li>Graders</li>
                <li>Lawn Landscape & Tree</li>
                <li>Lighting</li>
                <li>Loaders</li>
                <li>Painting</li>
              </ul>
            </div>
            <div className="col-md-4 col-sm-3">
              <ul>
                <li>Site Services</li>
                <li>Skid Steers</li>
                <li>Trailers</li>
                <li>Trucks</li>
                <li>And more</li>
              </ul>
            </div>
          </div>

        </Box>
        <Box className='service-section__row renter_img' >

        <Box className='service-section renter_img' style={{ marginBottom:"30px"}}>
          <Box className='testimonial power-protection'>

            <Grid container spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid item xs={2} md={2} lg={2} xl={2} sx={{ textAlign: 'center' }} className="testimonial-img-div">
                <img src={greenshield} className="img-fluid" alt="Responsive image"/>
              </Grid>
              <Grid item xs={10} md={10} lg={10} xl={10} className="testimonial-title-div">
                <Typography variant='h4' className='testimonial-title' style={{ borderBottom: "none"}}>
                  THE POWER OF PROTECTION
                </Typography>
                <Typography className='testimonial-description' style={{ hyphens:"auto"}}>
                  GoEquipMe provides $50,000 additional protection coverage included in every transaction. See contract for coverage details.
                </Typography>
              </Grid>
            </Grid>
            
          </Box>
        </Box>


          {/* <div className="row footer-line">
            <div className="col-md-3" >
              <div className="tickimg"><img src={greenshield} className="img-fluid" alt="Responsive image"  /></div>
            </div>
            <div className="col-md-9 power">
              <h3 >THE POWER OF PROTECTION</h3>
              <p>GoEquipMe provides $50,000 additional protection coverage included in every transaction. See contract for coverage details.</p>
            </div>
          </div> */}

        </Box>



        
   
      </Box>
       
    </MainLayout>

  )
}

export default About