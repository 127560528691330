// firebase.js
// import * as firebase from 'firebase/app';
// import firebase from 'firebase/app';
// import analytics from 'firebase/analytics';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
const platform = process.env.REACT_APP_PLATFORM;

const firebaseConfig = {
  // apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  // authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  // projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDERID,
  // appId: process.env.REACT_APP_FIREBASE_APPID,
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  messagingSenderId: process.env.REACT_APP_SENDERID,
  appId: process.env.REACT_APP_APPID,
  //   apiKey: 'YOUR_API_KEY',
  //   authDomain: 'YOUR_AUTH_DOMAIN',
  //   projectId: 'YOUR_PROJECT_ID',
  //   storageBucket: 'YOUR_STORAGE_BUCKET',
  //   messagingSenderId: 'YOUR_MESSAGING_SENDER_ID',
  //   appId: 'YOUR_APP_ID',
  //   measurementId: 'YOUR_MEASUREMENT_ID',
};

// let app = firebase.initializeApp(firebaseConfig);
let app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const fireAnalyticsEvent = (eventName) => {
  if (platform == "Live") {
    console.log("event Fired: ", eventName);
    logEvent(analytics, eventName);
  }
};

// export default analytics;
export default fireAnalyticsEvent;

// logEvent(analytics, 'Equipment_near_me');

// logEvent(analytics, 'select_content', {
//     content_type: 'image',
//     content_id: 'P12453',
//     items: [{ name: 'Kittens' }]
//   });
// Equipment_near_me
// firebase.analytics();
// const firebaseAnalytics = analytics.initializeAnalytics(app);

// firebaseAnalytics.logEvent('button_click', {
//     button_name: 'your_button_name',
//   });

// export default firebaseAnalytics;

// import firebase from 'firebase/app';
// import 'firebase/analytics';

// const firebaseConfig = {
//   apiKey: 'YOUR_API_KEY',
//   authDomain: 'YOUR_AUTH_DOMAIN',
//   projectId: 'YOUR_PROJECT_ID',
//   storageBucket: 'YOUR_STORAGE_BUCKET',
//   messagingSenderId: 'YOUR_MESSAGING_SENDER_ID',
//   appId: 'YOUR_APP_ID',
//   measurementId: 'YOUR_MEASUREMENT_ID',
// };

// // Initialize Firebase
// const app = firebase.initializeApp(firebaseConfig);
// const firebaseAnalytics = firebase.analytics(app);

// // Use the initialized analytics instance
// firebaseAnalytics.logEvent('button_click', {
//   button_name: 'your_button_name',
// });

// export default firebaseAnalytics;
