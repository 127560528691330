import React, {useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, useMediaQuery, Pagination ,Typography} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from 'redux/redux-slice';
import { IStoreValue } from 'utils/types';
import stripesLong from 'assets/images/stripes_long.png'
import EquipmentItem from 'components/equipment-item/EquipmentItem';
import './styles.scss'

interface IProps {
  next : number
}

const EquipmentsView = ({next}: IProps) => {
  const equipments = useSelector((state: IStoreValue) => state.equipmentReducer.equipments)
  const pagecount = useSelector((state: IStoreValue) => state.equipmentReducer.count)
  const isLoadingEquipments = useSelector((state: IStoreValue) => state.equipmentReducer.isLoadingEquipments)
  const matchesDesktop = useMediaQuery('(min-width:769px)')
  // const matchesMobile = useMediaQuery('(max-width:768px)')
  const matchesMobileX = useMediaQuery('(max-width:425px)')

  const dispatch = useDispatch();
 // const [page, setPage] = React.useState(1);

  const handleChange = (event, value) => {   
    dispatch(setCurrentPage(value))
   // setPage(value);
  };


  return (

    <Box className='equipments-wrapper'>
      {/* <img
        src={stripesLong}
        alt='Stripes Long'
        className='img-stripes-long'
      /> */}
      
      <Box className='grid-container'>
        {/* {isLoadingEquipments && (
          <CircularProgress />
        )} */}
        {!isLoadingEquipments && equipments &&  (
          <Grid container spacing={4} className="equipment-grid" sx={{ marginTop: '5px', marginBottom: '20px' }}>
             {/* { equipments?.slice(0, next)?.map(equipment => ( */}
            { equipments.map(equipment => (
              <Grid item xs={matchesDesktop ? 4 : matchesMobileX ? 12 : 12} sm={6} md={6} lg={4} key={equipment.id} sx={{ display: 'flex', justifyContent: 'center' }} className="equipment-grid-item"
              >
              
                <EquipmentItem item={equipment} type={'equipDetail'} />
              </Grid>
            ))}
           
            {equipments.length == 0&&
              <Box className='equipments-wrapper no-data' sx={{ textAlign: 'center', marginLeft: '35px !important' }}>
                No equipment found.
              </Box>
            }
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default EquipmentsView;