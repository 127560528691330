import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
  Link,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import "./styles.scss";

import locationIcon from "assets/images/location.png";
import msgIcon from "assets/images/details-page/msgg.png";
import chatIcon from "assets/images/location.png";
import dummyProfile from "assets/images/dummy-profile-pic.jpeg";
import leftArrow from "assets/images/myofficetrailer/left_yellow.png";
import rightArrow from "assets/images/myofficetrailer/right_yellow.png";
import Autorenew from "@mui/icons-material/Autorenew";
import btnLeftArrow from "assets/images/gem-arrow.png";
import btnRightArrow from "assets/images/gem-arrow.png";
import phnIcon from "assets/images/serviceDirectory/Scall.png";
import photoIcon from "assets/images/serviceDirectory/Sphoto.png";
import webIcon from "assets/images/serviceDirectory/global.png";
import locIcon from "assets/images/serviceDirectory/newPin.png";
import locpin from "assets/images/serviceDirectory/locpin.png";
import textIcon from "assets/images/serviceDirectory/text_icon.png";
import userIcon from "assets/images/serviceDirectory/user_icon.png";

import MainLayout from "layouts/MainLayout";
import { EquipmentAPIs } from "../../service/api-service";
import { useDispatch, useSelector } from "react-redux";
import {
  setModalOpen,
  setLoginStatus,
  setaccessToken,
  setSessionExpire,
  setalertMessagetype,
  setalertMessage,
} from "redux/redux-slice";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import { useEffect, useState } from "react";

// popups/Models
import DeleteServiceModal from "./DeleteServiceModal";
import EditServiceDescriptionModal from "./EditServiceDescriptionModal";
import EditServiceLocationModal from "./editServiceLocationModal";
import EditServicePhotosModal from "./EditServicePhotosModal";
import MakeServiceLiveModal from "./MakeServiceLiveModal";

const NewCssTextField = styled(TextField)({
  "& .MuiInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },

  "& .MuiInputLabel-shrink.MuiInputLabel-filled": {
    color: "#2d2d2d",
  },
  "& .MuiInputBase-root": {
    color: "#2d2d2d",
    background: "#fff",
    fontFamily: "Gotham !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "&. MuiInputLabel-shrink": {
    color: "red !important",
  },

  "&. MuiInputLabel-shrink.Mui-focused": {
    color: "red !important",
  },

  "& label.Mui-focused": {
    color: "#2d2d2d !important",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important",
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
  "& .MuiInputBase-root-MuiFilledInput-root:before": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
  },
});

const MyServiceDirectory = () => {
  // const EditRef = useRef(null);

  const dispatch = useDispatch();
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const AuthUserID = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const AuthProfileData = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );

  const [loading, setLoading] = useState(false);
  // State used for serviceListing
  const [myServiceList, setMyServiceList] = useState([]);
  const [FullAddress, setFullAddress] = useState({
    address: "",
    city: "",
    state: "",
    short_state: "",
    postal_code: "",
    country: "",
    lat: "",
    lng: "",
  });
  // end filter value states
  // for paginations
  const [page, setPage] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  const [PhotosModelOpen, setPhotosModelOpen] = useState(false);
  const [textModelOpen, setTextModelOpen] = useState(false);

  const [sendTextMobileNo, setSendTextMobileNo] = useState("");
  const [photosArray, setPhotosArray] = useState([]);

  const [deleteServiceId, setDeleteServiceId] = useState("");
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);

  const [serviceDescModalOpen, setServiceDescModalOpen] = useState(false);
  const [serviceData, setServiceData] = useState<any>({});
  const [serviceCategories, setServiceCategories] = useState([]);

  const [serviceLocationModalOpen, setServiceLocationModalOpen] =
    useState(false);

  const [servicePhotosModalOpen, setServicePhotosModalOpen] = useState(false);
  const [images, setImages] = useState([]);

  const [serviceLiveModalOpen, setServiceLiveModalOpen] = useState(false);
  // for description expand
  const [expandedItems, setExpandedItems] = useState([]);

  // const handleSeeMoreClick = (itemId) => {
  //     setExpandedItems((prevExpandedItems) => {
  //         if (prevExpandedItems.includes(itemId)) {
  //             return prevExpandedItems.filter((id) => id !== itemId);
  //         } else {
  //             return [...prevExpandedItems, itemId];
  //         }
  //     });
  // };

  // const handleSeeMoreClick = (itemId) => {
  //     setExpandedItems((prevExpandedItems) => {
  //         if (prevExpandedItems.includes(itemId)) {
  //             return prevExpandedItems.filter((id) => id !== itemId);
  //         } else {
  //             return [...prevExpandedItems, itemId];
  //         }
  //     });
  // };

  const handleSeeMoreClick = (itemId) => {
    setExpandedItems((prevExpandedItems) => {
      if (prevExpandedItems.includes(itemId)) {
        return prevExpandedItems.filter((id) => id !== itemId);
      } else {
        return [...prevExpandedItems, itemId];
      }
    });
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  // to fetch service list from api
  const myServices = async () => {
    try {
      setLoading(true);
      let data = {
        user_id: AuthUserID,
      };
      // Call the API to delete the equipment
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const myServices = await EquipmentAPIs.myServices(data, headers);
      console.log("myServices: ", myServices);

      // Check if the deletion was successful
      if (myServices["type"] == "RXSUCCESS") {
        console.log("in my services success: , ", myServices);
        setMyServiceList(
          myServices["data"].filter((data) => data.is_deleted != 1)
        );
        setLoading(false);
      } else {
        // Display an error message if deletion fails
        dispatch(setalertMessage("error while fetching myService."));
        dispatch(setalertMessagetype("error"));
        setLoading(false);
        // setDeletePopup(false)
      }
    } catch (err) {
      // Handle errors that occur during the deletion process
      console.log(err);
      setLoading(false);
    }
  };

  // to fetch serviceCategories
  const fetchServiceCategories = async () => {
    try {
      // Call the API to get the service categories
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const serviceCategories = await EquipmentAPIs.fetchServiceCategories();

      if (serviceCategories["type"] == "RXSUCCESS") {
        setServiceCategories(
          serviceCategories["data"]
            .filter((data) => data.status == 1)
            .sort((a, b) => a.title.localeCompare(b.title))
        );
      } else {
        // Display an error message if deletion fails
        dispatch(setalertMessagetype("error"));
        dispatch(setalertMessage("error while fetching service Categories."));
      }
    } catch (err) {
      // Handle errors that occur during the deletion process
      console.log(err);
    }
  };

  // fxn to add services
  const updateService = async (serviceLive = "2") => {
    try {
      console.log("updating service data: ", serviceData.service_live_status);
      console.log("updating serviceLive: ", serviceLive);
      // setLoading(false);
      // return;
      if (!serviceData.company_name.trim()) {
        dispatch(setalertMessagetype("error"));
        dispatch(setalertMessage("Please add a Company name."));
        return false;
      }

      if (!serviceData.service_category) {
        dispatch(setalertMessagetype("error"));
        dispatch(setalertMessage("Please select a Service Category."));
        return false;
      }

      if (serviceData.email && serviceData.email != "") {
        let emailRegex =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isEmailValid = emailRegex.test(serviceData.email);
        if (!isEmailValid) {
          dispatch(setalertMessagetype("error"));
          dispatch(setalertMessage("Please enter a valid mail."));
          return false;
        }
      }

      if (serviceData.phone_voice && serviceData.phone_voice !== "") {
        if (serviceData.phone_voice.length < 10) {
          dispatch(setalertMessagetype("error"));
          dispatch(setalertMessage("Please enter a valid phone/voice."));
          return false;
        }
      }

      if (serviceData.phone_text && serviceData.phone_text !== "") {
        if (serviceData.phone_text.length < 10) {
          dispatch(setalertMessagetype("error"));
          dispatch(setalertMessage("Please enter a valid phone/text."));
          return false;
        }
      }

      setLoading(true);
      const data = {
        company_name: serviceData.company_name,
        service_category: serviceData.service_category,
        key_description_search: serviceData.key_description_search,
        address: serviceData.address,
        city: serviceData.city,
        state: serviceData.state,
        short_state_name: serviceData.short_state_name,
        country: serviceData.country,
        latitude: serviceData.latitude,
        longitude: serviceData.longitude,
        phone_voice: serviceData.phone_voice,
        phone_text: serviceData.phone_text,
        email: serviceData.email,
        website: serviceData.website,
        service_description: serviceData.service_description,
        user_id: AuthUserID,
        service_id: serviceData.id,
        location_status: serviceData.location_status,
        service_live_status:
          serviceLive == "2" ? serviceData.service_live_status : serviceLive,
      };

      // let params = JSON.stringify({
      //     company_name: this.state.company_name,
      //     service_category: this.state.category,
      //     key_description_search: this.state.search_term,
      //     address: this.state.address,
      //     city: this.state.city,
      //     state: this.state.state,
      //     short_state_name: this.state.short_state_name,
      //     country: this.state.country,
      //     latitude: this.state.latitude,
      //     longitude: this.state.longitude,
      //     phone_voice: this.state.phone_voice,
      //     phone_text: this.state.phone_txt,
      //     email: this.state.email,
      //     website: this.state.website,
      //     service_description: this.state.service_description,
      //     user_id: this.state.user_id,
      //     service_id: this.state.service_id,
      //     location_status: this.state.hideLocation == false ? 0 : 1,
      //   });

      console.log("data in update Service:", data);

      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const updateService = await EquipmentAPIs.updateServiceDetails(
        data,
        headers
      );
      console.log("updateService: ", updateService);
      if (updateService["type"] == "RXSUCCESS") {
        console.log("service updated successfully");
        dispatch(setalertMessagetype("success"));
        dispatch(setalertMessage("Service updated successfully."));
        // setLoading(false);
        // setTimeout(() => {
        //     navigate("/service-directory");
        // }, 1000);
        setServiceDescModalOpen(false);
        setServiceLocationModalOpen(false);
        setServiceLiveModalOpen(false);
        myServices();
      } else {
        // Display an error message if deletion fails
        dispatch(setalertMessagetype("error"));
        dispatch(setalertMessage("error while adding service."));
        setLoading(false);
      }
    } catch (error) {
      console.log("error in updateService fxn: ", error.message);
      dispatch(setalertMessagetype("error"));
      dispatch(setalertMessage("error while updating service."));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      AuthProfileData &&
      AuthProfileData != undefined &&
      AuthProfileData != null &&
      AuthToken
    ) {
      myServices();
      fetchServiceCategories();
      // console.log("auth profileData: " , AuthProfileData);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [AuthProfileData]);

  return (
    <>
      {/* <MainLayout> */}
      {loading && (
        <Box>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}
      <Box className="service-directory">
        {/* <Container> */}
        <Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {/* <img src={leftArrow} alt="" /> */}
            <Typography
              sx={{
                fontSize: {
                  xs: "20px",
                  sm: "22px",
                  md: "28px",
                  lg: "34px",
                  xl: "40px",
                },
                color: "#fff",
                fontFamily: "GothamBold !important",
                textAlign: "center",
              }}
            >
              My Services
            </Typography>
            {/* <img src={rightArrow} alt="" /> */}
          </Box>

          <Box className="sd-cards-main">
            {myServiceList.map((item) => (
              <Box
                key={item.id}
                className="sd-cards"
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "1rem",
                }}
              >
                <Box>
                  {item.profile_pic ? (
                    <img
                      src={item.profile_pic}
                      alt=""
                      className="sd-card-profile"
                    />
                  ) : (
                    <img
                      src={dummyProfile}
                      alt=""
                      className="sd-card-profile"
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    sx={{
                      backgroundColor: "#faa61a",
                      width: "fit-content",
                      padding: "2px 5px",
                      borderRadius: "20px",
                      minWidth: "50px",
                      fontSize: "10.5px",
                      fontFamily: "GothamBold !important",
                      textTransform: "uppercase",
                      color: "#000 !important",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {item.service_category}
                  </Typography>

                  <Typography
                    sx={{
                      width: "fit-content",
                      padding: "5px 2px",
                      borderRadius: "20px",
                      fontSize: "16px",
                      fontFamily: "GothamBold !important",
                      textTransform: "capitalize",
                      color: "#fff !important",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {item.company_name}
                  </Typography>

                  <Box className="sd-card-grid">
                    <Box className="sd-name-div">
                      <img src={userIcon} alt="" className="sd-name-icon" />
                      <Typography>{item.first_name}</Typography>
                    </Box>
                    <Box className="sd-location-div">
                      <img
                        src={locationIcon}
                        alt=""
                        className="sd-location-icon"
                      />
                      <Typography>
                        {/* Sahibzada Ajit Singh Nagar, Punjab */}
                        {item.city && <>{item.city},</>} {item.state}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      overflow: "hidden",
                    }}
                  >
                    {/* <Typography className='sd-card-msg'>
                                                        {item.service_description} */}
                    {/* <Button className='sd-more-btn'>&#40;More&#41;</Button> */}
                    {/* </Typography> */}
                    {item.service_description && (
                      <Typography className="sd-card-msg">
                        {expandedItems.includes(item.id)
                          ? item.service_description
                          : truncateText(item.service_description, 100)}
                        {item.service_description.length > 100 && (
                          <Button
                            onClick={() => handleSeeMoreClick(item.id)}
                            className="sd-more-btn"
                          >
                            {expandedItems.includes(item.id)
                              ? "Show Less"
                              : "Show More"}
                          </Button>
                        )}
                      </Typography>
                    )}
                    {/* <Typography className='sd-card-msg' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: expandedItems.includes(item.id) ? 'unset' : 3 }}>
                                                        {item.service_description}
                                                    </Typography> */}
                    {/* {item.service_description.split('\n').length > 3 && ( */}
                    {/* <Button onClick={() => handleSeeMoreClick(item.id)} className='sd-more-btn'>
                                                        {expandedItems.includes(item.id) ? 'See Less' : 'See More'}
                                                    </Button> */}
                    {/* )} */}
                  </Box>

                  <Box className="btn-grp">
                    <Button
                      sx={{
                        background: "#149247 !important",
                        color: "#fff !important",
                        fontFamily: "GothamBold !important",
                      }}
                      onClick={() => {
                        setServiceData(item);
                        setServiceDescModalOpen(true);
                      }}
                    >
                      Edit Service Description
                    </Button>

                    <Button
                      sx={{
                        background: "#149247 !important",
                        color: "#fff !important",
                        fontFamily: "GothamBold !important",
                      }}
                      onClick={() => {
                        setServiceData(item);
                        setServiceLocationModalOpen(true);
                      }}
                    >
                      Edit Service Location
                    </Button>

                    <Button
                      sx={{
                        background: "#149247 !important",
                        color: "#fff !important",
                        fontFamily: "GothamBold !important",
                      }}
                      onClick={() => {
                        setServiceData(item);
                        setServicePhotosModalOpen(true);
                      }}
                    >
                      Edit Service Images
                    </Button>

                    {item.service_live_status == "0" && (
                      <Button
                        sx={{
                          background: "#149247 !important",
                          color: "#fff !important",
                          fontFamily: "GothamBold !important",
                        }}
                        onClick={() => {
                          setServiceData(item);
                          setServiceLiveModalOpen(true);
                        }}
                      >
                        Live service
                      </Button>
                    )}

                    <Button
                      sx={{
                        background: "#ff0000 !important",
                        color: "#fff !important",
                        fontFamily: "GothamBold !important",
                      }}
                      onClick={() => {
                        setDeleteServiceId(item.id);
                        setDeleteModelOpen(true);
                      }}
                    >
                      Delete Service
                    </Button>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>

          {/* <Box sx={{ padding: '1rem' }}>
                                <Box className='get-started'>
                                    <Box className='close-get-started'>
                                        <Typography className='close-text'>
                                            X Close
                                        </Typography>
                                    </Box>
                                    <Box className='get-started-content'>
                                        <Typography>
                                            Want To List YOUR Services on GoEquipMe ?
                                        </Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '0.5rem' }}>
                                            <Link className='get-started-btn' href='/add-service'>
                                                <img src={btnRightArrow} alt="" className='btn-arrow' style={{ transform: 'rotate(90deg)' }} />
                                                Get Started
                                                <img src={btnLeftArrow} alt="" className='btn-arrow' style={{ transform: 'rotate(-90deg)' }} />
                                            </Link>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box> */}
        </Box>
        {/* </Container> */}
      </Box>

      {/* modal for delete a service */}
      {deleteModelOpen && (
        <DeleteServiceModal
          deleteModelOpen={deleteModelOpen}
          setDeleteModelOpen={setDeleteModelOpen}
          deleteServiceId={deleteServiceId}
          setLoading={setLoading}
          onDeleteSuccess={(serviceId) => {
            console.log("serviceId in onDeleteSuccess: ", serviceId);
            setMyServiceList(
              myServiceList.filter((item) => item.id != serviceId)
            );
            setLoading(false);
          }}
        />
      )}

      {/* modal for edit service descriptions */}
      {serviceDescModalOpen && (
        <EditServiceDescriptionModal
          loading={loading}
          serviceDescModalOpen={serviceDescModalOpen}
          setServiceDescModalOpen={setServiceDescModalOpen}
          serviceCategories={serviceCategories}
          serviceData={serviceData}
          setServiceData={setServiceData}
          updateService={updateService}
        />
      )}

      {/* modal for service location update */}
      {serviceLocationModalOpen && (
        <EditServiceLocationModal
          loading={loading}
          serviceLocationModalOpen={serviceLocationModalOpen}
          setServiceLocationModalOpen={setServiceLocationModalOpen}
          serviceData={serviceData}
          setServiceData={setServiceData}
          updateService={updateService}
        />
      )}

      {/* modal for photos update */}
      {servicePhotosModalOpen && (
        <EditServicePhotosModal
          servicePhotosModalOpen={servicePhotosModalOpen}
          setServicePhotosModalOpen={setServicePhotosModalOpen}
          serviceData={serviceData}
          myServices={myServices}
        />
      )}

      {/* to make a service live */}
      {serviceLiveModalOpen && (
        <MakeServiceLiveModal
          loading={loading}
          setLoading={setLoading}
          serviceLiveModalOpen={serviceLiveModalOpen}
          setServiceLiveModalOpen={setServiceLiveModalOpen}
          serviceData={serviceData}
          setServiceData={setServiceData}
          updateService={updateService}
          myServices={myServices}
        />
      )}
      {/* </MainLayout> */}
    </>
  );
};

export default MyServiceDirectory;
