import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import { FaPlus, FaMinus } from "react-icons/fa";

import './styles.scss'

const CustomExpandIcon = () => {
    return (
        <Box
            sx={{
                ".Mui-expanded & > .collapsIconWrapper": {
                    display: "none"
                },
                ".expandIconWrapper": {
                    display: "none"
                },
                ".Mui-expanded & > .expandIconWrapper": {
                    display: "block"
                }
            }}
        >


            <div className="expandIconWrapper"><FaMinus /></div>
            <div className="collapsIconWrapper"><FaPlus /></div>
        </Box>
    );
};

const FindEqpQuesSale = () => {
  return (
      <Box className='gq'>
          <Box className='gq-in'>
              <Box>
                  <p className='gq-heading'>
                      GOT QUESTIONS?
                  </p>
                  <p className='gq-subheading'>
                      Here Are a Few That Are Frequently Asked...
                  </p>
              </Box>
              <Box className='gq-content'>
                  
                  <Box>
                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                          >
                              <Typography>What buyer fees does GoEquipeMe charge?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  None! GoEquipMe may offer other buyer services for a fee. Please inquire should you
                                  need other services.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel2-content"
                              id="panel2-header"
                          >
                              <Typography>How old do you have to be to buy on GoEquipMe?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Most states require you to be 18 years of age to enter into a legally binding contract.
                                  Please check your state’s requirements.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel3-content"
                              id="panel3-header"
                          >
                              <Typography>What other buyer services does GoEquipMe provide?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Just about everything! From due diligence items, inspections, transport or title, just ask
                                  us and we will help.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel4-content"
                              id="panel4-header"
                          >
                              <Typography>What is required to buy on GoEquipMe?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  You must be of legal age for your resident state and have a GoEquipMe user account
                                  with a completed profile.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel5-content"
                              id="panel5-header"
                          >
                              <Typography>Can I communicate with the equipment owner?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  A GoEquipMe representative will communicate with the equipment owner on your behalf to ensure you have everything you need.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel6-content"
                              id="panel6-header"
                          >
                              <Typography>Are posted sales prices negotiable?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Most owners are willing to consider offers that may differ from the asking price.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel7-content"
                              id="panel7-header"
                          >
                              <Typography>What is an RPO?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  RPO stands for Rental Purchase Option. Some equipment sellers allow for potential
                                  buyers to ‘test drive’ the equipment through a rental agreement with a purchase option..
                                  Should you choose to purchase the equipment a predetermined percentage of the
                                  rental payment will be applied to the purchase price.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel8-content"
                              id="panel8-header"
                          >
                              <Typography>What are RPO Terms?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Terms are set by the equipment owner and vary with each piece of equipment.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel9-content"
                              id="panel9-header"
                          >
                              <Typography>Is it hard to get a loan through GoEquipMe to secure my purchase?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  GoEquipMe strives to make borrowing easy. Most loans funded by GoEquipMe are
                                  solely underwritten based on collateral value and fund in less than a week.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel10-content"
                              id="panel10-header"
                          >
                              <Typography>What are GoEquipMe’s loan rates?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Loan rates greatly vary depending on the specific loan details and current economic
                                  conditions. Please inquire directly to find a loan tailored for your situation.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                      <Accordion disableGutters>
                          <AccordionSummary
                              expandIcon={<CustomExpandIcon />}
                              aria-controls="panel11-content"
                              id="panel11-header"
                          >
                              <Typography>How do I get pre-approved financing for my equipment purchase?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Typography>
                                  Simply <a>click</a> here to get started.
                              </Typography>
                          </AccordionDetails>
                      </Accordion>

                  </Box>
              </Box>
          </Box>
      </Box>
  )
}

export default FindEqpQuesSale
