import { Box, Button, Container, FormControl, InputLabel, MenuItem, Select, TextField, Typography, styled, Link, Backdrop, CircularProgress, FormLabel, FormControlLabel, Checkbox, IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import MainLayout from "layouts/MainLayout";
import { EquipmentAPIs } from "../../service/api-service";
import { useDispatch, useSelector } from 'react-redux';
import { setModalOpen, setLoginStatus, setaccessToken, setSessionExpire, setalertMessagetype, setalertMessage } from 'redux/redux-slice';
import { IApiResponseRawData, IStoreValue } from 'utils/types';
import { useEffect, useState, useRef, useCallback } from 'react'
import { useNavigate } from "react-router-dom";

import GoogleautoComplete from "../equipment-list/GoogleautoComplete";
import Geocode from "react-geocode";
import { newPhoneFormat } from "utils/commonFxns"


import Check from "assets/images/check.png";
import Scamera from "assets/images/Scamera.png";
import Pencil from "assets/images/pencil.png";
import iconLocation from "assets/images/icons_equipmentlocation-footer.png";
import iconDoller from "assets/images/icons_lending-footer.png";
import iconArrow from "assets/images/details-page/left-arrow.png";

//photo tips
import Suggest1 from "assets/images/suggest1.png";
import Suggest2 from "assets/images/suggest2.png";
import Suggest3 from "assets/images/suggest3.png";
import Suggest4 from "assets/images/suggest4.png";

const defaultLocation = { lat: 39.321980897034734, lng: -111.10149320091872 };
const CssTextField = styled(TextField)({
    "& .MuiInputBase-root": {
        color: "#2e2e2e",
        background: "#fff",
        fontFamily: "GothamBold !important",
        fontSize: "clamp(12px, 3vw, 15px) !important",
        borderRadius: "10px !important",
        // border: "2px solid #faa61a",
    },
    "& .MuiInputBase-input": {
        // height: '28px'
    },
    "& label.Mui-focused": {
        color: "#faa61a",
        fontWeight: "bold",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#2e2e2e",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "2px solid #faa61a",
        },
        "&:hover fieldset": {
            borderColor: "#2e2e2e",
            border: "2px solid #faa61a",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#faa61a",
        },
    },
});


const AddService = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const inRef = useRef(null);
    const searchRef = useRef();

    const AuthToken = useSelector((state: IStoreValue) => state.equipmentReducer.accessToken);
    const AuthUserID = useSelector((state: IStoreValue) => state.equipmentReducer.Authuser);
    const AuthProfileData = useSelector((state: IStoreValue) => state.equipmentReducer.AuthProfileData);

    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);


    // step 1 fields
    // Add service Fields States
    const [companyName, setCompanyName] = useState('');
    const [serviceCategories, setServiceCategories] = useState([]);
    const [serviceCategory, setServiceCategory] = useState('');
    const [searchTerms, setSearchTerms] = useState('');
    const [hideMyLocation, setHideMyLocation] = useState(false);
    const [phoneVoice, setPhoneVoice] = useState('');
    const [phoneText, setPhoneText] = useState('');
    const [email, setEmail] = useState('');
    const [webSite, setWebSite] = useState('');
    const [serviceDescription, setServiceDescription] = useState('');
    const [serviceDescriptionWords, setServiceDescriptionWords] = useState(1000);

    // location related states
    const [locationMarker, setLocationMarker] = useState({});
    const [mapLocation, setMapLocation] = useState({});
    const [FullAddressMap, setFullAddressMap] = useState('');
    const [FullAddress, setFullAddress] = useState({
        address: "",
        city: "",
        state: "",
        short_state: "",
        postal_code: "",
        country: "",
    });

    // step 2 fields
    // image upload related fields
    const [file, setFile] = useState([]);
    const [Thumbimage, setThumbimage] = useState(0);
    const [imagearr, setimagearr] = useState([]);
    const [Uploadedpath, setUploadedpath] = useState([]);

    // step 3 fields
    // send to 3 emails 
    const [email1, setEmail1] = useState('');
    const [email2, setEmail2] = useState('');
    const [email3, setEmail3] = useState('');

    const fetchServiceCategories = async () => {
        try {
            // Call the API to get the service categories
            const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
            const serviceCategories = await EquipmentAPIs.fetchServiceCategories();

            if (serviceCategories["type"] == "RXSUCCESS") {
                setServiceCategories(
                    serviceCategories["data"].filter((data) => data.status == 1).sort((a, b) => a.title.localeCompare(b.title))
                );
            } else {
                // Display an error message if deletion fails
                dispatch(setalertMessage("error while fetching service Categories."));
                dispatch(setalertMessagetype("error"));
            }
        } catch (err) {
            // Handle errors that occur during the deletion process
            console.log(err);
        }
    };

    // Select multiple images
    const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        const fileList = e.target.files;
        if (fileList.length > 0) {
            setLoading(true);
            var result = Object.entries(fileList);

            if (result.length > 6 || file.length + result.length > 6) {
                setLoading(false);
                dispatch(setalertMessage("you can upload only 6 images."));
                dispatch(setalertMessagetype("error"));
                return false;
            } else {
                if (imagearr.length > 0) {
                    var arr = [...imagearr, ...result];
                    setimagearr(arr);
                } else {
                    setimagearr(result);
                }

                let upload_files = [...file];
                for (let index = 0; index < result.length; index++) {
                    if (
                        e.target.files[index].type == "image/jpg" ||
                        e.target.files[index].type == "image/jpeg" ||
                        e.target.files[index].type == "image/png"
                    ) {
                        var fileLoaded = URL.createObjectURL(e.target.files[index]);
                        upload_files.push(fileLoaded);
                    }
                }
                setFile(upload_files);
                setLoading(false);
            }
        }
    };

    // Remove Images
    const removeImage = (fileName, i) => {
        var myArray = [...file];
        var _myarray = [...imagearr];
        const index = myArray.indexOf(fileName);
        if (index > -1) {
            setThumbimage(i == Thumbimage ? 0 : Thumbimage > i ? 0 : 0);
            myArray.splice(index, 1);
            _myarray.splice(index, 1);
        }
        setFile(myArray);
        setimagearr(_myarray);
        console.log("Uploadedpath : ", Uploadedpath);

        // error length in console here
        if (_myarray.length == 0 && Uploadedpath.length > 0) {
            // if (_myarray.length > 0 && Uploadedpath.length > 0) {
            var uploadedPathFile = [...Uploadedpath];
            uploadedPathFile.splice(i, 1);
            // setUploadedpath(uploadedPathFile);
        }
        setUploadedpath(uploadedPathFile);

        // setimagearr(myArray)
    };

    // Upload services images
    const uploadfiles = async () => {
        if (imagearr.length > 0) {
            setLoading(true);
            let upload_files_path = [];

            file.map((filename, i) => {
                if (filename.indexOf("blob:http") > -1) {
                } else {
                    upload_files_path.push(filename);
                }
            });

            let promises = imagearr.map(async (imgObg, index) => {
                let file_obj = imgObg[1];

                if (file_obj) {
                    try {
                        const formData = new FormData();
                        formData.append("image", file_obj);
                        formData.append("name", file_obj.name);
                        formData.append("from", "Web");

                        if (Thumbimage == index) {
                            formData.append("thumbnail", file_obj);
                        }

                        let access_token = AuthToken;
                        const headers = {
                            Authorization: `${access_token}`,
                            "Content-Type": "multipart/form-data",
                            Accept: "application/json",
                            request_from: "Web",
                        };
                        const upload_Equip_file: IApiResponseRawData =
                            await EquipmentAPIs.uploadEquipImage(formData, headers);

                        if (upload_Equip_file["status"] == 200) {
                            var uploaded_path = upload_Equip_file["data"]["image"];
                            upload_files_path.push(uploaded_path);
                            return;
                        } else if (upload_Equip_file["status"] == false) {
                            dispatch(setalertMessage(upload_Equip_file["slug"]));
                            dispatch(setalertMessagetype("error"));
                            setLoading(false);
                            return false;
                        } else {
                            dispatch(setalertMessage(upload_Equip_file["message"]));
                            dispatch(setalertMessagetype("error"));
                            return false;
                        }
                    } catch (err) {
                        dispatch(setalertMessage(err));
                        dispatch(setalertMessagetype("error"));
                        setLoading(false);
                        return false;
                    }
                }
            });
            Promise.all(promises).then(() => {
                if (
                    upload_files_path.length > 0 &&
                    imagearr.length == upload_files_path.length
                ) {
                    console.log("upload_files_path: ", upload_files_path);
                    let activeStep = step + 1;
                    setUploadedpath(upload_files_path);
                    // by GD
                    // setUploadedpath([...Uploadedpath, ...upload_files_path]);
                    setStep(activeStep);
                    setimagearr([]);
                }
                setLoading(false);
                // formSubmit(upload_files_path)
            });
        }
    };

    // Get city state country address etc from geocode
    const getFullAddress = async (center) => {
        console.log("getFullAddress worked");
        if (center.lat != null && center.lng != null) {
            setLocationMarker(center);
            Geocode.fromLatLng(center.lat, center.lng).then(
                (response) => {
                    const address = response.results[0].formatted_address;
                    let city, state, country, short_state, postal_code;
                    for (
                        let i = 0;
                        i < response.results[0].address_components.length;
                        i++
                    ) {
                        for (
                            let j = 0;
                            j < response.results[0].address_components[i].types.length;
                            j++
                        ) {
                            switch (response.results[0].address_components[i].types[j]) {
                                case "locality":
                                    city = response.results[0].address_components[i].long_name;
                                    break;
                                case "administrative_area_level_1":
                                    state = response.results[0].address_components[i].long_name;
                                    break;
                                case "country":
                                    country = response.results[0].address_components[i].long_name;
                                    break;
                                case "administrative_area_level_1":
                                    short_state =
                                        response.results[0].address_components[i].short_name;
                                    break;
                                case "postal_code":
                                    postal_code =
                                        response.results[0].address_components[i].short_name;
                                    break;
                            }
                        }
                    }
                    let full_address = {
                        address: address,
                        city: city,
                        state: state,
                        short_state: short_state,
                        postal_code: postal_code,
                        country: country,
                    };
                    setFullAddress(full_address);
                    //setLocationAddr(full_address)
                },
                (error) => {
                    console.error(error);
                }
            );
        } else {
            setFullAddress({
                address: "",
                city: "",
                state: "",
                short_state: "",
                postal_code: "",
                country: "",
            });
            setLocationMarker({});
        }
    };

    // fxn to share app link with three emails after success call the addService method
    const shareLink = async () => {
        try {
            setLoading(true);
            const data = {
                emails: `${email1},${email2},${email3}`,
                link: `Hi, I just found this awesome app where you can rent or list heavy equipment. Check it out!
                                https://onelink.to/ch9mhw`
            }
            // console.log("data in emails : ", data);

            const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
            const sendMails = await EquipmentAPIs.shareWithEmails(data, headers);
            console.log("sendMails: ", sendMails);
            if (sendMails["type"] == "RXSUCCESS") {
                console.log("emails send successfully.");
                // setLoading(false);
                // dispatch(setalertMessage("error while adding service."));
                addService();
            } else {
                // Display an error message if deletion fails
                // addService();
                dispatch(setalertMessagetype("error"));
                dispatch(setalertMessage("error while adding service."));
                setLoading(false);
            }

        } catch (error) {
            dispatch(setalertMessagetype("error"));
            dispatch(setalertMessage("error while sending emails."));
            setLoading(false);
        }
    }

    const areEmailsUnique = (email1, email2, email3) => {
        return email1 !== email2 && email1 !== email3 && email2 !== email3;
    }

    // const newPhoneFormat = (phNumber) => {
    //     let rawNumber = phNumber.replace(/[^0-9]/g, "");
    //     // second way to format number
    //     if (rawNumber.length <= 3) return rawNumber;
    //     const segments = [
    //         rawNumber.slice(0, 3),
    //         rawNumber.slice(3, 6),
    //         // rawNumber.slice(6, 10)
    //         rawNumber.slice(6)
    //     ];
    //     return segments.filter(Boolean).join('-');
    // };

    // fxn to add services
    const addService = async () => {
        try {
            setLoading(true);

            const primaryImage = Uploadedpath ? Uploadedpath[Thumbimage] : null;
            const serviceThumbnail = primaryImage ? primaryImage : null;
            const data = {
                company_name: companyName,
                service_category: serviceCategory,
                key_description_search: searchTerms,
                address: FullAddress.address,
                city: FullAddress.city,
                state: FullAddress.state,
                country: FullAddress.country,
                latitude: locationMarker["lat"],
                longitude: locationMarker["lng"],
                phone_voice: phoneVoice,
                phone_text: phoneText,
                email,
                website: webSite,
                service_description: serviceDescription,
                // ...(Uploadedpath.length > 0 && {service_photos: Uploadedpath}),
                service_photos: (Uploadedpath && Uploadedpath.length > 0) ? Uploadedpath.toString() : null,
                service_thumbnail: serviceThumbnail,
                user_id: AuthUserID,
                location_status: hideMyLocation ? 1 : 0,
            };
            console.log("data in add Service:", data);

            const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
            const addService = await EquipmentAPIs.addService(data, headers);
            console.log("addService: ", addService);
            if (addService["type"] == "RXSUCCESS") {
                console.log("service added successfully");
                dispatch(setalertMessagetype("success"));
                dispatch(setalertMessage("service added successfully."));
                // setLoading(false);
                setTimeout(() => {
                    navigate("/service-directory");
                }, 1000);

            } else {
                // Display an error message if deletion fails
                dispatch(setalertMessagetype("error"));
                dispatch(setalertMessage("error while adding service."));
                setLoading(false);
            }

        } catch (error) {
            // console.log("error in addSevice fxn: ", error.message);
            console.log("error in addSevice fxn: ", error);
            dispatch(setalertMessagetype("error"));
            dispatch(setalertMessage("error while adding a service."));
            setLoading(false);
        }
    };

    // multiStep form next Button handle fxn
    const nextStep = async () => {

        const primaryImage = Uploadedpath ? Uploadedpath[Thumbimage] : null;
        const serviceThumbnail = primaryImage ? primaryImage : null;
        const data = {
            company_name: companyName,
            service_category: serviceCategory,
            key_description_search: searchTerms,
            address: FullAddress.address,
            city: FullAddress.city,
            state: FullAddress.state,
            country: FullAddress.country,
            latitude: locationMarker["lat"],
            longitude: locationMarker["lng"],
            phone_voice: phoneVoice,
            phone_text: phoneText,
            email,
            webSite,
            service_description: serviceDescription,
            // ...(Uploadedpath.length > 0 && {service_photos: Uploadedpath}),
            service_photos: (Uploadedpath && Uploadedpath.length > 0) ? Uploadedpath.toString() : null,
            service_thumbnail: serviceThumbnail,
            user_id: AuthUserID,
            location_status: hideMyLocation ? 1 : 0,
        };

        console.log("data: ", data);
        // let params = JSON.stringify({
        //     company_name: this.state.service_detail.company_name,
        //     service_category: this.state.service_detail.service_category,
        //     key_description_search: this.state.service_detail.search_term,
        //     address: this.state.service_detail.address,
        //     city: this.state.service_detail.city,
        //     state: this.state.service_detail.state,
        //     country: this.state.service_detail.country,
        //     latitude: this.state.service_detail.latitude,
        //     longitude: this.state.service_detail.longitude,
        //     phone_voice: this.state.service_detail.phone_voice,
        //     phone_text: this.state.service_detail.phone_txt,
        //     email: this.state.service_detail.email,
        //     website: this.state.service_detail.website,
        //     service_description: this.state.service_detail.service_description,
        //     service_photos: this.state.dataImage.images,
        //     service_thumbnail: this.state.dataImage.thumbnail,
        //     user_id: this.state.user_id,
        //     location_status: this.state.service_detail.location_status == false ? 0 : 1,
        //   });

        console.log("next step");
        if (step == 1) {
            if (!companyName.trim()) {
                dispatch(setalertMessagetype("error"));
                dispatch(setalertMessage("Please enter company name."));
                return false;
            }

            if (!serviceCategory) {
                dispatch(setalertMessagetype("error"));
                dispatch(setalertMessage("Please select a Service Category."));
                return false;
            }

            if (email !== '') {
                let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                const isEmailValid = emailRegex.test(email);
                if (!isEmailValid) {
                    dispatch(setalertMessagetype("error"));
                    dispatch(setalertMessage("Please enter a valid mail."));
                    return false;
                }
            }

            if (!locationMarker["lat"]) {
                dispatch(setalertMessagetype("error"));
                dispatch(setalertMessage("Please select a location."));
                return false;
            }

            if (phoneVoice !== '') {
                if (phoneVoice.length < 10) {
                    dispatch(setalertMessagetype("error"));
                    dispatch(setalertMessage("Please enter a valid phone/voice."));
                    return false;
                }
            }

            if (phoneText !== '') {
                if (phoneText.length < 10) {
                    dispatch(setalertMessagetype("error"));
                    dispatch(setalertMessage("Please enter a valid phone/text."));
                    return false;
                }
            }
        }

        if (step == 2) {
            if (imagearr.length > 0) {
                // upload files
                uploadfiles();
                //activeStep = currentStep + 1;
                // activeStep = currentStep;    
            } else if (Uploadedpath && Uploadedpath.length > 0) {
                //   activeStep = currentStep + 1;
                setStep(step + 1)
            } else {
                //   dispatch(setalertMessage("Please add images to continue."));
                //   dispatch(setalertMessagetype("error"));
                //   return false;
                setStep(step + 1)
            }
        }

        if (step == 3) {
            console.log("step three here to hit the api");
            if (!email1 || !email2 || !email3) {
                dispatch(setalertMessagetype("error"));
                dispatch(setalertMessage("Please fill all 3 email fields and emails should be valid."));
                return;
            }

            // if (email1 && email2 && email3) {
            let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const isEmail1Valid = emailRegex.test(email1);
            const isEmail2Valid = emailRegex.test(email2);
            const isEmail3Valid = emailRegex.test(email3);
            console.log("email validator", { isEmail1Valid, isEmail2Valid, isEmail3Valid });

            if (!isEmail1Valid || !isEmail2Valid || !isEmail3Valid) {
                console.log("in invalid if");
                dispatch(setalertMessagetype("error"));
                dispatch(setalertMessage("Please enter valid emails."));
                return false;
            } else {
                if (areEmailsUnique(email1, email2, email3)) {
                    console.log("All three emails are unique.");
                    console.log("in all valid else");
                    setLoading(true);
                    shareLink();
                } else {
                    console.log("At least two emails are the same.");
                    dispatch(setalertMessagetype("error"));
                    dispatch(setalertMessage("Please enter different emails."));
                    return false;
                }
                // console.log("in all valid else");
                // setLoading(true);
                // shareLink();
            }
            // }
            // setLoading(true);
            // const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
            // const addService = await EquipmentAPIs.addService(data, headers);
            // console.log("addService: ", addService);
            // if (addService["type"] == "RXSUCCESS") {
            //     console.log("service added successfully");
            //     setLoading(false);
            // } else {
            //     // Display an error message if deletion fails
            //     dispatch(setalertMessage("error while adding service."));
            //     dispatch(setalertMessagetype("error"));
            //     setLoading(false);
            // }
        }

        if (step < 3) setStep(step + 1);

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    };

    // multiStep form back button handle fxn
    const backStep = () => {
        console.log("clicked on backStep");
        if (step > 1) setStep(step - 1);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };


    // const uploadImages = () => {
    //     if (step == 2) {
    //         if (imagearr.length > 0) {
    //             // upload files
    //             uploadfiles();
    //             //activeStep = currentStep + 1;
    //             // activeStep = currentStep;
    //         } else if (Uploadedpath.length > 0) {
    //             //   activeStep = currentStep + 1;
    //             setStep(step + 1)
    //         } else {
    //             //   dispatch(setalertMessage("Please add images to continue."));
    //             //   dispatch(setalertMessagetype("error"));
    //             //   return false;
    //             setStep(step + 1)
    //         }
    //     }
    // }

    useEffect(() => {
        if (AuthProfileData && AuthProfileData != undefined && AuthProfileData != null && AuthToken) {
            fetchServiceCategories();
            console.log("auth profileData: ", AuthProfileData);

            setFullAddressMap(AuthProfileData['address']);

            setMapLocation({
                lat: parseFloat(AuthProfileData["lat"]) || defaultLocation.lat,
                lng: parseFloat(AuthProfileData["lng"]) || defaultLocation.lng,
            });

        }
    }, [AuthProfileData]);

    return (
        <>
            <MainLayout>
                {loading && (
                    <Box>
                        <Backdrop
                            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={loading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </Box>
                )}

                <Box>

                    <Typography className='' sx={{ fontSize: 'clamp(30px, 3vw, 50px) !important', color: '#fff', textAlign: 'center', fontFamily: 'GothamBold !important' }}>
                        Service Directory
                    </Typography>


                    <Box className='service-list-grid'>
                        <Box className=''>
                            <Box className="">
                                <Typography className='' sx={{ color: '#faa61a !important', fontSize: 'clamp(18px, 3vw, 30px) !important', fontFamily: 'GothamBold !important' }}>
                                    LET’S GET STARTED
                                </Typography>
                                <Typography className='' sx={{ margin: '1rem 0', color: '#fff !important', fontSize: 'clamp(16px, 3vw, 20px) !important', fontFamily: 'GothamBold !important' }}>
                                Spread the word about our app with three friends through email, and in return, enjoy a complimentary listing on our platform. Join our vibrant community and showcase your services to a broader audience hassle-free!
                                </Typography>

                                <ul className="list-eqp-left-list">
                                    <li className="list-eqp-left-list-item">
                                        &nbsp;Increased visibility to a larger audience.
                                    </li>
                                    <li className="list-eqp-left-list-item">
                                        &nbsp;Access to a wider customer base.
                                    </li>
                                    <li className="list-eqp-left-list-item">
                                        &nbsp;Convenient discovery and booking for customers.
                                    </li>
                                    <li className="list-eqp-left-list-item">
                                        &nbsp;Built-in marketing and promotional opportunities.
                                    </li>
                                    <li className="list-eqp-left-list-item">
                                        &nbsp;Streamlined booking and payment processes.
                                    </li>
                                    <li className="list-eqp-left-list-item">
                                        &nbsp;Ability to gather customer reviews and ratings.
                                    </li>
                                    <li className="list-eqp-left-list-item">
                                        &nbsp;Access to analytics for insights and optimization.
                                    </li>
                                    <li className="list-eqp-left-list-item">
                                        &nbsp;Competitive advantage in the marketplace.
                                    </li>
                                    <li className="list-eqp-left-list-item">
                                        &nbsp;List for free!
                                    </li>
                                </ul>

                                {/* <Typography
                                    className=""
                                    sx={{ color: "#faa61a", fontSize: 'clamp(18px, 3vw, 30px) !important', fontFamily: 'GothamBold !important' }}
                                >
                                    Put your equipment to work!
                                </Typography> */}
                            </Box>
                        </Box>
                        <Box className='list-eqp-grid-item'>
                            <Box className="list-eqp-grid-item-inner">
                                {step == 1 && (
                                    <Box className="list-eqp-steps-sec">
                                        <Box className="list-eqp-steps-heading">
                                            <img src={Pencil} className="step-icon" alt="" />
                                            <Typography>Step {step}</Typography>
                                        </Box>
                                        <Box className="list-eqp-steps-subheading">
                                            <Typography>SERVICE DESCRIPTION'S</Typography>
                                        </Box>
                                    </Box>
                                )}
                                {step == 2 && (
                                    <Box className="list-eqp-steps-sec">
                                        <Box className="list-eqp-steps-heading">
                                            <img src={Scamera} className="step-icon" alt="" />
                                            <Typography>Step {step}</Typography>
                                        </Box>
                                        <Box className="list-eqp-steps-subheading">
                                            <Typography>SERVICE PHOTOS</Typography>
                                        </Box>
                                    </Box>
                                )}
                                {step == 3 && (
                                    <Box className="list-eqp-steps-sec">
                                        <Box className="list-eqp-steps-heading">
                                            <img src={iconDoller} className="" alt="" width='55px' height='auto' />
                                            <Typography>Step {step}</Typography>
                                        </Box>
                                        <Box className="list-eqp-steps-subheading">

                                            <Typography>SHARE TO 3 EMAILS TO PUBLISH</Typography>
                                        </Box>
                                    </Box>
                                )}

                                <div className="step-bars MuiBox-root css-0">
                                    {/* {Activestep.map((active_index) => ( */}
                                    <span className={`equipment-step-bar ${step >= 1 ? 'active' : ''}`}></span>
                                    {/* ))} */}

                                    {/* {INActivestep.map((active_index) => ( */}
                                    <span className={`equipment-step-bar ${step >= 2 ? 'active' : ''}`}></span>
                                    <span className={`equipment-step-bar ${step >= 3 ? 'active' : ''}`}></span>
                                    {/* ))} */}
                                </div>
                                {step === 1 && (
                                    <>
                                        <Typography className="list-eqp-white-p" sx={{ marginBottom: '1rem', color: '#fff !important', fontSize: 'clamp(16px, 3vw, 20px) !important' }}>
                                            Let's start by getting a few details about your services.
                                        </Typography>
                                        {/* for company Name */}
                                        <FormControl
                                            fullWidth
                                            className="form-fields list-eqp-form-fields"
                                        >
                                            <label className="list-eqp-input-label">
                                                Company Name
                                            </label>
                                            <CssTextField
                                                onChange={(e) => setCompanyName(e.target.value)}
                                                id="outlined-basicd"
                                                // label="Listing Title"
                                                variant="outlined"
                                                value={companyName}
                                                placeholder="your company name (required)"
                                            />
                                        </FormControl>

                                        {/* for service category select */}
                                        <FormControl
                                            sx={{ width: "100%" }}
                                            className="form-fields list-eqp-form-fields"
                                        >
                                            <label className="list-eqp-input-label">Category</label>
                                            <Select
                                                value={serviceCategory}
                                                onChange={(e) => setServiceCategory(e.target.value)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                                sx={{
                                                    "& .MuiSelect-select": {
                                                        color: "#2D2D2D !important",
                                                        fontFamily: "GothamBold !important",
                                                    },
                                                }}
                                                style={{
                                                    backgroundColor: "#fff",
                                                    border: "2px solid #FAA61A",
                                                    borderRadius: "10px",
                                                }}
                                                className="select-dropdwn"
                                            >
                                                <MenuItem value="" className="category-dropdown">
                                                    Select Here
                                                </MenuItem>
                                                {serviceCategories.map((item, index) => (
                                                    <MenuItem
                                                        key={item.id} value={item.title}
                                                        className="list-eqp-select-options"
                                                    >
                                                        {item.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        {/* key search terms */}
                                        <FormControl
                                            fullWidth
                                            className="form-fields list-eqp-form-fields"
                                        >
                                            <label className="list-eqp-input-label">
                                                Search Terms
                                            </label>
                                            <CssTextField
                                                onChange={(e) => setSearchTerms(e.target.value)}
                                                value={searchTerms}
                                                id="outlined-basic"
                                                // label="Make & Model"
                                                variant="outlined"
                                                placeholder="Key Search Terms"
                                            />
                                        </FormControl>

                                        {/* location fields */}


                                        {/* google map */}
                                        <FormControl fullWidth className="form-fields">
                                            <label className="list-eqp-input-label">
                                                Location
                                            </label>
                                            <GoogleautoComplete
                                                onPress={(center) => {
                                                    getFullAddress(center);
                                                }}
                                                inputRefs={inRef}
                                                searchRef={searchRef}
                                                equipLocation={mapLocation}
                                            // equipAddress={FullAddressMap}
                                            />

                                        </FormControl>

                                        {/* hide my cation checkBox */}

                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                            <FormControlLabel
                                                className='hide-checkbox'
                                                control={<Checkbox
                                                    sx={{
                                                        "&:hover": { bgcolor: "transparent", color: '#fff', fontFamily: 'GothamBold !important' },
                                                    }}
                                                    checked={hideMyLocation}
                                                    onChange={(e) => setHideMyLocation(e.target.checked)}
                                                    disableRipple
                                                    color="default"
                                                    // checkedIcon={<BpCheckedIcon />}
                                                    // icon={<BpIcon />}
                                                    inputProps={{ "aria-label": "Checkbox demo" }}
                                                />} label="Hide my Location"
                                            />
                                        </Box>

                                        <Typography className="list-eqp-white-p" sx={{ marginBottom: '1rem', color: '#fff !important', fontSize: 'clamp(16px, 3vw, 20px) !important' }}>
                                            Optional info to publish
                                        </Typography>


                                        {/* phoneVoice field */}
                                        <FormControl
                                            fullWidth
                                            className="form-fields list-eqp-form-fields"
                                        >
                                            <label className="list-eqp-input-label">
                                                Phone / Voice
                                            </label>
                                            <CssTextField
                                                // onChange={(e) => {
                                                //     const input = e.target.value;
                                                //     const numericInput = input.replace(/[^0-9]/g, '');
                                                //     setPhoneVoice(numericInput);
                                                // }}
                                                // value={phoneVoice}
                                                onChange={(e) => setPhoneVoice(e.target.value.replace(/[^0-9]/g, ""))}
                                                value={newPhoneFormat(phoneVoice)}
                                                id="outlined-basic"
                                                // label="Make & Model"
                                                variant="outlined"
                                                placeholder="Phone / Voice"
                                                inputProps={{
                                                    maxLength: 12,
                                                }}
                                            />
                                        </FormControl>

                                        {/* phoneText field */}
                                        <FormControl
                                            fullWidth
                                            className="form-fields list-eqp-form-fields"
                                        >
                                            <label className="list-eqp-input-label">
                                                Phone / Text
                                            </label>
                                            <CssTextField
                                                // onChange={(e) => setPhoneText(e.target.value.replace(/[^0-9]/g, ''))}
                                                // value={phoneText}
                                                onChange={(e) => setPhoneText(e.target.value.replace(/[^0-9]/g, ""))}
                                                value={newPhoneFormat(phoneText)}
                                                id="outlined-basic"
                                                // label="Make & Model"
                                                variant="outlined"
                                                placeholder="Phone / Text"
                                                inputProps={{
                                                    maxLength: 12,
                                                }}
                                            />
                                        </FormControl>

                                        {/* Email field*/}
                                        <FormControl
                                            fullWidth
                                            className="form-fields list-eqp-form-fields"
                                        >
                                            <label className="list-eqp-input-label">
                                                Email
                                            </label>
                                            <CssTextField
                                                type='email'
                                                onChange={(e) => setEmail(e.target.value)}
                                                value={email}
                                                id="outlined-basic"
                                                // label="Make & Model"
                                                variant="outlined"
                                                placeholder="Email"
                                            />
                                        </FormControl>

                                        {/* website field */}
                                        <FormControl
                                            fullWidth
                                            className="form-fields list-eqp-form-fields"
                                        >
                                            <label className="list-eqp-input-label">
                                                Website
                                            </label>
                                            <CssTextField
                                                onChange={(e) => setWebSite(e.target.value)}
                                                value={webSite}
                                                id="outlined-basic"
                                                // label="Make & Model"
                                                variant="outlined"
                                                placeholder="Website"
                                            />
                                        </FormControl>

                                        {/* serviceDescription */}
                                        <FormControl
                                            fullWidth
                                            className="form-fields list-eqp-form-fields"
                                        >
                                            <label className="list-eqp-input-label">
                                                Provide a description of the service you offer
                                            </label>
                                            <CssTextField
                                                onChange={(e) => {
                                                    setServiceDescription(e.target.value);
                                                    setServiceDescriptionWords(1000 - (e.target.value.length));
                                                }}
                                                value={serviceDescription}
                                                id="outlined-basic"
                                                // label="Make & Model"
                                                variant="outlined"
                                                placeholder="Provide a description of the service you offer"
                                                multiline
                                                rows={4}
                                                inputProps={{
                                                    maxLength: 1000,
                                                }}
                                            />
                                        </FormControl>
                                        <Typography sx={{ display: 'flex', alignItems: 'baseline', color: '#fff', fontFamily: 'GothamBold !important' }}>
                                            <span style={{ color: '#fff !important', fontFamily: 'GothamBold !important' }}>
                                                Characters Left :
                                            </span>
                                            <span style={{ color: '#faa61a !important', fontFamily: 'GothamBold !important' }}>
                                                {serviceDescriptionWords}
                                            </span>

                                        </Typography>

                                        <Box
                                            className=""

                                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '2rem', marginTop: '1.5rem' }}

                                        >
                                            <Button
                                                className="signin-btn cmmn-green-btn"
                                                type="button"
                                                variant="contained"
                                                // sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                                                onClickCapture={() => navigate("/service-directory")}
                                                sx={{
                                                    // border: "3px solid #faa61a",
                                                    borderRadius: "10px !important",
                                                    fontSize: '20px !important',
                                                    height: '55px'
                                                }}
                                            >
                                                Cancel & Exit
                                            </Button>

                                            <Button
                                                className="signin-btn cmmn-green-btn"
                                                type="button"
                                                variant="contained"
                                                // sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                                                onClickCapture={nextStep}
                                                sx={{
                                                    // border: "3px solid #faa61a",
                                                    borderRadius: "10px !important",
                                                }}
                                            >
                                                Next
                                            </Button>
                                        </Box>
                                    </>
                                )}

                                {step === 2 && (
                                    <>
                                        <Typography className="list-eqp-white-p">
                                            Let's show what you've got
                                        </Typography>

                                        <Box className="img-box list-eqp-img-box">
                                            <Box className="img-box-grid list-eqp-img-box-grid">
                                                {file.map((image, i) => (
                                                    <Box
                                                        className="img-box-item list-eqp-img-box-item"
                                                        onClickCapture={() => {
                                                            setThumbimage(i);
                                                        }}
                                                        key={i}
                                                    >
                                                        <img
                                                            className="main-img-box-item list-eqp"
                                                            src={image}
                                                            alt=""
                                                        />
                                                        <IconButton
                                                            className="cross-btn list-eqp-cross"
                                                            onClickCapture={() => {
                                                                // setThumbimage(Thumbimage);
                                                                removeImage(image, i);
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>


                                                        {Thumbimage == i && (
                                                            <img className="img-check" src={Check} alt="" />
                                                        )}

                                                    </Box>
                                                ))}
                                            </Box>
                                        </Box>

                                        <FormControl fullWidth className="form-fields">
                                            <Button
                                                variant="contained"
                                                component="label"
                                                className="upload-btn"
                                                sx={{
                                                    fontSize: "25px",
                                                    fontFamily: "GothamBold !important",
                                                }}
                                            >
                                                Select Photos
                                                <input
                                                    id="photo"
                                                    name="photo"
                                                    type="file"
                                                    multiple={true}
                                                    onChange={handleImageChange}
                                                    hidden
                                                    accept="image/jpg, image/jpeg, image/png"
                                                />
                                            </Button>
                                        </FormControl>

                                        <Typography
                                            className="service-item__content-para list-eqp-white-p"
                                            sx={{
                                                fontFamily: "GothamBold !important",
                                                fontWeight: "900",
                                            }}
                                        >
                                            PHOTO TIPS
                                        </Typography>
                                        <hr className="yellow-line" />
                                        {/* suggestion photos */}
                                        <Box className="photo-tips-main list-eqp-photo-tips">
                                            <Box className="photo-tips-main-item">
                                                <Typography className="list-eqp-suggestion-p">
                                                    Horizontal Orientation
                                                </Typography>
                                                <img src={Suggest1} className="photo-tips" alt="" />
                                            </Box>
                                            <Box className="photo-tips-main-item">
                                                <Typography className="list-eqp-suggestion-p">
                                                    Well Lit
                                                </Typography>
                                                <img src={Suggest2} className="photo-tips" alt="" />
                                            </Box>
                                            <Box className="photo-tips-main-item">
                                                <Typography className="list-eqp-suggestion-p">
                                                    No Graphics/Text
                                                </Typography>
                                                <img src={Suggest3} className="photo-tips" alt="" />
                                            </Box>
                                            <Box className="photo-tips-main-item">
                                                <Typography className="list-eqp-suggestion-p">
                                                    Composition/Angle
                                                </Typography>
                                                <img src={Suggest4} className="photo-tips" alt="" />
                                            </Box>
                                        </Box>

                                        <Box className="btns-grp list-eqp-btn-grp">
                                            <Button
                                                className="signin-btn cmmn-green-btn"
                                                type="button"
                                                variant="contained"
                                                // sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                                                onClickCapture={backStep}
                                            >
                                                Back
                                            </Button>
                                            <Button
                                                className="signin-btn cmmn-green-btn"
                                                type="button"
                                                variant="contained"
                                                // sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                                                onClickCapture={nextStep}
                                            // onClickCapture={uploadImages}
                                            >
                                                Next
                                            </Button>
                                        </Box>
                                    </>
                                )}

                                {step === 3 && (
                                    <>
                                        {/* 3 email fields */}
                                        {/* email1 field */}
                                        <FormControl
                                            fullWidth
                                            className="form-fields list-eqp-form-fields"
                                        >
                                            <label className="list-eqp-input-label">
                                                1. Email
                                            </label>
                                            <CssTextField
                                                onChange={(e) => setEmail1(e.target.value)}
                                                id="outlined-basicd"
                                                // label="Listing Title"
                                                variant="outlined"
                                                value={email1}
                                                placeholder="Enter Email Address"
                                            />
                                        </FormControl>

                                        {/* email2 field */}
                                        <FormControl
                                            fullWidth
                                            className="form-fields list-eqp-form-fields"
                                        >
                                            <label className="list-eqp-input-label">
                                                2. Email
                                            </label>
                                            <CssTextField
                                                onChange={(e) => setEmail2(e.target.value)}
                                                id="outlined-basicd"
                                                // label="Listing Title"
                                                variant="outlined"
                                                value={email2}
                                                placeholder="Enter Email Address"
                                            />
                                        </FormControl>

                                        {/* email3 field */}
                                        <FormControl
                                            fullWidth
                                            className="form-fields list-eqp-form-fields"
                                        >
                                            <label className="list-eqp-input-label">
                                                3. Email
                                            </label>
                                            <CssTextField
                                                onChange={(e) => setEmail3(e.target.value)}
                                                id="outlined-basicd"
                                                // label="Listing Title"
                                                variant="outlined"
                                                value={email3}
                                                placeholder="Enter Email Address"
                                            />
                                        </FormControl>

                                        <Box className="btns-grp list-eqp-btn-grp">
                                            <Button
                                                className="signin-btn cmmn-green-btn"
                                                type="button"
                                                variant="contained"
                                                // sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                                                onClickCapture={backStep}
                                            >
                                                Back
                                            </Button>
                                            <Button
                                                className="signin-btn cmmn-green-btn"
                                                type="button"
                                                variant="contained"
                                                // sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                                                onClickCapture={nextStep}
                                            >
                                                LIST IT
                                            </Button>
                                        </Box>

                                    </>
                                )}
                            </Box>
                        </Box>

                    </Box>
                </Box>

            </MainLayout>
        </>
    )
}

export default AddService;