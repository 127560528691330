import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  Typography,
  Backdrop,
  Link,
  Modal,
  Button,
  useMediaQuery,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import moment from 'moment';

import { IStoreValue } from 'utils/types';
import { EquipmentAPIs } from "../../service/api-service";
import { useDispatch, useSelector } from "react-redux";
import { setalertMessagetype, setalertMessage, setLoginModalOpen, setLogInLoading, SetRedirectPage } from "redux/redux-slice";
import { useParams,useNavigate } from "react-router-dom";
import MainLayout from '../../layouts/MainLayout'
import CloseIcon from '@mui/icons-material/Close'
import './styles.scss'
import momentTimeZone from "moment-timezone";
import CountdownTimer from "../common/CountDownTimer";
// Define style global
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#b8b9bbfc',
  boxShadow: 24,
  p: 4
}

const OrderRequestSummary = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matchesMobileX = useMediaQuery('(max-width:425px)')

  // Get User data using radux
  const AuthToken = useSelector((state: IStoreValue) => state.equipmentReducer.accessToken)
  const AuthUserID = useSelector((state: IStoreValue) => state.equipmentReducer.Authuser);
  const AuthProfile = useSelector((state: IStoreValue) => state.equipmentReducer.AuthProfileData);

  const [loading, setloading] = React.useState(false);
  const [OrderRequestData, setOrderRequestData] = React.useState(null);
  const [OwnerStatus, setOwnerStatus] = React.useState(true);

  const [OrderRequestPopup, setOrderRequestPopup] = React.useState(false);
  const [OrderRequestClick, setOrderRequestClick] = React.useState(null);

  const [paymentExpiredPopup,setPaymentExpiredPopup] = useState(false);
  const [requestExpireMessage,setRequestExpireMessage] = useState("");

  // Get Order request summary
  const LoadOrderRequest = async (order_requestId, owner_id) => {
    try {
      //if (owner_id == AuthUserID) {

        setloading(true);
        const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
        const Result = await EquipmentAPIs.RequestSummary(
          {
            request_id: order_requestId,
            user_id: AuthUserID,
          }, headers
        );

        if (Result['type'] == "RXSUCCESS") {
          console.log("Result['data']", Result['data']);
          setOwnerStatus(Result['ownerstatus'])
          setOrderRequestData(Result['data']);
          setloading(false);
        }
        else {
          dispatch(setalertMessage(Result['message']));
          dispatch(setalertMessagetype('error'));
          setloading(false);
          return false
        }
     // } else {
       // setOwnerStatus(false)
      //}

    } catch (err) {
      console.log(err);
      setloading(false);
    }
  };

  /*
   * Update order request 
   * Order request approve or reject
   * Status will update after login
   */ 
  const UpdateOrderRequest = async (request_id, status) => {
    try {
      setloading(true)
      setOrderRequestPopup(false)
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const Result = await EquipmentAPIs.UpdateRequest(
        {
          order_request_id: request_id,
          status: status.toString(),
        },
        headers
      );

      if (Result['type'] == "RXSUCCESS") {
        setloading(false) 
        await LoadOrderRequest(request_id, params.user_id);
        if(parseInt(status) === 1) {
          const parentOrderId = parseInt(OrderRequestData?.order_request_data?.parent_order_id);
          const orderId = parentOrderId > 0 ? parentOrderId: OrderRequestData?.order_id;
          setTimeout(()=>navigate(`/equipmentOrders/${orderId}/advance`),300);    
        } else {
          setTimeout(()=>navigate("/equipmentOrders"),300);
        }
        return false;
      }
      else {
        setloading(false)
        if(Result['statusCode']) {
          setPaymentExpiredPopup(true);
        } else {
          setPaymentExpiredPopup(true);
          setRequestExpireMessage(Result["message"]);
        }
        return false;
      }

    } catch (err) {
      setloading(false)
      console.log(err);
      return false;
    }
  };

  /*
   * Handle chat with renter by owner
   * If user want to chat with renter
   * If user not login then get a message "You must be login" and open login popup
   * if user already login and click on chat link 'click here' then user redirect to notification section
   * Create a chat object for open chat window
   * Chat object store on localStroage
   */
  const HandkeChatwithRenter = async (equipId, senderID, reciverID, reciverName, equipName) => {

    // Handle chat notification object for open chat window
    let ChatObj = {
      equipId: equipId,
      senderID: senderID,
      reciverID: reciverID,
      reciverName: reciverName,
      EquipMake: equipName,
    };
    localStorage.setItem("ChatFromEquipDetail", JSON.stringify(ChatObj));
   // console.log('ChatObj', ChatObj)

    if(AuthUserID && AuthUserID != 0) {
      // let ChatObj = {
      //   equipId: equipId,
      //   senderID: senderID,
      //   reciverID: reciverID,
      //   reciverName: reciverName,
      //   EquipMake: equipName,
      // };
      // localStorage.setItem("ChatFromEquipDetail", JSON.stringify(ChatObj));
      window.location.href = "/notifications";
    } else {

      dispatch(setalertMessage('You must be logged in.'));
      dispatch(setalertMessagetype('error'));      
      
      dispatch(SetRedirectPage(window.location.origin+"/notifications"))
      //localStorage.setItem("RedirectLink", window.location.origin);
      dispatch(setLoginModalOpen(true));
      dispatch(setLogInLoading(false));
    }
  };

  /*
   * Handle click on Approve or Reject Button
   * If user not login then display message "You must be login" and open login popup
   * After Login page reload and user can approve or reject request
   * if User login then open confirm popup
   * On click confirm popup cancel button popup closed
   * On click confirm popup ok button updated request status
   */
  const HandleRequest = async (requestId, status, type) => {
    // Check user Login status and user ID    
    //if(AuthUserID && AuthUserID != 0) {
      setOrderRequestClick({
        'request_id': requestId,
        'request_status': status,
        'request_type': type,
      })
      setOrderRequestPopup(true);
    // } else {
    //   dispatch(setalertMessage('You must be logged in.'));
    //   dispatch(setalertMessagetype('error'));
    //   dispatch(SetRedirectPage(window.location.href))
    //   dispatch(setLoginModalOpen(true));
    //   dispatch(setLogInLoading(false));
      
    // }
  }

  /*
   * Handle update state request
   * When user click on ok button from update status confirm popup
   * Get Request object form a state
   * Call UpdateOrderRequest function for update order request status
   */
  const UpdateRequest = async () => {
    let req_Obj = OrderRequestClick;
    // setloading(true)
    UpdateOrderRequest(req_Obj.request_id, req_Obj.request_status)
  }

  /*
   * Call on page load
   * Check order request Id and user id form URL
   * Get Order Request summary using user_id and order_request_id
   * */
  useEffect(() => {
    let order_request_id = params.id;
    let owner_id = params.user_id;

    //if (AuthUserID) {
      LoadOrderRequest(order_request_id, owner_id);
    //}
    // else {

    // }
  }, []); //AuthUserID


  return (
    <>
      <MainLayout>
        {loading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}

        {OrderRequestData != null && OwnerStatus && (
          <>
            <Box className="req-sum-main">
              <Typography
                sx={{
                  color: "#faa61a",
                  fontSize: "clamp(22px, 3vw, 30px) !important",
                  fontFamily: "GothamBold !important",
                }}
              >
                {OrderRequestData["order_type"] == "extend_order" && (
                  <>EXTENDED </>
                )}
                RENTAL SUMMARY
              </Typography>

              <Box className="req-sum-grid">
                <Box className="req-sum-left">
                  <Box className="req-sum-content">
                    <Typography>Rental:</Typography>
                    <Typography>
                      {" "}
                      $
                      {parseFloat(
                        OrderRequestData["order_request_data"]["price"]
                      ).toFixed(2)}
                    </Typography>
                  </Box>

                  <Box className="req-sum-content">
                    <Typography>Security Deposit:</Typography>
                    <Typography>
                      $
                      {parseFloat(
                        OrderRequestData["order_request_data"][
                          "security_deposit"
                        ]
                      ).toFixed(2)}
                    </Typography>
                  </Box>

                  <Box className="req-sum-content">
                    <Typography>Rental Admin Fee:</Typography>
                    <Typography>
                      -$
                      {parseFloat(
                        OrderRequestData["order_request_data"][
                          "admin_commission"
                        ]
                      ).toFixed(2)}
                    </Typography>
                  </Box>

                  <br />
                  {OrderRequestData["order_request_data"]?.[
                    "delivery_required"
                  ] == "1" && (
                    <Box className="req-sum-content">
                      <Typography>Transport:</Typography>
                      <Typography>
                        $
                        {(
                          parseFloat(
                            OrderRequestData["order_request_data"]?.[
                              "delivery_charges"
                            ]
                          ) +
                          parseFloat(
                            OrderRequestData["order_request_data"]?.[
                              "pickup_chages"
                            ]
                          ) +
                          parseFloat(
                            OrderRequestData["order_request_data"]?.[
                              "minimum_delivery_fees"
                            ]
                          )
                        ).toFixed(2)}
                      </Typography>
                    </Box>
                  )}
                  {OrderRequestData["order_type"] != "extend_order" && (
                    <Box className="req-sum-content">
                      <Typography>Transport Admin Fee:</Typography>
                      <Typography>
                        -$
                        {parseFloat(
                          OrderRequestData["order_request_data"][
                            "admin_transport_commission"
                          ]
                        ).toFixed(2)}
                      </Typography>
                    </Box>
                  )}
                  <br />
                  <Box className="req-sum-content">
                    <Typography>Equipment Protection:</Typography>
                    <Typography>Paid by Renter</Typography>
                  </Box>
                </Box>
                <Box className="req-sum-right">
                  <Typography
                    sx={{
                      fontSize: "clamp(16px, 3vw, 20px) !important",
                      fontFamily: "GothamBold !important",
                      color: "#faa61a !important",
                    }}
                  >
                    {OrderRequestData["order_type"] != "extend_order" ? (
                      <>Rental Dates</>
                    ) : (
                      <>Extended Dates</>
                    )}
                  </Typography>

                  <Box>
                    {moment(
                      OrderRequestData["order_request_data"]["start_date"]
                    ).format("MM/DD/YYYY")}
                    &nbsp;
                    {moment(
                      OrderRequestData["order_request_data"]["start_time"],
                      "HH:mm"
                    ).format("hh:mm A")}
                  </Box>

                  <Box>
                    {moment(
                      OrderRequestData["order_request_data"]["end_date"]
                    ).format("MM/DD/YYYY")}
                    &nbsp;
                    {moment(
                      OrderRequestData["order_request_data"]["end_time"],
                      "HH:mm"
                    ).format("hh:mm A")}
                  </Box>
                </Box>

                <Box className="req-sum-total">
                  <Typography
                    sx={{
                      color: "#faa61a !important",
                      fontSize: "clamp(20px, 3vw ,25px) !important",
                      fontFamily: "GothamBold !important",
                    }}
                  >
                    Total :
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff !important",
                      fontSize: "clamp(20px, 3vw ,25px) !important",
                      fontFamily: "GothamBold !important",
                    }}
                  >
                    {/* Add on 18 Jan 2024 MK - new Intl.NumberFormat */}
                    {/* ${parseFloat(OrderRequestData['order_request_data']['total_price']).toFixed(2)} */}
                    {new Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }).format(
                      Number(OrderRequestData["order_request_data"]["price"]) +
                        Number(
                          OrderRequestData["order_request_data"][
                            "security_deposit"
                          ]
                        ) -
                        Number(
                          OrderRequestData["order_request_data"][
                            "admin_commission"
                          ]
                        ) +
                        (OrderRequestData["order_request_data"][
                          "delivery_address"
                        ] == undefined
                          ? 0
                          : Number(
                              OrderRequestData["order_request_data"][
                                "delivery_charges"
                              ]
                            ) +
                            Number(
                              OrderRequestData["order_request_data"][
                                "pickup_chages"
                              ]
                            ) +
                            Number(
                              OrderRequestData["order_request_data"][
                                "minimum_delivery_fees"
                              ]
                            ) -
                            Number(
                              OrderRequestData["order_request_data"][
                                "admin_transport_commission"
                              ]
                            ))
                    )}
                  </Typography>
                </Box>
              </Box>

              <Box className="req-msg">
                <Box className="req-msg-box">
                  <Typography>
                    {OrderRequestData["message"]
                      ? OrderRequestData["message"]
                      : "NA"}

                    {OrderRequestData["status"] == "0" && (
                      <>
                        &nbsp;You can message them by
                        <Link
                          onClick={(e) => {
                            var equipId =
                              OrderRequestData["order_request_data"][
                                "equip_id"
                              ];
                            var senderID = OrderRequestData["recever_id"];
                            var receverID = OrderRequestData["user_id"];
                            var requestType = OrderRequestData["order_type"];

                            var strr = OrderRequestData["message"]
                              ? OrderRequestData["message"]
                              : "NA";
                            var equipName = "";

                            var reciverName = strr.match(
                              new RegExp(", (.*) would like")
                            );
                            reciverName = reciverName[1];

                            if (requestType.trim() != "main_order") {
                              // equipName = strr.match(new RegExp("extend my rental of (.*) until "));
                              // by GD 9 Feb 2024 due to extend rental redirection not working
                              equipName = strr.match(
                                new RegExp(
                                  "would like to extend rental of (.*) until "
                                )
                              );
                              equipName = equipName[1];
                            } else {
                              equipName = strr.match(
                                new RegExp("would like to rent (.*) from ")
                              );
                              equipName = equipName[1];
                            }
                            // console.log("HandleChatwithRenter: " , {equipId, senderID, receverID, reciverName, equipName});

                            HandkeChatwithRenter(
                              equipId,
                              senderID,
                              receverID,
                              reciverName,
                              equipName
                            );
                          }}
                          sx={{ cursor: "pointer" }}
                        >
                          {" "}
                          clicking here
                        </Link>
                        . Or approve this rental by accepting below.
                      </>
                    )}
                  </Typography>
                </Box>

                <Box>
                  {OrderRequestData["status"] == "0" ? (
                    <>
                      {OrderRequestData["expire_at"] && <div style={{color:"#fff",marginBottom:"30px"}}>
                          <span style={{color:"#faa61a"}}>Note: </span>
                          <span>
                          The rental request will automatically cancel if not accepted in next
                          </span>
                          <CountdownTimer expirationDateTime={OrderRequestData["expire_at"]} color={"#faa61a"}/>                     
                        </div>
                      }
                      <Box className="req-msg-btngrp">
                        <Button
                          className="req-reject-btn"
                          onClick={(e) => {
                            HandleRequest(
                              OrderRequestData["id"],
                              "2",
                              "Reject"
                            );
                          }}
                        >
                          Reject
                        </Button>
                        <Button
                          className="req-accpt-btn"
                          onClick={(e) => {
                            HandleRequest(
                              OrderRequestData["id"],
                              "1",
                              "Accept"
                            );
                          }}
                        >
                          Accept
                        </Button>
                      </Box>
                      {/* {OrderRequestData["expire_at"] && (
                        <div
                          className="notification-timer-div"
                          style={{ marginTop: "24px" }}
                        >
                          <span>Note: </span>
                          <span>This request expired on</span>
                          <span>
                            &nbsp;
                            {AuthProfile.length && AuthProfile["timezone"]
                              ? momentTimeZone
                                  .tz(
                                    OrderRequestData["expire_at"],
                                    AuthProfile["timezone"]
                                  )
                                  .format("MM/DD/YY")
                              : moment(OrderRequestData["expire_at"]).format(
                                  "MM/DD/YY"
                                )}
                          </span>
                          <span> at </span>
                          <span>
                            {AuthProfile.length && AuthProfile["timezone"]
                              ? momentTimeZone
                                  .tz(
                                    OrderRequestData["expire_at"],
                                    AuthProfile["timezone"]
                                  )
                                  .format("hh:mm A")
                              : moment(OrderRequestData["expire_at"]).format(
                                  "hh:mm A"
                                )}
                          </span>
                        </div>
                      )} */}
                    </>
                  ) : (
                    <>
                      <Box className="single-btn">
                        {OrderRequestData["status"] == "1" ? (
                          <Button className="req-accpt-btn" sx={{backgroundColor:"#faa61a !important"}}>Rental Accepted</Button>
                        ) : OrderRequestData["status"] == "2" ? (
                          <Button className="req-reject-btn" sx={{backgroundColor:"#faa61a !important"}}>Rental Rejected</Button>
                        ) : OrderRequestData["status"] == "3" ? (
                          <Button className="req-reject-btn">
                            Cancelled By Renter
                          </Button>
                        ) : (
                          <Button className="req-reject-btn">
                            This Rental Request Has Expired
                          </Button>
                        )}
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            </Box>

            <Modal
              keepMounted
              open={OrderRequestPopup}
              onClose={() => setOrderRequestPopup(false)}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"
            >
              <Box
                className="download-popup"
                sx={{
                  ...style,
                  width: matchesMobileX ? "calc(100vw - 100px)" : 400,
                  padding: matchesMobileX ? "20px" : 4,
                  bgcolor: "#2d2d2d",
                  borderRadius: "10px",
                }}
              >
                <IconButton
                  onClick={() => setOrderRequestPopup(false)}
                  sx={{ position: "absolute", right: 0, top: 0, color: "#fff" }}
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  className="download-text"
                  id="keep-mounted-modal-title"
                  component="p"
                  sx={{
                    textAlign: "center",
                    color: "#fff !important",
                    fontSize: "20px !important",
                    fontFamily: "GothamBold !important",
                  }}
                >
                  {OrderRequestPopup &&
                  OrderRequestClick.request_type == "Reject" ? (
                    <>Are you sure you want to REJECT?</>
                  ) : (
                    <>Are you sure you want to ACCEPT?</>
                  )}
                </Typography>

                <Box className="chat-not-me">
                  <Typography>
                    <span>
                      <Box className="chat-btn-grp">
                        <Button
                          className="reject-btn"
                          type="button"
                          variant="contained"
                          onClick={() => {
                            setOrderRequestPopup(false);
                          }}
                          sx={{
                            fontSize: "16px !important",
                            fontFamily: "GothamBold !important",
                          }}
                        >
                          Cancel
                        </Button>

                        <Button
                          className="accept-btn"
                          type="button"
                          variant="contained"
                          onClick={UpdateRequest}
                          sx={{
                            fontSize: "16px !important",
                            fontFamily: "GothamBold !important",
                          }}
                        >
                          YES
                        </Button>
                      </Box>
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Modal>

            <Box>
              <Dialog
                open={paymentExpiredPopup}
                // open={true}
                aria-labelledby="draggable-dialog-title"
              >
                <Box className="session-expire">
                  <DialogContent>
                    <DialogTitle
                      style={{
                        color: "#fff",
                        fontSize: "24px",
                        textAlign: "center",
                        marginTop: "-32px",
                      }}
                    >
                      Error
                    </DialogTitle>
                    <DialogContentText
                      style={{ color: "#fff", fontSize: "18px" }}
                    >
                      {requestExpireMessage
                        ? requestExpireMessage
                        : "This Rental request is no longer valid."}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      className=""
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#de8d14 !important",
                        color: "#fff !important",
                        padding: "6px 50px 6px 50px",
                        borderRadius: "7px",
                        fontFamily: "GothamBold !important",
                        fontSize: "20px !important",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        LoadOrderRequest(OrderRequestClick.request_id,params.user_id)
                        setPaymentExpiredPopup(false);
                        console.log("order request expired");
                      }}
                    >
                      OKAY
                    </Button>
                  </DialogActions>
                </Box>
              </Dialog>
            </Box>
          </>
        )}

        {!OwnerStatus && (
          <Box
            className="req-sum-main"
            sx={{ border: "5px solid #faa61a", borderRadius: "12px" }}
          >
            <Box
              className="req-msg"
              sx={{ backgroundColor: "#2e2e2e !important" }}
            >
              <Box
                className="req-msg-text"
                sx={{ backgroundColor: "#2e2e2e !important" }}
              >
                <Typography
                  sx={{
                    color: "#fff !important",
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                >
                  You must be login with same credentials.
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </MainLayout>
    </>
  );
}
export default OrderRequestSummary