import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#b8b9bbfc", //background.paper
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const ConfirmationModal = ({
  isConfirmationOpen,
  handleConfirmationClose,
  onConfirm,
  message = "",
  loading,
}) => {
  const matchesMobileX = useMediaQuery("(max-width:425px)");
  return (
    <Modal
      keepMounted
      open={isConfirmationOpen}
      onClose={handleConfirmationClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box
        className="download-popup"
        sx={{
          ...style,
          width: matchesMobileX ? "calc(100vw - 100px)" : 400,
          padding: matchesMobileX ? "20px" : 4,
          bgcolor: "#2d2d2d",
          borderRadius: "10px",
        }}
      >
        {loading && (
          <Box>
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer - 1,
              }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        )}
        <IconButton
          onClick={handleConfirmationClose}
          sx={{ position: "absolute", right: 0, top: 0, color: "#fff" }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          className="download-text"
          id="keep-mounted-modal-title"
          component="p"
          sx={{
            textAlign: "center",
            color: "#fff !important",
            fontSize: "20px !important",
            fontFamily: "GothamBold !important",
          }}
        >
          {message}
        </Typography>

        <Box className="chat-not-me">
          <Typography>
            <span>
              <Box className="chat-btn-grp">
                <Button
                  className="reject-btn"
                  type="button"
                  variant="contained"
                  onClick={handleConfirmationClose}
                  sx={{
                    fontSize: "16px !important",
                    fontFamily: "GothamBold !important",
                  }}
                >
                  Cancel
                </Button>

                <Button
                  className="accept-btn"
                  type="button"
                  variant="contained"
                  onClick={onConfirm}
                  sx={{
                    fontSize: "16px !important",
                    fontFamily: "GothamBold !important",
                  }}
                >
                  YES
                </Button>
              </Box>
            </span>
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
