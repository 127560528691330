import React, { useCallback, useEffect, useRef, useState, Dispatch, PropsWithChildren } from "react";
import { Box, IconButton, Link, Modal, Typography, useMediaQuery, Button } from '@mui/material'
import googlePlay from 'assets/images/google-play-badge.png'
import iosBtn from 'assets/images/ios_btn.png'
import { useDispatch, useSelector } from 'react-redux';
import { setModalOpen } from 'redux/redux-slice';
import CloseIcon from '@mui/icons-material/Close'
import siteLogo from 'assets/images/logo.png'
//import QrCode from 'assets/images/app_stpre_code.png'
import QRCode from "react-qr-code";
import { IApiResponseRawData, IStoreValue } from "utils/types";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'rgb(46 46 46)',
  boxShadow: 24,
  p: 4,
}
const DownloadPopup: React.FC = () => {
  const dispatch = useDispatch()
  const matchesMobileX = useMediaQuery('(max-width:425px)')
  const OnelinkQaCode = useSelector((state: IStoreValue) => state.equipmentReducer.qrCodeValue);
  return (
    <>
      <Modal
        keepMounted
        open={true}
        //onClose={() => dispatch(setModalOpen(false))}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popup"
          sx={{
            ...style,
            width: matchesMobileX ? 'calc(100vw - 100px)' : 400,
            padding: matchesMobileX ? '20px' : 4,
          }}>
          {/* <IconButton onClick={() => dispatch(setModalOpen(false))} sx={{ position: 'absolute', right: 0, top: 0, color: '#fff' }}>
        <CloseIcon />
        </IconButton> */}

          <div className="popup-headr-image">
            <img
              src={siteLogo}
              alt='GoEquipMe'
              className='download-popup-logo'
            />
          </div>
          <Typography className="download-text" id="keep-mounted-modal-title" component="p" sx={{ textAlign: 'center' }}>
            Find this listing on the GoEquipMe app.
            Get Renting... Get Listing today!
          </Typography>

          <div className="download-parent">
            <Link
              href="https://onelink.to/a7ptek" //https://onelink.to/ch9mhw
              target="_blank"
              sx={{ textDecoration: 'none' }}
            >
              <Button variant="contained" className="download-app" sx={{ textTransform: 'capitalize' }}>
                Download App
              </Button>
            </Link>
            <Typography className="download-yellow-text" variant='h4'>
              Scan the QR Code
            </Typography>
          </div>
          <div className="app_store_code">
            {/* <img
            src={QrCode}
            alt='GoEquipMe'
            className='app_store_qr'
          /> */}
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100px" }}
              value={OnelinkQaCode}
              viewBox={`0 0 256 256`}
              className='app_store_qr'
            />
            <Typography className="download-text">
              Or search "GoEquipMe" in ...
            </Typography>
          </div>

          <Box
            className="download-buttons"
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              mt: 2,
              flexDirection: matchesMobileX ? 'column' : 'unset',
              alignItems: matchesMobileX ? 'center' : 'unset'
            }}>
            <Link className="download-store"
              href="https://apps.apple.com/us/app/goequipme-peer-to-peer/id1502954874"
              sx={{ paddingRight: 2 }}
              target="_blank"
            >
              <img
                src={iosBtn}
                alt='Google Play Store'
                className='header-mobile-icon'
              />
            </Link>
            <Link className="download-store"
              href="https://play.google.com/store/apps/details?id=com.goequipeme"
              target="_blank"
              sx={{ paddingRight: 2 }}
            >
              <img
                src={googlePlay}
                alt='iOS Store'
                className='header-mobile-icon'
              />
            </Link>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
export default DownloadPopup