import { useState, useEffect } from "react";
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Modal, TextField, Typography, styled, useMediaQuery } from "@mui/material"
import { useDispatch, useSelector } from 'react-redux';
import { EquipmentAPIs } from "../../service/api-service";
import { IApiResponseRawData, IStoreValue } from 'utils/types';
import { setModalOpen, setLoginStatus, setaccessToken, setSessionExpire, setalertMessagetype, setalertMessage, setSocialEmailVerifiedModalOpen, setAuthuser, setLoginModalOpen } from 'redux/redux-slice';
import CloseIcon from '@mui/icons-material/Close'
import { newPhoneFormat } from "utils/commonFxns";
const CssTextField = styled(TextField)({
    "& .MuiInputBase-root": {
        color: "#2e2e2e",
        background: "#fff",
        fontFamily: "GothamBold !important",
        fontSize: "clamp(12px, 3vw, 15px) !important"
        // border: "2px solid #faa61a",
    },
    "& label.Mui-focused": {
        color: "#faa61a",
        fontWeight: "bold"
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#2e2e2e"
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "2px solid #faa61a"
        },
        "&:hover fieldset": {
            borderColor: "#2e2e2e",
            border: "2px solid #faa61a"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#faa61a"
        }
    }
});

const loginstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 4,
}

// const SocialEmailVerifyModal = ({ emailVerifyModelOpen, setEmailVerifyModelOpen, data }) => {
const SocialEmailVerifyModal = ({ emailVerifyModelOpen, setEmailVerifyModelOpen, data }) => {
    // const [PhotosModelOpen , setPhotosModelOpen] = useState(false);
    const dispatch = useDispatch();
    const matchesMobileX = useMediaQuery('(max-width:425px)');
    // const [email, setEmail] = useState((data['email'] && (!data['apple_id'])) ? data['email'] : '');
    const [email, setEmail] = useState((data['email']) ? data['email'] : '');
    const AuthToken = useSelector((state: IStoreValue) => state.equipmentReducer.accessToken);
    // const emailVerifyModelOpen = useSelector((state: IStoreValue) => state.equipmentReducer.socialEmailVerifiedModalOpen);

    // const setEmailVerifyModelOpen = (val) => {
    //     dispatch(setSocialEmailVerifiedModalOpen(val));
    // }
    const [loading, setLoading] = useState(false);

    const extractNumbers = (inputString) => {
        return inputString.replace(/\D/g, "");
    }

    const sendEmailApi = async () => {
        try {
            let mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (email.trim() == '') {
                dispatch(setalertMessagetype("error"));
                dispatch(setalertMessage("Invalid email address!"));
                return;
            };
            if (!email.match(mailformat)) {
                dispatch(setalertMessage("Invalid email address!"));
                dispatch(setalertMessagetype("error"));
                return;
            }
            if (!data['user_id']) {
                dispatch(setalertMessagetype("error"));
                dispatch(setalertMessage("User id is not provided."));
                return;
            }
            setLoading(true);
            // Call the API to get the service categories
            const body = {
                email: email,
                id: data['user_id'].toString(),
                device_type: 'web'
            };

            console.log("body: ", body);
            const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
            const sendEmail = await EquipmentAPIs.socialLoginVerification(body);
            console.log("sendEmail: ", sendEmail);
            if (sendEmail["type"] == "RXSUCCESS") {
                console.log("newData : ", sendEmail);
                localStorage.setItem("token-info", JSON.stringify(sendEmail["data"]));
                dispatch(setaccessToken(sendEmail['data']["auth_token"]));
                dispatch(setAuthuser(sendEmail["data"]["user_id"]));
                dispatch(setalertMessagetype("success"));
                dispatch(setalertMessage("The email has been sent successfully. Please check your inbox."));
                // setEmailVerifyModelOpen(false);
                // setEmail('');
                window.location.href = "/signup";
                setLoading(false);
            } else {
                // Display an error message if deletion fails
                dispatch(setalertMessagetype("error"));
                dispatch(setalertMessage(sendEmail["message"]));
                setLoading(false);
                // setDeletePopup(false)
            }
        } catch (err) {
            // Handle errors that occur during the deletion process
            console.log(err);
            setLoading(false);
            dispatch(setalertMessagetype("error"));
            dispatch(setalertMessage("error while sending email."));
            // setEmailVerifyModelOpen(false);
        }
    };
    useEffect(() => {
        console.log("emailVerifyModelOpen: ", emailVerifyModelOpen);

    }, [emailVerifyModelOpen])
    // return (
    //     <Modal
    //         keepMounted
    //         open={emailVerifyModelOpen}
    //         onClose={() => setEmailVerifyModelOpen(false)}
    //         aria-labelledby="keep-mounted-modal-title"
    //         aria-describedby="keep-mounted-modal-description"
    //     >
    //         <Box
    //             className="download-popups"
    //             sx={{
    //                 ...loginstyle,
    //                 width: matchesMobileX ? 'calc(100vw - 100px)' : 800,
    //                 padding: "0px",
    //             }}>

    //             <IconButton
    //                 className="cross-btn"
    //                 onClick={() => setEmailVerifyModelOpen(false)}
    //             >
    //                 <CloseIcon />
    //             </IconButton>
    //             <Box className="login__main">
    //                 <Box className="login-main-inner">
    //                     <Box className="login__main-div__form popup-cmmn popup-white edit-eqp-inner">
    //                         <Box className="">
    //                             <Box className="">
    //                                 <Typography
    //                                     variant="h4"
    //                                     className="welcome-heading"
    //                                     sx={{ textAlign: "center", color: "#fff" }}
    //                                 >
    //                                 </Typography>

    //                                 <Typography className="second-para" sx={{ fontSize: '20px !important', textAlign: 'center', color: '#fff !important', fontFamily: 'GothamBold !important' }}>
    //                                     Send email to :-
    //                                 </Typography>
    //                                 <Box sx={{ margin: '1rem 0' }}>
    //                                     <CssTextField id="outlined-basic"
    //                                         fullWidth
    //                                         className='sd-input'
    //                                         label=""
    //                                         variant="outlined"
    //                                         placeholder='Please type a message'
    //                                         InputLabelProps={{
    //                                             shrink: false,
    //                                         }}
    //                                         sx={{

    //                                         }}
    //                                         value={messageText}
    //                                         onChange={(e) => setMessageText(e.target.value)}
    //                                     />
    //                                 </Box>
    //                                 <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem', }}>
    //                                     {/* <Button onClick={() => setPhotosModel(false)} sx={{ width: '100%', border: '2px solid #faa61a', color: '#fff', textTransform: 'capitalize', fontFamily: 'GothamBold !important' }}>Cancel</Button> */}
    //                                     <Button sx={{ fontSize: '20px !important', padding: '0.5rem 1rem', width: 'fit-content', background: '#149247 !important', color: '#fff', textTransform: 'capitalize', fontFamily: 'GothamBold !important' }}
    //                                         onClick={() => {
    //                                             console.log("send email working");
    //                                             // sendMessage();
    //                                         }}
    //                                     >
    //                                         Send email
    //                                     </Button>
    //                                 </Box>
    //                             </Box>
    //                         </Box>

    //                     </Box>
    //                 </Box>
    //             </Box>
    //         </Box>
    //     </Modal>
    // )

    return (
        <>
            <Dialog open={emailVerifyModelOpen} aria-labelledby="draggable-dialog-title">
                <Box
                    // className="session-expire" 

                    sx={{
                        background: '#2d2d2d',
                        border: '5px solid #faa61a !important',
                        borderRadius: '5px',
                        padding: '1rem',
                    }}>
                    {loading && (
                        <Box>
                            <Backdrop
                                sx={{
                                    color: "#fff",
                                    zIndex: (theme) => theme.zIndex.drawer + 1,
                                }}
                                open={loading}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        </Box>
                    )}
                    <DialogTitle sx={{ color: "#fff", padding: ' 0', margin: '0rem 0rem 1rem 0rem' }} id="draggable-dialog-title">
                        Please enter email to complete login
                    </DialogTitle>
                    <DialogContent sx={{ padding: ' 0', }}>
                        {/* <DialogContentText sx={{ color: "#fff", fontSize: { xs: '14px', sm: '14px', md: '16px', lg: '20px', xl: '20px' } }}>
                            Are you sure you want to logout?
                        </DialogContentText> */}
                        <Box sx={{ margin: '1rem 0' }}>
                            <CssTextField id="outlined-basic"
                                fullWidth
                                className='sd-input'
                                label=""
                                variant="outlined"
                                placeholder='Please type your Email'
                                InputLabelProps={{
                                    shrink: false,
                                }}
                                sx={{

                                }}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ padding: ' 0', marginTop: '1rem' }}>
                        {/* <Box
                            className="btn-load-more"
                            sx={{
                                color: "#faa61a !important",
                                padding: "6px 40px 6px 40px",
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                            onClick={handleCloseConfirmlogout}
                        >
                            No
                        </Box> */}
                        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem', width: { xs: '100%', sm: '100%', md: '70%', lg: '70%', xl: '70%', margin: 'auto' } }}>
                            <Button sx={{ fontFamily: 'Gotham !important', fontSize: 'clamp(16px, 3vw ,20px) !important', textDecorationLine: 'underline !important', textDecorationColor: '#faa61a !important', color: '#faa61a !important', textTransform: 'capitalize' }}
                                onClick={() => {
                                    dispatch(setLoginStatus(true));
                                    dispatch(setLoginModalOpen(false));
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                className="reset-cal-btn addmoney--checkout-btn new-money-btn transfer-btn"
                                onClick={() => {
                                    console.log("send email working: ");
                                    sendEmailApi();
                                }}
                                sx={{ textTransform: 'capitalize' }}
                            >
                                Submit
                            </Button>
                        </Box>
                        {/* <Box
                            className="btn-load-more"
                            sx={{
                                color: "#faa61a !important",
                                padding: "6px 40px 6px 40px",
                                textAlign: 'center !important',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                            onClick={() => {
                                console.log("send email working: ");
                                sendEmailApi();
                            }}
                        >
                            Send Email
                           
                        </Box> */}

                        {/* <Button onClick={handleCloseConfirmlogout}>
                No
              </Button>
              <Button onClick={handleCloseLogout}>Yes</Button> */}
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}


export default SocialEmailVerifyModal;