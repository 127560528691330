import { Backdrop, Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, IconButton, MenuItem, Modal, Select, TextField, Typography, styled, useMediaQuery } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close'
import { useEffect, useState } from "react";
import { newPhoneFormat } from "utils/commonFxns"


import { EquipmentAPIs } from "../../service/api-service";
import { IApiResponseRawData, IStoreValue } from 'utils/types';
import { useDispatch, useSelector } from 'react-redux';
import { setModalOpen, setLoginStatus, setaccessToken, setSessionExpire, setalertMessagetype, setalertMessage } from 'redux/redux-slice';

const loginstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 4,
};

const CssTextField = styled(TextField)({
    "& .MuiInputBase-root": {
        color: "#2e2e2e",
        background: "#fff",
        fontFamily: "GothamBold !important",
        fontSize: "clamp(12px, 3vw, 15px) !important"
        // border: "2px solid #faa61a",
    },
    "& label.Mui-focused": {
        color: "#faa61a",
        fontWeight: "bold"
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#2e2e2e"
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "2px solid #faa61a"
        },
        "&:hover fieldset": {
            borderColor: "#2e2e2e",
            border: "2px solid #faa61a"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#faa61a"
        }
    }
});


const EditServiceDescriptionModal = ({ loading, setLoading, serviceLiveModalOpen, setServiceLiveModalOpen, serviceData, setServiceData, updateService, myServices }) => {
    const matchesMobileX = useMediaQuery('(max-width:425px)');
    // console.log("service data in edit api: ", serviceData); 

    const dispatch = useDispatch();
    const AuthToken = useSelector((state: IStoreValue) => state.equipmentReducer.accessToken);
    // send to 3 emails 
    const [email1, setEmail1] = useState('');
    const [email2, setEmail2] = useState('');
    const [email3, setEmail3] = useState('');

    const shareLink = async () => {
        try {
            setLoading(true);
            const data = {
                emails: `${email1},${email2},${email3}`,
                link: `Hi, I just found this awesome app where you can rent or list heavy equipment. Check it out!
                                https://onelink.to/ch9mhw`
            }
            // console.log("data in emails : ", data);

            const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
            const sendMails = await EquipmentAPIs.shareWithEmails(data, headers);
            console.log("sendMails: ", sendMails);
            if (sendMails["type"] == "RXSUCCESS") {
                console.log("emails send successfully.");
                // setServiceData({ ...serviceData, service_live_status: "1" });
                // updateService("1");
                updateServiceStatus();
            } else {
                // Display an error message if deletion fails
                // addService();
                dispatch(setalertMessagetype("error"));
                dispatch(setalertMessage("error while adding service."));
                setLoading(false);
            }

        } catch (error) {
            dispatch(setalertMessagetype("error"));
            dispatch(setalertMessage("error while sending emails."));
            setLoading(false);
        }
    }

    const updateServiceStatus = async () => {
        try {
            setLoading(true);
            const data = {
                service_id: serviceData.id
            }
            // console.log("data in emails : ", data);

            const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
            const updateServicee = await EquipmentAPIs.updateServiceLiveStatus(data, headers);
            console.log("updateServicee: ", updateServicee);
            if (updateServicee["type"] == "RXSUCCESS") {
                console.log("service updated successfully.");
                setLoading(false);
                setServiceLiveModalOpen(false);
                dispatch(setalertMessagetype("success"));
                dispatch(setalertMessage("service updated successfully."));
                myServices();
                // setServiceData({ ...serviceData, service_live_status: "1" });
                // updateService("1");
            } else {
                // Display an error message if deletion fails
                // addService();
                dispatch(setalertMessagetype("error"));
                dispatch(setalertMessage("error while making service service."));
                setLoading(false);
            }

        } catch (error) {
            console.log("error: ", error);
            dispatch(setalertMessagetype("error"));
            dispatch(setalertMessage("error while making service service."));
            setLoading(false);
        }
    }

    const areEmailsUnique = (email1, email2, email3) => {
        return email1 !== email2 && email1 !== email3 && email2 !== email3;
    }

    const sendEmails = () => {
        console.log("step three here to hit the api");
        if (!email1 || !email2 || !email3) {
            dispatch(setalertMessagetype("error"));
            dispatch(setalertMessage("Please fill all 3 email fields and emails should be valid."));
            return;
        }

        // if (email1 && email2 && email3) {
        let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isEmail1Valid = emailRegex.test(email1);
        const isEmail2Valid = emailRegex.test(email2);
        const isEmail3Valid = emailRegex.test(email3);
        console.log("email validator", { isEmail1Valid, isEmail2Valid, isEmail3Valid });

        if (!isEmail1Valid || !isEmail2Valid || !isEmail3Valid) {
            console.log("in invalid if");
            dispatch(setalertMessagetype("error"));
            dispatch(setalertMessage("Please enter valid emails."));
            // return false;
        } else {
            if (areEmailsUnique(email1, email2, email3)) {
                console.log("All three emails are unique.");
                console.log("in all valid else");
                setLoading(true);
                shareLink();
            } else {
                console.log("At least two emails are the same.");
                dispatch(setalertMessagetype("error"));
                dispatch(setalertMessage("Please enter different emails."));
                return false;
            }
            // console.log("in all valid else");
            // setLoading(true);
            // shareLink();
        }
    };
    useEffect(() => {
        // console.log("edit Component Modal rendered");
    }, []);

    return (
        <Modal
            keepMounted
            open={serviceLiveModalOpen}
            onClose={() => setServiceLiveModalOpen(false)}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box
                className="download-popups"
                sx={{
                    ...loginstyle,
                    width: matchesMobileX ? 'calc(100vw - 100px)' : 800,
                    padding: "0px",
                }}>

                <IconButton
                    className="cross-btn"
                    onClick={() => setServiceLiveModalOpen(false)}
                >
                    <CloseIcon />
                </IconButton>
                <Box className="login__main">
                    <Box className="login-main-inner">
                        <Box className="login__main-div__form popup-cmmn popup-white edit-eqp-inner">
                            {loading && (
                                <Box>
                                    <Backdrop
                                        sx={{
                                            color: "#000",
                                            zIndex: (theme) => theme.zIndex.drawer - 1,
                                        }}
                                        open={loading}
                                    >
                                        <CircularProgress color="inherit" />
                                    </Backdrop>
                                </Box>
                            )}

                            <Box className="">

                                <Box className="">
                                    <Typography
                                        variant="h5"
                                        // className="welcome-heading"
                                        sx={{ textAlign: "center", color: "#fff" }}
                                    >
                                        SHARE TO 3 EMAILS TO PUBLISH
                                    </Typography>
                                    <Box>
                                        {/* email 1 */}
                                        <FormControl
                                            fullWidth
                                            className="form-fields list-eqp-form-fields"
                                        >
                                            <label className="list-eqp-input-label">
                                                Email 1
                                            </label>
                                            <CssTextField
                                                onChange={(e) => setEmail1(e.target.value)}
                                                id="outlined-basicd"
                                                // label="Listing Title"
                                                variant="outlined"
                                                value={email1}
                                                placeholder="Enter Email Address"
                                            />
                                        </FormControl>

                                        {/* email 2 */}
                                        <FormControl
                                            fullWidth
                                            className="form-fields list-eqp-form-fields"
                                        >
                                            <label className="list-eqp-input-label">
                                                Email 2
                                            </label>
                                            <CssTextField
                                                onChange={(e) => setEmail2(e.target.value)}
                                                id="outlined-basicd"
                                                // label="Listing Title"
                                                variant="outlined"
                                                value={email2}
                                                placeholder="Enter Email Address"
                                            />
                                        </FormControl>

                                        {/* email 3 */}
                                        <FormControl
                                            fullWidth
                                            className="form-fields list-eqp-form-fields"
                                        >
                                            <label className="list-eqp-input-label">
                                                Email 3
                                            </label>
                                            <CssTextField
                                                onChange={(e) => setEmail3(e.target.value)}
                                                id="outlined-basicd"
                                                // label="Listing Title"
                                                variant="outlined"
                                                value={email3}
                                                placeholder="Enter Email Address"
                                            />
                                        </FormControl>

                                    </Box>
                                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', justifyContent: 'center', alignItems: 'center', gap: '2rem', marginTop: '1.5rem', marginBottom: '0.5rem' }}>
                                        <Button onClick={() => setServiceLiveModalOpen(false)} sx={{ borderRadius: '7px', width: '100%', padding: '0.5rem 1rem', background: '#faa61a !important', fontSize: '18px !important', color: '#fff', textTransform: 'capitalize', fontFamily: 'GothamBold !important' }}>Cancel</Button>
                                        <Button sx={{ borderRadius: '7px', fontSize: '18px !important', padding: '0.5rem 1rem', width: '100%', background: '#149247 !important', color: '#fff', textTransform: 'capitalize', fontFamily: 'GothamBold !important' }}
                                            onClick={() => {
                                                console.log("sendEmails Working");
                                                sendEmails();
                                            }}
                                        >
                                            List it
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
};

export default EditServiceDescriptionModal;