import { useState } from "react";
import { Box, Button, IconButton, Modal, TextField, Typography, styled, useMediaQuery } from "@mui/material"
import { useDispatch, useSelector } from 'react-redux';
import { EquipmentAPIs } from "../../service/api-service";
import { IApiResponseRawData, IStoreValue } from 'utils/types';
import { setModalOpen, setLoginStatus, setaccessToken, setSessionExpire, setalertMessagetype, setalertMessage } from 'redux/redux-slice';
import CloseIcon from '@mui/icons-material/Close'
import { newPhoneFormat } from "utils/commonFxns";
const CssTextField = styled(TextField)({
    "& .MuiInputBase-root": {
        color: "#2e2e2e",
        background: "#fff",
        fontFamily: "GothamBold !important",
        fontSize: "clamp(12px, 3vw, 15px) !important"
        // border: "2px solid #faa61a",
    },
    "& label.Mui-focused": {
        color: "#faa61a",
        fontWeight: "bold"
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#2e2e2e"
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "2px solid #faa61a"
        },
        "&:hover fieldset": {
            borderColor: "#2e2e2e",
            border: "2px solid #faa61a"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#faa61a"
        }
    }
});

const loginstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 4,
}

const SendTextMsgModal = ({ textModelOpen, setTextModelOpen, sendTextMobileNo }) => {
    // const [PhotosModelOpen , setPhotosModelOpen] = useState(false);
    const dispatch = useDispatch();
    const matchesMobileX = useMediaQuery('(max-width:425px)');
    const [messageText, setMessageText] = useState('');
    const AuthToken = useSelector((state: IStoreValue) => state.equipmentReducer.accessToken);
    const [loading, setLoading] = useState(false);

    const extractNumbers = (inputString) => {
        return inputString.replace(/\D/g, "");
    }

    const sendMessage = async () => {
        try {
            if (messageText.trim() == '') {
                dispatch(setalertMessagetype("error"));
                dispatch(setalertMessage("Please type a valid message."));
                return;
            };

            setLoading(true);
            // Call the API to get the service categories
            const data = {
                phone: extractNumbers(sendTextMobileNo),
                message: messageText
            };

            console.log("data: ", data);

            const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
            const sendMessage = await EquipmentAPIs.sendTextMsg(data, headers);
            console.log("sendMessage: ", sendMessage);
            if (sendMessage["type"] == "RXSUCCESS") {
                console.log("newData : ", sendMessage);
                dispatch(setalertMessagetype("success"));
                dispatch(setalertMessage("message send successfully."));
                setLoading(false);
                setTextModelOpen(false);
                setMessageText('');
            } else {
                // Display an error message if deletion fails
                dispatch(setalertMessagetype("error"));
                dispatch(setalertMessage("error while sending message."));
                setLoading(false);
                // setDeletePopup(false)
            }
        } catch (err) {
            // Handle errors that occur during the deletion process
            console.log(err);
            setLoading(false);
            setTextModelOpen(false);
        }
    };

    return (
        <Modal
            keepMounted
            open={textModelOpen}
            onClose={() => setTextModelOpen(false)}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box
                className="download-popups"
                sx={{
                    ...loginstyle,
                    width: matchesMobileX ? 'calc(100vw - 100px)' : 800,
                    padding: "0px",
                }}>

                <IconButton
                    className="cross-btn"
                    onClick={() => setTextModelOpen(false)}
                >
                    <CloseIcon />
                </IconButton>
                <Box className="login__main">
                    <Box className="login-main-inner">
                        <Box className="login__main-div__form popup-cmmn popup-white edit-eqp-inner">
                            <Box className="">
                                <Box className="">
                                    <Typography
                                        variant="h4"
                                        className="welcome-heading"
                                        sx={{ textAlign: "center", color: "#fff" }}
                                    >
                                    </Typography>

                                    <Typography className="second-para" sx={{ fontSize: '20px !important', textAlign: 'center', color: '#fff !important', fontFamily: 'GothamBold !important' }}>
                                        Send Message to :-{newPhoneFormat(sendTextMobileNo)}
                                    </Typography>
                                    <Box sx={{ margin: '1rem 0' }}>
                                        <CssTextField id="outlined-basic"
                                            fullWidth
                                            className='sd-input'
                                            label=""
                                            variant="outlined"
                                            placeholder='Please type a message'
                                            InputLabelProps={{
                                                shrink: false,
                                            }}
                                            sx={{

                                            }}
                                            value={messageText}
                                            onChange={(e) => setMessageText(e.target.value)}
                                        />
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem', }}>
                                        {/* <Button onClick={() => setPhotosModel(false)} sx={{ width: '100%', border: '2px solid #faa61a', color: '#fff', textTransform: 'capitalize', fontFamily: 'GothamBold !important' }}>Cancel</Button> */}
                                        <Button sx={{ fontSize: '20px !important', padding: '0.5rem 1rem', width: 'fit-content', background: '#149247 !important', color: '#fff', textTransform: 'capitalize', fontFamily: 'GothamBold !important' }}
                                            onClick={() => {
                                                console.log("send text working");
                                                sendMessage();
                                            }}
                                        >
                                            Send Message
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}


export default SendTextMsgModal;