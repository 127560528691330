import React from 'react'
import MainLayout from '../../layouts/MainLayout'
import UserLoginComponent from 'components/user-login/UserLogin'

/**
 * User Login page.
 */
const UserLogin: React.FC = () => {
  
  return (
    <div style={{backgroundColor:"#c58004"}}>
     {/* <MainLayout insideMainContainer={false}> */}
      <UserLoginComponent />
      </div>
    //  </MainLayout>
  )
}

export default UserLogin