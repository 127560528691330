import {
  Box,
  Button,
  List,
  ListItemButton,
  ListSubheader,
  Typography,
} from "@mui/material";
import React from "react";
import dummyImg from "../../assets/images/jcb.jpg";
import { useSelector } from "react-redux";
import { IStoreValue } from "utils/types";
import moment from "moment";
interface Notifications {
  notification_id: number;
  thumbnail: string;
  created_at: string;
  message:string;
  message_type:string;
  sfn:string;
  type:string;
  send_by:number;
  make:string;
  modal:string;
  notification_status:number;
}
const NotificationsPopOver = () => {
  const NotificationsList:Notifications[] = useSelector(
    (state: IStoreValue) => state.equipmentReducer.NotificationList
  );
  const UnreadNotificationsList = useSelector(
    (state: IStoreValue) => state.equipmentReducer.UnreadNotificationList
  );
  const UserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const notifications:Notifications[] = [...UnreadNotificationsList];
  if(notifications?.length === 0) {
    notifications.push(...NotificationsList.slice(0,5));
  } else if(notifications.length<5) {
    const notificationIds = new Set(notifications.map(notification=>notification?.notification_id))
    for(const notification of NotificationsList) {
      if(notifications.length>=5){
        break;
      }
      if(!notificationIds.has(notification?.notification_id)) {
        notifications.push(notification);
        notificationIds.add(notification.notification_id);
      }
    }
  }

  const HandleChatOwnerToRenter = (chatData:Notifications) => {
    window.location.href = `/notifications?notificationId=${chatData?.notification_id}`;
  };


  return (
    <Box>
      <List
        sx={{ width: "100%", paddingLeft: "0" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            sx={{
              background: "none",
              padding: "0",
              mb: 0,
              color: "#faa61a",
              fontFamily: "GothamBold !important",
              fontSize: "14px",
            }}
          >
            Latest Notifications
          </ListSubheader>
        }
      >
        {notifications.map((notification) => (
          <ListItemButton
            key={notification?.notification_id}
            sx={{
              padding: "5px",
              display: "flex",
              gap: "0.5rem",
              borderRadius: "5px",
              alignItems:'flex-start',
              background:notification?.notification_status>0?"#faa61a36":"transparent",
              "&:hover": { background:"#47463E" },
              mb:1
            }}
            onClick={()=>HandleChatOwnerToRenter(notification)}
          >
            <Box
              component={"img"}
              src={notification?.thumbnail}
              alt={`Notification`}
              sx={{
                width: "40px",
                minWidth:'40px',
                maxWidth:'40px',
                height: "40px",
                minHeight:'40px',
                maxHeight:'40px',
                objectFit: "cover",
                borderRadius: "5px",
                mt:'7px'
              }}
            />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1px" }}>
              <Typography
                sx={{
                  fontSize: "11.5px",
                  fontFamily: "Montserrat !important",
                  fontWeight: "500",
                  wordBreak: "break-word",
                }}
              >
                {/* hamara code */}

                {notification?.type == "chat" &&
                  notification?.message_type != "request" && (
                    <Typography
                      sx={{
                        color: "#149247",
                        // fontSize: "clamp(16px, 3vw, 22px)",
                        fontFamily: "Montserrat !important",
                        fontSize: "12.5px",
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        fontWeight:'600'
                      }}
                    >
                      {UserId != notification?.send_by ? (
                        <>
                          {(notification?.message.includes("would like to") ||
                            notification?.message.includes(
                              "has been Approved"
                            ) ||
                            notification?.message.includes(
                              "has been rejected"
                            )) &&
                          notification?.message_type == "request" ? (
                            <></>
                          ) : (
                            <>Received from {notification?.sfn}</>
                          )}
                        </>
                      ) : (
                        <>
                          {(notification?.message.includes("would like to") ||
                            notification?.message.includes(
                              "has been Approved"
                            ) ||
                            notification?.message.includes(
                              "has been rejected"
                            )) &&
                          notification?.message_type == "request" ? (
                            <></>
                          ) : (
                            <>Sent To {notification?.sfn}</>
                          )}
                        </>
                      )}
                    </Typography>
                  )}
                <Typography
                  sx={{
                    overflow: "hidden",
                    fontFamily: "Montserrat !important",
                    fontSize: "11.5px",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    fontWeight:notification?.notification_status>0?"600":"400"
                  }}
                  title={
                    notification?.message == "Security Refunded for null"
                      ? "Security refunded"
                      : UserId == notification?.send_by &&
                        notification?.message.includes("would like to") &&
                        notification?.message_type == "request"
                      ? `Your ${
                          notification?.message.includes("extend")
                            ? "extend a"
                            : ""
                        } rental request has been submitted for approval.`
                      : notification?.message.includes("would like to") &&
                        notification?.message_type == "request"
                      ? `You have received ${
                          notification?.message.includes("extend")
                            ? "extend"
                            : ""
                        } a rental request for ${notification?.make} from ${
                          notification?.sfn
                        }.`
                      : notification?.message.includes("has been Approved")
                      ? `Your ${
                          notification?.message.includes("extend")
                            ? "extend a"
                            : ""
                        } rental request for ${
                          notification?.make
                        } has been approved by ${notification?.sfn}.`
                      : notification?.message.includes("has been rejected")
                      ? `Your ${
                          notification?.message.includes("extend")
                            ? "extend a"
                            : ""
                        } rental request for ${
                          notification?.make
                        } has been rejected by ${notification?.sfn}.`
                      : notification?.message
                  }
                >
                  {notification?.message == "Security Refunded for null"
                    ? "Security refunded"
                    : UserId == notification?.send_by &&
                      notification?.message.includes("would like to") &&
                      notification?.message_type == "request"
                    ? `Your ${
                        notification?.message.includes("extend")
                          ? "extend a"
                          : ""
                      } rental request has been submitted for approval.`
                    : notification?.message.includes("would like to") &&
                      notification?.message_type == "request"
                    ? `You have received ${
                        notification?.message.includes("extend")
                          ? "extend"
                          : ""
                      } a rental request for ${notification?.make} from ${
                        notification?.sfn
                      }.`
                    : notification?.message.includes("has been Approved")
                    ? `Your ${
                        notification?.message.includes("extend")
                          ? "extend a"
                          : ""
                      } rental request for ${
                        notification?.make
                      } has been approved by ${notification?.sfn}.`
                    : notification?.message.includes("has been rejected")
                    ? `Your ${
                        notification?.message.includes("extend")
                          ? "extend a"
                          : ""
                      } rental request for ${
                        notification?.make
                      } has been rejected by ${notification?.sfn}.`
                    : notification?.message}
                </Typography>

                {/* hamara code end */}
              </Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontStyle: "italic",
                  fontWeight: "300",
                  fontFamily: "Montserrat !important",
                }}
              >
                {moment(notification?.created_at).fromNow()}
              </Typography>
            </Box>
          </ListItemButton>
        ))}
      </List>
      <Button
        sx={{
          fontFamily: "GothamBold !important",
          color: "#fff",
          width: "100%",
          background: "#149247",
          borderRadius: "5px",
          fontSize: "12px",
          "&:hover": {
            background: "#0c7b39",
          },
        }}
        onClick={(event) => {
            event.stopPropagation();
            if(window.location.pathname !== "/notifications") {
                window.location.href = "/notifications"
            }
        }}
      >
        View All
      </Button>
    </Box>
  );
};
export default NotificationsPopOver;
