import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Box, CircularProgress,  Typography, Backdrop, } from '@mui/material';
import ProfilePic from './../../assets/images/dummy-profile-pic.jpeg';

import './styles.scss'

const Userinfo = ({ userData }) => {
    const [loading, setloading] = React.useState(false);
    const [UserInfoData, setUserInfoData] = React.useState(null);

    useEffect(() => {
        if (userData && userData != null) {
            setUserInfoData(userData)           
        }
    }, [userData]);

    return (
        <>
            {loading && (
                <div>
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
            )}

            {UserInfoData != null && (
                <Box
                    // className="rentyrd-dates renter-modal"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem'
                    }}
                >
                    <Box
                    // className="rentyrd-dates-item rental-dates renter-imag-box"

                    >
                    {/* <img src={UserInfoData['profile_pic'] ? UserInfoData['profile_pic'] : ProfilePic}/> */}

                        <Box component={'img'} src={UserInfoData['profile_pic'] ? UserInfoData['profile_pic'] : ProfilePic} alt=''
                            sx={{
                                border: '2px solid #fff',
                                width: '70px',
                                height: '70px',
                                borderRadius: '50%',
                                objectFit: 'cover'
                            }}
                        />
                </Box>
                    <Box
                        // className="rentyrd-dates-item rental-dates renter-text-box"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '2px'
                        }}
                    >
                        <Typography
                            // className='renter-text-p'
                            sx={{
                                color: "#fff",
                                fontFamily: 'Montserrat !important',
                                fontSize: {
                                    xs: '13px',
                                    sm: '14px',
                                    md: '14px',
                                    lg: '15px',
                                    xl: '16px'
                                },
                                fontWeight: '600',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px'
                            }}
                        >
                            Name: 
                            <Typography component={'span'}
                                sx={{
                                    fontSize: {
                                        xs: '13px',
                                        sm: '14px',
                                        md: '14px',
                                        lg: '15px',
                                        xl: '16px'
                                    },
                                    fontWeight: '400',
                                    fontFamily: 'Montserrat !important',
                                }}
                            >{UserInfoData.first_name.charAt(0).toUpperCase() + UserInfoData.first_name.slice(1)} {UserInfoData.last_name}</Typography>
                    </Typography>
                        <Typography // className='renter-text-p'
                            sx={{
                                color: "#fff",
                                fontFamily: 'Montserrat !important',
                                fontSize: {
                                    xs: '13px',
                                    sm: '14px',
                                    md: '14px',
                                    lg: '15px',
                                    xl: '16px'
                                },
                                fontWeight: '600',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px'
                            }}>
                            Email:
                            <Typography component={'span'}
                                sx={{
                                    fontSize: {
                                        xs: '13px',
                                        sm: '14px',
                                        md: '14px',
                                        lg: '15px',
                                        xl: '16px'
                                    },
                                    fontWeight: '400',
                                    fontFamily: 'Montserrat !important',
                                }}
                            > {UserInfoData.email}</Typography>
                    </Typography>
                        <Typography
                            // className='renter-text-p'
                            sx={{
                                color: "#fff",
                                fontFamily: 'Montserrat !important',
                                fontSize: {
                                    xs: '13px',
                                    sm: '14px',
                                    md: '14px',
                                    lg: '15px',
                                    xl: '16px'
                                },
                                fontWeight: '600',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px'
                            }}>
                            Contact No:
                            <Typography component={'span'}
                                sx={{
                                    fontSize: {
                                        xs: '13px',
                                        sm: '14px',
                                        md: '14px',
                                        lg: '15px',
                                        xl: '16px'
                                    },
                                    fontWeight: '400',
                                    fontFamily: 'Montserrat !important',
                                }}
                            > {UserInfoData.phone_no}</Typography>
                    </Typography>
                </Box>
            </Box>
    )}
        </>
    );
}

export default Userinfo