import {
  Box,
  CircularProgress,
  Link,
  Typography,
  Backdrop,
  Button,
  Modal,
  IconButton,
  TextField,
  Checkbox,
  FormGroup,
  styled,
  FormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useRef, useState } from "react";
import {
  setalertMessagetype,
  setalertMessage,
  setSessionExpire,
  setLoginModalOpen,
  setLogInLoading,
} from "redux/redux-slice";
import { useSelector, useDispatch } from "react-redux";
import { IStoreValue } from "utils/types";
import { EquipmentAPIs } from "service/api-service";
import placeholderImg from "assets/images/placeholder.png";
const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4,
};

const CssTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    color: "#2e2e2e",
    background: "#fff",
    fontFamily: "GothamBold !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "& label.Mui-focused": {
    color: "#faa61a",
    fontWeight: "bold",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#2e2e2e",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
    },
    "&:hover fieldset": {
      borderColor: "#2e2e2e",
      border: "2px solid #faa61a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
});
const pickup_time = [];
const pickup_imgs = [];
const PickupDropoffImages = ({
  pickupDropoffpopup,
  handlePickupDropoffClose,
  activeEquipment,
}) => {
  const dispatch = useDispatch();
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [FrontImage, setFrontImage] = useState(null);
  const [BackImage, setBackImage] = useState(null);
  const [LeftImage, setLeftImage] = useState(null);
  const [RightImage, setRightImage] = useState(null);

  const [FrontImageUploadPath, setFrontImageUploadPath] = useState(null);
  const [BackImageUploadPath, setBackImageUploadPath] = useState(null);
  const [LeftImageUploadPath, setLeftImageUploadPath] = useState(null);
  const [RightImageUploadPath, setRightImageUploadPath] = useState(null);

  const FrontImagetRef = useRef(null);
  const BackImageRef = useRef(null);
  const LeftImageRef = useRef(null);
  const RightImageRef = useRef(null);

  const [file, setFile] = useState([]);
  const [HourMilesImages, setHourMilesImages] = useState([]);

  const [Description, setDescription] = useState("");
  const [AdditionalInfo, setAdditionalInfo] = useState("");
  const [NotAvailable, setNotAvailable] = useState(false);
  const [Hours, setHours] = useState(false);
  const [Miles, setMiles] = useState(false);
  const [CurrentReading, setCurrentReading] = useState(0);

  const [PickDropType, setPickDropType] = useState("Pickup");
  const [MoreImgs, setMoreImgs] = useState([]);
  const [HourMilesImgs, setHourMilesImgs] = useState([]);
  const [more_file_res, setmore_file_res] = useState([]);
  const [hour_miles_res, sethour_miles_res] = useState([]);
  const [FinalPopup, setFinalPopup] = useState(false);

  // useEffect(()=>{
  //   if(pickupDropoffpopup) {
  //       console.log("activeEquipment",activeEquipment);

  //       if(activeEquipment["renter_images"]) {
  //           const images = activeEquipment["renter_images"]?.split(",");
  //           if(images && images?.length) {
  //               setPickupImages(images)
  //           }
  //           const timestamp = activeEquipment["renter_pickup_timestamp"]?.split(",");
  //           if(timestamp && timestamp?.length) {
  //               setPickupImagesTimestamp(timestamp);
  //           }
  //       }
  //       if(activeEquipment["return_renter_images"]) {
  //           const images = activeEquipment["return_renter_images"]?.split(",");
  //           if(images && images.length) {
  //               setDropoffImage(images)
  //           }
  //           const timestamp = activeEquipment["renter_return_timestamp"]?.split(",");
  //           if(timestamp && timestamp?.length) {
  //               setDropoffImageTimestamp(timestamp);
  //           }
  //       }

  //   } else {
  //       setPickupImages([]);
  //       setDropoffImage([]);
  //       setPickupImagesTimestamp([]);
  //       setDropoffImageTimestamp([]);
  //   }
  // },[activeEquipment,pickupDropoffpopup])

  const SingleFileUpload = async (fileObj, from) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("image", fileObj);
      formData.append("user_id", AuthUserId.toString());
      const headers = {
        Authorization: `${AuthToken}`,
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        request_from: "Web",
      };
      let c_time = new Date();
      const upload_file = await EquipmentAPIs.UploadImage(formData, headers);
      if (upload_file["type"] == "RXSUCCESS") {
        let uploaded_path = upload_file["data"]["imageUrl"];
        let fileLoaded = URL.createObjectURL(fileObj);
        // pickup_imgs.push(uploaded_path);
        if (from == "Front") {
          pickup_time.push(c_time);
          setFrontImage(fileLoaded);
          setFrontImageUploadPath(uploaded_path);
          FrontImagetRef.current.value = "";
        } else if (from == "Back") {
          pickup_time.push(c_time);
          setBackImage(fileLoaded);
          setBackImageUploadPath(uploaded_path);
          BackImageRef.current.value = "";
        } else if (from == "Right") {
          pickup_time.push(c_time);
          setRightImage(fileLoaded);
          setLeftImageUploadPath(uploaded_path);
          RightImageRef.current.value = "";
        } else if (from == "Left") {
          pickup_time.push(c_time);
          setLeftImage(fileLoaded);
          setRightImageUploadPath(uploaded_path);
          LeftImageRef.current.value = "";
        }
      } else if (
        upload_file["status"] == false &&
        upload_file["slug"] == "Session Expired"
      ) {
        setLoading(false);
        dispatch(setSessionExpire(true));
      } else {
        throw new Error("Failed to upload");
      }
    } catch (err) {
      dispatch(setalertMessage("Failed to upload file!"));
      dispatch(setalertMessagetype("error"));
    } finally {
      setLoading(false);
    }
  };

  const handleSingleImageChange = function (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) {
    console.log("images", e.target.files);
    if (
      e.target.files[0] &&
      (e.target.files[0]?.type == "image/jpg" ||
        e.target.files[0]?.type == "image/jpeg" ||
        e.target.files[0]?.type == "image/png")
    ) {
      SingleFileUpload(e.target.files[0], type);
    } else {
      dispatch(setalertMessage("Invalid selected file type."));
      dispatch(setalertMessagetype("error"));
    }
  };

  // Handle More images
  const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;
    var result = Object.entries(fileList);
    if (result.length > 8) {
      dispatch(setalertMessage("you can upload only 8 images."));
      dispatch(setalertMessagetype("error"));
      return false;
    } else {
      setMoreImgs(result);

      let upload_files = [];
      let oldimgs = file;

      if (oldimgs.length + result.length > 8) {
        dispatch(
          setalertMessage(
            "you can upload only " + (8 - oldimgs.length) + " images."
          )
        );
        dispatch(setalertMessagetype("error"));
        return false;
      }

      if (oldimgs.length > 0) {
        oldimgs.map((item) => {
          upload_files.push(item);
        });
      }

      for (let index = 0; index < result.length; index++) {
        if (
          e.target.files[index].type == "image/jpg" ||
          e.target.files[index].type == "image/jpeg" ||
          e.target.files[index].type == "image/png"
        ) {
          var fileLoaded = URL.createObjectURL(e.target.files[index]);
          upload_files.push(fileLoaded);
        }
      }
      setFile(upload_files);
    }
  };

  const HandleDescription = (event) => {
    let val = event.target.value;
    let len = val.length;

    if (len > 300) {
      let remove_char = len - 300;
      val = val.substring(0, val.length - remove_char);
      setDescription(val);
    } else {
      setDescription(val);
    }
  };

  const HandleNotAvailable = (event) => {
    setNotAvailable(event.target.checked);
  };

  const HandleHourCheck = (event) => {
    setHours(event.target.checked);
    if (event.target.checked) {
      setMiles(false);
    }
  };

  const removeImage = (fileName, i) => {
    var myArray = [...file];
    const index = myArray.indexOf(fileName);
    if (index > -1) {
      myArray.splice(index, 1);
    }
    setFile(myArray);
    setMoreImgs(myArray);
  };

  const HandleMilesCheck = (event) => {
    setMiles(event.target.checked);
    if (event.target.checked) {
      setHours(false);
    }
  };

  const HandleReading = (event) => {
    let val = event.target.value;
    val = val.replace(/\D/g, "");
    setCurrentReading(val);
  };

  const HandleHourMileImageChange = function (
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    const upload_filelist = e.target.files;
    var results = Object.entries(upload_filelist);
    if (results.length > 8) {
      dispatch(setalertMessage("you can upload only 8 images."));
      dispatch(setalertMessagetype("error"));
      return false;
    } else {
      setHourMilesImgs(results);
      let hour_upload_files = [];
      let oldimgs = HourMilesImages;

      if (oldimgs.length + results.length > 8) {
        dispatch(
          setalertMessage(
            "you can upload only " + (8 - oldimgs.length) + " images."
          )
        );
        dispatch(setalertMessagetype("error"));
        return false;
      }

      if (oldimgs.length > 0) {
        oldimgs.map((item) => {
          hour_upload_files.push(item);
        });
      }

      for (let index = 0; index < results.length; index++) {
        if (
          e.target.files[index].type == "image/jpg" ||
          e.target.files[index].type == "image/jpeg" ||
          e.target.files[index].type == "image/png"
        ) {
          var fileLoaded = URL.createObjectURL(e.target.files[index]);
          hour_upload_files.push(fileLoaded);
        }
      }
      setHourMilesImages(hour_upload_files);
    }
  };

  const RemoveHourMileImage = (fileName, i) => {
    var myArray = [...HourMilesImages];
    const indexs = myArray.indexOf(fileName);
    if (indexs > -1) {
      myArray.splice(indexs, 1);
    }
    setHourMilesImages(myArray);
    setHourMilesImgs(myArray);
  };

  const HandleAdditionalInfo = (event) => {
    let val = event.target.value;
    let len = val.length;

    if (len > 300) {
      let remove_char = len - 300;
      val = val.substring(0, val.length - remove_char);
      setAdditionalInfo(val);
    } else {
      setAdditionalInfo(val);
    }
  };

  // Handle Multiple files upload
  const UploadmMultiplefiles = async (img_arr, type) => {
    if (img_arr.length > 0) {
      let upload_files_path = [];

      let promises = img_arr.map(async (imgObg, index) => {
        let file_obj = imgObg[1];
        if (file_obj) {
          try {
            const formData = new FormData();
            formData.append("image", file_obj);
            formData.append("user_id", AuthUserId.toString());
            let access_token = AuthToken;
            const headers = {
              Authorization: `${access_token}`,
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              request_from: "Web",
            };
            const upload_Equip_file = await EquipmentAPIs.UploadImage(
              formData,
              headers
            );
            if (upload_Equip_file["type"] == "RXSUCCESS") {
              var uploaded_path = upload_Equip_file["data"]["imageUrl"];
              upload_files_path.push(uploaded_path);
              pickup_imgs.push(uploaded_path);
              return;
            } else if (
              upload_Equip_file["status"] == false &&
              upload_Equip_file["slug"] == "Session Expired"
            ) {
              dispatch(setalertMessage(upload_Equip_file["slug"]));
              dispatch(setalertMessagetype("error"));

              return false;
            } else {
              dispatch(setalertMessage(upload_Equip_file["message"]));
              dispatch(setalertMessagetype("error"));
              return false;
            }
          } catch (err) {
            dispatch(setalertMessage(err));
            dispatch(setalertMessagetype("error"));

            return false;
          }
        }
      });
      Promise.all(promises).then(() => {
        if (type == "More") {
          setmore_file_res(upload_files_path);
        } else {
          sethour_miles_res(upload_files_path);
        }
      });
    }
  };

  const Submit = async () => {
    try {
      setLoading(true);
      if (
        FrontImageUploadPath == null ||
        BackImageUploadPath == null ||
        LeftImageUploadPath == null ||
        RightImageUploadPath == null
      ) {
        let msg = "images";
        if (FrontImageUploadPath == null) {
          msg = "front side";
        } else if (BackImageUploadPath == null) {
          msg = "back side";
        } else if (LeftImageUploadPath == null) {
          msg = "left side";
        } else if (RightImageUploadPath == null) {
          msg = "right side";
        }

        dispatch(setalertMessage("Please add " + msg + " image to continue."));
        dispatch(setalertMessagetype("error"));
        setLoading(false);
        return false;
      } else if (!Hours && !Miles && !NotAvailable) {
        dispatch(setalertMessage("Please select miles/hours"));
        dispatch(setalertMessagetype("error"));
        setLoading(false);
        return false;
      }

      //Set pickup images
      let more_img = MoreImgs.length;
      let hour_miles_img = HourMilesImgs.length;

      /************/
      if (more_img > 0 || hour_miles_img > 0) {
        UploadmMultiplefiles(MoreImgs, "More").then(
          (onResolved) => {
            if (hour_miles_img > 0) {
              UploadmMultiplefiles(HourMilesImgs, "Hour").then(
                (onResolved) => {
                  handlePickupDropoffClose();
                  setFinalPopup(true);
                  setLoading(false);
                },
                (onRejected) => {
                  setLoading(false);
                  // Some task on failure
                }
              );
            } else {
              handlePickupDropoffClose();
              setFinalPopup(true);
              setLoading(false);
            }
          },
          (onRejected) => {
            setLoading(false);
            // Some task on failure
          }
        );
      } else {
        handlePickupDropoffClose();
        setFinalPopup(true);
        setLoading(false);
      }
      /************/
    } catch (err) {
      setLoading(false);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // All values Reset
  const Restval = () => {
    setFrontImageUploadPath(null);
    setBackImageUploadPath(null);
    setLeftImageUploadPath(null);
    setRightImageUploadPath(null);

    setFrontImage(null);
    setBackImage(null);
    setLeftImage(null);
    setRightImage(null);
    setFile([]);
    setHourMilesImages([]);
    setDescription("");
    setAdditionalInfo("");
    setNotAvailable(false);
    setHours(false);
    setMiles(false);
    setCurrentReading(0);
    setMoreImgs([]);
    setHourMilesImgs([]);
    setmore_file_res([]);
    sethour_miles_res([]);
    setFinalPopup(false);
    pickup_time.length = 0;
  };

  const FinalSubmit = async () => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `${AuthToken}`,
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        request_from: "Web",
      };

      /********************* */
      let Front = FrontImageUploadPath;
      let Back = BackImageUploadPath;
      let Right = LeftImageUploadPath;
      let Left = RightImageUploadPath;
      let _imgs = [Front, Back, Right, Left];
      let final_imgs = _imgs.concat(more_file_res);
      /********************* */

      //let final_imgs = pickup_imgs.concat(more_file_res);
      let field_description = Description;
      let field_additional_info = AdditionalInfo;
      let body = {
        user_id: AuthUserId.toString(),
        order_id: activeEquipment["order_id"]?.toString(),
        owner_id: activeEquipment["owner_id"].toString(),
        images: final_imgs.toString(),
        description: field_description,
        hrs_miles_available: NotAvailable ? "1" : "0",
        hours: Hours ? "1" : "0",
        miles: Miles ? "1" : "0",
        hour_miles_images: hour_miles_res.toString(),
        reading: CurrentReading,
        renter_additional_info: field_additional_info,
      };
      let Result = "";
      if (PickDropType == "Pickup") {
        body["pickup_timestamp"] = pickup_time.join(",");
        Result = await EquipmentAPIs.PickUp(body, headers);
      } else {
        body["return_timestamp"] = pickup_time.join(",");
        Result = await EquipmentAPIs.DropOff(body, headers);
      }
      console.log("final submission body", body);

      if (Result["type"] == "RXSUCCESS") {
        dispatch(setalertMessage(Result["message"]));
        dispatch(setalertMessagetype("success"));
        Restval();
        setFinalPopup(false);
        setLoading(false);
      } else {
        setLoading(false);
        setFinalPopup(false);
        dispatch(setalertMessage(Result["message"]));
        dispatch(setalertMessagetype("error"));
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <>
      <Modal
        keepMounted
        open={pickupDropoffpopup}
        onClose={handlePickupDropoffClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton className="cross-btn" onClick={handlePickupDropoffClose}>
            <CloseIcon />
          </IconButton>

          <Box 
          className="login__main"
          >
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    background: "#484840",
                    borderBottom: 1,
                    borderColor: "divider",
                    p: 1,
                    borderRadius: "7px",
                  }}
                >
                  <Button
                    sx={{
                      fontSize: {
                        xs: "13px",
                        sm: "14px",
                        md: "15px",
                        lg: "16px",
                        xl: "18px",
                      },
                      textTransform: "capitalize",
                      width: "100%",
                      background:
                        PickDropType === "Pickup" ? "#faa61a" : "transparent",
                      color: "#fff",
                      fontFamily: "Montserrat !important",
                      fontWeight: "800",
                      "&:hover": {
                        background:
                          PickDropType === "Pickup" ? "#faa61a" : "transparent",
                        outline: "1.5px solid #faa61a",
                      },
                    }}
                    onClick={() => {
                      setPickDropType("Pickup")
                    }}
                  >
                    Pickup Photos
                  </Button>
                  <Button
                    sx={{
                      fontSize: {
                        xs: "13px",
                        sm: "14px",
                        md: "15px",
                        lg: "16px",
                        xl: "18px",
                      },
                      textTransform: "capitalize",
                      width: "100%",
                      background:
                        PickDropType === "Dropoff" ? "#faa61a" : "transparent",
                      color: "#fff",
                      fontFamily: "Montserrat !important",
                      fontWeight: "800",
                      "&:hover": {
                        background:
                          PickDropType === "Dropoff"
                            ? "#faa61a"
                            : "transparent",
                        outline: "1.5px solid #faa61a",
                      },
                    }}
                    onClick={() => setPickDropType("Dropoff")}
                  >
                    Dropoff Photos
                  </Button>
                </Box>

                <Box>
                  {loading && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}
                  <Box className="drp-off-img">
                    <Box className="drp-off-grid">
                      <Box className="drp-off-item">
                        <Typography className="drp-off-item-heading">
                          Front
                        </Typography>
                        <Box className="drp-off-item-inner">
                          <img
                            className="drp-off-img"
                            src={FrontImage ? FrontImage : placeholderImg}
                            alt=""
                          />
                          <Button
                            variant="contained"
                            component="label"
                            className="drp-off-btn"
                          >
                            <input
                              id="photo"
                              name="photo"
                              type="file"
                              ref={FrontImagetRef}
                              onChange={(event) => {
                                handleSingleImageChange(event, "Front");
                              }}
                              hidden
                              accept="image/jpg, image/jpeg, image/png"
                            />
                          </Button>
                        </Box>
                      </Box>
                      <Box className="drp-off-item">
                        <Typography className="drp-off-item-heading">
                          Back
                        </Typography>
                        <Box className="drp-off-item-inner">
                          <img
                            className="drp-off-img"
                            src={BackImage ? BackImage : placeholderImg}
                            alt=""
                          />
                          <Button
                            variant="contained"
                            component="label"
                            className="drp-off-btn"
                          >
                            <input
                              id="photo"
                              name="photo"
                              type="file"
                              ref={BackImageRef}
                              onChange={(event) =>
                                handleSingleImageChange(event, "Back")
                              }
                              hidden
                              accept="image/jpg, image/jpeg, image/png"
                            />
                          </Button>
                        </Box>
                      </Box>
                      <Box className="drp-off-item">
                        <Typography className="drp-off-item-heading">
                          Right
                        </Typography>
                        <Box className="drp-off-item-inner">
                          <img
                            className="drp-off-img"
                            src={RightImage ? RightImage : placeholderImg}
                            alt=""
                          />
                          <Button
                            variant="contained"
                            component="label"
                            className="drp-off-btn"
                          >
                            <input
                              id="photo"
                              name="photo"
                              type="file"
                              ref={RightImageRef}
                              onChange={(event) =>
                                handleSingleImageChange(event, "Right")
                              }
                              hidden
                              accept="image/jpg, image/jpeg, image/png"
                            />
                          </Button>
                        </Box>
                      </Box>
                      <Box className="drp-off-item">
                        <Typography className="drp-off-item-heading">
                          Left
                        </Typography>
                        <Box className="drp-off-item-inner">
                          <img
                            className="drp-off-img"
                            src={LeftImage ? LeftImage : placeholderImg}
                            alt=""
                          />
                          <Button
                            variant="contained"
                            component="label"
                            className="drp-off-btn"
                          >
                            <input
                              id="photo"
                              name="photo"
                              type="file"
                              ref={LeftImageRef}
                              onChange={(event) =>
                                handleSingleImageChange(event, "Left")
                              }
                              hidden
                              accept="image/jpg, image/jpeg, image/png"
                            />
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <CssTextField
                    fullWidth
                    placeholder="Add Additional Information"
                    multiline
                    maxRows={4}
                    onChange={HandleDescription}
                    value={Description}
                  />
                  <Box>
                    <Button
                      variant="text"
                      component="label"
                      className="add-more-upload-btn"
                      sx={{ color: "#fff !important" }}
                    >
                      Click to add more images
                      <input
                        id="photo"
                        name="photo"
                        type="file"
                        multiple={true}
                        onChange={handleImageChange}
                        hidden
                        accept="image/jpg, image/jpeg, image/png"
                      />
                    </Button>
                    <Box className="addmore-img-grid">
                      {file.map((image, i) => (
                        <Box className="addmore-img-grid-item-box">
                          <img
                            className="addmore-img-grid-item"
                            src={image}
                            alt=""
                          />
                          <IconButton
                            className="cross-btn cross-btn-3"
                            onClickCapture={(i) => {
                              removeImage(image, i);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <Typography className="drp-off-item-heading">
                    Add Hours/Miles
                  </Typography>
                  <FormGroup>
                    <Box className="checbox-main-div">
                      <Box
                        className="checbox-div"
                        sx={{ color: "#fff !important" }}
                      >
                        <Checkbox
                          className="checkbox-label"
                          checked={NotAvailable}
                          onChange={HandleNotAvailable}
                        />
                        <label>Not Available</label>
                      </Box>

                      {!NotAvailable && (
                        <>
                          <Box
                            className="checbox-div"
                            sx={{ color: "#fff !important" }}
                          >
                            <Checkbox
                              className="checkbox-label"
                              checked={Hours}
                              onChange={HandleHourCheck}
                            />
                            <label>Hours</label>
                          </Box>
                          <Box
                            className="checbox-div"
                            sx={{ color: "#fff !important" }}
                          >
                            <Checkbox
                              className="checkbox-label"
                              checked={Miles}
                              onChange={HandleMilesCheck}
                            />
                            <label>Miles</label>
                          </Box>
                        </>
                      )}
                    </Box>
                  </FormGroup>
                  {!NotAvailable && (
                    <>
                      <Box className="drp-off-reading">
                        <Typography
                          className=""
                          sx={{ color: "#fff !important" }}
                        >
                          Current Reading
                        </Typography>
                        <CssTextField
                          placeholder="Ex. 22222"
                          type="text"
                          onChange={HandleReading}
                          value={CurrentReading}
                        />
                      </Box>

                      <Box>
                        <Button
                          variant="text"
                          component="label"
                          className="add-more-upload-btn"
                          sx={{ color: "#fff !important" }}
                        >
                          Click to add Hours/Miles images
                          <input
                            id="photo"
                            name="photo"
                            type="file"
                            multiple={true}
                            onChange={HandleHourMileImageChange}
                            hidden
                            accept="image/jpg, image/jpeg, image/png"
                          />
                        </Button>
                        <Box className="addmore-img-grid">
                          {HourMilesImages.map((images, i) => (
                            <Box className="addmore-img-grid-item-box">
                              <img
                                className="addmore-img-grid-item"
                                src={images}
                                alt=""
                              />
                              <IconButton
                                className="cross-btn cross-btn-3"
                                onClickCapture={(i) => {
                                  RemoveHourMileImage(images, i);
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </>
                  )}
                  <Button
                    className="signin-btn"
                    type="button"
                    //fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                    onClick={Submit}
                  >
                    Upload
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="signin-btn"
                    type="button"
                    variant="contained"
                    sx={{ mt: 3, mb: 2, backgroundColor: "#faa61a !important" }}
                    onClick={handlePickupDropoffClose}
                  >
                    Skip
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={FinalPopup}
        onClose={() => setFinalPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setFinalPopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
              >
                <Box>
                  <Typography variant="h5" style={{ color: "#faa61a" }}>
                    Additional Info
                  </Typography>
                  {loading && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}
                  <CssTextField
                    fullWidth
                    placeholder="Add Information"
                    multiline
                    minRows={4}
                    value={AdditionalInfo}
                    onChange={HandleAdditionalInfo}
                  />
                  <Button
                    className="signin-btn"
                    type="button"
                    variant="contained"
                    sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                    onClickCapture={FinalSubmit}
                  >
                    Save
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="signin-btn"
                    type="button"
                    variant="contained"
                    sx={{ mt: 3, mb: 2, backgroundColor: "#faa61a !important" }}
                    onClickCapture={FinalSubmit}
                  >
                    Skip
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default PickupDropoffImages;
