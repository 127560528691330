



import {
    Box,
    Button,
    FormControl,
    Link,
    TextField,
    Typography,
    useMediaQuery,
    Slider,
    Backdrop,
    CircularProgress,  
    Modal,
    IconButton,
    FormLabel,
    ToggleButtonGroup,
    ToggleButton
} from "@mui/material";
import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
    Dispatch
} from "react";
import "./styles.scss";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import "react-image-gallery/styles/css/image-gallery.css";
//import ImageGallery from "react-image-gallery";

//import LocationIcon from "assets/images/location.png";
import leftarrow from "assets/images/myofficetrailer/right_yellow.png";
//import ThumbsImg from "assets/images/likeMe.png";

// import msgg from "assets/images/details-page/msgg.png";
// import visit from "assets/images/details-page/visit.png";

// import EquipProtection from "assets/images/details-page/EquipmentProtection.png";
// import calendarimg from "assets/images/details-page/calendar_two.png";
// import bookingimg from "assets/images/details-page/booking2.png";
// import addImg from "assets/images/details-page/Add.png";
// import Img from "assets/images/dummy-profile-pic.jpeg";
// import MainLayout from "layouts/MainLayout";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import AddBalance from "../../components/AddBalance/AddBalance";

/**
 * Changes for card payment holding
 * Section start
 */
import PaymentHold from "../../components/AddBalance/PaymentHold";
import CloseIcon from "@mui/icons-material/Close";
/**
 * Changes for card payment holding
 * Section end
 */

import {
    setModalOpen,
    setalertMessagetype,
    setalertMessage,
    // setLogInLoading,
    // setLoginStatus,
    // setaccessToken,
    // setLoginModalOpen,
    setSessionExpire,
    // setQrCodeValue,
    // setWalletBalance,
    // SetRedirectPage
} from "redux/redux-slice";
import { EquipmentAPIs } from "../../service/api-service";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import moment from "moment";
//import DatePicker from "react-multi-date-picker";
import { Calendar as NewCalendar } from "react-multi-date-picker";
// import radioOff from "assets/images/radioOff.png";
// import radioOn from "assets/images/radioOn.png";
// import GOOGLELOCATION from "../../components/equipment-list/GoogleautoComplete";

// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { Calendar } from "react-modern-calendar-datepicker";
import "./calendar.scss";
// import { ReadableStreamDefaultController } from "stream/web";
// import UserInfo from "./../../components/UserInfo/Userinfo";
// import CloseIcon from "@mui/icons-material/Close";
// import DetailComponent from "../../components/EquipDetails/EquipDetail";

// import DatePicker, { DateObject } from "react-multi-date-picker";

import momentTimeZone from "moment-timezone";
import { convertTimeZoneShortName } from "utils/commonFxns";

const timeArr = [
    "12:30 AM",
    "01:00 AM",
    "01:30 AM",
    "02:00 AM",
    "02:30 AM",
    "03:00 AM",
    "03:30 AM",
    "04:00 AM",
    "04:30 AM",
    "05:00 AM",
    "05:30 AM",
    "06:00 AM",
    "06:30 AM",
    "07:00 AM",
    "07:30 AM",
    "08:00 AM",
    "08:30 AM",
    "09:00 AM",
    "09:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "01:00 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 PM",
    "04:00 PM",
    "04:30 PM",
    "05:00 PM",
    "05:30 PM",
    "06:00 PM",
    "06:30 PM",
    "07:00 PM",
    "07:30 PM",
    "08:00 PM",
    "08:30 PM",
    "09:00 PM",
    "09:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
    "12:00 AM"
];

const myCustomLocale = {
    months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ],

    weekDays: [
        {
            name: "Sunday",
            short: "Sun",
            isWeekend: true,
        },
        {
            name: "Monday",
            short: "Mon"
        },
        {
            name: "Tuesday",
            short: "Tue"
        },
        {
            name: "Wednesday",
            short: "Wed"
        },
        {
            name: "Thursday",
            short: "Thu"
        },
        {
            name: "Friday",
            short: "Fri"
        },
        {
            name: "Saturday",
            short: "Sat",
            isWeekend: true
        }
    ],

    weekStartingIndex: 0,

    getToday(gregorainTodayObject) {
        return gregorainTodayObject;
    },

    toNativeDate(date) {
        return new Date(date.year, date.month - 1, date.day);
    },

    getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
    },

    transformDigit(digit) {
        return digit;
    },

    // texts in the date picker
    nextMonth: "Next Month",
    previousMonth: "Previous Month",
    openMonthSelector: "Open Month Selector",
    openYearSelector: "Open Year Selector",
    closeMonthSelector: "Close Month Selector",
    closeYearSelector: "Close Year Selector",
    defaultPlaceholder: "Select...",
    // for input range value
    from: "from",
    to: "to",
    // used for input value when multi dates are selected
    digitSeparator: ",",
    // if your provide -2 for example, year will be 2 digited
    yearLetterSkip: 0,
    // is your language rtl or ltr?
    isRtl: false
};

const loginstyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    p: 4
};

let nowdata = moment().format("YYYY-MM-DD");

let trans_option_sel = null;
const ExtendRental = ({ ActiveOrder = {}, Result ,setLocal=false}) => {
    const [DeviceType, setDeviceType] = useState("large");
    const inRef = useRef(null);
    const searchRef = useRef();
    const datePickerCalendar = useRef(null);
    const APIKEY = process.env.REACT_APP_GEOCODDER_KEY;
    const AuthToken = useSelector(
        (state: IStoreValue) => state.equipmentReducer.accessToken
    );
    const WalletBalance = useSelector(
        (state: IStoreValue) => state.equipmentReducer.WalletBalance
    );
    const AuthUserID = useSelector(
        (state: IStoreValue) => state.equipmentReducer.Authuser
    );
    const isLoggedin = useSelector(
        (state: IStoreValue) => state.equipmentReducer.isLogin
    );

    const AuthProfileData = useSelector((state: IStoreValue) => state.equipmentReducer.AuthProfileData)

    const [ProfilePicStatus, setProfilePicStatus] = React.useState(null);
    const [LicenceStatus, setLicenceStatus] = React.useState(null);


    const matchesMobileX = useMediaQuery("(max-width:425px)");
    const [loading, setLoading] = useState(true);
    const [OwnerInfo, setOwnerInfo] = useState(false);
    const [placeOrder, setplaceOrder] = useState(false);
    const [result, setResult] = useState({});
    const [img, setImg] = useState([]);
    const [images_gallerys, setimages_gallerys] = useState([]);

    const [blockdate, setBlockdate] = useState([ActiveOrder['end_date']]);
    const [Maplocation, setMaplocation] = useState("");
    const [mapLink, setMapLink] = useState("javascript:void(0)");
    const params = useParams();
    const [rescount, setRescount] = useState(0);
    const dispatch = useDispatch();
    const [deeplink, setDeeplink] = useState([]);
    //    const [ChaeckDatesBlockArr, setChaeckDatesBlockArr] = useState([]);

    //Display States for extend
    const [ExStartDate, setExStartDate] = useState("");
    const [ExEndDate, setExEndDate] = useState("");
    const [ExStTime, setExStTime] = useState("08:00 AM");
    const [ExEnTime, setExEnTime] = useState("05:00 PM");

    console.log("ExEndDate",ExEndDate)
    const [rentStartDate, setrentStartDate] = useState("");
    const [rentEndDate, setrentEndDate] = useState("");
    const [startTime, setStartTime] = useState("08:00 AM");
    const [endTime, setEndTime] = useState("05:00 PM");
    const [rentPrice, setRentPrice] = useState(null);
    const [securityDeposit, setSecurityDeposit] = useState(0);
    const [equipProtection, setEquipProtection] = useState(0);
    const [rentalTotal, setRentalTotal] = useState(0);
    const [chooseTransport, setchooseTransport] = React.useState(null);
    const [transportTotal, settransportTotal] = useState(0);

    /**
     * Changes for card payment holding
     * Section start
     */
    //use for pay order request amount with card
    const [payWithCard, setpayWithCard] = React.useState(false);
    const [holdAmount, setholdAmount] = React.useState(0);
    const [orderRequestData, setorderRequestData] = React.useState({});
    
    /**
     * Changes for card payment holding
     * Section end
     */


    const [values, setValues] = useState(blockdate);
    const [mobilecalendarstartDate, setmobilecalendarstartDate] = useState(null);
    const [mobilecalendarendDate, setmobilecalendarendDate] = useState(null);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [Distance, setDistance] = useState(0);
    const [TotalDays, setTotalDays] = useState("0");
    const [grandTotal, setgrandTotal] = useState(0);
    const [deliveryPrice, setdeliveryPrice] = useState(0);
    const [FullAddress, setFullAddress] = useState({
        city: "",
        state: "",
        country: "",
        postal_code: "",
        address: ""
    });
    const [deliveryerror, setdeliveryerror] = useState(false);
    const [checkoutsection, setcheckoutsection] = useState(false);
    const [proceedpaysection, setproceedpaysection] = useState(false);

    const [transportcalculating, settransportcalculating] = React.useState(false);
    const defaultdeliveryLocation = {
        lat: 39.321980897034734,
        lng: -111.10149320091872
    };
    const [deliveryLocation, setdeliveryLocation] = React.useState(
        defaultdeliveryLocation
    );
    const [DeliveryInstructionsval, setDeliveryInstructionsval] = useState("");
    const [DeliveryInstructionsvallength, setDeliveryInstructionsvallength] =
        useState(400);

    const [LoadWalletBalance, setLoadWalletBalance] = useState(false);
    const [addbalancebox, setaddbalancebox] = React.useState(false);
    const [balanceamount, setbalanceamount] = React.useState("");
    const [balancecard, setbalancecard] = React.useState(null);
    const [balancecardholdername, setbalancecardholdername] =
        React.useState(null);
    const [balancecardexpmonth, setbalancecardexpmonth] = React.useState(null);
    const [balancecardexpyear, setbalancecardexpyear] = React.useState(null);
    const [balancecardcvv, setbalancecardcvv] = React.useState(null);
    const [dateselectsection, setdateselectsection] = React.useState(false);
    const [rentersummaymobile, setrentersummaymobile] = React.useState(false);
    const [tabviewcalendar, settabviewcalendar] = React.useState(1);
    const [LikeStatus, setLikeStatus] = React.useState(null);
    const [LikeLoading, setLikeLoading] = React.useState(false);

    const hoursArray = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12"
    ];

    const [startHours, setStartHours] = useState(7);
    const [startMinutes, setStartMinutes] = useState("00");
    const [endHours, setEndHours] = useState(4);
    const [endMinutes, setEndMinutes] = useState("00");
    const [selectedStartTime, setSelectedStartTime] = useState("AM");
    const [selectedEndTime, setSelectedEndTime] = useState("PM");
    const [autoCompAddress, setAutoCompAddress] = useState("");


    const [ExtendRentalSummary, setExtendRentalSummary] = useState(false);
    const [APICallStatus, setAPICallStatus] = useState(false);
    const [MaxDate, setMaxDate] = useState([]);
    const [calenderMinDate,setCalenderMinDate] = useState(new Date())

    const [timeZone, setTimeZone] = useState("")

    const handleStartEndTime = (event, key) => {
        event.stopPropagation();
        switch (key) {
            // case "startHoursPlus": {
            //     setStartTime(
            //         `${startHours === 11 ? hoursArray[0] : hoursArray[startHours + 1]
            //         }:${startMinutes} ${selectedStartTime}`
            //     );
            //     startHours === 11 ? setStartHours(0) : setStartHours(startHours + 1);
            //     break;
            // }

            // case "startHoursMinus": {
            //     setStartTime(
            //         `${startHours === 0 ? hoursArray[11] : hoursArray[startHours - 1]
            //         }:${startMinutes} ${selectedStartTime}`
            //     );
            //     startHours === 0 ? setStartHours(11) : setStartHours(startHours - 1);
            //     break;
            // }

            // case "startMinutesToggle": {
            //     setStartTime(
            //         `${hoursArray[startHours]}:${startMinutes === "00" ? "30" : "00"
            //         } ${selectedStartTime}`
            //     );
            //     setStartMinutes(startMinutes === "00" ? "30" : "00");
            //     break;
            // }

            // case "toggleStartTime": {
            //     setStartTime(
            //         `${hoursArray[startHours]}:${startMinutes} ${selectedStartTime === "AM" ? "PM" : "AM"
            //         }`
            //     );
            //     setSelectedStartTime(selectedStartTime === "AM" ? "PM" : "AM");
            //     break;
            // }

            case "endHoursPlus": {
                setEndTime(
                    `${endHours === 11 ? hoursArray[0] : hoursArray[endHours + 1]
                    }:${endMinutes} ${selectedEndTime}`
                );
                endHours === 11 ? setEndHours(0) : setEndHours(endHours + 1);
                break;
            }

            case "endHoursMinus": {
                setEndTime(
                    `${endHours === 0 ? hoursArray[11] : hoursArray[endHours - 1]
                    }:${endMinutes} ${selectedEndTime}`
                );
                endHours === 0 ? setEndHours(11) : setEndHours(endHours - 1);
                break;
            }

            case "endMinutesToggle": {
                setEndTime(
                    `${hoursArray[endHours]}:${endMinutes === "00" ? "30" : "00"
                    } ${selectedEndTime}`
                );
                setEndMinutes(endMinutes === "00" ? "30" : "00");
                break;
            }

            case "toggleEndTime": {
                setEndTime(
                    `${hoursArray[endHours]}:${endMinutes} ${selectedEndTime === "AM" ? "PM" : "AM"
                    }`
                );
                setSelectedEndTime(selectedEndTime === "AM" ? "PM" : "AM");
                break;
            }
            default: {
                return;
            }
        }
    };

    var images = [];
    var images_gallery = [];

    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    const minimumDate = {
        year: year,
        month: month,
        day: day
    };
    const defaultRange = {
        from: null,
        to: null
    };
    const [selectedDay, setselectedDay] = useState(minimumDate);
    const [selectedDayRange, setSelectedDayRange] = useState(defaultRange);

    // Calendar Popup
    const [CalendarPopup, setCalendarPopup] = useState(false);
    const [CalendarPopupResult, setCalendarPopupResult] = useState({
        start: null,
        end: null
    });
    // Transaport option popup
    const [transportoptionpopup, settransportoptionpopup] = useState(false);

    const [ExtendRentalRequestpopup, setExtendRentalRequestpopup] = useState(false);


    // Remove AM or PM from time string
    const RemoveAMPMandTimeFormat = (time_str) => {
        let am_check = time_str.includes("AM");
        if (am_check) {
            time_str = time_str.slice(0, -3);
        }
        let pm_check = time_str.includes("PM");
        if (pm_check) {
            time_str = time_str.slice(0, -3);
            var new_time_arr = time_str.split(":");
            var new_time = parseInt(new_time_arr[0]);
            if (new_time_arr[0] != 12 || new_time_arr[0] != "12") {
                new_time = new_time + 12;
            }
            time_str = new_time + ":" + new_time_arr[1];
        }
        return time_str;
    };

    // Calculate Rental price
    const calculatePrice = (__rentStartDate, __rentEndDate) => {

        if (__rentStartDate && __rentEndDate) {


            const startTime = moment(ActiveOrder['end_time'], "HH:mm:ss").format("hh:mm");

            var _startTime = RemoveAMPMandTimeFormat(startTime);
            var _endTime = RemoveAMPMandTimeFormat(endTime);
            __rentEndDate = moment(__rentEndDate).format("YYYY/MM/DD");
            __rentStartDate = moment(__rentStartDate).format("YYYY/MM/DD");
            var _rentStartDate = __rentStartDate.split("/").join("-");
            var _rentEndDate = __rentEndDate.split("/").join("-");

            if (_rentStartDate == _rentEndDate && _endTime < _startTime) {
                dispatch(setalertMessage("The selected pickup time is invalid."));
                dispatch(setalertMessagetype("error"));
                setRentPrice(0);
                setSecurityDeposit(0);
                setEquipProtection(0);
                setRentalTotal(0);
                setdeliveryPrice(0);
                setgrandTotal(0);
                return false;
            }


            var st = moment(_rentStartDate + " " + _startTime);
            var en = moment(_rentEndDate + " " + _endTime);
            var difference = en.valueOf() - st.valueOf();

            var Difference_In_Days = difference / (1000 * 3600 * 24);
            var totalDays = Difference_In_Days.toFixed();
            setTotalDays(totalDays);
            let seconds = difference / 1000;
            var months = Math.floor(seconds / (3600 * 24 * 30));
            var weeks = Math.floor((seconds % (3600 * 24 * 30)) / (3600 * 24 * 7));
            var days = Math.floor(
                ((seconds % (3600 * 24 * 30)) % (3600 * 24 * 7)) / (3600 * 24)
            );
            var hours = Math.floor((seconds % (3600 * 24)) / 3600);
            if (months > 0 && weeks > 0) {
                days = days + weeks * 7;
                weeks = 0;
            }
            let monthPrice = parseFloat(ActiveOrder["price_month"]),
                weekPrice = parseFloat(ActiveOrder["price_per_week"]),
                dayPrice = months > 0 ? monthPrice / 30 : parseFloat(ActiveOrder["price_day"]),
                hourPrice = parseFloat(ActiveOrder["price_per_hour"]);
            var priceH = 0,
                priceD = 0,
                priceW = 0,
                priceM = 0;
            priceH = hours * hourPrice > dayPrice ? dayPrice : hours * hourPrice;
            if (months > 0) {
                priceD = days * dayPrice + priceH;
            } else {
                priceD =
                    days * dayPrice + priceH > weekPrice
                        ? weekPrice
                        : days * dayPrice + priceH;
            }
            priceW =
                weeks * weekPrice + priceD > monthPrice
                    ? monthPrice
                    : weeks * weekPrice + priceD;
            priceM = months * monthPrice + priceW;
            var total = priceM;
            if (months == 0) {
                total = total > monthPrice ? monthPrice : total;
            }
            if (months == 0 && weeks == 0) {
                total = total > weekPrice ? weekPrice : total;
            }

            var new_total = parseFloat(total + "").toFixed(2);
            setRentPrice(new_total);
            let security_amt = (total / 100) * 20;
            let equipeprotection_amt = (total / 100) * 15;

            let p = equipeprotection_amt.toFixed(2);
            equipeprotection_amt = parseFloat(p);

            //let new_security_amt = parseFloat(security_amt+"").toFixed(2);

            let rental_total = total + security_amt + equipeprotection_amt;

            setRentalTotal(rental_total);
            setSecurityDeposit(security_amt);
            setEquipProtection(equipeprotection_amt);
            let transport_chrg = 0;
            if (chooseTransport == "owner") {
                transport_chrg =
                    parseFloat(ActiveOrder["minimum_delivery_charges"]) + Number(deliveryPrice);
            }
            var grand_total = Number(rental_total) + Number(transport_chrg)
            setgrandTotal(grand_total);
            setExtendRentalSummary(true);
        } else {
            setExtendRentalSummary(false);
        }
    };

    // Check Date is Reserved or not
    const isReserved = (strDate) => {
        // if (result["weekends"] == "0") {
        //     if (moment(strDate).format("ddd") == "Sat") {
        //         return true;
        //     }
        // }
        // if (result["sunday"] == "0") {
        //     if (moment(strDate).format("ddd") == "Sun") {
        //         return true;
        //     }
        // }

        if (blockdate.includes(strDate)) {
            return true;
        }
        return false;
    };


    // Calculate  Difference in minutes bitween start and end dates
    const GetDifferenceinmin = (start, end) => {
        //var _start_Time = moment(ActiveOrder['end_time'], "HH:mm:ss").format("H:mm");
        //var _startTime = RemoveAMPMandTimeFormat(_start_Time);
        var _startTime = moment(ActiveOrder['end_time'], "HH:mm:ss").format("H:mm");
        var _endTime = RemoveAMPMandTimeFormat(endTime);
        let rentEndDate = moment(endDate).format("YYYY-MM-DD");
        let rentStartDate = moment(startDate).format("YYYY-MM-DD");
        if (start && end) {
            rentEndDate = moment(end).format("YYYY-MM-DD");
            rentStartDate = moment(start).format("YYYY-MM-DD");
        }

        var st = moment(rentStartDate + " " + _startTime);
        var en = moment(rentEndDate + " " + _endTime);


        if (start == end) {

            var localStartTime = moment(st).format('YYYY-MM-DD HH:mm:ss');
            var s_tms = moment(localStartTime).format("X");

            var localEndTime = moment(en).format('YYYY-MM-DD HH:mm:ss');
            var e_tms = moment(localEndTime).format("X");

            if (s_tms > e_tms) {
                return null;
            }
        }

        let res = en.diff(st, "minute");
        // let tDay = moment();
        // let diff = st.diff(tDay, "minute");
        // console.log('res', res);
        // console.log('tDay', tDay);

        let diff = res; // new change

        if (diff <= 0) {
            return null;
        }

        return res;
    };

    // Place extended order
    const PlaceExtendOrder = async () => {
        setLoading(true)
        let grand_total = parseFloat(grandTotal.toFixed(2));
        let new_grand_total = parseFloat(grand_total + "").toFixed(2);

        let auth_user = localStorage.getItem("token-info");
        auth_user = JSON.parse(auth_user);
        let user_id = auth_user["user_id"].toString();
        let balance = Number(WalletBalance);

        // Check wallet balance
        // if (grand_total > balance) {
        //     dispatch(setalertMessage('Insufficient wallet balance. please add balance.'));
        //     dispatch(setalertMessagetype("error"));
        //     setLoading(false);
        //     return false;
        // }

        let timeString = ActiveOrder['end_time'];
        // let startTime = new Date('1970-01-01T' + timeString + 'Z')
        //     .toLocaleTimeString('en-US',
        //         { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' }
        //     );
        let startTime = moment(ActiveOrder['end_time'], "HH:mm:ss").format("h:mm A");

        var _startTime = RemoveAMPMandTimeFormat(startTime);
        let date = moment(startDate + "T" + _startTime, "YYYY-MM-DDTHH:mm").utc();

        var _endTime = RemoveAMPMandTimeFormat(endTime);
        let end_time_utc = moment(endDate + "T" + _endTime, "YYYY-MM-DDTHH:mm")
            .utc()
            .toDate()
            .getTime();
        let order_amount = Number(rentalTotal) - Number(securityDeposit) - Number(equipProtection);
        let new_order_amount = parseFloat(order_amount + "").toFixed(2);

        let new_securityDeposit = parseFloat(securityDeposit + "").toFixed(2);
        let new_equipProtection = parseFloat(equipProtection + "").toFixed(2);

        let start_Date = moment(startDate).format("YYYY-MM-DD");
        let end_Date = moment(endDate).format("YYYY-MM-DD");

        let orderData = {
            user_id: user_id,
            equip_id: ActiveOrder["id"].toString(),
            txn_id: Date.now().toString(),
            order_details: "test",
            status: "1",
            amount: new_order_amount,
            start_date: start_Date.toString(),
            end_date: end_Date.toString(),
            start_time: _startTime.toString(),
            end_time: _endTime.toString(),
            instruction: '',
            total_days: TotalDays.toString(),
            equip_user_id: ActiveOrder["user_id"].toString(),
            order_date: date,
            total_price: new_grand_total.toString(),
            security_deposit: new_securityDeposit.toString(),
            equipment_protection_fees: new_equipProtection.toString(),
            end_time_utc: end_time_utc,
            order_from: "web",
            parent_order_id: ActiveOrder["order_id"].toString(),
            // Added for card payment hold
            payment_hold: false,
            CardObj: {},
            is_expire: true,
        };
        // console.log('orderData', orderData);
        // return;

        /**
         * Changes for card payment holding
         * Section start
         */        
        setorderRequestData(orderData)        
        let remaining = 0;
        if (balance != null && balance < grand_total) {
            remaining = grand_total - balance;
            setholdAmount(remaining)     
        }

        if (remaining != 0) {
            setLoading(false)
            setpayWithCard(true);
            
        } else {            
       
            /**
             * Changes for card payment holding
             * Section end
             */
            if (grand_total > balance) {
                dispatch(setalertMessage('Insufficient wallet balance. please add balance.'));
                dispatch(setalertMessagetype("error"));
                setLoading(false);
                return false;
            }

            let headers = { Authorization: `${AuthToken}`, request_from: "Web" };
            const orderResult: IApiResponseRawData = await EquipmentAPIs.ExtendedOrderPlace(
                orderData,
                headers
            );

            if (orderResult["type"] == "RXSUCCESS") {
                if(!setLocal) {
                    localStorage.setItem("OrderPlacedId", ActiveOrder["order_id"]);
                }

                // dispatch(setalertMessage(orderResult["message"]));            
                // dispatch(setalertMessagetype("success"));
                //Result('closepopup');
                // by GD 5 jan to reload the orders page
                Result('orderExtended');
                // end by GD
                setExtendRentalRequestpopup(true)
                setLoading(false);

            } else if (orderResult["status"] == false && orderResult["slug"] == "Session Expired") {
                Result('closepopup');
                dispatch(setSessionExpire(true));
                return false;
            } else {
                setLoading(false);
                dispatch(setalertMessage(orderResult["message"]));
                dispatch(setalertMessagetype("error"));

            }
        /**
         * Changes for card payment holding
         * Section start
         */
        }
        /**
         * Changes for card payment holding
         * Section end
         */
    };

    // Extend Order Section start
    const getDates = (startDate, stopDate) => {
        var dateArray = [];
        var currentDate = moment(startDate);
        var stop_Date = moment(stopDate);
        while (currentDate <= stop_Date) {
            dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
            currentDate = moment(currentDate).add(1, "days");
        }
        return dateArray;
    }

    const APIGetOrderedDates = async (Equip_id) => {
            try {
                setLoading(true);
                const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
                const ResultData = await EquipmentAPIs.GetOrderedDates(
                    {
                        equip_id: Equip_id.toString()
                    },
                    headers
                );
                if (ResultData['type'] == "RXSUCCESS") {
                    let APIResult = ResultData['data']
                    var bookedDates = [];
                    for (var i = 0; i < APIResult.length; i++) {
                        bookedDates = [
                            ...bookedDates,
                            ...getDates(APIResult[i].start_date, APIResult[i].end_date),
                        ];
                    }
                    // Set block dates
                    let blockDates = ActiveOrder['block_dates'];
                    let blockDates_Arr = blockDates.split(",");
                    blockDates_Arr.shift()
                    let new_blockdates = [...blockDates_Arr, ...bookedDates];
                    setBlockdate(new_blockdates);
                    let enddate = ActiveOrder['end_date']
                    // let _date = []
                    // Set max deate
                    const calendermaxdate = new_blockdates.filter((itm, ind) => {
                        if(itm > enddate){
                            return new Date(itm);
                        }
                    });
                    const minDate = calendermaxdate.reduce((min, date) => (date < min ? date : min), calendermaxdate[0]);
                    setMaxDate([minDate]);
                    setCalenderMinDate(new Date(ActiveOrder["start_date"]))
                    setLoading(false);
                    /*********************** */
                    /*********************** */
                        const today = moment().format("YYYY-MM-DD");
                        // let extendedArray = ActiveOrder['order_extend'];
                        let extendedArray = ActiveOrder["order_extend"]?.filter(equip=>equip?.order_status !== "order_canceled");
                        let orderStartTime =
                        extendedArray?.length > 0
                            ? extendedArray[extendedArray.length - 1].end_time
                            :  ActiveOrder['end_time'];
                        let orderEndDate =
                        extendedArray?.length > 0
                            ? extendedArray[extendedArray.length - 1].end_date
                            : ActiveOrder['end_date'];
                        let finalBookBlockDates = [
                        ...ActiveOrder['block_dates'].split(","),
                        ...bookedDates,
                        ];
                        var hour = new Date().getHours().toString();
                        var mint = new Date().getMinutes().toString();
                        if (mint < '30') {
                        mint = '30';
                        } else {
                        mint = '00';
                        hour = hour + 1;
                        }
                        if ((hour + "").length == 1) {
                        hour = '0' + hour;
                        }
                        if (orderEndDate > today || orderEndDate == today) {

                             //Display states for extend
                             let ex_end = today > orderEndDate ? today : orderEndDate;
                             let ex_en_time =  today > orderEndDate ? hour + ":" + mint : orderStartTime;
                            setExEndDate(ex_end)
                            setExEnTime(ex_en_time)                
                            // const [ExStartDate, setExStartDate] = useState("");                
                            // const [ExStTime, setExStTime] = useState("08:00 AM");
                            let a = [ex_end, ''];
                            setValues(a);

                            

                        //   this.props.navigation.navigate("AvailbilityScreen", {
                        //     data: this.state.data,
                        //     selectedDates: this.state.selectedDates,
                        //     onBack: this.onGoBack,
                        //     order_start_date:
                        //       today > this.state.data.start_date
                        //         ? today
                        //         : this.state.data.start_date,
                        //     order_start_time:
                        //       today > orderEndDate ? hour + ":" + mint : orderStartTime,
                        //     order_end_date: today > orderEndDate ? today : orderEndDate,
                        //     order_type: "extended",
                        //     orderID: this.state.data.order_id,
                        //   });
                        } else {
                            var finalBookBlockDateschk;
                            if (finalBookBlockDates.length > 0) {
                                finalBookBlockDateschk = new_blockdates.filter((item) => {
                                    return item == today;
                                });
                            } else {
                                finalBookBlockDateschk = ""
                            }
                            if (finalBookBlockDateschk == today) {
                                dispatch(setalertMessage("Oops, Rental is no longer available to extend."));
                                dispatch(setalertMessagetype('error'));
                                Result('closepopup');
                            } else {
                                
                                let ex_end = today > orderEndDate ? today : orderEndDate;
                                let ex_en_time =  today > orderEndDate ? hour + ":" + mint : orderStartTime;

                                //Display states for extend
                                setExEndDate(ex_end)
                                setExEnTime(ex_en_time)

                                let a = [ex_end, ''];
                                setValues(a);
                        }
                        }
               
                    setAPICallStatus(true);
                    return false;
                }
                else {
                    dispatch(setalertMessage(ResultData['message']));
                    dispatch(setalertMessagetype('error'));
                    setLoading(false);
                    return false
                }
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
    };

    useEffect(() => {
        //added by pp 0n 25 apr 2024 due to timezone changes
        async function fetchTimeZone() {
            let timeZone = ActiveOrder['timezone'];
            if(!timeZone) {
                const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
                const timeZoneData = await EquipmentAPIs.getEquipTimeZone({equip_id:ActiveOrder["id"]} ,headers);
                if(timeZoneData.type === "RXSUCCESS") {
                   timeZone = timeZoneData.data
                }
            }
            setTimeZone(convertTimeZoneShortName(momentTimeZone().tz(timeZone ?? "UTC").zoneAbbr()));
        }
        // end by pp
        if(ActiveOrder != null) {
            
            setLoading(true);
            APIGetOrderedDates(ActiveOrder['id'])

            // check extended orders
            // let extended_Orders = ActiveOrder['order_extend']?.length;
            // if (extended_Orders > 0) {
                // let last_obj = extended_Orders - 1;
                // ActiveOrder['end_date'] = ActiveOrder['order_extend'][last_obj]['end_date'];
                // ActiveOrder['end_time'] = ActiveOrder['order_extend'][last_obj]['end_time'];
            const lastExtendedOrder = ActiveOrder["order_extend"]?.filter(equip=>equip?.order_status !== "order_canceled")?.slice(-1);
            if(lastExtendedOrder?.length && lastExtendedOrder?.end_date && lastExtendedOrder?.end_time) {
                ActiveOrder["end_date"] = lastExtendedOrder.end_date;
                ActiveOrder["end_time"] = lastExtendedOrder.end_time;
                setExEndDate(lastExtendedOrder.end_date)
                setExEnTime(lastExtendedOrder.end_time)
                // //Display states for extend
                // setExEndDate(ActiveOrder['order_extend'][last_obj]['end_date'])
                // setExEnTime(ActiveOrder['order_extend'][last_obj]['end_time'])
            } else {
                //Display states for extend
                setExEndDate(ActiveOrder['end_date'])
                setExEnTime(ActiveOrder['end_time'])
            }
            fetchTimeZone()
        }
    }, [ActiveOrder,AuthToken]);

    interface MyPluginProps {
        position: string;
    }

    function MyPlugin(props: MyPluginProps) {
        return (
            <>
                <Box className="time-box">

                    <Box className="time-main-box">
                        <FormLabel className="time-box-label">Return Time ({timeZone}) - </FormLabel>
                        <Box className="time-main-box-top">
                            <Button
                                className="plus-btn"
                                variant="contained"
                                onClick={(event) => {
                                    handleStartEndTime(event, "endHoursPlus");
                                    //calculatePrice(startDate, endDate);
                                }}
                            >
                                <KeyboardArrowUpIcon />
                            </Button>
                            <TextField
                                id="outlined-disabled"
                                value={hoursArray[endHours]}
                                disabled
                                className="cal-time-input"
                                sx={{
                                    "& .MuiSelect-select": {
                                        color: "#2D2D2D !important",
                                        fontFamily: "Gotham !important"
                                    }
                                }}
                                style={{
                                    backgroundColor: "#fff",
                                    border: "1px solid #1976d2",
                                    borderRadius: "10px"
                                }}
                            />
                            <Button
                                className="minus-btn"
                                variant="contained"
                                onClick={(event) => {
                                    handleStartEndTime(event, "endHoursMinus");
                                    //calculatePrice(startDate, endDate);
                                }}
                            >
                                <KeyboardArrowDownIcon />
                            </Button>
                        </Box>

                        <Typography sx={{ color: "#fff !important" }}>:</Typography>

                        <Box className="time-main-box-mid">
                            <Button
                                className="plus-btn"
                                variant="contained"
                                onClick={(event) => {
                                    handleStartEndTime(event, "endMinutesToggle");
                                    // calculatePrice(startDate, endDate);
                                }}
                            >
                                <KeyboardArrowUpIcon />
                            </Button>
                            <TextField
                                id="outlined-disabled"
                                value={endMinutes}
                                disabled
                                className="cal-time-input"
                                sx={{
                                    "& .MuiSelect-select": {
                                        color: "#2D2D2D !important",
                                        fontFamily: "Gotham !important"
                                    }
                                }}
                                style={{
                                    backgroundColor: "#fff",
                                    border: "1px solid #1976d2",
                                    borderRadius: "10px"
                                }}
                            />
                            <Button
                                className="minus-btn"
                                variant="contained"
                                onClick={(event) =>
                                    handleStartEndTime(event, "endMinutesToggle")
                                }
                            >
                                <KeyboardArrowDownIcon />
                            </Button>
                        </Box>
                        <Box className="time-main-box-btm">
                            {/* <Button variant="contained" onClick={(event) => handleStartEndTime(event, "toggleEndTime")}>
                  {selectedEndTime}
                </Button> */}
                            <ToggleButtonGroup
                                orientation="vertical"
                                value={selectedEndTime}
                                exclusive
                                onChange={(event) => {
                                    handleStartEndTime(event, "toggleEndTime");
                                    //calculatePrice(startDate, endDate);
                                }}
                            >
                                <ToggleButton
                                    className={
                                        selectedEndTime === "AM" ? "am-pm active" : "am-pm"
                                    }
                                    value="AM"
                                    aria-label="list"
                                >
                                    AM
                                </ToggleButton>
                                <ToggleButton
                                    className={
                                        selectedEndTime === "PM" ? "am-pm active" : "am-pm"
                                    }
                                    value="PM"
                                    aria-label="module"
                                >
                                    PM
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </Box>
                </Box>
                <Box className="confirm-div">
                    {startDate && endDate && (
                        <Button
                            className="confirm-btns extend-confirm-btn"
                            variant="contained"
                            onClick={() => {
                                let diffMins = GetDifferenceinmin(startDate, endDate);
                                if (diffMins === null || diffMins == 0) {
                                    dispatch(setalertMessage("The selected pickup time is in the past."));
                                    dispatch(setalertMessagetype("error"));
                                    return false;
                                }
                                calculatePrice(startDate, endDate);
                            }}
                        >
                            Confirm
                        </Button>
                    )}
                </Box>

            </>
        );
    }


    /**
     * Direct pay with card and payment hold
     * section start
     */
    const payWithCardClose = () => {   
        setpayWithCard(false);
        setLoading(false);
    };

    const HandleCardHoldPayment = async (cardObjData) => {
        try {     
          let final = { ...orderRequestData, CardObj: cardObjData, payment_hold: true ,is_expire:true}
    
          let headers = { Authorization: `${AuthToken}`, request_from: "Web" };
            const orderResult: IApiResponseRawData = await EquipmentAPIs.ExtendedOrderPlace(
                final,
                headers
            );

            if (orderResult["type"] == "RXSUCCESS") {
                if(!setLocal){
                    localStorage.setItem("OrderPlacedId", ActiveOrder["order_id"]);
                }
                Result('orderExtended');             
                setExtendRentalRequestpopup(true)
                setLoading(false);
                setpayWithCard(false);

            } else if (orderResult["status"] == false && orderResult["slug"] == "Session Expired") {
                Result('closepopup');
                dispatch(setSessionExpire(true));
                return false;
            } else {
                setLoading(false);
                dispatch(setalertMessage(orderResult["message"]));
                dispatch(setalertMessagetype("error"));
            }
    
        } catch (err) {
          console.log(err);
        }
    
      };
    /**
     * Direct pay with card and payment hold
     * section start
     */
    return (
        <>


            {loading && (
                <Box>
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            )}


            {APICallStatus && (
                <Box 
                // className="twin-cal" 
                className="extend-rent-calendar"
                style={{ display: ExtendRentalSummary ? "none" : "block" }}
                >
                    <Box className='new-modal-heading'>
                        {/* <img src={leftarrow} alt="" style={{ width: "9%", cursor: "pointer" }} /> */}
                        <Typography variant="h5">
                            <Box component = {"span"} sx={{ color: "#faa61a" }}>Availability Calender</Box>
                        </Typography>
                    </Box>

                    <Box 
                    // className="twin-cal-item "
                    >
                        
                        <NewCalendar
                            numberOfMonths={tabviewcalendar}
                            disableMonthPicker
                            disableYearPicker
                            value={values}
                            onChange={(rang) => {                               
                                //let start = moment(ActiveOrder['end_date']).format("YYYY/MM/DD");
                                let start = moment(ExEndDate).format("YYYY/MM/DD");

                                let end = rang[1]?.format?.();

                                if (start || end) {
                                    let a = [start, end];

                                    setValues(a);
                                    console.log('start',start);
                                    console.log('end',end);
                                    if (start) {
                                        setStartDate(start);
                                        setrentStartDate(
                                            moment(start).format("M/D/YYYY")
                                        );
                                    }

                                    if (end) {
                                        setrentEndDate(moment(end).format("M/D/YYYY"));
                                        setEndDate(end);
                                    }

                                }
                                return;
                            }}
                            mapDays={({ date }) => {
                                let className = "";
                                const strDate = moment(date.format()).format(
                                    "YYYY-MM-DD"
                                );                                    
                                
                                //if (ActiveOrder['start_date'] <= strDate && ActiveOrder['end_date'] >= strDate) {

                                if (ActiveOrder['start_date'] <= strDate && ActiveOrder['end_date'] >= strDate) {

                                   // console.log('data ==>',strDate, 'start ==>', ActiveOrder['start_date'], 'end =>',ExEndDate, 'Cdate =>', nowdata  );
                                    
                                    if (ExEndDate == strDate) {
                                        className = "rmdp-range start";
                                    } else {
                                        className = "active-order";
                                    }
                                } else {
                                    if(nowdata > ActiveOrder['end_date']) {
                                        if (nowdata == strDate) {
                                          //  console.log('object',strDate);
                                            
                                            className = "rmdp-range start";
                                        } 
                                    } else{

                                        if (isReserved(strDate)) className = "reserved";
                                    }
                                }

                                //disable sat sun
                                if (ActiveOrder["weekends"] == 0) {
                                    if (moment(strDate).format("ddd") == "Sat") {
                                        className = "reserved";
                                    }
                                }
                                if (ActiveOrder["sunday"] == 0) {
                                    if (moment(strDate).format("ddd") == "Sun") {
                                        className = "reserved";
                                    }
                                }
                                /***********/

                                if (className) return { className };
                            }}
                            minDate={calenderMinDate}
                            maxDate={MaxDate.length > 0 ? MaxDate[0] : ''}
                            className="twin-cal-custom-calendar"
                            range
                        />
                        <Box className="cal-main-btm">
                            <MyPlugin position="bottom" />
                        </Box>
                    </Box>
                </Box>
            )}
            {ExtendRentalSummary && !ExtendRentalRequestpopup && !payWithCard && (
                <>
                    <Box className="">

                        <Box className='new-modal-heading'>
                            <img src={leftarrow} alt="" style={{ width: "9%", cursor: "pointer" }}
                                onClick={() => {
                                    setExtendRentalSummary(false);
                                }}
                            />
                            <Typography variant="h5">
                                <Box component = {"span"} sx={{ color: "#faa61a" }}>EXTENDED RENTAL SUMMARY</Box>
                            </Typography>
                        </Box>

                        <Box className="rentyrd-dates">
                            <Box className="er-grid">
                                <Box className="er-item">
                                    <Typography>
                                        <Box component = {"span"} sx={{color:"#fff"}}>Rental Price :</Box>
                                        <Box component = {"span"} sx={{color:"#fff"}}>
                                            ${new Intl.NumberFormat("en-US", {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2
                                            }).format(rentPrice)}
                                        </Box>
                                    </Typography>
                                    <Typography>
                                        <Box component = {"span"} sx={{color:"#fff"}} >Security Deposit :</Box>
                                        <Box component = {"span"} sx={{color:"#fff"}} >
                                            ${new Intl.NumberFormat("en-US", {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2
                                            }).format(securityDeposit)}
                                        </Box>
                                    </Typography>
                                    <Typography>
                                        <Box component = {"span"} sx={{color:"#fff"}}>EquipMe Protection :</Box>
                                        <Box component = {"span"} sx={{color:"#fff"}}>
                                            ${new Intl.NumberFormat("en-US", {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2
                                            }).format(equipProtection)}
                                        </Box>
                                    </Typography>
                                    {/* <Typography>
                                        <span style={{ color: "#faa61a" }}>Total: </span>
                                        <span>
                                            $ {parseFloat(grandTotal + "").toFixed(2)}
                                        </span>
                                    </Typography> */}
                                </Box>

                                <Box className="er-item" >
                                    <Typography sx={{ color: "#faa61a !important", fontSize: "clamp(20px, 3vw, 20px)", fontFamily: "'GothamBold' !important" }}>
                                        Extended Dates
                                    </Typography>
                                    <Box className="rentyrd-dates-item rental-dates">
                                        <Typography>
                                            <Box component = {"span"} sx={{color:"#fff"}} >Start:</Box>
                                            <Box component = {"span"} sx={{color:"#fff"}}>
                                                {/* {moment(ActiveOrder["end_date"], [
                                                    "YYYY-MM-DD",
                                                ]).format("MM/DD/YYYY")}{" "}
                                                {moment(ActiveOrder["end_time"], [
                                                    "HH:mm:ii",
                                                ]).format("hh:mm a")} */}
                                               
                                                {moment(values[0], [
                                                    "YYYY-MM-DD",
                                                ]).format("MM/DD/YYYY")}{" "}
                                                {moment(ActiveOrder["end_time"], [
                                                    "HH:mm:ii",
                                                ]).format("hh:mm A")}
                                                &nbsp;({timeZone})
                                            </Box>
                                        </Typography>
                                        <Typography>
                                            <Box component = {"span"} sx={{color:"#fff"}}>End:</Box>
                                            <Box component = {"span"} sx={{color:"#fff"}} >
                                                {/* {moment(endDate, [
                                                    "YYYY-MM-DD",
                                                ]).format("MM/DD/YYYY")}{" "}
                                                {endTime} */}
                                                
                                                {moment(values[1], [
                                                    "YYYY-MM-DD",
                                                ]).format("MM/DD/YYYY")}{" "}
                                                {endTime}
                                                &nbsp;({timeZone})
                                            </Box>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                    </Box>


                    <Box className="proceed-sec">
                        <Box className="total-amount-div">
                            <Typography>Total Amount to Pay</Typography>
                            <Typography className="total-value-text">
                                ${new Intl.NumberFormat("en-US", {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                }).format(grandTotal)}
                            </Typography>
                        </Box>

                    </Box>
                    <Box className="submitpayment-btn-div">
                        <Button
                            className="submitpayment-btn"
                            onClick={() => {
                                PlaceExtendOrder();
                            }}
                        >
                            SUBMIT
                        </Button>
                    </Box>
                </>

            )}

            {ExtendRentalRequestpopup && (
                <Box className="">
                    Thank you, you will not be charged until the owner of this equipment approves your rental request. Keep an eye on your email for confirmation. Also, please reach out to the owner directly at this link with questions.
                </Box>

            )}

            {/* Order amount pay direct with card */}
            <Modal
                open={payWithCard}                
                className="addmoney-modal"
              >
                <Box
                  className="download-popups"
                  sx={{
                    ...loginstyle,
                    padding: "0px"
                  }}
                >
                  <IconButton className="cross-btn" onClick={payWithCardClose}>
                    <CloseIcon />
                  </IconButton>

                  <Box className="login__main">
                    <Box
                      className="login__main-div edit-eqp">
                      <Box
                        className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                        style={{
                          border: "5px solid #faa61a",
                          borderRadius: "10px"
                        }}
                      >
                        <Typography
                          variant="h5"
                          style={{ color: "#faa61a", marginBottom: "0.5rem" }}
                        >
                          Pay With Card
                        </Typography>

                        <PaymentHold
                          amount={holdAmount}
                          onSuccess={(e) => {                            
                            if (e.status == "success") {                              
                              HandleCardHoldPayment(e);
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Modal>
        </>
    );
};

export default ExtendRental;