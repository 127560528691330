import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Container,
  Backdrop,
  CircularProgress,
  Typography,
  Link,
  TextField,
  styled,
  Modal,
  IconButton,
  FormControl,
  FormLabel,
  Button,
  InputAdornment
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {Visibility, VisibilityOff } from "@mui/icons-material";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { setalertMessagetype, setalertMessage } from "redux/redux-slice";
import { EquipmentAPIs } from "../../service/api-service";
import { IApiResponseRawData, IStoreValue } from "utils/types";

// import cameraIcon from "assets/images/Scamera.png";
import dlPlaceholder from "assets/images/DL_placeholder.png";
import MainLayout from "layouts/MainLayout";
import GoogleMapLocation from "../equipment-list/GoogleautoComplete";
import Geocode from "react-geocode";
import "./styles.scss";
import Img from "./../../assets/images/dummy-profile-pic.jpeg";
import DummyImage from "../../assets/images/dummy-profile-image.png";
import profileEditImg from "../../assets/images/profile_edit_icon.png";
import ProfileWarningIcon from "../../assets/images/icon_warning_shadow.png";
import AccountSetting from "./AccountSetting";

const CssTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    color: "#2e2e2e",
    background: "#fff",
    fontFamily: "GothamBold !important",
    fontSize: "clamp(12px, 3vw, 15px) !important"
    // border: "2px solid #faa61a",
  },
  "& label.Mui-focused": {
    color: "#faa61a",
    fontWeight: "bold"
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#2e2e2e"
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a"
    },
    "&:hover fieldset": {
      borderColor: "#2e2e2e",
      border: "2px solid #faa61a"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a"
    }
  }
});

const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4
};

const Profileinfo = () => {
  const inRef = useRef(null);
  const searchRef = useRef();
  const inputProfileFile = useRef(null);
  const inputLicencepic = useRef(null);
  const dispatch = useDispatch();
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const UserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const AuthProfileData = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );
  const [loading, setloading] = useState<boolean>(true);
  const [Profile, setProfile] = useState([]);
  const [Profilepic, setProfilepic] = useState("");
  const [Licencepic, setLicencepic] = useState("");
  const [UpdateEmailPopup, setUpdateEmailPopup] = useState(false);
  const [UpdatePhonePopup, setUpdatePhonePopup] = useState(false);
  const [Phone, setPhone] = React.useState("");
  const [Email, setEmail] = React.useState("");
  const [NewEmail, setNewEmail] = React.useState("");
  const [mainPhone, setmainPhone] = React.useState("");
  const [UpdateAddressPopup, setUpdateAddressPopup] = React.useState(false);
  const [ChangePasswordPopup, setChangePasswordPopup] = React.useState(false);
  const [changeProfilePopup, setChangeProfilePopup] = useState(false);
  const [changeLicensePopup,setChangeLicensePopup] = useState(false);

  const APIKEY = process.env.REACT_APP_GEOCODDER_KEY;
  Geocode.setApiKey(APIKEY);

  const [UserAddress, setUserAddress] = useState("");
  const [UserLocation, setUserLocation] = useState({
    lat: 0,
    lng: 0
  });
  const [FullAddress, setFullAddress] = useState({
    address: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
    lat: "",
    lng: ""
  });

  const [addressCounter, setAddressCounter] = useState(0)

  // Change password section
  const [Oldpassword, setOldpassword] = useState("");
  const [Newpassword, setNewpassword] = useState("");
  const [Confirmpassword, setConfirmpassword] = useState("");

  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showCnfrmPassword, setShowCnfrmPassword] = React.useState(false);

  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowCnfrmPassword = () => setShowCnfrmPassword((show) => !show);

  const HandleProfilepic = async function (
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    if(!e.target.files[0]) {
      return;
    }
    setloading(true);
    if (
      e.target.files[0].type == "image/jpg" ||
      e.target.files[0].type == "image/jpeg" ||
      e.target.files[0].type == "image/png"
    ) {
      var fileLoaded = URL.createObjectURL(e.target.files[0]);

      let file_obj = e.target.files[0];
      const formData = new FormData();
      formData.append("profile_image", file_obj);
      formData.append("user_id", UserId.toString());
      let access_token = AuthToken;
      const headers = {
        Authorization: `${access_token}`,
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        request_from: "Web"
      };
      const upload_profileimage = await EquipmentAPIs.UpdateProfileimage(
        formData,
        headers
      );
      if (upload_profileimage["type"] == "RXSUCCESS") {
        setProfilepic(fileLoaded);
        setloading(false);
        setChangeProfilePopup(false);
        dispatch(setalertMessage(upload_profileimage["message"]));
        dispatch(setalertMessagetype("success"));
      } else {
        setloading(false);
        dispatch(setalertMessage(upload_profileimage["message"]));
        dispatch(setalertMessagetype("error"));
      }
    }
  };

  //Update Profile pic
  const HandleLicece = async function (e: React.ChangeEvent<HTMLInputElement>) {
    if(!e.target.files[0]) {
      return;
    }
    setloading(true);
    if (
      e.target.files[0].type == "image/jpg" ||
      e.target.files[0].type == "image/jpeg" ||
      e.target.files[0].type == "image/png"
    ) {
      var fileLoaded = URL.createObjectURL(e.target.files[0]);

      let file_obj = e.target.files[0];
      const formData = new FormData();
      formData.append("image", file_obj);
      formData.append("user_id", UserId.toString());
      let access_token = AuthToken;
      const headers = {
        Authorization: `${access_token}`,
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        request_from: "Web"
      };
      const Result = await EquipmentAPIs.UpdateLicenceimage(formData, headers);
      if (Result["type"] == "RXSUCCESS") {
        setLicencepic(fileLoaded);
        setloading(false);
        setChangeLicensePopup(false);
        dispatch(setalertMessage("Licence image updated successfully"));
        dispatch(setalertMessagetype("success"));
      } else {
        setloading(false);
        dispatch(setalertMessage(Result["message"]));
        dispatch(setalertMessagetype("error"));
      }
    }
  };

  const phoneFormat = (phNumber) => {
    // var match = phNumber.match(/(\d{3})(\d{3})(\d{4})$/);
    // if (match) {
    //   var number = [match[1], "-", match[2], "-", match[3]].join("");
    //   setPhone(number);
    //   return;
    // }
    // setPhone(phNumber);
    let cleanNumber = phNumber;
    if (cleanNumber.length <= 3) {
      setPhone(cleanNumber);
    } else if (cleanNumber.length <= 6) {
      setPhone(`${cleanNumber.slice(0, 3)}-${cleanNumber.slice(3)}`);
    } else if (cleanNumber.length <= 10) {
      setPhone(`${cleanNumber.slice(0, 3)}-${cleanNumber.slice(3,6 )}-${cleanNumber.slice(6)}`);
    } else {
      setPhone(`${cleanNumber.slice(0, 3)}-${cleanNumber.slice( 3, 6 )}-${cleanNumber.slice(6, 10)}`);
    }
  };

  // fxn created by GD 12 Jan 2024  for phone formatting issue
  const newPhoneFormat = (phNumber) => {
    let rawNumber = phNumber.replace(/[^0-9]/g, "");
    // second way to format number
    if (rawNumber.length <= 3)  return rawNumber;
    const segments = [
      rawNumber.slice(0, 3),
      rawNumber.slice(3, 6),
      // rawNumber.slice(6, 10)
      rawNumber.slice(6)
    ];
    return segments.filter(Boolean).join('-');

  };
  // end by GD 12 Jan 2024
  const HandlePhone = (event) => {
    let val = event.target.value;
    val = val.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1");
    if (val.length == 0) {
      setPhone(val);
    } else {
      phoneFormat(val);
    }
  };
  //Handle phone update popup
  const HandlePhoneUpdate = async () => {
    try {
      setloading(true);
      if (Phone == "") {
        dispatch(setalertMessage("Please fill phone number."));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }
      // by GD 12 Jan 2024 length changed from 12 to 10
      if (Phone.length != 10) {
        dispatch(setalertMessage("Invalid phone number."));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }

      var new_Phone = Phone.replace(/[^0-9]/g, "");

      let access_token = AuthToken;
      const headers = { Authorization: `${access_token}`, request_from: "Web" };
      const Result = await EquipmentAPIs.UpdatePhone(
        {
          user_id: UserId.toString(),
          new_phone_no: new_Phone
        },
        headers
      );
      if (Result["type"] == "RXSUCCESS") {
        setmainPhone(new_Phone);
        setPhone(new_Phone);
        dispatch(setalertMessage(Result["message"]));
        dispatch(setalertMessagetype("success"));
        setUpdatePhonePopup(false);
        setloading(false);
      } else {
        setloading(false);
        dispatch(setalertMessage(Result["message"]));
        dispatch(setalertMessagetype("error"));
      }
    } catch (err) {
      setloading(false);
    }
  };

  // Get city state country address etc from geocode
  const getFullAddress = async (center) => {
    Geocode.fromLatLng(center.lat, center.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;

        let city, state, country, short_state, postal_code;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                short_state =
                  response.results[0].address_components[i].short_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
              case "postal_code":
                postal_code =
                  response.results[0].address_components[i].short_name;
                break;
            }
          }
        }
        if(!postal_code) {
          const addressData = response.results.find(address => address.types.includes("postal_code"));
          const postalCodeArr = addressData?.address_components?.find(address => !isNaN(+address.short_name));
          postal_code = postalCodeArr?.short_name || postalCodeArr?.long_name || "";
        }
        let full_address = {
          address: address,
          city: city,
          state: state,
          short_state: short_state,
          postal_code: postal_code,
          country: country,
          lat: center.lat,
          lng: center.lng
        };
        setAddressCounter((prev)=>prev+1)
        setFullAddress(full_address);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const UpdateProfile = async (e) => {
    e.preventDefault();
    setloading(true);
    if(addressCounter <= 1) {
      dispatch(setalertMessage("Please Choose location to update."));
      dispatch(setalertMessagetype("error"));
      setloading(false);
      return;
    }
    try {
      let body = {
        address: FullAddress.address,
        lat: FullAddress.lat,
        lng: FullAddress.lng,
        city: FullAddress.city,
        state: FullAddress.state,
        country: FullAddress.country,
        postal_code: FullAddress.postal_code,
        user_id: UserId.toString(),
        device_type:"web", 
      };
      
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const UserStatus: IApiResponseRawData = await EquipmentAPIs.UpdateProfile(
        body,
        headers
      );

      if (UserStatus["status"] == 200) {
        setloading(false);
        if (UserStatus["type"] == "RXERROR") {
          dispatch(setalertMessage(UserStatus["message"]));
          dispatch(setalertMessagetype("error"));
          return false;
        } else {
          setUserAddress(FullAddress.address);
          setUpdateAddressPopup(false);
          dispatch(setalertMessage(UserStatus["message"]));
          dispatch(setalertMessagetype("success"));
        }
      } else {
        setloading(false);
        dispatch(setalertMessage(UserStatus["message"]));
        dispatch(setalertMessagetype("error"));
      }
    } catch (err) {
      setloading(false);
      console.log(err);
    }
  };

  // Change password section
  const HandleOldPassword = (event) => {
    let val = event.target.value;
    val = val.trim();
    setOldpassword(val);
  };

  const HandleNewPassword = (event) => {
    let val = event.target.value;
    val = val.trim();
    setNewpassword(val);
  };

  const HandleConfirmPassword = (event) => {
    let val = event.target.value;
    val = val.trim();
    setConfirmpassword(val);
  };

  const UpdatePassword = async () => {
    try {
      setloading(true);
      let old_password = Oldpassword;
      let new_password = Newpassword;
      let c_password = Confirmpassword;

      if (old_password == "" || old_password.length == 0) {
        setloading(false);
        dispatch(setalertMessage("Please fill old password first."));
        dispatch(setalertMessagetype("error"));
        return false;
      }

      if (new_password == "" || new_password.length == 0) {
        setloading(false);
        dispatch(setalertMessage("Please fill new password first."));
        dispatch(setalertMessagetype("error"));
        return false;
      }

      if (c_password == "" || c_password.length == 0) {
        setloading(false);
        dispatch(setalertMessage("Please fill confirm password first."));
        dispatch(setalertMessagetype("error"));
        return false;
      }

      if (new_password != c_password) {
        setloading(false);
        dispatch(
          setalertMessage("New password and confirm password did not match.")
        );
        dispatch(setalertMessagetype("error"));
        return false;
      }

      if (new_password == old_password) {
        setloading(false);
        dispatch(
          setalertMessage("Old password and new password cannot be same.")
        );
        dispatch(setalertMessagetype("error"));
        return false;
      }

      let body = {
        user_id: UserId.toString(),
        old_password: old_password,
        new_password: new_password
      };
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const UserStatus = await EquipmentAPIs.ChangePassword(body, headers);

      if (UserStatus["status"] == 200) {
        setloading(false);
        if (UserStatus["type"] == "RXERROR") {
          dispatch(setalertMessage(UserStatus["message"]));
          dispatch(setalertMessagetype("error"));
          return false;
        } else {
          setOldpassword("");
          setNewpassword("");
          setConfirmpassword("");

          setChangePasswordPopup(false);
          dispatch(setalertMessage(UserStatus["message"]));
          dispatch(setalertMessagetype("success"));
        }
      } else {
        setloading(false);
        dispatch(setalertMessage(UserStatus["message"]));
        dispatch(setalertMessagetype("error"));
      }
    } catch (err) {
      setloading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    //   let auth_user = localStorage.getItem("token-info");
    //   if (auth_user != "undefined") {
    //     auth_user = JSON.parse(auth_user);
    //     if (auth_user != null && auth_user) {
    //       dispatch(setaccessToken(auth_user["auth_token"]));
    //       getuserprofile(auth_user["user_id"], auth_user["auth_token"]);
    //       dispatch(setLoginStatus(true));
    //       dispatch(setAuthuser(auth_user["user_id"]));
    //     }
    //   }
    // }, []);

    window.scrollTo(0, 0);
    if (AuthProfileData && AuthProfileData != null) {
      setProfilepic(AuthProfileData["profile_image"]);
      setLicencepic(AuthProfileData["licence_pic"]);
      setmainPhone(AuthProfileData["phone_no"]);
      setUserAddress(AuthProfileData["address"]);
      let lat = AuthProfileData["lat"];
      let lng = AuthProfileData["lng"];
      setUserLocation({ lat: Number(lat), lng: Number(lng) });
      setProfile(AuthProfileData);
      // setPhone(Profile["phone_no"]);
      setEmail(Profile["email"]);
      setNewEmail(Profile["email"]);

      setloading(false);
    }
  }, [AuthProfileData]);

  return (
    <MainLayout>
      {loading && (
        <Box>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}

      <Box className="profile">
        <Container>
          <Box className="profile-main">
            <Box className="profile-inner">
              <Box className="profile-heading">
                <Typography>My Profile & Settings</Typography>
              </Box>

              <Box className="profile-info-grid">
                <Box className="profile-info-grid-left">
                  <Box className="user-pimg">
                    <img
                    className="user-p-img"
                      src={Profilepic ? Profilepic : Img}
                      alt=""
                      style={{ width: "100%" }}
                    />
                    {!Profilepic && <img className="profilewarning" src={ProfileWarningIcon} alt="" />}
                    {/* <Box className="camera-icon" onClick={()=>setChangeProfilePopup(true)}> */}
                      {/* <img src={cameraIcon} alt="" /> */}
                      {/* below line commented by pp on 11 june 2024 due to profie upload changes */}
                      {/* <input
                        type="file"
                        onChange={HandleProfilepic}
                        accept="image/jpg, image/jpeg, image/png"
                        ref={inputProfileFile}
                      /> */}
                    {/* </Box> */}
                    <Link
                      className="profile-change-photo-link change-link"
                      // onClick={() => inputProfileFile.current.click()}
                      onClick={ () =>setChangeProfilePopup(true)}
                    >
                      {!Profilepic ?"Add Photo":"Change Photo"}
                    </Link>
                  </Box>
                </Box>

                <Box className="profile-info-grid-right">
                  <Box className="user-name">
                    <Typography>
                      {Profile["first_name"]} {Profile["last_name"]}
                    </Typography>
                  </Box>

                  <Box className="user-detail-div">
                    <label className="profile-info-label">Email</label>
                    <Box className="user-detail">
                      <Box className="user-detail-item">
                        {/* <Typography>{Email}</Typography> */}
                        <Typography sx={{color:"#fff !important"}}>{Profile["email"]}</Typography>
                      </Box>
                      {/* <Link
                        className="change-link"
                        onClickCapture={() => {
                          setNewEmail(Email);
                          setUpdateEmailPopup(true);
                        }}
                      >
                        Change Email
                      </Link> */}
                    </Box>
                  </Box>

                  <Box className="user-detail-div">
                    <Box className="user-detail">
                      <label className="profile-info-label">Mobile Phone</label>                     
                      <Link
                        className="change-link"
                        onClickCapture={() => {
                          setPhone(mainPhone);
                          setUpdatePhonePopup(true);
                        }}
                      >
                        <img src={profileEditImg} alt="Edit icon" className="profile-edit-icon"/>
                      </Link>
                    </Box>
                    <Box className="user-detail-item">
                        {/* <Typography>Mobile Phone</Typography> */}
                      <Typography sx={{color:"#fff !important"}}>{mainPhone}</Typography>
                    </Box>
                  </Box>

                  {/* <Box className="user-address"> */}
                  <Box className="user-detail-div" sx={{ position:'relative' }}>
                    {!UserAddress && <img className="addresswarning" src={ProfileWarningIcon} alt="" />}
                    <Box className="user-detail">
                    <label className="profile-info-label">Address</label> 
                    <Link
                      className="change-link"
                      onClick={() => {
                        setUpdateAddressPopup(true);
                      }}
                    >
                      <img src={profileEditImg} alt="Edit icon" className="profile-edit-icon"/>
                    </Link>
                    </Box>
                    <Box className="user-detail-item">
                      <Typography sx={{color:"#fff !important"}}>{UserAddress} </Typography>
                    </Box>
                  </Box>

                  <Box className="user-detail-div">
                    <Box className="user-detail">
                      <label className="profile-info-label">Password</label>
                      <Link
                        className="change-link"
                        onClick={() => {
                          setChangePasswordPopup(true);
                          setOldpassword("");
                          setNewpassword("");
                          setConfirmpassword("");
                        }}
                      >
                        <img src={profileEditImg} alt="Edit icon" className="profile-edit-icon"/>
                      </Link>
                    </Box>
                    <Box className="user-detail-item">
                        {/* <Typography>Password</Typography> */}
                      <Typography sx={{color:"#fff !important"}}>*********</Typography>
                    </Box>
                  </Box>

                  <Box className="user-detail-div">
                    
                    {/* <Typography className="ddl">Driver's License</Typography> */}
                    <Box className="user-detail lic-change">                    
                    <label className="profile-info-label" >
                      Driver's License
                    </label>
                      <Link
                        className="change-link"
                        // onClick={() => inputLicencepic.current.click()}
                        onClick={() => setChangeLicensePopup(true)}
                      >
                        <img src={profileEditImg} alt="Edit icon" className="profile-edit-icon"/>
                      </Link>
                      
                    </Box>
                    <Box className="change-image" sx={{ position:'relative' }}>
                    {!Licencepic &&
                        <img className="dlwarning" src={ProfileWarningIcon} alt="" />
                    }
                      <img
                      className="license-image-new"
                        src={Licencepic?Licencepic:dlPlaceholder}
                        alt=""
                        // style={{ width: "100%" }}
                      />
                      <Box className="camera-icon" onClick={() => setChangeLicensePopup(true)}>
                      {/* <img src={cameraIcon} alt="" /> */}
                      {/* below line commented by pp on 11 june 2024 due to License upload changes */}
                        {/* <input
                          type="file"
                          onChange={HandleLicece}
                          accept="image/jpg, image/jpeg, image/png"
                          ref={inputLicencepic}
                        /> */}
                      </Box>
                    </Box>
                  </Box>
                  < AccountSetting />
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      {/* Update Phone Number */}
      <Modal
        keepMounted
        open={UpdatePhonePopup}
        onClose={() => setUpdatePhonePopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px"
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setUpdatePhonePopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Box
                className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
              >
                <Typography variant="h5" style={{ color: "#faa61a" }}>
                  Update Phone
                </Typography>

                <Box>
                  {loading && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer + 1
                        }}
                        open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}
                  <FormControl fullWidth className="form-fields">
                    <FormLabel id="demo-radio-buttons-group-label">
                      Mobile phone
                    </FormLabel>

                    <CssTextField
                      id="login-email"
                      variant="outlined"
                      placeholder="Mobile number"
                      type="tel"
                      sx={{ width: "100%" }}
                      // onChange={HandlePhone}
                      // value={Phone}
                      // by GD 12 jan due to phone format issue
                      onChange={(e)=>  setPhone(e.target.value.replace(/[^0-9]/g, ""))}
                      value={newPhoneFormat(Phone)}
                      inputProps={{
                        maxLength: 12
                      }}
                    />
                  </FormControl>
                  <Button
                    className="signin-btn"
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                    onClick={HandlePhoneUpdate}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Update Address */}
      <Modal
        keepMounted
        open={UpdateAddressPopup}
        onClose={() => setUpdateAddressPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px"
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setUpdateAddressPopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Box
                className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
              >
                <Typography variant="h5" style={{ color: "#faa61a" }}>
                  Update Address
                </Typography>

                <Box>
                  {loading && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer + 1
                        }}
                        open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}

                  <FormControl fullWidth className="form-fields">
                    <FormLabel id="demo-radio-buttons-group-label">
                      Address
                    </FormLabel>

                    <GoogleMapLocation
                      onPress={(center) => {
                        getFullAddress(center);
                      }}
                      inputRefs={inRef}
                      searchRef={searchRef}
                      equipLocation={UserLocation}
                      equipAddress={UserAddress}
                    />
                  </FormControl>
                  <Button
                    className="signin-btn"
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                    onClick={UpdateProfile}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Change Password Popup */}
      <Modal
        keepMounted
        open={ChangePasswordPopup}
        onClose={() => setChangePasswordPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px"
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setChangePasswordPopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Box
                className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
              >
                <Typography variant="h5" style={{ color: "#faa61a" }}>
                  Update Password
                </Typography>

                <Box>
                  {loading && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer + 1
                        }}
                        open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}

                  <FormControl fullWidth className="form-fields">
                    <FormLabel id="demo-radio-buttons-group-label">
                      Old password
                    </FormLabel>

                    <CssTextField
                      id="old-login-email"
                      variant="outlined"
                      placeholder="Old password"
                      type={showOldPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowOldPassword}
                              edge="end"
                              className="eye-icon"
                          >
                          {showOldPassword ? <Visibility />:<VisibilityOff />}
                          </IconButton>
                          </InputAdornment>
                      ),
                      }}
                      sx={{ width: "100%" }}
                      onChange={HandleOldPassword}
                      value={Oldpassword}
                      // inputProps={{
                      //   maxLength: 10,
                      // }}
                    />
                  </FormControl>

                  <FormControl fullWidth className="form-fields">
                    <FormLabel id="demo-radio-buttons-group-label">
                      New password
                    </FormLabel>

                    <CssTextField
                      id="new-login-email"
                      variant="outlined"
                      placeholder="New password"
                      type={showNewPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowNewPassword}
                              edge="end"
                              className="eye-icon"
                          >
                          {showNewPassword ? <Visibility />:<VisibilityOff />}
                          </IconButton>
                          </InputAdornment>
                      ),
                      }}
                      sx={{ width: "100%" }}
                      onChange={HandleNewPassword}
                      value={Newpassword}
                      inputProps={{
                        maxLength: 10
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth className="form-fields">
                    <FormLabel id="demo-radio-buttons-group-label">
                      Confirm password
                    </FormLabel>

                    <CssTextField
                      id="confirm-login-email"
                      variant="outlined"
                      placeholder="Confirm password"
                      type={showCnfrmPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowCnfrmPassword}
                              edge="end"
                              className="eye-icon"
                          >
                          {showCnfrmPassword ? <Visibility />:<VisibilityOff />}
                          </IconButton>
                          </InputAdornment>
                      ),
                      }}
                      sx={{ width: "100%" }}
                      onChange={HandleConfirmPassword}
                      value={Confirmpassword}
                      inputProps={{
                        maxLength: 10
                      }}
                    />
                  </FormControl>
                  <Button
                    className="signin-btn"
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                    onClick={UpdatePassword}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>


      {/* upload profile popup */}
      <Modal
        keepMounted
        open={changeProfilePopup}
        onClose={() => setChangeProfilePopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px"
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setChangeProfilePopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Box
                className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
              >
                <Typography 
                  variant="h5" 
                  style={{
                    color: "#faa61a",
                    cursor:"pointer",
                    marginBottom:"1rem",
                    display: "flex",
                    justifyContent: "center",
                    textDecoration: "underline",
                  }} 
                  >
                  <Box
                    onClick={()=>inputProfileFile.current.click()}
                    style={{display:"inline"}}
                    >
                    Upload New Photo
                  </Box>
                  <input
                    type="file"
                    onChange={HandleProfilepic}
                    accept="image/jpg, image/jpeg, image/png"
                    ref={inputProfileFile}
                    style={{display:"none"}}
                  />
                </Typography>
                <Box>
                  {loading && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer + 1
                        }}
                        open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}
                  <Box>
                    <img
                      src={Profilepic ? Profilepic : DummyImage}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* upload lincence popup */}
      <Modal
        keepMounted
        open={changeLicensePopup}
        onClose={() => setChangeLicensePopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px"
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setChangeLicensePopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Box
                className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
              >
                <Typography 
                  variant="h5" 
                  style={{
                    color: "#faa61a",
                    cursor:"pointer",
                    marginBottom:"1rem",
                    display: "flex",
                    justifyContent: "center",
                    textDecoration: "underline",
                  }} 
                  >
                  <Box
                    onClick={()=>inputLicencepic.current.click()}
                    style={{display:"inline"}}
                    >
                    Upload New Photo
                  </Box>
                    <input
                      type="file"
                      onChange={HandleLicece}
                      accept="image/jpg, image/jpeg, image/png"
                      ref={inputLicencepic}
                      style={{display:"none"}}
                    />
                </Typography>
                <Box>
                  <img
                    src={Licencepic?Licencepic:DummyImage}
                    alt=""
                    style={{ width: "100%" }}
                  />
                </Box>

                <Box>
                  {loading && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer + 1
                        }}
                        open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

    </MainLayout>
  );
};

export default Profileinfo;
