import React, { useEffect, } from "react";
import MainLayout from '../../layouts/MainLayout'
import { Box, Container, Typography, Link } from '@mui/material'
import './styles.scss'
import HandsImg from '../../assets/images/hand_holding_cellphone.png'
import Playstore from '../../assets/images/download_googleplay.png'
import AppStore from '../../assets/images/download_appstore.png'
//import AppQR from '../../assets/images/app_stpre_code.png'
import QRCode from "react-qr-code";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import { useDispatch, useSelector } from "react-redux";

const DownloadPage = () => {
    const [loading, setloading] = React.useState(false);
    const OnelinkQaCode = useSelector((state: IStoreValue) => state.equipmentReducer.qrCodeValue);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <MainLayout>
                <Box 
                    // className="app-download"
                    sx={{
                        padding: '3rem 0 0',
                    }}
                    >
                    <Container>
                        <Box 
                        // className="app-download-main"
                        >
                            <Typography 
                            // className="app-download-heading" 
                            sx={{
                                fontSize: 'clamp(35px,3vw, 50px) !important',
                                color: '#fff',
                                fontFamily: 'GothamBold !important',
                                textAlign: 'center',
                                margin: '0rem 0rem 2rem 0',
                                fontWeight: '900',
                            }}
                            variant="h1">
                                Download the App
                            </Typography>
                            <Typography 
                            // className="app-download-subheading"
                            sx={{
                                margin: '2rem auto',
                                color: '#faa61a',
                                fontSize: 'clamp(18px, 3vw, 25px)',
                                fontFamily: 'GothamBold !important',
                                width: {
                                    xs:'100%',
                                    sm:'100%',
                                    md:'70%',
                                    lg:'70%',
                                    xl: '70%'
                                },
                                textAlign: 'center',
                            }}
                            >
                                A Rental Yard that brings equipment owners and renters together...
                                all in your back pocket.
                            </Typography>
                            <Box 
                            // className="app-download-grid"
                            sx={{
                                display: 'flex',
                                gap: '2rem',
                                margin: '3rem 0',
                                flexDirection:{
                                    xs:'column',
                                    sm:'column',
                                    md:'row',
                                    lg:'row',
                                    xl:'row'
                                }
                            }}
                            >
                                <Box 
                                    // className="app-download-grid-item-left"
                                    sx={{
                                        width:{
                                            xs:'100%',
                                            sm:'100%',
                                            md: '50%',
                                            lg:'50%',
                                            xl:'50%'
                                        },
                                        order: {
                                            xs: '2',
                                            sm: '2',
                                            md: '1'
                                        }
                                    }}
                                >
                                    <Box component={'img'} src={HandsImg} alt=""
                                        sx={{
                                            display: 'block',
                                            width: '100%',
                                            height: 'auto',
                                            maxHeight: '500px',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </Box>
                                <Box 
                                    // className="app-download-grid-item-right"
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '100%',
                                            md: '50%',
                                            lg: '50%',
                                            xl: '50%'
                                        },
                                        order:{
                                            xs:'1',
                                            sm:'1',
                                            md:'2'
                                        }
                                    }}
                                >
                                    <Box 
                                        // className="app-download-grid-item-right-grid"
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '2rem',
                                            width:'unset',
                                        }}
                                        >
                                        <Box 
                                        // className="app-download-grid-item-right-grid-item-left"
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '1rem',
                                            width: 'unset !important',
                                        }}
                                        >
                                            <Link
                                                // className="nav-link desktop-visible"
                                                sx={{
                                                    display:{
                                                        xs:'none',
                                                        sm:'none',
                                                        md:'block',
                                                        lg:'block',
                                                        xl:'block'
                                                    }
                                                }}
                                                target="_blank"
                                                href="https://apps.apple.com/us/app/goequipme-peer-to-peer/id1502954874"
                                            >
                                                <Box component={'img'} src={AppStore} alt="" 
                                                    sx={{
                                                        display: 'block',
                                                        width: '150px',
                                                        height: '50px',
                                                        maxHeight: '50px',
                                                        objectFit: 'contain',
                                                    }}
                                                />
                                            </Link>
                                            <Link
                                                // className="nav-link desktop-visible"
                                                sx={{
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'none',
                                                        md: 'block',
                                                        lg: 'block',
                                                        xl: 'block'
                                                    }
                                                }}
                                                target="_blank"
                                                href="https://play.google.com/store/apps/details?id=com.goequipeme"
                                            >
                                                <Box component={'img'} src={Playstore} alt=""
                                                    sx={{
                                                        display: 'block',
                                                        width: '150px',
                                                        height: '50px',
                                                        maxHeight: '50px',
                                                        objectFit: 'contain',
                                                    }}
                                                />
                                            </Link>
                                        </Box>
                                        <Box 
                                            // className="app-download-grid-item-right-grid-item-right"
                                            sx={{
                                                display:'flex',
                                                alignItems:'center',
                                                justifyContent:{
                                                    xs:'center',
                                                    sm:'center',
                                                    md: 'flex-start',
                                                },
                                                width:'100%'
                                            }}
                                        >
                                            {/* <img src={AppQR} alt="" /> */}

                                            <QRCode
                                                size={256}
                                                style={{ height: "auto", maxWidth: "170px", border :"4px solid #fff" }}
                                                value={OnelinkQaCode}
                                                viewBox={`0 0 256 256`}
                                            />

                                        </Box>
                                    </Box>
                                    <Typography 
                                        // className="app-download-p"
                                        sx={{
                                            color:'#faa61a',
                                            fontFamily:'Montserrat !important',
                                            fontSize:{
                                                xs:'18px',
                                                sm:'19px',
                                                md:'22px',
                                                lg:'24px',
                                                xl:'25px'
                                            },
                                            mt:2,
                                            fontWeight:'500',
                                            textAlign:{
                                                xs:'center',
                                                sm:'center',
                                                md:'left'
                                            }
                                        }}
                                    >
                                        Available for Android and iPhone
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            </MainLayout>
        </>
    )
}
export default DownloadPage