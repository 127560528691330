// update chat thread function added by pp on 4 apr 2024
import { EquipmentAPIs } from "../service/api-service";
export const updateChatThread = () => {
  try {
    const lastActiveNotification = JSON.parse(
      localStorage.getItem("lastActiveNotification")
    );
    console.log("lastActive notification ===>", lastActiveNotification);
    if (lastActiveNotification && lastActiveNotification.sender_id) {
      const AuthUser = JSON.parse(localStorage.getItem("token-info"));
      if (AuthUser && AuthUser.auth_token) {
        const params = {
          sender_id: lastActiveNotification["sender_id"]?.toString(),
          recever_id: lastActiveNotification["recever_id"]?.toString(),
          equip_id: lastActiveNotification["equip_id"]?.toString(),
          user_id: AuthUser.user_id?.toString(),
        };
        const headers = {
          Authorization: `${AuthUser.auth_token}`,
          request_from: "Web",
        };
        const res = EquipmentAPIs.updateThread(params);
        console.log("chat thread updated ==>",res);
        localStorage.removeItem("lastActiveNotification");
        return true;
      }
    }
  } catch (err) {
    console.log("update chat thread error==>", err.message);
    return false;
  }
};
//end by pp