import {
  Box,
  Button,
  FormControl,
  Link,
  TextField,
  Typography,
  useMediaQuery,
  Slider,
  Backdrop,
  CircularProgress,
  Modal,
  IconButton,
  FormLabel,
  ToggleButtonGroup,
  ToggleButton,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  Dispatch
} from "react";
import "./styles.scss";
import ForSale from 'assets/images/for-sale.png';
import RequestReceived from 'assets/images/request-received.png';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

import LocationIcon from "assets/images/location.png";
import leftarrow from "assets/images/details-page/left-arrow.png";
import ThumbsImg from "assets/images/likeMe.png";
// import ThumbsImg from "assets/images/favorite-filled.png";
// import ThumbsImg from "assets/images/favorite-blank.png";
import check from "assets/images/check.png";
import dollar from "assets/images/go-dollar.png";

import msgg from "assets/images/details-page/msgg.png";
import visit from "assets/images/details-page/visit.png";

import EquipProtection from "assets/images/details-page/EquipmentProtection.png";
import calendarimg from "assets/images/details-page/calendar_two.png";
import bookingimg from "assets/images/details-page/booking2.png";
import addImg from "assets/images/details-page/Add.png";
import Img from "assets/images/dummy-profile-pic.jpeg";
import MainLayout from "layouts/MainLayout";
import { useParams,useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddBalance from "../../components/AddBalance/AddBalance";
import PaymentHold from "../../components/AddBalance/PaymentHold";
import CurrentFormat from "../../components/common/CurrenyFormat";
import {
  setModalOpen,
  setalertMessagetype,
  setalertMessage,
  setLogInLoading,
  setLoginStatus,
  setaccessToken,
  setLoginModalOpen,
  setSessionExpire,
  setQrCodeValue,
  setWalletBalance,
  SetRedirectPage
} from "redux/redux-slice";
import { EquipmentAPIs } from "../../service/api-service";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import moment from "moment";
import DatePicker from "react-multi-date-picker";
import { Calendar as NewCalendar } from "react-multi-date-picker";
import radioOff from "assets/images/radioOff.png";
import radioOn from "assets/images/radioOn.png";
import GOOGLELOCATION from "../../components/equipment-list/GoogleautoComplete";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Calendar } from "react-modern-calendar-datepicker";
import "./calendar.scss";
import { ReadableStreamDefaultController } from "stream/web";
import UserInfo from "./../../components/UserInfo/Userinfo";
import CloseIcon from "@mui/icons-material/Close";
import cross from "assets/images/Social-Icon/cross-delete.png";
//import DatePicker, { DateObject, getAllDatesInRange } from "react-multi-date-picker"
import DetailComponent from "../../components/EquipDetails/EquipDetail";

// timezone common function
import { convertTimeZone, convertTimeZoneShortName } from "utils/commonFxns";
import momentTimeZone from "moment-timezone";

const dollarUSLocale = Intl.NumberFormat("en-US");

const timeArr = [
  "12:30 AM",
  "01:00 AM",
  "01:30 AM",
  "02:00 AM",
  "02:30 AM",
  "03:00 AM",
  "03:30 AM",
  "04:00 AM",
  "04:30 AM",
  "05:00 AM",
  "05:30 AM",
  "06:00 AM",
  "06:30 AM",
  "07:00 AM",
  "07:30 AM",
  "08:00 AM",
  "08:30 AM",
  "09:00 AM",
  "09:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "01:00 PM",
  "01:30 PM",
  "02:00 PM",
  "02:30 PM",
  "03:00 PM",
  "03:30 PM",
  "04:00 PM",
  "04:30 PM",
  "05:00 PM",
  "05:30 PM",
  "06:00 PM",
  "06:30 PM",
  "07:00 PM",
  "07:30 PM",
  "08:00 PM",
  "08:30 PM",
  "09:00 PM",
  "09:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM",
  "12:00 AM"
];

const type = process.env.REACT_APP_PLATFORM;

let Base_url = 'https://www.goequipmestaging.com'; //Staging
if (type == "Live") {
  Base_url = 'https://goequipme.com';   // Live
}


const myCustomLocale = {
  // months list by order
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],
  // week days by order
  weekDays: [
    {
      name: "Sunday", // used for accessibility
      short: "Sun", // displayed at the top of days' rows
      isWeekend: true // is it a formal weekend or not?
    },
    {
      name: "Monday",
      short: "Mon"
    },
    {
      name: "Tuesday",
      short: "Tue"
    },
    {
      name: "Wednesday",
      short: "Wed"
    },
    {
      name: "Thursday",
      short: "Thu"
    },
    {
      name: "Friday",
      short: "Fri"
    },
    {
      name: "Saturday",
      short: "Sat",
      isWeekend: true
    }
  ],

  // just play around with this number between 0 and 6
  weekStartingIndex: 0,

  // return a { year: number, month: number, day: number } object
  getToday(gregorainTodayObject) {
    return gregorainTodayObject;
  },

  // return a native JavaScript date here
  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  },

  // return a number for date's month length
  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate();
  },

  // return a transformed digit to your locale
  transformDigit(digit) {
    return digit;
  },

  // texts in the date picker
  nextMonth: "Next Month",
  previousMonth: "Previous Month",
  openMonthSelector: "Open Month Selector",
  openYearSelector: "Open Year Selector",
  closeMonthSelector: "Close Month Selector",
  closeYearSelector: "Close Year Selector",
  defaultPlaceholder: "Select...",
  // for input range value
  from: "from",
  to: "to",
  // used for input value when multi dates are selected
  digitSeparator: ",",
  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,
  // is your language rtl or ltr?
  isRtl: false
};

const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4
};

const CssTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    color: "#2e2e2e",
    background: "#fff",
    fontFamily: "GothamBold !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    // border: "2px solid #faa61a",
  },
  "& label.Mui-focused": {
    color: "#faa61a",
    fontWeight: "bold",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#2e2e2e",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
    },
    "&:hover fieldset": {
      borderColor: "#2e2e2e",
      border: "2px solid #faa61a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
});

let trans_option_sel = null;
const EquipmentDetail = () => {

  const navigate = useNavigate();
  const [DeviceType, setDeviceType] = useState("large");
  const inRef = useRef(null);
  const searchRef = useRef();
  const datePickerCalendar = useRef(null);
  const APIKEY = process.env.REACT_APP_GEOCODDER_KEY;
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const WalletBalance = useSelector(
    (state: IStoreValue) => state.equipmentReducer.WalletBalance
  );
  const AuthUserID = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const isLoggedin = useSelector(
    (state: IStoreValue) => state.equipmentReducer.isLogin
  );

  const AuthProfileData = useSelector((state: IStoreValue) => state.equipmentReducer.AuthProfileData)
  const [OrderSuccessPopup, setOrderSuccessPopup] = useState(false);
  const [requestedOrderId,setRequestedOrderId] = useState<number>(0);

  const matchesMobileX = useMediaQuery('(max-width:425px)')

  const [loading, setLoading] = useState(true);
  const [OwnerInfo, setOwnerInfo] = useState(false);
  const [placeOrder, setplaceOrder] = useState(false);
  const [result, setResult] = useState({});
  const [img, setImg] = useState([]);
  const [images_gallerys, setimages_gallerys] = useState([]);
  const [blockdate, setBlockdate] = useState([]);
  const [Maplocation, setMaplocation] = useState("");
  const [mapLink, setMapLink] = useState("javascript:void(0)");
  const params = useParams();
  const [rescount, setRescount] = useState(0);
  const dispatch = useDispatch();
  const [deeplink, setDeeplink] = useState([]);
  const [ChaeckDatesBlockArr, setChaeckDatesBlockArr] = useState([]);

  const [rentStartDate, setrentStartDate] = useState("");
  const [rentEndDate, setrentEndDate] = useState("");
  const [startTime, setStartTime] = useState("08:00 AM");
  const [endTime, setEndTime] = useState("05:00 PM");
  const [rentPrice, setRentPrice] = useState(null);
  const [securityDeposit, setSecurityDeposit] = useState(0);
  const [equipProtection, setEquipProtection] = useState(0);
  const [rentalTotal, setRentalTotal] = useState(0);
  const [chooseTransport, setchooseTransport] = React.useState(null);
  const [transportTotal, settransportTotal] = useState(0);
  const [values, setValues] = useState(ChaeckDatesBlockArr);
  const [mobilecalendarstartDate, setmobilecalendarstartDate] = useState(null);
  const [mobilecalendarendDate, setmobilecalendarendDate] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [Distance, setDistance] = useState(0);
  const [TotalDays, setTotalDays] = useState("0");
  const [grandTotal, setgrandTotal] = useState(0);
  const [deliveryPrice, setdeliveryPrice] = useState(0);
  const [FullAddress, setFullAddress] = useState({
    city: "",
    state: "",
    country: "",
    postal_code: "",
    address: ""
  });
  const [deliveryerror, setdeliveryerror] = useState(false);
  const [checkoutsection, setcheckoutsection] = useState(false);
  const [proceedpaysection, setproceedpaysection] = useState(false);

  const [transportcalculating, settransportcalculating] = React.useState(false);
  const defaultdeliveryLocation = {
    lat: 39.321980897034734,
    lng: -111.10149320091872
  };
  const [deliveryLocation, setdeliveryLocation] = React.useState(
    defaultdeliveryLocation
  );
  const [DeliveryInstructionsval, setDeliveryInstructionsval] = useState("");
  const [DeliveryInstructionsvallength, setDeliveryInstructionsvallength] =
    useState(400);
  // const [WalletBalance, setWalletBalance] = useState(0);
  const [LoadWalletBalance, setLoadWalletBalance] = useState(false);
  const [addbalancebox, setaddbalancebox] = React.useState(false);
  const [balanceamount, setbalanceamount] = React.useState("");
  const [balancecard, setbalancecard] = React.useState(null);
  const [balancecardholdername, setbalancecardholdername] =
    React.useState(null);
  const [balancecardexpmonth, setbalancecardexpmonth] = React.useState(null);
  const [balancecardexpyear, setbalancecardexpyear] = React.useState(null);
  const [balancecardcvv, setbalancecardcvv] = React.useState(null);
  const [dateselectsection, setdateselectsection] = React.useState(false);
  const [rentersummaymobile, setrentersummaymobile] = React.useState(false);
  const [tabviewcalendar, settabviewcalendar] = React.useState(2);
  const [LikeStatus, setLikeStatus] = React.useState(null);
  const [LikeLoading, setLikeLoading] = React.useState(false);

  //use for pay order request amount with card
  // card payment hold
  const [payWithCard, setpayWithCard] = React.useState(false);
  const [holdAmount, setholdAmount] = React.useState(0);
  const [orderRequestData, setorderRequestData] = React.useState({});

  // new states for start Time and End Time in calendar pop up
  const hoursArray = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12"
  ];

  // const minutesArray = ["00", "30"]
  const [startHours, setStartHours] = useState(7);
  const [startMinutes, setStartMinutes] = useState("00");
  const [endHours, setEndHours] = useState(4);
  const [endMinutes, setEndMinutes] = useState("00");
  const [selectedStartTime, setSelectedStartTime] = useState("AM");
  const [selectedEndTime, setSelectedEndTime] = useState("PM");
  const [autoCompAddress, setAutoCompAddress] = useState("");
  const [EquipListType, setEquipListType] = useState('');
  //timezone task by pp 28 mar 2024 to show the timezone in calendar
  const [timeZone,setTimeZone] = useState("UTC");
  const [timeZoneAbbr,setTimeZoneAbbr] = useState("");
  const [calendarStartDate, setCalendarStartDate] = useState(new Date());
  // let timeObj = {
  //   startHours, startMinutes, endHours, endMinutes, selectedStartTime, selectedEndTime
  // };

  /**
   * handleStartEndTime Function:
   * Update Start and End Time Based on User Interaction
   * Description:
   * this fxn is for footer part of calendar section to select delivery start and end time
   * This function is called when there is an interaction with the start and end time UI elements.
   * It handles adjustments to the start and end time values, including incrementing/decrementing
   * hours and toggling between AM and PM.
   *
   * @param {Object} event - The event object representing the user interaction.
   * @param {string} key - A key indicating the specific action to be performed (e.g., "startHoursPlus").
   */
  const handleStartEndTime = (event, key) => {
    event.stopPropagation(); // Prevent event propagation
    // console.log("event ", event);
    // console.log("key ", key);

    switch (key) {
      case "startHoursPlus": {
        let sTime = `${startHours === 11 ? hoursArray[0] : hoursArray[startHours + 1]}:${startMinutes} ${selectedStartTime}`
        // console.log("startHoursPlus ", sTime);
        setStartTime(sTime);
        // setStartTime(
        //   `${startHours === 11 ? hoursArray[0] : hoursArray[startHours + 1]
        //   }:${startMinutes} ${selectedStartTime}`
        // );
        startHours === 11 ? setStartHours(0) : setStartHours(startHours + 1);
        // console.log("new startTime : " , startTime);
        calculatePrice(startDate, endDate , sTime , endTime);
        break;
      }

      case "startHoursMinus": {
        let sTime = `${startHours === 0 ? hoursArray[11] : hoursArray[startHours - 1]}:${startMinutes} ${selectedStartTime}`;
        // console.log("startHoursMinus ", sTime);
        setStartTime(sTime);
        // setStartTime(
        //   `${startHours === 0 ? hoursArray[11] : hoursArray[startHours - 1]
        //   }:${startMinutes} ${selectedStartTime}`
        // );
        startHours === 0 ? setStartHours(11) : setStartHours(startHours - 1);
        // console.log("new startTime : " , startTime);
        calculatePrice(startDate, endDate , sTime , endTime);
        break;
      }

      case "startMinutesToggle": {
        let sTime = `${hoursArray[startHours]}:${startMinutes === "00" ? "30" : "00"} ${selectedStartTime}`
        // console.log("startMinutesToggle ", sTime);
        setStartTime(sTime);
        // setStartTime(
        //   `${hoursArray[startHours]}:${startMinutes === "00" ? "30" : "00"
        //   } ${selectedStartTime}`
        // );
        setStartMinutes(startMinutes === "00" ? "30" : "00");
        calculatePrice(startDate, endDate , sTime , endTime);
        break;
      }

      case "toggleStartTime": {
        let sTime = `${hoursArray[startHours]}:${startMinutes} ${selectedStartTime === "AM" ? "PM" : "AM"}`
        // console.log("toggleStartTime ", sTime);
        setStartTime(sTime);
        // setStartTime(
        //   `${hoursArray[startHours]}:${startMinutes} ${selectedStartTime === "AM" ? "PM" : "AM"
        //   }`
        // );
        setSelectedStartTime(selectedStartTime === "AM" ? "PM" : "AM");
        calculatePrice(startDate, endDate , sTime , endTime);
        break;
      }

      case "endHoursPlus": {
        let eTime = `${endHours === 11 ? hoursArray[0] : hoursArray[endHours + 1]}:${endMinutes} ${selectedEndTime}`
        // console.log("endHoursPlus ", eTime);
        setEndTime(eTime);
        // setEndTime(
        //   `${endHours === 11 ? hoursArray[0] : hoursArray[endHours + 1]
        //   }:${endMinutes} ${selectedEndTime}`
        // );
        endHours === 11 ? setEndHours(0) : setEndHours(endHours + 1);
        // console.log("new EndTime : " , endTime);
        calculatePrice(startDate, endDate , startTime , eTime);
        break;
      }

      case "endHoursMinus": {
        let eTime = `${endHours === 0 ? hoursArray[11] : hoursArray[endHours - 1]}:${endMinutes} ${selectedEndTime}`
        // console.log("endHoursMinus ", eTime);
        setEndTime(eTime);
        // setEndTime(
        //   `${endHours === 0 ? hoursArray[11] : hoursArray[endHours - 1]
        //   }:${endMinutes} ${selectedEndTime}`
        // );
        endHours === 0 ? setEndHours(11) : setEndHours(endHours - 1);
        // console.log("new startTime : " , endTime);
        calculatePrice(startDate, endDate , startTime , eTime);
        break;
      }

      case "endMinutesToggle": {
        let eTime = `${hoursArray[endHours]}:${endMinutes === "00" ? "30" : "00"} ${selectedEndTime}`
        // console.log("endMinutesToggle ", eTime);
        setEndTime(eTime);
        // setEndTime(
        //   `${hoursArray[endHours]}:${endMinutes === "00" ? "30" : "00"
        //   } ${selectedEndTime}`
        // );
        setEndMinutes(endMinutes === "00" ? "30" : "00");
        calculatePrice(startDate, endDate , startTime , eTime);
        break;
      }

      case "toggleEndTime": {
        let eTime =  `${hoursArray[endHours]}:${endMinutes} ${selectedEndTime === "AM" ? "PM" : "AM"}`
        // console.log("toggleEndTime ", eTime);
        setEndTime(eTime);
        // setEndTime(
        //   `${hoursArray[endHours]}:${endMinutes} ${selectedEndTime === "AM" ? "PM" : "AM"
        //   }`
        // );
        setSelectedEndTime(selectedEndTime === "AM" ? "PM" : "AM");
        calculatePrice(startDate, endDate , startTime , eTime);
        break;
      }
      default: {
        return;
      }
    }
  };

  var images = [];
  var images_gallery = [];

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const minimumDate = {
    year: year,
    month: month,
    day: day
  };
  const defaultRange = {
    from: null,
    to: null
  };
  const [selectedDay, setselectedDay] = useState(minimumDate);
  const [selectedDayRange, setSelectedDayRange] = useState(defaultRange);

  // Calendar Popup
  const [CalendarPopup, setCalendarPopup] = useState(false);
  const [CalendarPopupResult, setCalendarPopupResult] = useState({
    start: null,
    end: null
  });
  // Transaport option popup
  const [transportoptionpopup, settransportoptionpopup] = useState(false);

  /**
   * viewEquipment Function:
   * Fetch Equipment Details for Display
   * Description:
   * This function fetches equipment details based on the provided equipment ID.
   * It handles loading state, fetches equipment information, and updates various
   * states for rendering equipment details on the UI, including images, location,
   * and availability dates.
   *
   * @param {string} id - The ID of the equipment for which details are to be fetched.
   */
  // View Equipment Detail
  const viewEquipment = async (id = null) => {
    try {
      setLoading(true);

      // Fetch equipment details
      const equipments: IApiResponseRawData = await EquipmentAPIs.viewEquipment(
        {
          id: id
        }
      );
      if (equipments.data.rowCount > 0) {
        setRescount(1);
        let viewresult = equipments.data.rows[0][0];
        setResult(viewresult);
        let deeplink_urls = equipments.data.deep_links;
        setDeeplink(deeplink_urls);
        setLoading(false);

        // Process block and booked dates
        let block_dates = viewresult["block_dates"];
        let book_dates = "," + equipments.data.rows[1];
        block_dates = block_dates;
        let dates = block_dates.concat(book_dates);
        let block_arr = dates.split(",");
        let mainblockArr = [];
        let ChaeckDatesBlockArr = [];

        block_arr.map((item, index) => {
          if (item != "" && (item != "0" || item != 0)) {
            let item_date = item.split("-");
            var startDay = item_date[2] > 9 ? item_date[2] : "0" + item_date[2];
            var startMonth =
              item_date[1] > 9 ? item_date[1] : "0" + item_date[1];
            var startYear = item_date[0];
            var blk_date = startMonth + "/" + startDay + "/" + startYear;
            var _block_ds = moment(blk_date).format("YYYY-MM-DD");
            //   let _block_d = moment(item).format("YYYY-MM-DD");
            let _today = moment(new Date()).format("YYYY-MM-DD");
            let block = Date.parse(_block_ds);

            // Filter out past dates
            if (Date.parse(_today) <= block) {
              let b_dates = {
                year: Number(moment(_block_ds).format("YYYY")),
                month: Number(moment(_block_ds).format("M")),
                day: Number(moment(_block_ds).format("D")),
                className: "block_day"
              };
              mainblockArr.push(b_dates);
              let dd = moment(_block_ds).format("YYYY-M-D");
              ChaeckDatesBlockArr.push(dd);
            }
          }
        });
        setChaeckDatesBlockArr(ChaeckDatesBlockArr);
        setBlockdate(mainblockArr);

        // Gallery Section
        let img = viewresult["images"].split(",");
        setImg(img);
        setLoading(false);
        // if (img.length > 0) {
        //   img.map((imgs, idx) =>
        //     images_gallery.push({
        //       original: imgs,
        //       thumbnail: imgs,
        //       thumbnailHeight: "70px"
        //     })
        //   );
        // }

        // by GD 29 Jan 2024 new code for images old code commented up
        if (img.length > 0) {
          // console.log("images in details: " , img);
          
          img.map((imgs, idx) =>{
            if(imgs){
              images_gallery.push({
                original: imgs,
                thumbnail: imgs,
                thumbnailHeight: "70px"
              })
            }
          }
          );
        }
        setimages_gallerys(images_gallery);

        // Map location Section
        //let mapLink ="https://www.google.com/maps?q=" +viewresult["lat"] +"," +viewresult["lng"];

        let mapLink =
          "https://www.google.com/maps/@" +
          viewresult["lat"] +
          "," +
          viewresult["lng"] +
          ",11z?hl=en&entry=ttu";

        setMapLink(mapLink);
        // Create google map image
        var lat = viewresult["lat"];
        var lng = viewresult["lng"];
        var lati = lat.toString();
        lati = lati.split(".");
        var num = lati[1];
        var digits = num.toString().split("");
        var last = digits.map(Number);
        lat = lati[0] + "." + last[0] + last[1] + last[2] + last[3];
        var long = lng.toString();
        long = long.split(".");
        num = long[1];
        digits = num.toString().split("");
        var last = digits.map(Number);
        long = long[0] + "." + last[0] + last[1] + last[2] + last[3];
        var map_location =
          "https://maps.googleapis.com/maps/api/staticmap?center=" +
          lat +
          "," +
          long +
          "&size=360x400&zoom=11&maptype=roadmap&path=color:0x83827f8c|weight:150|" +
          lat +
          "," +
          long +
          "|" +
          lat +
          "1," +
          long +
          "1&path=color:0xf9f8f63b|weight:125|" +
          lat +
          "," +
          long +
          "|" +
          lat +
          "1," +
          long +
          "1&key=" +
          APIKEY;
        setMaplocation(map_location);
        window.scrollTo(0, 0);

        // timezone project by pp on 28 Mar 2024  
        const {time,currentDate} = convertTimeZone(viewresult["timezone"]);
        console.log("timezone=====",time,currentDate, " : " , viewresult["timezone"]);
        if(viewresult["timezone"]) {
          setTimeZone(viewresult["timezone"]);
          const timeZoneAbbr = convertTimeZoneShortName(momentTimeZone().tz(viewresult["timezone"]).zoneAbbr());
          setTimeZoneAbbr(timeZoneAbbr);
        }
        setCalendarStartDate(new Date(currentDate));
        // end by PP
       
      } else {
        setLoading(false);
        setRescount(0);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setRescount(0);
    }
  };

  //equipment date and time update according to timezone by pp 29 mar 2024
  const updateEquipmentTimezone = (start:string) => {
    const {time,currentDate,hours,minutes,timeSign} = convertTimeZone(timeZone);
    if(start == moment(currentDate).format("YYYY/MM/DD").toString()) {
      setStartTime(time);
      setStartHours(hours-1);
      setStartMinutes(minutes);
      setSelectedStartTime(timeSign);
      console.log("date mathed");
    } else {
      setStartTime("08:00 AM");
      setStartHours(7);
      setStartMinutes("00");
      setSelectedStartTime("AM");
    }
  }
  //end by PP

  // Remove AM or PM from time string
  const RemoveAMPMandTimeFormat = (time_str) => {
    let am_check = time_str.includes("AM");
    if (am_check) {
      time_str = time_str.slice(0, -3);
    }
    let pm_check = time_str.includes("PM");
    if (pm_check) {
      time_str = time_str.slice(0, -3);
      var new_time_arr = time_str.split(":");
      var new_time = parseInt(new_time_arr[0]);
      if (new_time_arr[0] != 12 || new_time_arr[0] != "12") {
        new_time = new_time + 12;
      }
      time_str = new_time + ":" + new_time_arr[1];
    }
    return time_str;
  };


  /**
   * calculatePrice Function:
   * Calculate Rental Price and Related Costs
   * Description:
   * This function calculates the rental price, security deposit, equipment protection fee,
   * and the overall rental total based on the provided rental start and end dates. It also
   * considers additional factors such as pickup time and delivery charges.
   *
   * @param {Date} __rentStartDate - Start date of the rental period.
   * @param {Date} __rentEndDate - End date of the rental period.
   */
  // Calculate Rental price
  // const calculatePrice = (__rentStartDate, __rentEndDate) => {
    // two new params added by GD on 20 Feb 2024 due to error in startTime and endTime
  const calculatePrice = (__rentStartDate, __rentEndDate , sTime = startTime , eTime = endTime) => {
    // console.log("__rentStartDate: " , __rentStartDate , "__rentEndDate: " , __rentEndDate);
    console.log("startTime: " , sTime , "endTime: " , eTime);
    
    if (__rentStartDate && __rentEndDate) {
      // var _startTime = RemoveAMPMandTimeFormat(startTime);
      // var _endTime = RemoveAMPMandTimeFormat(endTime);
      // by GD 20 Feb 2024
      var _startTime = RemoveAMPMandTimeFormat(sTime);
      var _endTime = RemoveAMPMandTimeFormat(eTime);
      // end by GD
      __rentEndDate = moment(__rentEndDate).format("YYYY/MM/DD");
      __rentStartDate = moment(__rentStartDate).format("YYYY/MM/DD");
      var _rentStartDate = __rentStartDate.split("/").join("-");
      var _rentEndDate = __rentEndDate.split("/").join("-");

      // Check for invalid pickup time
      if (_rentStartDate == _rentEndDate && _endTime < _startTime) {
        dispatch(setalertMessage("The selected pickup time is invalid."));
        dispatch(setalertMessagetype("error"));
        setRentPrice(0);
        setSecurityDeposit(0);
        setEquipProtection(0);
        setRentalTotal(0);
        setdeliveryPrice(0);
        setgrandTotal(0);
        return false;
      }

      var st = moment(_rentStartDate + " " + _startTime);
      var en = moment(_rentEndDate + " " + _endTime);
      var difference = en.valueOf() - st.valueOf();

      // Calculate difference in days
      var Difference_In_Days = difference / (1000 * 3600 * 24);
      var totalDays = Difference_In_Days.toFixed();
      setTotalDays(totalDays);
      let seconds = difference / 1000;
      var months = Math.floor(seconds / (3600 * 24 * 30));
      var weeks = Math.floor((seconds % (3600 * 24 * 30)) / (3600 * 24 * 7));
      var days = Math.floor(
        ((seconds % (3600 * 24 * 30)) % (3600 * 24 * 7)) / (3600 * 24)
      );
      var hours = Math.floor((seconds % (3600 * 24)) / 3600);
      if (months > 0 && weeks > 0) {
        days = days + weeks * 7;
        weeks = 0;
      }
      let monthPrice = Number(result["price_month"]),
        weekPrice = Number(result["price_per_week"]),
        dayPrice = months > 0 ? monthPrice / 30 : Number(result["price_day"]),
        hourPrice = Number(result["price_per_hour"]);
      var priceH = 0,
        priceD = 0,
        priceW = 0,
        priceM = 0;
      priceH = hours * hourPrice > dayPrice ? dayPrice : hours * hourPrice;
      if (months > 0) {
        priceD = days * dayPrice + priceH;
      } else {
        priceD =
          days * dayPrice + priceH > weekPrice
            ? weekPrice
            : days * dayPrice + priceH;
      }
      priceW =
        weeks * weekPrice + priceD > monthPrice
          ? monthPrice
          : weeks * weekPrice + priceD;
      priceM = months * monthPrice + priceW;
      var total = priceM;
      if (months == 0) {
        total = total > monthPrice ? monthPrice : total;
      }
      if (months == 0 && weeks == 0) {
        total = total > weekPrice ? weekPrice : total;
      }

      // total = Math.round(total);
      //console.log('total',total);
      setRentPrice(total);

      // Calculate security deposit and equipment protection fee
      let security_amt = (total / 100) * 20;
      //security_amt = Math.round(security_amt);

      let equipeprotection_amt = (total / 100) * 15;
      //equipeprotection_amt = Math.round(equipeprotection_amt);

      /**************/
      // fraction number issue fix
      let p = equipeprotection_amt.toFixed(2);
      equipeprotection_amt = parseFloat(p);

      let s = security_amt.toFixed(2);
      security_amt = parseFloat(s);
      /**************/
      let rental_total = total + security_amt + equipeprotection_amt;

      let ab =
        Number(rental_total) -
        Number(security_amt) -
        Number(equipeprotection_amt);
      // -Number(deliveryPrice)    

      setRentalTotal(rental_total);
      setSecurityDeposit(security_amt);
      setEquipProtection(equipeprotection_amt);
      let transport_chrg = 0;

      // Consider delivery charges if the transport type is owner
      if (chooseTransport == "owner") {
        transport_chrg =
          Number(result["minimum_delivery_charges"]) + Number(deliveryPrice);
      }
      setgrandTotal(Number(rental_total) + Number(transport_chrg));
    }
  };

  // Check Date is Reserved or not
  const isReserved = (strDate) => {
    if (result["weekends"] == "0") {
      if (moment(strDate).format("ddd") == "Sat") {
        return true;
      }
    }
    if (result["sunday"] == "0") {
      if (moment(strDate).format("ddd") == "Sun") {
        return true;
      }
    }

    // console.log('ChaeckDatesBlockArr',ChaeckDatesBlockArr);
    if (ChaeckDatesBlockArr.includes(strDate)) {
      return true;
    }
    return false;
  };

  // create dynamic QR code
  const createQRcode = (qrvalue: string) => {

    dispatch(setQrCodeValue(qrvalue));
    dispatch(setModalOpen(true));
  };


  /**
   * HandleDateRangPicket Function:
   * Handle Selection of Rental Start and End Dates
   * Description:
   * This function handles the selection of rental start and end dates. It checks if the selected
   * dates are valid and not blocked. If valid, it updates the component state and triggers the
   * calculation of rental prices based on the selected dates.
   *
   * @param {Date} start - Selected rental start date.
   * @param {Date} end - Selected rental end date.
   */
  // Select date range
  const HandleDateRangPicket = (start, end) => {
    if (start || end) {
      let flag = "false";

      // Check if the selected dates are not blocked
      ChaeckDatesBlockArr.map((itm, ind) => {
        let b_date = moment(itm).format("YYYY/MM/DD");
        if (b_date == start || b_date == end) {
          flag = "true";
          return false;
        }
        let foundDateInRange = moment(b_date).isBetween(start, end);
        if (foundDateInRange) {
          flag = "true";
          return false;
        }
      });

      // If dates are not blocked, update the component state
      if (flag !== "true") {
        let a = [start, end];
        setValues(a);

        // Update rental start date state
        if (start) {
          setStartDate(start);
          setrentStartDate(moment(start).format("M/D/YYYY"));
        }

        // Update rental end date state
        if (end) {
          setrentEndDate(moment(end).format("M/D/YYYY"));
          setEndDate(end);
        }

        // Trigger calculation of rental prices based on the selected dates
        if (start && end) {
          calculatePrice(start, end);
        }
      } else {

        // If dates are blocked, only update the end date
        let a = [end];
        setValues(a);
        return false;
      }
    }
    return;
  };

  /**
   * GetDifferenceinmin Function:
   * Calculate the Difference in Minutes between Rental Start and End Times
   * Description:
   * This function calculates the difference in minutes between the rental start and end times.
   * It takes into account the start and end dates, converting them to the appropriate format.
   * The result is the time difference in minutes, and if the rental start time is before the
   * current time, it returns null to indicate an invalid time selection.
   *
   * @param {Date} start - Selected rental start date and time.
   * @param {Date} end - Selected rental end date and time.
   * @returns {number|null} - The difference in minutes or null if the start time is invalid.
   */
  // Calculate  Difference in minutes bitween start and end dates
  const GetDifferenceinmin = (start, end) => {
    // Remove AM/PM and get the formatted start and end times
    var _startTime = RemoveAMPMandTimeFormat(startTime);
    var _endTime = RemoveAMPMandTimeFormat(endTime);

    // Format the rental start and end dates
    let rentEndDate = moment(endDate).format("YYYY-MM-DD");
    let rentStartDate = moment(startDate).format("YYYY-MM-DD");

    // Update start and end dates if provided
    if (start && end) {
      //console.log("start and End : ", { start, end });
      rentEndDate = moment(end).format("YYYY-MM-DD");
      rentStartDate = moment(start).format("YYYY-MM-DD");
    }

    // Calculate the time difference in minutes // Calculate the time difference in minutes
    var st = moment(rentStartDate + " " + _startTime);
    var en = moment(rentEndDate + " " + _endTime);

    // var difference = en.valueOf() - st.valueOf();
    let res = en.diff(st, "minute");

    // Calculate the difference between the current time and the rental start time
    // let tDay = moment();
    // by pp 29 mar 2024 comparing time according to equipment timzone
    let {dateTime:tDay} = convertTimeZone(timeZone);
    let diff = st.diff(tDay, "minute");
    console.log("tday and diff: " , tDay," : " , diff);
    // end by pp
    // Return null if the rental start time is before the current time
    if (diff <= 0) {
      return null;
    }

    return res;
  };


  /**
   * validateData Function:
   * Validate Rental Data Before Proceeding to Checkout
   * Description:
   * This function validates the rental data before allowing the user to proceed to checkout.
   * It checks if the user is logged in, completes their profile, selects valid pickup and return times,
   * and provides necessary transportation details. It also handles error messages and opens pop-ups
   * for selecting dates or transportation options when needed.
   *
   * @param {string} from - Optional parameter indicating the source of the function call.
   * @returns {boolean} - Returns true if the data is valid, false otherwise.
   */
  // Validation for web view
  const validateData = async (from = null) => {
    try {
      if (isLoggedin) {

        // Get user profile data
        let profile = AuthProfileData;

        let profile_status = profile['profile_image'];
        let licence_status = profile['licence_pic'];

        // Check if the user profile is complete
        if (profile_status == null || profile_status == "null" || licence_status == null || licence_status == "null") {
          dispatch(setalertMessage('Please Complete your Profile First.'));
          dispatch(setalertMessagetype('error'));
          return false
        }

        else if (startDate && endDate) {

          // Calculate the difference in minutes between pickup and return times
          let diffMins = GetDifferenceinmin(startDate, endDate);

          if (diffMins === null) {
            dispatch(
              setalertMessage("The selected pickup time is in the past.")
            );
            dispatch(setalertMessagetype("error"));
            return false;
          }

          // Check if the pickup time is in the past
          if (diffMins > 0) {

            // Check if transportation option is selected
            if (from == "fromPopUp" && chooseTransport == null) {
              dispatch(setalertMessage("Please select a TRANSPORT option."));
              dispatch(setalertMessagetype("error"));
              return false;
            }

            // Open select Transportation popUp if no option is selected
            if (chooseTransport == null) {
              //Open select Transportation popUp
              settransportoptionpopup(true);
              return false;
            }

            // Check transportation details based on owner or renter selection
            if (chooseTransport == "owner" || chooseTransport == "renter") {

              // Check if delivery location is entered (for owner) and available
              if (
                (FullAddress.address == null ||
                  FullAddress.address == "" ||
                  FullAddress.address == "null") &&
                chooseTransport == "owner"
              ) {
                dispatch(
                  setalertMessage("Enter delivery location to proceed.")
                );
                dispatch(setalertMessagetype("error"));
                return false;
              }

              // Check if delivery is not available to the location
              if (chooseTransport == "owner" && deliveryerror) {
                dispatch(
                  setalertMessage("Delivery is not available to your location.")
                );
                dispatch(setalertMessagetype("error"));
                return false;
              }
              // Scroll to the top of the page
              window.scrollTo(0, 0);

              // Get user ID from localStorage
              let auth_user = localStorage.getItem("token-info");
              auth_user = JSON.parse(auth_user);
              let user_id = auth_user["user_id"];

              // Check if the equipment owner is the same as the logged-in user
              if (result["user_id"] == user_id) {
                dispatch(setalertMessage("You Can't own equipment order ."));
                dispatch(setalertMessagetype("error"));
                return false;
              }

              // Close transportation option pop-up if called from there
              if (from === "fromPopUp") {
                settransportoptionpopup(false);
                // return false;
              }

              // Proceed to checkout and get wallet balance for payment
              setcheckoutsection(true);
              getWalletBalanceadPay();
            } else {
              dispatch(setalertMessage("Please select a TRANSPORT option."));
              dispatch(setalertMessagetype("error"));
              return false;
            }
          } else {
            dispatch(
              setalertMessage("Select pickup time is less than return time")
            );
            dispatch(setalertMessagetype("error"));
            return false;
          }
        } else {
          // dispatch(setalertMessage("Please select rental start and end date."));
          // dispatch(setalertMessagetype("error"));

          // Open Calendar Popup for Select rent Dates
          setCalendarPopup(true);
          setCalendarPopupResult({
            start: null,
            end: null
          });
        }
      } else {
        //dispatch(setalertMessage('Oops, you must first Sign In.'));
        //dispatch(setalertMessagetype('error'));

        // Open login modal if the user is not logged in
        dispatch(setLoginModalOpen(true));
      }
    } catch (error) {
      settransportcalculating(false);
    }
  };

  /**
   * validateDataMobile Function:
   * Validate Rental Data for Mobile View Before Showing Summary
   * Description:
   * This function validates the rental data for mobile view before showing the summary.
   * It checks if the user is logged in, completes their profile, and selects valid pickup and return times.
   * It also handles error messages and opens pop-ups when needed.
   *
   * @returns {boolean} - Returns true if the data is valid, false otherwise.
   */
  // Validation for mobile view
  const validateDataMobile = async () => {
    try {
      if (isLoggedin) {

        // Get user profile data
        let profile = AuthProfileData;

        let profile_status = profile['profile_image'];
        let licence_status = profile['licence_pic'];

        // Check if the user profile is complete
        if (profile_status == null || profile_status == "null" || licence_status == null || licence_status == "null") {
          dispatch(setalertMessage('Please Complete your Profile First.'));
          dispatch(setalertMessagetype('error'));
          return false
        }

        // Calculate the difference in minutes between pickup and return times
        else if (startDate && endDate) {
          let diffMins = GetDifferenceinmin(startDate, endDate);

          // Check if the pickup time is greater than the return time
          if (diffMins > 0) {

            // Get user ID from localStorage
            let auth_user = localStorage.getItem("token-info");
            auth_user = JSON.parse(auth_user);
            let user_id = auth_user["user_id"];

            // Check if the equipment owner is the same as the logged-in user
            if (result["user_id"] == user_id) {
              dispatch(setalertMessage("You Can't own equipment order ."));
              dispatch(setalertMessagetype("error"));
              return false;
            }

            // Show mobile summary and scroll to the top of the page
            setrentersummaymobile(true);
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 200);
          } else {
            dispatch(
              setalertMessage("Select pickup time is less than return time")
            );
            dispatch(setalertMessagetype("error"));
            return false;
          }
        } else {
          dispatch(setalertMessage("Please select rental start and end date."));
          dispatch(setalertMessagetype("error"));
        }
      } else {
        //dispatch(setalertMessage('Oops, you must first Sign In.'));
        //dispatch(setalertMessagetype('error'));

        // Open login modal if the user is not logged in
        dispatch(setLoginModalOpen(true));
      }
    } catch (error) {
      settransportcalculating(false);
    }
  };

  /**
   * validatedeliveroptionMobile Function:
   * Validate Delivery Option for Mobile View Before Proceeding
   * Description:
   * This function validates the delivery option for mobile view before proceeding with the rental.
   * It checks if the user is logged in, completes their profile, and selects a valid transport option.
   * It also checks for a valid delivery location and handles error messages accordingly.
  */
  // Delivery option validation
  const validatedeliveroptionMobile = async () => {
    try {
      if (isLoggedin) {

        // Get user profile data
        let profile = AuthProfileData;

        let profile_status = profile['profile_image'];
        let licence_status = profile['licence_pic'];

        // Check if the user profile is complete
        if (profile_status == null || profile_status == "null" || licence_status == null || licence_status == "null") {
          dispatch(setalertMessage('Please Complete your Profile First.'));
          dispatch(setalertMessagetype('error'));
          return false
        }


        // Check if the chosen transport option is owner or renter
        else if (chooseTransport == "owner" || chooseTransport == "renter") {
          if (
            (FullAddress.address == null ||
              FullAddress.address == "" ||
              FullAddress.address == "null") &&
            chooseTransport == "owner"
          ) {
            dispatch(setalertMessage("Enter delivery location to proceed."));
            dispatch(setalertMessagetype("error"));
            return false;
          }

          if (chooseTransport == "owner" && deliveryerror) {
            dispatch(
              setalertMessage("Delivery is not available to your location.")
            );
            dispatch(setalertMessagetype("error"));
            return false;
          }

          // Scroll to the top of the page and proceed with wallet balance and payment
          window.scrollTo(0, 0);
          getWalletBalanceadPay();
        } else {
          dispatch(setalertMessage("Please select a TRANSPORT option."));
          dispatch(setalertMessagetype("error"));
          // settransportoptionpopup(true);
        }
      } else {
        //dispatch(setalertMessage('Oops, you must first Sign In.'));
        //dispatch(setalertMessagetype('error'));

        // Open login modal if the user is not logged in
        dispatch(setLoginModalOpen(true));
      }
    } catch (error) {
      settransportcalculating(false);
    }
  };


  /**
   * RenterRadioClick Function:
   * Handle Click Event for Renter Transport Option
   * Description:
   * This function is triggered when the renter transport option is selected.
   * It updates the chosen transport option state, resets the delivery address,
   * and calculates the new grand total with any additional charges.
  */
  const RenterRadioClick = () => {
    let transport_chrg = 0;
    trans_option_sel = "renter";

    // Set chosen transport option to "renter" and reset delivery address
    setchooseTransport("renter");
    setFullAddress({
      city: "",
      state: "",
      country: "",
      postal_code: "",
      address: ""
    });

    // Calculate the new grand total with any additional charges
    let grnt_total = Number(rentalTotal) + Number(transport_chrg);
    setgrandTotal(grnt_total);

    // Reset the autocomplete address field
    setAutoCompAddress("");
  };

  /**
   * OwnerRadioClick Function:
   * Handle Click Event for Owner Transport Option
   * Description:
   * This function is triggered when the owner transport option is selected.
   * It checks if the owner pickup and return option is available for the item.
   * If available, it updates the chosen transport option state, resets the delivery price,
   * sets the minimum delivery charges, and calculates the new grand total with any additional charges.
   * If not available, it displays an error message.
  */
  const OwnerRadioClick = () => {
    let transport_chrg = 0;

    // Check if the owner pickup and return option is available
    if (
      result["owner_pickup_return"] == 0 ||
      result["owner_pickup_return"] == "0"
    ) {
      dispatch(setalertMessage("Option not available for this item."));
      dispatch(setalertMessagetype("error"));
      return false;
    } else {

      // Set chosen transport option to "owner" and reset delivery price
      trans_option_sel = "owner";
      setchooseTransport("owner");
      setdeliveryPrice(0);

      // Set the minimum delivery charges
      settransportTotal(result["minimum_delivery_charges"]);
      transport_chrg = result["minimum_delivery_charges"];
    }

    // Calculate the new grand total with any additional charges
    let grnt_total = Number(rentalTotal) + Number(transport_chrg);
    setgrandTotal(grnt_total);
  };


  /**
 * calculateDistance Function:
 * Calculate Distance and Delivery Price
 * @param {Object} center - Center location coordinates { lat, lng }
 * @param {Object} address - Full address details
 * Description:
 * This function calculates the distance between the center location and the provided address.
 * It then determines the delivery price based on the calculated distance, free delivery miles,
 * and additional miles fee. The function updates the state variables for distance, delivery price,
 * transport total, and grand total. It also handles errors and session expiration.
 */
  // calculate distance
  const calculateDistance = async (center, address) => {
    try {
      // console.log("full address : ", address);

      // If the center location is not available, stop calculating and set the state accordingly
      if (center.lat == null && center.lng == null) {
        settransportcalculating(false);
      } else {

        // Start calculating and set the center location and address
        settransportcalculating(true);
        setdeliveryLocation(center);
        setFullAddress(address);
        let lat = center.lat;
        let lng = center.lng;

        let auth_user = localStorage.getItem("token-info");
        auth_user = JSON.parse(auth_user);

        // Get distance data from the server  
        const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
        const distanceData: IApiResponseRawData =
          await EquipmentAPIs.getDistence(
            {
              equip_id: result["id"].toString(),
              lat: lat.toString(),
              lng: lng.toString()
            },
            headers
          );

        // If the distance data is successfully retrieved
        if (distanceData["type"] == "RXSUCCESS") {
          let price = 0;

          // Check if the distance is within the allowed maximum miles
          if (distanceData["data"]["distance"] <= Number(result["max_miles"])) {
            setDistance(distanceData["data"]["distance"]);
            let freeMiles = Number(result["free_delivery_miles"]);
            let additional_miles_fee = Number(result["additional_miles_fee"]);


            // Calculate delivery price based on distance, free miles, and additional miles fee
            if (freeMiles >= Math.ceil(distanceData["data"]["distance"])) {
              setdeliveryPrice(0);
            } else {
              //price = (Math.ceil(distanceData["data"]["distance"]) - freeMiles) * additional_miles_fee;
              price = (distanceData["data"]["distance"] - freeMiles);
              price = (price * additional_miles_fee);
              price = price * 2;
              setdeliveryPrice(price);
            }

            /****************/
            //calculation fraction issue fixed
            let pp = price.toFixed(2);
            price = parseFloat(pp);

            let del_fee = result["minimum_delivery_charges"];
            del_fee = parseFloat(del_fee).toFixed(2);
            /****************/

            // Calculate final transport total and grand total
            let final_trnsport_total = Number(del_fee) + Number(price);
            settransportTotal(final_trnsport_total);

            let grand_total =
              Number(final_trnsport_total) + Number(rentalTotal);
            setgrandTotal(grand_total);

            // Stop calculating and reset delivery error
            settransportcalculating(false);
            setdeliveryerror(false);
          } else {

            // If the distance is beyond the allowed maximum, show an error and stop calculating
            settransportcalculating(false);
            dispatch(
              setalertMessage("Delivery is not available to your location 125.")
            );
            dispatch(setalertMessagetype("error"));
            setdeliveryerror(true);
            return false;
          }
        } else if (
          distanceData["status"] == false &&
          distanceData["slug"] == "Session Expired"
        ) {

          // If session expires, set session expiration state
          dispatch(setSessionExpire(true));
          return false;
        } else {

          // If there is an issue with distance data, stop calculating
          settransportcalculating(false);
          return false;
        }
      }
    } catch (err) {
      // If there is an error during the calculation, stop calculating and log the error
      settransportcalculating(false);
      console.log(err);
    }
  };


  /**
   * DeliveryInstructions Function:
   * Update Delivery Instructions and Length
   * @param {Object} event - The event object containing the target value
   * Description:
   * This function is triggered when the delivery instructions input is changed.
   * It updates the state variables for delivery instructions value and length.
   * Additionally, it checks if the length exceeds the limit (400 characters) and shows an error if it does.
   */
  const DeliveryInstructions = (event) => {
    // Get the length and remaining characters
    let value_len = event.target.value.length;
    let left_len = 400 - value_len;

    // Set the length in the state
    setDeliveryInstructionsvallength(left_len);

    // Check if the length is within the limit
    if (value_len < 400) {

      // Update the delivery instructions value in the state
      setDeliveryInstructionsval(event.target.value);
    } else {

      // If the length exceeds the limit, show an error and return false
      dispatch(setalertMessage("Delivery instructions limit exceeded."));
      dispatch(setalertMessagetype("error"));
      return false;
    }
  };


  /**
   * minimumPositionStart Function:
   * Calculate the Minimum Position for Start Time
   * Description:
   * This function calculates the minimum position for the start time based on the current time,
   * ensuring that the start time is either the current time (rounded to the nearest half-hour) or the next half-hour.
   * It also considers the case when the start date is the current date or a future date.
   * @returns {number} - The index of the calculated minimum start time position in the timeArr array
   */
  const minimumPositionStart = () => {

    // Get the current hour and minute
    var hour = new Date().getHours();
    var mint = new Date().getMinutes();

    // Default values for the start time (08:00 AM)
    var asj = "08";
    var mibs = "00";

    // Adjust the minute based on the current minute
    if (mint < 30) {
      mint = 30;
    } else {
      mint = 0;
      hour = hour + 1;
    }

    // Ensure the hour is formatted properly (e.g., single-digit hours with a leading zero)
    if ((hour + "").length == 1) {
      hour = 0 + hour;
    }

    // Check if startDate is empty
    if (startDate == "") {
      return timeArr.indexOf(tConv24(asj + ":" + mibs));
    }

    // Check if startDate is the current date
    if (startDate == moment(new Date()).format("YYYY-MM-DD")) {
      return timeArr.indexOf(tConv24(hour.toString() + ":" + mint.toString()));
    } else {

      // If startDate is a future date, return the position for the default start time (00:00 AM)
      return timeArr.indexOf(tConv24(0 + ":" + mibs));
    }
  };

  /**
   * tConv24 Function:
   * Convert 24-hour format time to 12-hour format
   * Description:
   * This function takes a time string in 24-hour format and converts it to 12-hour format.
   * @param {string} time24 - Time in 24-hour format (HH:mm)
   * @returns {string} - Time in 12-hour format with AM/PM
   */
  const tConv24 = (time24) => {
    if (time24 != "") {

      // Extract hours and minutes from the input time  
      var ts = time24;
      var H = +ts.substr(0, 2);
      var h = H % 12 || 12;

      // Ensure single-digit hours have a leading zero
      h = h < 10 ? 0 + h : h; // leading 0 at the left for 1 digit hours

      // Determine whether it's AM or PM
      var ampm = H < 12 ? " AM" : " PM";

      // Format the time in 12-hour format
      ts = h + ts.substr(2, 3) + ampm;
      return ts;
    } else {
      return "";
    }
  };


  /**
   * getuserprofile Function:
   * Fetch user profile data from the server
   * Description:
   * This function fetches the user profile data from the server using the provided user ID.
   * @param {string} user_id - User ID for whom the profile data is to be fetched
   * @returns {void}
   */
  // Get User profile data
  const getuserprofile = async (user_id) => {
    try {

      // Make an API request to fetch the user profile data
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const profileData: IApiResponseRawData = await EquipmentAPIs.getProfile(
        {
          user_id: user_id.toString()
        },
        headers
      );

      // Check the type of response received
      if (profileData["type"] == "RXSUCCESS") {

        // Extract the user balance from the profile data
        let balance = Number(profileData["data"]["balance"]);

        // Dispatch an action to set the wallet balance in the Redux store
        dispatch(setWalletBalance(balance));
        //setWalletBalance(balance);
        setLoadWalletBalance(false);

        // Show the payment section and hide the checkout section
        setproceedpaysection(true);
        setcheckoutsection(false);

      } else if (
        profileData["status"] == false &&
        profileData["slug"] == "Session Expired"
      ) {
        // Dispatch an action to set the session expiry flag
        dispatch(setSessionExpire(true));
        return false;
      }
    } catch (err) {
      // Handle errors, if any
      // setWalletBalance(0);
    }
  };

  /**
   * resetCalendar Function:
   * Reset calendar and related state variables to default values
   *
   * Description:
   * This function resets the calendar and various related state variables to their default values.
   * It is called when the user wants to reset the selected date range and associated calculations.
   */
  const resetCalendar = () => {
    // Reset selected day range to default
    setSelectedDayRange(defaultRange);

    // Reset rental start and end dates
    setrentStartDate("");
    setrentEndDate("");

    // Reset price-related state variables
    setRentPrice(0);
    setSecurityDeposit(0);
    setEquipProtection(0);
    setRentalTotal(0);
    setdeliveryPrice(0);
    setgrandTotal(transportTotal);

    // Reset start and end dates
    setStartDate("");
    setEndDate("");

    // Reset mobile calendar start and end dates
    setmobilecalendarstartDate(null);
    setmobilecalendarendDate(null);

    // Set values array to empty strings
    let a = ["", ""];
    setValues(a);
    // setchooseTransport(null)
  };


  /**
   * SubmitPlaceOrder Function:
   * Submits the equipment rental order to the server.
   * Description:
   * This function is responsible for submitting the equipment rental order to the server.
   * It collects and validates necessary data, constructs the order object, and sends it to the server.
   */
  // Place order
  const SubmitPlaceOrder = async () => {
    settransportcalculating(true);
    setplaceOrder(false);
    //let grand_total = Number(grandTotal);
    let grand_total = parseFloat(grandTotal.toFixed(2));

    // Get user information from local storage
    let auth_user = localStorage.getItem("token-info");
    auth_user = JSON.parse(auth_user);
    let user_id = auth_user["user_id"].toString();
    let balance = Number(WalletBalance);

    let xx = chooseTransport == "owner" ? "1" : "0";

    // Format start and end times and Convert start and end dates to UTC
    var _startTime = RemoveAMPMandTimeFormat(startTime);
    let date = moment(startDate + "T" + _startTime, "YYYY-MM-DDTHH:mm").utc();

    var _endTime = RemoveAMPMandTimeFormat(endTime);
    let end_time_utc = moment(endDate + "T" + _endTime, "YYYY-MM-DDTHH:mm")
      .utc()
      .toDate()
      .getTime();


    // Construct order data object
    let orderData = {
      user_id: user_id,
      equip_id: params.id.toString(),
      txn_id: Date.now().toString(),
      order_details: "test",
      status: "1",
      amount:
        Number(rentalTotal) - Number(securityDeposit) - Number(equipProtection),
      start_date: startDate.toString(),
      end_date: endDate.toString(),
      start_time: _startTime.toString(),
      end_time: _endTime.toString(),
      delivery_required: chooseTransport == "owner" ? "1" : "0",
      instruction: DeliveryInstructionsval,
      city: FullAddress.city == null || xx == "0" ? "" : FullAddress.city,
      state: FullAddress.state == null || xx == "0" ? "" : FullAddress.state,
      country:
        FullAddress.country == null || xx == "0" ? "" : FullAddress.country,
      postal_code:
        FullAddress.postal_code == null || xx == "0"
          ? ""
          : FullAddress.postal_code,
      address:
        FullAddress.address == null || xx == "0" ? "" : FullAddress.address,
      delivery_charges: deliveryPrice / 2,
      pickup_chages: deliveryPrice / 2,
      total_days: TotalDays.toString(),
      total_miles: Distance,
      delivery_lat: deliveryLocation.lat.toString(),
      delivery_lng: deliveryLocation.lng.toString(),
      equip_user_id: result["user_id"].toString(),
      minimum_delivery_fees:
        chooseTransport == "owner" ? result["minimum_delivery_charges"] : "0",
      order_date: date,
      security_deposit: securityDeposit.toString(),
      equipment_protection_fees: equipProtection.toString(),
      total_price: grand_total.toString(),
      end_time_utc: end_time_utc,
      order_from: "web",
      // Added for card payment hold
      payment_hold: false,
      CardObj: {},
      is_expire:true,
    };


    /**
     * Added on 10July2024
     * If user dont have sufficent balance than display pop for fill card details and saved cards.
     * Added for card payment hold
     * Section start
     */
    setorderRequestData(orderData)
    let userBalance = WalletBalance;
    let remaining = 0;
    if (userBalance != null && userBalance < grand_total) {
      remaining = grand_total - userBalance;
      setholdAmount(remaining)
      // this.setState({
      //   remainingAmount: parseFloat(remaining).toFixed(2),
      //   isShowCreditCard: true,
      // });
    }

    if (remaining != 0) {
      setpayWithCard(true);
    } else {

      /**
       * Added on 10July2024
       * If user dont have sufficent balance than display pop for fill card details and saved cards.
       * Added for card payment hold
       * Section end
       */


      // Make API request to add the order
      let headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const orderResult: IApiResponseRawData = await EquipmentAPIs.addOrder(
        orderData,
        headers
      );

      console.log("orderResult : " , orderResult);
      // return;
      // Check the result of the order submission
      if (orderResult["type"] == "RXSUCCESS") {
        settransportcalculating(false);
        setRequestedOrderId(orderResult["data"]?.["requestedOrderId"])
        {/* For Live */ }
        // for direct order without owner pemission 2 jan 2024
        // dispatch(setalertMessage("Your order request placed successfully."));
        // dispatch(setalertMessagetype("success"));
        // localStorage.setItem("OrderPlacedId", orderResult["data"]["order_id"]);
        // window.location.href = "/myofficetrailer";     
        // order request to owner 2 jan 2024
        setOrderSuccessPopup(true)

      } else if (
        orderResult["status"] == false &&
        orderResult["slug"] == "Session Expired"
      ) {
        // Handle session expiration
        dispatch(setSessionExpire(true));
        return false;
      } else {
        // Handle order submission failure
        settransportcalculating(false);
        dispatch(setalertMessage(orderResult["message"]));
        dispatch(setalertMessagetype("error"));
      }

    }

  };

  /**
   * GoBack Function:
   * Handles navigation to the previous section during the equipment rental process.
   * Description:
   * This function is responsible for scrolling to the top of the page and navigating
   * back to the previous section based on the 'from' parameter provided.
   * @param {string} from - A string indicating the source or section from which the user is navigating.
   */
  const GoBack = (from) => {
    window.scrollTo(0, 0);

    // Check the source and navigate accordingly
    if (from == "checkout") {
      setcheckoutsection(false);
      setproceedpaysection(false);
    } else if (from == "proceedpaysection") {
      setcheckoutsection(true);
      setproceedpaysection(false);
    }
  };

  /**
   * getWalletBalanceadPay Function:
   * Retrieves the user's wallet balance and initiates the payment process.
   * Description:
   * This function is responsible for fetching the user's wallet balance and triggering
   * the payment process by calling the 'getuserprofile' function. It also sets the loading
   * state to provide feedback to the user during this process.
   */
  const getWalletBalanceadPay = () => {
    let auth_user = localStorage.getItem("token-info");
    if (auth_user != "undefined") {
      setLoadWalletBalance(true);
      //setLoading(true);
      auth_user = JSON.parse(auth_user);

      // Call getuserprofile function to retrieve user profile and initiate payment
      getuserprofile(auth_user["user_id"]);

      // Scroll to the top of the page after initiating the process
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 500);
    }
  };

  // for add balance to wallet popup opne
  const addbalancepopupClickOpen = () => {
    setaddbalancebox(true);
  };


  /**
   * addbalancepopupClose Function:
   * Closes the add balance popup and resets related state variables.
   * Description:
   * This function is responsible for closing the add balance popup and resetting
   * the state variables related to the balance amount and card information.
   * @returns {void}
   */
  const addbalancepopupClose = () => {
    // Reset state variables related to balance and card information
    setbalanceamount("");
    setbalancecard(null);
    setbalancecardholdername(null);
    setbalancecardexpmonth(null);
    setbalancecardexpyear(null);
    setbalancecardcvv(null);

    // Close the add balance popup
    setaddbalancebox(false);
  };

  // card related fxns
  const addBalanceAmount = (event) => {
    let val = event.target.value;
    if (val) {
      let newval = val.toString();
      //val = val.replace(/\D/g, "");
      //val = val.replace(/[^0-9.]/g, '')
      if (newval.lenght > 0) {
        newval = newval.replace(/(\..*?)\..*/g, "$1");
        setbalanceamount(newval);
      } else {
        setbalanceamount(val);
      }
    } else {
      setbalanceamount(null);
    }
  };

  const addBalanceCardNumber = (event) => {
    let val = event.target.value;
    val = val.replace(/\D/g, "");

    var foo = val.split("-").join("");
    if (foo.length > 0) {
      foo = foo.match(new RegExp(".{1,4}", "g")).join("-");
    }
    setbalancecard(foo);
  };

  const addBalanceCardholderName = (event) => {
    let name = event.target.value;
    var letters = name.replace(/[^A-Za-z ]+/g, "");
    setbalancecardholdername(letters);
  };

  const addBalanceCardexpmonth = (event) => {
    let val = event.target.value;
    val = val.replace(/\D/g, "");
    if (val > 12) {
      dispatch(setalertMessage("Invalid month"));
      dispatch(setalertMessagetype("error"));
    } else {
      setbalancecardexpmonth(val);
    }
  };

  const addBalanceCardexpyear = (event) => {
    let val = event.target.value;
    val = val.replace(/\D/g, "");
    setbalancecardexpyear(val);
    // const d = new Date();
    // let current_year = d.getFullYear();
    // if (current_year > val && val.length == 4) {
    //   dispatch(setalertMessage("Invaid year"));
    //   dispatch(setalertMessagetype("error"));
    // } else {
    //   setbalancecardexpyear(val);
    // }
  };

  const addBalanceCardcvv = (event) => {
    let val = event.target.value;
    val = val.replace(/\D/g, "");
    setbalancecardcvv(val);
  };

  let STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
  let type = process.env.REACT_APP_PLATFORM;
  if (type == "Live") {
    STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY_LIVE;
  }
  //const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
  const addBalance = async () => {
    let amount = Number(balanceamount);
    if (balanceamount.length == 0) {
      dispatch(setalertMessage("Please add amount to proceed"));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    // if (amount < 49) {
    //   dispatch(setalertMessage("Amount should be greater than 50"));
    //   dispatch(setalertMessagetype("error"));
    //   return false;
    // }

    let card__numbers = balancecard;
    if (card__numbers == "" || card__numbers == null) {
      dispatch(setalertMessage("Please provide the card number"));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    if (card__numbers.length < 19) {
      setbalancecard("");
      dispatch(setalertMessage("Please provide the valid card number"));
      dispatch(setalertMessagetype("error"));
      setbalancecard(null);
      return false;
    }

    if (balancecardexpmonth == null || balancecardexpmonth == "") {
      dispatch(setalertMessage("Please provide the card Exp. month"));
      dispatch(setalertMessagetype("error"));
      return false;
    }

    if (balancecardexpyear == null || balancecardexpyear == "") {
      dispatch(setalertMessage("Please provide the card Exp. year"));
      dispatch(setalertMessagetype("error"));
      return false;
    }

    if (balancecardcvv == null || balancecardcvv == "") {
      dispatch(setalertMessage("Please provide the cvv number"));
      dispatch(setalertMessagetype("error"));
      return false;
    }

    let card__number = balancecard;
    card__number = card__number.split("-").join("");
    card__number = Number(card__number);
    let card__holder_name = balancecardholdername;
    let exp__month = Number(balancecardexpmonth);
    let exp__year = Number(balancecardexpyear);
    let card__cvv = Number(balancecardcvv);

    setLoadWalletBalance(true);
    var data =
      "card%5Bnumber%5D=" +
      card__number +
      "&card%5Bexp_month%5D=" +
      exp__month +
      "&card%5Bexp_year%5D=" +
      exp__year +
      "&card%5Bcvc%5D=" +
      card__cvv;

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        return false;
      }
    });

    xhr.open("POST", "https://api.stripe.com/v1/tokens");
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.setRequestHeader("Authorization", "Bearer " + STRIPE_KEY);
    xhr.onload = async function () {
      if (xhr.status === 200) {
        let result = JSON.parse(xhr.response);
        let stripe_token = result["id"];

        let auth_user = localStorage.getItem("token-info");
        auth_user = JSON.parse(auth_user);
        let user_id = auth_user["user_id"].toString();
        let headers = { Authorization: `${AuthToken}`, request_from: "Web" };
        const balanceresult: IApiResponseRawData =
          await EquipmentAPIs.addWalletBalance(
            {
              user_id: user_id,
              balance: amount,
              token: stripe_token,
              description: user_id + ", added_wallet",
              from: " -web"
            },
            headers
          );
        if (balanceresult["type"] == "RXSUCCESS") {
          getuserprofile(user_id);
          setLoadWalletBalance(false);
          setaddbalancebox(false);
          dispatch(setalertMessage(balanceresult["message"]));
          dispatch(setalertMessagetype("success"));

          setbalanceamount("");
          setbalancecard(null);
          setbalancecardholdername(null);
          setbalancecardexpmonth(null);
          setbalancecardexpyear(null);
          setbalancecardcvv(null);
        } else if (
          balanceresult["status"] == false &&
          balanceresult["slug"] == "Session Expired"
        ) {
          dispatch(setSessionExpire(true));
          return false;
        } else {
          setLoadWalletBalance(false);
          setaddbalancebox(false);
          dispatch(setalertMessage(balanceresult["message"]));
          dispatch(setalertMessagetype("error"));
          return false;
        }
      } else {
        let results = JSON.parse(xhr.response);
        dispatch(setalertMessage(results.error.message));
        dispatch(setalertMessagetype("error"));
        setLoadWalletBalance(true);
        setLoadWalletBalance(false);

        return false;
      }
    };
    xhr.send(data);
  };

  // Pause Payment
  const cancelPayment = () => {
    setplaceOrder(false);
    setbalanceamount("");
    setbalancecard(null);
    setbalancecardholdername(null);
    setbalancecardexpmonth(null);
    setbalancecardexpyear(null);
    setbalancecardcvv(null);
  };

  // Handle Disable date on click
  // const handleDisabledSelect = (disabledDay) => {
  //   dispatch(setalertMessage('Selected date is disabled.'));
  //   dispatch(setalertMessagetype('error'));
  //   return false;
  // };


  /**
   * DateRangSelect Function:
   * Handles the selection of a date range for rental and performs validation based on available days.
   *
   * @param {object} val - Selected date range object containing 'from' and 'to' properties.
   * @param {string} saturday - Availability flag for Saturday ('1' if available, '0' if not).
   * @param {string} sunday - Availability flag for Sunday ('1' if available, '0' if not).
   */
  //handle calendat dates on select
  const DateRangSelect = (val, saturday, sunday) => {
    // Extract start date components
    var startDay = val.from.day;
    startDay = startDay > 9 ? startDay : "0" + startDay;
    var startMonth = val.from.month;
    startMonth = startMonth > 9 ? startMonth : "0" + startMonth;
    var startYear = val.from.year;
    var rent_start = startMonth + "/" + startDay + "/" + startYear;
    var orderStart = moment(rent_start).format("YYYY-MM-DD");

    // Validate start date against Saturday and Sunday availability
    if (orderStart) {
      if (saturday == "0") {
        let sat_day = moment(orderStart).format("ddd");
        if (sat_day == "Sat") {
          dispatch(setalertMessage("Selected date is not available."));
          dispatch(setalertMessagetype("error"));
          return false;
        }
      }
      if (sunday == "0") {
        let sun_day = moment(orderStart).format("ddd");
        if (sun_day == "Sun") {
          dispatch(setalertMessage("Selected date is not available."));
          dispatch(setalertMessagetype("error"));
          return false;
        }
      }
    }

    // Reset end date and related state variables
    setrentEndDate("");
    setEndDate("");

    // Process end date if available
    if (val.to != null) {

      // Extract end date components
      var endDay = val.to.day;
      endDay = endDay > 9 ? endDay : "0" + endDay;
      var endMonth = val.to.month;
      endMonth = endMonth > 9 ? endMonth : "0" + endMonth;
      var endYear = val.to.year;
      var rent_end = endMonth + "/" + endDay + "/" + endYear;

      var orderEnd = moment(rent_end).format("YYYY-MM-DD");

      // Validate end date against Saturday and Sunday availability
      if (rent_end) {
        if (saturday == 0) {
          let en_sat_day = moment(orderEnd).format("ddd");
          if (en_sat_day == "Sat") {
            dispatch(setalertMessage("Selected date is not available."));
            dispatch(setalertMessagetype("error"));
            return false;
          }
        }
        if (sunday == 0) {
          let en_sun_day = moment(orderEnd).format("ddd");
          if (en_sun_day == "Sun") {
            dispatch(setalertMessage("Selected date is not available."));
            dispatch(setalertMessagetype("error"));
            return false;
          }
        }

        // Calculate date range between start and end dates
        var startt = moment(orderStart); // Sept. 1st
        var endd = moment(orderEnd); // Nov. 2nd
        var dayy = 0; // Sunday
        var sat_resultt = [];
        var sun_resultt = [];

        // Check for Saturday sunday availability within the date range
        if (saturday == 0) {
          var currentt = startt.clone();
          while (currentt.day(7 + dayy).isBefore(endd)) {
            sat_resultt.push(currentt.clone());
          }
        }

        if (sunday == 0) {
          var sun_currentt = startt.clone();
          while (sun_currentt.day(7 + dayy).isBefore(endd)) {
            sun_resultt.push(sun_currentt.clone());
          }
        }

        // If either Saturday or Sunday is not available, display an error message
        if (sat_resultt.length > 0 || sun_resultt.length > 0) {
          dispatch(setalertMessage("Selected date is not available."));
          dispatch(setalertMessagetype("error"));
          return false;
        }
      }

      // Set the end date for mobile calendar
      setmobilecalendarendDate(orderEnd);
    }

    // Set the start date for mobile calendar and update the selected date range
    setmobilecalendarstartDate(orderStart);
    setSelectedDayRange(val);
  };


  /**
   * SaveCalendarDates Function:
   * Saves the selected rental start and end dates from the mobile calendar, calculates the price,
   * and performs necessary state updates.
   */
  // Save dates form mobile view calendat
  const SaveCalendarDates = () => {
    // Retrieve start and end dates from mobile calendar
    let start = mobilecalendarstartDate;
    let end = mobilecalendarendDate;

    // Check if both start and end dates are selected
    if (start != null && end != null) {

      // Update state variables with formatted date values
      setStartDate(start);
      setrentStartDate(moment(start).format("M/D/YYYY"));
      //console.log('v',start,end);
      setrentEndDate(moment(end).format("M/D/YYYY"));
      setEndDate(end);

      // Calculate price based on selected date range
      calculatePrice(start, end);

      // Close the date selection section
      setdateselectsection(false);

      // Scroll to the top of the page
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    } else {

      // Display an error message if either start or end date is not selected
      dispatch(setalertMessage("Please select rental start and end date."));
      dispatch(setalertMessagetype("error"));
    }
  };


  /**
   * SaveDoubleCalendarDates Function:
   * Saves the selected rental start and end dates from the double calendar, calculates the price,
   * and performs necessary state updates.
   */
  const SaveDoubleCalendarDates = () => {

    // Retrieve selected start and end dates from the double calendar
    let doubledates = values;
    let start = doubledates[0];
    let end = doubledates[1];

    // Check if both start and end dates are selected
    if (start != null && end != null) {

      // Update state variables with formatted date values
      setStartDate(start);
      setrentStartDate(moment(start).format("M/D/YYYY"));
      //console.log('v',start,end);
      setrentEndDate(moment(end).format("M/D/YYYY"));
      setEndDate(end);

      // Calculate price based on selected date range
      calculatePrice(start, end);

      // Close the date selection section
      setdateselectsection(false);

      // Scroll to the top of the page
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    } else {
      dispatch(setalertMessage("Please select rental start and end date."));
      dispatch(setalertMessagetype("error"));
    }
  };

  /**
   * HandleOpencalendar Function:
   * Opens the date selection section and scrolls to the top of the page.
   */
  const HandleOpencalendar = () => {
    setdateselectsection(true);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  };

  /**
   * Function: HandleChatOwnerToRenter
   * Description:
   * Handles the initiation of a chat from the equipment owner to the renter.
   * Constructs a chat object with relevant details and stores it in local storage.
   * Redirects the user to the notifications page for further chat interaction.
   */
  const HandleChatOwnerToRenter = () => {
    if(+AuthUserID === +result?.["user_id"]) {
      return;
    }
    // Get the sender ID
    let senderID = AuthUserID;
    // Extract equipment make or set a default value if not available
    let EquipMake = result["make"] ? result["make"] : "Not Available";

    // Create a chat object with relevant details
    let ChatObj = {
      equipId: result["id"],
      senderID: senderID,
      reciverID: result["user_id"],
      reciverName: result["first_name"] + " " + result["last_name"],
      EquipMake: EquipMake
      // 'EquipModel': EquipModel
    };

    // Store the chat object in local storage
    localStorage.setItem("ChatFromEquipDetail", JSON.stringify(ChatObj));
    //ChatClick(ChatObj)

    // Redirect the user to the notifications page for chat interaction
    window.location.href = "/notifications";
  };
  
  //by GD 21 Feb 2024 for chat redirection without login 
  const HandleChatOwnerToRenterWithotLogin = () => {
    // Get the sender ID
    let senderID = '';
    // Extract equipment make or set a default value if not available
    let EquipMake = result["make"] ? result["make"] : "Not Available";

    // Create a chat object with relevant details
    let ChatObj = {
      equipId: result["id"],
      senderID: senderID,
      reciverID: result["user_id"],
      reciverName: result["first_name"] + " " + result["last_name"],
      EquipMake: EquipMake
      // 'EquipModel': EquipModel
    };

    // Store the chat object in local storage
    localStorage.setItem("ChatFromEquipDetail", JSON.stringify(ChatObj));
    //ChatClick(ChatObj)

    // Redirect the user to the notifications page for chat interaction
    // window.location.href = "/notifications";
  };

  //Check Equipment status
  /**
   * Function: CheckEquipStatus
   * Description:
   * Checks the status of an equipment (like, dislike, etc.) for a user.
   * Makes an API call to EquipStatus and updates the state based on the response.
   * @param {string} user_id - The user ID making the request.
   * @param {string} token - The authentication token for the request.
   * @param {string} equip_id - The ID of the equipment being checked.
   * @param {string} equipType - The type of equipment being checked (liked, disliked, etc.).
   */
  const CheckEquipStatus = async (user_id, token, equip_id, equipType) => {
    try {
      setLikeLoading(true);

      // Set headers for the API request
      const headers = { Authorization: `${token}`, request_from: "Web" };
      // Create the request body
      let reqBody = {
        user_id: user_id.toString(),
        equip_id: equip_id.toString(),
        liked_equip_list_type: equipType
      };
      // console.log('reqBody', reqBody);

      // Make the API call to EquipStatus
      const EquipStatus = await EquipmentAPIs.EquipStatus(
        reqBody,
        headers
      );

      // Check the type of response received
      if (EquipStatus["type"] == "RXSUCCESS") {
        setLikeLoading(false);

        // Check if the data is not null
        if (EquipStatus["data"] == null) {
          // console.log('comming in null condition...');
        } else {
          //console.log('comming in not null condition...');
          // Update the like status state
          setLikeStatus(EquipStatus["data"].status);
        }

      }
      else if (
        EquipStatus["status"] == false &&
        EquipStatus["slug"] == "Session Expired"
      ) {
        // session expired handling
        setLikeLoading(false);
      }
      else {
        // Dispatch error alert messages
        dispatch(setalertMessage(EquipStatus["message"]));
        dispatch(setalertMessagetype("error"));
        setLikeLoading(false);
        return false;
      }
    } catch (err) {
      // Set loading state to false in case of an error
      setLikeLoading(false);
      console.log(err);
    }
  };

  /**
   * Function: LikeDislikeEquip
   * Description:
   * Likes or dislikes an equipment based on the provided status.
   * Makes an API call to AddToLikeDislike and updates the state based on the response.
   * @param {string} status - The status to set for liking or disliking the equipment.
   */
  //Like Dislike Equipment
  const LikeDislikeEquip = async (status) => {
    try {
      setLikeLoading(true);

      // Make the API call to AddToLikeDislike
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const EquipData = await EquipmentAPIs.AddToLikeDislike(
        {
          user_id: AuthUserID.toString(),
          equip_id: params.id.toString(),
          status: status.toString(),
          liked_equip_list_type: EquipListType
        },
        headers
      );

      // Check the type of response received
      if (EquipData["type"] == "RXSUCCESS") {
        // dispatch(setalertMessage('Action completed.'));
        // dispatch(setalertMessagetype('success'));
        setLikeLoading(false);
        // Update the like status state
        setLikeStatus(status);
      } else if (
        EquipData["status"] == false &&
        EquipData["slug"] == "Session Expired"
      ) {
        // Set loading state to false in case of session expiration
        // dispatch(setSessionExpire(true));
        setLikeLoading(false);
        return false;
      } else {
        // dispatch(setalertMessage(EquipData['message']));
        // dispatch(setalertMessagetype('error'));
        setLikeLoading(false);
        return false;
      }
    } catch (err) {
      // Set loading state to false in case of an error
      setLikeLoading(false);
      console.log(err);
    }
  };

  /*
   * Card Payment Hold
   * Handle order request payment with card and card payment hold
   * Section start
   */

  const payWithCardClose = () => {   
    setpayWithCard(false);
  };

  const HandleCardHoldPayment = async (cardObjData) => {
    try {     
      let final = { ...orderRequestData, CardObj: cardObjData, payment_hold: true }
      console.log('====>>',final)
      // Make API request to add the order
      let headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const orderResult: IApiResponseRawData = await EquipmentAPIs.addOrder(
        final,
        headers
      );
      // return;
      // Check the result of the order submission
      if (orderResult["type"] == "RXSUCCESS") {
        settransportcalculating(false);
        setRequestedOrderId(orderResult["data"]?.["requestedOrderId"])
        setOrderSuccessPopup(true)
      } else if (
        orderResult["status"] == false &&
        orderResult["slug"] == "Session Expired"
      ) {
        // Handle session expiration
        dispatch(setSessionExpire(true));
        return false;
      } else {
        // Handle order submission failure
        settransportcalculating(false);
        dispatch(setalertMessage(orderResult["message"]));
        dispatch(setalertMessagetype("error"));
      }

    } catch (err) {
      console.log(err);
    }

  };
  
  /*
   * Card Payment Hold
   * Handle order request payment with card and card payment hold
   * Section start
   */


  // use effect
  const [sticky, setSticky] = useState("");

  // Effect to load equipment details on component mount
  /**
   * Effect: useEffect
   * Description:
   * Loads equipment details and sets initial states on component mount.
   * Listens for scroll and resize events.
   */
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      // Call the viewEquipment function with equipment ID from params
      viewEquipment(params.id);

      // Set equipment list type based on params
      if (params.list_type === undefined) {
        setEquipListType('rent')
      } else {
        setEquipListType(params.list_type)
      }

      // Scroll to the top of the page
      window.scrollTo(0, 0);
    }, 500);

    // Get the user information from local storage
    let auth_user = localStorage.getItem("token-info");

    // Check if user information is not "undefined"
    if (auth_user != "undefined") {
      auth_user = JSON.parse(auth_user);

      // Check if user information is not null and exists
      if (auth_user != null && auth_user) {

        // Dispatch login status and access token
        dispatch(setLoginStatus(true));
        dispatch(setaccessToken(auth_user["auth_token"]));


        // setTimeout(() => {
        //   CheckEquipStatus(
        //     auth_user["user_id"],
        //     auth_user["auth_token"],
        //     params.id
        //   );
        // }, 500);
      } else {
        // Set access token to null if user information is null
        dispatch(setaccessToken(null));
      }
    } else {
      // Set access token to null if user information is "undefined"
      dispatch(setaccessToken(null));
    }

    // Add event listeners for scroll and resize events
    window.addEventListener("scroll", updateDimensions);
    window.addEventListener("resize", updateDimensionss);

    // Clean up by removing event listeners on component unmount
    return () => {
      window.removeEventListener("scroll", updateDimensions);
      window.removeEventListener("resize", updateDimensionss);
    };
  }, []);


  // Effect to check equipment status when EquipListType changes
  /**
   * Effect: useEffect
   * Description:
   * Checks equipment status when EquipListType changes.
   * Fetches user information and calls CheckEquipStatus with the updated EquipListType.
   * @param {Array} [EquipListType] - Dependency array containing EquipListType.
   */
  useEffect(() => {
    let auth_user = localStorage.getItem("token-info");
    if (auth_user != "undefined" && EquipListType != '') {
      auth_user = JSON.parse(auth_user);
      if (auth_user != null && auth_user) {
        CheckEquipStatus(
          auth_user["user_id"],
          auth_user["auth_token"],
          params.id,
          EquipListType
        );
      }
    }
  }, [EquipListType]);


  // Function to update dimensions based on screen width
  /**
   * Function: updateDimensions
   * Description:
   * Updates dimensions based on the current screen width.
   * Adjusts tab view calendar and device type accordingly.
   */
  const updateDimensions = () => {
    let screenwidth = window.innerWidth;

    // Adjust tab view calendar based on screen width
    if (screenwidth >= 768 && screenwidth <= 1024) {
      settabviewcalendar(1);
    } else {
      settabviewcalendar(2);
    }

    // Set device type based on screen width
    if (screenwidth >= 992) {
      setDeviceType("large");
    } else {
      setDeviceType("small");
    }
  };

  const updateDimensionss = () => {
    let screenwidth = window.innerWidth;

    // Adjust tab view calendar based on screen width
    if (screenwidth >= 768 && screenwidth <= 1024) {
      settabviewcalendar(1);
    } else {
      settabviewcalendar(2);
    }

    // Set device type based on screen width
    if (screenwidth >= 992) {
      setDeviceType("large");
    } else {
      setDeviceType("small");
    }
  };

  const classes = `newdetail-grid-item dextop d-none d-xl-block${sticky}`;

  interface MyPluginProps {
    position: string;
  }

  function MyPlugin(props: MyPluginProps) {
    return (
      <>
        <Box className="time-box">
          <Box className="time-main-box">
            <FormLabel className="time-box-label">Pickup Time {timeZone && <>({timeZoneAbbr})</>} -</FormLabel>
            <Box className="time-main-box-top">
              <Button
                className="plus-btn"
                variant="contained"
                onClick={(event) => {
                  handleStartEndTime(event, "startHoursPlus");
                  // calculatePrice(startDate, endDate);
                }}
              >
                <KeyboardArrowUpIcon />
              </Button>
              <TextField
                id="outlined-disabled"
                value={hoursArray[startHours]}
                disabled
                className="cal-time-input"
                sx={{
                  "& .MuiSelect-select": {
                    color: "#2D2D2D !important",
                    fontFamily: "Gotham !important"
                  }
                }}
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid #1976d2",
                  borderRadius: "10px"
                }}
              />
              <Button
                className="minus-btn"
                variant="contained"
                onClick={(event) => {
                  handleStartEndTime(event, "startHoursMinus");
                  // calculatePrice(startDate, endDate);
                }}
              >
                <KeyboardArrowDownIcon />
              </Button>
            </Box>
            <Typography sx={{ color: "#fff !important" }}>:</Typography>
            <Box className="time-main-box-mid">
              <Button
                className="plus-btn"
                variant="contained"
                onClick={(event) => {
                  handleStartEndTime(event, "startMinutesToggle");
                  // calculatePrice(startDate, endDate);
                }}
              >
                <KeyboardArrowUpIcon />
              </Button>
              <TextField
                id="outlined-disabled"
                value={startMinutes}
                disabled
                className="cal-time-input"
                sx={{
                  "& .MuiSelect-select": {
                    color: "#2D2D2D !important",
                    fontFamily: "Gotham !important"
                  }
                }}
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid #1976d2",
                  borderRadius: "10px"
                }}
              />
              <Button
                className="minus-btn"
                variant="contained"
                onClick={(event) => {
                  handleStartEndTime(event, "startMinutesToggle");
                  // calculatePrice(startDate, endDate);
                }}
              >
                <KeyboardArrowDownIcon />
              </Button>
            </Box>
            <Box className="time-main-box-btm">
              {/* <Button variant="contained" onClick={(event) => handleStartEndTime(event, "toggleStartTime")
            }>
              {selectedStartTime}
            </Button> */}
              <ToggleButtonGroup
                orientation="vertical"
                value={selectedStartTime}
                exclusive
                onChange={(event) => {
                  handleStartEndTime(event, "toggleStartTime");
                  // calculatePrice(startDate, endDate);
                }}
              >
                <ToggleButton
                  className={
                    selectedStartTime === "AM" ? "am-pm active" : "am-pm"
                  }
                  value="AM"
                  aria-label="list"
                >
                  AM
                </ToggleButton>
                <ToggleButton
                  className={
                    selectedStartTime === "PM" ? "am-pm active" : "am-pm"
                  }
                  value="PM"
                  aria-label="module"
                >
                  PM
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>

          <Box className="time-main-box">
            <FormLabel className="time-box-label">Return Time {timeZone && <>({timeZoneAbbr})</>} -</FormLabel>
            <Box className="time-main-box-top">
              <Button
                className="plus-btn"
                variant="contained"
                onClick={(event) => {
                  handleStartEndTime(event, "endHoursPlus");
                  // calculatePrice(startDate, endDate);
                }}
              >
                <KeyboardArrowUpIcon />
              </Button>
              <TextField
                id="outlined-disabled"
                value={hoursArray[endHours]}
                disabled
                className="cal-time-input"
                sx={{
                  "& .MuiSelect-select": {
                    color: "#2D2D2D !important",
                    fontFamily: "Gotham !important"
                  }
                }}
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid #1976d2",
                  borderRadius: "10px"
                }}
              />
              <Button
                className="minus-btn"
                variant="contained"
                onClick={(event) => {
                  handleStartEndTime(event, "endHoursMinus");
                  // calculatePrice(startDate, endDate);
                }}
              >
                <KeyboardArrowDownIcon />
              </Button>
            </Box>

            <Typography sx={{ color: "#fff !important" }}>:</Typography>

            <Box className="time-main-box-mid">
              <Button
                className="plus-btn"
                variant="contained"
                onClick={(event) => {
                  handleStartEndTime(event, "endMinutesToggle");
                  // calculatePrice(startDate, endDate);
                }}
              >
                <KeyboardArrowUpIcon />
              </Button>
              <TextField
                id="outlined-disabled"
                value={endMinutes}
                disabled
                className="cal-time-input"
                sx={{
                  "& .MuiSelect-select": {
                    color: "#2D2D2D !important",
                    fontFamily: "Gotham !important"
                  }
                }}
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid #1976d2",
                  borderRadius: "10px"
                }}
              />
              <Button
                className="minus-btn"
                variant="contained"
                onClick={(event) =>{
                  handleStartEndTime(event, "endMinutesToggle");
                  // calculatePrice(startDate, endDate);
                }}
              >
                <KeyboardArrowDownIcon />
              </Button>
            </Box>
            <Box className="time-main-box-btm">
              {/* <Button variant="contained" onClick={(event) => handleStartEndTime(event, "toggleEndTime")}>
              {selectedEndTime}
            </Button> */}
              <ToggleButtonGroup
                orientation="vertical"
                value={selectedEndTime}
                exclusive
                onChange={(event) => {
                  handleStartEndTime(event, "toggleEndTime");
                  // calculatePrice(startDate, endDate);
                }}
              >
                <ToggleButton
                  className={
                    selectedEndTime === "AM" ? "am-pm active" : "am-pm"
                  }
                  value="AM"
                  aria-label="list"
                >
                  AM
                </ToggleButton>
                <ToggleButton
                  className={
                    selectedEndTime === "PM" ? "am-pm active" : "am-pm"
                  }
                  value="PM"
                  aria-label="module"
                >
                  PM
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
        </Box>
        <Box className="confirm-div">
          {startDate && endDate && (
            <Button
              className="confirm-btn"
              variant="contained"
              onClick={() => {
                let diffMins = GetDifferenceinmin(
                  moment(startDate).format("M/D/YYYY"),
                  moment(endDate).format("M/D/YYYY")
                );

                if (diffMins === null) {
                  // console.log("in if condition");

                  dispatch(
                    setalertMessage("The selected pickup time is in the past.")
                  );
                  dispatch(setalertMessagetype("error"));
                  return false;
                }
                calculatePrice(startDate, endDate);

                datePickerCalendar.current.closeCalendar();
              }}
            >
              Confirm
            </Button>
          )}
        </Box>
      </>
    );
  }


  // Sales equip section start
  const phoneFormat = (phNumber) => {
    let cleanNumber = phNumber;
    if (cleanNumber.length <= 3) {
      setPhone(cleanNumber);
    } else if (cleanNumber.length <= 6) {
      setPhone(`${cleanNumber.slice(0, 3)}-${cleanNumber.slice(3)}`);
    } else if (cleanNumber.length <= 10) {
      setPhone(`${cleanNumber.slice(0, 3)}-${cleanNumber.slice(3, 6)}-${cleanNumber.slice(6)}`);
    } else {
      setPhone(`${cleanNumber.slice(0, 3)}-${cleanNumber.slice(3, 6)}-${cleanNumber.slice(6, 10)}`);
    }
  };

  // fxn created by GD 12 Jan 2024  for phone formatting issue
    const newPhoneFormat = (phNumber) => {
      let rawNumber = phNumber.replace(/[^0-9]/g, "");
      // console.log("rawNumber: " , rawNumber);
      // one way to format number
      // if (rawNumber.length <= 3) {
      //   return rawNumber;
      // } else if (rawNumber.length <= 6) {
      //   return `${rawNumber.slice(0, 3)}-${rawNumber.slice(3)}`;
      // } else if (rawNumber.length <= 10) {
      //   return `${rawNumber.slice(0, 3)}-${rawNumber.slice(3, 6)}-${rawNumber.slice(6)}`;
      // } else {
      //   return `${rawNumber.slice(0, 3)}-${rawNumber.slice(3, 6)}-${rawNumber.slice(6, 10)}`;
      // }
      // second way to format number
      if (rawNumber.length <= 3)  return rawNumber;
      const segments = [
        rawNumber.slice(0, 3),
        rawNumber.slice(3, 6),
        // rawNumber.slice(6, 10)
        rawNumber.slice(6)
      ];
      return segments.filter(Boolean).join('-');

    };
  // end by GD 12 Jan 2024
  useEffect(() => {
    if (AuthProfileData && AuthProfileData != undefined) {
      // console.log("AuthProfileData : " , AuthProfileData);
      let ph = AuthProfileData['phone_no']
      if (ph != '' && ph != undefined && ph != null) {
        //console.log('phph',ph)
        // by GD 12 jan 2024 due to phone number dispearing issues
          setPhone(ph)
        // end by GD 12 jan 2024
        // phoneFormat(ph);
      }
      let mail = AuthProfileData['email']
      if (mail != '' || mail != undefined) {
        setEmail(mail)
      }

      // ph = phoneFormat(ph);
      // setPhone(ph)


    }
  }, [AuthProfileData]);

  const [InterestPopup, setInterestPopup] = React.useState(false);
  const [ClickInfoPopup, setClickInfoPopup] = React.useState(false);
  const [Phone, setPhone] = React.useState("");
  const [Email, setEmail] = React.useState("");
  const [Message, setMessage] = React.useState("");
  const [InfoLoading, setInfoLoading] = React.useState(false);
  const [pendingDiscriptionChar, setpendingDiscriptionChar] = React.useState(500);

   // const HandlePhone = (event) => {
    //   let val = event.target.value;
    //   val = val.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1");
    //   if (val.length == 0) {
    //     setPhone(val);
    //   } else {
    //     phoneFormat(val);
    //   }
    // };

  // by GD 12 jan 2024 created new HandlePhone and commented old due to error in phone formatting but not in use now code moved to onChange
  const HandlePhone = (event) => {
    let val = event.target.value.replace(/[^0-9]/g, "");
    // val = val.replace(/[^0-9]/g, "");
    console.log('val:' , val);
    setPhone(val);
    // if (val.length == 0) {
    //   setPhone(val);
    // } else {
    //   // phoneFormat(val);
    //   setPhone(val);
    // }
  };

  const HandleMessage = (event) => {
    // const totalLen = 50;
    let val = event.target.value;
    setMessage(val);
    // let len = val.length;
    // if (len > totalLen) {
    //   let remove_char = len - totalLen;
    //   val = val.substring(0, val.length - remove_char);
    //   setpendingDiscriptionChar(0);
    //   setMessage(val);
    // } else {
    //   setpendingDiscriptionChar(totalLen - len);
    //   setMessage(val);
    // }
  }

  const HandleInfoPopup = () => {
    // setPhone("");
    // setEmail("");
    // setMessage("");
    if (AuthUserID) {
      // setClickInfoPopup(true);
      if(AuthUserID && AuthUserID.toString() == params.ownerid){
        setInfoLoading(false);
        setInfoLoading(false);
        dispatch(setalertMessage("You cannot request your own equipment."));
        dispatch(setalertMessagetype('error'));
        return false;
      }
      // Get the sender ID
      let senderID = AuthUserID;
      // Extract equipment make or set a default value if not available
      let EquipMake = result["make"] ? result["make"] : "Not Available";

      // Create a chat object with relevant details
      let ChatObj = {
        equipId: result["id"],
        senderID: senderID,
        reciverID: result["user_id"],
        reciverName: result["first_name"] + " " + result["last_name"],
        EquipMake: EquipMake
        // 'EquipModel': EquipModel
      };

      // Store the chat object in local storage
      localStorage.setItem("ChatFromEquipDetail", JSON.stringify(ChatObj));
      window.location.href = "/notifications";
    } else {
       // by pp 23 july 2024 for verifying authentication
       dispatch(setLoginModalOpen(true));
       dispatch(setLogInLoading(false));
    }
  };

  const HandleInfoSubmit = async (e) => {
    e.preventDefault();
    setInfoLoading(true)
    try {
      // let phone_number = Phone;
      // let email_address = Email;
      let message = Message;

      // if (phone_number == '') {
      //   dispatch(setalertMessage('Please enter phone number to continue!'));
      //   dispatch(setalertMessagetype('error'));
      //   setInfoLoading(false)
      //   return false;
      // }
      // console.log("phone_number:" , phone_number);
      
      // if (phone_number.length < 10) {
      // if (phone_number.length != 12) {
      //   dispatch(setalertMessage('Please enter valid phone number to continue!'));
      //   dispatch(setalertMessagetype('error'));
      //   setInfoLoading(false)
      //   return false;
      // }

      // var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // if (email_address == "") {
      //   dispatch(setalertMessage("Please fill email address!"));
      //   dispatch(setalertMessagetype("error"));
      //   setInfoLoading(false)
      //   return false;
      // }
      // if (!email_address.match(mailformat)) {
      //   dispatch(setalertMessage("Invalid email address!"));
      //   dispatch(setalertMessagetype("error"));
      //   setInfoLoading(false)
      //   return false;
      // }

      if (message?.trim() === '') {
        dispatch(setalertMessage('Please enter message to continue!'));
        dispatch(setalertMessagetype('error'));
        setInfoLoading(false)
        return false;
      }
      // let req_data = {
        // equip_id: params.id.toString(),
        // phone: phone_number, //.replace(/-/g, ""),
        // email: email_address,
        // message: message,
        // request_from: "web"     // by GD
      // }
      // by GD add if block to prevent send message if the owner and user are same 4 jan 2014
      if(AuthUserID && AuthUserID.toString() == params.ownerid){
        setInfoLoading(false);
        setInfoLoading(false);
        dispatch(setalertMessage("You cannot request your own equipment."));
        dispatch(setalertMessagetype('error'));
        return false;
      }
      const reqData = {
        sender_id: AuthUserID?.toString(),
        sender_type: "equipment",
        recever_id: params.ownerid,
        message: message,
        equip_id: params.id.toString(),
      }
      console.log('req_data', reqData);
      // end by GD
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      // const Response = await EquipmentAPIs.SalesEquipInterest(
      //   req_data,
      //   headers
      // );

      const Response = await EquipmentAPIs.SendChatMsg(
        reqData,
        headers
      );
      console.log("message response", Response);
      if (Response["type"] == "RXSUCCESS") {
        setClickInfoPopup(false)
        setInfoLoading(false)
        // setInterestPopup(true)
        setMessage("");
        dispatch(setalertMessage("Message sent successfully"));
        dispatch(setalertMessagetype('success'));
      } else if (
        Response["status"] == false &&
        Response["slug"] == "Session Expired"
      ) {
        //setClickInfoPopup(false)
        setInfoLoading(false)
        //setInterestPopup(true)  
        dispatch(setalertMessage(Response['message']));
        dispatch(setalertMessagetype('error'));

        return false;
      } else {
        setInfoLoading(false)
        dispatch(setalertMessage(Response['message']));
        dispatch(setalertMessagetype('error'));
        return false;
      }
    } catch (err) {
      console.log(err);
      // setInfoLoading(false)
      // setloading(false);
      // setEmail("");
      // setPassword("");
      // dispatch(setLoginStatus(false));
    } finally {
      setInfoLoading(false)
    }
  };
  // by GD for scroll to locationBox
  const locationBoxRef = useRef(null);
  // end by GD
  // Sales equip section end
  const messageOwnerStyle = AuthUserID === result["user_id"] 
        ?{ textDecoration: "none" }
        :{ cursor: "pointer" };

  return (
    <>
    {/* below code is commented by pp on  21 MAY 2024 due to design changes*/}
      {/* {!loading && rescount > 0 && EquipListType != "sale" && (
        <Box className="yellow-header">

          <Box className="yellow-header-inner">
            <Typography sx={{ color: "white" }}>
              <span className="yellow-header-inner-big"
                style={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                }}
              >
                {result["make"] ? result["make"] : "NA"}
              </span>
              <span className="yellow-header-inner-sm"
                style={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                }}
              >
                / {result["model"] ? result["model"] : "NA"}
              </span>
            </Typography>
          </Box>
          <Box className="yellow-header-abs"></Box>
        </Box>
      )} */}

      <MainLayout>
        {loading && rescount != 1 && (
          <Box>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        )}

        {/* New Single Column Section for Sale */}

        {!loading && rescount > 0 && EquipListType == "sale" && (
          <>
            <Box sx={{ margin: 'auto', maxWidth: '768px' }}>
              <Box className="new-eqp-d">
                <Box className="newdetail-img-slider">
                  <Box className="equipment-carousel-main-div">
                    {img.length != 0 && (
                      <ImageGallery
                        items={images_gallerys}
                        infinite={true}
                        lazyLoad={true}
                        autoPlay={false}
                        className="carousel-item equipment-details-item-img "
                        indicatorContainerProps={{
                          style: {
                            marginTop: "30px" // 5
                          }
                        }}
                      />
                    )}
                    <Box sx={{ position: 'absolute', top: '-10px', left: '-10px', background: 'transparent', zIndex: '10', padding: '5px 15px', color: '#fff', fontFamily: 'Gotham !important' }}>
                      <img src={ForSale} alt='For Sale' style={{ width: '70px', height: 'auto', display: 'block' }} />
                    </Box>


                    <Box className="like-dislike-grp">
                      {isLoggedin && (
                        <>
                          {LikeLoading ? (
                            <CircularProgress />
                          ) : (
                            <>
                              {LikeStatus == "1" || LikeStatus == 1 ? (
                                <Box
                                  className="image-like-icon"
                                  onClick={() => {
                                    LikeDislikeEquip(0);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={ThumbsImg} alt="" />
                                </Box>
                              ) : (
                                <Box
                                  className="image-like-icon"
                                  onClick={() => {
                                    LikeDislikeEquip(1);
                                  }}
                                  style={{
                                    filter: "grayscale(100%)",
                                    cursor: "pointer"
                                  }}
                                >
                                  <img src={ThumbsImg} alt="" />
                                </Box>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Box>



                    <Box className="eqp-price">
                      <Typography>
                        <span style={{ color: '#fff !important' }}>
                          $<CurrentFormat Currency={result["equip_sale_price"]} />
                        </span>{' '}
                        <span>
                          {result["obo"] == 1 && (<>obo</>)}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>



              {/* <Box sx={{ margin: '1rem 0 2rem 0' }}>
                <Typography sx={{ color: '#fff', fontFamily: 'GothamBold !important', fontSize: '26px' }}>
                  Track Skid Steer
                </Typography>
                <Typography sx={{ color: '#faa61a', fontFamily: 'GothamBold !important', fontSize: '22px' }}>
                  Bobcat
                </Typography>
              </Box> */}







              <Box className="" sx={{ marginTop: '1rem' }}>
                <Typography sx={{ fontSize: '24px', textTransform: 'capitalize', fontFamily: 'GothamBold !important', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline', flexDirection: 'column' }}>
                  <span className=""
                    style={{
                      color: '#faa61a',
                      fontSize: '30px',
                      lineHeight: '45px',
                      fontFamily: 'GothamBold !important',
                      textTransform: 'capitalize',
                      // width: 'max-content',
                      // by GD 25 Jan 2024 to resolve designs issue
                      width: '100%',
                      display: '-webkit-box',
                      overflow: 'hidden',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 1,
                    }}
                  >
                    {result["make"] ? result["make"] : "NA"}
                  </span>
                  <span className=""
                    style={{
                      color: '#fff',
                      fontSize: '30px',
                      lineHeight: '45px',
                      fontFamily: 'Gotham',
                      textTransform: 'capitalize',
                      // width: 'max-content'
                       // width: 'max-content',
                      // by GD 25 Jan 2024 to resolve designs issue
                      width: '100%',
                      display: '-webkit-box',
                      overflow: 'hidden',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 1,
                    }}
                  >
                    {result["model"] ? result["model"] : "NA"}
                  </span>
                </Typography>
              </Box>



              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', margin: '1rem 0' }}>
                  <img src={LocationIcon} alt="" style={{ width: "22px", height: 'auto', marginTop: '3px' }} />
                  <Typography sx={{ gap: '8px', color: '#fff !important', textDecorationLine: 'underline', textDecorationColor: '#fff', cursor: 'pointer' }}
                    onClick={() => {
                      // by GD 12Dec to scrol to map
                      locationBoxRef.current?.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      });
                    }}
                  >
                    {result["city"] ? result["city"] : "NA"},{" "}
                    {result["state"] ? result["state"] : "NA"}
                  </Typography>
                  
                  
                </Box>
                {/* 21 Dec by GD for owner name and image */}
                <Box className="" sx={{ display: 'flex' ,alignItems: 'center', gap: '1rem' }}>
                    <Box className="">
                      <img
                        src={
                          result["profile_pic"]
                            ? result["profile_pic"]
                            : Img
                        }
                        alt=""
                        style={{ width:'70px', height: '70px', display: 'block', borderRadius: '50%', maxHeight: '70px', maxWidth:'70px', borderWidth: '2px', borderStyle: 'solid', borderColor: '#fff'}}
                      />
                    </Box>
                    <Box className="">
                      <Typography sx={{ fontSize: 'clamp(14px, 3vw, 18px) !important', color: '#faa61a !important' }}>Owner:</Typography>
                      <Typography

                      sx={{
                        fontSize:'clamp(14px, 3vw, 18px) !important',
                        color:'#ffffff !important',
                      }}
                      // style={{ cursor: "pointer" }}
                      // onClick={() => {
                      //   setOwnerInfo(true);
                      // }}
                      >
                        {result["first_name"].charAt(0).toUpperCase() +
                          result["first_name"].slice(1)}
                      </Typography>
                    </Box>
                  </Box>
                  {/* end code for image and name */}
              </Box>



              {result["rpo_available"] == 1 && (
                <>
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '1rem', margin: '1rem 0' }}>
                    <img src={check} alt="" style={{ width: "22px", height: '22px', marginTop: '3px' }} />
                    <Typography sx={{ gap: '8px' }}>
                      <span className='radio-span-yellow' style={{ fontFamily: '', color: '#faa61a !important' }}>
                        RPO Available:
                      </span>
                      <span className='radio-span-white' style={{ fontFamily: '', color: '#fff !important' }}>
                        The owner may offer this as a rental with purchase option.
                      </span>
                    </Typography>
                  </Box>
                </>
              )}

              <Box sx={{ margin: '1rem 0' }}>
                <Button className="cfi-btn" onClick={HandleInfoPopup}>
                  {/* Click for info */}
                  Message Owner
                </Button>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '1rem', margin: '1rem 0' }}>
                <Typography sx={{ gap: '8px' }}>
                  <span className='radio-span-yellow' style={{ fontFamily: '', color: '#faa61a !important' }}>
                    Asking Price:
                  </span>
                  <span className='radio-span-white' style={{ fontFamily: '', color: '#fff !important' }}>
                    $<CurrentFormat Currency={result["equip_sale_price"]} />
                    {result["obo"] == 1 && (<> obo</>)}
                  </span>
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '1rem', margin: '1rem 0' }}>
                <Typography sx={{ gap: '8px' }}>
                  <span className='radio-span-yellow' style={{ fontFamily: '', color: '#faa61a !important' }}>
                    Year:
                  </span>
                  <span className='radio-span-white' style={{ fontFamily: '', color: '#fff !important' }}>
                    {result["year"] ? result["year"] : "NA"}
                  </span>
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', gap: '1rem', margin: '1rem 0' }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '1rem', }}>
                  <Typography sx={{ gap: '8px' }}>
                    <span className='radio-span-yellow' style={{ fontFamily: '', color: '#faa61a !important' }}>
                      Mileage:
                    </span>
                    <span className='radio-span-white' style={{ fontFamily: '', color: '#fff !important' }}>
                      {result["mileage"] ? result["mileage"] : "NA" } {result["mileage"]  && (<>miles</>)}
                    </span>
                  </Typography>
                </Box>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '1rem', }}>
                <Typography sx={{ gap: '8px' }}>
                  <span className='radio-span-yellow' style={{ fontFamily: '', color: '#faa61a !important' }}>
                    Hours:
                  </span>
                  <span className='radio-span-white' style={{ fontFamily: '', color: '#fff !important' }}>
                    {result["hours"] ? result["hours"] : "NA"} 
                    {/* {result["hours"]  && (<>hours</>)} */}
                  </span>
                </Typography>
              </Box>
             {/* Commented on 19 Jan 2024 By MK  */}
              {/* <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '1rem', margin: '1rem 0' }}>
                <Typography sx={{ gap: '8px' }}>
                  <span className='radio-span-yellow' style={{ fontFamily: '', color: '#faa61a !important' }}>
                    Features:
                  </span>
                  <span className='radio-span-white' style={{ fontFamily: '', color: '#fff !important' }}>
                    {result["feature"] ? result["feature"] : "NA"}
                  </span>
                </Typography>
              </Box> */}

              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '1rem', margin: '1rem 0' }}>
                <Typography sx={{ gap: '8px' }}>
                  <span className='radio-span-yellow' style={{ fontFamily: '', color: '#faa61a !important' }}>
                    Description:
                  </span>
                  <span className='radio-span-white' style={{ fontFamily: '', color: '#fff !important' }}>
                    {result["description"] ? result["description"] : "NA"}
                  </span>
                </Typography>
              </Box>

              <Box>
                <Box>

                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', margin: '1rem 0', border: '2px solid #faa61a', borderRadius: '10px', padding: '10px' }}>
                  <img src={dollar} alt="" style={{ width: '55px', height: '55px', display: 'block', objectFit: 'contain' }} />
                  <Typography sx={{ gap: '8px' }}>
                    <span className='radio-span-yellow' style={{ fontFamily: '', color: '#faa61a !important' }}>
                      Need Financing?
                    </span>
                    <span className='radio-span-white' style={{ fontFamily: '', color: '#fff !important' }}>
                      GoEquipme Lending can help.&nbsp;
                      <Link href={'/equipmentlending'} className='equipment-item-details'
                        sx={{ color: '#fff !important', textDecorationColor: '#fff !important' }}>
                        Click here to get approved
                      </Link>.
                      {/* <a href="" style={{ color: '#fff !important', textDecorationColor: '#fff !important' }}>Click her to get approved</a> */}
                    </span>
                  </Typography>
                </Box>


              </Box>

              {/* by GD 12Dec for map section in sales details */}
              <Box className="mapnslide"
                ref={locationBoxRef}
              >
                <Box className="mapsec dextop">
                  <Box className="equipment-details-sub-div">
                    <Box className="mapsec-heading">
                      <Box className="mapsec-icon">
                        <img src={LocationIcon} alt="" />
                      </Box>
                      <Box className="mapsec-heading-text">
                        <Typography
                          variant="h5"
                          sx={{
                            color: "white",
                            fontSize: "clamp(12px, 3vw, 19px) !important"
                          }}
                          className="equipment-description equipment-details-heading"
                        >
                          EQUIPMENT LOCATION
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="item-value-content description-content equipment-details-para" >
                      {/* <Link
                                href={mapLink}
                                target="_blank"
                                sx={{ width: "100%" }}
                              > */}
                      <img
                        src={Maplocation}
                        alt="Map Location"
                        style={{ width: "100%" }}

                      />
                      {/* </Link> */}
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* map section for sale end */}
            </Box>


            <Modal
              open={ClickInfoPopup}
              onClose={() => { setClickInfoPopup(false) }}
              className="addmoney-modal"
            >
              <Box
                className="download-popups"
                sx={{
                  ...loginstyle,
                  padding: "0px"
                }}
              >
                <IconButton className="cross-btn" onClick={() => { setClickInfoPopup(false) }}>
                  <CloseIcon />
                </IconButton>

                <Box className="login__main">
                  <Box
                    className="login__main-div edit-eqp"
                  >
                    <Box
                      className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                      style={{
                        border: "5px solid #faa61a",
                        borderRadius: "10px"
                      }}
                    >


                      <Box className="wallet-accordion" sx={{ background: "transparent !important" }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', margin: '1rem 0' }}>


                          <Box>
                            <Backdrop
                              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                              open={InfoLoading}
                            >
                              <CircularProgress color="inherit" />
                            </Backdrop>
                          </Box>

                          {/* <FormControl fullWidth className="form-fields">
                            <FormLabel id="demo-radio-buttons-group-label">
                              Phone
                            </FormLabel>

                            <CssTextField
                              id="login-email"
                              variant="outlined"
                              placeholder="xxx-xxx-xxxx"
                              type="tel"
                              sx={{ width: "100%" }}
                              // onChange={HandlePhone}
                              // value={Phone}
                              // by GD 12 jan 2024 due to error in phone
                              value={newPhoneFormat(Phone)}
                              onChange={(e)=>  setPhone(e.target.value.replace(/[^0-9]/g, ""))}
                              inputProps={{
                                maxLength: 12
                              }}
                            />
                          </FormControl> */}

                          {/* <FormControl fullWidth className="form-fields">
                            <FormLabel id="demo-radio-buttons-group-label">
                              Email
                            </FormLabel>

                            <CssTextField
                              id="login-email"
                              variant="outlined"
                              placeholder="Email Address"
                              type="email"
                              sx={{ width: "100%" }}
                              onChange={(e) => setEmail(e.target.value.trim())}
                              value={Email}
                            />
                          </FormControl> */}

                          <FormControl fullWidth className="form-fields">
                            <FormLabel id="demo-radio-buttons-group-label">
                              Message
                            </FormLabel>

                            <CssTextField
                              id="outlined-multiline-static"
                              multiline
                              rows={8}
                              fullWidth
                              // placeholder='Message'
                              placeholder='Type here'
                              onChange={HandleMessage}
                              value={Message}
                            />

                            {/* <span className="characters-p">
                              Characters Left&nbsp;
                              <span style={{ color: "#faa61a" }}>
                                {pendingDiscriptionChar}
                              </span>
                            </span> */}

                          </FormControl>

                        </Box>
                        <Box className="wallet-accordion-footer">

                          <Box>
                            <Button
                              type="submit"
                              variant="contained"
                              className="reset-cal-btn addmoney--checkout-btn new-money-btn transfer-btn"
                              sx={{ width: '100%' }}
                              onClickCapture={HandleInfoSubmit}
                            >
                              Send
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Modal>


            {/* after completion */}
            <Modal
              keepMounted
              open={InterestPopup}
              onClose={() => setInterestPopup(false)}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"
            >
              <Box
                className="download-popups"
                sx={{
                  ...loginstyle,
                  width: matchesMobileX ? 'calc(100vw - 100px)' : 800,
                  padding: "0px",
                }}>

                <>
                  <Box className="login__main">
                    <Box className="login-main-inner">
                      <Box className="login-fields-div">
                        <Box className="after-msg">
                          <Box className="after-msg-inner">
                            <Typography
                              variant="h4"
                              className="welcome-heading"
                              sx={{ textAlign: "center", color: "#fff" }}
                            >
                              <img src={RequestReceived} alt="image" style={{ width: "70%" }} />

                            </Typography>

                            <Typography className="second-para">
                              A GoEquipMe representative will contact you soon to discuss your interest in this equipment.
                            </Typography>

                            <Box
                              className="close"
                              onClick={() => setInterestPopup(false)}
                            >
                              <Box className="close-content">
                                <Typography sx={{ color: '#faa61a !important', fontFamily: 'GothamBold !important', fontSize: '18px !important' }}>X Close</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>

                      </Box>
                    </Box>
                  </Box>
                </>
              </Box>
            </Modal>

          </>
        )}


        {/* Rental Section */}
        {!loading && rescount > 0 && EquipListType == "rent" && (
          <>
            <Box>

              {!loading &&
                rescount > 0 &&
                !checkoutsection &&
                !proceedpaysection &&
                !dateselectsection && (
                  <Box className="newdetail">
                    <Box className="newdetail-main">
                      {/* // for Desktop */}
                        <Box> 
                          <Typography sx={{ color: "white", fontSize: "3rem", fontWeight: '900' }}>{result["make"] ? result["make"] : "NA"}</Typography>
                          <Typography sx={{ color: "#faa61a", fontSize: "1.8rem", fontWeight: '900', marginTop: '-5px'}}>{result["model"] ? result["model"] : "NA"}</Typography>
                        </Box>

                      <Box className="newdetail-grid dextop">
                        <Box className="newdetail-grid-item">
                          <Box className="newdetail-img-slider">
                            <Box className="equipment-carousel-main-div">
                              {img.length != 0 && (
                                <ImageGallery
                                  items={images_gallerys}
                                  infinite={true}
                                  lazyLoad={true}
                                  autoPlay={false}
                                  className="carousel-item equipment-details-item-img "
                                  indicatorContainerProps={{
                                    style: {
                                      marginTop: "30px" // 5
                                    }
                                  }}
                                />
                              )}

                              <Box className="like-dislike-grp">
                                {isLoggedin && (
                                  <>
                                    {LikeLoading ? (
                                      <CircularProgress />
                                    ) : (
                                      <>
                                        {LikeStatus == "1" || LikeStatus == 1 ? (
                                          <Box
                                            className="image-like-icon"
                                            onClick={() => {
                                              LikeDislikeEquip(0);
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <img src={ThumbsImg} alt="" />
                                          </Box>
                                        ) : (
                                          <Box
                                            className="image-like-icon"
                                            onClick={() => {
                                              LikeDislikeEquip(1);
                                            }}
                                            style={{
                                              filter: "grayscale(100%)",
                                              cursor: "pointer"
                                            }}
                                          >
                                            <img src={ThumbsImg} alt="" />
                                          </Box>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </Box>
                            </Box>
                          </Box>

                          <Box className=""></Box>
                        </Box>

                        <Box className="newdetail-grid-item">
                          <Box className="address-main-div">
                            <Box className="address-grid">
                              <Box className="address-grid-item">
                                <Box className="address-grid-item-img">
                                  <img src={LocationIcon} alt="" />
                                </Box>
                                <Box className="address-grid-item-text">
                                  {/* <Link
                              href={mapLink}
                              target="_blank"
                              sx={{ width: "100%" }}
                            > */}
                                  <Typography
                                    onClick={() => {
                                      // by GD 11Dec  on Click added to scroll to Map section
                                      // if (locationBoxRef.current) {
                                      locationBoxRef.current?.scrollIntoView({
                                        behavior: "smooth",
                                        block: "center",
                                      });
                                      // }
                                    }}
                                    sx={{ cursor: 'pointer' }}
                                  >
                                    {result["city"] ? result["city"] : "NA"},{" "}
                                    {result["state"] ? result["state"] : "NA"}
                                  </Typography>
                                  {/* </Link> */}
                                </Box>
                              </Box>

                              {result["owner_pickup_return"] == 0 ||
                                result["owner_pickup_return"] == "0" ? (
                                <>
                                  <Box className="address-grid-item">
                                    <Box className="address-grid-item-img"></Box>
                                    <Box className="address-grid-item-text"></Box>
                                  </Box>
                                </>
                              ) : (
                                <>
                                  <Box className="address-grid-item">
                                    <Box className="address-grid-item-img">
                                      <img src={leftarrow} alt="" />
                                    </Box>
                                    <Box className="address-grid-item-text">
                                      <Typography>Delivery Available</Typography>
                                    </Box>
                                  </Box>
                                </>
                              )}
                            </Box>

                            <Box
                              className="rental-dates-sec"
                              onClick={() => {
                                setdateselectsection(true);
                              }}
                            >
                              <Box className="rental-dates-icon">
                                <img src={calendarimg} alt="" />
                              </Box>
                              <Box className="rental-dates-p">
                                <Typography>
                                  Rental <br /> Dates
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box className="rate-grid">
                            <Box className="rate-grid-item">
                              <Typography>
                                $<CurrentFormat Currency={result["price_month"]} />
                                <span className="rate-item-span">/m</span>
                              </Typography>
                            </Box>
                            <Box className="rate-grid-item">
                              <Typography>
                                $<CurrentFormat Currency={result["price_per_week"]} />
                                <span className="rate-item-span">/w</span>
                              </Typography>
                            </Box>
                            <Box className="rate-grid-item">
                              <Typography>
                                $<CurrentFormat Currency={result["price_day"]} />
                                <span className="rate-item-span">/d</span>
                              </Typography>
                            </Box>
                          </Box>

                          <Box className="owner-grid">
                            <Box className="owner-grid-item">
                              <Box className="owner-profile-img">
                                <img
                                  src={
                                    result["profile_pic"]
                                      ? result["profile_pic"]
                                      : Img
                                  }
                                  alt=""
                                />
                              </Box>
                              <Box className="owner-name-para">
                                <Typography>Owner:</Typography>
                                <Typography
                                // style={{ cursor: "pointer" }}
                                // onClick={() => {
                                //   setOwnerInfo(true);
                                // }}
                                >
                                  {result["first_name"].charAt(0).toUpperCase() +
                                    result["first_name"].slice(1)}
                                </Typography>
                              </Box>
                            </Box>
                            <Box className="owner-grid-item">
                              <Box className="owner-grid-item-div">
                                <Link
                                  className="owner-a"
                                  style={messageOwnerStyle}
                                  onClick={() => {
                                    if (AuthUserID) {
                                      HandleChatOwnerToRenter();
                                    } else {
                                      // createQRcode(deeplink[1]["url"]);
                                       // by GD 21 Feb 2024 for redirection to message without login
                                       HandleChatOwnerToRenterWithotLogin();
                                       dispatch(setLoginModalOpen(true));
                                       dispatch(setLogInLoading(false));
                                      //  dispatch(SetRedirectPage("notifications"));
                                    }
                                  }}
                                >
                                  <Box className="msg-img">
                                    <img src={msgg} alt="" />
                                  </Box>
                                  <Box className="owner-a-text">
                                    <Typography>Message Owner</Typography>
                                  </Box>
                                </Link>
                              </Box>
                              <Box className="owner-grid-item-div">
                                <Link
                                  style={{ cursor: "pointer" }}
                                  className="owner-a"
                                  onClick={() => {
                                    //createQRcode(deeplink[2]["url"])
                                    window.location.href = "/share-rental-yard/rent/" + params.ownerid;
                                    // if (AuthUserID) {
                                    //   window.location.href = "/share-rental-yard/rent/" + params.ownerid;
                                    // } else {
                                    //   //createQRcode(deeplink[2]["url"])
                                    //   createQRcode(Base_url + "/share-rental-yard/rent/" + params.ownerid)
                                    // }
                                  }}
                                >
                                  <Box className="visit-img">
                                    <img src={visit} alt="" />
                                  </Box>
                                  <Box className="owner-a-text">
                                    <Typography>Visit Rental Yard</Typography>
                                  </Box>
                                </Link>
                              </Box>
                            </Box>
                          </Box>
                        </Box>

                        <Box className="newdetail-grid-item">
                          <Box className="newdetail-grid-item-div">
                            <Typography className="newdetail-equip-heading">
                              EQUIPMENT DESCRIPTION
                            </Typography>
                            <hr className="yellow-line" />
                            <Typography className="newdetail-equip-rate-detail">
                              {result["description"] ? result["description"] : "N/A"}
                            </Typography>
                          </Box>

                          {/* Commented on 19 Jan 2024 by MK */}
                          {/* <Box className="newdetail-grid-item-div">
                            <Typography className="newdetail-equip-heading">
                              EQUIPMENT FEATURES
                            </Typography>
                            <hr className="yellow-line" />
                            <Typography className="newdetail-equip-para">
                              {result["feature"] ? result["feature"] : "N/A"}
                            </Typography>
                          </Box> */}

                          <Box className="newdetail-grid-item-div">
                            <Typography className="newdetail-equip-heading">
                              PRICING OPTIONS
                            </Typography>
                            <hr className="yellow-line" />

                            <Typography
                              className="newdetail-equip-subheading"
                              style={{ marginTop: "8px", marginBottom: "-13px" }}
                            >
                              RENTAL RATES
                            </Typography>

                            <Typography className="top-list">
                              <Typography className="newdetail-equip-rate-detail">
                                Price per hour{" "}
                                <span>
                                  $<CurrentFormat Currency={result["price_per_hour"]} />
                                </span>
                              </Typography>
                              <Typography className="newdetail-equip-rate-detail">
                                Price per day{" "}
                                <span>
                                  $<CurrentFormat Currency={result["price_day"]} />
                                </span>
                              </Typography>
                              <Typography className="newdetail-equip-rate-detail">
                                Price per week{" "}
                                <span>
                                  $<CurrentFormat Currency={result["price_per_week"]} />
                                </span>
                              </Typography>
                              <Typography className="newdetail-equip-rate-detail">
                                Price per month{" "}
                                <span>
                                  $<CurrentFormat Currency={result["price_month"]} />
                                </span>
                              </Typography>
                              {/* below line commented by pp on 24 may 2024 due to design changes */}
                              {/* <Typography className="newdetail-equip-rate-detail">
                                Additional per hour price{" "}
                                <span>
                                  $

                                  {result["additional_miles_fee"] != "null"
                                    ? <>
                                      <CurrentFormat Currency={result["additional_miles_fee"]} />
                                    </>
                                    : 0}
                                </span>
                              </Typography> */}
                              <Typography className="newdetail-equip-rate-detail">
                                Hours included per day{" "}
                                <span>
                                  {dollarUSLocale.format(result["included_hrs_day"])}
                                </span>
                              </Typography>
                            </Typography>

                            <Typography className="newdetail-equip-subheading">
                              TRANSPORTATION RATES
                            </Typography>

                            {result["owner_pickup_return"] == 0 ||
                              result["owner_pickup_return"] == "0" ? (
                              <>
                                <Typography className="newdetail-equip-para">
                                  Renter must provide transport.
                                </Typography>
                              </>
                            ) : (
                              <>
                                <Typography className="newdetail-equip-para">
                                  Owner delivery available. Maximum transport range
                                  for this equipment is{" "}
                                  <span className="distance-text">
                                    {result["max_miles"] != "null"
                                      ? dollarUSLocale.format(result["max_miles"])
                                      : 0}{" "}
                                    miles
                                  </span>
                                  . &nbsp;
                                  {result["weekends"] == 0 &&
                                    result["sunday"] == 0 ? (
                                    <span>
                                      {" "}
                                      Owner transport is NOT available on&nbsp;
                                      <span className="weekday">Saturdays</span>{" "}
                                      or&nbsp;
                                      <span className="weekday">Sundays</span>.
                                    </span>
                                  ) : result["weekends"] == 0 ? (
                                    <span>
                                      {" "}
                                      Owner transport is NOT available on&nbsp;
                                      <span className="weekday">Saturdays</span>.
                                    </span>
                                  ) : result["sunday"] == 0 ? (
                                    <span>
                                      {" "}
                                      Owner transport is NOT available on&nbsp;
                                      <span className="weekday">Sunday</span>.
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </Typography>

                                <Box className="bottom-list">
                                  <Typography className="newdetail-equip-rate-detail">
                                    Min. transport charges (for round trip){" "}
                                    <span>
                                      $
                                      {result["minimum_delivery_charges"] != "null"
                                        ?
                                        <>
                                          <CurrentFormat Currency={result["minimum_delivery_charges"]} />
                                        </>
                                        : 0}
                                    </span>
                                  </Typography>
                                  <Typography className="newdetail-equip-rate-detail">
                                    Per-mile charge begins after{" "}
                                    <span>
                                      {result["free_delivery_miles"] != "null"
                                        ? dollarUSLocale.format(
                                          result["free_delivery_miles"]
                                        )
                                        : 0}{" "}
                                      mi
                                    </span>
                                  </Typography>
                                  <Typography className="newdetail-equip-rate-detail">
                                    Per mile rate{" "}
                                    <span>
                                      $
                                      {result["additional_miles_fee"] != "null"
                                        ? dollarUSLocale.format(
                                          result["additional_miles_fee"]
                                        )
                                        : 0}
                                    </span>
                                  </Typography>
                                </Box>
                              </>
                            )}
                          </Box>

                          <Box className="newdetail-grid-item-div">
                            <Typography className="newdetail-equip-heading">
                              DELIVERY AND PICKUP
                            </Typography>
                            <hr className="yellow-line" />
                            <Typography className="newdetail-equip-para">
                              Delivery and pickup is available for this item and if
                              selected will be calculated upon checkout.
                            </Typography>
                          </Box>

                          <Box className="newdetail-grid-item-div">
                            <Typography className="newdetail-equip-heading">
                              DAMAGE & LIABILITY COVERAGE
                            </Typography>
                            <hr className="yellow-line" />
                            <Typography className="newdetail-equip-para">
                              The party in possession of the equipment is responsible
                              for damage to any other person or property while the
                              equipment is in their possession
                            </Typography>
                            <Box className="see-terms">
                              <img src={EquipProtection} alt="" />
                              <Typography className="newdetail-equip-para">
                                $50,000 incidental equipment damage coverage
                                included.&nbsp;
                                <Link
                                  href="/terms_and_conditions"
                                  target="_blank"
                                  className="see-terms-link"
                                >
                                  See terms and conditions
                                </Link>
                              </Typography>
                            </Box>
                          </Box>

                          <Box className="newdetail-grid-item-div">
                            <Typography className="newdetail-equip-heading">
                              MAINTENANCE & SERVICE
                            </Typography>
                            <hr className="yellow-line" />

                            <Typography className="newdetail-equip-para">
                              No data provided.
                            </Typography>
                          </Box>

                          <Box className="newdetail-grid-item-div">
                            <Typography className="newdetail-equip-heading">
                              CANCELLATION
                            </Typography>
                            <hr className="yellow-line" />

                            <Typography className="newdetail-equip-para">
                              Either party can cancel without penalty up to 24 hours
                              prior to rental start time.
                            </Typography>
                          </Box>
                        </Box>

                        {/* <Box className="newdetail-grid-item dextop "> */}
                        <Box className="newdetail-grid-item dextop">
                          <Box className="newdetail-checkout-div">
                            <Typography className="newdetail-equip-heading">
                              Rental Dates
                            </Typography>
                            <Box className="select-date-div">
                              <Typography>Start</Typography>

                              <Box
                                className="calendar-date-select cal-date-pick"
                                onClick={() => {
                                  if (isLoggedin) {
                                    datePickerCalendar.current.openCalendar();
                                  } else {
                                    dispatch(setLoginModalOpen(true));
                                    dispatch(setLogInLoading(false));
                                    dispatch(SetRedirectPage(window.location.href));
                                  }
                                }}
                              >
                                {/* {rentStartDate && rentStartDate} */}
                                {rentStartDate &&
                                  moment(rentStartDate).format("MM/DD/YY")}
                                {!rentStartDate && <>Select Here</>} &nbsp;
                                {rentStartDate && startTime}
                                &nbsp;{rentStartDate && timeZone && <>({timeZoneAbbr})</>}
                              </Box>
                            </Box>
                            <Box className="select-date-div">
                              <Typography>End</Typography>
                              <Box
                                className="calendar-date-select cal-date-pick"
                                onClick={() => {
                                  if (isLoggedin) {
                                    datePickerCalendar.current.openCalendar();
                                  } else {
                                    dispatch(setLoginModalOpen(true));
                                    dispatch(setLogInLoading(false));
                                    dispatch(SetRedirectPage(window.location.href));
                                  }
                                }}
                              >
                                {/* {rentEndDate && rentEndDate} */}
                                {rentEndDate &&
                                  moment(rentEndDate).format("MM/DD/YY")}
                                {!rentEndDate && <>Select Here</>} &nbsp;
                                {rentEndDate && endTime}
                                &nbsp;{rentEndDate && timeZone && <>({timeZoneAbbr})</>}
                              </Box>
                            </Box>
                            <DatePicker
                              numberOfMonths={tabviewcalendar}
                              highlightToday={true}
                              plugins={[<MyPlugin position="bottom" />]}
                              value={values}
                              onChange={(date) => {
                                let start = date[0]?.format?.();
                                console.log("this is it");
                                 // timezone project by pp on 28 Mar 2024  
                                updateEquipmentTimezone(start)
                                 // end by PP
                                let end = date[1]?.format?.();
                                if (!end) {
                                  setEndDate("");
                                  setrentEndDate("");
                                }
                                if (end) {
                                  var endd = moment(end);
                                  var starttt = moment(start);
                                  var diff = endd.diff(starttt, "days");
                                  if (diff > 0) {
                                    for (let index = 0; index <= diff; index++) {
                                      let new_date = new Date(start);
                                      new_date.setDate(new_date.getDate() + index);
                                      let dayname = moment(new_date).format("ddd");
                                      if (
                                        result["weekends"] == "0" &&
                                        dayname == "Sat"
                                      ) {
                                        let a = [end];
                                        setValues(a);
                                        return;
                                      }
                                      if (
                                        result["sunday"] == "0" &&
                                        dayname == "Sun"
                                      ) {
                                        let a = [end];
                                        setValues(a);
                                        return;
                                      }
                                    }
                                  }
                                }
                                if (end && datePickerCalendar.current) {
                                  // datePickerCalendar.current.closeCalendar();
                                }

                                HandleDateRangPicket(start, end);
                              }}
                              ref={datePickerCalendar}
                              mapDays={({ date }) => {
                                let className = "";
                                const strDate = moment(date.format()).format(
                                  "YYYY-M-D"
                                );

                                if (result["weekends"] == 0) {
                                  if (moment(strDate).format("ddd") == "Sat") {
                                    className = "reserved";
                                  }
                                }
                                if (result["sunday"] == 0) {
                                  if (moment(strDate).format("ddd") == "Sun") {
                                    className = "reserved";
                                  }
                                }

                                if (isReserved(strDate)) className = "reserved";
                                if (className) return { className };
                              }}
                              minDate={calendarStartDate}
                              className="my-custom-calendar"
                              range
                              style={{ opacity: "0", display: "none !important" }}
                            />

                            <Typography className="newdetail-equip-heading">
                              Rental Summary
                            </Typography>
                            <Box className="rental-summary-div">
                              <Typography className="rental-summary-text">
                                Rental Price{" "}
                                <span>
                                  $
                                  {new Intl.NumberFormat("en-US", {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                  }).format(rentPrice)}
                                </span>
                              </Typography>
                              <Typography className="rental-summary-text">
                                Security Deposit{" "}
                                <span>
                                  $
                                  {new Intl.NumberFormat("en-US", {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                  }).format(securityDeposit)}
                                </span>
                              </Typography>

                              <Typography className="rental-summary-text equipme-protect">
                                <Link
                                  href="/terms_and_conditions"
                                  target="_blank"
                                  className="see-terms-link equipprotection-link"
                                  style={{ color: "#faa61a" }}
                                >
                                  <span>EquipMe Protection </span>{" "}
                                </Link>
                                <span style={{ textDecoration: "none" }}>
                                  {new Intl.NumberFormat("en-US", {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                  }).format(equipProtection)}
                                </span>
                              </Typography>
                              {transportTotal > 0 && (
                                <Typography className="rental-summary-text">
                                  Transport Total{" "}
                                  <span>
                                    $
                                    {chooseTransport == "owner"
                                      ? new Intl.NumberFormat("en-US", {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                      }).format(transportTotal)
                                      : "0.00"}
                                  </span>
                                </Typography>
                              )}
                            </Box>
                            <Typography className="total-p">
                              <span className="total-span">Total:</span>&nbsp; $
                              {/* {grandTotal.toFixed(2)} */}
                              {new Intl.NumberFormat("en-US", {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                              }).format(grandTotal)}
                            </Typography>

                            <Button
                              className="rent-now-btn"
                              onClickCapture={() => {
                                validateData();
                              }}
                            >
                              rent now
                            </Button>

                            <Box className="transport-options">
                              <Typography className="transport-options-heading">
                                Transport Options
                              </Typography>
                              <FormControl sx={{ fontFamily: "Gotham !important" }}>
                                <Box className="main-radio-section">
                                  <Box className="renter-section-grid">
                                    <Box className="renter-section-image">
                                      <img
                                        src={
                                          chooseTransport == "renter"
                                            ? radioOn
                                            : radioOff
                                        }
                                        //onClickCapture={RenterRadioClick}
                                        onClickCapture={() => {
                                          if (isLoggedin) {
                                            RenterRadioClick();
                                          } else {
                                            dispatch(setLoginModalOpen(true));
                                            dispatch(setLogInLoading(false));
                                            dispatch(
                                              SetRedirectPage(window.location.href)
                                            );
                                          }
                                        }}
                                        className="renter-radio"
                                        alt=""
                                      />
                                    </Box>
                                    <Box className="renter-section-label">
                                      <Typography sx={{ color: "#fff" }}>
                                        I will pickup and return (Renter transports)
                                      </Typography>
                                    </Box>
                                  </Box>

                                  <Box className="renter-section-grid">
                                    <Box></Box>
                                    <Box className="renter-section-detail">
                                      {chooseTransport == "renter" && (
                                        <Box className="firstoption">
                                          <Typography
                                            sx={{
                                              color: "#fff",
                                              fontSize:
                                                "clamp(12px, 3vw, 16px) !important"
                                            }}
                                          >
                                            By selecting Renter Pickup and Return, you
                                            verify that you have the necessary
                                            transport equipment and assume all
                                            liability.
                                          </Typography>
                                          <Typography
                                            sx={{
                                              color: "#faa61a",
                                              fontWeight: "600"
                                            }}
                                          >
                                            EQUIPMENT SPECIFICATIONS
                                          </Typography>
                                          <Box className="firstoption-inner">
                                            <Box className="firstoption-inner-div">
                                              <Typography sx={{ color: "#fff" }}>
                                                Height &nbsp;&nbsp;
                                                {result["height"] && (
                                                  <>
                                                    {result["height"]}{" "}
                                                    {result["height_unit_type"]}.
                                                  </>
                                                )}
                                              </Typography>
                                              <Typography sx={{ color: "#fff" }}>
                                                Length &nbsp;&nbsp;{" "}
                                                {result["length"] && (
                                                  <>
                                                    {result["length"]}{" "}
                                                    {result["length_unit_type"]}.
                                                  </>
                                                )}
                                              </Typography>
                                            </Box>
                                            <Box className="firstoption-inner-div">
                                              <Typography sx={{ color: "#fff" }}>
                                                Width &nbsp;&nbsp;
                                                {result["width"] && (
                                                  <>
                                                    {result["width"]}{" "}
                                                    {result["width_unit_type"]}.
                                                  </>
                                                )}
                                              </Typography>
                                              <Typography sx={{ color: "#fff" }}>
                                                Weight &nbsp;&nbsp;{" "}
                                                {result["weight"] && (
                                                  <>{result["weight"]} lbs</>
                                                )}
                                              </Typography>
                                            </Box>
                                          </Box>
                                        </Box>
                                      )}
                                    </Box>
                                  </Box>

                                  <Box
                                    className="renter-section-grid"
                                    style={{
                                      opacity:
                                        result["owner_pickup_return"] == 0 ||
                                          result["owner_pickup_return"] == "0"
                                          ? "0.4"
                                          : 10
                                    }}
                                  >
                                    <Box className="owner-section-image">
                                      <img
                                        src={
                                          chooseTransport == "owner"
                                            ? radioOn
                                            : radioOff
                                        }
                                        //onClickCapture={OwnerRadioClick}
                                        onClickCapture={() => {
                                          if (isLoggedin) {
                                            OwnerRadioClick();
                                          } else {
                                            dispatch(setLoginModalOpen(true));
                                            dispatch(setLogInLoading(false));
                                            dispatch(
                                              SetRedirectPage(window.location.href)
                                            );
                                          }
                                        }}
                                        className="owner-radio"
                                        alt=""
                                      />
                                    </Box>
                                    <Box className="owner-section-label">
                                      <Typography sx={{ color: "#fff" }}>
                                        Delivery and pickup needed (Owner transports)
                                      </Typography>
                                    </Box>
                                  </Box>

                                  <Box className="owner-section-detail">
                                    {chooseTransport == "owner" && (
                                      <Box className="secondoption">
                                        <Box className="renter-section-grid">
                                          <Box></Box>
                                          <Box>
                                            <Typography
                                              sx={{
                                                color: "#fff"
                                              }}
                                            >
                                              Please tell us the delivery location
                                            </Typography>
                                          </Box>
                                        </Box>

                                        <Box className="renter-section-grid">
                                          <Box className="secondoption-img">
                                            <img src={LocationIcon} alt="" />
                                          </Box>
                                          <Box className="map-location">
                                            {!transportoptionpopup && (
                                              <GOOGLELOCATION
                                                onPress={(center, address) => {
                                                  // console.log("Adress : ", address);
                                                  setAutoCompAddress(address.address);
                                                  calculateDistance(center, address);
                                                }}
                                                inputRefs={inRef}
                                                searchRef={searchRef}
                                                equipAddress={autoCompAddress}
                                              />
                                            )}
                                          </Box>
                                        </Box>
                                        <Box className="secondoption-inner">
                                          <Box className="renter-section-grid">
                                            <Box></Box>
                                            <Box>
                                              <Typography
                                                sx={{
                                                  color: "#fff",
                                                  fontSize:
                                                    "clamp(12px, 3vw, 12px) !important"
                                                }}
                                              >
                                                Delivery Instructions. Provide any
                                                instructions that might be helpful to
                                                the owner for the delivery.
                                              </Typography>
                                            </Box>
                                          </Box>
                                          <Box className="renter-section-grid">
                                            <Box></Box>
                                            <Box>
                                              <Box className="secondoption-inner-div">
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  //label="Max. 400 characters"
                                                  placeholder={"Max. 400 characters"}
                                                  multiline
                                                  maxRows={4}
                                                  rows={4}
                                                  fullWidth
                                                  sx={{
                                                    color: "#adadad",
                                                    "& .MuiInputBase-root": {
                                                      color: "#000",
                                                      background: "#fff",
                                                      fontFamily:
                                                        "GothamBold !important",
                                                      fontSize:
                                                        "clamp(10px, 3vw, 12px) !important"
                                                    },
                                                    "& .MuiFormLabel-root": {
                                                      color: "#adadad"
                                                    },
                                                    "& label.Mui-focused": {
                                                      color: "#000",
                                                      fontWeight: "bold"
                                                    },
                                                    "& .MuiInput-underline:after": {
                                                      borderBottomColor: "#2e2e2e"
                                                    },
                                                    "& .MuiOutlinedInput-root": {
                                                      "& input": {
                                                        color: "#000 !important"
                                                      },
                                                      "& fieldset": {
                                                        borderColor: "#faa61a",
                                                        // backgroundColor: "#fff",
                                                        color: "#faa61a !important"
                                                      },
                                                      "&:hover fieldset": {
                                                        borderColor: "#2e2e2e",
                                                        border: "2px solid #faa61a"
                                                      },
                                                      "&.Mui-focused fieldset": {
                                                        borderColor: "#faa61a",
                                                        backgroundColor:
                                                          "transparent",
                                                        color: "#000 !important"
                                                      }
                                                    }
                                                  }}
                                                  onChange={(e) =>
                                                    DeliveryInstructions(e)
                                                  }
                                                  value={DeliveryInstructionsval}
                                                />
                                                <Typography
                                                  className="ltr-left"
                                                  sx={{ color: "#fff" }}
                                                >
                                                  {DeliveryInstructionsvallength}{" "}
                                                  characters left
                                                </Typography>

                                                <Box className="equipment-rate-inner">
                                                  <Box
                                                    className="rate-list-grids"
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent: "space-between",
                                                      alignItems: "baseline"
                                                    }}
                                                  >
                                                    <Typography
                                                      sx={{ color: "#fff" }}
                                                    >
                                                      Min. Transport (Round Trip) /
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        color: "#fff",
                                                        flex: "1"
                                                      }}
                                                      className="dash-border"
                                                    ></Typography>
                                                    <Typography
                                                      sx={{ color: "#fff" }}
                                                    >
                                                      $
                                                      {new Intl.NumberFormat(
                                                        "en-US",
                                                        {
                                                          maximumFractionDigits: 2,
                                                          minimumFractionDigits: 2
                                                        }
                                                      ).format(
                                                        result[
                                                        "minimum_delivery_charges"
                                                        ]
                                                      )}

                                                    </Typography>
                                                  </Box>
                                                  <Box
                                                    className="rate-list-grids"
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent: "space-between",
                                                      alignItems: "baseline"
                                                    }}
                                                  >
                                                    <Typography
                                                      sx={{ color: "#fff" }}
                                                    >
                                                      Additional Miles Charge
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        color: "#fff",
                                                        flex: "1"
                                                      }}
                                                      className="dash-border"
                                                    ></Typography>
                                                    <Typography
                                                      sx={{ color: "#fff" }}
                                                    >
                                                      $
                                                      {new Intl.NumberFormat(
                                                        "en-US",
                                                        {
                                                          maximumFractionDigits: 2,
                                                          minimumFractionDigits: 2
                                                        }
                                                      ).format(deliveryPrice)}

                                                    </Typography>
                                                  </Box>
                                                </Box>
                                              </Box>
                                            </Box>
                                          </Box>
                                        </Box>
                                        <hr />

                                        <Box className="equipment-rate-inner">
                                          <Box
                                            className="rate-list-grids"
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "baseline"
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                color: "#faa61a",
                                                fontWeight: "bold",
                                                fontFamily: "GothamBold !important",
                                                marginLeft: "25px"
                                              }}
                                            >
                                              Transport Total
                                            </Typography>
                                            <Typography
                                              sx={{ color: "#fff", flex: "1" }}
                                              className="dash-border"
                                            ></Typography>
                                            <Typography
                                              sx={{
                                                color: "#faa61a",
                                                fontWeight: "bold",
                                                fontFamily: "GothamBold !important"
                                              }}
                                            >
                                              $
                                              {transportTotal
                                                ?
                                                new Intl.NumberFormat("en-US", {
                                                  maximumFractionDigits: 2,
                                                  minimumFractionDigits: 2
                                                }).format(transportTotal)
                                                : "0.00"}
                                            </Typography>
                                          </Box>
                                          <Box
                                            className="rate-list-grids"
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "baseline"
                                            }}
                                          ></Box>
                                        </Box>
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                              </FormControl>
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      <Box className="twin-cal">
                        <Box className="twin-cal-item">
                          <NewCalendar
                            numberOfMonths={tabviewcalendar}
                            disableMonthPicker
                            disableYearPicker
                            value={values}
                            onChange={(rang) => {
                              if (isLoggedin) {
                                let start = rang[0]?.format?.();
                                 // timezone project by pp on 28 Mar 2024  
                                console.log("new calender 1")
                                updateEquipmentTimezone(start)
                                // end by PP
                                let end = rang[1]?.format?.();
                                if (start || end) {
                                  let flag = "false";
                                  ChaeckDatesBlockArr.map((itm, ind) => {
                                    let item_date = itm.split("-");
                                    var startDay =
                                      item_date[2] > 9
                                        ? item_date[2]
                                        : "0" + item_date[2];
                                    var startMonth =
                                      item_date[1] > 9
                                        ? item_date[1]
                                        : "0" + item_date[1];
                                    var startYear = item_date[0];
                                    var blk_date =
                                      startMonth + "/" + startDay + "/" + startYear;
                                    var b_date =
                                      moment(blk_date).format("YYYY/MM/DD");
                                    // let b_date = moment(itm).format("YYYY/MM/DD");
                                    if (b_date == start || b_date == end) {
                                      flag = "true";
                                      return false;
                                    }
                                    let foundDateInRange = moment(b_date).isBetween(
                                      start,
                                      end
                                    );
                                    if (foundDateInRange) {
                                      flag = "true";
                                      return false;
                                    }
                                  });

                                  var endd = moment(end);
                                  var starttt = moment(start);
                                  var diff = endd.diff(starttt, "days");
                                  if (diff > 0) {
                                    for (let index = 0; index <= diff; index++) {
                                      let new_date = new Date(start);
                                      new_date.setDate(new_date.getDate() + index);
                                      let dayname = moment(new_date).format("ddd");

                                      if (
                                        result["weekends"] == "0" &&
                                        dayname == "Sat"
                                      ) {
                                        let a = [end];
                                        setValues(a);
                                        return false;
                                      }
                                      if (
                                        result["sunday"] == "0" &&
                                        dayname == "Sun"
                                      ) {
                                        let a = [end];
                                        setValues(a);
                                        return false;
                                      }
                                    }
                                  }
                                  if (flag !== "true") {
                                    let a = [start, end];
                                    setValues(a);

                                    if (start) {
                                      setStartDate(start);
                                      setrentStartDate(
                                        moment(start).format("M/D/YYYY")
                                      );
                                    }

                                    if (end) {
                                      setrentEndDate(moment(end).format("M/D/YYYY"));
                                      setEndDate(end);
                                    }

                                    if (start && end) {
                                      calculatePrice(start, end);
                                    }
                                  } else {
                                    let a = [end];
                                    setValues(a);
                                    return false;
                                  }
                                }
                                return;
                              } else {
                                dispatch(setLoginModalOpen(true));
                                dispatch(setLogInLoading(false));
                                dispatch(SetRedirectPage(window.location.href));
                                return false;
                              }
                            }}
                            mapDays={({ date }) => {
                              let className = "";
                              const strDate = moment(date.format()).format(
                                "YYYY-M-D"
                              );

                              if (isReserved(strDate)) className = "reserved";
                              if (className) return { className };
                            }}
                            // minDate={new Date()}
                            //by pp added next line 28 mar 2024 for timezone change
                            minDate={calendarStartDate}
                            className="twin-cal-custom-calendar"
                            range
                          />
                          <Box className="cal-main-btm">
                            <MyPlugin position="bottom" />
                          </Box>
                        </Box>
                      </Box>

                      <Box className="mapnslide"
                        ref={locationBoxRef}
                      >

                        <Box className="mapsec dextop">
                          <Box className="equipment-details-sub-div">
                            <Box className="mapsec-heading">
                              <Box className="mapsec-icon">
                                <img src={LocationIcon} alt="" />
                              </Box>
                              <Box className="mapsec-heading-text">
                                <Typography
                                  variant="h5"
                                  sx={{
                                    color: "white",
                                    fontSize: "clamp(12px, 3vw, 19px) !important"
                                  }}
                                  className="equipment-description equipment-details-heading"
                                >
                                  EQUIPMENT LOCATION
                                </Typography>
                              </Box>
                            </Box>

                            <Box className="item-value-content description-content equipment-details-para" >
                              {/* <Link
                                href={mapLink}
                                target="_blank"
                                sx={{ width: "100%" }}
                              > */}
                              <img
                                src={Maplocation}
                                alt="Map Location"
                                style={{ width: "100%" }}

                              />
                              {/* </Link> */}
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      {/* For Mobile */}

                      <Box className="newdetail-grid mobile">
                        <Box className="newdetail-grid-item">
                          <Box className="newdetail-img-slider">
                            <Box className="equipment-carousel-main-div mobile-thumb">
                              {img.length != 0 && (
                                <ImageGallery
                                  items={images_gallerys}
                                  infinite={true}
                                  lazyLoad={true}
                                  autoPlay={false}
                                  className="carousel-item equipment-details-item-img"
                                  indicatorContainerProps={{
                                    style: {
                                      marginTop: "30px" // 5
                                    }
                                  }}
                                />
                              )}
                              <Box className="like-dislike-grp">
                                {isLoggedin && (
                                  <>
                                    {LikeLoading ? (
                                      <CircularProgress />
                                    ) : (
                                      <>
                                        {LikeStatus == "1" || LikeStatus == 1 ? (
                                          <Box
                                            className="image-like-icon"
                                            onClick={() => {
                                              LikeDislikeEquip(0);
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <img src={ThumbsImg} alt="" />
                                          </Box>
                                        ) : (
                                          <Box
                                            className="image-like-icon"
                                            onClick={() => {
                                              LikeDislikeEquip(1);
                                            }}
                                            style={{
                                              filter: "grayscale(100%)",
                                              cursor: "pointer"
                                            }}
                                          >
                                            <img src={ThumbsImg} alt="" />
                                          </Box>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </Box>
                            </Box>
                          </Box>

                          <Box className=""></Box>
                        </Box>

                        <Box className="newdetail-grid-item">
                          <Box className="address-main-div">
                            <Box className="address-grid">
                              <Box className="address-grid-item">
                                <Box className="address-grid-item-img">
                                  <img src={LocationIcon} alt="" />
                                </Box>
                                <Box className="address-grid-item-text">
                                  {/* <Link
                              href={mapLink}
                              target="_blank"
                              sx={{ width: "100%" }}
                            > */}
                                  <Typography>
                                    {result["city"] ? result["city"] : "NA"},{" "}
                                    {result["state"] ? result["state"] : "NA"}
                                  </Typography>
                                  {/* </Link> */}
                                </Box>
                              </Box>
                              {result["owner_pickup_return"] == 0 ||
                                result["owner_pickup_return"] == "0" ? (
                                <>
                                  <Box className="address-grid-item">
                                    <Box className="address-grid-item-img"></Box>
                                    <Box className="address-grid-item-text"></Box>
                                  </Box>
                                </>
                              ) : (
                                <>
                                  <Box className="address-grid-item">
                                    <Box className="address-grid-item-img">
                                      <img src={leftarrow} alt="" />
                                    </Box>
                                    <Box className="address-grid-item-text">
                                      <Typography>Delivery Available</Typography>
                                    </Box>
                                  </Box>
                                </>
                              )}
                            </Box>

                            {!rentStartDate && !rentEndDate && (
                              <Box
                                className="rental-dates-sec"
                                onClick={HandleOpencalendar}
                              >
                                <Box className="rental-dates-icon">
                                  <img src={calendarimg} alt="" />
                                </Box>
                                <Box className="rental-dates-p">
                                  <Typography>
                                    Rental <br /> Dates
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                          </Box>
                          <Box className="rate-grid">
                            <Box className="rate-grid-item">
                              <Typography>
                                ${new Intl.NumberFormat("en-US", {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2
                                }).format(result["price_month"])}

                                <span className="rate-item-span">/m</span>
                              </Typography>
                            </Box>
                            <Box className="rate-grid-item">
                              <Typography>
                                ${new Intl.NumberFormat("en-US", {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2
                                }).format(result["price_per_week"])}

                                <span className="rate-item-span">/w</span>
                              </Typography>
                            </Box>
                            <Box className="rate-grid-item">
                              <Typography>
                                ${new Intl.NumberFormat("en-US", {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2
                                }).format(result["price_day"])}
                                <span className="rate-item-span">/d</span>
                              </Typography>
                            </Box>
                          </Box>

                          <Box className="owner-grid">
                            <Box className="owner-grid-item">
                              <Box className="owner-profile-img">
                                <img
                                  src={
                                    result["profile_pic"]
                                      ? result["profile_pic"]
                                      : Img
                                  }
                                  alt=""
                                />
                              </Box>
                              <Box className="owner-name-para">
                                <Typography>Owner:</Typography>
                                <Typography
                                // style={{ cursor: "pointer" }}
                                // onClick={() => {
                                //   setOwnerInfo(true);
                                // }}
                                >
                                  {result["first_name"].charAt(0).toUpperCase() +
                                    result["first_name"].slice(1)}
                                </Typography>
                              </Box>
                            </Box>
                            <Box className="owner-grid-item">
                              <Box className="owner-grid-item-div">
                                <Link
                                  style={messageOwnerStyle}
                                  className="owner-a"
                                  onClick={() => {
                                    if (AuthUserID) {
                                      HandleChatOwnerToRenter();
                                    } else {
                                      // createQRcode(deeplink[1]["url"]);
                                      // by GD 21 Feb 2024 for redirection to message without login
                                      HandleChatOwnerToRenterWithotLogin();
                                      // dispatch(SetRedirectPage("notifications"));
                                      dispatch(setLoginModalOpen(true));
                                      dispatch(setLogInLoading(false));
                                    }
                                  }}
                                >
                                  <Box className="msg-img">
                                    <img src={msgg} alt="" />
                                  </Box>
                                  <Box
                                    className="owner-a-text"
                                    // onClick={HandleChatOwnerToRenter}
                                  >
                                    <Typography>Message Owner</Typography>
                                  </Box>
                                </Link>
                              </Box>
                              <Box className="owner-grid-item-div">
                                <Link
                                  style={{ cursor: "pointer" }}
                                  className="owner-a"
                                  // onClick={() => createQRcode(deeplink[2]["url"])}
                                  onClick={() => {
                                    //createQRcode(deeplink[2]["url"])
                                    window.location.href = "/share-rental-yard/rent/" + params.ownerid;

                                    // if (AuthUserID) {
                                    //   window.location.href = "/share-rental-yard/rent/" + params.ownerid;
                                    // } else {
                                    //   //createQRcode(deeplink[2]["url"])
                                    //   createQRcode(Base_url + "/share-rental-yard/rent/" + params.ownerid)
                                    // }
                                  }}
                                >
                                  <Box className="visit-img">
                                    <img src={visit} alt="" />
                                  </Box>
                                  <Box className="owner-a-text">
                                    <Typography>Visit Rental Yard</Typography>
                                  </Box>
                                </Link>
                              </Box>
                            </Box>
                          </Box>

                          {rentStartDate &&
                            startTime &&
                            rentEndDate &&
                            endTime &&
                            !rentersummaymobile && (
                              <>
                                <Box className="rental-summry-sec">
                                  <Box className="rental-summry-heading">
                                    <Typography>YOUR RENTAL SUMMARY</Typography>
                                  </Box>

                                  <Box className="rental-summry-rate-grid">
                                    <Box className="rental-summry-rate-item">
                                      <Typography>
                                        <span>Rental Price</span>
                                        <span>
                                          $
                                          {new Intl.NumberFormat("en-US", {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2
                                          }).format(rentPrice)}

                                        </span>
                                      </Typography>
                                      <Typography>
                                        <span>Security Deposit</span>
                                        <span>

                                          $
                                          {new Intl.NumberFormat("en-US", {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2
                                          }).format(securityDeposit)}
                                        </span>
                                      </Typography>
                                      <Typography className="mobile-rental-summary-text">
                                        <Link
                                          href="/terms_and_conditions"
                                          target="_blank"
                                          className="see-terms-link equipprotection-link"
                                          style={{
                                            color: "#fff",
                                            textDecorationColor: "#fff"
                                          }}
                                        >
                                          <span>EquipMe Protection</span>
                                        </Link>
                                        <span>

                                          $
                                          {new Intl.NumberFormat("en-US", {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2
                                          }).format(equipProtection)}
                                        </span>
                                      </Typography>
                                    </Box>
                                    <Box className="rental-summry-rate-item">
                                      <Typography className="rental-dates-heading rental-date-m">
                                        Rental Dates
                                      </Typography>
                                      <Typography className="rental-smmry-dd">
                                        <span>Start</span>
                                        <span>
                                          {/* {rentStartDate && rentStartDate}&nbsp; */}
                                          {rentStartDate &&
                                            moment(rentStartDate).format(
                                              "MM/DD/YYYY"
                                            )}
                                          &nbsp;
                                          {rentStartDate && startTime}
                                        </span>
                                        {/* Start {rentStartDate && rentStartDate}&nbsp;
                                  {rentStartDate && startTime} */}
                                      </Typography>
                                      <Typography className="rental-smmry-dd">
                                        <span> End</span>
                                        <span>
                                          {/* {rentEndDate && rentEndDate}&nbsp; */}
                                          {rentEndDate &&
                                            moment(rentEndDate).format("MM/DD/YYYY")}
                                          &nbsp;
                                          {rentEndDate && endTime}
                                        </span>
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box className="rental-summry-edit">
                                    <Link
                                      className="rental-summry-edit-btn"
                                      //onClick={() => { setdateselectsection(true)}}
                                      onClick={HandleOpencalendar}
                                    >
                                      Edit
                                    </Link>
                                  </Box>

                                  <Box className="rental-summry-total">
                                    <Typography>
                                      <span className="rental-summry-yellow-text">
                                        Total:
                                      </span>{" "}
                                      {/* {grandTotal.toFixed(2)} */}
                                      {new Intl.NumberFormat("en-US", {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                      }).format(grandTotal)}
                                    </Typography>
                                  </Box>

                                  <Box className="bottom-note">
                                    {result["owner_pickup_return"] == "0" ? (
                                      <Typography>
                                        * Renter pickup available only.
                                      </Typography>
                                    ) : (
                                      <Typography>
                                        * Delivery & Pickup available. Select RENT NOW
                                        to see options.
                                      </Typography>
                                    )}
                                  </Box>
                                </Box>
                                <Box className="below-owner-photos">
                                  <Button
                                    className="rent-now-btn"
                                    onClickCapture={() => {
                                      validateDataMobile();
                                    }}
                                  >
                                    rent now
                                  </Button>
                                </Box>
                              </>
                            )}

                          {rentersummaymobile && (
                            <Box className="newdetail-grid-item mobile">
                              <Box className="newdetail-checkout-div">
                                <Typography className="newdetail-equip-heading">
                                  Rental Dates
                                </Typography>
                                <Box className="select-date-div">
                                  <Typography>Start</Typography>
                                  <Box
                                    className="calendar-date-select"
                                  // onClick={() => {
                                  //   datePickerCalendar.current.openCalendar();
                                  // }}
                                  >
                                    {rentStartDate && rentStartDate}
                                    {!rentStartDate && <>Select Here</>} &nbsp;
                                    {rentStartDate && startTime}
                                  </Box>
                                </Box>
                                <Box className="select-date-div">
                                  <Typography>End</Typography>
                                  <Box
                                    className="calendar-date-select"
                                  // onClick={() => {
                                  //   datePickerCalendar.current.openCalendar();
                                  // }}
                                  >
                                    {rentEndDate && rentEndDate}
                                    {!rentEndDate && <>Select Here</>} &nbsp;
                                    {rentEndDate && endTime}
                                  </Box>
                                </Box>

                                <Typography className="newdetail-equip-heading">
                                  Rental Summary
                                </Typography>
                                <Box className="rental-summary-div">
                                  <Typography className="rental-summary-text">
                                    Rental Price{" "}
                                    <span>
                                      $
                                      {new Intl.NumberFormat("en-US", {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                      }).format(rentPrice)}
                                    </span>
                                  </Typography>
                                  <Typography className="rental-summary-text">
                                    Security Deposit{" "}
                                    <span>

                                      $
                                      {new Intl.NumberFormat("en-US", {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                      }).format(securityDeposit)}
                                    </span>
                                  </Typography>
                                  <Typography className="rental-summary-text equipme-protect">
                                    <Link
                                      href="/terms_and_conditions"
                                      target="_blank"
                                      className="see-terms-link equipprotection-link"
                                      style={{ color: "#faa61a" }}
                                    >
                                      <span>EquipMe Protection </span>{" "}
                                    </Link>
                                    <span style={{ textDecoration: "none" }}>

                                      $
                                      {new Intl.NumberFormat("en-US", {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                      }).format(equipProtection)}
                                    </span>
                                  </Typography>

                                  <Typography className="rental-summary-text">
                                    Transport Total{" "}
                                    <span>
                                      $
                                      {chooseTransport == "owner"
                                        ?
                                        new Intl.NumberFormat("en-US", {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2
                                        }).format(transportTotal)
                                        : "0.00"}
                                    </span>
                                  </Typography>
                                </Box>
                                <Typography className="total-p">
                                  <span className="total-span">Total:</span>&nbsp; $

                                  {new Intl.NumberFormat("en-US", {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                  }).format(grandTotal)}
                                </Typography>

                                <Button
                                  className="rent-now-btn"
                                  onClickCapture={() => {
                                    validatedeliveroptionMobile();
                                  }}
                                >
                                  rent now
                                </Button>

                                <Box className="transport-options">
                                  <Typography
                                    className="transport-options-heading"
                                    sx={{ color: "#fff !important" }}
                                  >
                                    Transport Options
                                  </Typography>
                                  <FormControl
                                    sx={{ fontFamily: "Gotham !important" }}
                                  >
                                    <Box className="main-radio-section">
                                      <Box className="renter-section-grid">
                                        <Box className="renter-section-image">
                                          <img
                                            src={
                                              chooseTransport == "renter"
                                                ? radioOn
                                                : radioOff
                                            }
                                            onClickCapture={RenterRadioClick}
                                            className="renter-radio"
                                            alt=""
                                          />
                                        </Box>
                                        <Box className="renter-section-label">
                                          <Typography sx={{ color: "#fff" }}>
                                            I will pickup and return (Renter
                                            transports)
                                          </Typography>
                                        </Box>
                                      </Box>

                                      <Box className="renter-section-grid">
                                        <Box></Box>
                                        <Box className="renter-section-detail">
                                          {chooseTransport == "renter" && (
                                            <Box className="firstoption">
                                              <Typography
                                                sx={{
                                                  color: "#fff",
                                                  fontSize:
                                                    "clamp(12px, 3vw, 16px) !important"
                                                }}
                                              >
                                                By selecting Renter Pickup and Return,
                                                you verify that you have the necessary
                                                transport equipment and assume all
                                                liability.
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  color: "#faa61a",
                                                  fontWeight: "600"
                                                }}
                                              >
                                                EQUIPMENT SPECIFICATIONS
                                              </Typography>
                                              <Box className="firstoption-inner">
                                                <Box className="firstoption-inner-div">
                                                  <Typography sx={{ color: "#fff" }}>
                                                    Height &nbsp;&nbsp;
                                                    {result["height"] && (
                                                      <>
                                                        {result["height"]}{" "}
                                                        {result["height_unit_type"]}.
                                                      </>
                                                    )}
                                                  </Typography>
                                                  <Typography sx={{ color: "#fff" }}>
                                                    Length &nbsp;&nbsp;{" "}
                                                    {result["length"] && (
                                                      <>
                                                        {result["length"]}{" "}
                                                        {result["length_unit_type"]}.
                                                      </>
                                                    )}
                                                  </Typography>
                                                </Box>
                                                <Box className="firstoption-inner-div">
                                                  <Typography sx={{ color: "#fff" }}>
                                                    Width &nbsp;&nbsp;
                                                    {result["width"] && (
                                                      <>
                                                        {result["width"]}{" "}
                                                        {result["width_unit_type"]}.
                                                      </>
                                                    )}
                                                  </Typography>
                                                  <Typography sx={{ color: "#fff" }}>
                                                    Weight &nbsp;&nbsp;{" "}
                                                    {result["weight"] && (
                                                      <>{result["weight"]} lbs</>
                                                    )}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                            </Box>
                                          )}
                                        </Box>
                                      </Box>

                                      <Box
                                        className="renter-section-grid"
                                        style={{
                                          opacity:
                                            result["owner_pickup_return"] == 0 ||
                                              result["owner_pickup_return"] == "0"
                                              ? "0.4"
                                              : 10
                                        }}
                                      >
                                        <Box className="owner-section-image">
                                          <img
                                            src={
                                              chooseTransport == "owner"
                                                ? radioOn
                                                : radioOff
                                            }
                                            onClickCapture={OwnerRadioClick}
                                            className="owner-radio"
                                            alt=""
                                          />
                                        </Box>
                                        <Box className="owner-section-label">
                                          <Typography sx={{ color: "#fff" }}>
                                            Delivery and pickup needed (Owner
                                            transports)
                                          </Typography>
                                        </Box>
                                      </Box>

                                      <Box className="owner-section-detail">
                                        {chooseTransport == "owner" && (
                                          <Box className="secondoption">
                                            <Box className="renter-section-grid">
                                              <Box></Box>
                                              <Box>
                                                <Typography
                                                  sx={{
                                                    color: "#fff"
                                                  }}
                                                >
                                                  Please tell us the delivery location
                                                </Typography>
                                              </Box>
                                            </Box>

                                            <Box className="renter-section-grid">
                                              <Box className="secondoption-img">
                                                <img src={LocationIcon} alt="" />
                                              </Box>
                                              <Box className="map-location">
                                                <GOOGLELOCATION
                                                  onPress={(center, address) => {
                                                    calculateDistance(
                                                      center,
                                                      address
                                                    );
                                                  }}
                                                  inputRefs={inRef}
                                                  searchRef={searchRef}
                                                />
                                              </Box>
                                            </Box>
                                            <Box className="secondoption-inner">
                                              <Box className="renter-section-grid">
                                                <Box></Box>
                                                <Box>
                                                  <Typography
                                                    sx={{
                                                      color: "#fff",
                                                      fontSize:
                                                        "clamp(12px, 3vw, 12px) !important"
                                                    }}
                                                  >
                                                    Delivery Instructions. Provide any
                                                    instructions that might be helpful
                                                    to the owner for the delivery.
                                                  </Typography>
                                                </Box>
                                              </Box>
                                              <Box className="renter-section-grid">
                                                <Box></Box>
                                                <Box>
                                                  <Box className="secondoption-inner-div">
                                                    <TextField
                                                      id="outlined-multiline-static"
                                                      //label="Max. 400 characters"
                                                      placeholder={
                                                        "Max. 400 characters"
                                                      }
                                                      multiline
                                                      maxRows={4}
                                                      rows={4}
                                                      fullWidth
                                                      sx={{
                                                        color: "#adadad",
                                                        "& .MuiInputBase-root": {
                                                          color: "#000",
                                                          background: "#fff",
                                                          fontFamily:
                                                            "GothamBold !important",
                                                          fontSize:
                                                            "clamp(10px, 3vw, 12px) !important"
                                                        },
                                                        "& .MuiFormLabel-root": {
                                                          color: "#adadad"
                                                        },
                                                        "& label.Mui-focused": {
                                                          color: "#000",
                                                          fontWeight: "bold"
                                                        },
                                                        "& .MuiInput-underline:after":
                                                        {
                                                          borderBottomColor:
                                                            "#2e2e2e"
                                                        },
                                                        "& .MuiOutlinedInput-root": {
                                                          "& input": {
                                                            color: "#000 !important"
                                                          },
                                                          "& fieldset": {
                                                            borderColor: "#faa61a",
                                                            // backgroundColor: "#fff",
                                                            color:
                                                              "#faa61a !important"
                                                          },
                                                          "&:hover fieldset": {
                                                            borderColor: "#2e2e2e",
                                                            border:
                                                              "2px solid #faa61a"
                                                          },
                                                          "&.Mui-focused fieldset": {
                                                            borderColor: "#faa61a",
                                                            backgroundColor:
                                                              "transparent",
                                                            color: "#000 !important"
                                                          }
                                                        }
                                                      }}
                                                      onChange={(e) =>
                                                        DeliveryInstructions(e)
                                                      }
                                                      value={DeliveryInstructionsval}
                                                    />
                                                    <Typography
                                                      className="ltr-left"
                                                      sx={{ color: "#fff" }}
                                                    >
                                                      {DeliveryInstructionsvallength}{" "}
                                                      characters left
                                                    </Typography>

                                                    <Box className="equipment-rate-inner">
                                                      <Box
                                                        className="rate-list-grids"
                                                        sx={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                          alignItems: "baseline"
                                                        }}
                                                      >
                                                        <Typography
                                                          sx={{ color: "#fff" }}
                                                        >
                                                          Min. Transport (Round Trip)
                                                        </Typography>
                                                        <Typography
                                                          sx={{
                                                            color: "#fff",
                                                            flex: "1"
                                                          }}
                                                          className="dash-border"
                                                        ></Typography>
                                                        <Typography
                                                          sx={{ color: "#fff" }}
                                                        >
                                                          $
                                                          {new Intl.NumberFormat(
                                                            "en-US",
                                                            {
                                                              maximumFractionDigits: 2,
                                                              minimumFractionDigits: 2
                                                            }
                                                          ).format(
                                                            result[
                                                            "minimum_delivery_charges"
                                                            ]
                                                          )}
                                                        </Typography>
                                                      </Box>
                                                      <Box
                                                        className="rate-list-grids"
                                                        sx={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                          alignItems: "baseline"
                                                        }}
                                                      >
                                                        <Typography
                                                          sx={{ color: "#fff" }}
                                                        >
                                                          Additional Miles Charge
                                                        </Typography>
                                                        <Typography
                                                          sx={{
                                                            color: "#fff",
                                                            flex: "1"
                                                          }}
                                                          className="dash-border"
                                                        ></Typography>
                                                        <Typography
                                                          sx={{ color: "#fff" }}
                                                        >
                                                          $
                                                          {new Intl.NumberFormat(
                                                            "en-US",
                                                            {
                                                              maximumFractionDigits: 2,
                                                              minimumFractionDigits: 2
                                                            }
                                                          ).format(deliveryPrice)}

                                                        </Typography>
                                                      </Box>

                                                      <hr className="gray-line" />
                                                      <Typography
                                                        className="transport-total"
                                                        sx={{
                                                          color: "#faa61a",
                                                          fontWeight: "800",
                                                          fontFamily:
                                                            "GothamBold !important",
                                                          textAlign: "center"
                                                        }}
                                                      >
                                                        <span>Transport Total</span>
                                                        <span>
                                                          $

                                                          {new Intl.NumberFormat(
                                                            "en-US",
                                                            {
                                                              maximumFractionDigits: 2,
                                                              minimumFractionDigits: 2
                                                            }
                                                          ).format(transportTotal)}
                                                        </span>
                                                      </Typography>
                                                    </Box>
                                                  </Box>
                                                </Box>
                                              </Box>
                                            </Box>
                                          </Box>
                                        )}
                                      </Box>
                                    </Box>
                                  </FormControl>
                                </Box>
                              </Box>
                            </Box>
                          )}

                          {!rentStartDate && !rentEndDate && (
                            <Box className="below-owner-photo">
                              <Button
                                className="rent-now-btn"
                                onClick={HandleOpencalendar}
                              >
                                rent now
                              </Button>
                            </Box>
                          )}
                        </Box>
                        {!rentersummaymobile && (
                          <>
                            <Box
                              className="newdetail-grid-item"
                              style={{ marginTop: "-30px" }}
                            >
                              <Box className="newdetail-grid-item-div">
                                <Typography className="newdetail-equip-heading">
                                  EQUIPMENT DESCRIPTION
                                </Typography>
                                <hr className="yellow-line" />
                                <Typography className="newdetail-equip-rate-detail">
                                  {result["description"]
                                    ? result["description"]
                                    : "N/A"}
                                </Typography>
                              </Box>

                            {/* Commented on 19 Jan 2024 by MK */}
                              {/* <Box className="newdetail-grid-item-div">
                                <Typography className="newdetail-equip-heading">
                                  EQUIPMENT FEATURES
                                </Typography>
                                <hr className="yellow-line" />
                                <Typography className="newdetail-equip-para">
                                  {result["feature"] ? result["feature"] : "N/A"}
                                </Typography>
                              </Box> */}

                              <Box className="newdetail-grid-item-div">
                                <Typography className="newdetail-equip-heading">
                                  PRICING OPTIONS
                                </Typography>
                                <hr className="yellow-line" />
                                <Typography
                                  className="newdetail-equip-subheading"
                                  style={{ marginTop: "8px", marginBottom: "-13px" }}
                                >
                                  RENTAL RATES
                                </Typography>

                                <Typography className="top-list">
                                  <Typography className="newdetail-equip-rate-detail">
                                    Price per hour{" "}
                                    <span>
                                      $<CurrentFormat Currency={result["price_per_hour"]} />
                                    </span>
                                  </Typography>
                                  <Typography className="newdetail-equip-rate-detail">
                                    Price per day{" "}
                                    <span>
                                      $<CurrentFormat Currency={result["price_day"]} />
                                    </span>
                                  </Typography>
                                  <Typography className="newdetail-equip-rate-detail">
                                    Price per week{" "}
                                    <span>
                                      $<CurrentFormat Currency={result["price_per_week"]} />
                                    </span>
                                  </Typography>
                                  <Typography className="newdetail-equip-rate-detail">
                                    Price per month{" "}
                                    <span>
                                      $<CurrentFormat Currency={result["price_month"]} />
                                    </span>
                                  </Typography>
                                  <Typography className="newdetail-equip-rate-detail">
                                    Additional per hour price{" "}
                                    <span>
                                      $
                                      {result["additional_miles_fee"] != "null"
                                        ?
                                        <>
                                          <CurrentFormat Currency={result["additional_miles_fee"]} />
                                        </>
                                        : 0}
                                    </span>
                                  </Typography>
                                  <Typography className="newdetail-equip-rate-detail">
                                    Hours included per day{" "}
                                    <span>
                                      {dollarUSLocale.format(
                                        result["included_hrs_day"]
                                      )}
                                    </span>
                                  </Typography>
                                </Typography>

                                <Typography className="newdetail-equip-subheading">
                                  TRANSPORTATION RATES
                                </Typography>

                                {result["owner_pickup_return"] == 0 ||
                                  result["owner_pickup_return"] == "0" ? (
                                  <>
                                    <Typography className="newdetail-equip-para">
                                      Renter must provide transport.
                                    </Typography>
                                  </>
                                ) : (
                                  <>
                                    <Typography className="newdetail-equip-para">
                                      Owner delivery available. Maximum transport
                                      range for this equipment is{" "}
                                      <span className="distance-text">
                                        {result["max_miles"] != "null"
                                          ? dollarUSLocale.format(result["max_miles"])
                                          : 0}{" "}
                                        miles
                                      </span>
                                      . &nbsp;
                                      {result["weekends"] == 0 &&
                                        result["sunday"] == 0 ? (
                                        <span>
                                          {" "}
                                          Owner transport is NOT available on&nbsp;
                                          <span className="weekday">
                                            Saturdays
                                          </span>{" "}
                                          or&nbsp;
                                          <span className="weekday">Sundays</span>.
                                        </span>
                                      ) : result["weekends"] == 0 ? (
                                        <span>
                                          {" "}
                                          Owner transport is NOT available on&nbsp;
                                          <span className="weekday">Saturdays</span>.
                                        </span>
                                      ) : result["sunday"] == 0 ? (
                                        <span>
                                          {" "}
                                          Owner transport is NOT available on&nbsp;
                                          <span className="weekday">Sunday</span>.
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </Typography>

                                    <Box className="bottom-list">
                                      <Typography className="newdetail-equip-rate-detail">
                                        Min. transport charges (for round trip){" "}
                                        <span>
                                          $
                                          {result["minimum_delivery_charges"] !=
                                            "null"
                                            ?
                                            <>
                                              <CurrentFormat Currency={result["minimum_delivery_charges"]} />
                                            </>
                                            : 0}
                                        </span>
                                      </Typography>
                                      <Typography className="newdetail-equip-rate-detail">
                                        Per-mile charge begins after{" "}
                                        <span>
                                          {result["free_delivery_miles"] != "null"
                                            ? dollarUSLocale.format(
                                              result["free_delivery_miles"]
                                            )
                                            : 0}{" "}
                                          mi
                                        </span>
                                      </Typography>
                                      <Typography className="newdetail-equip-rate-detail">
                                        Per mile rate{" "}
                                        <span>
                                          $
                                          {result["additional_miles_fee"] != "null"
                                            ? dollarUSLocale.format(
                                              result["additional_miles_fee"]
                                            )
                                            : 0}
                                        </span>
                                      </Typography>
                                    </Box>
                                  </>
                                )}
                              </Box>

                              <Box className="newdetail-grid-item-div">
                                <Typography className="newdetail-equip-heading">
                                  DELIVERY AND PICKUP
                                </Typography>
                                <hr className="yellow-line" />
                                <Typography className="newdetail-equip-para">
                                  Delivery and pickup is available for this item and
                                  if selected will be calculated upon checkout.
                                </Typography>
                              </Box>

                              <Box className="newdetail-grid-item-div">
                                <Typography className="newdetail-equip-heading">
                                  DAMAGE & LIABILITY COVERAGE
                                </Typography>
                                <hr className="yellow-line" />
                                <Typography className="newdetail-equip-para">
                                  The party in possession of the equipment is
                                  responsible for damage to any other person or
                                  property while the equipment is in their possession
                                </Typography>
                                <Box className="see-terms">
                                  <img src={EquipProtection} alt="" />
                                  <Typography className="newdetail-equip-para">
                                    $50,000 incidental equipment damage coverage
                                    included.&nbsp;
                                    <Link
                                      href="/terms_and_conditions"
                                      target="_blank"
                                      className="see-terms-link"
                                    >
                                      See terms and conditions
                                    </Link>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box className="newdetail-grid-item-div">
                                <Typography className="newdetail-equip-heading">
                                  MAINTENANCE & SERVICE
                                </Typography>
                                <hr className="yellow-line" />

                                <Typography className="newdetail-equip-para">
                                  No data provided.
                                </Typography>
                              </Box>

                              <Box className="newdetail-grid-item-div">
                                <Typography className="newdetail-equip-heading">
                                  CANCELLATION
                                </Typography>
                                <hr className="yellow-line" />

                                <Typography className="newdetail-equip-para">
                                  Either party can cancel without penalty up to 24
                                  hours prior to rental start time.
                                </Typography>
                              </Box>
                            </Box>

                            <Box className="newdetail-grid-item dextop">
                              <Box className="newdetail-checkout-div">
                                <Typography className="newdetail-equip-heading">
                                  Rental Dates
                                </Typography>
                                <Box className="select-date-div">
                                  <Typography>Start</Typography>
                                  <Box
                                    className="calendar-date-select"
                                  // onClick={() => {
                                  //   datePickerCalendar.current.openCalendar();
                                  // }}
                                  >
                                    {rentStartDate && rentStartDate}
                                    {!rentStartDate && <>Select Here</>} &nbsp;
                                    {rentStartDate && startTime}
                                  </Box>
                                </Box>
                                <Box className="select-date-div">
                                  <Typography>End</Typography>
                                  <Box
                                    className="calendar-date-select"
                                  // onClick={() => {
                                  //   datePickerCalendar.current.openCalendar();
                                  // }}
                                  >
                                    {rentEndDate && rentEndDate}
                                    {!rentEndDate && <>Select Here</>} &nbsp;
                                    {rentEndDate && endTime}
                                  </Box>
                                </Box>

                                <Typography className="newdetail-equip-heading">
                                  Rental Summary
                                </Typography>
                                <Box className="rental-summary-div">
                                  <Typography className="rental-summary-text">
                                    Rental Price{" "}
                                    <span>

                                      {new Intl.NumberFormat("en-US", {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                      }).format(rentPrice)}
                                    </span>
                                  </Typography>
                                  <Typography className="rental-summary-text">
                                    Security Deposit{" "}
                                    <span>

                                      $
                                      {new Intl.NumberFormat("en-US", {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                      }).format(securityDeposit)}
                                    </span>
                                  </Typography>
                                  <Typography className="rental-summary-text equipme-protect">
                                    EquipMe Protection{" "}
                                    <span>

                                      $
                                      {new Intl.NumberFormat("en-US", {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                      }).format(equipProtection)}
                                    </span>
                                  </Typography>
                                  <Typography className="rental-summary-text">
                                    Transport Total{" "}
                                    <span>
                                      $
                                      {chooseTransport == "owner"
                                        ?
                                        new Intl.NumberFormat("en-US", {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2
                                        }).format(transportTotal)
                                        : "0"}
                                    </span>
                                  </Typography>
                                </Box>
                                <Typography className="total-p">
                                  <span className="total-span">Total:</span>&nbsp; $

                                  {new Intl.NumberFormat("en-US", {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                  }).format(grandTotal)}
                                </Typography>

                                <Button
                                  className="rent-now-btn"
                                  onClickCapture={() => {
                                    validateData();
                                  }}
                                >
                                  rent now
                                </Button>

                                <Box className="transport-options">
                                  <Typography className="transport-options-heading">
                                    Transport Options
                                  </Typography>
                                  <FormControl
                                    sx={{ fontFamily: "Gotham !important" }}
                                  >
                                    <Box className="main-radio-section">
                                      <Box className="renter-section-grid">
                                        <Box className="renter-section-image">
                                          <img
                                            src={
                                              chooseTransport == "renter"
                                                ? radioOn
                                                : radioOff
                                            }
                                            onClickCapture={RenterRadioClick}
                                            className="renter-radio"
                                            alt=""
                                          />
                                        </Box>
                                        <Box className="renter-section-label">
                                          <Typography sx={{ color: "#fff" }}>
                                            I will pickup and return (Renter
                                            transports)
                                          </Typography>
                                        </Box>
                                      </Box>

                                      <Box className="renter-section-grid">
                                        <Box></Box>
                                        <Box className="renter-section-detail">
                                          {chooseTransport == "renter" && (
                                            <Box className="firstoption">
                                              <Typography
                                                sx={{
                                                  color: "#fff",
                                                  fontSize:
                                                    "clamp(12px, 3vw, 16px) !important"
                                                }}
                                              >
                                                By selecting Renter Pickup and Return,
                                                you verify that you have the necessary
                                                transport equipment and assume all
                                                liability.
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  color: "#faa61a",
                                                  fontWeight: "600"
                                                }}
                                              >
                                                EQUIPMENT SPECIFICATIONS
                                              </Typography>
                                              <Box className="firstoption-inner">
                                                <Box className="firstoption-inner-div">
                                                  <Typography sx={{ color: "#fff" }}>
                                                    Height &nbsp;&nbsp;
                                                    {result["height"] && (
                                                      <>
                                                        {result["height"]}{" "}
                                                        {result["height_unit_type"]}.
                                                      </>
                                                    )}
                                                  </Typography>
                                                  <Typography sx={{ color: "#fff" }}>
                                                    Length &nbsp;&nbsp;{" "}
                                                    {result["length"] && (
                                                      <>
                                                        {result["length"]}{" "}
                                                        {result["length_unit_type"]}.
                                                      </>
                                                    )}
                                                  </Typography>
                                                </Box>
                                                <Box className="firstoption-inner-div">
                                                  <Typography sx={{ color: "#fff" }}>
                                                    Width &nbsp;&nbsp;
                                                    {result["width"] && (
                                                      <>
                                                        {result["width"]}{" "}
                                                        {result["width_unit_type"]}.
                                                      </>
                                                    )}
                                                  </Typography>
                                                  <Typography sx={{ color: "#fff" }}>
                                                    Weight &nbsp;&nbsp;{" "}
                                                    {result["weight"] && (
                                                      <>{result["weight"]} lbs</>
                                                    )}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                            </Box>
                                          )}
                                        </Box>
                                      </Box>

                                      <Box
                                        className="renter-section-grid"
                                        style={{
                                          opacity:
                                            result["owner_pickup_return"] == 0 ||
                                              result["owner_pickup_return"] == "0"
                                              ? "0.4"
                                              : 10
                                        }}
                                      >
                                        <Box className="owner-section-image">
                                          <img
                                            src={
                                              chooseTransport == "owner"
                                                ? radioOn
                                                : radioOff
                                            }
                                            onClickCapture={OwnerRadioClick}
                                            className="owner-radio"
                                            alt=""
                                          />
                                        </Box>
                                        <Box className="owner-section-label">
                                          <Typography sx={{ color: "#fff" }}>
                                            Delivery and pickup needed (Owner
                                            transports)
                                          </Typography>
                                        </Box>
                                      </Box>

                                      <Box className="owner-section-detail">
                                        {chooseTransport == "owner" && (
                                          <Box className="secondoption">
                                            <Box className="renter-section-grid">
                                              <Box></Box>
                                              <Box>
                                                <Typography
                                                  sx={{
                                                    color: "#fff"
                                                  }}
                                                >
                                                  Please tell us the delivery location
                                                </Typography>
                                              </Box>
                                            </Box>

                                            <Box className="renter-section-grid">
                                              <Box className="secondoption-img">
                                                <img src={LocationIcon} alt="" />
                                              </Box>
                                              <Box className="map-location">
                                                <GOOGLELOCATION
                                                  onPress={(center, address) => {
                                                    calculateDistance(
                                                      center,
                                                      address
                                                    );
                                                  }}
                                                  inputRefs={inRef}
                                                  searchRef={searchRef}
                                                />
                                              </Box>
                                            </Box>
                                            <Box className="secondoption-inner">
                                              <Box className="renter-section-grid">
                                                <Box></Box>
                                                <Box>
                                                  <Typography
                                                    sx={{
                                                      color: "#fff",
                                                      fontSize:
                                                        "clamp(12px, 3vw, 12px) !important"
                                                    }}
                                                  >
                                                    Delivery Instructions. Provide any
                                                    instructions that might be helpful
                                                    to the owner for the delivery.
                                                  </Typography>
                                                </Box>
                                              </Box>
                                              <Box className="renter-section-grid">
                                                <Box></Box>
                                                <Box>
                                                  <Box className="secondoption-inner-div">
                                                    <TextField
                                                      id="outlined-multiline-static"
                                                      //label="Max. 400 characters"
                                                      placeholder={
                                                        "Max. 400 characters"
                                                      }
                                                      multiline
                                                      maxRows={4}
                                                      rows={4}
                                                      fullWidth
                                                      sx={{
                                                        color: "#adadad",
                                                        "& .MuiInputBase-root": {
                                                          color: "#000",
                                                          background: "#fff",
                                                          fontFamily:
                                                            "GothamBold !important",
                                                          fontSize:
                                                            "clamp(10px, 3vw, 12px) !important"
                                                        },
                                                        "& .MuiFormLabel-root": {
                                                          color: "#adadad"
                                                        },
                                                        "& label.Mui-focused": {
                                                          color: "#000",
                                                          fontWeight: "bold"
                                                        },
                                                        "& .MuiInput-underline:after":
                                                        {
                                                          borderBottomColor:
                                                            "#2e2e2e"
                                                        },
                                                        "& .MuiOutlinedInput-root": {
                                                          "& input": {
                                                            color: "#000 !important"
                                                          },
                                                          "& fieldset": {
                                                            borderColor: "#faa61a",
                                                            // backgroundColor: "#fff",
                                                            color:
                                                              "#faa61a !important"
                                                          },
                                                          "&:hover fieldset": {
                                                            borderColor: "#2e2e2e",
                                                            border:
                                                              "2px solid #faa61a"
                                                          },
                                                          "&.Mui-focused fieldset": {
                                                            borderColor: "#faa61a",
                                                            backgroundColor:
                                                              "transparent",
                                                            color: "#000 !important"
                                                          }
                                                        }
                                                      }}
                                                      onChange={(e) =>
                                                        DeliveryInstructions(e)
                                                      }
                                                      value={DeliveryInstructionsval}
                                                    />
                                                    <Typography
                                                      className="ltr-left"
                                                      sx={{ color: "#fff" }}
                                                    >
                                                      {DeliveryInstructionsvallength}{" "}
                                                      characters left
                                                    </Typography>

                                                    <Box className="equipment-rate-inner">
                                                      <Box
                                                        className="rate-list-grids"
                                                        sx={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                          alignItems: "baseline"
                                                        }}
                                                      >
                                                        <Typography
                                                          sx={{ color: "#fff" }}
                                                        >
                                                          Min. Transport (Round Trip)
                                                        </Typography>
                                                        <Typography
                                                          sx={{
                                                            color: "#fff",
                                                            flex: "1"
                                                          }}
                                                          className="dash-border"
                                                        ></Typography>
                                                        <Typography
                                                          sx={{ color: "#fff" }}
                                                        >
                                                          $
                                                          {new Intl.NumberFormat(
                                                            "en-US",
                                                            {
                                                              maximumFractionDigits: 2,
                                                              minimumFractionDigits: 2
                                                            }
                                                          ).format(
                                                            result[
                                                            "minimum_delivery_charges"
                                                            ]
                                                          )}
                                                        </Typography>
                                                      </Box>
                                                      <Box
                                                        className="rate-list-grids"
                                                        sx={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                          alignItems: "baseline"
                                                        }}
                                                      >
                                                        <Typography
                                                          sx={{ color: "#fff" }}
                                                        >
                                                          Additional Miles Charge
                                                        </Typography>
                                                        <Typography
                                                          sx={{
                                                            color: "#fff",
                                                            flex: "1"
                                                          }}
                                                          className="dash-border"
                                                        ></Typography>
                                                        <Typography
                                                          sx={{ color: "#fff" }}
                                                        >
                                                          $
                                                          {new Intl.NumberFormat(
                                                            "en-US",
                                                            {
                                                              maximumFractionDigits: 2,
                                                              minimumFractionDigits: 2
                                                            }
                                                          ).format(deliveryPrice)}

                                                        </Typography>
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                                </Box>
                                              </Box>
                                            </Box>
                                            <Typography
                                              className="transport-total"
                                              sx={{
                                                color: "#faa61a",
                                                fontWeight: "800",
                                                fontFamily: "GothamBold !important",
                                                textAlign: "center"
                                              }}
                                            >
                                              Transport Total: $
                                              {transportTotal
                                                ?
                                                new Intl.NumberFormat("en-US", {
                                                  maximumFractionDigits: 2,
                                                  minimumFractionDigits: 2
                                                }).format(transportTotal)
                                                : "0.00"}
                                            </Typography>
                                          </Box>
                                        )}
                                      </Box>
                                    </Box>
                                  </FormControl>
                                </Box>
                              </Box>
                            </Box>

                            <Box className="twin-cal">
                              <Box className="twin-cal-item">
                                <NewCalendar
                                  numberOfMonths={2}
                                  disableMonthPicker
                                  disableYearPicker
                                  value={values}
                                  onChange={(rang) => {
                                    let start = rang[0]?.format?.();
                                    //by pp on 9 apr 2023
                                    console.log("new calender 2")
                                    updateEquipmentTimezone(start)
                                    // end by pp
                                    let end = rang[1]?.format?.();
                                    if (start || end) {
                                      let flag = "false";
                                      ChaeckDatesBlockArr.map((itm, ind) => {
                                        let item_date = itm.split("-");
                                        var startDay =
                                          item_date[2] > 9
                                            ? item_date[2]
                                            : "0" + item_date[2];
                                        var startMonth =
                                          item_date[1] > 9
                                            ? item_date[1]
                                            : "0" + item_date[1];
                                        var startYear = item_date[0];
                                        var blk_date =
                                          startMonth +
                                          "/" +
                                          startDay +
                                          "/" +
                                          startYear;
                                        var b_date =
                                          moment(blk_date).format("YYYY/MM/DD");
                                        // let b_date = moment(itm).format("YYYY/MM/DD");

                                        //let b_date = moment(itm).format("YYYY/MM/DD");
                                        if (b_date == start || b_date == end) {
                                          flag = "true";
                                          return false;
                                        }

                                        let foundDateInRange = moment(
                                          b_date
                                        ).isBetween(start, end);

                                        if (foundDateInRange) {
                                          flag = "true";
                                          return false;
                                        }
                                      });

                                      var endd = moment(end);
                                      var starttt = moment(start);
                                      var diff = endd.diff(starttt, "days");
                                      if (diff > 0) {
                                        for (let index = 0; index <= diff; index++) {
                                          let new_date = new Date(start);
                                          new_date.setDate(
                                            new_date.getDate() + index
                                          );
                                          let dayname =
                                            moment(new_date).format("ddd");

                                          if (
                                            result["weekends"] == "0" &&
                                            dayname == "Sat"
                                          ) {
                                            let a = [end];
                                            setValues(a);
                                            return false;
                                          }
                                          if (
                                            result["sunday"] == "0" &&
                                            dayname == "Sun"
                                          ) {
                                            let a = [end];
                                            setValues(a);
                                            return false;
                                          }
                                        }
                                      }
                                      if (flag !== "true") {
                                        let a = [start, end];
                                        setValues(a);

                                        if (start) {
                                          setStartDate(start);
                                          setrentStartDate(
                                            moment(start).format("M/D/YYYY")
                                          );
                                        }

                                        if (end) {
                                          setrentEndDate(
                                            moment(end).format("M/D/YYYY")
                                          );
                                          setEndDate(end);
                                        }

                                        if (start && end) {
                                          calculatePrice(start, end);
                                        }
                                      } else {
                                        let a = [end];
                                        setValues(a);
                                        return false;
                                      }
                                    }
                                    return;
                                  }}
                                  mapDays={({ date }) => {
                                    let className = "";
                                    const strDate = moment(date.format()).format(
                                      "YYYY-M-D"
                                    );
                                    let dayname = moment(strDate).format("dddd");
                                    if (isReserved(strDate)) className = "reserved";
                                    if (className) return { className };
                                  }}
                                  minDate={calendarStartDate}
                                  className="twin-cal-custom-calendar"
                                  range
                                />
                              </Box>
                            </Box>

                            <Box className="mapnslide">
                              <Box className="value-slider dextop">
                                <Box className="slider-parent-div">
                                  <Box className="slider-div">
                                    <Typography sx={{ color: "#fff" }}>
                                      Pickup Time {timeZone && <>({timeZoneAbbr})</>} {startTime}
                                    </Typography>
                                    <Slider
                                      aria-label="Default"
                                      valueLabelDisplay="off"
                                      min={
                                        minimumPositionStart() == -1
                                          ? 0
                                          : minimumPositionStart()
                                      }
                                      max={timeArr.length - 1}
                                      value={timeArr.indexOf(startTime)}
                                      onChange={(event) => {
                                        //StartTimeHandel(event)
                                        setStartTime(timeArr[event.target["value"]]);
                                        calculatePrice(rentStartDate, rentEndDate);
                                      }}
                                      step={1}
                                      sx={{
                                        color: "#0F8F81",
                                        "& .MuiSlider-rail": {
                                          backgroundColor: "#B2B2B2"
                                        },
                                        "& .MuiSlider-thumb": {
                                          backgroundColor: "#faa61a",
                                          width: "25px !important",
                                          height: "25px !important"
                                        }
                                      }}
                                    />
                                  </Box>
                                  <Box className="slider-div">
                                    <Typography sx={{ color: "#fff" }}>
                                      Return Time {timeZone && <>({timeZoneAbbr})</>} {endTime}
                                    </Typography>
                                    <Slider
                                      aria-label="Default"
                                      valueLabelDisplay="off"
                                      min={0}
                                      max={timeArr.length - 1}
                                      value={timeArr.indexOf(endTime)}
                                      onChange={(event) => {
                                        // EndTimeHandel(event)
                                        setEndTime(timeArr[event.target["value"]]);
                                        calculatePrice(rentStartDate, rentEndDate);
                                      }}
                                      step={1}
                                      sx={{
                                        color: "#0F8F81",
                                        "& .MuiSlider-rail": {
                                          backgroundColor: "#B2B2B2"
                                        },
                                        "& .MuiSlider-thumb": {
                                          backgroundColor: "#faa61a",
                                          width: "25px !important",
                                          height: "25px !important"
                                        }
                                      }}
                                    />
                                  </Box>
                                </Box>
                              </Box>

                              <Box className="mapsec" style={{ marginTop: "-40px" }}>
                                <Box className="equipment-details-sub-div">
                                  <Box className="mapsec-heading">
                                    <Box className="mapsec-icon">
                                      <img src={LocationIcon} alt="" />
                                    </Box>
                                    <Box className="mapsec-heading-text">
                                      <Typography
                                        variant="h5"
                                        sx={{
                                          color: "white",
                                          fontSize:
                                            "clamp(12px, 3vw, 19px) !important"
                                        }}
                                        className="equipment-description equipment-details-heading"
                                      >
                                        EQUIPMENT LOCATION
                                      </Typography>
                                    </Box>
                                  </Box>

                                  <Box className="item-value-content description-content equipment-details-para">
                                    {/* <Link
                                href={mapLink}
                                target="_blank"
                                sx={{ width: "100%" }}
                              > */}
                                    <img
                                      src={Maplocation}
                                      alt="Map Location"
                                      style={{ width: "100%" }}
                                    />
                                    {/* </Link> */}
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                )}

              {/* Calendar Section for mobile */}

              <Box
                className="calendar-save-sec cal-avail-large"
                style={{ display: dateselectsection ? "block" : "none" }}
              >
                <Box className="calendar-heading-grid">
                  <Box className="calendar-grid-icon">
                    <img src={calendarimg} alt="" />
                  </Box>
                  <Box className="calendar-heading-p">
                    <Typography>AVAILABILITY CALENDAR 789</Typography>
                  </Box>
                </Box>

                <Box
                  className="large-cal"
                  style={{ display: DeviceType == "large" ? "block" : "none" }}
                >
                  <NewCalendar
                    numberOfMonths={2}
                    disableMonthPicker
                    disableYearPicker
                    value={values}
                    onChange={(rang) => {
                      let start = rang[0]?.format?.();
                      // timezone project by pp on 28 Mar 2024  
                      console.log("new calender 3")
                      updateEquipmentTimezone(start)
                      // end by PP
                      let end = rang[1]?.format?.();

                      if (start || end) {
                        let flag = "false";
                        ChaeckDatesBlockArr.map((itm, ind) => {
                          let item_date = itm.split("-");
                          var startDay =
                            item_date[2] > 9 ? item_date[2] : "0" + item_date[2];
                          var startMonth =
                            item_date[1] > 9 ? item_date[1] : "0" + item_date[1];
                          var startYear = item_date[0];
                          var blk_date =
                            startMonth + "/" + startDay + "/" + startYear;
                          var b_date = moment(blk_date).format("YYYY/MM/DD");
                          // let b_date = moment(itm).format("YYYY/MM/DD");
                          if (b_date == start || b_date == end) {
                            flag = "true";
                            return false;
                          }
                          let foundDateInRange = moment(b_date).isBetween(start, end);
                          if (foundDateInRange) {
                            flag = "true";
                            return false;
                          }
                        });

                        var endd = moment(end);
                        var starttt = moment(start);
                        var diff = endd.diff(starttt, "days");
                        if (diff > 0) {
                          for (let index = 0; index <= diff; index++) {
                            let new_date = new Date(start);
                            new_date.setDate(new_date.getDate() + index);
                            let dayname = moment(new_date).format("ddd");

                            if (result["weekends"] == "0" && dayname == "Sat") {
                              let a = [end];
                              setValues(a);
                              return false;
                            }
                            if (result["sunday"] == "0" && dayname == "Sun") {
                              let a = [end];
                              setValues(a);
                              return false;
                            }
                          }
                        }

                        if (flag !== "true") {
                          let a = [start, end];
                          setValues(a);

                          if (start) {
                            setStartDate(start);
                            setrentStartDate(moment(start).format("M/D/YYYY"));
                          }

                          if (end) {
                            setrentEndDate(moment(end).format("M/D/YYYY"));
                            setEndDate(end);
                          }

                          if (start && end) {
                            calculatePrice(start, end);
                          }
                        } else {
                          let a = [end];
                          setValues(a);
                          return false;
                        }
                      }
                      return;
                    }}
                    mapDays={({ date }) => {
                      let className = "";
                      const strDate = moment(date.format()).format("YYYY-M-D");
                      if (isReserved(strDate)) className = "reserved";
                      if (className) return { className };
                    }}
                    minDate={calendarStartDate}
                    className="twin-cal-custom-calendar"
                    range
                  />
                </Box>

                <Box
                  className="small-cal"
                  style={{ display: DeviceType == "small" ? "block" : "none" }}
                >
                  {/* <DatePicker
                    className="rmdp-mobile"
                    render={<InputIcon/>}
                    mobileLabels={{
                      OK: "Accept",
                      CANCEL: "Close",
                    }}
                  /> */}

                  <Calendar
                    locale={myCustomLocale}
                    value={selectedDayRange}
                    onChange={(event) => {
                      DateRangSelect(event, result["weekends"], result["sunday"]);
                    }}
                    minimumDate={minimumDate}
                    calendarClassName="responsive-calendar"
                    calendarTodayClassName="custom-today-day"
                    customDaysClassName={blockdate}
                    disabledDays={blockdate}
                  />
                </Box>

                <Box className="calendar-sec-slider">
                  <Box className="value-slider">
                    <Box className="slider-parent-div">
                      <Box className="slider-div">
                        <Typography sx={{ color: "#fff" }}>
                          Pickup Time {timeZone && <>({timeZoneAbbr})</>} {startTime}
                        </Typography>
                        <Slider
                          aria-label="Default"
                          valueLabelDisplay="off"
                          min={
                            minimumPositionStart() == -1 ? 0 : minimumPositionStart()
                          }
                          max={timeArr.length - 1}
                          value={timeArr.indexOf(startTime)}
                          onChange={(event) => {
                            //StartTimeHandel(event)
                            setStartTime(timeArr[event.target["value"]]);
                            calculatePrice(rentStartDate, rentEndDate);
                          }}
                          step={1}
                          sx={{
                            color: "#0F8F81",
                            "& .MuiSlider-rail": {
                              backgroundColor: "#B2B2B2"
                            },
                            "& .MuiSlider-thumb": {
                              backgroundColor: "#faa61a",
                              width: "25px !important",
                              height: "25px !important"
                            }
                          }}
                        />
                      </Box>
                      <Box className="slider-div">
                        <Typography sx={{ color: "#fff" }}>
                          Return Time {timeZone && <>({timeZoneAbbr})</>} {endTime}
                        </Typography>
                        <Slider
                          aria-label="Default"
                          valueLabelDisplay="off"
                          min={0}
                          max={timeArr.length - 1}
                          value={timeArr.indexOf(endTime)}
                          onChange={(event) => {
                            // EndTimeHandel(event)
                            setEndTime(timeArr[event.target["value"]]);
                            calculatePrice(rentStartDate, rentEndDate);
                          }}
                          step={1}
                          sx={{
                            color: "#0F8F81",
                            "& .MuiSlider-rail": {
                              backgroundColor: "#B2B2B2"
                            },
                            "& .MuiSlider-thumb": {
                              backgroundColor: "#faa61a",
                              width: "25px !important",
                              height: "25px !important"
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box className="calendar-btn-sec">
                  <Button
                    className="rent-now-btn reset-btn"
                    onClickCapture={() => {
                      resetCalendar();
                    }}
                  >
                    Reset
                  </Button>
                  <Box
                    className="small-save"
                    style={{ display: DeviceType == "small" ? "block" : "none" }}
                  >
                    <Button
                      className="rent-now-btn save-btn small-save-btn"
                      onClickCapture={() => {
                        SaveCalendarDates();
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                  <Box
                    className="large-save"
                    style={{ display: DeviceType == "large" ? "block" : "none" }}
                  >
                    <Button
                      className="rent-now-btn save-btn large-save-btn"
                      onClickCapture={() => {
                        SaveDoubleCalendarDates();
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Box>

              {/* Loader section */}
              {LoadWalletBalance && (
                <Box>
                  <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={LoadWalletBalance}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Box>
              )}

              {/* Payment section */}
              {proceedpaysection && (
                <>
                  {/* Payment section */}
                  <Box className="proceed-sec">
                    <Box className="proceed-sec-heading">
                      <ArrowBackIcon
                        className="back-btn-icon"
                        onClickCapture={() => {
                          GoBack("checkout");
                        }}
                      />

                      <Box className="proceed-sec-icon">
                        <img src={bookingimg} alt="" />
                      </Box>
                      <Box
                        className="proceed-sec-text"
                        onClickCapture={() => {
                          GoBack("proceedpaysection");
                        }}
                      >
                        <Typography>
                          Let’s
                          <span>Go</span>!
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="eqip-detail-div">
                      <Typography className="eqip-detail-div-head">
                        Rental Dates
                      </Typography>
                      <Typography className="eqip-detail-div-list-item">
                        Start
                        <span>
                          {rentStartDate &&
                            moment(rentStartDate).format("MM/DD/YYYY")}
                          &nbsp;
                          {/* {rentStartDate &&  rentStartDate}&nbsp; */}
                          {rentStartDate && startTime}
                          &nbsp;{timeZone && <>({timeZoneAbbr})</>}
                        </span>
                      </Typography>
                      <Typography className="eqip-detail-div-list-item">
                        End
                        <span>
                          {rentEndDate && moment(rentEndDate).format("MM/DD/YYYY")}
                          &nbsp;
                          {/* {rentEndDate && rentEndDate }&nbsp; */}
                          {rentEndDate && endTime}
                          &nbsp;{timeZone && <>({timeZoneAbbr})</>}
                        </span>
                      </Typography>

                      <Typography className="eqip-detail-div-head">
                        Rental Summary
                      </Typography>

                      <Box className="eqip-detail-div-list">
                        <Typography className="eqip-detail-div-list-item">
                          Rental Price{" "}
                          <span>
                            $
                            {new Intl.NumberFormat("en-US", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2
                            }).format(rentPrice)}

                          </span>
                        </Typography>
                        <Typography className="eqip-detail-div-list-item">
                          Security Deposit{" "}
                          <span>
                            $
                            {new Intl.NumberFormat("en-US", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2
                            }).format(securityDeposit)}

                          </span>
                        </Typography>

                        <Typography className="eqip-detail-div-list-item ">
                          <Link
                            href="/terms_and_conditions"
                            target="_blank"
                            className="eqip-detail-div-link"
                            style={{ color: "#faa61a" }}
                          >
                            <span>EquipMe Protection </span>{" "}
                          </Link>
                          <span style={{ textDecoration: "none" }}>
                            $
                            {new Intl.NumberFormat("en-US", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2
                            }).format(equipProtection)}

                          </span>
                        </Typography>

                        <Typography className="eqip-detail-div-list-item">
                          Transport Total{" "}
                          <span>
                            $
                            {chooseTransport == "owner"
                              ? new Intl.NumberFormat("en-US", {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                              }).format(transportTotal)
                              : "0.00"}

                          </span>
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="total-amount-div">
                      <Typography>Total Amount to Pay</Typography>
                      <Typography className="total-value-text">
                        {/* ${grandTotal.toFixed(2)} */}$
                        {new Intl.NumberFormat("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        }).format(grandTotal)}
                      </Typography>
                      <Box className="proceed-btn-div">
                        <Button
                          className="proceed-btn letsgo"
                          onClick={() => {
                            //setplaceOrder(true);
                            SubmitPlaceOrder();
                          }}
                        >
                          Request Rental
                        </Button>
                      </Box>

                      <Box className="wallet-div">
                        <Typography>
                          My Wallet Balance:&nbsp;
                          <span>
                            {new Intl.NumberFormat("en-US", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2
                            }).format(WalletBalance)}
                          </span>
                        </Typography>
                      </Box>

                      <Box className="addmoney-div">
                        <Link className="addmoney-a">
                          <img src={addImg} alt="" />
                          <Typography
                            onClick={addbalancepopupClickOpen}
                            sx={{ cursor: "pointer" }}
                          >
                            Add Money
                          </Typography>
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}

              {/* No Equipment section  */}
              {!loading && rescount != 1 && (
                <Box className="service-section__row">
                  <Box sx={{ width: "100%" }} className="equipment-details-main">
                    <Box sx={{ width: "100%" }} className="no-data">
                      <Typography variant="h4">No Equipment Found.</Typography>
                    </Box>
                  </Box>
                </Box>
              )}

              {/* Add Balance section start */}
              <Modal
                open={addbalancebox}
                onClose={addbalancepopupClose}
                className="addmoney-modal"
              >
                <Box
                  className="download-popups"
                  sx={{
                    ...loginstyle,
                    padding: "0px"
                  }}
                >
                  <IconButton className="cross-btn" onClick={addbalancepopupClose}>
                    <CloseIcon />
                  </IconButton>

                  <Box className="login__main">
                    <Box
                      className="login__main-div edit-eqp"
                    // sx={{
                    //   display: "flex",
                    //   justifyContent: "center",
                    // }}
                    >
                      <Box
                        className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                        style={{
                          border: "5px solid #faa61a",
                          borderRadius: "10px"
                        }}
                      //component="form"
                      // onSubmit={login}
                      >
                        <Typography
                          variant="h5"
                          style={{ color: "#faa61a", marginBottom: "0.5rem" }}
                        >
                          Add Funds to Wallet
                        </Typography>

                        <AddBalance
                          onSuccess={(e) => {
                            if (e == "success") {
                              // viewWalletHistory(UserId, AuthToken, 0, "new");
                              // setaddbalancebox(false);
                              // getuserprofile(user_id);
                              getuserprofile(AuthUserID);
                              setLoadWalletBalance(false);
                              setaddbalancebox(false);
                              // dispatch(setalertMessage(balanceresult["message"]));
                              // dispatch(setalertMessagetype("success"));
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Modal>

              {/* Owner Info Popup */}
              <Modal
                keepMounted
                open={OwnerInfo}
                onClose={() => setOwnerInfo(false)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
              >
                <Box
                  className="download-popups"
                  sx={{
                    ...loginstyle,
                    padding: "0px"
                  }}
                >
                  <IconButton
                    className="cross-btn"
                    onClick={() => setOwnerInfo(false)}
                  >
                    <CloseIcon />
                  </IconButton>

                  <Box className="login__main">
                    <Box
                      className="login__main-div edit-eqp"
                      sx={{
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <Box className="login__main-div__form popup-cmmn popup-white edit-eqp-inner">
                        <Typography variant="h5" style={{ color: "#faa61a" }}>
                          Owner Info
                        </Typography>
                        {OwnerInfo && <UserInfo userData={result} />}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Modal>

              {/* Select rent dates form calendar popup */}
              <Modal
                keepMounted
                open={CalendarPopup}
                onClose={() => setCalendarPopup(false)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
              >
                <Box
                  className="download-popups"
                  sx={{
                    ...loginstyle,
                    padding: "0px"
                  }}
                >
                  <IconButton
                    className="cross-btn"
                    onClick={() => setCalendarPopup(false)}
                  >
                    <CloseIcon />
                  </IconButton>

                  <Box className="login__main">
                    <Box
                      className="login__main-div edit-eqp"
                      sx={{
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <Box
                        className="login__main-div__form edit-eqp-inner pupup-scroll"
                        style={{ display: CalendarPopup ? "block" : "none" }}
                      >
                        <Box className="modal-cal">
                          <DetailComponent
                            monthview={2}
                            EquipObj={result}
                            BlockDatesArr={ChaeckDatesBlockArr}
                            // handleStartEndTime={handleStartEndTime}
                            // timeObj={timeObj}
                            MyPlugin={MyPlugin}
                            OnSuccess={(start, end) => {
                              setCalendarPopupResult({
                                start: start,
                                end: end
                              });
                            }}
                            calendarStartDate={calendarStartDate}
                            updateEquipmentTimezone={updateEquipmentTimezone}
                          />
                        </Box>

                        <Box className="modal-cal-btn-grp">
                          <Button
                            className="rent-now-btn cmmn-cancel-btn"
                            onClickCapture={() => {
                              setCalendarPopup(false);
                              //  setrentEndDate("")
                              //     setrentStartDate("")
                              //     setStartDate("")
                              //     setEndDate("")
                              //     setValues(ChaeckDatesBlockArr);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="rent-now-btn cmmn-green-btn-2"
                            onClick={() => {
                              let calendatDates = CalendarPopupResult;

                              if (
                                calendatDates.start == null &&
                                calendatDates.end == null
                              ) {
                                dispatch(
                                  setalertMessage(
                                    "Please select rental start and end date first."
                                  )
                                );
                                dispatch(setalertMessagetype("error"));
                                return false;
                              } else if (calendatDates.start == null) {
                                dispatch(
                                  setalertMessage(
                                    "Please select rental start date first."
                                  )
                                );
                                dispatch(setalertMessagetype("error"));
                                return false;
                              } else if (calendatDates.end == null) {
                                dispatch(
                                  setalertMessage(
                                    "Please select rental end date first."
                                  )
                                );
                                dispatch(setalertMessagetype("error"));
                                return false;
                              }

                              if (calendatDates.start && calendatDates.end) {
                                setStartDate(calendatDates.start);
                                setrentStartDate(
                                  moment(calendatDates.start).format("M/D/YYYY")
                                );

                                setrentEndDate(
                                  moment(calendatDates.end).format("M/D/YYYY")
                                );
                                setEndDate(calendatDates.end);

                                let a = [calendatDates.start, calendatDates.end];
                                setValues(a);
                                calculatePrice(
                                  calendatDates.start,
                                  calendatDates.end
                                );
                                let diffMins = GetDifferenceinmin(
                                  moment(calendatDates.start).format("M/D/YYYY"),
                                  moment(calendatDates.end).format("M/D/YYYY")
                                );

                                if (diffMins === null) {
                                  // console.log("in if condition");

                                  dispatch(
                                    setalertMessage(
                                      "The selected pickup time is in the past."
                                    )
                                  );
                                  dispatch(setalertMessagetype("error"));
                                  return false;
                                } else {
                                  // console.log("in else condition");
                                  settransportoptionpopup(true);
                                  setCalendarPopup(false);
                                }
                              }
                            }}
                          >
                            Next
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Modal>

              {/* Select rent transport option popup */}
              <Modal
                keepMounted
                open={transportoptionpopup}
                onClose={() => settransportoptionpopup(false)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
              >
                <Box
                  className="download-popups"
                  sx={{
                    ...loginstyle,
                    padding: "0px"
                  }}
                >
                  <IconButton
                    className="cross-btn"
                    onClick={() => settransportoptionpopup(false)}
                  >
                    <CloseIcon />
                  </IconButton>

                  <Box className="login__main">
                    <Box
                      className="login__main-div edit-eqp"
                      sx={{
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <Box className="login__main-div__form popup-cmmn popup-white edit-eqp-inner">
                        {transportoptionpopup && (
                          <>
                            <Box className="transport-options">
                              <Typography
                                className="transport-options-heading"
                                sx={{ color: "#fff !important" }}
                              >
                                Transport Options
                              </Typography>
                              <FormControl sx={{ fontFamily: "Gotham !important" }}>
                                <Box className="main-radio-section modal-radio-sec">
                                  <Box className="renter-section-grid">
                                    <Box className="renter-section-image">
                                      <img
                                        src={
                                          chooseTransport == "renter"
                                            ? radioOn
                                            : radioOff
                                        }
                                        //onClickCapture={RenterRadioClick}
                                        onClickCapture={() => {
                                          if (isLoggedin) {
                                            RenterRadioClick();
                                          } else {
                                            dispatch(setLoginModalOpen(true));
                                            dispatch(setLogInLoading(false));
                                            dispatch(
                                              SetRedirectPage(window.location.href)
                                            );
                                          }
                                        }}
                                        className="renter-radio"
                                        alt=""
                                      />
                                    </Box>
                                    <Box className="renter-section-label">
                                      <Typography sx={{ color: "#fff" }}>
                                        I will pickup and return (Renter transports)
                                      </Typography>
                                    </Box>
                                  </Box>

                                  <Box className="renter-section-grid">
                                    <Box></Box>
                                    <Box className="renter-section-detail">
                                      {chooseTransport == "renter" && (
                                        <Box className="firstoption">
                                          <Typography
                                            sx={{
                                              color: "#fff",
                                              fontSize:
                                                "clamp(12px, 3vw, 16px) !important"
                                            }}
                                          >
                                            By selecting Renter Pickup and Return, you
                                            verify that you have the necessary
                                            transport equipment and assume all
                                            liability.
                                          </Typography>
                                          <Typography
                                            sx={{
                                              color: "#faa61a",
                                              fontWeight: "600"
                                            }}
                                          >
                                            EQUIPMENT SPECIFICATIONS
                                          </Typography>
                                          <Box className="firstoption-inner">
                                            <Box className="firstoption-inner-div">
                                              <Typography sx={{ color: "#fff" }}>
                                                Height &nbsp;&nbsp;
                                                {result["height"] && (
                                                  <>
                                                    {result["height"]}{" "}
                                                    {result["height_unit_type"]}.
                                                  </>
                                                )}
                                              </Typography>
                                              <Typography sx={{ color: "#fff" }}>
                                                Length &nbsp;&nbsp;{" "}
                                                {result["length"] && (
                                                  <>
                                                    {result["length"]}{" "}
                                                    {result["length_unit_type"]}.
                                                  </>
                                                )}
                                              </Typography>
                                            </Box>
                                            <Box className="firstoption-inner-div">
                                              <Typography sx={{ color: "#fff" }}>
                                                Width &nbsp;&nbsp;
                                                {result["width"] && (
                                                  <>
                                                    {result["width"]}{" "}
                                                    {result["width_unit_type"]}.
                                                  </>
                                                )}
                                              </Typography>
                                              <Typography sx={{ color: "#fff" }}>
                                                Weight &nbsp;&nbsp;{" "}
                                                {result["weight"] && (
                                                  <>{result["weight"]} lbs</>
                                                )}
                                              </Typography>
                                            </Box>
                                          </Box>
                                        </Box>
                                      )}
                                    </Box>
                                  </Box>

                                  <Box
                                    className="renter-section-grid"
                                    style={{
                                      opacity:
                                        result["owner_pickup_return"] == 0 ||
                                          result["owner_pickup_return"] == "0"
                                          ? "0.4"
                                          : 10
                                    }}
                                  >
                                    <Box className="owner-section-image">
                                      <img
                                        src={
                                          chooseTransport == "owner"
                                            ? radioOn
                                            : radioOff
                                        }
                                        //onClickCapture={OwnerRadioClick}
                                        onClickCapture={() => {
                                          if (isLoggedin) {
                                            OwnerRadioClick();
                                          } else {
                                            dispatch(setLoginModalOpen(true));
                                            dispatch(setLogInLoading(false));
                                            dispatch(
                                              SetRedirectPage(window.location.href)
                                            );
                                          }
                                        }}
                                        className="owner-radio"
                                        alt=""
                                      />
                                    </Box>
                                    <Box className="owner-section-label">
                                      <Typography sx={{ color: "#fff" }}>
                                        Delivery and pickup needed (Owner transports)
                                      </Typography>
                                    </Box>
                                  </Box>

                                  <Box className="owner-section-detail">
                                    {chooseTransport == "owner" && (
                                      <Box className="secondoption">
                                        <Box className="renter-section-grid">
                                          <Box></Box>
                                          <Box>
                                            <Typography
                                              sx={{
                                                color: "#fff"
                                              }}
                                            >
                                              Please tell us the delivery location
                                            </Typography>
                                          </Box>
                                        </Box>

                                        <Box className="renter-section-grid">
                                          <Box className="secondoption-img">
                                            <img src={LocationIcon} alt="" />
                                          </Box>
                                          <Box className="map-location">
                                            <GOOGLELOCATION
                                              onPress={(center, address) => {
                                                // console.log("Adress : ", address);
                                                setAutoCompAddress(address.address);
                                                calculateDistance(center, address);
                                              }}
                                              inputRefs={inRef}
                                              searchRef={searchRef}
                                              equipAddress={autoCompAddress}
                                            />
                                          </Box>
                                        </Box>
                                        <Box className="secondoption-inner">
                                          <Box className="renter-section-grid">
                                            <Box></Box>
                                            <Box>
                                              <Typography
                                                sx={{
                                                  color: "#fff",
                                                  fontSize:
                                                    "clamp(12px, 3vw, 12px) !important"
                                                }}
                                              >
                                                Delivery Instructions. Provide any
                                                instructions that might be helpful to
                                                the owner for the delivery.
                                              </Typography>
                                            </Box>
                                          </Box>
                                          <Box className="renter-section-grid">
                                            <Box></Box>
                                            <Box>
                                              <Box className="secondoption-inner-div">
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  //label="Max. 400 characters"
                                                  placeholder={"Max. 400 characters"}
                                                  multiline
                                                  maxRows={4}
                                                  rows={4}
                                                  fullWidth
                                                  sx={{
                                                    color: "#adadad",
                                                    "& .MuiInputBase-root": {
                                                      color: "#000",
                                                      background: "#fff",
                                                      fontFamily:
                                                        "GothamBold !important",
                                                      fontSize:
                                                        "clamp(10px, 3vw, 12px) !important"
                                                    },
                                                    "& .MuiFormLabel-root": {
                                                      color: "#adadad"
                                                    },
                                                    "& label.Mui-focused": {
                                                      color: "#000",
                                                      fontWeight: "bold"
                                                    },
                                                    "& .MuiInput-underline:after": {
                                                      borderBottomColor: "#2e2e2e"
                                                    },
                                                    "& .MuiOutlinedInput-root": {
                                                      "& input": {
                                                        color: "#000 !important"
                                                      },
                                                      "& fieldset": {
                                                        borderColor: "#faa61a",
                                                        // backgroundColor: "#fff",
                                                        color: "#faa61a !important"
                                                      },
                                                      "&:hover fieldset": {
                                                        borderColor: "#2e2e2e",
                                                        border: "2px solid #faa61a"
                                                      },
                                                      "&.Mui-focused fieldset": {
                                                        borderColor: "#faa61a",
                                                        backgroundColor:
                                                          "transparent",
                                                        color: "#000 !important"
                                                      }
                                                    }
                                                  }}
                                                  onChange={(e) =>
                                                    DeliveryInstructions(e)
                                                  }
                                                  value={DeliveryInstructionsval}
                                                />
                                                <Typography
                                                  className="ltr-left"
                                                  sx={{ color: "#fff" }}
                                                >
                                                  {DeliveryInstructionsvallength}{" "}
                                                  characters left
                                                </Typography>

                                                <Box className="equipment-rate-inner">
                                                  <Box
                                                    className="rate-list-grids"
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent: "space-between",
                                                      alignItems: "baseline"
                                                    }}
                                                  >
                                                    <Typography
                                                      sx={{ color: "#fff" }}
                                                    >
                                                      Min. Transport (Round Trip)
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        color: "#fff",
                                                        flex: "1"
                                                      }}
                                                      className="dash-border"
                                                    ></Typography>
                                                    <Typography
                                                      sx={{ color: "#fff" }}
                                                    >
                                                      $
                                                      {new Intl.NumberFormat(
                                                        "en-US",
                                                        {
                                                          maximumFractionDigits: 2,
                                                          minimumFractionDigits: 2
                                                        }
                                                      ).format(
                                                        result[
                                                        "minimum_delivery_charges"
                                                        ]
                                                      )}
                                                    </Typography>
                                                  </Box>
                                                  <Box
                                                    className="rate-list-grids"
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent: "space-between",
                                                      alignItems: "baseline"
                                                    }}
                                                  >
                                                    <Typography
                                                      sx={{ color: "#fff" }}
                                                    >
                                                      Additional Miles Charge
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        color: "#fff",
                                                        flex: "1"
                                                      }}
                                                      className="dash-border"
                                                    ></Typography>
                                                    <Typography
                                                      sx={{ color: "#fff" }}
                                                    >
                                                      $
                                                      {new Intl.NumberFormat(
                                                        "en-US",
                                                        {
                                                          maximumFractionDigits: 2,
                                                          minimumFractionDigits: 2
                                                        }
                                                      ).format(deliveryPrice)}

                                                    </Typography>
                                                  </Box>
                                                </Box>
                                              </Box>
                                            </Box>
                                          </Box>
                                        </Box>
                                        <hr />

                                        <Box className="equipment-rate-inner">
                                          <Box
                                            className="rate-list-grids"
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "baseline"
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                color: "#faa61a",
                                                fontWeight: "bold",
                                                fontFamily: "GothamBold !important",
                                                marginLeft: "25px"
                                              }}
                                            >
                                              Transport Total
                                            </Typography>
                                            <Typography
                                              sx={{ color: "#fff", flex: "1" }}
                                              className="dash-border"
                                            ></Typography>
                                            <Typography
                                              sx={{
                                                color: "#faa61a",
                                                fontWeight: "bold",
                                                fontFamily: "GothamBold !important"
                                              }}
                                            >
                                              $
                                              {transportTotal
                                                ?
                                                new Intl.NumberFormat("en-US", {
                                                  maximumFractionDigits: 2,
                                                  minimumFractionDigits: 2
                                                }).format(transportTotal)
                                                : "0.00"}
                                            </Typography>
                                          </Box>
                                          <Box
                                            className="rate-list-grids"
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "baseline"
                                            }}
                                          ></Box>
                                        </Box>
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                              </FormControl>
                            </Box>

                            <Box className="modal-cal-btn-grp">
                              <Button
                                className="rent-now-btn cmmn-cancel-btn"
                                onClickCapture={() => {
                                  settransportoptionpopup(false);
                                  // setrentEndDate("")
                                  // setrentStartDate("")
                                  // setStartDate("")
                                  // setEndDate("")
                                  // setValues(ChaeckDatesBlockArr);
                                }}
                              >
                                Cancel
                              </Button>

                              <Button
                                className="rent-now-btn cmmn-green-btn-2"
                                onClickCapture={() => {
                                  validateData("fromPopUp");
                                }}
                              >
                                Confirm
                              </Button>
                            </Box>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Modal>

              {/* After order Request create display popup for success message and redirection*/}

              <Box>
                <Dialog
                  open={OrderSuccessPopup}
                  // open={true}  
                  aria-labelledby="draggable-dialog-title">
                  <Box className="session-expire">
                    <DialogContent>
                      <DialogContentText style={{ color: "#fff", fontSize: "20px" }}>
                        Thank you, you will not be charged until the owner of this equipment approves your rental request. Keep an eye on your email for confirmation. Also, please reach out to the owner directly at this 
                        <span style={{textDecoration:"underline",color:"#fba618",cursor:"pointer",marginLeft:"7px"}} onClick={HandleInfoPopup}>link</span> with questions.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Button
                        className=""
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          backgroundColor: '#2e7d32 !important',
                          color: "#fff !important",
                          padding: "6px 50px 6px 50px",
                          borderRadius: '7px',
                          fontFamily: 'GothamBold !important',
                          fontSize: '20px !important',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          // window.location.href = "/";
                          if(requestedOrderId) {
                            window.location.href = `/equipmentOrders/${requestedOrderId}/advance`;
                          } else {
                            window.location.href = "/";
                          }
                        }
                        }
                      >
                        OK
                      </Button>

                    </DialogActions>
                  </Box>
                </Dialog>
              </Box>

              {/* Order amount pay direct with card */}
              <Modal
                open={payWithCard}
                //onClose={addbalancepopupClose}
                className="addmoney-modal"
              >
                <Box
                  className="download-popups"
                  sx={{
                    ...loginstyle,
                    padding: "0px"
                  }}
                >
                  <IconButton className="cross-btn" onClick={payWithCardClose}>
                    <CloseIcon />
                  </IconButton>

                  <Box className="login__main">
                    <Box
                      className="login__main-div edit-eqp">
                      <Box
                        className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                        style={{
                          border: "5px solid #faa61a",
                          borderRadius: "10px"
                        }}
                      >
                        <Typography
                          variant="h5"
                          style={{ color: "#faa61a", marginBottom: "0.5rem" }}
                        >
                          Pay With Card
                        </Typography>

                        <PaymentHold
                          amount={holdAmount}
                          onSuccess={(e) => {                            
                            if (e.status == "success") {
                              setLoadWalletBalance(false);
                              //setpayWithCard(false);

                              HandleCardHoldPayment(e);
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Modal>


              {/* <Modal
                keepMounted                
                open={OrderSuccessPopup}               
                onClose={() => setOrderSuccessPopup(false)}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
              >
                <Box
                  className="download-popups"
                  sx={{
                    ...loginstyle,
                    padding: "0px",
                  }}
                >
                  

                  <Box className="login__main">
                    <Box
                      className="login__main-div edit-eqp"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Box className="login__main-div__form popup-cmmn popup-white edit-eqp-inner">
                        <Box className="" sx={{ color: '#fff', fontSize: "1.3rem" }}>
                          Thank you, you will not be charged until the owner of this equipment approves your rental request. Keep an eye on your email for confirmation. Also, please reach out to the owner directly at this link with questions.
                        </Box>

                        <Box className="chat-btn-grp">
                          <Button
                            className="accept-btn"
                            type="button"
                            variant="contained"
                            onClick={() => {                             
                              window.location.href = "/";                             
                              }
                            }
                            sx={{ fontSize: '16px !important', fontFamily: 'GothamBold !important' }}
                          >OK</Button>
                        </Box>


                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Modal> */}

            </Box>
          </>
        )}

      </MainLayout>
    </>
  );
};

export default EquipmentDetail;
