import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import './styles.scss'

const Howitworks_slider = () => {
  return (
      <div className='hiw-slider-div'>
          {/* Desktop Swiper */}
          <Swiper
              slidesPerView={1}
              spaceBetween={10}
              loop={true}
              modules={[Pagination, Navigation, Autoplay]}
              autoplay={{ delay: 2000, disableOnInteraction: false }}
              className='hiw-slider'
          >
              
              <SwiperSlide>
                  <p >
                      earning together.
                  </p>
              </SwiperSlide>

              <SwiperSlide>
                  <p >
                      keeping your equipment utilized.
                  </p>
              </SwiperSlide>

              <SwiperSlide>
                  <p >
                      saving you more.
                  </p>
              </SwiperSlide>

              <SwiperSlide>
                  <p >
                      earning you more.
                  </p>
              </SwiperSlide>

              <SwiperSlide>
                  <p >
                      a better way to do business.
                  </p>
              </SwiperSlide>

             
          </Swiper>

          

         
      </div>
  )
}

export default Howitworks_slider
