import React, { useCallback, useEffect, useState } from 'react'
import { IStoreValue } from "utils/types";
import { useDispatch, useSelector } from 'react-redux';
import { setalertMessagetype, setalertMessage } from 'redux/redux-slice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Messages = () => {
    const dispatch = useDispatch()
    const alertType = useSelector((state: IStoreValue) => state.equipmentReducer.alertMessagetype)
    const alertMessage = useSelector((state: IStoreValue) => state.equipmentReducer.alertMessage)

    const info = () => {
        toast.info(alertMessage, {
            position: "top-center",
            autoClose: 3000,
            closeOnClick: true,
            theme: "colored",
            hideProgressBar: true,
        });
        dispatch(setalertMessagetype(''))
        dispatch(setalertMessage(''))
    }
    
    const success = () => {
        toast.success(alertMessage, {
            position: "top-center",
            autoClose: 3000,
            closeOnClick: true,
            theme: "colored",
            hideProgressBar: true,
        });
        dispatch(setalertMessagetype(''))
        dispatch(setalertMessage(''))
    }

    const error = () => {
        toast.error(alertMessage, {
            position: "top-center",
            autoClose: 3000,
            closeOnClick: true,
            theme: "colored",
            hideProgressBar: true,
        });
        dispatch(setalertMessagetype(''))
        dispatch(setalertMessage(''))
    }

    useEffect(() => {
        //checkLoginorNot()
    }, []);

    return (
        <>
            {
                alertType == 'success' &&
                success()
            }
            {
                alertType == 'error' &&
                error()
            }
            {
                alertType == 'info' &&
                info()
            }

            <ToastContainer />

        </>
    );
}
export default Messages