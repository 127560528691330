import React, { useState, useEffect } from 'react';
import moment from 'moment';

const CountdownTimer = ({ expirationDateTime, color = "#000" }) => {
  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    // Calculate the time remaining and format it using moment
    const expirationTime = moment(expirationDateTime).unix();

    const calculateTimeRemaining = () => {
      const currentTime = moment().unix(); // Get current time in seconds (UNIX timestamp)
      const timeLeftInSeconds = expirationTime - currentTime;

      if (timeLeftInSeconds > 0) {
        const duration = moment.duration(timeLeftInSeconds, 'seconds');
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        return `${String(hours).padStart(2, '0')}h:${String(minutes).padStart(2, '0')}m:${String(seconds).padStart(2, '0')}s`;
      } else {
        return '00h:00m:00s';
      }
    };

    // Initialize the time remaining
    setTimeRemaining(calculateTimeRemaining());

    if (expirationTime > moment().unix()) {
      // Start the countdown timer if the expiration time is in the future
      const interval = setInterval(() => {
        const newTimeRemaining = calculateTimeRemaining();
        setTimeRemaining(newTimeRemaining);

        // Clear interval if timeRemaining reaches '00h:00m:00s'
        if (newTimeRemaining === '00h:00m:00s') {
          clearInterval(interval);
        }
      }, 1000); // Update every second

      // Cleanup interval on component unmount
      return () => clearInterval(interval);
    }
  }, [expirationDateTime]);

  return (
    <span style={{ color }}>&nbsp;{timeRemaining}</span>
  );
};

export default CountdownTimer;
