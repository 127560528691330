import { useState } from "react";
import { Box, Button, IconButton, Modal, TextField, Typography, styled, useMediaQuery } from "@mui/material"
import { useDispatch, useSelector } from 'react-redux';
import { setModalOpen, setLoginStatus, setaccessToken, setSessionExpire, setalertMessagetype, setalertMessage } from 'redux/redux-slice';
import CloseIcon from '@mui/icons-material/Close'



const loginstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 4,
}
const SendTextMsgModal = ({ emailModelOpen, setEmailModelOpen, sendToEmail }) => {
    // const [PhotosModelOpen , setPhotosModelOpen] = useState(false);
    const dispatch = useDispatch();
    const matchesMobileX = useMediaQuery('(max-width:425px)');
    const [messageText, setMessageText] = useState('');
    // const AuthToken = useSelector((state: IStoreValue) => state.equipmentReducer.accessToken);
    const [loading, setLoading] = useState(false);

    return (
        <Modal
            keepMounted
            open={emailModelOpen}
            onClose={() => setEmailModelOpen(false)}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box
                className="download-popups"
                sx={{
                    ...loginstyle,
                    width: matchesMobileX ? 'calc(100vw - 100px)' : 800,
                    padding: "0px",
                }}>

                <IconButton
                    className="cross-btn"
                    onClick={() => setEmailModelOpen(false)}
                >
                    <CloseIcon />
                </IconButton>
                <Box className="login__main">
                    <Box className="login-main-inner">
                        <Box className="login__main-div__form popup-cmmn popup-white edit-eqp-inner">
                            <Box className="">
                                <Box className="">
                                    <Typography
                                        variant="h4"
                                        className="welcome-heading"
                                        sx={{ textAlign: "center", color: "#fff" }}
                                    >
                                    </Typography>

                                    <Typography className="second-para" sx={{ fontSize: '20px !important', textAlign: 'center', color: '#fff !important', fontFamily: 'GothamBold !important' }}>
                                        {/* Send email to :-{sendToEmail} */}
                                    </Typography>
                                    <Typography className="second-para" sx={{ marginBottom: '1rem', fontSize: '20px !important', textAlign: 'center', color: '#fff !important', fontFamily: 'GothamBold !important' }}>
                                        Are you sure you want to open your email?
                                    </Typography>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem', }}>
                                        {/* <Button onClick={() => setPhotosModel(false)} sx={{ width: '100%', border: '2px solid #faa61a', color: '#fff', textTransform: 'capitalize', fontFamily: 'GothamBold !important' }}>Cancel</Button> */}
                                        <Button sx={{ borderRadius: '13px', fontSize: '20px !important', padding: '0.5rem 1rem', width: 'fit-content', background: '#149247 !important', color: '#fff', textTransform: 'capitalize', fontFamily: 'GothamBold !important' }}
                                            onClick={() => {
                                                const mailtoLink = `mailto:${sendToEmail}`;
                                                // Open the default email client
                                                window.location.href = mailtoLink;
                                            }}
                                        >
                                            Open Email
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}


export default SendTextMsgModal;