import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import './styles.scss'

const BuildaCrew_Slider = () => {
    return (
        <div className='bac-slider-div'>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
                loop={true}
                modules={[Pagination, Navigation, Autoplay]}
                autoplay={{ delay: 2000, disableOnInteraction: false }}
                className='hiw-slider'
            >
                <SwiperSlide>
                    <p >
                        who has heavy equipment?
                    </p>
                </SwiperSlide>

                <SwiperSlide>
                    <p >
                        who rents equipment?
                    </p>
                </SwiperSlide>

                <SwiperSlide>
                    <p >
                        who needs an equipment loan?
                    </p>
                </SwiperSlide>

                <SwiperSlide>
                    <p >
                        who needs GPS solutions?
                    </p>
                </SwiperSlide>

                <SwiperSlide>
                    <p >
                        who is good at networking?
                    </p>
                </SwiperSlide>


            </Swiper>
        </div>
    )
}

export default BuildaCrew_Slider
